import React from 'react';
import {LoadGraphic} from '../components/Common';
import u from '../utilities/Utilities';
import store from 'store';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import "../styles/print-dorm-qr.scss";

export default class PrintDormQR extends React.Component{

    state={
        dormID:"",
        dormName:"",
        loading:true,
        loadText:"Loading...",
        dimensions:{},
    };

    componentDidMount=()=>{
        const dorm = store.get("print-dorm");
        if(dorm){
            this.setState({dormID:dorm.dormID,dormName:dorm.dormName,loading:false});
            setTimeout(()=>{
                window.print();
            },500);
        }
        else{
            this.setState({loadText:"Dormitory Not Found"});
        }
    };

    render=()=>{
        const width=this.state.dimensions.width;
        return(
            <div className="page-container" style={{textAlign:"center"}}>
                <div className="print-dorm-container">
                    <div className="pd-header">
                        <img src={"/images/app/app-logo.png"}/>
                    </div>

                    <div style={{position:"relative"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                        <div>
                            <h2 style={{margin:"25px auto 40px auto"}}>MWMD Dormitories Resident Login</h2>

                            <div className="instruction-item">
                                <div className="instruction-text">Download <span className="text-emphasis">MWMD Dormitories</span> from App Store or Play Store & select resident login</div>
                                <img src="/images/dorm-qr/qr-1.png" className="instruction-image"/>
                            </div>

                            <div className="instruction-item">
                                <div className="instruction-image">
                                    <img src="/images/dorm-qr/qr-2.png" style={{width:"100%",borderRadius:"5px"}} />
                                    <div className="pd-qr-code-container">
                                        <div className="pd-qr-dorm">{this.state.dormName}</div>
                                        <Measure bounds onResize={contentRect => {this.setState({dimensions:contentRect.bounds})}}>
                                            {
                                                ({measureRef})=>
                                                    (
                                                        <div ref={measureRef} className="pd-qr-code">
                                                            <QRCode value={this.state.dormID} level="M" fgColor="#000" size={width} />
                                                        </div>
                                                    )
                                            }
                                        </Measure>
                                        <div className="pd-qr-id">{this.state.dormID}</div>
                                    </div>
                                </div>
                                <div className="instruction-text" style={{padding:"0 0 0 20px",textAlign:"right"}}>Scan this QR Code to register to the dormitory or manually key in <span className="text-emphasis">{this.state.dormID}</span></div>
                            </div>

                            <div className="instruction-item">
                                <div className="instruction-text">Scan or key in your <span className="text-emphasis">FIN Number</span> to login to your account</div>
                                <img src="/images/dorm-qr/qr-3.png" className="instruction-image"/>
                            </div>
                        </div>
                    </div>

                    <div className="pd-footer">
                        <img src="/images/app/footer.png"/>
                    </div>
                </div>
            </div>
        )
    }
    
}
