import React from 'react';
import {CSSTransition} from 'react-transition-group';
import PropTypes from 'prop-types';
import {Tag} from '../components/OverviewList';
import {Button,Checkbox,ProgressSlider,Box,Area,MiniMinusButton,TextButton,
        Select,EditableBox,EditableContactNumber,ContactNumber,EditableSelect,
        EditableArea,EditableDate,EditableTime,EditSizeLabel,EditSizeLink,Date,
        Time,Info,DayOfWeek,EditableDOW} from './Input';
import Webcam from '../components/Webcam';
import {ListSegmentPhoto} from './OverviewList';
import u from '../utilities/Utilities';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';
import {Photo,Spreadsheet,Attachment} from '../data/Mimetypes';
import Editor from 'react-avatar-editor';
import '../styles/common.scss';
import '../styles/animations.scss';
import Measure from 'react-measure';

/**
 * Creates a themed segment element
 *
 * @param {bool} active
 *      show or hide this component
 *
 * @param {string} align
 *      CSS text align - left | right | center
 *
 * @param {string} padding
 *      CSS padding
 *
 * @param {string}  margin
 *      CSS margin
 *
 */
export class Segment extends React.Component{

    scrolledOnce = false;

    scrollAnchor = u.getKey();

    componentDidUpdate=(prevProps,prevState)=>{
        if(!this.props.active){
            this.scrolledOnce=false;
        }
    }

    scrollToDiv=()=>{
        if(!this.scrolledOnce && !this.props.ignoreScroll){
            this.scroll();
            this.scrolledOnce=true;
        }
    }

    scroll=()=>{
        var element = document.getElementById(this.scrollAnchor);
        if (typeof element == "undefined") {
            return 0;
        }
        element.scrollIntoView({behavior: "smooth", block: "start"});
    }

    render=()=>{
        return (
            <CSSTransition in={this.props.active} classNames={"fadeIn"} timeout ={300}>
                <div style={{position:"relative"}}>
                    <div className="segment-scroll-facade" style={{top:(this.props.scrollTop) ? this.props.scrollTop+"px" : undefined}} id={this.scrollAnchor}/>
                    {
                        (this.props.active)
                            ?
                            <div ref={(a)=>{this.scrollToDiv()}} className={"segment-container" + (this.props.noBorder ? " no-border" : "")} style={((this.props.style) ? this.props.style : {textAlign:this.props.align,padding:this.props.padding,margin:this.props.margin})}>
                                {this.props.children}
                            </div>
                            :
                            <div/>
                    }
                </div>
            </CSSTransition>
        )
    }
}
Segment.propTypes={
    active:PropTypes.bool.isRequired,
    align:PropTypes.string,
    padding:PropTypes.string,
    margin:PropTypes.string,
    style:PropTypes.object,
    id:PropTypes.string,
    scrollTop:PropTypes.number,
    ignoreScroll:PropTypes.bool,
};
Segment.defaultProps={
    align:"left",
    padding:"0 0 0 0",
    margin:"0 0 0 0",
};


/**
 * Creates a themed databox element for data entities
 *
 * @param {bool} active
 *      show or hide this component
 *
 * @param {string} align
 *      CSS text align - left | right | center
 *
 * @param {string} padding
 *      CSS padding
 *
 * @param {string}  margin
 *      CSS margin
 */
export class Databox extends React.Component{
    render=()=>{
        return (
            <div className={"data-box" + ((this.props.hideBorder) ? " no-border" : "") + ((this.props.title) ? " has-title" : "") + ((this.props.shrink) ? " shrink" : "")}
                 style={{margin:this.props.margin,textAlign:this.props.align,padding:this.props.padding,width:this.props.width,maxWidth:this.props.maxWidth,height:this.props.height,maxHeight:this.props.maxHeight}}
                 id={this.props.id}>
                {
                    (this.props.title)
                        ?
                        <div className="data-box-title">
                            <h2 className="data-box-title-content">{this.props.title}</h2>
                        </div>
                        :
                        ""
                }
                <div className="databox-expand">
                    {
                        (this.props.shrink)
                          ?
                          <img className="databox-expand-image" src="/images/user/expand-blue.png" onClick={this.props.onSelect}/>
                          :
                          (typeof this.props.shrink != "undefined")
                            ?
                            <div className="databox-expand-close">
                                <TextButton style={{margin:"0"}} onClick={this.props.onCancel}>Close</TextButton>
                            </div>
                            :
                            null
                    }
                </div>
                <div className="data-box-content">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
Databox.propTypes={
    align:PropTypes.string,
    padding:PropTypes.string,
    margin:PropTypes.string,
    width:PropTypes.string,
    maxWidth:PropTypes.string,
    hideBorder:PropTypes.bool,
    title:PropTypes.string,
    height:PropTypes.string,
    maxHeight:PropTypes.string,
    shrink:PropTypes.bool,
};
Databox.defaultProps={
    active:true,
    align:"left",
    margin:"50px auto",
};


/**
 * Creates an animated step division to unify animation between steps
 *
 * @param {bool} active
 *      show or hide this step
 *
 * @param {bool} forceFade
 *      Changes step animation to fade instead of slide, performance improvement for devices that cannot support slide
 */
export class Step extends React.Component{

    scrolledOnce = false;
    scrollAnchor = u.getKey();

    componentDidUpdate=(prevProps,prevState)=>{
        if(!this.props.active){
            this.scrolledOnce=false;
        }
    }

    scrollToDiv=()=>{
        if(this.props.ignoreScroll){
            return 0;
        }

        if(!this.scrolledOnce && this.props.scroll){
            var element = document.getElementById(this.scrollAnchor);
            if(typeof element == "undefined"){
                return 0;
            }
            element.scrollIntoView({behavior: "smooth",block:"start"});
            this.scrolledOnce=true;
        }
    }

    render=()=>{
        return (
            <CSSTransition in={this.props.active} classNames={"fadeIn"} timeout={300}>
                {
                    (this.props.active==true)
                        ?
                        <div ref={(a)=>{this.scrollToDiv()}} style={{position:"relative",...this.props.style}}>
                            <div className="segment-scroll-facade" style={{top:(this.props.scrollTop) ? this.props.scrollTop+"px" : undefined}} id={this.scrollAnchor}/>
                            {this.props.children}
                        </div>
                        :
                        <div/>
                }
            </CSSTransition>
        )
    }
}
Step.propTypes={
    active:PropTypes.bool.isRequired,
    forceFade:PropTypes.bool,
    scroll:PropTypes.bool,
    scrollTop:PropTypes.number
};


/**
 * Creates a themed header object
 *
 * @param {string} type
 *      half || full
 *      half = only show text logo
 *      full = show text logo and icon logo
 *
 * @param {array} buttons
 *      Navigational buttons shown on this page - See data/NavButtons.js
 *
 * @param {bool} hasNav
 *      show navigational bar?
 *
 * @param {number} width
 *      the current inner width of the browser
 *
 * @param {string} current
 *      the current url of the web page
 */
export class Header extends React.Component{

    state={
        avatarLink:u.getUserAvatar(),
        name:u.getFullName(),
        favorite:u.getFavorite(),
        tempUser:true,
    };

    componentDidMount=()=>{
        this.setState({tempUser:store.get("tempUser")});
    };

    onLogout=(e)=>{
        e.preventDefault();
        u.logout();
    }

    onMarkFavorite=(e)=>{
        e.preventDefault();

        let favorite = window.location.pathname;
        if(favorite == "/estate-list"){
            favorite="/estate-list/owner";
        }
        store.set("favorite",favorite);
        this.setState({favorite:favorite});
        u.post({
            url:"/api/set-favorite",
            data:{
                favorite:favorite
            },
            success:(callback)=>{},
            error:(error,status)=>{}
        });
    }

    onUnmarkFavorite=(e)=>{
        e.preventDefault();

        const favorite = "/dashboard";
        store.set("favorite",favorite);
        this.setState({favorite:favorite});
        u.post({
            url:"/api/set-favorite",
            data:{
                favorite:favorite
            },
            success:(callback)=>{},
            error:(error,status)=>{}
        });
    }

    onSiteBack=()=>{
        window.history.back();
    }

    render=()=>{
        const c = (this.props.type != "logo") ? " mobile" : "";
        const favorite = (this.state.favorite) ? this.state.favorite : "/login";
        const pathname = (window) ? window.location.pathname : null;

        let hasMenu = false;
        if(this.props.buttons){
            for(var i=0; i<this.props.buttons.length; i++){
                if(this.props.buttons[i].permission != "none"){
                    hasMenu=true;
                    break;
                }
            }
        }

        return (
            <div className={"header-container" + c} style={this.props.style}>
                {
                    (this.props.hasNav && !this.state.tempUser)
                        ?
                        <div className="header-addon no-print">
                            {
                                (this.props.current != "/dashboard")
                                    ?
                                    <div className="header-back" onClick={this.onSiteBack}>
                                        <img src="/images/mobile/left-arrow.png"/>
                                    </div>
                                    :
                                    ""
                            }

                            {
                                (hasMenu)
                                    ?
                                    <div className="header-nav">
                                        <NavMenu buttons={this.props.buttons} current={this.props.current}/>
                                    </div>
                                    :
                                    ""
                            }

                            <div className={"header-profile" + ((hasMenu) ? "" : " no-nav")}>
                                {
                                    (this.state.avatarLink)
                                    ?
                                    <img src={this.state.avatarLink} style={{marginRight:"10px"}}/>
                                    :
                                    null
                                }
                                <div className="header-text">{this.state.name}</div>
                            </div>

                            <div className="header-options">
                                <img src="/images/app/logout.png" title="Logout" onClick={this.onLogout}/>

                                <Link to={favorite}>
                                    <img className="header-home" src="/images/app/home.png" title="Return to favorite page"/>
                                </Link>

                                {
                                    (pathname == this.state.favorite)
                                        ?
                                        <img className="header-star" src="/images/app/star.png" title="Unmark favorite page" onClick={this.onUnmarkFavorite}/>
                                        :
                                        <img className="header-star" src="/images/app/unstar.png" title="Mark page as favorite" onClick={this.onMarkFavorite}/>
                                }
                            </div>
                        </div>
                        :
                        ""
                }
                <img src="/images/app/app-logo.png" className="app-logo" onClick={this.props.onLogoClick}/>
                {
                    (this.props.pageTitle)
                        ?
                        <div className="mobile-title">{this.props.pageTitle.toUpperCase()}</div>
                        :
                        ""
                }
            </div>
        )
    }
}
Header.propTypes={
    type:PropTypes.string.isRequired,
    hasNav:PropTypes.bool,
    buttons:PropTypes.array,
    current:PropTypes.string
};

/**
 * Creates a themed header object
 *
 * @param {string} type
 *      half || full
 *      half = only show text logo
 *      full = show text logo and icon logo
 *
 * @param {array} buttons
 *      Navigational buttons shown on this page - See data/NavButtons.js
 *
 * @param {bool} hasNav
 *      show navigational bar?
 *
 * @param {number} width
 *      the current inner width of the browser
 *
 * @param {string} current
 *      the current url of the web page
 */
export class CPHeader extends React.Component{

    state={
        avatarLink:u.getUserAvatar(),
        name:u.getCookie("clientUserName"),
    };

    componentDidMount=()=>{
        this.setState({tempUser:store.get("tempUser")});
    };

    onLogout=(e)=>{
        e.preventDefault();
        u.cpLogout();
    }

    onSiteBack=()=>{
        window.history.back();
    }

    render=()=>{
        const c = (this.props.type != "logo") ? " mobile" : "";
        const favorite = (this.state.favorite) ? this.state.favorite : "/login";
        const pathname = (window) ? window.location.pathname : null;

        let hasMenu = false;
        if(this.props.buttons){
            for(var i=0; i<this.props.buttons.length; i++){
                if(this.props.buttons[i].permission != "none"){
                    hasMenu=true;
                    break;
                }
            }
        }

        return (
            <div className={"header-container" + c} style={this.props.style}>
                {
                    (this.props.hasNav && !this.state.tempUser)
                        ?
                        <div className="header-addon no-print">
                            {
                                (this.props.current != "/dashboard")
                                    ?
                                    <div className="header-back" onClick={this.onSiteBack}>
                                        <img src="/images/mobile/left-arrow.png"/>
                                    </div>
                                    :
                                    ""
                            }

                            {
                                (hasMenu)
                                    ?
                                    <div className="header-nav">
                                        <NavMenu buttons={this.props.buttons} current={this.props.current}/>
                                    </div>
                                    :
                                    ""
                            }

                            <div className={"header-profile" + ((hasMenu) ? "" : " no-nav")}>
                                {
                                    (this.state.avatarLink)
                                    ?
                                    <img src={this.state.avatarLink} style={{marginRight:"10px"}}/>
                                    :
                                    null
                                }
                                <div className="header-text">{this.state.name}</div>
                            </div>

                            <div className="header-options">
                                <img src="/images/app/logout.png" title="Logout" onClick={this.onLogout}/>

                                <Link to="/portal/dashboard">
                                    <img className="header-home" src="/images/app/home.png" title="Return to favorite page"/>
                                </Link>
                            </div>
                        </div>
                        :
                        ""
                }
                <img src="/images/app/app-logo.png" className="app-logo" onClick={this.props.onLogoClick}/>
                {
                    (this.props.pageTitle)
                        ?
                        <div className="mobile-title">{this.props.pageTitle.toUpperCase()}</div>
                        :
                        ""
                }
            </div>
        )
    }
}
CPHeader.propTypes={
    type:PropTypes.string.isRequired,
    hasNav:PropTypes.bool,
    buttons:PropTypes.array,
    current:PropTypes.string
};


/**
 * Creates a themed dynamic navigation menu for desktop users > 650px browsers
 *
 * @param {string} current
 *      url of the current page
 *
 * @param {array} buttons
 *      all buttons required to render on this page
 *
 */
class NavMenu extends React.Component{

    state={
        active:false
    };

    onClickHamburger=(e)=>{
        e.preventDefault();
        this.setState({active:!this.state.active});
    }

    onLogout=(e)=>{
        if(e){
            e.preventDefault();
        }
        u.logout();
    }

    render=()=>{
        return(
            <div className="nav-menu">
                <div className={"hamburger-nav" + ((this.state.active) ? " cross" : "")} onClick={this.onClickHamburger}>
                    <svg className="hamburger-svg" viewBox="0 0 800 600">
                        <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                        <path d="M300,320 L540,320" id="middle"></path>
                        <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                    </svg>
                </div>
                {
                    (this.state.active)
                        ?
                        <div className="nav-menu-container">
                            <img className="nav-triangle" src="/images/app/nav-triangle.png"/>
                            <div className="nav-triangle-cover"/>
                            <div className="nav-menu-content">
                                {
                                    this.props.buttons.map(
                                        (item,index)=>{
                                            return (
                                                <Link key={index} to={item.url} className="nav-menu-item">
                                                    <div className={"nav-item-image" + ((index%2==0) ? " col-even" : " col-odd") + ((item.url == this.props.current) ? " current" : "")}>
                                                        <img src={item.image}/>
                                                    </div>
                                                    <div className="nav-item-text">{item.name}</div>
                                                </Link>
                                            )
                                        }
                                    )
                                }

                                <div className="nav-menu-item nav-addon" onClick={this.onLogout} style={{cursor:"pointer"}}>
                                    <div className={"nav-item-image"}>
                                        <img src="/images/dashboard/logout.png"/>
                                    </div>
                                    <div className="nav-item-text">Logout</div>
                                </div>
                            </div>
                        </div>
                        :
                        ""

                }
            </div>
        )
    }
}
NavMenu.propTypes={
    current:PropTypes.string.isRequired,
    buttons:PropTypes.array.isRequired
};


/**
 * Creates a themed mobile nav menu
 *
 * @param {array} content
 *     @var {string} label
 *     @var {string} mobileLabel
 *     @var {string} ref
 *     @var {string} image
 *     @var {bool} enabled
 *
 * @param {bool} hasNotebook
 *      Does that page have a notebook module
 *
 * @param {func} onClick
 *      built to function with onMenuOptionsClicked(ref,show,mobileNav)
 */
export class MobileMenu extends React.Component{

    state={active:false};

    onToggleActive=(e)=>{
        if(e){
            e.preventDefault();
        }
        this.setState({active:!this.state.active});
    }

    onSelectItem=(target,array)=>{
        this.props.onClick(target.ref,true,array);
        this.setState({active:false});
    }

    render=()=>{
        let content=this.props.content.filter((a)=>{return a.enabled});
        if(this.props.settings.list && !this.props.settings.noListOptions){
            content.push(
              {label:"Tag Macros",ref:"macro",enabled:true,type:"list"},
              {label:"Column Setting",ref:"column",enabled:true,type:"list"},
            )
        }

        if(content.length == 0){
            return (<span/>)
        }

        return (
            <div className={"mobile-menu" + ((this.props.settings.hasNotebook) ? " short" : "") + ((this.state.active) ? " active" : "")}>
                <div className="mobile-menu-title" onClick={this.onToggleActive}>
                    <img className="mobile-menu-title-image" src={
                      (this.state.active)
                        ?
                        "/images/page-menu/cancel.png"
                        :
                        "/images/page-menu/row-menu.png"
                    }/>
                </div>
                <div className="mobile-menu-content">
                    {
                        content.map(
                            (item,index,arr)=>{
                                return (
                                    <div key={index} id={index+""} className="mobile-menu-item" onClick={()=>{this.onSelectItem(item,arr)}}>
                                        {((item.mobileLabel) ? item.mobileLabel : item.label).toLocaleUpperCase()}
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </div>
        )
    }
}
MobileMenu.propTypes={
    content:PropTypes.array.isRequired,
    hasNotebook:PropTypes.bool,
    onClick:PropTypes.func.isRequired,
};


/**
 * Creates a themed footer object
 *
 * @param type
 *      half || full
 *      half = only show chimeric logo
 *      full = show chimeric logo & copyright text
 *
 * @param width
 *      inner width of the browser
 */
export class Footer extends React.Component{

    state={year:moment().format("YYYY")};

    render=()=>{
        const ios = u.isiOS();
        return (
            <div className={"footer-container"+ ((ios)?" ios":"")}>
                <div className="footer">
                    {
                        (this.props.children)
                            ?
                            this.props.children
                            :
                            ""
                    }
                    {
                        (this.props.type != "half")
                            ?
                            <div className="footer-text no-print">Copyright &copy; {this.state.year} Chimeric Technologies. All Rights Reserved.</div>
                            :
                            ""
                    }
                    <img src="/images/app/footer.png" className="footer-logo no-print"/>
                </div>
            </div>
        )
    }
}
Footer.propTypes={
    type:PropTypes.string,
};


/**
 * Creates a themed file drag and drop container that auto validates & uploads files
 *
 * @param {string} label
 *      name of the parent component
 *
 * @param {bool} noUpload
 *      if false, file will not upload
 *
 * @param {bool} accessibleForPreview
 *      if true, stores file in a URL-accessible location on server
 *      else stores in protected location
 *
 * @param {array} filetypes
 *      list of acceptable filetypes for this dropzone. See data/Mimetypes
 *
 * @param {function} onAdded (filename,file)
 *      file added to dropzone callback function
 *
 * @param {function} onProgress (progress)
 *      callback on upload progress intervals
 *
 * @param {function} onUploaded(filename)
 *      successful upload callback with server filename
 *
 * @param {function} onError(error)
 *      callback on upload error
 *
 * @function {null} onCancel
 *      call to terminate upload sequence
 *
 */
export class Dropzone extends React.Component{

    state={
        status:"idle",
        progress:0,
        ext:"",
        browserFile:null,
    };

    uploadEvent=null;

    componentDidMount=()=>{
        let fileExtension = "";
        for(var i=0; i<this.props.filetypes.length; i++){
            for(var j=0; j<this.props.filetypes[i].ext.length; j++){
                fileExtension = fileExtension + ((i==0 && j==0) ? "" : ", ") + "." + this.props.filetypes[i].ext[j];
            }
        }
        this.setState({ext:fileExtension});
    }

    onDragEnter=(e)=>{
        e.stopPropagation();
        e.preventDefault();
        this.setState({status: 'drag-enter'});
    }

    onDragLeave=(e)=>{
        e.stopPropagation();
        e.preventDefault();
        this.setState({status: 'drag-leave'});
    }

    onDragOver=(e)=>{
        e.stopPropagation();
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    onDrop=(e)=>{
        e.stopPropagation();
        e.preventDefault();
        this.setState({status: 'idle',progress:0,error:""});
        let files = e.dataTransfer.files;
        this.onSubmit(files);
    }

    onCancel=()=>{
        if(this.uploadEvent) {
            this.uploadEvent.cancel();
            this.uploadEvent = null;
            this.setState({error:"Upload cancelled..."});
        }
    }

    onClick=(e)=>{
        const element = this.props.label +"-input";
        const fileBrowser = document.getElementById(element);
        fileBrowser.click();
    }

    onSelectFile=(e)=>{
        const element = this.props.label +"-input";
        const fileBrowser = document.getElementById(element);
        this.onSubmit(fileBrowser.files);
        e.currentTarget.value = null;
    }

    onSubmit=(files)=>{
        this.setState({error:""});
        if(files.length > 1){
            this.setState({error:"Cannot upload more than 1 file at a time"});
        }

        //validate file type
        let validFile = false;
        let fileImageReference = "";
        let filetype = "";
        const fileExtension = files[0].name.substr(files[0].name.lastIndexOf(".")+1);

        for(var i=0; i<this.props.filetypes.length; i++){
            if(files[0].type == this.props.filetypes[i].mimetype)
            {
                validFile = true;
                fileImageReference=this.props.filetypes[i].fileImageReference;
                filetype = this.props.filetypes[i].type;
                break;
            }

            if(!validFile){
                for(var j=0; j<this.props.filetypes[i].ext.length; j++){
                    if(fileExtension.toLowerCase() == this.props.filetypes[i].ext[j]){
                        validFile = true;
                        fileImageReference=this.props.filetypes[i].fileImageReference;
                        filetype = this.props.filetypes[i].type;
                        break;
                    }
                }
            }
        }
        if(!validFile){
            this.setState({error:"File type - " + files[0].type + " is not supported"});
            return 0;
        }

        if(this.props.onAdded) {
            this.props.onAdded(files[0].name,files[0],fileExtension);
        }

        if(this.props.noUpload){
            return 0;
        }

        this.uploadEvent = u.upload({
            file:files[0],
            data:{
                accessibleForPreview:this.props.accessibleForPreview
            },
            uploadProgress:(e)=>{
                if (e.lengthComputable) {
                    let loaded = Math.round((e.loaded / e.total) * 100);
                    if(this.props.onProgress){
                        this.props.onProgress(loaded);
                    }
                }
            },
            success:(filename)=>{
                this.props.onUploaded(filename,fileImageReference,filetype);
            },
            error:(error,status)=>{
                this.props.onError(error);
            }
        });
    }

    render=()=>{
        if(this.props.buttonOnly){
            return(
                <div className={"dropzone-button"}>
                    <input id={this.props.label + "-input"} type="file" style={{display:"none"}} accept={this.state.ext} onChange={this.onSelectFile}/>
                    <TextButton style={{margin:"0",fontSize:"12px"}} onClick={this.onClick}>{this.props.children}</TextButton>
                </div>
            )
        }
        return(
            <div className={"dropzone " + this.state.status} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDragOver={this.onDragOver} onDrop={this.onDrop} onClick={this.onClick}>
                <input id={this.props.label + "-input"} type="file" style={{display:"none"}} accept={this.state.ext} onChange={this.onSelectFile}/>
                <div className="segment-error">{this.state.error}</div>
                <h3 className="dropzone-title">Drag & Drop File or Click Here</h3>
                <div className="dropzone-formats">
                  {
                    (this.props.dragAndDropLabel)
                      ?
                      this.props.dragAndDropLabel
                      :
                      "Accepts formats "+this.state.ext
                  }
                </div>
            </div>
        )
    }
}
Dropzone.propTypes={
    label:PropTypes.string.isRequired,
    accessibleForPreview:PropTypes.bool.isRequired,
    onAdded:PropTypes.func,
    onUploaded:PropTypes.func,
    onError:PropTypes.func.isRequired,
    onProgress:PropTypes.func,
    filetypes:PropTypes.arrayOf(PropTypes.object).isRequired,
    noUpload:PropTypes.bool,
    buttonOnly:PropTypes.bool,
};


/**
 * Creates an image dropzone including image editor
 *
 * @param {func} onUpload (filename,callback)
 *
 */
export class ImageDropzone extends React.Component{

    state={
        step:0,
        rawfile:"",
        scale:100,
        rotate:0,
        loading:false,
        loadText:"Uploading..."
    };

    uploadEvent=null;

    onFileAdded=(filename,file)=>{
        this.setState({rawfile:file,step:1});
    }

    onFileError=(error)=>{
        this.setState({error:error});
    }

    onScaleChange=(value)=>{
        this.setState({scale:value});
    }

    onRotateChange=(value)=>{
        this.setState({rotate:value});
    }

    onBack=()=>{
        this.setState({step:0,scale:100,rotate:0});
    }

    onConfirm=()=>{
        this.setState({loading:true});
        this.refs["editor"].getImage().toBlob((blob)=>{
            let f = new File([blob],"editted-image.png",{type:"image/png",lastModifier:moment().format("YYYY-MM-DD")});
            this.uploadEvent = u.upload({
                file:f,
                data:{
                    accessibleForPreview:true,
                },
                uploadProgress:(e)=>{
                    if (e.lengthComputable) {
                        let loaded = Math.round((e.loaded / e.total) * 100);
                        this.setState({loadText:"Uploading " + loaded + "%..."});
                    }
                },
                success:(filename)=>{
                    this.setState({step:0,loadText:"Processing File..."});
                    if(this.props.onUpload){
                        this.props.onUpload(filename,()=>{
                            this.setState({loading:false})
                        });
                    }
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        },"image/png",1);
    }

    render=()=>{
        return (
            <div className="image-dropzone">
                <div className="image-dropzone-error">{this.state.error}</div>
                <Step active={this.state.step == 0}>
                    <Dropzone label="image-dropzone" accessibleForPreview={false} onError={this.onFileError} onAdded={this.onFileAdded} filetypes={Photo} noUpload={true}/>
                    {
                        (this.props.onBack)
                            ?
                            <div className="list-segment-buttons">
                                <Button type="medium" onClick={this.props.onBack}>Back</Button>
                            </div>
                            :
                            ""
                    }
                </Step>
                <Step active={this.state.step==1}>
                    <div className="image-dropzone-editor">
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <h3>Edit Image</h3>
                        <Editor ref="editor"
                                image={this.state.rawfile}
                                width={256}
                                height={256}
                                border={30}
                                color={[255, 0, 0, 0.3]} // RGBA
                                scale={this.state.scale/100}
                                rotate={this.state.rotate}
                                />
                        <div className="image-dropzone-slider">
                            <div className="image-dropzone-slider-title">Zoom</div>
                            <ProgressSlider min={1} max={300} value={this.state.scale} onChange={this.onScaleChange} />
                        </div>
                        <div className="image-dropzone-slider">
                            <div className="image-dropzone-slider-title">Rotate</div>
                            <ProgressSlider min={-180} max={180} value={this.state.rotate} onChange={this.onRotateChange}/>
                        </div>
                        <div className="image-dropzone-buttons">
                            <Button type="medium" onClick={this.onBack}>Back</Button>
                            <Button type="medium" onClick={this.onConfirm}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
ImageDropzone.propTypes={
    onUpload:PropTypes.func.isRequired,
    onBack:PropTypes.func,
};


/**
 * Creates a themed loading graphic to hide content and denote loading
 *
 * @param {bool} active
 *      show or hide this component
 *
 * @param {string} opacity
 *      [0..1] opacity of the background of this component
 *
 * @param {string} text
 *      text displayed under the animating load graphic
 */
export class LoadGraphic extends React.Component{

    render=()=>{
        return (
            (this.props.active)
              ?
              (
                  <div className="spinner-container">
                      <div className="spinner-background" style={{...this.props.style,opacity:this.props.opacity}}></div>
                      <div className="spinner-vertical-align"></div>
                      <div className="spinner-content">
                          <div className="spinner">
                              <div className="rect1"></div>
                              <div className="rect2"></div>
                              <div className="rect3"></div>
                              <div className="rect4"></div>
                              <div className="rect5"></div>
                          </div>
                          <div className="spinner-text">{this.props.text}</div>
                      </div>
                  </div>
              )
              :
              ""
          )
    }
}
LoadGraphic.propTypes={
    active:PropTypes.bool.isRequired,
    opacity:PropTypes.string,
    text:PropTypes.string.isRequired
};
LoadGraphic.defaultProps={
    opacity:"1"
};


/**
 * Creates a menu bar on a page that allows you to create components as children and reference the components accordingly
 *
 * @param {function} onMenuOptionClicked
 *
 *      Use the following function in the parent page component
 *
 *      onMenuOptionClicked(ref,show){
 *           this.refs[ref].show(show);
 *       }
 *
 * @param {array} buttons
 *      buttons:[
 *               {label:"Add",ref:"add",image:"/images/page-menu/add.png",title:"Add new staff",enabled:true,hasComponent:true},
 *               {label:"Template",ref:"download",image:"/images/page-menu/template.png",title:"Download bulk add template",enabled:true,hasComponent:false},
 *               {label:"Delete",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected staff",enabled:true,hasComponent:true},
 *               {label:"Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:true,hasComponent:true},
 *       ]
 */
export class PageMenu extends React.Component{

    state={
      active:false,
      activeIndex:0,
      open:false,
    };

    onClickButton=(e)=>{
        const index = e.currentTarget.id;
        if(this.props.buttons[index].onClick){
            this.props.buttons[index].onClick();
            return 0;
        }

        const ref = this.props.buttons[index].ref;
        const active = !this.state.active;
        this.onMenuOptionClicked(ref,active);
        this.setState({active:active,ref:ref,open:false});
    }

    onToggleActionMenu=()=>{
        if(this.state.active){
            const ref = this.state.ref;
            this.onMenuOptionClicked(ref,false);
            this.setState({active:false,open:false});
        }
        else{
            this.setState({open:!this.state.open});
        }
    }

    toggleForm=(ref,active)=>{
        this.onMenuOptionClicked(ref,active);
        this.setState({
          active:active,
          open:false,
          ref:ref,
        });
    }

    onMenuOptionClicked=(ref,show,mobileNav)=>{
        if(mobileNav){
            const toggle = (this.mobileSelect != ref);
            this.mobileSelect= ((toggle) ? ref : null);
            for(var i=0; i<mobileNav.length; i++){
                const r = mobileNav[i].ref;
                const show = (r == ref);

                if(show){
                    if(mobileNav[i].onClick){
                        mobileNav[i].onClick();
                    }
                    else if(mobileNav[i].type == "list"){
                        this.props.settings.overview.triggerListOptions(r,true);
                    }
                    else{
                      if(this.refs[r]){
                        this.refs[r].show(toggle);
                      }
                    }
                }
                else{
                    if(mobileNav[i].onClick) {

                    }
                    else if(mobileNav[i].type == "list"){
                        this.props.settings.overview.triggerListOptions(r,false);
                    }
                    else{
                        if(this.refs[r]){
                          this.refs[r].show(false);
                        }
                    }
                }
            }
        }
        else{
            this.refs[ref].show(show);
        }

        if(this.props.onChange){
            this.props.onChange(show,ref);
        }
    }

    onToggleForm=(ref,show)=>{
        this.toggleForm(ref,show);
        this.mobileSelect= (show) ? ref : null;
    }

    render=()=>{
        const hasTitle = (typeof this.props.settings.title != "undefined");
        const hasButtons = (this.props.buttons.filter((item)=>{return item.enabled}).length > 0)

        return(
            <div className="page-menu">
                {
                  (typeof this.props.settings.title == "string")
                    ?
                    <h1 className="page-menu-title">
                        {
                            (hasTitle)
                                ?
                                this.props.settings.title
                                :
                                ""
                        }
                    </h1>
                    :
                    this.props.settings.title
                }

                {
                    (hasButtons)
                      ?
                      <div className={"page-menu-button-container" + ((this.state.active) ? " active" : "") + ((hasTitle) ? " has-title" : "")}>
                          <div className="row-menu-container">
                              <div className="page-menu-button" title={(this.state.active || this.state.open) ? "Cancel" : "Actions"} onClick={this.onToggleActionMenu}>
                                  <div className={"page-menu-button-image"}>
                                      {
                                        (this.state.open)
                                          ?
                                          <div className="row-menu-triangle"/>
                                          :
                                          ""
                                      }
                                      <img src={(this.state.open || this.state.active) ? "/images/page-menu/cancel.png" : "/images/page-menu/row-menu.png"} style={{padding:((this.state.active || this.state.open) ? "3px" : "7px")}}/>
                                  </div>
                                  <div className="page-menu-button-title">{(this.state.active) ? "" : "Actions"}</div>
                              </div>

                              <div className={"row-menu" + ((this.state.open) ? " active" : "")}>
                                  <div className="row-menu-content">
                                  {
                                      this.props.buttons.map(
                                          (button,index,arr)=>{
                                              if(!button.enabled){
                                                  return "";
                                              }
                                              return(
                                                 <div key={index} className={"row-menu-button" + ((index + 1 == arr.length) ? " last" : "")} id={index} onClick={this.onClickButton}>
                                                      {(button.mobileLabel) ? button.mobileLabel : button.label}
                                                 </div>
                                              )
                                          }
                                      )
                                  }
                                  </div>
                              </div>
                          </div>
                      </div>
                      :
                      null
                }

                <div className="page-menu-content">
                {
                    this.props.buttons.map(
                        (item,index)=>{
                            if(typeof this.props.components[item.ref] == "undefined"){
                                return (<div key={index}/>)
                            }
                            return React.createElement(
                                this.props.components[item.ref].component,
                                {
                                    key:index,
                                    settings:this.props.settings,
                                    selected:this.props.selected,
                                    onToggleForm:this.onToggleForm,
                                    ref:item.ref,
                                    reference:item.ref,
                                    ...this.props.components[item.ref].props
                                }
                          );
                        }
                    )
                }
                </div>

                <MobileMenu content={this.props.buttons} settings={this.props.settings} onClick={this.onMenuOptionClicked}/>
            </div>
        )
    }
}
PageMenu.propTypes={
    buttons:PropTypes.array.isRequired,
    settings:PropTypes.object.isRequired,
    components:PropTypes.object.isRequired,
};


/**
 *  Creates a general menu item that allows for photo upload
 *
 *  @param {string} reference
 *      ref defined in parent object
 *
 *  @param {string} fieldname
 *      returned when the file has been uploaded
 *
 *  @param {func} onToggleForm
 *      control menu button
 *
 *  @param {func} onUpload(filename,fieldname)
 *      called once file has been successfully uploaded
 *
 *  @function show(bool)
 *      show or hide this component
 */
export class UploadPhoto extends React.Component{

    state={
        active:false,
        step:0,
        isMobile:false,
        currentVideoSource:"",
        cameraVideoSourceOptions:[]
    };

    webcam=null;

    dropzone=null;

    componentDidMount=()=>{
        this.setState({isMobile:u.isMobileDevice()});
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onGoToWebcam=()=>{
        this.setState({error:"",step:1});
    }

    onChangeCamera=(value,label,index,option)=>{
        this.setState({currentVideoSource:value});
    }

    onPromptSources=(sources,selectedSource)=>{
        if(this.state.cameraVideoSourceOptions.length == 0 && sources.length > 0) {
            let s = [];
            for (var i = 0; i < sources.length; i++) {
                s.push({value: sources[i].deviceId, label: sources[i].label});
            }

            this.setState({cameraVideoSourceOptions: s, currentVideoSource: selectedSource});
        }
    }

    checkPhoto=(ref)=>{
        if(this.state.photoAdded){
            ref.onFileAdded(null,this.state.photoAdded);
            this.setState({photoAdded:undefined});
        }
    }

    onCameraCapture=()=>{
        const image = this.webcam.getScreenshot();
        this.setState({step:0,error:"",photoAdded:image});
    }

    onBack=()=>{
        this.setState({error:"",step:0});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onPhotoUploaded=(filename,callback)=>{
        u.post({
            url:this.props.url,
            data:{
                id:this.props.id,
                filename:filename
            },
            success:(url)=>{
                callback();
                this.props.onUpload(url,this.props.fieldname);
                this.props.onToggleForm(this.props.reference,false);
            },
            error:(error,status)=>{
                this.setState({error:error});
            }
        });
    }

    render=()=>{
        return (
            <Segment active={this.state.active} align="center" >
                <div>
                    {
                        (this.props.noTitle)
                            ?
                            ""
                            :
                            <div className="segment-title">Upload Photo</div>

                    }
                    <div className="segment-error">{this.state.error}</div>
                    <Step active={(this.state.step == 0)} scroll={true}>
                        <ImageDropzone ref={(c) => {this.checkPhoto(c)}} onUpload={this.onPhotoUploaded}/>
                        {
                            (!this.state.isMobile)
                                ?
                                <div className="upload-webcam" style={{padding:"20px",fontSize:"0.85em"}}><TextButton onClick={this.onGoToWebcam}>Use Webcam</TextButton></div>
                                :
                                ""
                        }
                        <div className="segment-buttons">
                            <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                        </div>
                    </Step>
                    <Step active={(this.state.step == 1)}>
                        <h3>If video is not streaming below, please make sure you have a webcam attached and refresh the page.</h3>
                        <div style={{width:"500px",maxWidth:"90%",margin:"20px auto",fontSize:"0.85em"}}>
                            <Select label="Camera Source" values={{options:this.state.cameraVideoSourceOptions,value:this.state.currentVideoSource}} onChange={this.onChangeCamera}/>
                        </div>
                        <Webcam ref={(c) => this.webcam = c} audio={false} width={640} screenshotFormat="image/png" onPromptSources={this.onPromptSources} videoSource={this.state.currentVideoSource} videoOnly="true"/>
                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" onClick={this.onBack}>Back</Button>
                            <Button type="medium" onClick={this.onCameraCapture}>Capture</Button>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
UploadPhoto.propTypes={
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]).isRequired,
    url:PropTypes.string.isRequired,
    reference:PropTypes.string.isRequired,
    fieldname:PropTypes.string.isRequired,
    onUpload:PropTypes.func.isRequired,
    onToggleForm:PropTypes.func.isRequired,
    noTitle:PropTypes.bool,
};


/**
 * Creates a remark object to be placed in a databox
 *
 * @param {array} content
 *
 * @param {func} onChange
 *
 * @param {string} id
 *      entity id
 *
 * @param {string} addURL
 *      url to push new remarks
 *
 * @param {string} deleteURL
 *      url to push delete remarks
 *
 * @param {number} permissions
 *      numbered permission value
 */
export class Remarks extends React.Component{

    state={
        error:"",
        step:0,
        loading:false
    };

    onRemoveRequest=(index)=>{
        this.removeIndex=index;
        this.setState({error:"",step:"remove"});
    }

    onRemove=(index)=>{
        this.setState({loading:true,loadText:"Removing..."});
        const remark=this.props.content[this.removeIndex];
        u.post({
            url:this.props.deleteURL,
            data:{
                id:this.props.id,
                remark:remark,
            },
            success:()=>{
                let remarks = this.props.content;
                remarks.splice(this.removeIndex,1);
                this.setState({loading:false,step:0});
                this.props.onChange(remarks,"remarks",this.props.index);
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onAddRequest=()=>{
        this.setState({step:1,error:""});
    }

    onAdded=()=>{
        this.setState({error:""});
        const remark = this.refs['newRemark'].validate();
        if(!remark.valid){
            this.setState({error:remark.value});
            return 0;
        }

        let data={
            dateTime:moment().format("YYYY-MM-DDTHH:mm:ss"),
            staffID:u.getUserID(),
            staffName:u.getFullName(),
            value:remark.value
        };

        u.post({
            url:this.props.addURL,
            data:{
                id:this.props.id,
                remark:data
            },
            success:(callback)=>{
                data = callback;
                let remarks = this.props.content;
                remarks.unshift(data);
                this.setState({loading:false,step:0});
                this.props.onChange(remarks,"remarks",this.props.index,(error)=>{
                    this.setState({error:error,loading:false});
                })
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onCancel=()=>{
        this.setState({step:0,error:""});
    }

    render=()=>{
        return(
            <div className="remark-container">
                <LoadGraphic active={this.state.loading} text="Updating database..."/>
                <Step active={(this.state.step == 0)}>
                    <div>
                        {
                            (this.props.content.length > 0)
                                ?
                                <div>
                                    <div className="remark-scroll">
                                        <div className="remark header">
                                            <div className="remark-remove"/>
                                            <div className="remark-data">Remark</div>
                                            <div className="remark-date">Created On</div>
                                            <div className="remark-staff">Created By</div>
                                        </div>
                                        {
                                            this.props.content.map(
                                                (remark,index)=>{
                                                    return (
                                                        <div className="remark" key={index}>
                                                            <div className="remark-remove">
                                                                <div style={{width:"15px",height:"15px",position:"relative"}}>
                                                                    <MiniMinusButton enabled={(this.props.permissions)} onClick={()=>{this.onRemoveRequest(index)}}/>
                                                                </div>
                                                            </div>
                                                            <div className="remark-data">
                                                                {remark.value}
                                                            </div>
                                                            <div className="remark-date">
                                                                {moment(remark.dateTime).format("DD MMM YY - HH:mma")}
                                                            </div>
                                                            <div className="remark-staff">
                                                                <Link to={"/staff/"+remark.staffID} >{remark.staffName}</Link>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                    <div style={{margin:"20px auto 0 auto",textAlign:"center"}}>
                                        <TextButton onClick={this.onAddRequest}>Add Remark</TextButton>
                                    </div>
                                </div>
                                :
                                <div className="remarks-placeholder">
                                    <h4>No Remarks Added</h4>
                                    <div style={{margin:"10px auto 0 auto",fontSize:"1em"}}>
                                        <TextButton onClick={this.onAddRequest}>Add Remark</TextButton>
                                    </div>
                                </div>
                        }
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div style={{textAlign:"center"}}>
                        <div style={{width:"700px",maxWidth:"100%",margin:"auto"}}>
                            <h2>Add Remark</h2>
                            <div className="overview-list-error">{this.state.error}</div>
                            <div><Area ref="newRemark" label="Remarks" rules={{required:true,rows:5}}/></div>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                <Button type="medium" onClick={this.onAdded}>Add</Button>
                            </div>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{textAlign:"center"}}>
                        <h2 className="text-negative">Remove Remark?</h2>
                        <div style={{margin:"10px auto 20px auto"}}>Note. This action cannot be undone</div>
                        <div style={{margin:"20px auto"}}>
                            <Button onClick={()=>{this.setState({step:0,error:""})}}>Back</Button>
                            <Button onClick={this.onRemove}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
Remarks.propTypes={
    content:PropTypes.array,
    onChange:PropTypes.func.isRequired,
    addURL:PropTypes.string.isRequired,
    deleteURL:PropTypes.string.isRequired,
    permissions:PropTypes.number.isRequired,
    id:PropTypes.string.isRequired
};


/**
 * Creates a themed acknowledgement to display at the end of segments
 *
 * @param {html} children
 *
 * @param {function} onComplete
 *
 */
export class SegmentComplete extends React.Component{

    render=()=>{
        return (
            <div className="segment-content">
                <div>
                    <img src="/images/input/confirm.png" style={{display:"inline-block",verticalAlign:"middle",width:"50px",margin:"25px"}}/>
                    <h3 style={{display:"inline-block",verticalAlign:"middle",margin:"0"}}>{this.props.children}</h3>
                </div>
                {
                    (this.props.onComplete)
                    ?
                    <div className="segment-buttons">
                        <Button type="medium" onClick={this.props.onComplete}>Close</Button>
                    </div>
                    :
                    ""
                }
            </div>
        )
    }
}
SegmentComplete.propTypes={
    onComplete:PropTypes.func
};


/**
 * Creates a themed resolution stack area
 */
export class ResolutionStack extends React.Component{

    state={active:false};

    toggleActive=()=>{
        this.setState({active:!this.state.active});
    }

    render=()=>{
        const content=this.props.content;
        return (
            <div>
                <Step active={!this.state.active}>
                    <TextButton onClick={this.toggleActive}>View Participants</TextButton>
                </Step>

                <Step active={this.state.active}>
                    <div className="resolution-stack">
                        <div style={{margin:"10px 0"}}>
                            <TextButton onClick={this.toggleActive}>Hide Participants</TextButton>
                        </div>

                        <div className="list-table-container">
                            <div className="list-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="left">Date</th>
                                            <th>Action</th>
                                            <th className="right">Participant</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            content.map(
                                                (item,index)=>{
                                                    return (
                                                        <tr key={index}
                                                            className="item">
                                                            <td>{moment(item.dateTime).format("DD MMM YYYY h:mma")}</td>
                                                            <td>{item.action}</td>
                                                            <td>
                                                                {
                                                                    (item.link)
                                                                        ?
                                                                        <Link to={item.link}>{item.user}</Link>
                                                                        :
                                                                        item.user
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}


export class ResolutionStep extends React.Component{
    render=()=>{
        const content=this.props.content;
        const isYou = (content.staffID == u.getUserID());
        return (
            <div className={"resolution-step " + ((isYou) ? "mine" : "none")}>
                <div className="step-content">
                    {content.remark}
                </div>
                <div className="step-user">
                    {(isYou) ? "You" : content.staffName} <div className="step-divider"/> {moment(content.dateTime).format("DD/MM/YY h:mma")}
                    {
                        (isYou)
                            ?
                            <span>
                                <div className="step-divider"/>
                                <TextButton style={{margin:"0"}}
                                            onClick={()=>{this.props.onDelete(this.props.index)}}>
                                            Delete
                                </TextButton>
                            </span>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}

/** OBSOLETE - CONVERTED TO TABLE FORMAT **/
/**
 * Creates a themed resolution item to place resolving staff into
 *
 * @param {object} content
 *      @var {string} label
 *           "Completed" on
 *
 *      @var {string} date
 *
 *      @var {string} staffID
 *
 *      @var {string} avatarLink
 *
 *      @var {string} name
 */
export class ResolutionItem extends React.Component{
    render=()=>{
        const content=this.props.content;
        return (
            <div className="resolution-item">
                <div className="resolution-title">{content.label}</div>
                <div>{moment(content.date).format("DD MMM YY")}</div>
                {
                    (content.staffID && content.staffID != "-1")
                        ?
                        <Link to={(content.link) ? content.link : "/staff/"+content.staffID}>
                            <img src={u.getAvatarLink(content.avatarLink)} className="resolution-image"/>
                            <div className="resolution-name">{content.name}</div>
                        </Link>
                        :
                        <div>
                            <img src={"/images/app/system.png"} className="resolution-image"/>
                            <div className="resolution-name">System</div>
                        </div>
                }
            </div>
        )
    }
}
ResolutionItem.propTypes={
    content:PropTypes.shape({
        label:PropTypes.string,
        date:PropTypes.string,
        staffID:PropTypes.string,
        link:PropTypes.string,
        avatarLink:PropTypes.string,
        name:PropTypes.string
    })
};

/**
 * Themed photo upload dropzone, different from image dropzone which allows users to edit image
 * Photo dropzone allows users to append comments and stores photo without editting aspect ratio
 *
 * @param {function} onCancel
 *
 * @param {function} onAdded
 */
export class PhotoDropzone extends React.Component{

    state={
        error:"",
        step:0,
        scale:120,
        rotate:0,
        loading:false,
        loadText:"Uploading...",
        dimensions:{width:512,height:512},
        ratingOptions:[],
    };

    onDropzoneError=(error)=>{
        this.setState({error:error,loading:false});
    }

    onAdded=(filename,file)=>{
        this.setState({step:1,error:"",src:file,userFilename:filename});
    }

    onBack=(step)=>{
        this.setState({step:parseInt(step),error:""});
    }

    onAddPhoto=()=>{
        this.setState({error:""});

        let comments = this.refs['photoComments'].validate();
        if(!comments.valid){
            this.setState({error:comments.value});
            return 0;
        }

        const others = (this.refs['photoCommentsOthers']) ? this.refs['photoCommentsOthers'].validate() : {valid:true};
        if(!others.valid){
            this.setState({error:others.value});
            return 0;
        }

        const category=(this.refs['photoCategory']) ? this.refs['photoCategory'].validate() : {};

        this.setState({loading:true});

        this.refs["editor"].getImageScaledToCanvas().toBlob((blob)=>{
            let f = new File([blob],"editted-image.jpg",{type:"image/jpeg",lastModifier:moment().format("YYYY-MM-DD")});
            this.uploadEvent = u.upload({
                file:f,
                data:{
                    accessibleForPreview:true,
                },
                uploadProgress:(e)=>{
                    if (e.lengthComputable) {
                        let loaded = Math.round((e.loaded / e.total) * 100);
                        this.setState({loadText:"Uploading " + loaded + "%..."});
                    }},
                success:(filename)=>{
                    let photo = {
                        filename:filename,
                        comments:(others.value) ? others.value : comments.value,
                        severity:(comments.option) ? comments.option.severity : undefined,
                        chargeType:(comments.option) ? comments.option.chargeType : undefined,
                        category:category.value,
                        src:this.state.src,
                        userFilename:this.state.userFilename,
                    };
                    this.props.onAdded(photo);
                    this.setState({loading:false,step:0});
                },
                error:(error,status)=>{
                    this.setState({error:error,loading:false});
                }
            });
        },"image/jpeg",1);
    }

    onCancel=()=>{
        this.props.onCancel(this.props.index);
    }

    onChangeCategory=(value,label,index,option)=>{
        this.setState({photoCategory:value,ratingOptions:option.ratingOptions});
    };

    onChangePhotoComments=(value)=>{
        this.setState({photoComments:value});
    }

    render=()=>{
        let {width, height} = this.state.dimensions;
        if(width > 512){
            width=512;
        }
        return(
            <div style={{position:"relative"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="page-error">{this.state.error}</div>
                <Step active={(this.state.step==0)}>
                    <div style={{position:"relative",textAlign:"center"}}>
                        <h3>Add Photo</h3>
                        <Dropzone onError={this.onDropzoneError} accessibleForPreview={false} filetypes={Photo} label="Photo Upload" onAdded={this.onAdded} noUpload={true}/>
                        {
                            (this.props.onCancel)
                                ?
                                <div style={{margin:"20px auto"}}>
                                    <Button type="medium" onClick={this.onCancel}>Back</Button>
                                </div>
                                :
                                ""
                        }
                    </div>
                </Step>
                <Step active={(this.state.step==1)}>
                    <div style={{textAlign:"center"}}>
                        <h3>Add Photo</h3>

                        <Editor ref="editor"
                                image={this.state.src}
                                width={width-20}
                                height={width-20}
                                border={10}
                                color={[255, 0, 0, 0.5]} // RGBA
                                scale={this.state.scale/100}
                                rotate={this.state.rotate}
                                />

                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>(
                                    <div ref={measureRef} style={{width:"532px", maxWidth:"100%",margin:"auto"}}/>
                                )
                            }
                        </Measure>

                        <div style={{width:"532px", maxWidth:"100%",margin:"auto"}}>
                            <div className="image-dropzone-slider" style={{margin:"10px auto"}}>
                                <div className="image-dropzone-slider-title">Zoom</div>
                                <ProgressSlider min={50} max={300} value={this.state.scale} onChange={(value)=>{this.setState({scale:value})}} />
                            </div>
                            <div className="image-dropzone-slider" style={{margin:"10px auto"}}>
                                <div className="image-dropzone-slider-title">Rotate</div>
                                <ProgressSlider min={-180} max={180} value={this.state.rotate} onChange={(value)=>{this.setState({rotate:value})}}/>
                            </div>
                            {
                                (this.props.rating)
                                    ?
                                    <div>
                                        <div>
                                            <Select ref="photoCategory" label="Category" values={{options:CenturionInspectionOptions}} onChange={this.onChangeCategory}/>
                                        </div>

                                        <div style={{margin:"10px auto 0 auto"}}>
                                            <Select ref="photoComments" label="Photo Comments" field="photoComments" values={{options:this.state.ratingOptions}} rules={{defaultValue:"Passed inspection standard"}} onChange={this.onChangePhotoComments}/>
                                        </div>

                                        {
                                            (this.state.photoComments == "Others")
                                                ?
                                                <div style={{margin:"5px auto 0 auto"}}>
                                                    <Box ref="photoCommentsOthers" label="Photo Comment Others" rules={{required:true}}/>
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                    :
                                    <div>
                                        <Area ref="photoComments" label="Photo Comments" rules={{rows:5,placeholder:"Please add some comments to describe this photo..."}}/>
                                    </div>
                            }
                        </div>

                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" index="0" onClick={this.onBack}>Back</Button>
                            <Button type="medium" onClick={this.onAddPhoto}>Add</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
PhotoDropzone.propTypes={
    onCancel:PropTypes.func,
    index:PropTypes.string,
    onAdded:PropTypes.func.isRequired,
    rating:PropTypes.bool,
};


export class EmptyAvatar extends React.Component{

    state = {
        value:"",
        color:"",
        borderColor:{},
    };

    componentDidMount=()=>{
        //Select Random Color
        const value = this.props.value.substr(0,1).toUpperCase();
        const colors = [
            "#3DB34C",
            "#B32B4F",
            "#4C31B8",
            "#FB8AFF",
            "#3387B8",
            "#FF7096",
            "#45ABB8",
        ];
        const colorIndex = parseInt(value.charCodeAt(0)) % colors.length;
        const color = colors[colorIndex];
        const borderColor = u.hexToRgb(color);
        this.setState({value:value,color:color,borderColor:borderColor});
    };

    render=()=>{
        return (
            <div style={{position:"relative",width:"30px",height:"30px",display:"flex",alignItems:"center",justifyContent:"center",backgroundColor:this.state.color,borderRadius:"50%"}}>
                <div style={{color:"white",display:"inline-block",fontSize:"1em",margin:"auto"}}>{this.state.value}</div>
            </div>
        )
    }
}
EmptyAvatar.propTypes={
    value:PropTypes.string,
};


export class DynamicListArea extends React.Component{
    state={
        instantiated:false,
        items:[]
    };

    componentDidMount=()=>{
        let permissions = u.getPermissions(this.props.permission);
        let listItems = store.get("listItems");

        if(listItems[this.props.listLabel]){
            const listItem = listItems[this.props.listLabel];
            this.setState({
              instantiated:true,
              settings:listItem.settings,
              items:listItem.content,
              permissions: (permissions.update == 2) || (permissions[this.props.combinedField] == 2),
            })
        }
    }

    static checkHide (content,settings){
        if(settings.hideWhen){
            for(var i=0; i<settings.hideWhen.length; i++){
                const field = settings.hideWhen[i].field;
                const value = settings.hideWhen[i].value;
                const not = settings.hideWhen[i].not;

                if(not && content[field] != value){
                    return true;
                }
                else if(!not && content[field] == value){
                    return true;
                }
            }
        }
        return false;
    }

    static checkDisable (content,settings){
        let disable = false;

        if(settings.disableWhen){
            for(var i=0; i<settings.disableWhen.length; i++){
                if(i>0 && !disable){
                    return false;
                }

                const field = settings.disableWhen[i].field;
                const value = settings.disableWhen[i].value;
                const not = settings.disableWhen[i].not;

                if(not && content[field] != value){
                    disable = true;
                }
                else if(!not && content[field] == value){
                    disable = true;
                }
                else{
                    disable = false;
                }
            }
        }

        return disable;
    }

    render=()=>{
        if(!this.state.instantiated){
          return (
              <div/>
          )
        }

        const content = this.props.content;
        const settings=this.state.settings;
        const enabled=this.state.permissions;
        const items=this.state.items;
        return (
            <div className="dynamic-list">
                {
                  items.map(
                      (section,sectionIndex,arr)=>{
                          return (
                              <div key={sectionIndex} className={"list-segment" + ((arr.length == 2) ? " extended" : "") + ((section.length > 0) ? ((section[0].dataType=="image") ? " image" : "") : "")}>
                                  {
                                    section.map(
                                        (item,index)=>{
                                            if(DynamicListArea.checkHide(content,item)){
                                                return null;
                                            }

                                            return(
                                                <DynamicListItem key={index}
                                                                 settings={settings}
                                                                 content={content}
                                                                 fields={item}
                                                                 enabled={enabled && !DynamicListArea.checkDisable(content,item)}
                                                                 onChange={this.props.onChange}
                                                                 index={this.props.index}
                                                                 />
                                            )
                                        }
                                    )
                                  }
                              </div>
                          )
                      }
                  )
                }
            </div>
        )
    }
}
DynamicListArea.propTypes={
    content:PropTypes.object.isRequired,
    listLabel:PropTypes.string.isRequired,
    permission:PropTypes.string.isRequired,
    onChange:PropTypes.func.isRequired,
    index:PropTypes.number.isRequired,
};


/**
 * Creates a themed list segment item that supports generic setup of certain components
 *
 * @param {object} settings
 *      @var {string} url
 *
 *      @var {array} id
 *
 * @param {object} content
 *      respective content object
 *
 * @param {object} fields
 *      @var {string} dataType
 *          image,label,link,box,select,date,time,area
 *
 *      @var {string} label
 *
 *      @var {string} field
 *
 *      @var {object} rules
 *
 *      @var {string} options
 *          Select only - [{value,label}]
 *
 *      @var {string} type
 *          Box only - defaults to text, email, password, number
 *
 *      @var {array} range
 *          Date only - defaults to [5,5)
 *
 */
class DynamicListItem extends React.Component{

    render=()=>{
        const settings = this.props.settings;
        const content = this.props.content;
        const fields = this.props.fields;
        const enabled = (typeof fields.enabled != "undefined") ? (fields.enabled && this.props.enabled) : this.props.enabled;

        let id = (settings.id.length == 1) ? content[settings.id[0]] : {};
        if(typeof id == "object"){
          for(var i=0; i<settings.id.length; i++){
            id[settings.id[i]] = content[settings.id[i]];
          }
        }

        if(fields.dataType == "image"){
            return <ListSegmentPhoto image={u.getAvatarLink(content[fields.field])}/>
        }

        let to = fields.to;
        if(fields.dataType == "link"){
            while(to.indexOf("%(") != -1){
                const start = to.indexOf("%(");
                const end = to.indexOf(")%");
                const contentField = to.substring(start + 2, end);
                to = to.replace("%(" + contentField + ")%" , content[contentField]);
            }
        }

        return (
            <div className="list-segment-item">
                <div className="list-segment-label">
                    <div style={{position:"relative",display:"inline-block"}}>
                        {fields.label}:
                        {fields.info ? <Info fill={true} title={fields.info}/> : null}
                    </div>
                </div>
                <div className="list-segment-input">
                    {
                        (fields.dataType == "label")
                            ?
                            <EditSizeLabel>
                              {
                                  (fields.specialType == "datetime" || fields.specialType == "dateTime")
                                      ?
                                      (
                                        (moment(content[fields.field]).isValid())
                                          ?
                                          moment(content[fields.field]).format("DD MMM YYYY - h:mma")
                                          :
                                          "None"
                                      )
                                      :
                                      (
                                        (content[fields.field])
                                          ?
                                          content[fields.field]
                                          :
                                          "None"
                                      )
                              }
                            </EditSizeLabel>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "link")
                            ?
                            <EditSizeLink>{(content[fields.field]) ? <Link to={to}>{content[fields.field]}</Link> : null}</EditSizeLink>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "box")
                            ?
                            <EditableBox values={{value:content[fields.field],enabled:enabled}} type={fields.type} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "contactNumber")
                            ?
                            <EditableContactNumber countryCodeField={fields.countryCodeField} contactNumberField={fields.contactNumberField} values={{contactNumber:content[fields.contactNumberField],countryCode:content[fields.countryCodeField],enabled:enabled}} type={fields.type} rules={fields.rules} id={id} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "select")
                            ?
                            <EditableSelect values={{value:content[fields.field],options:fields.options,enabled:enabled}} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "date")
                            ?
                            <EditableDate values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "time")
                            ?
                            <EditableTime values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "area")
                            ?
                            <EditableArea values={{value:content[fields.field],enabled:enabled}} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "dayOfWeek")
                            ?
                            <EditableDOW values={{value:content[fields.field],enabled:enabled}} rules={fields.rules} field={fields.field} id={id} label={fields.label} url={settings.url} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "tags")
                            ?
                            <EditSizeLabel labelStyle={{padding:"2px 5px",maxHeight:"33px",overflow:"auto"}}>
                                {
                                    (content[fields.field].length > 0)
                                      ?
                                      content[fields.field].map(
                                          (item,index)=>{
                                              return (
                                                  <Tag key={index}
                                                       index={index + ""}
                                                       clickable={false}
                                                       label={item}
                                                       onRemove={()=>{}}
                                                       values={{removable:false}}
                                                  />
                                              )
                                          }
                                      )
                                      :
                                      "None"
                                }
                            </EditSizeLabel>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}
DynamicListItem.propTypes={
    settings:PropTypes.object.isRequired,
    fields:PropTypes.object.isRequired,
    content:PropTypes.object,
    enabled:PropTypes.bool.isRequired,
};
DynamicListItem.defaultProps={
    settings:{},
    content:{},
    fields:{}
};


export class DynamicDetailArea extends React.Component{
    state={
        instantiated:false,
        details:[]
    };

    componentDidMount=()=>{

        let permissions = u.getPermissions(this.props.settings.permission);

        let details = store.get("details");

        if(details[this.props.settings.label]){
            const detail = details[this.props.settings.label];

            this.setState({
              instantiated:true,
              settings:detail.settings,
              details:detail.content,
              permissions: (permissions.update == 2) || (permissions[this.props.settings.combinedField] == 2),
            })
        }
    }

    render=()=>{
        if(!this.state.instantiated){
          return (
              <div/>
          )
        }

        const content = this.props.content;
        const settings=this.state.settings;
        const details=this.state.details;
        let id = (settings.id.length == 1) ? ((content[settings.id[0]]) ? content[settings.id[0]] : "loading") : {};
        if(typeof id == "object"){
          for(var i=0; i<settings.id.length; i++){
              id[settings.id[i]] = content[settings.id[i]];
          }
        }

        const databoxSetting = {
            url:settings.url,
            enabled:(this.state.permissions),
            onChange:this.props.onChange,
            id:id
        };

        return (
            <div className="dynamic-details">
                {
                  details.map(
                      (section,sectionIndex,arr)=>{
                          return (
                              <Databox active={true} key={sectionIndex} title={section.header} margin={(sectionIndex == 0 ) ? "0" : "50px 0"}>
                                  {
                                    section.details.map(
                                        (item,index)=>{
                                            if(DynamicListArea.checkHide(content,item)){
                                                return null;
                                            }

                                            let enabled = item.enabled;
                                            if(DynamicListArea.checkDisable(content,item)){
                                                enabled=false;
                                            }

                                            return(
                                                <DataboxGeneral key={index}
                                                                settings={databoxSetting}
                                                                content={content}
                                                                fields={{
                                                                  ...item,
                                                                  enabled:enabled
                                                                }}
                                                                />
                                            )
                                        }
                                    )
                                  }
                              </Databox>
                          )
                      }
                  )
                }
            </div>
        )
    }
}
DynamicDetailArea.propTypes={
    content:PropTypes.object.isRequired,
    settings:PropTypes.object.isRequired,
    onChange:PropTypes.func.isRequired,
};


/**
 * Creates a themed databox item that supports generic setup of certain components
 *
 * @param {object} settings
 *      @var {string} url
 *
 *      @var {bool} enabled
 *
 *      @var {function} onChange
 *
 *      @var {object || string} id
 *
 * @param {object} content
 *      respective content object
 *
 * @param {object} fields
 *      @var {string} dataType
 *          image,label,link,box,select,date,time,area
 *
 *      @var {string} label
 *
 *      @var {string} field
 *
 *      @var {string} value
 *
 *      @var {object} rules
 *
 *      @var {string} to
 *
 *      @var {string} options
 *          Select only
 *
 *      @var {string} type
 *          Box only - defaults to text
 *
 *      @var {array} range
 *          Date only - defaults to [5,5]
 *
 */
export class DataboxGeneral extends React.Component{

    checkEnabled=(content,fields,settings)=>{

        let allDisable = {};
        if(fields.disableWhen){
            for(var key in fields.disableWhen){
                allDisable[key] = false;
                if(typeof fields.disableWhen[key] == "object"){
                    for(var i=0;i<fields.disableWhen[key].length; i++){
                        if(content[key] == fields.disableWhen[key][i]){
                           allDisable[key] = true;
                        }
                    }
                }
                else{
                  if(content[key] == fields.disableWhen[key]){
                      allDisable[key] = true;
                  }
                }
            }

            let checkAll = true;
            for(var key in allDisable){
                if(allDisable[key]==false){
                    checkAll = false;
                }
            }

            if(checkAll){
                return false;
            }
        }

        return (typeof fields.enabled != "undefined") ? fields.enabled : settings.enabled;
    }

    render=()=>{
        const settings = this.props.settings;
        const content = this.props.content;
        const fields = this.props.fields;
        const rules = this.props.fields.rules;
        const enabled = this.checkEnabled(content,fields,settings);
        const title = (enabled) ? fields.tooltip : ((fields.disabledToolTip) ? fields.disabledTitle : fields.title);

        if(fields.dataType == "image"){
            return (
              <div className="data-box-photo">
                  <div className="data-box-photo-left"/>
                  <div className="data-box-photo-background">
                      <div className="data-box-photo-background-cover"/>
                      <img src={content[fields.field]} className="data-box-image"/>
                  </div>
                  <div className="data-box-photo-right"/>
              </div>
            )
        }

        let to = fields.to;
        if(fields.dataType == "link"){
            while(to.indexOf("%(") != -1){
                const start = to.indexOf("%(");
                const end = to.indexOf(")%");
                const contentField = to.substring(start + 2, end);
                to = to.replace("%(" + contentField + ")%" , content[contentField]);
            }
        }

        return (
            <div className="data-box-item" title={title}>
                <div className="data-box-item-label">
                    <div style={{display:"inline-block",verticalAlign:"middle"}}>
                        {fields.label}
                    </div>
                    {(title) ? <Info title={title}/> : ""}
                </div>
                <div className="data-box-item-input">
                    {
                        (fields.dataType == "label")
                            ?
                            <EditSizeLabel nullText="None">{(fields.specialType == "datetime" || fields.specialType=="dateTime") ? moment(content[fields.field]).format("DD MMM YYYY - h:mma") : content[fields.field]}</EditSizeLabel>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "link")
                            ?
                            <EditSizeLink>
                                {
                                    (content[fields.field])
                                        ?
                                        <Link to={to}>{content[fields.field]}</Link>
                                        :
                                        "None"
                                }
                            </EditSizeLink>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "box")
                            ?
                            <EditableBox values={{value:content[fields.field],enabled:enabled}} type={fields.type} rules={rules} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "contactNumber")
                            ?
                            <EditableContactNumber contactNumberField={fields.contactNumberField} countryCodeField={fields.countryCodeField} values={{contactNumber:content[fields.contactNumberField],countryCode:content[fields.countryCodeField],enabled:enabled}} type={fields.type} rules={rules} id={settings.id} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "select")
                            ?
                            <EditableSelect values={{value:content[fields.field],options:fields.options,enabled:enabled}} rules={rules} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "date")
                            ?
                            <EditableDate values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={rules} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "time")
                            ?
                            <EditableTime values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={rules} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "area")
                            ?
                            <EditableArea values={{value:content[fields.field],enabled:enabled}} rules={rules} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.dataType == "tags")
                            ?
                            <EditSizeLabel labelStyle={{padding:"2px 5px",maxHeight:"33px",overflow:"auto"}}>
                                {
                                    (content[fields.field].length > 0)
                                      ?
                                      content[fields.field].map(
                                          (item,index)=>{
                                              return (
                                                  <Tag key={index}
                                                       index={index + ""}
                                                       clickable={false}
                                                       label={item}
                                                       onRemove={()=>{}}
                                                       values={{removable:false}}
                                                  />
                                              )
                                          }
                                      )
                                      :
                                      "None"
                                }
                            </EditSizeLabel>
                            :
                            null
                    }
                </div>
            </div>
        )
    }

}
DataboxGeneral.propTypes={
    settings:PropTypes.object.isRequired,
    fields:PropTypes.object.isRequired,
    content:PropTypes.object,
};
DataboxGeneral.defaultProps={
    settings:{},
    content:{},
    fields:{}
};


/**
 * Creates a page menu item for deleting list entries.
 *
 * @param {string} url
 *      url to api call for delete
 *
 * @param {string} reference
 *      duplicate of components ref
 *
 * @param {object} selected
 *      @var {bool} all
 *
 *      @var {array} data
 *
 *      @var {number} entryCount
 *
 *      @var {array} tags
 *
 * @param {function} onToggleForm
 *
 * @param {function} onRefreshList
 *
 */
export class DeleteListItem extends React.Component{
    state={
        active:false,
        step:0,
        error:"",
        loading:false
    };

    show=(show)=>{
        this.setState({active:show,error:""});
    }

    onCancel=(index)=>{
        this.props.onToggleForm(this.props.reference,false);
        this.setState({step:0,error:""});
    }

    onDelete=(index)=>{
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to be deleted"});
            return 0;
        }
        this.setState({step:1,error:"",numEntries:numEntries});
    }

    onConfirm=(index)=>{
        this.setState({loading:true,error:""});
        u.post({
            url:this.props.settings.deleteURL,
            data:{
                selected:this.props.selected
            },
            success:(callback)=>{
                this.setState({step:2,loading:false,error:""});
                setTimeout(()=>{
                    if(this.props.settings.overview){
                        this.props.settings.overview.onRefreshList();
                    }
                    if(this.props.onRefreshList){
                        this.props.onRefreshList();
                    }
                },300);
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;

        return (
            <Segment ref="segment" active={this.state.active} align="center" >
                <div className="list-delete">
                    <div className="segment-title">Delete Entries</div>
                    <div className="segment-error">{this.state.error}</div>
                    <Step active={(this.state.step == 0)}>
                        <div className="segment-content">
                            <h3>Please select the entries you wish to permanently delete</h3>
                            <div className="segment-selected-bg">
                                <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                <div className="segment-note">Select entries from the list below</div>
                            </div>
                            <div className="segment-buttons">
                                <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onDelete}>Delete</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == 1)}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Deleting entries..."/>
                            <h3>Confirm that you wish to permanently delete {entries} {(entries==1) ? "entry" : "entries"}?</h3>
                            <div className="segment-note"><i>Note: This action is irreversible. Please double check before deleting any entries</i></div>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                <Button type="medium" onClick={this.onConfirm}>Confirm</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == 2)}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Deleting entries..."/>
                            <h3>Successfully deleted {this.state.numEntries} {(entries==1) ? "entry" : "entries"}</h3>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onCancel}>Reset</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
DeleteListItem.propTypes={
    reference:PropTypes.string.isRequired,
    selected:PropTypes.object.isRequired,
    settings:PropTypes.object.isRequired,
};


/**
 * Creates a page menu item for deleting list entries.
 *
 * @param {string} url
 *      url to api call for delete
 *
 * @param {string} reference
 *      duplicate of components ref
 *
 * @param {object} selected
 *      @var {bool} all
 *
 *      @var {array} data
 *
 *      @var {number} entryCount
 *
 *      @var {array} tags
 *
 * @param {function} onToggleForm
 *
 * @param {function} onRefreshList
 *
 */
export class GenerateSpreadsheet extends React.Component{

    state={
        active:false,
        step:"select",
        error:"",
        estimation:0,
        options:[],
        filename:"",
        filestamp:"",
        loading:false,
        macros:[],
        selectedMacro:"none",
        ordered:[],
    };

    componentDidMount=()=>{
        const ezspreadsheets=store.get("ezspreadsheets");
        const ezspreadsheetMacros=store.get("ezspreadsheetMacros");
        if(ezspreadsheetMacros[this.props.settings.listLabel]){
            let macros = ezspreadsheetMacros[this.props.settings.listLabel].map((item)=>{
                item.value=item.label;
                return item;
            });
            macros.unshift({label:"None",value:"none"});
            this.setState({macros:macros});
        }

        this.setState({options:ezspreadsheets[this.props.settings.listLabel]});
    }

    show=(show)=>{
        this.setState({active:show,error:"",step:"select"});
    }

    onResize=()=>{

    }

    onCancel=(index)=>{
        this.props.onToggleForm(this.props.reference,false);
        this.setState({step:"select",error:""});
    }

    onChangeOption=(value,label,index)=>{
        let options = this.state.options;
        if(value == true && (options[index].exclusive)){
            for(var i=0; i<options.length; i++){
                if(i != index && options[i].exclusive == options[index].exclusive){
                    options[i].selected=false;
                }
            }
        }
        options[index].selected=value;
        this.setState({options:options});
    }

    onOrder=()=>{
        this.setState({error:""});
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        const options = this.state.options.filter((item)=>{
            return (item.selected);
        });

        if(options.length == 0){
            this.setState({error:"Please select at least 1 field to display on spreadsheet"});
            return 0;
        }

        options.sort(
          (a,b)=>{
            if(typeof a.order == "undefined" && typeof b.order != "undefined"){
                return 1;
            }
            else if(typeof a.order != "undefined" && typeof b.order == "undefined"){
                return -1;
            }
            return a.order-b.order
          }
        );

        const ordered = options.map(
          (item,index)=>{
            item.order=index+1;
            return item;
        });

        this.setState({step:"order",error:"",ordered:JSON.parse(JSON.stringify(ordered))});
    }

    onConfirm=()=>{
        this.setState({error:""});
        let macroName = undefined;
        if(this.refs['macroName']){
            const mn = this.refs['macroName'].validate();
            if(!mn.valid){
                this.setState({error:mn.value})
                return 0;
            }
            macroName=mn.value;
        }

        this.setState({loading:true,saveMacro:false,error:""});
        u.post({
            url:this.props.settings.spreadsheetURL,
            data:{
                selected:this.props.selected,
                fields:this.state.ordered,
                macroName:macroName,
            },
            success:(callback)=>{
                this.setState({selectedMacro:"none"});
                //Save to cookies
                if(macroName){
                  let ezspreadsheetMacros = store.get("ezspreadsheetMacros");
                  if(typeof ezspreadsheetMacros[this.props.settings.listLabel] == "undefined"){
                      ezspreadsheetMacros[this.props.settings.listLabel] = [];
                  }

                  const ezs = ezspreadsheetMacros[this.props.settings.listLabel];
                  for(var i=0; i<ezs.length; i++){
                      if(ezs[i].label == macroName){
                          ezs.splice(i,1);
                      }
                  }

                  ezspreadsheetMacros[this.props.settings.listLabel].push({
                      label:macroName,
                      fields:this.state.ordered
                  });

                  store.set("ezspreadsheetMacros",ezspreadsheetMacros);

                  let macros = this.state.macros;
                  if(macros.length == 0){
                    macros.unshift({label:"None",value:"none"});
                  }

                  for(var m=0; m<macros.length; m++){
                      if(macros[m].label == macroName){
                          macros.splice(m,1);
                      }
                  }

                  macros.push({
                      value:macroName,
                      label:macroName,
                      fields:this.state.ordered
                  });
                  this.setState({macros:macros});
                }

                if(callback.ready){
                    u.download(
                        callback.filename,
                        callback.filestamp,
                        ()=>{
                            this.setState({step:"success",filestamp:callback.filestamp,filename:callback.filename,loading:false});
                        }
                        ,(error,status)=>{
                            this.setState({error:error,loading:false});
                        },
                        {serverURL:callback.serverURL}
                        );
                }

                else{
                    this.setState({step:"pending",estimation:callback.estimation,loading:false});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDownload=(index)=>{
        u.download(this.state.filename,this.state.filestamp,
            (callback)=>{},
            (error,status)=>{
                this.setState({error:error});
            });
    }

    onChangeMacros=(value,label,index,option)=>{
        const fields = option.fields;
        let options = this.state.options.map(
          (item)=>{
              const fieldArray = (value == "none") ? [] : fields.filter(
                  (field)=>{
                    return (field.column == item.column);
                  }
              );
              const selected = (fieldArray.length > 0);
              item.selected = selected;
              item.order = (selected) ? fieldArray[0].order : undefined;
              return item;
            }
        );
        this.setState({options:options,selectedMacro:value});
    }

    onDragStart=(event,index)=>{
        event.dataTransfer.setData("text",index+"");
        event.currentTarget.id = "drag";
        this.dragIndex=index;
    }

    onDrop=(event,index)=>{
        const dragTarget = event.dataTransfer.getData("text");
        const dropTarget = index;

        event.currentTarget.id = "none";

        if(dragTarget != dropTarget){
          let ordered = this.state.ordered;

          //sort
          const entry = ordered[dragTarget];
          ordered.splice(dragTarget,1);
          ordered.splice(((dropTarget > dragTarget) ? dropTarget - 1 : dropTarget),0,entry);

          for(var i=0; i<ordered.length; i++){
              ordered[i].order = i+1;
          }

          this.setState({ordered:ordered});
        }
    }

    onDragHover=(event,index,dragover)=>{
        event.stopPropagation();
        event.preventDefault();
        if(index == this.dragIndex){
            return 0;
        }
        if(dragover){
            event.currentTarget.id = "hover";
        }
        else{
            event.currentTarget.id = "none";
        }
    }

    onDragEnd=(event,index)=>{
        const drag = document.getElementById("drag");
        if(drag){
          drag.id = "none";
        }
    }

    onDeleteMacro=()=>{
        const macros = this.state.macros;
        const selected = this.state.selectedMacro;
        let index=0;
        for(var i=0; i<macros.length; i++){
            if(macros[i].value == selected){
                index = i;
            }
        }

        u.post({
          url:"/api/delete-spreadsheet-macro",
          data:{
              list:this.props.settings.listLabel,
              macro:this.state.macros[index]
          },
          success:()=>{},
          error:(error)=>{
              this.setState({error:error});
          }
        });

        macros.splice(index,1);
        this.setState({macros:macros});
        this.onChangeMacros("none","None",undefined,{value:"none",label:"None"});

        let ezspreadsheetMacros = store.get("ezspreadsheetMacros");
        ezspreadsheetMacros[this.props.settings.listLabel]=macros.slice(1);
        store.set("ezspreadsheetMacros",ezspreadsheetMacros);
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const divMap = (this.state.options.length == 0) ? [] : u.mapArrayToDivisions(this.state.options.length);
        return (
            <Segment ref="segment" style={this.props.segmentStyle} active={((this.props.active) ? this.props.active : this.state.active)} align="center" ignoreScroll={this.props.ignoreScroll}>
                <div className={"list-spreadsheet" + ((this.props.miniList)  ? " in-db" : "")}>
                    {
                        (this.props.miniList)
                          ?
                          null
                          :
                          <div className="segment-title">Convert to Spreadsheet</div>
                    }

                    <Step active={(this.state.step == "select")}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Preparing spreadsheet..."/>

                            <div className="spreadsheet-options" style={{padding:((this.props.miniList) ? "0px" : undefined)}}>
                                <h3 style={{marginTop:((this.props.miniList) ? "0px" : undefined)}}>Select your spreadsheet requirements</h3>
                                {
                                    divMap.map(
                                        (range,index)=>{
                                            return (
                                                <div key={index} className="spreadsheet-option-division">
                                                    {
                                                        (this.state.options.map(
                                                            (option,oIndex)=>{
                                                                return((oIndex >= range.start && oIndex < range.end)
                                                                        ?
                                                                        <div className="spreadsheet-option" key={oIndex}>
                                                                            <div className="spreadsheet-option-input"><Checkbox label="spreadsheet-option" index={oIndex+""} values={{value:option.selected}} onChange={this.onChangeOption}/></div>
                                                                            <div className="spreadsheet-option-label">{option.label}</div>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                )
                                                            }
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    )
                                }
                                {
                                  (this.state.macros.length > 0)
                                    ?
                                    <div className="segment-full-item" style={{fontSize:"1em",marginTop:"30px"}}>
                                        <div className="segment-item-input">
                                            <div className="small" style={{textAlign:"center"}}>Macros:</div>
                                            <Select label="macros" values={{value:this.state.selectedMacro,options:this.state.macros}} onChange={this.onChangeMacros}/>
                                            <div className="small" style={{textAlign:"right"}}>
                                            {
                                              (this.state.selectedMacro != "none")
                                                ?
                                                <TextButton style={{margin:0}} onClick={this.onDeleteMacro}>Delete Macro</TextButton>
                                                :
                                                ""
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                            {
                                (this.props.miniList)
                                    ?
                                    null
                                    :
                                    <div className="segment-selected-bg">
                                        <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                        <div className="segment-note">Select entries from the list below</div>
                                    </div>
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className={(this.props.miniList) ? "" : "mobile-only"}><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onOrder}>Next</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step=="order")} scroll={(this.props.ignoreScroll) ? false : true}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Preparing spreadsheet..."/>
                            <h3>Drag and drop to re-order spreadsheet columns</h3>
                            <div className="spreadsheet-options order">
                                {
                                    this.state.ordered.map(
                                        (item,index,arr)=>{
                                            return(
                                                <div className={"spreadsheet-order" + ((index == 0) ? " first" : "")}
                                                     key={index} draggable={true}
                                                     onDragEnd={(event)=>{this.onDragEnd(event,index)}}
                                                     onDragStart={(event)=>{this.onDragStart(event,index)}} onDrop={(event)=>{this.onDrop(event,index)}}
                                                     onDragOver={(event)=>{this.onDragHover(event,index,true)}} onDragLeave={(event)=>{this.onDragHover(event,index,false)}}
                                                     >
                                                     <div className="drop-hover"/>
                                                     <div className={"label" + ((index == arr.length - 1) ? " last" : "")}>{item.label}</div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>

                            <br/>

                            <div className={((this.state.saveMacro) ? "segment-selected-bg" : "segment-buttons")}>
                                <div className="save">
                                    <Checkbox values={{value:this.state.saveMacro}} onChange={(value)=>{this.setState({saveMacro:value})}}/> Save As Macro
                                </div>
                                {
                                  (this.state.saveMacro)
                                    ?
                                    <div className="segment-full-item" style={{margin:"20px auto"}}>
                                        <div className="segment-item-input">
                                            Macro Name:
                                            <Box ref="macroName" label="Macro name" rules={{required:true}}/>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"select"})}}>Back</Button>
                                <Button type="medium" onClick={this.onConfirm}>Next</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "success")}>
                        <div className="segment-content">
                            <h3>Spreadsheet successfully generated</h3>
                            <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={this.onDownload}>here</span></i></div>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "pending")}>
                        <div className="segment-content">
                            <h3>This spreadsheet will take <span className="segment-bad-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <i>Note: A download link will be sent to your email upon completion</i>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
GenerateSpreadsheet.propTypes={
    reference:PropTypes.string.isRequired,
    selected:PropTypes.object.isRequired,
    settings:PropTypes.object.isRequired,
};

/**
 * Creates a page menu item for deleting list entries.
 *
 * @param {string} url
 *      url to api call for delete
 *
 * @param {string} reference
 *      duplicate of components ref
 *
 * @param {object} selected
 *      @var {bool} all
 *
 *      @var {array} data
 *
 *      @var {number} entryCount
 *
 *      @var {array} tags
 *
 * @param {function} onToggleForm
 *
 * @param {function} onRefreshList
 *
 */
export class CPGenerateSpreadsheet extends React.Component{

    state={
        active:false,
        step:"select",
        error:"",
        estimation:0,
        options:[],
        filename:"",
        filestamp:"",
        loading:false,
        macros:[],
        selectedMacro:"none",
        ordered:[],
    };

    componentDidMount=()=>{
        const ezspreadsheets=store.get("ezspreadsheets");
        const ezspreadsheetMacros=store.get("ezspreadsheetMacros");
        if(ezspreadsheetMacros[this.props.settings.listLabel]){
            let macros = ezspreadsheetMacros[this.props.settings.listLabel].map((item)=>{
                item.value=item.label;
                return item;
            });
            macros.unshift({label:"None",value:"none"});
            this.setState({macros:macros});
        }

        this.setState({options:ezspreadsheets[this.props.settings.listLabel]});
    }

    show=(show)=>{
        this.setState({active:show,error:"",step:"select"});
    }

    onResize=()=>{

    }

    onCancel=(index)=>{
        this.props.onToggleForm(this.props.reference,false);
        this.setState({step:"select",error:""});
    }

    onChangeOption=(value,label,index)=>{
        let options = this.state.options;
        if(value == true && (options[index].exclusive)){
            for(var i=0; i<options.length; i++){
                if(i != index && options[i].exclusive == options[index].exclusive){
                    options[i].selected=false;
                }
            }
        }
        options[index].selected=value;
        this.setState({options:options});
    }

    onOrder=()=>{
        this.setState({error:""});
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        const options = this.state.options.filter((item)=>{
            return (item.selected);
        });

        if(options.length == 0){
            this.setState({error:"Please select at least 1 field to display on spreadsheet"});
            return 0;
        }

        options.sort(
          (a,b)=>{
            if(typeof a.order == "undefined" && typeof b.order != "undefined"){
                return 1;
            }
            else if(typeof a.order != "undefined" && typeof b.order == "undefined"){
                return -1;
            }
            return a.order-b.order
          }
        );

        const ordered = options.map(
          (item,index)=>{
            item.order=index+1;
            return item;
        });

        this.setState({step:"order",error:"",ordered:JSON.parse(JSON.stringify(ordered))});
    }

    onConfirm=()=>{
        this.setState({error:""});
        let macroName = undefined;
        if(this.refs['macroName']){
            const mn = this.refs['macroName'].validate();
            if(!mn.valid){
                this.setState({error:mn.value})
                return 0;
            }
            macroName=mn.value;
        }

        this.setState({loading:true,saveMacro:false,error:""});
        u.post({
            url:this.props.settings.spreadsheetURL,
            data:{
                selected:this.props.selected,
                fields:this.state.ordered,
                macroName:macroName,
            },
            success:(callback)=>{
                this.setState({selectedMacro:"none"});
                //Save to cookies
                if(macroName){
                  let ezspreadsheetMacros = store.get("ezspreadsheetMacros");
                  if(typeof ezspreadsheetMacros[this.props.settings.listLabel] == "undefined"){
                      ezspreadsheetMacros[this.props.settings.listLabel] = [];
                  }

                  const ezs = ezspreadsheetMacros[this.props.settings.listLabel];
                  for(var i=0; i<ezs.length; i++){
                      if(ezs[i].label == macroName){
                          ezs.splice(i,1);
                      }
                  }

                  ezspreadsheetMacros[this.props.settings.listLabel].push({
                      label:macroName,
                      fields:this.state.ordered
                  });

                  store.set("ezspreadsheetMacros",ezspreadsheetMacros);

                  let macros = this.state.macros;
                  if(macros.length == 0){
                    macros.unshift({label:"None",value:"none"});
                  }

                  for(var m=0; m<macros.length; m++){
                      if(macros[m].label == macroName){
                          macros.splice(m,1);
                      }
                  }

                  macros.push({
                      value:macroName,
                      label:macroName,
                      fields:this.state.ordered
                  });
                  this.setState({macros:macros});
                }

                if(callback.ready){
                    u.download(
                        callback.filename,
                        callback.filestamp,
                        ()=>{
                            this.setState({step:"success",filestamp:callback.filestamp,filename:callback.filename,loading:false});
                        }
                        ,(error,status)=>{
                            this.setState({error:error,loading:false});
                        },
                        {serverURL:callback.serverURL},
                        "/client/cp-download-file"
                        );
                }

                else{
                    this.setState({step:"pending",estimation:callback.estimation,loading:false});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDownload=(index)=>{
        u.download(this.state.filename,this.state.filestamp,
            (callback)=>{},
            (error,status)=>{
                this.setState({error:error});
            },
            null,
            "/client/cp-download-file"
            );
    }

    onChangeMacros=(value,label,index,option)=>{
        const fields = option.fields;
        let options = this.state.options.map(
          (item)=>{
              const fieldArray = (value == "none") ? [] : fields.filter(
                  (field)=>{
                    return (field.column == item.column);
                  }
              );
              const selected = (fieldArray.length > 0);
              item.selected = selected;
              item.order = (selected) ? fieldArray[0].order : undefined;
              return item;
            }
        );
        this.setState({options:options,selectedMacro:value});
    }

    onDragStart=(event,index)=>{
        event.dataTransfer.setData("text",index+"");
        event.currentTarget.id = "drag";
        this.dragIndex=index;
    }

    onDrop=(event,index)=>{
        const dragTarget = event.dataTransfer.getData("text");
        const dropTarget = index;

        event.currentTarget.id = "none";

        if(dragTarget != dropTarget){
          let ordered = this.state.ordered;

          //sort
          const entry = ordered[dragTarget];
          ordered.splice(dragTarget,1);
          ordered.splice(((dropTarget > dragTarget) ? dropTarget - 1 : dropTarget),0,entry);

          for(var i=0; i<ordered.length; i++){
              ordered[i].order = i+1;
          }

          this.setState({ordered:ordered});
        }
    }

    onDragHover=(event,index,dragover)=>{
        event.stopPropagation();
        event.preventDefault();
        if(index == this.dragIndex){
            return 0;
        }
        if(dragover){
            event.currentTarget.id = "hover";
        }
        else{
            event.currentTarget.id = "none";
        }
    }

    onDragEnd=(event,index)=>{
        const drag = document.getElementById("drag");
        if(drag){
          drag.id = "none";
        }
    }

    onDeleteMacro=()=>{
        const macros = this.state.macros;
        const selected = this.state.selectedMacro;
        let index=0;
        for(var i=0; i<macros.length; i++){
            if(macros[i].value == selected){
                index = i;
            }
        }

        u.post({
          url:"/client/cp-delete-spreadsheet-macro",
          data:{
              list:this.props.settings.listLabel,
              macro:this.state.macros[index]
          },
          success:()=>{},
          error:(error)=>{
              this.setState({error:error});
          }
        });

        macros.splice(index,1);
        this.setState({macros:macros});
        this.onChangeMacros("none","None",undefined,{value:"none",label:"None"});

        let ezspreadsheetMacros = store.get("ezspreadsheetMacros");
        ezspreadsheetMacros[this.props.settings.listLabel]=macros.slice(1);
        store.set("ezspreadsheetMacros",ezspreadsheetMacros);
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const divMap = (this.state.options.length == 0) ? [] : u.mapArrayToDivisions(this.state.options.length);
        return (
            <Segment ref="segment" style={this.props.segmentStyle} active={((this.props.active) ? this.props.active : this.state.active)} align="center" ignoreScroll={this.props.ignoreScroll}>
                <div className={"list-spreadsheet" + ((this.props.miniList)  ? " in-db" : "")}>
                    {
                        (this.props.miniList)
                          ?
                          null
                          :
                          <div className="segment-title">Convert to Spreadsheet</div>
                    }

                    <Step active={(this.state.step == "select")}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Preparing spreadsheet..."/>

                            <div className="spreadsheet-options" style={{padding:((this.props.miniList) ? "0px" : undefined)}}>
                                <h3 style={{marginTop:((this.props.miniList) ? "0px" : undefined)}}>Select your spreadsheet requirements</h3>
                                {
                                    divMap.map(
                                        (range,index)=>{
                                            return (
                                                <div key={index} className="spreadsheet-option-division">
                                                    {
                                                        (this.state.options.map(
                                                            (option,oIndex)=>{
                                                                return((oIndex >= range.start && oIndex < range.end)
                                                                        ?
                                                                        <div className="spreadsheet-option" key={oIndex}>
                                                                            <div className="spreadsheet-option-input"><Checkbox label="spreadsheet-option" index={oIndex+""} values={{value:option.selected}} onChange={this.onChangeOption}/></div>
                                                                            <div className="spreadsheet-option-label">{option.label}</div>
                                                                        </div>
                                                                        :
                                                                        ""
                                                                )
                                                            }
                                                        ))
                                                    }
                                                </div>
                                            )
                                        }
                                    )
                                }
                                {
                                  (this.state.macros.length > 0)
                                    ?
                                    <div className="segment-full-item" style={{fontSize:"1em",marginTop:"30px"}}>
                                        <div className="segment-item-input">
                                            <div className="small" style={{textAlign:"center"}}>Macros:</div>
                                            <Select label="macros" values={{value:this.state.selectedMacro,options:this.state.macros}} onChange={this.onChangeMacros}/>
                                            <div className="small" style={{textAlign:"right"}}>
                                            {
                                              (this.state.selectedMacro != "none")
                                                ?
                                                <TextButton style={{margin:0}} onClick={this.onDeleteMacro}>Delete Macro</TextButton>
                                                :
                                                ""
                                            }
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                            {
                                (this.props.miniList)
                                    ?
                                    null
                                    :
                                    <div className="segment-selected-bg">
                                        <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                        <div className="segment-note">Select entries from the list below</div>
                                    </div>
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className={(this.props.miniList) ? "" : "mobile-only"}><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onOrder}>Next</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step=="order")} scroll={(this.props.ignoreScroll) ? false : true}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Preparing spreadsheet..."/>
                            <h3>Drag and drop to re-order spreadsheet columns</h3>
                            <div className="spreadsheet-options order">
                                {
                                    this.state.ordered.map(
                                        (item,index,arr)=>{
                                            return(
                                                <div className={"spreadsheet-order" + ((index == 0) ? " first" : "")}
                                                     key={index} draggable={true}
                                                     onDragEnd={(event)=>{this.onDragEnd(event,index)}}
                                                     onDragStart={(event)=>{this.onDragStart(event,index)}} onDrop={(event)=>{this.onDrop(event,index)}}
                                                     onDragOver={(event)=>{this.onDragHover(event,index,true)}} onDragLeave={(event)=>{this.onDragHover(event,index,false)}}
                                                     >
                                                     <div className="drop-hover"/>
                                                     <div className={"label" + ((index == arr.length - 1) ? " last" : "")}>{item.label}</div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>

                            <br/>

                            <div className={((this.state.saveMacro) ? "segment-selected-bg" : "segment-buttons")}>
                                <div className="save">
                                    <Checkbox values={{value:this.state.saveMacro}} onChange={(value)=>{this.setState({saveMacro:value})}}/> Save As Macro
                                </div>
                                {
                                  (this.state.saveMacro)
                                    ?
                                    <div className="segment-full-item" style={{margin:"20px auto"}}>
                                        <div className="segment-item-input">
                                            Macro Name:
                                            <Box ref="macroName" label="Macro name" rules={{required:true}}/>
                                        </div>
                                    </div>
                                    :
                                    ""
                                }
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"select"})}}>Back</Button>
                                <Button type="medium" onClick={this.onConfirm}>Next</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "success")}>
                        <div className="segment-content">
                            <h3>Spreadsheet successfully generated</h3>
                            <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={this.onDownload}>here</span></i></div>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "pending")}>
                        <div className="segment-content">
                            <h3>This spreadsheet will take <span className="segment-bad-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <i>Note: A download link will be sent to your email upon completion</i>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
CPGenerateSpreadsheet.propTypes={
    reference:PropTypes.string.isRequired,
    selected:PropTypes.object.isRequired,
    settings:PropTypes.object.isRequired,
};


/**
 * Creates a themed add to list component that has a dropzone attached to it
 *
 * @param {string} label
 *      component name, required to uniquely identify this component on page
 *
 * @param {string} index
 *      component identifier, required to uniquely identify this component on page when labels are overlapping
 *
 * @param {string} url
 *      api request url to add new entries
 *
 * @param {number} addType
 *      1 - No Bulk Add
 *      2 - No Single Add
 *      3 - Both Add Available
 *
 * @param {string} reference
 *      duplicate of ref in parent component
 *
 * @param {function} onToggleForm
 *
 * @param {function} onRefreshList
 *
 * @param {function} onComplete
 *
 * @param {string} bulkURL
 *      api request url to add bulk entries
 *
 * @param {array} filetypes
 *      filetypes available for dropzone
 *
 * @function onValidated(data = object)
 *      Sends create to respective api call
 *
 * @function onCancel
 *      resets the component and hides it
 *
 * For easy instantiated -- use the following object
 *
 *  addData = {
 *      label:"add-entry",
 *      index:"0",
 *      addType:3,
 *      url:"",
 *      bulkURL:"",
 *      reference:"add",
 *      filetypes:Spreadsheet
 *  }
 */
export class AddListItem extends React.Component{

    state={
        active:false,
        step:(this.props.settings.addType == 2) ? 1 : 0,
        entryCount:0,
        loading:false,
        loadText:""
    };

    show=(show)=>{
        this.setState({active:show,error:""});
    }

    onBack=(step)=>{
        let nextStep = this.state.step;

        if(step){
            nextStep = step;
        }
        else if(nextStep - 1 >= 0){
            nextStep--;
        }
        this.setState({step:nextStep,error:""});
    }

    onBulkUpload=()=>{
        this.setState({step:1,error:""});
    }

    onCancel=()=>{
        if(this.state.step == 2 || this.state.step == "success"){
            if(this.props.settings.addType == 2){
                this.setState({step:1});
            }
            else{
                this.setState({step:0});
            }
        }
        this.setState({error:""});
        this.props.onToggleForm(this.props.reference,false);
    }

    onValidated=(data)=>{
        this.setState({loading:true});
        u.post({
            url:this.props.settings.addURL,
            data:{
                fields:data,
            },
            success:(countOrID)=>{
                this.setState({entryCount:countOrID,step:2,loading:false,type:((typeof countOrID === "string") ? "id" : "count")});

                if(this.props.onRefreshList){
                    this.props.onRefreshList();
                }

                if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                }

                if(this.props.onComplete){
                    this.props.onComplete(countOrID);
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading ...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Attempting to create entries..."});
        u.post({
            url:this.props.settings.bulkURL,
            data:{
                additional:this.props.additional,
                filename:filename
            },
            success:(numEntries)=>{
                this.setState({loading:false,step:2,entryCount:numEntries,type:"count"});
                if(this.props.onUploadSuccess){
                    this.props.onUploadSuccess(numEntries);
                }
                setTimeout(()=>{
                  if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error,status)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        console.log(progress);
        this.setState({loadText:"Uploading..." + progress + "%"});
    }

    onDownloadTemplate=()=>{
        this.setState({loading:true,loadText:"Preparing..."});
        const templateURL = (this.props.settings.templateURL) ? this.props.settings.templateURL : this.props.templateURL;
        u.post({
            url:templateURL,
            data:this.props.templateParams,
            success:({filename,filestamp})=>{
                this.filename=filename;
                this.filestamp=filestamp;
                this.download(this.filename,this.filestamp);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    download=(filename,filestamp)=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.download(filename,filestamp,
          ()=>{
            this.setState({loading:false,step:"success",error:""});
        },(error)=>{
            this.setState({loading:false,error:error});
        },
        null,
        this.props.overrideURL?.downloadFileURL);
    }

    render=()=>{
        const hasTemplate = ((this.props.settings.templateURL) ? this.props.settings.templateURL : this.props.templateURL);
        if(!this.state.active && !this.props.inList){
            return "";
        }
        return (
            <Segment active={this.state.active || this.props.inList} align="center" style={(this.props.inList)?{border:"None"}:{}} ignoreScroll={this.props.inList}>
                <div className="list-add">
                    {
                        (this.props.inList)
                            ?
                            ""
                            :
                            <div className="segment-title">{(this.props.title) ? this.props.title : "Add Entry"}</div>

                    }

                    <div className="segment-error">{this.state.error}</div>

                    <Step active={this.state.step == 0}>
                        <div>
                            <div className="segment-content">
                                <LoadGraphic active={this.state.loading} text="Creating new entries.."/>
                                {
                                    this.props.children
                                }
                            </div>
                            {
                                (this.props.settings.addType > 1)
                                    ?
                                    <div className="segment-add-buttons">
                                        <TextButton onClick={this.onBulkUpload}>Upload File</TextButton>
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                    </Step>

                    <Step active={this.state.step == 1} scroll={this.props.stepScroll}>
                        <div>
                            <div className="segment-content">
                                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                                <Dropzone accessibleForPreview={false}
                                          onAdded={this.onFileAdded}
                                          multiple={this.props.multiple}
                                          onUploaded={this.onFileUploaded}
                                          onError={this.onFileError}
                                          onProgress={this.onFileProgress}
                                          filetypes={this.props.filetypes}
                                          label={"dropzone-"+this.props.index}
                                          dragAndDropLabel={this.props.dragAndDropLabel}
                                />

                                <div className="segment-buttons">
                                {
                                    (this.props.settings.addType != 2)
                                        ?
                                        <Button type="medium" onClick={this.onBack}>Back</Button>
                                        :
                                        (
                                          (this.props.inList)
                                              ?
                                              null
                                              :
                                              <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                        )
                                }
                                {
                                    (hasTemplate)
                                        ?
                                        <Button type="medium" onClick={this.onDownloadTemplate}>Download Template</Button>
                                        :
                                        null
                                }
                                </div>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == 2} scroll={this.props.stepScroll}>
                        <div style={{position:"relative",fontSize:(this.props.inList) ? "16px" : "1em"}}>
                            <SegmentComplete onComplete={this.onCancel} inList={this.props.inList}>
                                {
                                    (this.props.success)
                                        ?
                                        this.props.success
                                        :
                                        <span>Successfully created <span className="segment-emphasis">{this.state.entryCount}</span>{(this.state.type == "count") ? " new " + ((this.state.entryCount == 1) ? "entry" : "entries") : ""}</span>
                                }
                            </SegmentComplete>
                        </div>
                    </Step>

                    <Step active={this.state.step == "success"} scroll={this.props.stepScroll}>
                        <div className="segment-content">
                            <h3>Download Has Started</h3>
                            <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={()=>{this.download(this.filename,this.filestamp);}}>here</span></i></div>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
AddListItem.propTypes={
    onComplete:PropTypes.func,
    onUploadSuccess:PropTypes.func,
    index:PropTypes.string,
    reference:PropTypes.string,
    inList:PropTypes.bool,
    success:PropTypes.string,
    title:PropTypes.string,
    settings:PropTypes.object.isRequired,
    stepScroll:PropTypes.bool,
};
AddListItem.defaultProps={
    index:"0",
    inList:false,
    filetypes:Spreadsheet
};


export class BulkEdit extends React.Component{

    state={
        active:false,
        loading:false,
        loadText:"",
        step:"field",
        options:[],
        interest:{}
    };

    show=(show)=>{
        this.setState({active:show});
    }

    componentDidMount=()=>{
        const options=u.getBulkEdit(this.props.settings.listLabel);
        this.setState({options:options});
    }

    onBack=()=>{
        this.setState({step:"field",error:""});
    }

    onSelectField=()=>{
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        const field = this.refs['field'].validate();
        const interest = field.option;

        this.setState({step:"interest",field:field.value,error:"",interest:interest});
    }

    onSubmit=()=>{
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to perform bulk edit"});
            return 0;
        }

        const value = this.refs["interest"].validate();
        if(!value.valid){
            this.setState({error:value.value});
            return 0;
        }

        this.setState({loading:true,error:""});

        u.post({
            url:"/api/list-bulk-edit",
            data:{
                selected:this.props.selected,
                list:this.props.settings.listLabel,
                field:this.state.field,
                value:value.value,
            },
            success:(count)=>{
                this.setState({loading:false,step:"success",error:"",count:count});
                setTimeout(()=>{
                  if(this.props.onRefreshList){
                      this.props.onRefreshList()
                  }
                  if(this.props.settings.overview){
                      this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onCancel=()=>{
        this.setState({step:'field',error:"",loading:false});
        this.props.onToggleForm(this.props.reference,false);
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading ...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Attempting to create entries..."});
        u.post({
            url:this.props.settings.editUploadURL,
            data:{
                additional:this.props.additional,
                filename:filename
            },
            success:(numEntries)=>{
                this.setState({loading:false,step:"success",count:numEntries});

                setTimeout(()=>{
                  if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error,status)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:"Uploading..." + progress + "%"});
    }

    onDownloadTemplate=()=>{
        this.setState({loading:true,loadText:"Preparing...",error:""});
        const templateURL = (this.props.settings.editTemplateURL) ? this.props.settings.editTemplateURL : this.props.templateURL;
        u.post({
            url:templateURL,
            data:this.props.templateParams,
            success:({filename,filestamp})=>{
                this.filename=filename;
                this.filestamp=filestamp;
                this.download(this.filename,this.filestamp);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    download=(filename,filestamp)=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.download(filename,filestamp,
          ()=>{
            this.setState({loading:false,step:"download-success",error:""});
        },(error)=>{
            this.setState({loading:false,error:error});
        });
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const interest=this.state.interest;
        return(
            <Segment active={this.state.active} align={"center"}>
                <div className="list-add">
                    <div className="segment-title">Bulk Edit</div>

                    <LoadGraphic active={this.state.loading} text="Updating..."/>

                    <div className="segment-content">
                        <Step active={(this.state.step == "field")}>
                            <h3>Select the field that you wish to edit</h3>
                            <div className="segment-content">
                                <div className="segment-full-item">
                                    <div className="segment-item-input"><Select ref="field" label="Field" values={{options:this.state.options}}/></div>
                                </div>
                            </div>

                            <div className="segment-selected-bg">
                                <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                <div className="segment-note" style={{marginBottom:"20px"}}>Select the entries you wish to edit from the list below</div>
                            </div>

                            {
                                (this.props.settings.editUploadURL)
                                    ?
                                    <div style={{fontSize:"0.85em"}}>
                                        <TextButton onClick={()=>{this.setState({step:"template",error:""})}}>Spreadsheet Upload</TextButton>
                                    </div>
                                    :
                                    null
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onSelectField}>Select</Button>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "interest")}>
                            <h3>Please set the value for <span className="text-emphasis">{interest.label}</span>:</h3>
                            <div className="segment-content">
                                <div className="segment-full-item">
                                    <div className="segment-item-input">
                                    {
                                      (interest.dataType=="box")
                                        ?
                                        <Box ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="select")
                                        ?
                                        <Select ref="interest" label={interest.label} values={{options:interest.options}} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="date")
                                        ?
                                        <Date ref="interest" label={interest.label} range={interest.range} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="time")
                                        ?
                                        <Time ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="area")
                                        ?
                                        <Area ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="dayOfWeek")
                                        ?
                                        <DayOfWeek ref="interest" label={interest.label} noBorder={true} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    </div>
                                </div>
                            </div>

                            <div className="segment-selected-bg">
                                <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                <div className="segment-note" style={{marginBottom:"20px"}}>Select the entries you wish to edit from the list below</div>
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onBack}>Back</Button>
                                <Button type="medium" onClick={this.onSubmit}>Apply</Button>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "success")}>
                            <SegmentComplete onComplete={this.onCancel}>Successfully updated {this.state.count} entries</SegmentComplete>
                        </Step>

                        <Step active={this.state.step == "template"}>
                            <div className="segment-content">
                                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                                <div className="segment-error">{this.state.error}</div>

                                <Dropzone accessibleForPreview={false}
                                          onAdded={this.onFileAdded}
                                          multiple={false}
                                          onUploaded={this.onFileUploaded}
                                          onError={this.onFileError}
                                          onProgress={this.onFileProgress}
                                          filetypes={Spreadsheet}
                                          label={"dropzone-bulk-edit-template-"+this.props.index}
                                          dragAndDropLabel={"Upload the completed template here"}
                                />

                                <div className="segment-buttons">
                                    <Button onClick={()=>{this.setState({step:"field",error:""})}}>Back</Button>
                                    {
                                        (this.props.settings.editTemplateURL || this.props.templateURL)
                                            ?
                                            <Button type="medium" onClick={this.onDownloadTemplate}>Download Template</Button>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </Step>

                        <Step active={this.state.step == "download-success"}>
                            <div className="segment-content">
                                <h3>Download Has Started</h3>
                                <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={()=>{this.download(this.filename,this.filestamp);}}>here</span></i></div>
                                <div className="segment-buttons">
                                    <div className="segment-error">{this.state.error}</div>
                                    <Button type="medium" onClick={this.onCancel}>Close</Button>
                                </div>
                            </div>
                        </Step>

                    </div>
                </div>
            </Segment>
        )
    }
}
BulkEdit.propTypes={
    settings:PropTypes.object.isRequired,
};

export class CPBulkEdit extends React.Component{

    state={
        active:false,
        loading:false,
        loadText:"",
        step:"field",
        options:[],
        interest:{}
    };

    show=(show)=>{
        this.setState({active:show});
    }

    componentDidMount=()=>{
        const options=u.getBulkEdit(this.props.settings.listLabel);
        this.setState({options:options});
    }

    onBack=()=>{
        this.setState({step:"field",error:""});
    }

    onSelectField=()=>{
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        const field = this.refs['field'].validate();
        const interest = field.option;

        this.setState({step:"interest",field:field.value,error:"",interest:interest});
    }

    onSubmit=()=>{
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to perform bulk edit"});
            return 0;
        }

        const value = this.refs["interest"].validate();
        if(!value.valid){
            this.setState({error:value.value});
            return 0;
        }

        this.setState({loading:true,error:""});

        u.post({
            url:"/client/cp-list-bulk-edit",
            data:{
                selected:this.props.selected,
                list:this.props.settings.listLabel,
                field:this.state.field,
                value:value.value,
            },
            success:(count)=>{
                this.setState({loading:false,step:"success",error:"",count:count});
                setTimeout(()=>{
                  if(this.props.onRefreshList){
                      this.props.onRefreshList()
                  }
                  if(this.props.settings.overview){
                      this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onCancel=()=>{
        this.setState({step:'field',error:"",loading:false});
        this.props.onToggleForm(this.props.reference,false);
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading ...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Attempting to create entries..."});
        u.post({
            url:this.props.settings.editUploadURL,
            data:{
                additional:this.props.additional,
                filename:filename
            },
            success:(numEntries)=>{
                this.setState({loading:false,step:"success",count:numEntries});

                setTimeout(()=>{
                  if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error,status)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:"Uploading..." + progress + "%"});
    }

    onDownloadTemplate=()=>{
        this.setState({loading:true,loadText:"Preparing...",error:""});
        const templateURL = (this.props.settings.editTemplateURL) ? this.props.settings.editTemplateURL : this.props.templateURL;
        u.post({
            url:templateURL,
            data:this.props.templateParams,
            success:({filename,filestamp})=>{
                this.filename=filename;
                this.filestamp=filestamp;
                this.download(this.filename,this.filestamp);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    download=(filename,filestamp)=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.download(filename,filestamp,
          ()=>{
            this.setState({loading:false,step:"download-success",error:""});
        },(error)=>{
            this.setState({loading:false,error:error});
        },
        null,
        "/client/cp-download-file"
        );
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const interest=this.state.interest;
        return(
            <Segment active={this.state.active} align={"center"}>
                <div className="list-add">
                    <div className="segment-title">Bulk Edit</div>

                    <LoadGraphic active={this.state.loading} text="Updating..."/>

                    <div className="segment-content">
                        <Step active={(this.state.step == "field")}>
                            <h3>Select the field that you wish to edit</h3>
                            <div className="segment-content">
                                <div className="segment-full-item">
                                    <div className="segment-item-input"><Select ref="field" label="Field" values={{options:this.state.options}}/></div>
                                </div>
                            </div>

                            <div className="segment-selected-bg">
                                <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                <div className="segment-note" style={{marginBottom:"20px"}}>Select the entries you wish to edit from the list below</div>
                            </div>

                            {
                                (this.props.settings.editUploadURL)
                                    ?
                                    <div style={{fontSize:"0.85em"}}>
                                        <TextButton onClick={()=>{this.setState({step:"template",error:""})}}>Spreadsheet Upload</TextButton>
                                    </div>
                                    :
                                    null
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onSelectField}>Select</Button>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "interest")}>
                            <h3>Please set the value for <span className="text-emphasis">{interest.label}</span>:</h3>
                            <div className="segment-content">
                                <div className="segment-full-item">
                                    <div className="segment-item-input">
                                    {
                                      (interest.dataType=="box")
                                        ?
                                        <Box ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="select")
                                        ?
                                        <Select ref="interest" label={interest.label} values={{options:interest.options}} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="date")
                                        ?
                                        <Date ref="interest" label={interest.label} range={interest.range} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="time")
                                        ?
                                        <Time ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="area")
                                        ?
                                        <Area ref="interest" label={interest.label} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    {
                                      (interest.dataType=="dayOfWeek")
                                        ?
                                        <DayOfWeek ref="interest" label={interest.label} noBorder={true} rules={interest.rules}/>
                                        :
                                        ""
                                    }
                                    </div>
                                </div>
                            </div>

                            <div className="segment-selected-bg">
                                <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                                <div className="segment-note" style={{marginBottom:"20px"}}>Select the entries you wish to edit from the list below</div>
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onBack}>Back</Button>
                                <Button type="medium" onClick={this.onSubmit}>Apply</Button>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "success")}>
                            <SegmentComplete onComplete={this.onCancel}>Successfully updated {this.state.count} entries</SegmentComplete>
                        </Step>

                        <Step active={this.state.step == "template"}>
                            <div className="segment-content">
                                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                                <div className="segment-error">{this.state.error}</div>

                                <Dropzone accessibleForPreview={false}
                                          onAdded={this.onFileAdded}
                                          multiple={false}
                                          onUploaded={this.onFileUploaded}
                                          onError={this.onFileError}
                                          onProgress={this.onFileProgress}
                                          filetypes={Spreadsheet}
                                          label={"dropzone-bulk-edit-template-"+this.props.index}
                                          dragAndDropLabel={"Upload the completed template here"}
                                />

                                <div className="segment-buttons">
                                    <Button onClick={()=>{this.setState({step:"field",error:""})}}>Back</Button>
                                    {
                                        (this.props.settings.editTemplateURL || this.props.templateURL)
                                            ?
                                            <Button type="medium" onClick={this.onDownloadTemplate}>Download Template</Button>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </Step>

                        <Step active={this.state.step == "download-success"}>
                            <div className="segment-content">
                                <h3>Download Has Started</h3>
                                <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={()=>{this.download(this.filename,this.filestamp);}}>here</span></i></div>
                                <div className="segment-buttons">
                                    <div className="segment-error">{this.state.error}</div>
                                    <Button type="medium" onClick={this.onCancel}>Close</Button>
                                </div>
                            </div>
                        </Step>
                    </div>
                </div>
            </Segment>
        )
    }
}
CPBulkEdit.propTypes={
    settings:PropTypes.object.isRequired,
};


export class DownloadTemplate extends React.Component{

    state={
      error:"",
      active:(this.props.active) ? this.props.active : false,
      loading:false,
      loadText:"Preparing...",
      step:"download",
    };

    show=(show)=>{
        this.setState({active:show});
        if(show){
            //Check if no-params
            if(this.props.skipParams){
                this.onDownload(this.props.params);
            }
        }
    }

    onCancel=()=>{
        this.setState({step:"download",error:""});
        if(this.props.onCancel){
            this.props.onCancel();
        }
        else{
            this.props.onToggleForm(this.props.reference,false);
        }
    }

    onDownload=(params)=>{
        this.setState({loading:true,loadText:"Preparing..."});
        u.post({
            url:(this.props.url) ? this.props.url : this.props.settings.templateURL,
            data:params,
            success:({filename,filestamp})=>{
                this.filename=filename;
                this.filestamp=filestamp;
                this.download(this.filename,this.filestamp);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    download=(filename,filestamp)=>{
        this.setState({loading:true,loadText:"Downloading..."});
        console.log("Download URL",this.props.overrideURL?.downloadFileURL);
        u.download(filename,filestamp,
          ()=>{
            this.setState({loading:false,step:"success",error:""});
        },(error)=>{
            this.setState({loading:false,error:error});
        },
        null,
        this.props.overrideURL?.downloadFileURL
        );
    }

    render=()=>{
        return(
            <Segment ref={this.props.reference} active={this.state.active} noBorder={this.props.noBorder}>
                <div style={{position:"relative",textAlign:"center"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="segment-title">Download Add Template</div>

                    <Step active={this.state.step == "download"}>
                        {
                          (this.props.skipParams)
                              ?
                              <div className="segment-content">
                                  <h3>Preparing for Download</h3>
                                  <div className="segment-note">Please leave the segment open until download has begun</div>
                              </div>
                              :
                              this.props.children
                        }
                    </Step>

                    <Step active={this.state.step == "success"}>
                        <div className="segment-content">
                            <h3>Download Has Started</h3>
                            <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={
                              ()=>{
                                this.download(this.filename,this.filestamp);
                              }}>here</span></i></div>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
DownloadTemplate.propTypes={
    skipParams:PropTypes.bool,
    params:PropTypes.object,
    settings:PropTypes.object.isRequired,
};


export class FileDropbox extends React.Component{

    state={
        error:"",
        step:0,
        loading:false
    };

    onRemoveRequest=(index)=>{
        this.removeIndex=index;
        this.setState({error:"",step:"remove"});
    }

    onRemove=(index)=>{
        this.setState({loading:true,loadText:"Removing..."});
        const file=this.props.content[this.removeIndex];
        u.post({
            url:this.props.deleteURL,
            data:{
                id:this.props.id,
                file:file,
            },
            success:()=>{
                let dropbox = this.props.content;
                dropbox.splice(this.removeIndex,1);
                this.setState({loading:false,step:0});
                this.props.onChange(dropbox,"dropbox");
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onAddRequest=()=>{
        this.setState({step:1,error:""});
    }

    onFileUpload=(url)=>{
        u.post({
            url:this.props.addURL,
            data:{
                id:this.props.id,
                filename:this.userFilename,
                url:url,
            },
            success:(filestamp)=>{
                const file = {
                    createdByID:u.getUserID(),
                    createdByName:u.getFullName(),
                    createdOn:moment().format("YYYY-MM-DDTHH:mm:ss"),
                    filename:this.userFilename,
                    filestamp:filestamp,
                };
                let dropbox=this.props.content;
                dropbox.unshift(file);
                this.props.onChange(dropbox,"dropbox");
                this.setState({loading:false,error:"",step:0});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onDownload=(index)=>{
        this.setState({loading:true,loadText:"Downloading...",error:""});
        const file=this.props.content[index];
        u.download(file.filename,file.filestamp,()=>{
            this.setState({loading:false});
        },(error)=>{
            this.setState({error:error,loading:false})
        });
    }

    render=()=>{
        const content=this.props.content;
        return(
            <div className="remark-container">
                <LoadGraphic active={this.state.loading} text="Updating database..."/>
                <Step active={(this.state.step == 0)}>
                    <div>
                        {
                            (this.props.content.length > 0)
                                ?
                                <div>
                                    <div className="remark-scroll">
                                        <div className="remark header">
                                            <div className="remark-remove"/>
                                            <div className="remark-data">File</div>
                                            <div className="remark-date">Created On</div>
                                            <div className="remark-staff">Created By</div>
                                        </div>
                                        {
                                            this.props.content.map(
                                                (item,index)=>{
                                                    return (
                                                        <div className="remark" key={index}>
                                                            <div className="remark-remove">
                                                                <div style={{width:"15px",height:"15px",position:"relative"}}>
                                                                    <MiniMinusButton enabled={(this.props.permissions)}
                                                                                     onClick={()=>{this.onRemoveRequest(index)}}/>
                                                                </div>
                                                            </div>
                                                            <div className="remark-data">
                                                                <TextButton onClick={()=>{this.onDownload(index)}}>{item.filename}</TextButton>
                                                            </div>
                                                            <div className="remark-date">
                                                                {moment(item.dateTime).format("DD MMM YY - HH:mma")}
                                                            </div>
                                                            <div className="remark-staff">
                                                                <Link to={"/staff/"+item.createdByID}>{item.createdByName}</Link>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                    <div style={{margin:"20px auto 0 auto",textAlign:"center"}}>
                                        <TextButton onClick={this.onAddRequest}>Add File</TextButton>
                                    </div>
                                </div>
                                :
                                <div className="remarks-placeholder">
                                    <h4>No Remarks Added</h4>
                                    <div style={{margin:"10px auto 0 auto",fontSize:"1em"}}>
                                        <TextButton onClick={this.onAddRequest}>Add Remark</TextButton>
                                    </div>
                                </div>
                        }
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div style={{textAlign:"center"}}>
                        <div style={{width:"98%",margin:"auto"}}>
                            <div className="page-error">{this.state.error}</div>
                            <Dropzone label="dropbox-dropzone"
                                      accessibleForPreview={true}
                                      onAdded={(filename)=>{this.setState({loading:true,loadText:"Uploading..."}); this.userFilename=filename}}
                                      onUploaded={this.onFileUpload}
                                      onError={(error)=>{this.setState({error:error,loading:false})}}
                                      onProgress={(progress)=>{this.setState({loadText:"Uploading..."+progress+"%"})}}
                                      filetypes={Attachment}
                                      />
                            <div className="segment-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"0",error:""})}}>Cancel</Button>
                            </div>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{textAlign:"center"}}>
                        <h2 className="text-negative">Delete File?</h2>
                        <div style={{margin:"10px auto 20px auto"}}>Note. This action cannot be undone</div>
                        <div style={{margin:"20px auto"}}>
                            <Button onClick={()=>{this.setState({step:0,error:""})}}>Back</Button>
                            <Button onClick={this.onRemove}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}


