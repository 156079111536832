import React from 'react';
import u from '../../utilities/Utilities';
import EmptyPageLayout from '../../components/EmptyPageLayout';
import {Box, Button, TextButton, InputOTP} from '../../components/Input';
import {Step} from '../../components/Common';
import {OwnerSelect} from '../Landing';
import store from 'store';
import Measure from 'react-measure';
import moment from 'moment';
import QRCode from 'qrcode.react';
import "../../styles/client-portal/portal-login.scss";

export default class Landing extends React.Component {

    render = () => {
        return (
            <LandingLayout ref={(ref) => {this.layout = ref}}
                           title={"MWMD Client Portal"}
                           url={"/supplier"}
                           >
                <LoginComponent showPopup={(e) => {this.layout.showPopup(e)}}
                                setPopupComponent={(c) => {this.layout.setPopupComponent(c)}}
                                />
            </LandingLayout>
        )
    }
}

export class LandingLayout extends React.Component {

    state = {
        mounted: false,
        dimensions: {width: 0, height: 0},
    };

    componentDidMount = () => {
        this.setState({mounted: true});
    }

    render = () => {
        if (!this.state.mounted) {
            return (<div />)
        }

        return (
            <EmptyPageLayout title={this.props.title} url={this.props.url} className="clp-page" style={{position:"relative",textAlign: 'center',height:"100%"}}>

                <div>
                    <img src="/images/app/app-logo.png"
                         className='client-header-logo'
                         />
                </div>

                <Measure bounds onResize={contentRect => {this.setState({dimensions: contentRect.bounds})}}>
                    {
                        ({measureRef}) =>
                        (
                            <div ref={measureRef} className="clp-width" />
                        )
                    }
                </Measure>

                <ImpressionSegment text={this.props.text}>
                    {this.props.children}
                </ImpressionSegment>

                <FooterSegment />

            </EmptyPageLayout>
        )
    }

}

class ImpressionSegment extends React.Component {

    render = () => {
        return (
            <div className="clp-segment">
                <div className="clp-main">
                    <div className="clp-main-top">
                        <div className="clp-content-area">
                            <div className="clp-container">
                                <div className="clp-logo-panel">
                                    {window.innerWidth >= 576 &&
                                        <ul className='circles'>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                            <li></li>
                                        </ul>}
                                    <div className="clp-logo-container">
                                        <img src="/images/app/mwmd-icon.png" className="clp-app-icon" />
                                    </div>

                                    <div className="clp-logo-caption">
                                        <h1>
                                            {(this.props.text) ? this.props.text : "Welcome back!"}
                                        </h1>
                                    </div>
                                </div>
                                <div className="clp-step">
                                    <div className="clp-step-container">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clp-main-background" />
                </div>
            </div>
        )
    }
}

class LoginComponent extends React.Component {

    r={};

    state = {
        step: "login",
        error: "",
        avatarLink: "",
        email: "",
        loading: false,
        loadText: "Authenticating...",
        pin: ["", "", "", "", "", ""],
        dimensions: {width: 0, height: 0},
        secret: {},
        ownerOptions:[],
    }

    componentDidMount = () => {

        const auth = store.get("client-auth");
        if (auth) {
            u.post({
                url: "/client/cp-validate-token",
                data: {},
                success: (callback) => {
                    for (var key in callback) {
                        store.set(key, callback[key]);
                    }
                    store.set("auth", moment().format("YYYY-MM-DDTHH:mm:ss"));

                    if (this.redirect) {
                        window.location = this.redirect;
                    }
                    else {
                        window.location = "/portal/dashboard";
                    }
                },
                error: (error, status) => {
                    u.cpLogout();
                }
            });
        }
        
        const email = u.getUserEmail();
        const avatarLink = u.getUserAvatar();

        if (typeof email != "undefined" || typeof avatarLink != "undefined") {
            this.setState({email: email, avatarLink: avatarLink});
        }
    }

    onLogin = () => {
        let email = {value: this.state.email};
        if (this.state.step == "login") {
            email = this.r['email'].validate();
            if (!email.valid) {
                this.setState({error: email.value});
                return 0;
            }
        }
        const password = this.r['password'].validate();
        if (!password.valid) {
            this.setState({error: password.value});
            return 0;
        }

        this.email = email.value;
        this.password = password.value;
        this.setState({step: "pin", error: ""});
    }

    onOwnerSelected=()=>{
        let {selected,ownerOptions}=this.state;
        const token = ownerOptions[selected].token;
        if(this.ownerCallback){
            this.ownerCallback(token);
        }
    }

    onResetPassword = () => {
        this.setState({error:""});
        let keys = ["email", "password", "cnfpassword"];
        let data = {};
        for (var i = 0; i < keys.length; i++) {
            let current = this.r[keys[i]].validate();
            if (!current.valid) {
                this.setState({error: current.value});
                return 0;
            }
            data[keys[i]] = current.value;
        }

        if (data.password != data.cnfpassword) {
            this.setState({error: "Passwords do not match"});
            return 0;
        }

        u.post({
            url: "/noauth/cp-get-reset-password-options",
            data: {
                email: data.email,
                password: data.password
            },
            success: (ownerOptions) => {
                this.ownerCallback=(token)=>{
                    u.post({
                        url:"/noauth/cp-reset-password-with-token",
                        data:{
                            token:token
                        },
                        success:()=>{
                            this.setState({step:"reset-success",error:""});
                        },
                        error:(error)=>{
                            this.setState({error:error});
                        }
                    });
                };
                if(ownerOptions.length > 1){
                    this.setState({ownerOptions:ownerOptions,step:"client-select",selected:-1});
                }
                else{
                    this.ownerCallback(ownerOptions[0].token);
                }
            },
            error: (error) => {
                this.setState({error: error});
            }
        });
    }

    onNotMe = () => {
        u.removeStore();
        this.setState({email: "", step: "login", error: ""});
    }

    validatePin = () => {
        this.setState({error: "", pinEnabled: false});
        const otp = this.r['otp'].validate();
        if (!otp.valid) {
            this.setState({error: otp.value, pinEnabled: true});
            return 0;
        }

        this.ownerCallback = (token)=>{
            u.post({
                url:"/noauth/cp-login-with-token",
                data:{
                    token:token
                },
                success:(callback)=>{
                    for(var key in callback){
                        store.set(key,callback[key]);
                    }
                    store.remove("dashboard-action");
                    store.remove("dashboard-action-label");
                    store.set("client-auth",moment().format("YYYY-MM-DDTHH:mm:ss"));
    
                    const redirect = store.get("cp-redirect");
                    if(redirect){
                        store.remove("cp-redirect");
                        window.location=redirect;
                    }
                    else{
                        window.location="/portal/dashboard";
                    }
                },
                error:(error,errorCode)=>{
                    if(errorCode == 420){
                        this.setState({
                        step:"reset-password",
                        expiry:true,
                        loading:false,
                        });
                    }
                    else{
                        this.setState({error:error,loading:false,pinEnabled:true});
                    }
                    if(this.r['password']){
                        this.r['password'].clear();
                    }
                    if(this.r['otp']){
                        this.r['otp'].clear();
                    }
                }
            });
        };

        u.post({
            url:"/noauth/cp-get-owner-options",
            data:{
                email:this.email,
                password:this.password,
                otp:otp.value,
            },
            success:(ownerOptions)=>{
                if(ownerOptions.length > 1){
                    this.setState({ownerOptions:ownerOptions,step:"client-select",selected:-1});
                }
                else{
                    this.ownerCallback(ownerOptions[0].token);
                }
            },
            error:(error,errorCode)=>{
                if(errorCode == 420){
                    this.setState({
                        step:"reset-password",
                        expiry:true,
                        loading:false,
                        pinEnabled:false,
                    });
                }
                else{
                    this.setState({error:error,loading:false,pinEnabled:true});
                }
                this.r['password'].clear();
            }
        })
    }

    onResetAuthRequest = () => {
        this.setState({loading: true, loadText: "Preparing..."});
        u.post({
            url: "/noauth/cp-generate-totp-secret",
            data: {},
            success: (secret) => {
                this.setState({
                    secret: secret,
                    loading: false,
                    step: "reset-authenticator",
                });
            },
            error: (error) => {
                this.setState({error: error, loading: false})
            }
        })
    }

    validateAuthenticator = () => {
        let keys = ['email', "otp"];
        let data = {};
        for (var i = 0; i < keys.length; i++) {
            const current = this.r[keys[i]].validate();
            if (!current.valid) {
                this.setState({error: current.value});
                return 0;
            }
            data[keys[i]] = current.value;
        }
        data.secret = this.state.secret.base32;

        this.setState({loading: true, loadText: "Verifying OTP..."});

        u.post({
            url: "/noauth/verify-totp",
            data: {
                secret: data.secret,
                otp: data.otp,
            },
            success: () => {
                this.setState({loadText: "Updating..."});
                u.post({
                    url: "/noauth/cp-admin-reset-authenticator",
                    data: data,
                    success: () => {
                        this.setState({step: "reset-success", loading: false, error: ""});
                    },
                    error: (error) => {
                        this.setState({loading: false, error: error});
                    }
                })
            },
            error: (error) => {
                this.setState({loading: false, error: error});
            }
        });

    }

    render = () => {
        const enabled = !this.state.loading;
        const pinEnabled = this.state.pinEnabled;
        const {width, height} = this.state.dimensions;

        return (
            <div>
                <Step active={this.state.step == "login"}>
                    <div>
                        <h3>Login Here!</h3>
                        <div className="clp-input-container">
                            <div className="clp-input">
                                <Box ref={(ref)=>{this.r["email"]=ref}} 
                                     label="Email" 
                                     type="email" 
                                     values={{value: this.state.email, enabled: enabled}} 
                                     rules={{required: true, placeholder: "Email"}} 
                                     onChange={(value) => {this.setState({email: value})}} 
                                     onEnterKey={() => {this.r['password'].focus()}} 
                                     />
                            </div>
                            <div className="clp-input">
                                <Box ref={(ref)=>{this.r["password"]=ref}}  
                                     label="Password" 
                                     type="password" 
                                     values={{enabled: enabled}} 
                                     rules={{required: true, placeholder: "Password"}} 
                                     onEnterKey={this.onLogin} 
                                     />
                            </div>
                        </div>
                        <div className="clp-error">{this.state.error}</div>

                        <div className="clp-button">
                            <Button type="large" enabled={enabled} onClick={this.onLogin}>Login</Button>
                        </div>
                        <div className="clp-text-button">
                            <TextButton enabled={enabled} onClick={() => {this.setState({step: "reset-password", expiry: false})}}>Reset Password</TextButton>
                            <TextButton enabled={enabled} onClick={this.onResetAuthRequest}>Reset Authenticator</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reset-password"}>
                    <div>
                        <h3>
                            {
                                (this.state.expiry)
                                    ?
                                    "Password Expired"
                                    :
                                    "Reset Password"
                            }
                        </h3>

                        {
                            (this.state.expiry)
                                ?
                                <div className="text-emphasis" style={{marginBottom: "10px"}}>
                                    <b>Your organization has a password expiry policy, please reset your password</b>
                                </div>
                                :
                                null
                        }
                        <div className="clp-input">
                            <Box ref={(ref)=>{this.r["email"]=ref}} label="Email" type="email" values={{value: this.state.email, enabled: enabled}} rules={{required: true, placeholder: "Email"}} onChange={(value) => {this.setState({email: value})}} 
                                 onEnterKey={() => {this.r['password'].focus()}} />
                        </div>

                        <div className="clp-input">
                            <Box ref={(ref)=>{this.r["password"]=ref}} label="New Password" type="password" values={{enabled: enabled}} rules={{required: true, placeholder: "New Password"}} 
                                 onEnterKey={() => {this.r['cnfpassword'].focus()}} />
                        </div>

                        <div className="clp-input">
                            <Box ref={(ref)=>{this.r["cnfpassword"]=ref}} label="Confirm Password" type="password" values={{enabled: enabled}} rules={{required: true, placeholder: "Confirm Password"}} 
                                 onEnterKey={this.onResetPassword} />
                        </div>

                        <div className="clp-error">{this.state.error}</div>

                        <div className="clp-button">
                            <Button type="large" enabled={enabled} onClick={this.onResetPassword}>Reset Password</Button>
                        </div>

                        <div className="clp-text-button">
                            <TextButton enabled={enabled} onClick={() => {this.setState({step: "login"})}}>Login Here</TextButton>
                            <TextButton enabled={enabled} onClick={this.onResetAuthRequest}>Reset Authenticator</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reset-authenticator"}>
                    <div className="clp-qr-container">
                        <h3>Scan the QR code into Google Authenticator</h3>

                        <div style={{position: "relative", width: "200px", margin: "auto"}}>
                            <Measure bounds onResize={contentRect => {this.setState({dimensions: contentRect.bounds})}}>
                                {
                                    ({measureRef}) =>
                                    (
                                        <div ref={measureRef} className="auth-qr-image">
                                            <QRCode value={this.state.secret.otpauth_url}
                                                level="H"
                                                fgColor="#000"
                                                size={width}
                                            />
                                        </div>
                                    )
                                }
                            </Measure>
                        </div>

                        <div style={{margin: "20px auto"}}>
                            <Button style={{minWidth: "250px", padding: "10px"}} onClick={() => {this.setState({step: "confirm-otp", error: ""})}}>Verify</Button>
                        </div>

                        <div className="clp-text-button">
                            <TextButton enabled={enabled} onClick={() => {this.setState({step: "reset-password", expiry: false})}}>Reset Password</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reset-success"}>
                    <div>
                        <h3 className="text-emphasis">Email Link Sent</h3>
                        <div className="clp-text">
                            To confirm your password or authenticator change, please check your email for a password change link. Update will only take effect after link is used
                        </div>
                        <div className="clp-button" style={{margin: "20px auto"}}>
                            <Button onClick={() => {this.setState({step: "login"})}}>Login</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "pin"}>
                    <div>
                        <h3>Enter OTP</h3>

                        <div style={{margin: "0 auto 15px auto", fontSize: "14px"}}>Please enter the 6 digit one time pin from your registered authenticator</div>

                        <InputOTP ref={(ref)=>{this.r["otp"]=ref}}
                                  enabled={this.state.pinEnabled}
                                  onComplete={this.validatePin}
                                  />

                        <div className="clp-error">{this.state.error}</div>
                        <div className="clp-button">
                            <Button type="large" enabled={enabled} onClick={this.validatePin}>Confirm</Button>
                        </div>
                        <div className="clp-text-button">
                            <TextButton enabled={enabled} onClick={() => {this.setState({step: "login", expiry: false})}}>Back</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "confirm-otp"}>
                    <div>
                        <h3>Enter Email & Verify New OTP</h3>

                        <div className="clp-input">
                            <Box ref={(ref)=>{this.r["email"]=ref}}
                                 label="Email"
                                 type="email"
                                 values={{
                                    value: this.state.email,
                                    enabled: enabled
                                 }}
                                 rules={{
                                    required: true,
                                    placeholder: "Account Email"
                                 }}
                                 onChange={(value) => {this.setState({email: value})}}
                                 />
                        </div>

                        <div className="clp-input" style={{background:"#f4f4f4",borderRadius:"5px",padding:"1px 0"}}>
                            <InputOTP ref={(ref)=>{this.r["otp"]=ref}}
                                      label="OTP"
                                      type="number"
                                      rules={{
                                        required: true,
                                        placeholder: "New OTP"
                                      }}
                                      onComplete={this.validateAuthenticator}
                                      />
                        </div>

                        <div className="clp-error">{this.state.error}</div>

                        <div className="clp-button">
                            <Button type="large" enabled={enabled} onClick={this.validateAuthenticator}>Verify</Button>
                        </div>

                        <div className="clp-text-button">
                            <TextButton enabled={enabled} onClick={() => {this.setState({step: "reset-authenticator"})}}>Back</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "client-select"}>
                    <div className="clp-owner-select-container">
                        <h3>Select Organization</h3>

                        <div>Please select the organization you are representing for this session</div>

                        <div className="owner-select-content">
                            {
                                this.state.ownerOptions.map(
                                    (item, index) => {
                                        return (
                                            <div key={index}
                                                className={"owner-select-options-container" + ((this.state.selected == index) ? " selected" : "")}
                                                onClick={() => {this.setState({selected: index})}}>
                                                <b>{item.ownerName}</b>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>

                        <div className="clp-error">{this.state.error}</div>

                        <div className="clp-button">
                            <Button type="large" enabled={enabled && this.state.selected != -1} onClick={this.onOwnerSelected}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class FooterSegment extends React.Component {

    render = () => {
        return (
            <div className="contact">
                <img src="/images/app/footer.png" className="clp-footer" />
            </div>
        )
    }
}