import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,DownloadTemplate,DynamicListArea,BulkEdit,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {EditSizeLabel,EditableBox,EditableSelect,Box,Area,Select,TextButton,Button,MiniMinusButton,Info} from '../components/Input';
import {EstateUtilities} from './Estate';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import PropTypes, { number } from 'prop-types';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';
import "../styles/transfer-list.scss";

export default class TransferList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Transfer Request",
        permission:"transfer",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/transfer-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/create-transfer-request",
        //templateURL:"/api/get-add-staff-template",
        //bulkURL:"/api/add-bulk-staff",
        deleteURL:"/api/delete-transfer-entries",
        spreadsheetURL:"/api/generate-transfer-spreadsheet",
        overviewURL:"/api/get-all-transfer-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"transfer-list",
        orderByField:"requestingDateTime",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{
            dormOptions:[],
            staffOptions:[],
        },
    };

    componentDidMount=()=>{
        this.getOptions();
        const permissions = u.getPermissions("transfer");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add", mobileLabel:"Create Transfer Request",ref:"add",image:"/images/page-menu/add.png",title:"Create a new transfer request",enabled:(permissions.create==2)},
                {label:"Delete", mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit transfer details",enabled:bulkEditEnabled,padding:"3px"},
            ]
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    getOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                let options = this.state.options;
                options.dormOptions = dormOptions;
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });

        u.post({
            url:"/api/get-staff-options",
            data:{
                permission:{transfer:{update:2}}
            },
            success:(staffOptions)=>{
                staffOptions.unshift({value:"none",label:"None"});
                let options = this.state.options;
                options.staffOptions=staffOptions;
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    static getEstateLabelFrom(item){
        const config = EstateUtilities.getDormConfig();
        return (
          <span>
            {
              ((config.cluster) ? EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName : "") +
              ((config.building) ? (((config.cluster) ? " > " : "") + EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName) : "") +
              ((config.cluster || config.building) ? " > " : "")
            }
            <Link to={"/estate-list/room/"+item.roomID} target="_blank">
              <b>
              {
                 EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
              }
              </b>
            </Link>
          </span>
        )
    }

    static getEstateLabelTo(item){
        const config = EstateUtilities.getDormConfig();
        return (
          <span>
            <Link to={"/estate-list/room/"+item.roomID} target="_blank">
              <b>
              {
                 EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
              }
              </b>
            </Link>
            {
              (
                (config.cluster || config.building)
                ?
                " < "
                :
                ""
              ) +
              (
                (config.building)
                ?
                EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName + ((config.cluster) ? " < " : "")
                :
                ""
              ) +
              (
                (config.cluster)
                ?
                EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName
                :
                ""
              )
            }
          </span>
        )
    }

    static getEstateLabelText(item){
        const config = EstateUtilities.getDormConfig();
        return(
            ((config.cluster) ? EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName : "") +
            ((config.building) ? (((config.cluster) ? " > " : "") + EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName) : "") +
            ((config.cluster || config.building) ? " > " : "") +
            EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
        )
    }

    static getOwnerLabel(item){
        if(item.tenantID){
            return (<Link title={item.tenantName} target="_blank" to={"/resident/"+item.tenantID}>{item.tenantName}</Link>);
        }
        else if(item.clientID){
            return (<Link title={item.clientName} target="_blank" to={"/client/"+item.clientID}>{item.clientName}</Link>);
        }
        return "Not Assigned";
    }

    render=()=>{
        const pageComponents={
            add:{component:AddEntry,props:{options:this.state.options}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
            bulkEdit:{component:BulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}
                              >
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <TransferItem key={index} 
                                              index={index} 
                                              content={content} 
                                              onChange={onChange} 
                                              onContentChange={onContentChange} 
                                              registerReference={registerRef} 
                                              permissions={this.state.permissions}
                                              settings={this.settings}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

export class TransferItem extends React.Component{

    state={
        error:"",
        step:"view",
        loading:false,
        loadText:"",
        fixFrom:{},
        parentIndex:0
    };

    onResolve=()=>{
        this.setState({loading:true,loadText:"Resolving..."});
        u.post({
            url:"/api/resolve-transfer-request",
            data:{
                transferID:this.props.content.transferID,
            },
            success:()=>{
                this.props.onChange("Resolved","status",this.props.index);
                this.setState({step:"complete",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onTerminate=()=>{
        this.setState({loading:true,loadText:"Resolving..."});
        const {overrideURL} = this.props;
        u.post({
            url:(overrideURL?.terminateURL) ? overrideURL.terminateURL : "/api/terminate-transfer-request",
            data:{
                transferID:this.props.content.transferID,
            },
            success:()=>{
                this.props.onChange("Cancelled","status",this.props.index);
                this.setState({step:"cancelled",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onRemove=(index)=>{
        this.setState({index:index,step:"remove"});
    }

    onRemoveConfirm=()=>{
       this.setState({loading:true,loadText:"Removing line item...",error:""});
       const {overrideURL} = this.props;
       u.post({
          url:(overrideURL?.removeLineURL) ? overrideURL.removeLineURL : "/api/transfer-remove-line",
          data:{
              transferID:this.props.content.transferID,
              entry:this.props.content.transfers[this.state.index],
          },
          success:()=>{
              let transfers = this.props.content.transfers;
              if(transfers[this.state.index].child){
                  transfers[this.state.index-1].parent = false;
              }
              transfers.splice(this.state.index,1);
              this.props.onChange(transfers,"transfers",this.props.index);
              this.setState({step:"view",loading:false});
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
       });
    }

    onAddTransfer=(transferLine)=>{
        this.setState({loading:true,error:"",loadText:"Adding new line..."});
        const {overrideURL} = this.props;
        u.post({
          url:(overrideURL?.addLineURL) ? overrideURL.addLineURL : "/api/transfer-add-line",
          data:{
            transferID:this.props.content.transferID,
            parentIndex:(transferLine.child) ? this.state.parentIndex : undefined,
            parentEntry:(transferLine.child) ? this.props.content.transfers[this.state.parentIndex] : undefined,
            entry:transferLine
          },
          success:()=>{
             let transfers = this.props.content.transfers;
             if(transferLine.child){
                transfers[this.state.parentIndex].parent=true;
                transfers.splice(this.state.parentIndex+1,0,transferLine);
             }
             else{
                transfers.push(transferLine);
             }
             this.props.onChange(transfers,"transfers",this.props.index);
             this.setState({loading:false,step:"view",error:""});
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
        });
    }

    onAddFix=(from,index)=>{
        this.setState({fixFrom:from,parentIndex:index,step:"add-fixed",error:""});
    }

    render=()=>{
        const content = this.props.content;
        const edit = (this.props.permissions.update == 2);
        const {overrideURL}=this.props;

        return (
            <div style={{textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <Step active={(this.state.step == "view")}>

                    <DynamicListArea listLabel={(overrideURL?.listLabel) ? overrideURL.listLabel :this.props.settings.listLabel} 
                                     permission={this.props.settings.permission}
                                     content={this.props.content} 
                                     onChange={this.props.onChange} 
                                     index={this.props.index}
                                     />

                    <TransferTable content={content} 
                                   onRemove={this.onRemove} 
                                   noRemove={(content.status == "Cancelled" || content.status == "Resolved")} 
                                   canRemove={(content.transfers.length > 1 && edit)} 
                                   onAddFix={this.onAddFix}
                                   />

                    {
                        (content.status == "Pending" && edit)
                            ?
                            <div className="list-segment-buttons">
                                <TextButton onClick={()=>{this.setState({step:"add",error:""})}}>Add Transfer Item</TextButton>
                            </div>
                            :
                            null
                    }

                    {
                        (content.status == "Pending" && edit)
                            ?
                            <div className="list-segment-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"terminate",error:""})}}>Terminate</Button>
                                {
                                    (this.props.noResolve)
                                        ?
                                        null
                                        :
                                        <Button type="medium" onClick={()=>{this.setState({step:"resolve",error:""})}}>Resolve</Button>
                                }
                            </div>
                            :
                            null
                    }
                </Step>

                <Step active={(this.state.step == "resolve")}>
                    <div className="segment-content" style={{padding:"30px 0"}}>
                        <h2 className="text-emphasis">Resolve Transfer Request?</h2>
                        <div className="list-segment-note">Note. Action cannot be reversed</div>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onResolve}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "complete")}>
                    <div className="segment-content" style={{padding:"30px 0"}}>
                        <h2 className="text-emphasis">Successfully Resolved Transfer Request</h2>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "terminate")}>
                    <div className="segment-content" style={{padding:"30px 0"}}>
                        <h2 className="text-negative">Terminate Transfer Request?</h2>
                        <div className="list-segment-note">Note. Action cannot be reversed</div>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onTerminate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "cancelled")}>
                    <div className="segment-content" style={{padding:"30px 0"}}>
                        <h2 className="text-negative">Successfully Terminated Transfer Request</h2>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "remove")}>
                    <div className="segment-content" style={{padding:"30px 0"}}>
                        <h2 className="text-negative">Confirm Remove Line Item?</h2>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button type="medium" onClick={this.onRemoveConfirm}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "add")}>
                    <div className="segment-content" style={{fontSize:"1.1em"}}>
                        <TransferAddLine ref="addline" 
                                         dormID={content.dormID} 
                                         type={content.type}
                                         onAdded={this.onAddTransfer}
                                         overrideURL={overrideURL}
                                         />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={()=>{this.refs['addline'].validate()}}>Add</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step=="add-fixed")}>
                    <div className="segment-content" style={{fontSize:"1.1em"}}>
                        <TransferAddDestination ref="addfix" 
                                                dormID={content.dormID} 
                                                type={content.type} 
                                                from={this.state.fixFrom} 
                                                parentIndex={this.state.parentIndex} 
                                                parentEntry={content.transfers[this.state.parentIndex]} 
                                                onAdded={this.onAddTransfer}
                                                overrideURL={overrideURL}
                                                />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={()=>{this.refs['addfix'].validate()}}>Add</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

export class TransferTable extends React.Component{

    canDestinationDrop=(content,item)=>{
        if(content.status != "Pending"){
            return false;
        }

        if(content.type == "Room"){
            return false;
        }

        const list = content.transfers;
        //If no resident
        /*if(typeof item.to.tenantID == "undefined"){
            return false;
        }*/
        if(item.to.clientID == item.from.clientID && u.isNull(item.to.tenantID)){
            return false;
        }

        if(u.isNull(item.to.clientID) && u.isNull(item.to.tenantID)){
            return false;
        }

        //If bed exist in list from
        for(var i=0; i<list.length; i++){
            if(list[i].from.bedID == item.to.bedID){
                return false;
            }
        }

        return true;
    }

    canRemoveLine=(content,index)=>{
        return !(content[index].parent);
    }

    isOneWay=(content,item)=>{
        //If Room Transfer, True = 1 way transfer
        if(content.type == "Room"){
            //If destination has no client (empty)
            if(u.isNull(item.to.clientID)){
                return true;
            }
            return false;
        }
        //If Bed Transfer
        else if(content.type == "Bed"){
            //If row has a child, assume destination is empty as destination will transfer
            if(item.parent){
                return true;
            }

            //If destination has no client (empty)
            if(u.isNull(item.to.clientID)){
                return true;
            }

            //If destination is same client and has no tenant (empty)
            if(item.from.clientID == item.to.clientID && u.isNull(item.to.tenantID)){
                return true;
            }

            //If the destination has completed circularity -- A > B , B > C, C --> A (Circular)
            const list = content.transfers;
            for(var i=0; i<list.length; i++){
                if(list[i].from.bedID == item.to.bedID){
                    return true;
                }
            }
        }
        return false;
    }

    isCircular=(content,item,index)=>{
        //If i am a child, i must either point to empty bed or have a circular reference exist in the FOR section
        if(item.child){
            //No client, complete empty
            if(u.isNull(item.to.clientID)){
                return {valid:true};
            }
            //Client same, tenant empty
            if(item.to.clientID == item.from.clientID && u.isNull(item.to.tenantID)){
                return {valid:true};
            }
            //Find parent identifier
            let parentIndex = -1;
            for(var i=index; i>=0; i--){
                if(content.transfers[i].parent && !content.transfers[i].child){
                    parentIndex = i;
                    break;
                }
            }

            if(parentIndex == -1){
                return {valid:false};
            }

            let parent = content.transfers[parentIndex];

            //If destination == parent from location (considered empty)
            if(parent.from.bedID == item.to.bedID){
                return {valid:true};
            }

            //Added into from of following lines
            for(var j=index; j<content.transfers.length; j++){
                if(content.transfers[j].from.bedID == item.to.bedID){
                    return {valid:true}
                }
            }

            return {valid:false,parent:TransferList.getEstateLabelText(parent.from)};
        }

        return {valid:true};
    }

    render=()=>{
        const content=this.props.content;
        return (
          <div className="transfer-table">
              <table>
                  <tbody>
                      <tr>
                          <th className="transfer-remove" style={{borderTopLeftRadius:"5px"}}/>
                          <th className="transfer-from">From</th>
                          <th className="transfer-space"/>
                          <th className="transfer-to">To</th>
                          <th className="transfer-remove" style={{borderTopRightRadius:"5px"}}/>
                      </tr>
                      {
                        content.transfers.map(
                            (item,index)=>{
                                const toLabel = TransferList.getEstateLabelTo(item.to);
                                const toOwner = TransferList.getOwnerLabel(item.to);
                                const fromLabel = TransferList.getEstateLabelFrom(item.from);
                                const fromOwner = TransferList.getOwnerLabel(item.from);
                                const canDestinationDrop = this.canDestinationDrop(content,item);
                                const hasDependant = !this.canRemoveLine(content.transfers,index);
                                const isOneWay = this.isOneWay(content,item);
                                const isCircular = this.isCircular(content,item,index);

                                return (
                                   <tr className={"transfer-table-item" + ((item.parent) ? " parent" : "")} key={index}>
                                      <td className="transfer-remove">
                                        {
                                            (this.props.noRemove || hasDependant)
                                                ?
                                                null
                                                :
                                                (
                                                    (item.child)
                                                        ?
                                                        <div className={"transfer-button" + ((hasDependant || !(this.props.canRemove)) ? " disabled" : "")} title="Remove Transfer Line" onClick={()=>{this.props.onRemove(index)}}>
                                                            <img src="/images/transfer/arrow-up.png"/>
                                                        </div>
                                                        :
                                                        <div style={{width:"15px",height:"15px"}} title="Remove Transfer Line">
                                                            <MiniMinusButton index={index} enabled={(this.props.canRemove)} onClick={this.props.onRemove}/>
                                                        </div>
                                                )
                                        }
                                      </td>
                                      <td className="transfer-from">
                                          {fromLabel} <span className="transfer-small-text">(<div className="transfer-owner">{(fromOwner) ? fromOwner : null}</div>)</span>
                                      </td>
                                      <td className="transfer-space">
                                          {
                                            (isOneWay)
                                              ?
                                              <img src="/images/transfer/transfer-one-way.png" title="One Way Transfer"/>
                                              :
                                              <img src="/images/transfer/transfer-icon.png" title="Two Way Transfer"/>
                                          }
                                      </td>
                                      <td className={"transfer-to" + ((!isCircular.valid) ? " not-circular" : "")}>
                                          {(!isCircular.valid) ? console.log(isCircular) : null}
                                          <span className="transfer-small-text">(<div className="transfer-owner">{(toOwner) ? toOwner : null}</div>)</span> {toLabel}
                                          {
                                            (!isCircular.valid)
                                              ?
                                              <Info title={"One way transfers must end with an empty bed with no client assigned or the parent location ("+isCircular.parent+")"}
                                                    style={{
                                                        width:"15px",
                                                        height:"15px",
                                                        margin:"0 0 3px 3px"
                                                    }}
                                              />
                                              :
                                              null
                                          }
                                      </td>
                                      <td className="transfer-remove">
                                          {
                                              (canDestinationDrop)
                                                  ?
                                                  <div className="transfer-button" title="Transfer 'To' Resident" onClick={()=>{this.props.onAddFix(item.to,index)}}>
                                                      <img src="/images/transfer/arrow-down.png"/>
                                                  </div>
                                                  :
                                                  null
                                          }
                                      </td>
                                   </tr>
                                )
                            }
                        )
                      }
                  </tbody>
              </table>
          </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{
            status:"Pending",
            transfers:[],
        },
        fixFrom:{},
        step:"add",
        parentIndex:0,
    };

    typeOptions=[
        {value:"Room",label:"Room"},
        {value:"Bed",label:"Bed"},
    ];

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentWillUnmount=()=>{
        this.removeCache();
    }

    onComplete=()=>{
        let entryData=this.state.entryData;
        entryData.comments="";
        entryData.transfers=[];
        this.setState({error:"",entryData:entryData});
        this.removeCache();
    };

    onCancel=()=>{
        let entryData=this.state.entryData;
        entryData.comments="";
        entryData.transfers=[];
        this.setState({error:"",entryData:entryData});
        this.props.onToggleForm(this.props.reference,false);
        this.removeCache();
    }

    removeCache=()=>{
        store.remove('transfer-to-cache');
        store.remove('transfer-from-cache');
    }

    onChangeEntry=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label] = value;
        if(label == "dormID" || label == "type"){
            this.removeCache();
        }
        this.setState({entryData:entryData});
    }

    onAddTransfer=()=>{
        this.refs['addline'].validate();
    }

    onTransferAdded=(transferLine)=>{
        let entryData = this.state.entryData;
        if(transferLine.child){
           entryData.transfers[this.state.parentIndex].parent=true;
           entryData.transfers.splice(this.state.parentIndex+1,0,transferLine);
        }
        else{
            entryData.transfers.push(transferLine);
        }
        this.setState({entryData:entryData,step:"add",error:""});
    }

    onRemoveTransfer=(index)=>{
        let entryData=this.state.entryData;
        if(entryData.transfers[index].child){
            entryData.transfers[index-1].parent = false;
        }
        entryData.transfers.splice(index,1);
        this.setState({entryData:entryData});
    }

    onSubmit=()=>{
        const keys = ["dormID","type","assignedToStaffID","comments"];
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        //Validate Entries
        const entryData = this.state.entryData;
        if(entryData.transfers.length == 0){
            this.setState({error:"Please add at least 1 transfer item"});
            return 0;
        }

        this.refs[this.props.reference].onValidated(entryData);
    }

    onAddFix=(from,index)=>{
        this.setState({fixFrom:from,parentIndex:index,step:"transfer-fixed",error:""});
    }

    render=()=>{
        const content=this.state.entryData;
        const options = this.props.options;

        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         onCancel={this.onCancel}
                         >
                         <div>
                             <Step active={(this.state.step == "add")}>
                                 <div className="segment-content">
                                      <h3>Complete the following to create transfer</h3>
                                      <div className="segment-full-item">
                                          <div className="segment-item-label">Dormitory</div>
                                          <div className="segment-item-input"><Select ref="dormID" label="Dormitory" field="dormID" values={{value:content.dormID,options:options.dormOptions,enabled:(content.transfers.length == 0)}} onChange={this.onChangeEntry}/></div>
                                      </div>
                                      <div className="segment-full-item">
                                          <div className="segment-item-label">Transfer Type</div>
                                          <div className="segment-item-input"><Select ref="type" label="Type" field="type" values={{value:content.type,options:this.typeOptions,enabled:(content.transfers.length == 0)}} onChange={this.onChangeEntry}/></div>
                                      </div>
                                      <div className="segment-full-item">
                                          <div className="segment-item-label">Primary</div>
                                          <div className="segment-item-input"><Select ref="assignedToStaffID" label="Assigned To" field="assignedToStaffID" values={{value:content.assignedToStaffID,options:options.staffOptions}} onChange={this.onChangeEntry}/></div>
                                      </div>
                                      <div className="segment-full-item">
                                          <div className="segment-item-label">Comments</div>
                                          <div className="segment-item-input"><Area ref="comments" label="Comments" field="comments" values={{value:content.comments}} rules={{rows:3}} onChange={this.onChangeEntry}/></div>
                                      </div>

                                      {
                                          (content.transfers.length > 0)
                                            ?
                                            <div>
                                                <div className="add-transfer-table">
                                                    <TransferTable content={content} onRemove={this.onRemoveTransfer} canRemove={true} onAddFix={this.onAddFix}/>
                                                </div>
                                                <div className="transfer-placeholder-buttons">
                                                    <TextButton onClick={()=>{this.setState({step:"transfer",error:""})}}>Add Transfer Item</TextButton>
                                                </div>
                                            </div>
                                            :
                                            <div className="transfer-add-placeholder">
                                                <b>No Transfers Added</b>
                                                <div className="transfer-placeholder-buttons">
                                                    <TextButton onClick={()=>{this.setState({step:"transfer",error:""})}}>Add Transfer Item</TextButton>
                                                </div>
                                            </div>
                                      }

                                      <div className="segment-buttons">
                                          <div className="segment-error">{this.state.error}</div>
                                          <Button type="medium" onClick={this.onCancel}>Back</Button>
                                          <Button type="medium" onClick={this.onSubmit}>Confirm</Button>
                                      </div>
                                 </div>
                             </Step>

                             <Step active={(this.state.step == "transfer")}>
                                  <div className="segment-content">
                                      <TransferAddLine ref="addline" dormID={content.dormID} type={content.type} onAdded={this.onTransferAdded}/>

                                      <div className="segment-buttons">
                                          <Button type="medium" onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                                          <Button type="medium" onClick={this.onAddTransfer}>Add</Button>
                                      </div>
                                  </div>
                             </Step>

                             <Step active={(this.state.step == "transfer-fixed")}>
                                 <div className="segment-content">
                                     <TransferAddDestination ref="addline" dormID={content.dormID} type={content.type} from={this.state.fixFrom} parentIndex={this.state.parentIndex} parentEntry={content.transfers[this.state.parentIndex]} onAdded={this.onTransferAdded}/>

                                     <div className="segment-buttons">
                                         <Button type="medium" onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                                         <Button type="medium" onClick={this.onAddTransfer}>Add</Button>
                                     </div>
                                 </div>
                             </Step>
                         </div>
          </AddListItem>
        )
    }
}

//Add regular transfer line
export class TransferAddLine extends React.Component{

    state={
      loading:false,
      loadText:"Validating...",
      error:"",
    };

    validate=()=>{
        this.setState({error:""});

        const from = this.refs['from'].validate();
        if(!from.valid){
            this.setState({error:from.value});
            return 0;
        }
        const to = this.refs['to'].validate();
        if(!to.valid){
            this.setState({error:to.value});
            return 0;
        }

        this.setState({loading:true,loadText:"Finding details..."});
        const {overrideURL}=this.props;
        u.post({
          url:(overrideURL?.checkLineURL) ? overrideURL.checkLineURL : "/api/check-transfer-line",
          data:{
              from:from.value,
              to:to.value,
              type:this.props.type,
              dormID:this.props.dormID,
          },
          success:(transferLine)=>{
              this.setState({loading:false,error:""});
              this.props.onAdded(transferLine);
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
        });
    }

    render=()=>{
        return (
            <div className="add-line-container">
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <h3>Complete the following to continue</h3>

                <div className="add-location-container">
                    <TransferAddLineItem ref="from" 
                                         dormID={this.props.dormID} 
                                         type={this.props.type} 
                                         direction="from"
                                         overrideURL={this.props.overrideURL}
                                         />
                </div>

                <div className="add-line-image">
                    <img src="/images/transfer/transfer-icon.png"/>
                </div>

                <div className="add-location-container">
                    <TransferAddLineItem ref="to" 
                                         dormID={this.props.dormID} 
                                         type={this.props.type} 
                                         direction="to"
                                         overrideURL={this.props.overrideURL}
                                         />
                </div>

                <div className="transfer-error">{this.state.error}</div>
            </div>
        )
    }
}
TransferAddLine.propTypes={
    dormID:PropTypes.string,
    type:PropTypes.string,
    onAdded:PropTypes.func.isRequired,
}

export class TransferAddLineItem extends React.Component{

    state={
      step:"location",
      config:{},
      options:{
          clusterOptions:[],
          buildingOptions:[],
          levelOptions:[],
          roomOptions:[],
          bedOptions:[],
      },
      update:false,
      values:{},
    };

    componentDidMount=()=>{
        const config = EstateUtilities.getDormConfig();
        this.setState({
            config:config,
            options:{
                clusterOptions:[{value:"none",label:"Select " + config.clusterLabel}],
                buildingOptions:[{value:"none",label:"Select " + config.buildingLabel}],
                levelOptions:[{value:"none",label:"Select " + config.levelLabel}],
                roomOptions:[{value:"none",label:"Select " + config.roomLabel}],
                bedOptions:[{value:"none",label:"Select " + config.bedLabel}],
            },
        });

        //Check for
        /** transfer-label-cache
        {
            values:{cluster,building..},
            options:{clusterOptions,buildingOptions,...}
        }
        */
        const cache = this.getCache();
        console.log(cache);
        if(cache.options){
            this.setState({options:cache.options});
        }
        if(cache.values){
            this.setState({values:cache.values});
        }
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormID != state.dormID){
            state.update=true;
            state.dormID=props.dormID;
        }
        return state;
    }

    setCache=(values,options)=>{
        const label = "transfer-"+this.props.direction+"-cache";
        let cache = {};
        cache.values  = values;
        cache.options = options;
        store.set(label,cache);
    }

    getCache=()=>{
        const label = "transfer-"+this.props.direction+"-cache";
        console.log(label);
        const cache = store.get(label);
        return (cache) ? cache : {};
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            if(this.state.options.clusterOptions.length == 1){
                console.log("skipping");
                this.getEstateOptions("dorm",this.state.dormID);
            }
            else{
                console.log("polling");
            }
            this.setState({update:false});
        }
    }

    getEstateOptions=(parentType,id)=>{
        const nextType = EstateUtilities.getNextType(parentType);
        const {overrideURL}=this.props;
        u.post({
            url:(overrideURL?.getEstateURL) ? overrideURL.getEstateURL : "/api/get-estate-options",
            data:{
                parentType:parentType,
                type:nextType,
                id:id,
                withOccupant:true,
            },
            success:(callbackOptions)=>{
                const config = this.state.config;
                callbackOptions.unshift({value:"none",label:config[nextType+"Label"]});

                let options=this.state.options;

                if(nextType == "cluster"){
                    options.buildingOptions = [{value:"none",label:config.buildingLabel}];
                    options.levelOptions = [{value:"none",label:config.levelLabel}];
                    options.roomOptions = [{value:"none",label:config.roomLabel}];
                    options.bedOptions = [{value:"none",label:config.bedLabel}];
                }
                else if(nextType == "building"){
                    options.levelOptions = [{value:"none",label:config.levelLabel}];
                    options.roomOptions = [{value:"none",label:config.roomLabel}];
                    options.bedOptions = [{value:"none",label:config.bedLabel}];
                }
                else if(nextType == "level"){
                    options.roomOptions = [{value:"none",label:config.roomLabel}];
                    options.bedOptions = [{value:"none",label:config.bedLabel}];
                }
                else if(nextType == "room"){
                    options.bedOptions = [{value:"none",label:config.bedLabel}];
                }

                options[nextType+"Options"]=callbackOptions;

                this.setState({options:options});
                this.setCache(this.state.values,options);
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChangeEstate=(value,type,index,options)=>{
        let values=this.state.values;
        values[type]=value;
        this.setState({values:values});
        this.setCache(values,this.state.options);

        if(value != "none" && !(type == "room" && this.props.type == "Room")){
            this.getEstateOptions(type,value);
        }
    }

    onChangeSearch=()=>{
        this.setState({step:(this.state.step == "location") ? "resident" : "location",error:""});
    }

    validate=()=>{
      const keys=["clusterID","buildingID","levelID","roomID","bedID","searchParam"];
      let data={};
      for(var i=0; i<keys.length; i++){
          const current = (this.refs[keys[i]]) ? this.refs[keys[i]].validate() : {valid:true};
          if(!current.valid){
              return {
                  valid:false,
                  value:current.value
              }
          }
          data[keys[i]] = current.value;
      }
      return {
          valid:true,
          value:data
      }
    }

    render=()=>{
        const config=this.state.config;
        const options=this.state.options;
        const values=this.state.values;
        const colorFrom = "#48a1da" ;
        const colorTo= "#8bbf39";
        return(
          <div className="add-line-item">
              <Step active={(this.state.step=="location")}>
                  <div className="add-line-border">
                      <div className="add-line-title">Select <span style={{color:((this.props.direction == "from") ? colorFrom : colorTo)}}>{(this.props.direction == "from") ? "From" : "To"}</span> {(this.state.step == "location") ? "Location" : "Resident"}</div>

                      {
                          (config.cluster)
                            ?
                            <div className="add-line-input">
                                <Select ref="clusterID" label="Cluster" field="cluster" values={{value:values.cluster,options:options.clusterOptions}} rules={{required:true}} onChange={this.onChangeEstate}/>
                            </div>
                            :
                            null
                      }

                      {
                          (config.building)
                            ?
                            <div className="add-line-input">
                                <Select ref="buildingID" label="Building" field="building" values={{value:values.building,options:options.buildingOptions}} rules={{required:true}} onChange={this.onChangeEstate}/>
                            </div>
                            :
                            null
                      }

                      <div className="add-line-input">
                          <Select ref="levelID" label="Level" field="level" values={{value:values.level,options:options.levelOptions}} rules={{required:true}} onChange={this.onChangeEstate}/>
                      </div>

                      <div className="add-line-input">
                          <Select ref="roomID" label="Room" field="room" values={{value:values.room,options:options.roomOptions}} rules={{required:true}} onChange={this.onChangeEstate}/>
                      </div>

                      {
                          (this.props.type == "Bed")
                            ?
                            <div className="add-line-input">
                                <Select ref="bedID" label="Bed" field="bed" values={{value:values.bed,options:options.bedOptions}} rules={{required:true}}/>
                            </div>
                            :
                            null
                      }
                  </div>
              </Step>

              <Step active={(this.state.step=="resident")}>

                  <div className="add-line-border">
                       <div className="add-line-title">Select {(this.props.direction == "from") ? "From" : "To"} {(this.state.step == "location") ? "Location" : "Resident"}</div>
                       <div className="add-line-resident">Enter Resident Permit/FIN</div>
                       <div className="add-line-input">
                          <Box ref="searchParam" label="Work Permit/FIN" rules={{required:true}}/>
                       </div>
                  </div>
              </Step>

              <div className="add-line-text-buttons">
                {
                  (this.props.type == "Bed")
                  ?
                  <TextButton onClick={this.onChangeSearch}>Search {(this.state.step == "location") ? "Resident" : "Location"}</TextButton>
                  :
                  null
                }
              </div>
          </div>
        )
    }
}
TransferAddLine.propTypes={
    dormID:PropTypes.string,
    type:PropTypes.string,
}

//Add child transfer line
export class TransferAddDestination extends React.Component{

    state={
        loading:false,
        loadText:"Validating...",
        error:"",
    };

    validate=()=>{
        this.setState({error:""});

        const to = this.refs['to'].validate();
        if(!to.valid){
            this.setState({error:to.value});
            return 0;
        }

        this.setState({loading:true,loadText:"Finding details..."});
        const {overrideURL}=this.props;
        u.post({
          url:(overrideURL?.checkLineURL) ? overrideURL.checkLineURL : "/api/check-transfer-line",
          data:{
              from:this.props.from,
              to:to.value,
              type:this.props.type,
              dormID:this.props.dormID,
              parentIndex:this.props.parentIndex,
              parentEntry:this.props.parentEntry,
          },
          success:(transferLine)=>{
              this.setState({loading:false,error:""});
              this.props.onAdded(transferLine);
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
        });
    }

    render=()=>{
        return (
            <div className="add-line-container">
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <h3>Complete the following to continue</h3>

                <div className="add-location-container">
                    <TransferFixLineItem content={this.props.from}
                                         overrideURL={this.props.overrideURL}/>
                </div>

                <div className="add-line-image">
                    <img src="/images/transfer/transfer-icon.png"/>
                </div>

                <div className="add-location-container">
                    <TransferAddLineItem ref="to" 
                                         dormID={this.props.dormID} 
                                         type={this.props.type}
                                         direction="to"
                                         overrideURL={this.props.overrideURL}
                                         />
                </div>

                <div className="transfer-error">{this.state.error}</div>
            </div>
        )
    }
}
TransferAddDestination.propTypes={
    dormID:PropTypes.string,
    type:PropTypes.string,
    onAdded:PropTypes.func.isRequired,
    from:PropTypes.object.isRequired,
}

export class TransferFixLineItem extends React.Component{

    render=()=>{
        const content=this.props.content;
        const fromLabel = TransferList.getEstateLabelFrom(content);
        const colorFrom = "#48a1da" ;
        return(
          <div className="add-line-item">
              <div className="add-line-border">
                  <img src={(u.isNull(content.tenantAvatarLink)) ? u.getClientAvatar() : content.tenantAvatarLink} className="fix-image"/>
                  {
                    (content.tenantName)
                      ?
                      <div className="fix-detail">{content.tenantName}</div>
                      :
                      <div className="fix-detail">Empty Bed</div>
                  }
                  {
                    (content.tenantNRIC)
                      ?
                      <div className="fix-detail">{content.tenantNRIC}</div>
                      :
                      null
                  }
                  <div className="fix-detail">{content.clientName}</div>
                  <div className="fix-detail">{fromLabel}</div>
              </div>
              <div className="add-line-text-buttons">Fixed</div>
          </div>
        )
    }

}
TransferFixLineItem.propTypes={
    dormID:PropTypes.string,
    type:PropTypes.string,
}
