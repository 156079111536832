
const navButtons = [
    {name:"Dashboard", url:"/portal/dashboard",permission:"none",image:"/images/nav-menu/dashboard.png"},
    {name:"Resident", url:"/portal/resident-list",permission:"portal",image:"/images/nav-menu/tenants.png"},
    {name:"Home Leave", url:"/portal/absence-reason-list",permission:"portal",image:"/images/nav-menu/home-leave.png"},
    {name:"Transfer", url:"/portal/transfer-list",permission:"portal",image:"/images/nav-menu/transfer_v2.png"},
    {name:"History", url:"/portal/history-list",permission:"portal",image:"/images/nav-menu/history.png"},
];

export default navButtons;
