import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList,{MiniList} from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,Dropzone,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Select,Area,Box,EditSizeLabel,Date,Time,EmptyBox,EditSizeLink,TextButton,ContactNumber,Checkbox,InputPhoto,Info} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Photo} from "../data/Mimetypes";
import {AddTask} from './TaskList';
import {Link} from 'react-router-dom';
import store from 'store';
import "../styles/feedback-list.scss";

export default class ReportSickList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Report Sick",
        permission:"resident",
        url:"/report-sick-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-report-sick-entry",
        templateURL:"/api/get-add-report-sick-template",
        bulkURL:"/api/add-bulk-report-sick-entries",
        deleteURL:"/api/delete-report-sick-entries",
        spreadsheetURL:"/api/generate-report-sick-spreadsheet",
        overviewURL:"/api/get-all-report-sick-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"report-sick-list",
        orderByField:"reportedOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
        options:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("resident");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Entry",ref:"add",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onDeleteStep=(success)=>{
        this.refs['layout'].onPopup({
          confirm:()=>{
              success();
              this.refs['layout'].onCancelPopup();
          },
          title:"Confirm delete step?",
          description:"Note. This action cannot be undone",
        });
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ReportSickItem key={index}
                                                content={content}
                                                index={index}
                                                onChange={onChange}
                                                onContentChange={onContentChange}
                                                registerReference={registerRef}
                                                options={this.state.options}
                                                permissions={this.state.permissions}
                                                settings={this.settings}
                                                onDeleteStep={this.onDeleteStep}
                                                />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

export class MiniReportSickList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Report Sick",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/report-sick-list",
        spreadsheetURL:"/api/generate-report-sick-spreadsheet",
        overviewURL:"/api/get-all-report-sick-entries",

        /**** List Setup ****/
        listLabel:"report-sick-list",
        orderByField:"reportedOn",
        orderByType:"descending",
        //detailsLink:"/absence-reason-list/", //Leave null if linking to list
    };

    refresh=()=>{
        this.refs['list'].refresh();
    }

    render=()=>{
        return(
            <MiniList ref="list"
                      settings={this.settings}
                      tags={this.props.tags}
                      pageLength={this.props.pageLength}
                      orderByField={this.props.orderByField}
                      orderByType={this.props.orderByType}
            />
        )
    }
}

class ReportSickItem extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
    }

    onViewImage=(index,photoArray)=>{
        const image = this.props.content.supportingPhoto[index];
        this.setState({
            step:"view-photo",
            selectedImage:image.url,
            selectedImageIndex:index,
            selectedImageName:image.filename,
        });
    }

    onUploadPhoto=(url)=>{
        this.setState({loading:true,loadText:"Processing...",error:""});
        u.post({
            url:"/api/add-report-sick-photo",
            data:{
              reportSickID:this.props.content.reportSickID,
              filename:"(Web) MC Photo",
              url:url
            },
            success:()=>{
                let images=this.props.content.supportingPhoto;
                images.push({filename:this.state.filename,url:url});
                this.props.onChange(images,"supportingPhoto",this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDeletePhoto=()=>{
        this.setState({loading:true});
        u.post({
            url:"/api/delete-report-sick-photo",
            data:{
                reportSickID:this.props.content.reportSickID,
                imageURL:this.state.selectedImage,
                imageName:this.state.selectedImageName,
            },
            success:()=>{
                let images = this.props.content.supportingPhoto;
                images.splice(this.state.selectedImageIndex,1);
                this.props.onChange(images,"supportingPhoto",this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onStatusChange=()=>{
        this.setState({error:""});
        let data={};
        this.setState({loading:true,loadText:"Updating..."});
        data.status=(this.props.content.status == "Reported") ? "Submitted" : "Verified";
        data.reportSickID=this.props.content.reportSickID;
        u.post({
            url:"/api/update-report-sick-status",
            data:data,
            success:()=>{
                this.props.onChange(data.status,"status",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    checkCompletion=()=>{
        let content=this.props.content;
        let complete=true;
        let errorText="";

        if(content.supportingPhoto.length == 0){
            complete=false;
            errorText += ((errorText.length == 0) ? "Note. " : ", ") + "Photo missing";
        }
        if(!content.mcFromDate){
            complete=false;
            errorText += ((errorText.length == 0) ? "Note. " : ", ") + "MC From date missing";
        }
        if(!content.mcToDate){
            complete=false;
            errorText += ((errorText.length == 0) ? "Note. " : ", ") + "MC To date missing";
        }
        if(!content.placeOfConsultation){
            complete=false;
            errorText += ((errorText.length == 0) ? "Note. " : ", ") + "Place of Consultation missing";
        }
        if(!content.remarks){
            complete=false;
            errorText += ((errorText.length == 0) ? "Note. " : ", ") + "Remarks missing";
        }

        return {
            complete:complete,
            errorText:errorText
        }
    }

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        const canEdit = (content.status == "Reported" || content.status == "Submitted") && permissions.update;
        const checkCompletion = this.checkCompletion();

        return (
            <div style={{position:"relative",textAlign:'center'}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                    <DynamicListArea listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />

                    {
                        (content.supportingPhoto.length > 0)
                            ?
                            <div className="list-segment extended" style={{textAlign:'center',margin:"20px auto 0 auto"}}>
                                <h3 style={{textAlign:"center"}}>Supporting Photos</h3>
                                <div className="list-image-container">
                                {
                                    content.supportingPhoto.map(
                                        (item,index)=>{
                                            return(
                                                <div key={index} className="list-image-item">
                                                    <div className="list-image-flex"><img src={item.url} onClick={()=>{this.onViewImage(index,"supportingPhoto")}}/></div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                </div>
                            </div>
                            :
                            null
                    }

                    {
                        (canEdit)
                            ?
                            <div className="list-segment-buttons">
                                <TextButton onClick={()=>{this.setState({step:"upload-photo",error:""})}}>Add Photo</TextButton>
                            </div>
                            :
                            null
                    }

                    <div className="list-segment-buttons">
                        {
                            (canEdit)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"status-change"})}}>
                                    {(content.status == "Reported") ? "Set Submitted" : "Set Verified"}
                                </Button>
                                :
                                null
                        }
                    </div>
                </Step>

                <Step active={this.state.step == "view-photo"}>
                    <div className="list-image-container">
                        <h2>Photo: {this.state.selectedImageName}</h2>
                        <img className="list-view-image" src={this.state.selectedImage} onClick={()=>{window.open(this.state.selectedImage,"_blank")}}/>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            {
                                (permissions.update)
                                  ?
                                  <Button type="medium" onClick={()=>{this.setState({step:"delete-photo"})}}>Delete</Button>
                                  :
                                  null
                            }
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "upload-photo"}>
                    <div className="list-upload-photo">
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "delete-photo"}>
                    <div style={{padding:"30px 0 20px 0",textAlign:'center'}}>
                      <h2 className="text-negative">Confirm Delete Photo: {this.state.selectedImageName}</h2>
                      <div>Note. This action cannot be reversed</div>
                    </div>

                    <div className="segment-buttons">
                          <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                          <Button type="medium" onClick={this.onDeletePhoto}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "status-change"}>
                    <div style={{padding:"20px 0"}}>
                        <h2 className="text-emphasis">Declare Entry {(content.status == "Reported") ? "Submitted" : "Verified"}?</h2>
                        {
                            (checkCompletion.complete)
                                ?
                                null
                                :
                                <div className="text-negative" style={{marginBottom:"30px"}}>
                                    {checkCompletion.errorText}
                                </div>
                        }
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:'view',error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onStatusChange}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
      error:"",
      options:{
          placeOfConsultation:[],
      },
      staffOptions:[],
      entryData:{
          supportingPhoto:[],
          reportedOnDate:moment().format("YYYY-MM-DD"),
          reportedOnTime:moment().format("HH:mm:ss"),
      },
      loading:false,
      loadText:'',
      step:"add",
    };

    componentDidMount=()=>{
        this.getOptions();
    }

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "tenantFIN",
            "reportedOnDate",
            "reportedOnTime",
            "temperature",
            "reason",
            "status",
            "placeOfConsultation",
            "mcFromDate",
            "mcToDate",
            "remarks",
        ];

        for(var i=0; i<values.length; i++){
            if(this.refs[values[i]]){
                const field = this.refs[values[i]].validate();
                if(!field.valid){
                    this.setState({error:field.value});
                    return 0;
                }
            }
        }

        let data = Object.assign({},this.state.entryData);
        if(this.props.tenantFIN){
            data.tenantFIN=this.props.tenantFIN;
        }

        data.reportedOn = data.reportedOnDate + "T" + data.reportedOnTime;

        delete data.reportedOnDate;
        delete data.reportedOnTime;

        this.refs[this.props.reference].onValidated(data);
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["reportSickStatus","placeOfConsultation"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.refs[this.props.reference].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.setState({entryData:{
            supportingPhoto:[],
            deadlineDate:moment().add(1,"days").format("YYYY-MM-DD"),
            deadlineTime:moment().format("HH:00:00"),
        },error:""});
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onPhotoUploaded=(url)=>{
        let entryData = this.state.entryData;
        entryData.supportingPhoto.push({filename:this.state.filename,url:url});
        this.setState({entryData:entryData,step:"add",error:"",loading:false});
    }

    onPhotoRemoved=(index)=>{
        let entryData=this.state.entryData;
        entryData.supportingPhoto.splice(index,1);
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData=this.state.entryData;
        const options=this.state.options;

        return(
            <AddListItem ref={this.props.reference}
                         title={"Add Report Sick"}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onComplete}
                         onRefreshList={this.props.onRefreshList}
                         onToggleForm={this.props.onToggleForm}
                         stepScroll={true}
                         >
                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step == "add"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-error">{this.state.error}</div>

                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Resident FIN <Info title="NRIC/FIN of the resident who is reporting sick"/></div>
                                <div className="segment-item-input">
                                    <Box ref="tenantFIN"
                                         field="tenantFIN"
                                         label="Resident FIN"
                                         values={{
                                           value:(this.props.tenantFIN) ? this.props.tenantFIN : entryData.tenantFIN,
                                           enabled:!(this.props.tenantFIN)
                                         }}
                                         rules={{required:true}}
                                         onChange={this.onChange}
                                         />
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Reported On Date <Info title="When was this report made? Can be in the past"/></div>
                                <div className="segment-item-input">
                                    <Date ref="reportedOnDate" field="reportedOnDate" label="Reported On Date" values={{value:entryData.reportedOnDate}} range={[2,1]} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Reported On Time <Info title="What time was this report made? Can be in the past"/></div>
                                <div className="segment-item-input">
                                    <Time ref="reportedOnTime" field="reportedOnTime" label="Reported On Time" values={{value:entryData.reportedOnTime}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Temperature <Info title="Resident's thermometer reading at the time of reporting"/></div>
                                <div className="segment-item-input">
                                    <Box ref="temperature" field="temperature" label="Temperature" values={{required:true,value:entryData.feedbackType}} rules={{required:true,isFloat:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Reason <Info title="What is the reason for reporting sick? What are the observed symptoms?"/></div>
                                <div className="segment-item-input">
                                    <Area ref="reason" field="reason" label="Reason" values={{value:entryData.reason}} rules={{required:true,placeholder:"Description of the issue",rows:5}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div style={{position:"relative",width:"100%",height:"2px",backgroundColor:"#f4f4f4"}}/>
                            </div>

                            <div style={{margin:"20px auto"}}><b>Optional Information (Can be furnished later)</b></div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Status</div>
                                <div className="segment-item-input">
                                    <Select ref="status" field="status" label="Status" values={{value:entryData.status,options:options.reportSickStatus}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">MC From Date</div>
                                <div className="segment-item-input">
                                    <Date ref="mcFromDate" field="mcFromDate" label="MC From Date" range={[2,1]} values={{value:entryData.mcFromDate}} rules={{addEmpty:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">MC To Date</div>
                                <div className="segment-item-input">
                                    <Date ref="mcToDate" field="mcToDate" label="MC To Date" range={[2,1]} values={{value:entryData.mcToDate}} rules={{addEmpty:true,minDate:entryData.mcFromDate}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Place of Consult.</div>
                                <div className="segment-item-input">
                                    {
                                        (options.placeOfConsultation.length > 0)
                                            ?
                                            <Select ref="placeOfConsultation"
                                                    field="placeOfConsultation"
                                                    label="Place of Consult."
                                                    values={{
                                                      value:entryData.placeOfConsultation,
                                                      options:options.placeOfConsultation
                                                    }}
                                                    onChange={this.onChange}
                                                    />
                                            :
                                            <Box ref="placeOfConsultation"
                                                    field="placeOfConsultation"
                                                    label="Place of Consult."
                                                    values={{
                                                      value:entryData.placeOfConsultation,
                                                    }}
                                                    rules={{placeholder:"Optional"}}
                                                    onChange={this.onChange}
                                                    />
                                    }
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Remarks</div>
                                <div className="segment-item-input">
                                    <Area ref="remarks" field="remarks" label="Remarks" values={{value:entryData.remarks}} rules={{placeholder:"Optional",rows:5}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Supporting Photo <Info title="Did the resident submit supporting documents such as an MC"/></div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="supportingPhoto" field="supportingPhoto" label="Supporting Photo" values={{value:entryData.supportingPhoto}} rules={{max:5}} onChange={this.onChange}
                                                onAdd={()=>{this.setState({step:"photo",error:""})}}
                                                onRemove={this.onPhotoRemoved}
                                    />
                                </div>
                            </div>

                            <div className="segment-buttons" style={{fontSize:"0.85em"}}>
                                <TextButton onClick={()=>{this.refs[this.props.reference].onBulkUpload()}}>Bulk Upload</TextButton>
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "photo"}>
                        <div className="segment-error">{this.state.error}</div>
                        <Dropzone label="add-task-dropzone"
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.setState({filename:filename,loading:true,loadText:"Uploading "+filename+"..."})}}
                                  onUploaded={this.onPhotoUploaded}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Photo}
                        />
                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Back</Button>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}

//Add Entry - For Exported Use
export class AddReportSick extends React.Component{

    settings={
        addType:0,
        addURL:"/api/add-report-sick-entry",
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    render=()=>{
        return(
            <AddEntry ref={this.props.reference}
                      title="Add Report Sick"
                      settings={this.settings}
                      reference={this.props.reference}
                      tenantFIN={this.props.tenantFIN}
                      onComplete={this.props.onComplete}
                      onRefreshList={this.props.onRefreshList}
                      onToggleForm={this.props.onToggleForm}
                      />
        )
    }
}
