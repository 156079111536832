import React from 'react';
import {Databox,LoadGraphic,Step} from '../components/Common';
import PublicPageLayout from '../components/PublicPageLayout';
import {Box,Button,TextButton,InputOTP} from '../components/Input';
import sha from 'sha1';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import '../styles/create-user.scss';

export default class CreateUser extends React.Component{

    state={
        loading:false,
        loadText: "Preparing...",
        step:"create",
        entryData:{},
        error:"",
        pinEnabled:true,
        secret:{
            otpauth_url:"none",
        },
        dimensions:{width:0,height:0},
    };

    componentDidMount=()=>{
        const query = qs.parse(this.props.location.search);
        const passToken = query.token;
        const email = query.user;

        if(typeof passToken == "undefined" || typeof email == "undefined") {
            this.setState({error:"Error! Please access this page from the link sent to your email"});
        }

        this.setState({instantiated:true,passToken:passToken,email:email});

        this.getUserSecret(query.token,query.user);
    }

    getUserSecret=(token,email)=>{
        u.post({
            url:"/noauth/generate-totp-secret",
            data:{
                token:token,
                email:email,
            },
            success:(secret)=>{
                this.setState({secret:secret,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loadText:error});
            }
        });
    }

    onCreateUser=()=>{
        const input = ["password","passconf"];
        const entryData = this.state.entryData;

        for(var i=0; i<input.length; i++){
            const current = this.refs[input[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        if(entryData.password != entryData.passconf){
            this.setState({error:"Passwords do not match"});
            return 0;
        }

        this.setState({loading:true,error:""});

        u.post({
            url:"/noauth/create-user-precheck",
            data:{
                password:entryData.password,
                testDelay:2000,
            },
            success:()=>{
                this.setState({
                    step:"register-auth",
                    error:"",
                    loading:false,
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onOTP=()=>{
        const otp = this.refs['input-otp'].validate();
        if(!otp.valid){
            this.setState({error:otp.value});
            return 0;
        }
        this.setState({pinEnabled:false});

        u.post({
            url:"/noauth/verify-totp",
            data:{
                secret:this.state.secret.base32,
                otp:otp.value,
            },
            success:()=>{
                this.onCommitUser();
            },
            error:(error)=>{
                this.setState({
                  error:"OTP does not match, try again or hit back to register QR code",
                  pinEnabled:true,
                });
            }
        })
    }

    onCommitUser=()=>{
        this.setState({loading:true,error:""});

        u.post({
            url:"/noauth/create-user",
            data:{
                email:this.state.email,
                password:this.state.entryData.password,
                secret:this.state.secret.base32,
                token:this.state.passToken
            },
            success:(callback)=>{
                this.setState({step:"success",loading:false});
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onChange=(value,label,index)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const enabled=(typeof this.state.passToken != "undefined" && !this.state.loading);
        const entryData = this.state.entryData;
        const {width} = this.state.dimensions;

        return(
            <PublicPageLayout title="Create User" url="/create-user">
                <div style={{position:"relative",textAlign:"center"}}>
                    <div className="create-container">
                        <div className="create-message-container">
                            <h2 className="create-message">Create Account</h2>
                        </div>

                        <div className="page-error">{this.state.error}</div>

                        <Step active={(this.state.step == "create")}>
                            <div>
                                <div style={{position:"relative"}}>
                                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                                    <div className="create-form" style={{margin:"20px auto"}}>
                                        <div className="input-item">
                                            <div className="input-label">Email:</div>
                                            <Box ref="email" 
                                                 label="Email" 
                                                 values={{value:this.state.email,enabled:false}}
                                                 onEnterKey={()=>{this.refs['password'].focus()}}
                                                 />
                                        </div>
                                        <div className="input-item">
                                            <div className="input-label">Password:</div>
                                            <Box ref="password" 
                                                 label="Password" 
                                                 field="password" 
                                                 type="password" 
                                                 values={{value:entryData.password,enabled:enabled}} 
                                                 rules={{required:true,minLength:8}} 
                                                 onChange={this.onChange}
                                                 onEnterKey={()=>{this.refs['passconf'].focus()}}
                                                 />
                                        </div>
                                        <div className="input-item">
                                            <div className="input-label">Confirm Password:</div>
                                            <Box ref="passconf" 
                                                 label="Confirm Password" 
                                                 field="passconf" 
                                                 type="password" 
                                                 values={{value:entryData.passconf,enabled:enabled}} 
                                                 rules={{required:true}} 
                                                 onChange={this.onChange}
                                                 onEnterKey={this.onCreateUser}
                                                 />
                                        </div>
                                    </div>

                                    <div className="input-button">
                                        <Button onClick={this.onCreateUser} type="medium" enabled={enabled}>Create</Button>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "register-auth")}>
                            <div className="login-qr-container">

                                <h3 style={{marginBottom:"10px"}}>Register Authenticator</h3>

                                <div className="text-emphasis" style={{fontSize:"14px",marginBottom:"20px"}}><b>Scan the QR code into Google Authenticator</b></div>

                                <div style={{position:"relative",width:"200px",margin:"auto"}}>
                                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                                        {
                                            ({measureRef})=>
                                                (
                                                    <div ref={measureRef} className="auth-qr-image">
                                                        <QRCode value={this.state.secret.otpauth_url}
                                                                level="H"
                                                                fgColor="#000"
                                                                size={width}
                                                                />
                                                    </div>
                                                )
                                        }
                                    </Measure>
                                </div>

                                <div className="login-button">
                                    <Button onClick={()=>{this.setState({step:"confirm-otp",error:""})}}>Verify</Button>
                                </div>

                                <div className="login-text-button">
                                    <TextButton enabled={enabled} onClick={()=>{this.setState({step:"create"})}}>Back</TextButton>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "confirm-otp")}>

                            <h3 style={{marginBottom:"10px"}}>Enter OTP</h3>

                            <div className="text-emphasis" style={{fontSize:"14px",marginBottom:"10px"}}><b>Enter the OTP shown in the authenticator to verify</b></div>

                            <InputOTP ref="input-otp"
                                    onComplete={this.onOTP}
                                    enabled={this.state.pinEnabled}
                                    />

                            <div className="login-error">{this.state.error}</div>

                            <div className="login-button">
                                <Button onClick={this.onOTP}>Verify</Button>
                            </div>

                            <div className="login-text-button">
                                <TextButton enabled={enabled} onClick={()=>{this.setState({step:"register-auth"})}}>Back</TextButton>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "success")}>
                            <div style={{padding:"20px 0"}}>
                                <h3>
                                    <img src="/images/input/confirm.png" style={{height:"60px",display:"inline-block",verticalAlign:"middle",margin:"0 10px"}}/>
                                    <span style={{display:"inline-block",verticalAlign:"middle",margin:"20px 10px 5px 10px"}}>Account has been successfully created</span>
                                </h3>
                                <div className="disclaimer-text" style={{margin:"0 auto 20px auto"}}>
                                      <Link to="/login" title="Login here">Go to Login</Link>
                                </div>
                            </div>
                        </Step>
                    </div>
                </div>
            </PublicPageLayout>
        )
    }
}
