import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,SegmentComplete,
        Segment,LoadGraphic,Step,DynamicListArea,BulkEdit,DownloadTemplate,
        Dropzone} from '../components/Common';
import {EditSizeLabel,EditSizeLink,Select,Button,Date,Table} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';

export default class ResidentList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Resident",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/resident-list",

        /**** Action Setup ****/
        addType:2,
        //addURL:"/api/add-new-client",
        //templateURL:"/api/get-add-tenant-template",
        bulkURL:"/api/add-bulk-tenants",
        editUploadURL:"/api/upload-resident-bulk-edit-template",
        editTemplateURL:"/api/download-resident-bulk-edit-template",
        deleteURL:"/api/delete-tenant-entries",
        spreadsheetURL:"/api/generate-tenant-spreadsheet",
        overviewURL:"/api/get-all-tenants",

        /**** List Setup ****/
        list:true,
        listLink:"/resident/",
        listLabel:"resident-list",
        orderByField:"tenantName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)},
        onCancelPopup:()=>{this.refs['layout'].onCancelPopup()}
    };

    pageComponents={
        add:{component:AddResident,props:{}},
        delete:{component:DeleteListItem,props:{}},
        download:{component:ResidentDownloadTemplate,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        printCard:{component:BulkPrintCard,props:{}},
        absence:{component:BulkPrintCard,props:{}},
        covid:{component:BulkUploadCovidRecords,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("resident");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add Residents",ref:"add",enabled:(permissions.create==2)},
                {label:"Delete Entries",ref:"delete",enabled:(permissions.delete==2)},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
                {label:"Absence Reason",ref:"absenceReason",enabled:(permissions.update == 2),padding:"2px",onClick:this.onAbsenceReason},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Print Card",ref:"printCard",enabled:(permissions.create == 2),padding:"2px"},
                {label:"Upload Covid Records",ref:"covid",enabled:(permissions.update == 2),padding:"2px"},
            ],
            permissions:permissions,
        });
    }

    onAbsenceReason=()=>{
        window.location = "/absence-reason-list";
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ResidentItem key={index}
                                              index={index}
                                              content={content}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              settings={this.settings}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

export class ResidentItem extends React.Component{
    render=()=>{
        const {content,settings} = this.props;
        return (
            <div>
                <DynamicListArea content={this.props.content} 
                                 onChange={this.props.onChange} 
                                 index={this.props.index} 
                                 listLabel={settings.listLabel} 
                                 permission={settings.permission}
                                 />
                <div className="list-segment-buttons" style={{textAlign:"center"}}>
                    <Link to={settings.listLink+content.tenantID}>See More</Link>
                </div>
            </div>
        )
    }
}

export class AddResident extends React.Component{

    state={
      error:"",
      active:false,
      loading:false,
      loadText:"",
      step:"upload",
    };

    show=(show)=>{
        this.setState({active:show});
    }

    onComplete=()=>{
        this.setState({step:"upload",error:""});
    }

    onCancel=()=>{
        this.setState({step:"upload",error:""});
        this.props.onToggleForm(this.props.reference,false);
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading ...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loading:true,loadText:"Validating upload template..."});
        this.filename=filename;
        const {overrideURL}=this.props;
        u.post({
            url:(overrideURL?.validateURL) ? overrideURL.validateURL : "/api/validate-tenant-upload",
            data:{
                filename:filename
            },
            success:(warning)=>{
                if(warning.length == 0){
                    this.onCommit();
                }
                else{
                    this.setState({
                        loading:false,
                        step:"warning",
                        error:"",
                        warning:warning,
                    });
                }
            },
            error:(error,status)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:"Uploading..." + progress + "%"});
    }

    onCommit=()=>{
        this.setState({loading:true,loadText:"Attempting to create entries..."});
        const {overrideURL}=this.props;
        u.post({
            url:(overrideURL?.bulkAddURL) ? overrideURL?.bulkAddURL : "/api/add-bulk-tenants",
            data:{
                filename:this.filename
            },
            success:(numEntries)=>{
                this.setState({
                    loading:false,
                    step:"complete",
                    entryCount:numEntries,
                    type:"count"
                });

                setTimeout(()=>{
                  if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                  }
                },300);
            },
            error:(error,status)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onOverride=()=>{
        this.props.settings.onPopup(
            {
              confirm:()=>{
                  this.props.settings.onCancelPopup();
                  this.onCommit();
              },
              title:"Confirm Override Warnings?",
              description:"Residents will be uploaded immediately, undoing the above task may be time consuming"
            }
        )
    }

    render=()=>{
        return(
            <Segment active={this.state.active}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div style={{position:"relative",textAlign:"center"}}>
                    <Step active={this.state.step == "upload"}>
                        <div className="segment-title">Add Resident</div>
                        <Dropzone accessibleForPreview={false}
                                  onAdded={this.onFileAdded}
                                  multiple={false}
                                  onUploaded={this.onFileUploaded}
                                  onError={this.onFileError}
                                  onProgress={this.onFileProgress}
                                  filetypes={Spreadsheet}
                                  label={"dropzone-"+this.props.index}
                                  dragAndDropLabel={"Only accepts completed templates downloaded below"}
                        />

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={this.onCancel}>Cancel</Button>
                            <Button onClick={()=>{this.setState({step:"template",error:""})}}>Download Template</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step == "template"}>
                        <ResidentDownloadTemplate settings={this.props.settings}
                                                  reference={"download"}
                                                  onCancel={()=>{this.setState({step:"upload",error:""})}}
                                                  onToggleForm={()=>{}}
                                                  active={true}
                                                  noBorder={true}
                                                  client={this.props.client}
                                                  overrideURL={this.props.overrideURL}
                                                  />
                    </Step>

                    <Step active={this.state.step=="warning"}>
                        <div className="segment-title">Add Resident</div>
                        <div className="segment-content">
                            <h3 className="text-negative">Warnings have been identified from your template</h3>
                            <div style={{
                                    position:"relative",
                                    width:"1000px",
                                    maxWidth:"100%",
                                    fontSize:"13px",
                                    margin:"20px auto"
                                }}>
                                <Table fields={[
                                         {field:"location",label:"Location",style:{width:"150px"}},
                                         {field:"warning",label:"Warning"},
                                       ]}
                                       content={this.state.warning}
                                       minRow={5}
                                       maxHeight={350}
                                       />
                            </div>

                            <div className="segment-buttons segment-note">
                                If you wish to override the above warnings, click on override to continue with the upload
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button onClick={()=>{this.setState({step:"upload",error:""})}}>Back</Button>
                                <Button onClick={this.onOverride}>Override</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step=="complete"}>
                        <div className="segment-title">Add Resident</div>
                        <div style={{position:"relative",fontSize:(this.props.inList) ? "16px" : "1em"}}>
                            <SegmentComplete onComplete={this.onComplete} inList={false}>
                                <span>Successfully created <span className="segment-emphasis">{this.state.entryCount}</span>{(this.state.type == "count") ? " new " + ((this.state.entryCount == 1) ? "entry" : "entries") : ""}</span>
                            </SegmentComplete>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}

export class ResidentDownloadTemplate extends React.Component{

    state={
      error:"",
      clientOptions:[],
      dormOptions:[],
      agreementOptions:[],
    };

    componentDidMount=()=>{
        this.getClientOptions();
        this.getDormOptions();
    }

    getClientOptions=()=>{
        if(this.props.client){
            const {client}=this.props;
            this.setState({
                clientOptions:[
                    {value:client.clientID,label:client.clientName},
                ],
                clientID:client.clientID,
            });
        }
        else{
            u.post({
                url:"/api/get-client-options",
                data:{},
                success:(options)=>{
                    options.unshift({value:"none",label:" "});
                    this.setState({clientOptions:options});
                },
                error:(error)=>{
                    this.setState({error:error});
                }
            });
        }
    }

    getAgreementOptions=(clientID)=>{
        const {overrideURL}=this.props;
        if(clientID != "none"){
          this.setState({error:""});
          u.post({
              url:(overrideURL?.getAgreementURL) ? overrideURL?.getAgreementURL : "/api/get-client-agreement-options",
              data:{
                  clientID:clientID
              },
              success:(agreementOptions)=>{
                  agreementOptions.unshift({value:"none",label:""});
                  this.setState({agreementOptions:agreementOptions});
              },
              error:(error)=>{
                  this.setState({error:error})
              }
          });
        }
        else{
            this.setState({agreementOptions:[]});
        }
    }

    getDormOptions=()=>{
        const {overrideURL}=this.props;
        u.post({
            url:(overrideURL?.getEstateURL) ? overrideURL?.getEstateURL : "/api/get-estate-options",
            data:{
                type:"dorm",
                hasAssignment:true,
            },
            success:(dormOptions)=>{
                dormOptions.unshift({value:"none",label:" "});
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    onDownload=()=>{
        const{overrideURL}=this.props;
        this.setState({error:""});
        const client = this.refs['client'].validate();
        if(!client.valid){
            this.setState({error:client.value});
            return 0;
        }

        const agreement = (this.refs['agreementID']) ? this.refs['agreementID'].validate() : {valid:true};
        if(!agreement.valid){
            this.setState({error:agreement.value});
            return 0;
        }

        const dorm = (this.refs['dorm']) ? this.refs['dorm'].validate() : {valid:true};
        if(!dorm.valid){
            this.setState({error:dorm.value});
            return 0;
        }

        this.refs[this.props.reference].onDownload(
          {
              clientID:client.value,
              agreementID:agreement.value,
              dormID:dorm.value,
              overrideURL:overrideURL?.downloadFileURL,
          }
        );
    }

    onCancel=()=>{
        if(this.props.onCancel){
            this.props.onCancel();
        }
        else{
            this.props.onToggleForm(this.props.reference,false);
        }
    }

    render=()=>{
        const {overrideURL}=this.props
        return(
            <DownloadTemplate ref={this.props.reference}
                              reference={this.props.reference}
                              settings={this.props.settings}
                              url={(overrideURL?.templateURL) ? overrideURL.templateURL : "/api/get-add-tenant-template"}
                              active={this.props.active}
                              noBorder={this.props.noBorder}
                              onCancel={this.props.onCancel}
                              overrideURL={overrideURL}
                              >

                <div className="segment-content">
                    <h3>Select a organization & dormitory to attach the residents</h3>

                    <div className="segment-full-item" style={{margin:"30px auto 0 auto"}}>
                        <div className="segment-item-label">Organization:</div>
                        <div className="segment-item-input">
                            <Select ref="client"
                                    label="Organization"
                                    values={{
                                      value:this.props.clientID,
                                      options:this.state.clientOptions,
                                      enabled:(typeof this.props.clientID == "undefined")}}
                                      rules={{required:true}}
                                      onChange={this.getAgreementOptions}
                                    />
                        </div>
                    </div>

                    {
                        (this.state.agreementOptions.length > 1)
                            ?
                            <div className="segment-full-item" style={{margin:"10px auto 30px auto"}}>
                                <div className="segment-item-label">Agreement:</div>
                                <div className="segment-item-input">
                                    <Select ref="agreementID"
                                            label="Agreement"
                                            values={{options:this.state.agreementOptions}}
                                            rules={{required:true}}
                                            />
                                </div>
                            </div>
                            :
                            <div className="segment-full-item" style={{margin:"10px auto 30px auto"}}>
                                <div className="segment-item-label">Dormitory:</div>
                                <div className="segment-item-input">
                                    <Select ref="dorm" label="Dormitory" values={{options:this.state.dormOptions}} rules={{required:true}}/>
                                </div>
                            </div>
                    }

                    <div className="segment-note">
                        Ensure that you have assigned beds or rooms to the selected Organization before downloading this spreadsheet
                    </div>

                    <div className="segment-buttons">
                        <div className="segment-error">{this.state.error}</div>
                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                        <Button type="medium" onClick={this.onDownload}>Download</Button>
                    </div>
                </div>
            </DownloadTemplate>
        )
    }
}

class BulkPrintCard extends React.Component{

    state={
        active:false,
        loading:false,
        step:0,
        options:[],
    };

    componentDidMount=()=>{
        u.post({
            url:"/api/get-resident-card-themes",
            data:{},
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                console.log(error);
            }
        })
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onBack=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onSubmit=()=>{
        this.setState({error:""});
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        this.setState({loading:true,error:""});
        u.post({
            url:"/api/get-selected-tenants",
            data:{
                selected:this.props.selected
            },
            success:(tenants)=>{
                if(this.refs['theme']){
                    const theme = this.refs['theme'].validate();
                    tenants = tenants.map((item)=>{
                        item.overrideTheme=theme.value;
                        return item;
                    });
                }
                store.set("print-multi-card",tenants);
                store.set("print-multi-card-category","tenant");
                this.setState({loading:false});
                window.open("/print-multiple-card","_blank","width=800,height=800,left=200,top=100");
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const options = this.state.options;
        return(
            <Segment active={this.state.active} align={"center"}>
                <div className="list-add">
                    <div className="segment-title">Bulk Print Card</div>
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text="Preparing print preview..."/>
                    <div className="segment-content">
                        <h3>Please select the residents to print</h3>
                        {
                            (options.length > 0)
                                ?
                                <div className="segment-buttons" style={{width:"400px",maxWidth:"96%",margin:"auto"}}>
                                    <Select ref="theme"
                                            label="Card Theme"
                                            values={{options:options}}
                                            />
                                </div>
                                :
                                null
                        }
                        <div className="segment-selected-bg">
                            <div className="segment-selected"><u>{entries} {(entries==1) ? "entry" : "entries"} selected</u></div>
                            <div className="segment-note">Select entries from the list below</div>
                        </div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={this.onBack}>Cancel</Button>
                            <Button type="medium" onClick={this.onSubmit}>Print</Button>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}

class BulkUploadCovidRecords extends React.Component{
    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    onComplete=()=>{

    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        return(
          <AddListItem ref={this.props.reference}
                     settings={{
                       ...this.props.settings,
                       addType:2,
                       templateURL:"/api/get-bulk-covid-template",
                       bulkURL:"/api/add-bulk-covid-records",
                     }}
                     reference={this.props.reference}
                     filetypes={Spreadsheet}
                     onToggleForm={this.props.onToggleForm}
                     onComplete={this.onComplete}
                     onCancel={this.onCancel}
                     stepScroll={true}
                     dragAndDropLabel="Upload completed template spreadsheet here"
                     />
        )
    }
}
