import React from 'react';
import moment from 'moment';
import u from '../utilities/Utilities';
import store from 'store';
import "../styles/task-report.scss";

export default class TaskReport extends React.Component{
    state={
        content:{
          form:[],
          participants:[],
        },
        supportingPhotoMod:[],
        completedPhotoMod:[],
    }

    componentDidMount=()=>{
        const content = store.get("task-report");
        const supportingPhotoMod=this.imageArrayBreak(content.supportingPhoto);
        const completedPhotoMod=this.imageArrayBreak(content.completedPhoto);
        this.setState({
          content:content,
          supportingPhotoMod:supportingPhotoMod,
          completedPhotoMod:completedPhotoMod,
        });
    }

    setDef=(value,type)=>{
        if(!value){
            return "None";
        }
        else if(type == "datetime"){
            return moment(value).format("DD MMM YYYY - h:mma");
        }
        else if(type == "date"){
            return moment(value).format("DD MMM YYYY");
        }
        else if(type == "time"){
            return moment("2000-01-01T" + value).format("h:mma");
        }
        else if(type == "dow"){
            const labels = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
            let dow = "";
            for(var i=0; i<value.length; i++){
                if(value[i]){
                    dow = dow + ((dow.length > 0) ? " / " : "") + labels[i];
                }
            }
            return dow;
        }

        return value;
    };

    imageArrayBreak=(array)=>{
        console.log(array);
        if(!array){
            return [];
        }
        const isEven = (array.length % 2 == 0);
        let twoArray = [];
        let row = [];
        for(var i=0; i<array.length; i++){
            row.push(array[i]);
            if(i%2 == 1){
                //new object
                twoArray.push(row);
                row = [];
            }
        }
        if(!isEven){
            row.push[{}];
            twoArray.push(row);
        }
        return twoArray;
    }

    render=()=>{
        const content=this.state.content;
        const completedArr = content.participants.filter((item)=>{return item.action == "Completed"});
        const completedOn = (completedArr.length > 0) ? completedArr[0].dateTime : null;
        //console.log(content);
        return(
          <div className="task-report-container">
              <img className="report-header-image" src="/images/app/app-logo.png"/>
              <div className="report-title">{content.taskType} Report</div>

              <div className="report-section">
                  <div className="report-section-title">Task Information</div>
                  <div className="report-table">
                      <table>
                          <tbody>
                              <tr className="report-row-double">
                                <td><b>Dormitory</b></td>
                                <td>{this.setDef(content.dormName)}</td>
                                <td><b>Status</b></td>
                                <td>{this.setDef(content.status)}</td>
                              </tr>
                              <tr className="report-row-double">
                                <td><b>Deliverable</b></td>
                                <td>{this.setDef(content.formReference)}</td>
                                <td><b>Completed On</b></td>
                                <td>{this.setDef(completedOn,"datetime")}</td>
                              </tr>
                              <tr className="report-row-double">
                                <td><b>Task Location</b></td>
                                <td>{this.setDef(content.locationDetailsText)}</td>
                                <td><b>Grade</b></td>
                                <td>{this.setDef(content.grade)}</td>
                              </tr>
                              <tr className="report-row-double">
                                <td><b>Task Description</b></td>
                                <td>{this.setDef(content.taskDescription)}</td>
                                <td><b>Remarks</b></td>
                                <td>{this.setDef(content.remarks)}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              {
                  (this.state.supportingPhotoMod.length > 0)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Supporting Photos</div>
                          <div className="report-table">
                              <table>
                                  <tbody>
                                      {
                                          this.state.supportingPhotoMod.map(
                                              (row,rowIndex)=>{
                                                return(
                                                    <tr key={rowIndex}>
                                                        {
                                                            row.map(
                                                                (item,index)=>{
                                                                    return(
                                                                        <td className="report-item" key={index}>
                                                                            <img src={item.url} className="report-image"/>
                                                                            <div className="report-image-name">{item.filename}</div>
                                                                        </td>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                )
                                              }
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }

              {
                  (this.state.completedPhotoMod.length > 0)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Completion Photos</div>
                          <div className="report-table">
                              <table>
                                  <tbody>
                                      {
                                          this.state.completedPhotoMod.map(
                                              (row,rowIndex)=>{
                                                return(
                                                    <tr key={rowIndex}>
                                                        {
                                                            row.map(
                                                                (item,index)=>{
                                                                    return(
                                                                        <td className="report-item" key={index}>
                                                                            <img src={item.url} className="report-image"/>
                                                                            <div className="report-image-name">{item.filename}</div>
                                                                        </td>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                )
                                              }
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }

              {
                  (content.form.length > 0)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Custom Deliverables</div>
                          <div className="report-table">
                              <table>
                                  <tbody>
                                      {
                                          content.form.map(
                                              (item,index)=>{
                                                  return (
                                                    <tr key={index}>
                                                        <td className="report-item"><b>{item.label}</b></td>
                                                        {
                                                            (item.dataType == "photo")
                                                                ?
                                                                (
                                                                  (item.value)
                                                                      ?
                                                                      (item.value.url)
                                                                          ?
                                                                          <td className="report-item">
                                                                              <img src={item.value.url} className="report-image"/>
                                                                              <div className="report-image-name">{item.value.filename}</div>
                                                                          </td>
                                                                          :
                                                                          <td className="report-item"/>
                                                                      :
                                                                      <td className="report-item"/>
                                                                )
                                                                :
                                                                <td className="report-item">
                                                                  {this.setDef(item.value,item.dataType)}
                                                                </td>
                                                        }
                                                    </tr>
                                                  )
                                              }
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }

              <div className="report-section">
                  <div className="report-section-title">Participants</div>
                  <div className="report-table">
                      <table>
                          <tbody>
                              {
                                  content.participants.map(
                                      (item,index)=>{
                                          return (
                                              <tr key={index} className="report-row-double">
                                                  <td><b>{item.action} By</b></td>
                                                  <td>{item.user}</td>
                                                  <td><b>{item.action} On</b></td>
                                                  <td>{moment(item.dateTime).format("DD MMM YY - h:mma")}</td>
                                              </tr>
                                          )
                                      }
                                  )
                              }
                          </tbody>
                      </table>
                  </div>
              </div>

          </div>
        )
    }
}
