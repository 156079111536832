import React from 'react';
import AppLayout from '../../components/PortalLayout';
import OverviewList from '../../components/OverviewList';
import {PageMenu,CPGenerateSpreadsheet,AddListItem,CPBulkEdit,Step} from '../../components/Common';
import {Area,Select,TextButton,Button} from '../../components/Input';
import {EstateUtilities} from '../Estate';
import u from '../../utilities/Utilities';
import {Link} from 'react-router-dom';
import store from 'store';
import "../../styles/transfer-list.scss";
import {TransferItem,TransferAddLine,TransferAddDestination,TransferTable} from '../TransferList';

export default class PortalTransferList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Transfer Request",
        permission:"portal",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/transfer-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/client/cp-create-transfer-request",
        deleteURL:"/client/cp-delete-transfer-entries",
        spreadsheetURL:"/client/cp-generate-transfer-spreadsheet",
        overviewURL:"/client/cp-get-all-transfer-entries",
        listSettingsURL:"/client/cp-apply-list-user-settings",
        macroURL:"/client/cp-update-user-tag-macro",

        /**** List Setup ****/
        list:true,
        listLabel:"cp-transfer-list",
        orderByField:"requestingDateTime",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{
            dormOptions:[],
        },
    };

    componentDidMount=()=>{
        this.getOptions();
        const permissions = u.getPermissions("portal");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add", mobileLabel:"Create Transfer Request",ref:"add",image:"/images/page-menu/add.png",title:"Create a new transfer request",enabled:(permissions.view==2)},
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:(permissions.view==2)},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit transfer details",enabled:bulkEditEnabled,padding:"3px"},
            ]
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    getOptions=()=>{
        u.post({
            url:"/client/cp-get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                let options = this.state.options;
                options.dormOptions = dormOptions;
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    static getEstateLabelFrom(item){
        const config = EstateUtilities.getDormConfig();
        return (
          <span>
            {
              ((config.cluster) ? EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName : "") +
              ((config.building) ? (((config.cluster) ? " > " : "") + EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName) : "") +
              ((config.cluster || config.building) ? " > " : "")
            }
            <Link to={"/estate-list/room/"+item.roomID} target="_blank">
              <b>
              {
                 EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
              }
              </b>
            </Link>
          </span>
        )
    }

    static getEstateLabelTo(item){
        const config = EstateUtilities.getDormConfig();
        return (
          <span>
            <Link to={"/estate-list/room/"+item.roomID} target="_blank">
              <b>
              {
                 EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
              }
              </b>
            </Link>
            {
              (
                (config.cluster || config.building)
                ?
                " < "
                :
                ""
              ) +
              (
                (config.building)
                ?
                EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName + ((config.cluster) ? " < " : "")
                :
                ""
              ) +
              (
                (config.cluster)
                ?
                EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName
                :
                ""
              )
            }
          </span>
        )
    }

    static getEstateLabelText(item){
        const config = EstateUtilities.getDormConfig();
        return(
            ((config.cluster) ? EstateUtilities.getPrefixValue(item,config.clusterPrefix,true) + item.clusterName : "") +
            ((config.building) ? (((config.cluster) ? " > " : "") + EstateUtilities.getPrefixValue(item,config.buildingPrefix,true) + item.buildingName) : "") +
            ((config.cluster || config.building) ? " > " : "") +
            EstateUtilities.getPrefixValue(item,config.roomPrefix,true) + item.roomName + ((item.bedID) ? "/" + item.bedName : "")
        )
    }

    static getOwnerLabel(item){
        if(item.tenantID){
            return (<Link title={item.tenantName} target="_blank" to={"/resident/"+item.tenantID}>{item.tenantName}</Link>);
        }
        else if(item.clientID){
            return (<Link title={item.clientName} target="_blank" to={"/client/"+item.clientID}>{item.clientName}</Link>);
        }
        return "Not Assigned";
    }

    render=()=>{
        const pageComponents={
            add:{component:AddEntry,props:{options:this.state.options}},
            spreadsheet:{component:CPGenerateSpreadsheet,props:{}},
            bulkEdit:{component:CPBulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}
                              >
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <TransferItem key={index} 
                                              index={index} 
                                              content={content} 
                                              onChange={onChange}
                                              onContentChange={onContentChange} 
                                              registerReference={registerRef} 
                                              permissions={this.state.permissions}
                                              settings={this.settings}
                                              noResolve={true}
                                              overrideURL={{
                                                getEstateURL:"/client/cp-get-estate-options",
                                                checkLineURL:"/client/cp-check-transfer-line",
                                                removeLineURL:"/client/cp-transfer-remove-line",
                                                addLineURL:"/client/cp-transfer-add-line",
                                                terminateURL:"/client/cp-terminate-transfer-request",
                                                listLabel:"cp-transfer-list"
                                              }}
                                              />
                            )
                        }
                    }
                </OverviewList>
            </AppLayout>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{
            status:"Pending",
            transfers:[],
        },
        fixFrom:{},
        step:"add",
        parentIndex:0,
    };

    typeOptions=[
        {value:"Room",label:"Room"},
        {value:"Bed",label:"Bed"},
    ];

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentWillUnmount=()=>{
        this.removeCache();
    }

    onComplete=()=>{
        let entryData=this.state.entryData;
        entryData.comments="";
        entryData.transfers=[];
        this.setState({error:"",entryData:entryData});
        this.removeCache();
    };

    onCancel=()=>{
        let entryData=this.state.entryData;
        entryData.comments="";
        entryData.transfers=[];
        this.setState({error:"",entryData:entryData});
        this.props.onToggleForm(this.props.reference,false);
        this.removeCache();
    }

    removeCache=()=>{
        store.remove('transfer-to-cache');
        store.remove('transfer-from-cache');
    }

    onChangeEntry=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label] = value;
        if(label == "dormID" || label == "type"){
            this.removeCache();
        }
        this.setState({entryData:entryData});
    }

    onAddTransfer=()=>{
        this.refs['addline'].validate();
    }

    onTransferAdded=(transferLine)=>{
        let entryData = this.state.entryData;
        if(transferLine.child){
           entryData.transfers[this.state.parentIndex].parent=true;
           entryData.transfers.splice(this.state.parentIndex+1,0,transferLine);
        }
        else{
            entryData.transfers.push(transferLine);
        }
        this.setState({entryData:entryData,step:"add",error:""});
    }

    onRemoveTransfer=(index)=>{
        let entryData=this.state.entryData;
        if(entryData.transfers[index].child){
            entryData.transfers[index-1].parent = false;
        }
        entryData.transfers.splice(index,1);
        this.setState({entryData:entryData});
    }

    onSubmit=()=>{
        const keys = ["dormID","type","comments"];
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        //Validate Entries
        const entryData = this.state.entryData;
        if(entryData.transfers.length == 0){
            this.setState({error:"Please add at least 1 transfer item"});
            return 0;
        }

        this.refs[this.props.reference].onValidated(entryData);
    }

    onAddFix=(from,index)=>{
        this.setState({fixFrom:from,parentIndex:index,step:"transfer-fixed",error:""});
    }

    render=()=>{
        const content=this.state.entryData;
        const options = this.props.options;

        return (
           <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         onCancel={this.onCancel}
                         >
                <div>
                    <Step active={(this.state.step == "add")}>
                        <div className="segment-content">
                            <h3>Complete the following to create transfer</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input"><Select ref="dormID" label="Dormitory" field="dormID" values={{value:content.dormID,options:options.dormOptions,enabled:(content.transfers.length == 0)}} onChange={this.onChangeEntry}/></div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Transfer Type</div>
                                <div className="segment-item-input"><Select ref="type" label="Type" field="type" values={{value:content.type,options:this.typeOptions,enabled:(content.transfers.length == 0)}} onChange={this.onChangeEntry}/></div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Comments</div>
                                <div className="segment-item-input"><Area ref="comments" label="Comments" field="comments" values={{value:content.comments}} rules={{rows:3}} onChange={this.onChangeEntry}/></div>
                            </div>

                            {
                                (content.transfers.length > 0)
                                ?
                                <div>
                                    <div className="add-transfer-table">
                                        <TransferTable content={content} 
                                                       onRemove={this.onRemoveTransfer} 
                                                       canRemove={true} 
                                                       onAddFix={this.onAddFix}
                                                       />
                                    </div>
                                    <div className="transfer-placeholder-buttons">
                                        <TextButton onClick={()=>{this.setState({step:"transfer",error:""})}}>Add Transfer Item</TextButton>
                                    </div>
                                </div>
                                :
                                <div className="transfer-add-placeholder">
                                    <b>No Transfers Added</b>
                                    <div className="transfer-placeholder-buttons">
                                        <TextButton onClick={()=>{this.setState({step:"transfer",error:""})}}>Add Transfer Item</TextButton>
                                    </div>
                                </div>
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Back</Button>
                                <Button type="medium" onClick={this.onSubmit}>Confirm</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "transfer")}>
                        <div className="segment-content">
                            <TransferAddLine ref="addline" 
                                             dormID={content.dormID} 
                                             type={content.type} 
                                             onAdded={this.onTransferAdded}
                                             overrideURL={{
                                                getEstateURL:"/client/cp-get-estate-options",
                                                checkLineURL:"/client/cp-check-transfer-line",
                                             }}
                                             />

                            <div className="segment-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                                <Button type="medium" onClick={this.onAddTransfer}>Add</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "transfer-fixed")}>
                        <div className="segment-content">
                            <TransferAddDestination ref="addline" 
                                                    dormID={content.dormID} 
                                                    type={content.type} 
                                                    from={this.state.fixFrom}
                                                    parentIndex={this.state.parentIndex} 
                                                    parentEntry={content.transfers[this.state.parentIndex]} 
                                                    onAdded={this.onTransferAdded}
                                                    overrideURL={{
                                                        getEstateURL:"/client/cp-get-estate-options",
                                                        checkLineURL:"/client/cp-check-transfer-line",
                                                     }}
                                                    />

                            <div className="segment-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                                <Button type="medium" onClick={this.onAddTransfer}>Add</Button>
                            </div>
                        </div>
                    </Step>
                </div>
          </AddListItem>
        )
    }
}