import React from 'react';
import {Databox,LoadGraphic,Step} from '../components/Common';
import EmptyPageLayout from '../components/EmptyPageLayout';
import {Box,Button} from '../components/Input';
import {Doughnut,Line,Bar} from 'react-chartjs-2';
import sha from 'sha1';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import moment from 'moment';
import store from 'store';
import '../styles/usage-report.scss';

export default class UsageReport extends React.Component{

    state={
        loading:false,
        loadText: "Loading...",
        error:"",
        options:{
            showLines:true,
            spanGaps:false,
            legend:{
                display:false,
                labels:{
                    boxWidth:(this.props.smallMode ? 20 : 40),
                    padding:(this.props.smallMode ? 5 : 10),
                }
            },
            scales:{
                x:{
                    ticks: {
                        fontSize:10,
                        autoSkip: true,
                        autoSkipPadding: 20,
                    },
                },
                y: {
                    ticks: {
                        fontSize:10,
                        callback: function(value, index, values) {

                            if(value >= 1000000){
                                let v = value / 1000000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }
                                return v + 'M';
                            }
                            else if(value >= 10000){
                                let v = value / 1000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }

                                return v + "k";
                            }
                            else{
                                return value;
                            }
                        }
                    }
                }
            },
        },
        graphData:{
            datasets:[],
            labels:[],
        },
    };

    componentDidMount=()=>{
        this.getUsageReport();
    }

    getUsageReport=()=>{
        const usage = store.get("usage");
        this.setState({
            content:usage,
            graphData:{
                datasets:[
                    {
                        /*label: usage.dormName + " ("+moment(usage.startDate).format("DD/MM")+"-"+moment(usage.endDate).format("DD/MM")+")",*/
                        data: usage.history.yAxis,
                        backgroundColor: "#07abf2",
                        borderColor:"#07abf2",
                        borderWidth:1,
                        pointRadius:2,
                        pointBorderWidth:1,
                        pointHoverRadius:1,
                        pointBackgroundColor:"#07abf2",
                        lineTension:0,
                        fill:false,
                    }
                ],
                labels:usage.history.xAxis,
            }
        });
    }

    render=()=>{
        const content = this.state.content;
        if(typeof content == "undefined"){
            return <div/>
        }
        let licenseKey = [];
        for(var i=0; i<content.numberOfLicenses; i++){
            licenseKey.push(i+1);
        }
        return(
            <div>
                <div className="usage-container">
                    <div className="usage-header">
                        <img src="/images/app/app-logo.png"/>
                    </div>

                    <div className="audit-header">
                        <h2>Smartdorm Usage Report</h2>
                        <h2>{content.dormName} - <span className="audit-emphasis">{moment(content.startDate).format("DD MMM YYYY")} to {moment(content.endDate).format("DD MMM YYYY")}</span></h2>
                        <div className="audit-item" style={{display:"flex",flexDirection:"row",alignItems:"flex-start",justifyContent:"flex-start",maxHeight:"32px",overflow:"visible"}}>
                            <div style={{marginLeft:"1px",marginRight:"5px"}}>License Key(s):</div>
                            <div style={{flex:1}}>
                                {
                                    (content.numberOfLicenses > 1)
                                        ?
                                        licenseKey.map(
                                            (item,index)=>{
                                                return (
                                                    <div key={index}>
                                                        {content.licenseKey}-{item}
                                                    </div>
                                                )
                                            }
                                        )
                                        :
                                        content.licenseKey
                                }
                            </div>
                        </div>
                    </div>

                    <div className="audit-container left">
                        <h3>Asset Management:</h3>
                        <div className="audit-item">Total Dormitories: {content.asset.dormCount}</div>
                        <div className="audit-item">Total Buildings: {content.asset.buildingCount}</div>
                        <div className="audit-item">Total Rooms: {content.asset.roomCount}</div>
                        <div className="audit-item">Total Beds: {content.asset.bedCount}</div>
                    </div>
                    <div className="audit-container right">
                        <h3>Resident Management:</h3>
                        <div className="audit-item">Current Occupants: {content.resident.currentOccupants}</div>
                        <div className="audit-item">Previous Occupants: {content.resident.previousOccupants}</div>
                        <div className="audit-item">Data Request from MOM: {content.resident.momDataPull}</div>
                        <div className="audit-item">Data Pushed to MOM: {content.resident.momDataPush}</div>
                    </div>
                    <div className="audit-container left">
                        <h3>Security (Turnstiles):</h3>
                        <div className="audit-item">Total Entries: {content.security.totalEntry}</div>
                        <div className="audit-item">Total Exits: {content.security.totalExist}</div>
                        <div className="audit-item">Total Visitors: {content.security.totalVisitors}</div>
                        <div className="audit-item">Turnstile Uptime: {content.security.turnstileUptime}</div>
                    </div>
                    <div className="audit-container right">
                        <h3>Supporting Modules:</h3>
                        <div className="audit-item">Organizations: {content.supporting.clients}</div>
                        <div className="audit-item">Administrators: {content.supporting.administrator}</div>
                        <div className="audit-item">Transfers: {content.supporting.transfers}</div>
                        <div className="audit-item">Reports Generated: {content.supporting.reportsGenerated}</div>
                    </div>
                    <div className="audit-container left">
                        <h3>Finance Modules:</h3>
                        <div className="audit-item">Quotations Created: {content.finance.quotations}</div>
                        <div className="audit-item">Agreements Created: {content.finance.agreements}</div>
                        <div className="audit-item">Accessories Created: {content.finance.accessories}</div>
                        <div className="audit-item">Billing Entries: {content.finance.billing}</div>
                    </div>
                    <div className="audit-container right">
                        <h3>Operations Modules:</h3>
                        <div className="audit-item">Inspections Conducted: {content.operations.inspections}</div>
                        <div className="audit-item">Maintenance Tasks: {content.operations.maintenance}</div>
                        <div className="audit-item">Cleaning Tasks: {content.operations.cleaning}</div>
                        <div className="audit-item">Fines Created: {content.operations.fines}</div>
                    </div>
                    <div className="audit-container left">
                        <h3>Consumption Modules:</h3>
                        <div className="audit-item">Water Entries: {content.consumption.water}</div>
                        <div className="audit-item">Electricity Entries: {content.consumption.electricity}</div>
                        <div className="audit-item">Gas Entries: {content.consumption.gas}</div>
                        <div className="audit-item">Waste Disposal Entries: {content.consumption.waste}</div>
                    </div>
                    <div className="audit-container right">
                        <h3>Smart Metering:</h3>
                        <div className="audit-item">Water Meters: {content.smartMeter.water}</div>
                        <div className="audit-item">Electricity Meters: {content.smartMeter.electricity}</div>
                        <div className="audit-item">Gas Meters: {content.smartMeter.gas}</div>
                        <div className="audit-item">Waste Disposal: {content.smartMeter.waste}</div>
                    </div>


                    <div className="audit-history">
                        <h3>Historical Events Performed</h3>
                        <div className="audit-graph">
                            <Line data={this.state.graphData} options={this.state.options} redraw/>
                        </div>
                    </div>

                    <div className="audit-validity">
                          This report has been generated with the security hash {content.reportKey}.
                          To verify the authenticity of this report, please contact support@chimeric.sg with
                          the dormitory name and the respective security hash for our operators to provide
                          you with another copy of the same report.
                    </div>

                    <div className="usage-footer">
                        <img src="/images/app/footer.png"/>
                    </div>
                </div>
            </div>
        )
    }
}
