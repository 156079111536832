import React from 'react';
import u from '../utilities/Utilities';
import EmptyPageLayout from '../components/EmptyPageLayout';
import {Box,Button,TextButton,InputOTP} from '../components/Input';
import {ComponentPopup} from '../components/Popup';
import {Footer,Step} from '../components/Common';
import store from 'store';
import Measure from 'react-measure';
import QRCode from 'qrcode.react';
import moment from 'moment';
import "../styles/landing.scss";

export default class Landing extends React.Component{

    state={
        mounted:false,
        dimensions:{width:0,height:0},
    };

    componentDidMount=()=>{
        this.setState({mounted:true});
    }

    getGraphicScale=(width)=>{
        const c = 1440.0;
        if(width >= c || width <= 600){
          return {scale:1,margin:"auto"};
        }
        else{
          const scale = width/c;
          const margin = (scale-1.0)/2 * c;
          return {scale:scale,margin: (margin/2) +"px " + margin+"px"};
        }
    }

    showPopup=(show)=>{
        this.refs['popup'].show(show);
    }

    setPopupComponent=(component)=>{
        this.refs['popup'].setComponent(component);
    }

    render=()=>{
        if(!this.state.mounted){
            return (<div/>)
        }
        const dimensions = this.getGraphicScale(this.state.dimensions.width);
        return (
            <EmptyPageLayout title="Smartdorm | Dormitory Management System" url="/login" className="landing-page-container" style={{textAlign:'center'}}>

                <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                    {
                        ({measureRef})=>
                            (
                                <div ref={measureRef} className="lp-width"/>
                            )
                    }
                </Measure>

                <img src="/images/app/app-logo.png" className="lp-logo"/>

                <ImpressionSegment dimensions={dimensions}>
                    <LoginComponent showPopup={this.showPopup}
                                    setPopupComponent={this.setPopupComponent}/>
                </ImpressionSegment>

                <h3>In Collaboration With</h3>
                <img src="/images/landing-page/mom.png" className="lp-partner"/>
                <img src="/images/landing-page/dasl.png" className="lp-partner"/>
                <img src="/images/landing-page/imda-logo.png" className="lp-partner"/>

                <ContactUsSegment/>

                <ComponentPopup ref="popup"/>

            </EmptyPageLayout>
        )
    }

}

class ImpressionSegment extends React.Component{

    render=()=>{
        const d = this.props.dimensions;
        return (
            <div className="lp-segment" style={{transform:"scale("+d.scale+","+d.scale+")",margin:d.margin}}>
                <div className="lp-main">
                    <div className="lp-main-top">
                        <img className="lp-main-bottom-image" src="/images/landing-page/lp-main-bottom.png" />
                        <img className="lp-wheel lp-gear-slow" src="/images/landing-page/lp-main-wheel.png" />
                        <img className="lp-gear-orange lp-gear" src="/images/landing-page/gear-orange.png" />
                        <img className="lp-main-top-image" src="/images/landing-page/lp-main-top.png" />

                        <div className="lp-content-area">
                            {this.props.children}
                        </div>
                    </div>
                    <div className="lp-main-background">
                        <img className="lp-main-background-image" src="/images/landing-page/lp-main-bg.png" />
                        <img className="lp-gear-blue lp-gear" src="/images/landing-page/gear-blue.png" />
                        <img className="lp-gear-purple lp-gear" src="/images/landing-page/gear-purple.png" />
                        <div className="lp-blue-square"></div>
                        <div className="lp-purple-square"></div>
                    </div>
                </div>
            </div>
        )
    }
}

class LoginComponent extends React.Component{

    state={
        step:"login",
        error:"",
        avatarLink:"",
        email:"",
        loading:false,
        loadText:"Authenticating...",
        dimensions: { width: 0, height: 0 },
    }

    componentDidMount=()=>{
        const tempUser = store.get("tempUser");

        if(!tempUser){
            const auth = store.get("auth");
            if(auth){
                u.post({
                    url:"/api/validate-token",
                    data:{},
                    success:(callback)=>{
                        for(var key in callback){
                            store.set(key,callback[key]);
                        }
                        store.set("auth",moment().format("YYYY-MM-DDTHH:mm:ss"));

                        if(this.redirect){
                            window.location=this.redirect;
                        }
                        else{
                            window.location=(callback.favorite) ? callback.favorite : "/dashboard";
                        }
                    },
                    error:(error,status)=>{
                        u.logout();
                    }
                });
            }
        }

        const email = u.getUserEmail();
        const avatarLink = u.getUserAvatar();

        if(typeof email != "undefined" || typeof avatarLink != "undefined"){
            this.setState({email:email,avatarLink:avatarLink,step:"login-prefill"});
        }
    }

    onLogin=()=>{
        let email = {value:this.state.email};
        if(this.state.step == "login"){
            email = this.refs['email'].validate();
            if(!email.valid){
                this.setState({error:email.value});
                return 0;
            }
        }
        const password = this.refs['password'].validate();
        if(!password.valid){
            this.setState({error:password.value});
            return 0;
        }

        this.email=email.value;
        this.password=password.value;
        this.setState({step:"pin",error:""});
    }

    onSelectOwner=(token)=>{
        u.post({
            url:"/noauth/login-with-token",
            data:{
                token:token
            },
            success:(callback)=>{
                for(var key in callback){
                    store.set(key,callback[key]);
                }
                store.remove("dashboard-action");
                store.remove("dashboard-action-label");
                store.set("tempUser",false);
                store.set("auth",moment().format("YYYY-MM-DDTHH:mm:ss"));

                const redirect = store.get("redirect");
                if(redirect){
                    store.remove("redirect");
                    window.location=redirect;
                }
                else{
                    window.location=(callback.favorite) ? callback.favorite : "/dashboard";
                }
            },
            error:(error,errorCode)=>{
                if(errorCode == 420){
                    this.setState({
                        step:"reset-password",
                        expiry:true,
                        loading:false,
                        pinEnabled:true
                    });
                }
                else{
                    this.setState({error:error,loading:false,pinEnabled:true});
                }
                if(this.refs['password']){
                    this.refs['password'].clear();
                }
                if(this.refs['input-otp']){
                    this.refs['input-otp'].clear();
                }
            }
        })
    }

    onResetPassword=()=>{
        let keys = ["email","password","cnfpassword"];
        let data = {};
        for(var i=0; i<keys.length; i++){
            let current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]] = current.value;
        }

        if(data.password != data.cnfpassword){
            this.setState({error:"Passwords do not match"});
            return 0;
        }

        u.post({
            url:"/noauth/get-reset-password-options",
            data:{
                email:data.email,
                password:data.password
            },
            success:(options)=>{
                const resetPassword=(token)=>{
                    u.post({
                        url:"/noauth/reset-password-with-token",
                        data:{
                            token:token
                        },
                        success:()=>{
                            this.setState({step:"reset-success",error:""});
                        },
                        error:(error)=>{
                            this.setState({error:error});
                        }
                    });
                };

                if(options.length > 1){
                    this.props.setPopupComponent(
                        <OwnerSelect ownerOptions={options}
                                    onCancel={()=>{this.props.showPopup(false)}}
                                    onConfirm={(token)=>{
                                            resetPassword(token);
                                            this.props.showPopup(false);
                                        }}
                                    />
                    )
                    this.props.showPopup(true);
                }
                else{
                    resetPassword(options[0].token);
                }

            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onNotMe=()=>{
        u.removeStore();
        this.setState({email:"",step:"login",error:""});
    }

    validatePin=()=>{
        this.setState({error:"",pinEnabled:false});
        const otp=this.refs['input-otp'].validate();
        if(!otp.valid){
            this.setState({error:otp.value,pinEnabled:true});
            return 0;
        }

        u.post({
            url:"/noauth/get-owner-options",
            data:{
                email:this.email,
                password:this.password,
                otp:otp.value,
            },
            success:(ownerOptions)=>{
                if(ownerOptions.length > 1){
                    this.props.setPopupComponent(
                        <OwnerSelect ownerOptions={ownerOptions}
                                     onCancel={()=>{this.props.showPopup(false)}}
                                     onConfirm={(token)=>{
                                        this.onSelectOwner(token);
                                        this.props.showPopup(false);
                                     }}
                                     />
                    )
                    this.props.showPopup(true);
                }
                else{
                    this.onSelectOwner(ownerOptions[0].token);
                }
            },
            error:(error,errorCode)=>{
                if(errorCode == 420){
                    this.setState({
                        step:"reset-password",
                        expiry:true,
                        loading:false,
                        pinEnabled:true,
                    });
                }
                else{
                    this.setState({error:error,loading:false,pinEnabled:true});
                }
                if(this.refs['password']){
                    this.refs['password'].clear();
                }
                if(this.refs['input-otp']){
                    console.log("clearing");
                    this.refs['input-otp'].clear();
                }
            }
        })
    }

    onResetAuthRequest=()=>{
        this.setState({loading:true,loadText:"Preparing..."});
        u.post({
            url:"/noauth/generate-totp-secret",
            data:{},
            success:(secret)=>{
                this.setState({
                    secret:secret,
                    loading:false,
                    step:"reset-authenticator",
                });
            },
            error:(error)=>{
                this.setState({error:error,loading:false})
            }
        })
    }

    validateAuthenticator=()=>{
        let keys=['email',"otp"];
        let data={};
        for (var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }
        data.secret=this.state.secret.base32;

        this.setState({loading:true,loadText:"Verifying OTP..."});

        u.post({
            url:"/noauth/verify-totp",
            data:{
                secret:data.secret,
                otp:data.otp,
            },
            success:()=>{
                this.setState({loadText:"Updating..."});
                u.post({
                    url:"/noauth/admin-reset-authenticator",
                    data:data,
                    success:()=>{
                        this.setState({step:"reset-success",loading:false,error:""});
                    },
                    error:(error)=>{
                        this.setState({loading:false,error:error,pinEnabled:true});
                    }
                })
            },
            error:(error)=>{
                this.refs['otp'].clear();
                this.setState({loading:false,error:error,pinEnabled:true});
            }
        });

    }

    render=()=>{
        const enabled = !this.state.loading;
        const {width} = this.state.dimensions;
        return (
            <div className="login-container">
                <Step active={this.state.step=="login"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <img src="/images/app/app-icon.png" className="login-app-icon"/>

                        <h3 className="mobile-only">Login Here!</h3>

                        <div className="login-input">
                            <Box ref="email" label="Email" type="email" values={{value:this.state.email,enabled:enabled}} rules={{required:true,placeholder:"Email"}} onChange={(value)=>{this.setState({email:value})}} onEnterKey={()=>{this.refs['password'].focus()}}/>
                        </div>
                        <div className="login-input">
                            <Box ref="password" label="Password" type="password" values={{enabled:enabled}} rules={{required:true,placeholder:"Password"}} onEnterKey={this.onLogin}/>
                        </div>

                        <div className="login-error">{this.state.error}</div>
                        <div className="login-button">
                            <Button type="large" enabled={enabled} onClick={this.onLogin}>Login</Button>
                        </div>
                        <div className="login-text-button">
                            <TextButton enabled={enabled} onClick={()=>{this.setState({step:"reset-password",expiry:false})}}>Reset Password</TextButton>
                            <TextButton enabled={enabled} onClick={this.onResetAuthRequest}>Reset Authenticator</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="login-prefill"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <img src={this.state.avatarLink} className="login-app-icon avatar" style={{borderRadius:"50%"}}/>
                        <h3 className="mobile-only">Login Here!</h3>

                        <div className="login-input">
                            <div style={{margin:"0",textAlign:"center",padding:"3px 0"}} className="text-emphasis"><b>{this.state.email}</b></div>
                        </div>
                        <div className="login-input">
                            <Box ref="password" label="Password" type="password" values={{enabled:enabled}} rules={{required:true,placeholder:"Password"}} onEnterKey={this.onLogin}/>
                        </div>
                        <div className="login-error">{this.state.error}</div>
                        <div className="login-button">
                            <Button type="large" enabled={enabled} onClick={this.onLogin}>Login</Button>
                        </div>
                        <div className="login-text-button">
                            <TextButton enabled={enabled} onClick={()=>{this.setState({step:"login"})}}>Not Me</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="reset-password"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <h3>
                        {
                            (this.state.expiry)
                                ?
                                "Password Expired"
                                :
                                "Reset Password"
                        }
                        </h3>

                        {
                            (this.state.expiry)
                                ?
                                <div className="text-emphasis" style={{marginBottom:"10px"}}>
                                    <b>Your organization has a password expiry policy, please reset your password</b>
                                </div>
                                :
                                null
                        }
                        <div className="login-input">
                            <Box ref="email" label="Email" type="email" values={{value:this.state.email,enabled:enabled}} rules={{required:true,placeholder:"Email"}} onChange={(value)=>{this.setState({email:value})}} onEnterKey={()=>{this.refs['password'].focus()}}/>
                        </div>

                        <div className="login-input">
                            <Box ref="password" label="New Password" type="password" values={{enabled:enabled}} rules={{required:true,placeholder:"New Password"}} onEnterKey={()=>{this.refs['cnfpassword'].focus()}}/>
                        </div>

                        <div className="login-input">
                            <Box ref="cnfpassword" label="Confirm Password" type="password" values={{enabled:enabled}} rules={{required:true,placeholder:"Confirm Password"}} onEnterKey={this.onResetPassword}/>
                        </div>

                        <div className="login-error">{this.state.error}</div>

                        <div className="login-button">
                            <Button type="large" enabled={enabled}onClick={this.onResetPassword}>Reset Password</Button>
                        </div>
                        <div className="login-text-button">
                            <TextButton enabled={enabled}onClick={()=>{this.setState({step:"login"})}}>Login Here</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reset-authenticator"}>
                    <div className="login-step">
                        <h3 style={{marginTop:"60px",marginBottom:"5px"}}>Scan the QR code into Google Authenticator</h3>
                        
                        <div style={{position:"relative",width:"180px",margin:"auto"}}>
                            <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                                {
                                    ({measureRef})=>
                                        (
                                            <div ref={measureRef} className="auth-qr-image">
                                                <QRCode value={this.state.secret.otpauth_url}
                                                        level="H"
                                                        fgColor="#000"
                                                        size={width}
                                                        />
                                            </div>
                                        )
                                }
                            </Measure>
                        </div>

                        <div className="login-button">
                            <Button type="large" style={{maxWidth:"300px"}} onClick={()=>{this.setState({step:"confirm-otp",error:""})}}>Next</Button>
                        </div>
                        
                        <div className="login-text-button">
                            <TextButton enabled={enabled} onClick={() => { this.setState({ step: "login", expiry: false }) }}>Login Here</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="reset-success"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <h3 className="text-emphasis">Email Link Sent</h3>
                        <div className="login-text">
                            To confirm your password or authenticator change, please check your email for a link from Smartdorm. Change will only take effect after link is used.
                        </div>
                        <div className="login-button" style={{margin:"20px auto"}}>
                            <Button type="large" onClick={()=>{this.setState({step:"login"})}}>Login</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "pin"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <h3>Enter OTP</h3>

                        <div style={{margin:"0 auto 15px auto",fontSize:"14px"}}>Please enter the 6 digit one time pin from your registered authenticator</div>

                        <InputOTP ref="input-otp"
                                  enabled={this.state.pinEnabled}
                                  onComplete={this.validatePin}
                                 />

                        <div className="login-error">{this.state.error}</div>
                        <div className="login-button">
                            <Button type="large" enabled={enabled} onClick={this.validatePin}>Confirm</Button>
                        </div>
                        <div className="login-text-button">
                            <TextButton enabled={enabled} onClick={()=>{this.setState({step:"login", expiry:false})}}>Back</TextButton>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "confirm-otp"} style={{position:"relative",width:"100%",height:'100%'}}>
                    <div className="login-step">
                        <h3 style={{margin:"10px auto 5px auto"}}>Verify Email & Authenticator</h3>

                        <div className="login-input">
                            <Box ref="email"
                                 label="Email"
                                 type="email"
                                 values={{
                                   value: this.state.email,
                                   enabled: enabled
                                 }}
                                 rules={{
                                   required: true,
                                   placeholder: "Account Email"
                                 }}
                                 onChange={(value) => {this.setState({email: value})}}
                                 />
                        </div>

                        <div className="login-input" style={{margin:"0 auto",background:"lightgrey",borderRadius:"5px",padding:"1px 0"}}>
                            <InputOTP ref="otp"
                                      label="OTP"
                                      type="number"
                                      rules={{
                                        required: true,
                                        placeholder: "New OTP"
                                      }}
                                      onComplete={this.validateAuthenticator}
                                      />
                        </div>

                        <div className="login-error">{this.state.error}</div>

                        <div className="login-button">
                            <Button type="large" enabled={enabled} onClick={this.validateAuthenticator}>Verify</Button>
                        </div>
                        <div className="login-text-button">
                            <TextButton enabled={enabled} onClick={()=>{this.setState({step:"reset-authenticator"})}}>Back</TextButton>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class ContactUsSegment extends React.Component{

    render(){
        return (
            <div className="contact">
                  <div className="lp-contact-left mobile-hide">
                      Chimeric Technologies Pte. Ltd.<br/>
                      3 Ang Mo Kio St 62, #05-34<br/>
                      Singapore 569139<br/>
                  </div>
                  <div className="lp-contact-right">
                      Hotline: (Weekdays) 10:00 - 18:00<br/>
                      Email: support@chimeric.sg<br/>
                      Office: (+65) 6253 1108<br/>
                  </div>

                <img src="/images/app/footer.png" className="lp-footer"/>
            </div>
        )
    }
}

export class OwnerSelect extends React.Component{
    state={
       selected:-1
    }

    onConfirm=()=>{
        const token = this.props.ownerOptions[this.state.selected].token;
        this.props.onConfirm(token);
    }

    render=()=>{
        const ownerOptions=this.props.ownerOptions;
        return (
            <div className="owner-select-component">
                <div className="owner-select-title">
                    <h2>Select Your Organization</h2>
                </div>
                <div className="owner-select-content">
                    {
                        ownerOptions.map(
                            (item,index)=>{
                                return (
                                    <div key={index}
                                         className={"owner-select-options-container" + ((this.state.selected == index) ? " selected" : "")}
                                         onClick={()=>{this.setState({selected:index})}}>
                                        <b>{item.ownerName}</b>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
                <div className="owner-select-buttons">
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button enabled={(this.state.selected != -1)} onClick={this.onConfirm}>Confirm</Button>
                </div>
            </div>
        )
    }
}
