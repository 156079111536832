import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,LoadGraphic,
        AddListItem,Step,Dropzone,BulkEdit} from '../components/Common';
import {Button,EditableBox,EditableArea,EditSizeLabel,EditableSelect,
        EditSizeLink,TextButton,MiniMinusButton,Select,
        Box,EmptyBox,Date,Area,Time} from '../components/Input';
import u from '../utilities/Utilities';
import {Spreadsheet,Attachment} from '../data/Mimetypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {AddSearchTag,Tag} from '../components/OverviewList';
import '../styles/survey-list.scss';

export default class SurveyList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Survey",
        permission:"survey",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/survey-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/create-survey-entry",
        //templateURL:"/api/get-add-staff-template",
        //bulkURL:"/api/add-bulk-staff",
        deleteURL:"/api/delete-survey-entries",
        spreadsheetURL:"/api/generate-survey-spreadsheet",
        overviewURL:"/api/get-all-survey-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"survey-list",
        orderByField:"lastPublishOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{}
    };

    getOptions=()=>{
       u.post({
          url:"/api/get-options",
          data:{
              keys:["targetUser","notificationType","surveyPrompt","surveyDuration","surveyAnswerType"]
          },
          success:(options)=>{
              this.setState({options:options});
          },
          error:(error)=>{
              this.setState({error:error});
          }
       });
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add Entry",ref:"add",enabled:permissions.create},
                {label:"Delete Entries",ref:"delete",enabled:permissions.delete},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled},
            ],
            permissions:permissions,
        });
        this.getOptions();
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        const pageComponents={
            add:{component:AddSurvey,props:{options:this.state.options}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
            bulkEdit:{component:BulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}
                />

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                              <SurveyItem key={index}
                                          content={content}
                                          index={index}
                                          onChange={onChange}
                                          onContentChange={onContentChange}
                                          registerReference={registerRef}
                                          options={this.state.options}
                                          permissions={this.state.permissions}
                                          />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class SurveyItem extends React.Component{

    state={
        error:"",
        step:0,
        permissions:{},
        loading:false,
        loadText:"",
        step:"view",
    };

    componentDidMount=()=>{
        this.setState({permissions:u.getPermissions("survey")});
        this.props.registerReference(this.props.index,this);
    }

    onDownloadTarget=()=>{
        this.setState({error:""});
        const target = this.props.content.targetUser;
        u.download(target.filename,target.filestamp,undefined,(error)=>{
            this.setState({error:error});
        });
    }

    onDownloadQuestions=()=>{
        this.setState({error:""});
        const target = this.props.content.questionsFile;
        u.download(target.filename,target.filestamp,undefined,(error)=>{
            this.setState({error:error});
        });
    }

    onTerminate=()=>{
        this.setState({loading:true,error:""});
        u.post({
            url:"/api/terminate-survey",
            data:{
                surveyID:this.props.content.surveyID
            },
            success:()=>{
                this.props.onChange("Terminated","status",this.props.index);
                this.setState({loading:false,step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onPublish=()=>{
        this.setState({loading:true,loadText:"Publishing..."});
        u.post({
            url:"/api/publish-survey",
            data:{
                surveyID:this.props.content.surveyID
            },
            success:()=>{
                this.setState({step:"publish-complete",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onResults=()=>{
        window.open("/survey-result/"+this.props.content.surveyID,"_blank");
    }

    onShowOption=(index)=>{
        let questions=this.props.content.questions;
        questions[index].showOptions=!questions[index].showOptions;
        this.props.onChange(questions,"questions",this.props.index);
    }

    onFireReminder=()=>{
        this.setState({loading:true,loadText:"Firing Reminder..."});
        u.post({
            url:"/api/survey-send-reminder",
            data:{
                surveyID:this.props.content.surveyID
            },
            success:(reminderCount)=>{
                this.setState({step:"reminder-complete",reminderCount:reminderCount,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const content=this.props.content;
        const url = "/api/update-survey-info";
        const edit = (this.state.permissions.update == 2);
        const options=this.props.options;

        return (
            <div style={{position:"relative"}}>
                <div className="page-error">{this.state.error}</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step == "view"}>
                    <div>
                        <div className="list-segment extended" style={{verticalAlign:"top"}}>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Reference:</div>
                                <div className="list-segment-input">
                                    <EditableBox field="reference" label="Reference" values={{value:content.reference}}
                                                 rules={{required:true}} id={content.surveyID} url={url} edit={edit} onChange={this.props.onChange} index={this.props.index+""}/>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Type:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.type}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Status:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.status}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Dormitory:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.dormName}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Start On:</div>
                                <div className="list-segment-input"><EditSizeLabel>{(content.type == "Fire On Demand") ? "Fire On Demand" : moment(content.startOn).format("DD MMM YYYY - h:mma")}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Last Publish On:</div>
                                <div className="list-segment-input"><EditSizeLabel>{(content.lastPublishByID) ? <Link to={"/staff/"+content.lastPublishByID}>{content.lastPublishByName}</Link> : ""}{(content.lastPublishOn) ? " ("+moment(content.lastPublishOn).format("DD MMM YYYY - h:mma") + ")" : ""}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Sessions Published:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.resultCount} Sessions</EditSizeLabel></div>
                            </div>
                        </div>

                        <div className="list-segment extended" style={{verticalAlign:"top"}}>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Session Duration:</div>
                                <div className="list-segment-input">
                                    <EditableSelect field="duration" label="Session Duration" values={{value:content.duration,options:options.surveyDuration}} id={content.surveyID} url={url} edit={edit} onChange={this.props.onChange} index={this.props.index+""}/>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Prompt User:</div>
                                <div className="list-segment-input">
                                    <EditableSelect field="prompt" label="Prompt User" values={{value:content.prompt,options:options.surveyPrompt}} id={content.surveyID} url={url} edit={edit} onChange={this.props.onChange} index={this.props.index+""}/>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Target User:</div>
                                <div className="list-segment-input">
                                    <EditSizeLabel labelStyle={{padding:"2px 5px",maxHeight:"33px",overflow:"auto"}}>
                                        {
                                            (content.targetUser)
                                                ?
                                                <div>
                                                {
                                                    (Array.isArray(content.targetUser))
                                                        ?
                                                        content.targetUser.map(
                                                            (item,index)=>{
                                                                return (
                                                                    <Tag key={index}
                                                                         index={index + ""}
                                                                         clickable={false}
                                                                         label={item}
                                                                         onRemove={()=>{}}
                                                                         values={{removable:false}}
                                                                    />
                                                                )
                                                            }
                                                        )
                                                    :
                                                    (
                                                        (content.targetUser.filename)
                                                        ?
                                                        <TextButton onClick={this.onDownloadTarget} style={{margin:"4px 0"}}>{content.targetUser.filename}</TextButton>
                                                        :
                                                        <div style={{margin:"4px 0"}}><Link to={"/tenant/"+content.dormID+"/"+content.targetUser.tenantID}>{content.targetUser.tenantName}</Link></div>
                                                    )
                                                }
                                                </div>
                                                :
                                                <div style={{margin:"4px 0"}}>{content.targetUserType}</div>
                                        }
                                    </EditSizeLabel>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Notification Subject:</div>
                                <div className="list-segment-input">
                                    <EditableBox field="notificationSubject" label="Notification Subject" values={{value:content.notificationSubject}}
                                                 rules={{required:true}}
                                                 id={content.surveyID} url={url} edit={edit} onChange={this.props.onChange} index={this.props.index+""}/>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Notification Message:</div>
                                <div className="list-segment-input">
                                    <EditableArea field="notificationMessage" label="Notification Message" values={{value:content.notificationMessage}}
                                                  rules={{required:true,rows:6}} labelStyle={{height:"120px"}}
                                                  id={content.surveyID} url={url} edit={edit} onChange={this.props.onChange} index={this.props.index+""}/>
                                </div>
                            </div>
                        </div>

                        <div className="list-table-container" style={{margin:"30px auto"}}>
                            <h3>Survey Questions</h3>
                            <div className="survey-table-container list-table" style={{maxHeight:"none"}}>
                                <table className="survey-table">
                                    <tbody>
                                        <tr>
                                            <th className="serial">Qn</th>
                                            <th className="question">Question</th>
                                            <th className="answerType">Type</th>
                                            <th className="prereq">Pre-requisite</th>
                                        </tr>
                                        {
                                            content.questions.map(
                                                (item,index)=>{
                                                    const q_options=(item.options) ? item.options : [];
                                                    return (
                                                        <tr key={index} className="item">
                                                            <td className="serial">{item.index}</td>
                                                            <td className="question">
                                                                {item.question} {(q_options.length > 0) ? <TextButton style={{margin:"0 0 0 5px"}} onClick={()=>{this.onShowOption(index)}}>({q_options.length} Options)</TextButton> : null}
                                                                {
                                                                    (item.showOptions)
                                                                        ?
                                                                        <ul>
                                                                        {
                                                                            q_options.map(
                                                                                (option,o)=>{
                                                                                    return (
                                                                                      <li key={o}>{option}</li>
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                        </ul>
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                            <td className="answerType">{item.answerType}</td>
                                                            <td className="prereq">{(item.preReq) ? "Qn " + item.preReq + " " + item.reqType.toLowerCase() +" " + item.reqValue: ""}</td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{margin:"10px auto"}}>
                                <TextButton onClick={this.onDownloadQuestions}>Download Questions</TextButton>
                            </div>
                        </div>

                        <div className="list-segment-buttons">
                        {
                            (content.status == "Pending" && content.publishType != "Fire On Demand" && edit)
                                ?
                                <Button onClick={()=>{this.setState({step:"terminate"})}}>Terminate</Button>
                                :
                                null
                        }
                        {
                            (content.type == "Fire On Demand" && edit)
                                ?
                                <Button onClick={()=>{this.setState({step:"publish"})}}>Publish Now</Button>
                                :
                                null
                        }
                        {
                            (edit && content.status == "Pending")
                                ?
                                <Button onClick={()=>{this.setState({step:"reminder"})}}>Fire Reminder</Button>
                                :
                                null
                        }
                        {
                            (content.resultCount > 0)
                              ?
                              <Button onClick={this.onResults}>View Results</Button>
                              :
                              null
                        }
                        </div>

                    </div>
                </Step>

                <Step active={this.state.step == "terminate"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-negative">Confirm Terminate Survey?</h2>
                        <div className="list-segment-note">Terminating will stop this survey from firing on all future dates</div>

                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button onClick={this.onTerminate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "publish"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-emphasis">Confirm Publish Survey?</h2>
                        <div className="list-segment-note">Survey will be sent to all targets immediately</div>

                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button onClick={this.onPublish}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "publish-complete"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-emphasis">Survey Successfully Published</h2>

                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reminder"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-emphasis">Confirm Fire Reminder?</h2>
                        <div className='list-segment-note'>
                            Note. Reminder notifications are fired in batches to residents who have not completed their surveys
                        </div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button onClick={this.onFireReminder}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reminder-complete"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className='text-success'>{this.state.reminderCount} Reminders Fired Successfully</h2>
                        <div className='list-segment-note'>
                            Note. Notifications may take some time to arrive at their respective devices, please refrain from repeating this function within the same day
                        </div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }

}

class AddSurvey extends React.Component{

    state={
        error:"",
        entryData:{
            date:moment().add(1,"days").format("YYYY-MM-DD"),
            time:moment().format("HH:mm:ss"),
            tags:[],
        },
        dormOptions:[],
        step:"add",
        loading:false,
        loadText:"",
    };

    componentDidMount=()=>{
        this.getDormOptions();
    }

    show=(show)=>{
        this.refs['add'].show(show);
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm",
            },
            success:(callback)=>{
                callback.unshift({value:"All",label:"All"});
                this.setState({dormOptions:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        let entryData = this.state.entryData;
        entryData[label] = value;
        if(label == "dormID"){
            entryData.dormName=option.label;
        }
        this.setState({entryData:entryData});
    }

    onDropzoneError=(error)=>{
        this.setState({error:error});
    }

    onGoToAddAttachment=()=>{
        this.setState({step:1,error:""});
    }

    onRemoveAttachment=(index)=>{
        let entryData = this.state.entryData;
        entryData.attachments.splice(index,1);
        this.setState({entryData:entryData});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    goToStep=(step)=>{
        this.setState({step:step,error:""});
    }

    onFileDropped=(file)=>{
        this.userFilename = file;
        this.setState({loading:true,loadText:"Checking file..."});
    }

    onUploadProgress=(progress)=>{
        this.setState({loading:true,loadText:"Uploading..." + progress + "%"});
    }

    onUploadComplete=(filename,fileIcon,type)=>{
        let entryData=this.state.entryData;
        if(this.state.uploadType == "tenants"){
            entryData.targetSpreadsheet={userFilename:this.userFilename,filename:filename};
        }
        else if(this.state.uploadType == "questions"){
            entryData.questions={userFilename:this.userFilename,filename:filename};
        }
        this.setState({step:"add",error:"",loading:false,entryData:entryData});
    }

    onAdd=()=>{
        this.setState({error:""});
        const datarefs = ["reference","searchParam","date","time","notificationSubject","notificationMessage"];
        for(var i=0; i<datarefs.length; i++){
            if(this.refs[datarefs[i]]){
                const d = this.refs[datarefs[i]].validate();
                if(!d.valid){
                    this.setState({error:d.value});
                    return 0;
                }
            }
        }

        const entryData=this.state.entryData;
        if(entryData.targetUserType == "Target Search Tags" && entryData.tags.length == 0){
            this.setState({error:"Please enter at least 1 search tag or choose a different Target User"});
            return 0;
        }

        if(entryData.targetUserType == "Target Spreadsheet" && !(entryData.targetSpreadsheet)){
            this.setState({error:"Please upload the target user spreadsheet or choose a different Target User"});
            return 0;
        }

        if(!entryData.questions){
            this.setState({error:"Please upload the question template"});
            return 0;
        }

        let validData = Object.assign({},entryData);
        validData.startOn = entryData.date+"T"+entryData.time;
        delete validData.date;
        delete validData.time;

        this.refs['add'].onValidated(validData);
    }

    onAddComplete=()=>{
        this.setState({
            entryData:{
                date:moment().add(1,"days").format("YYYY-MM-DD"),
                time:moment().format("HH:mm:ss"),
                tags:[],
            }
        });
    }

    onChangeTags=(tags)=>{
        let entryData=this.state.entryData;
        entryData.tags = tags;
        this.setState({entryData:entryData});
    }

    onDownloadTemplate=()=>{
       this.setState({loading:true,loadText:"Preparing..."});
       u.autoDownload({
          url:"/api/get-survey-question-template",
          data:{},
          success:()=>{
              this.setState({loading:false,loadText:""});
          },
          error:(error)=>{
              this.setState({error:error});
          }
       })
    }

    render=()=>{
        const content=this.state.entryData;
        const options=this.props.options;
        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onAddComplete}
                         inList={this.props.inList}>

                <div className="segment-error">{this.state.error}</div>

                <Step active={(this.state.step == "add")}>
                    <div style={{position:"relative"}}>
                        <h3>Complete the fields to create the survey</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Reference:</div>
                            <div className="segment-item-input">
                                <Box ref="reference"
                                     label="Reference"
                                     field="reference"
                                     values={{value:content.reference}}
                                     rules={{required:true}}
                                     onChange={this.onChange}
                                     />
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Dormitory:</div>
                            <div className="segment-item-input">
                              <Select ref="dormID"
                                      label="Dormitory"
                                      field="dormID"
                                      values={{
                                        value:content.dormID,
                                        options:this.state.dormOptions
                                      }}
                                      onChange={this.onChange}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Target Users:</div>
                            <div className="segment-item-input">
                                <Select ref="targetUserType"
                                        label="User"
                                        field="targetUserType"
                                        values={{
                                          value:content.targetUserType,
                                          options:options.targetUser
                                        }}
                                        onChange={this.onChange}/>
                            </div>
                        </div>

                        {
                            (content.targetUserType == "Target Residents")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Permit/FIN:</div>
                                    <div className="segment-item-input">
                                        <Box ref="searchParam"
                                             label="Permit"
                                             field="searchParam"
                                             rules={{required:true}}
                                             values={{value:content.searchParam}}
                                             onChange={this.onChange}
                                             />
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            (content.targetUserType == "Target Search Tags")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Search Tags:</div>
                                    <div className="segment-item-input">
                                        <EmptyBox onClick={()=>{this.setState({step:"add-tag"})}} style={{padding:"0 5px",cursor:"pointer"}}>
                                            {
                                                (content.tags.length > 0)
                                                    ?
                                                    content.tags.map(
                                                        (tag, index)=> {
                                                            return (
                                                                <Tag key={index}
                                                                     index={index + ""}
                                                                     clickable={true}
                                                                     label={tag}
                                                                     onRemove={()=>{}}
                                                                     values={{removable:false}}
                                                                    />
                                                            )
                                                        }
                                                    )
                                                    :
                                                    <div style={{padding:"5px 0",color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                            }
                                        </EmptyBox>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            (content.targetUserType == "Target Spreadsheet")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Target Spreadsheet:</div>
                                    <div className="segment-item-input">
                                        <EmptyBox onClick={()=>{this.setState({step:"upload",uploadType:"tenants"})}} style={{padding:"5px",cursor:"pointer"}}>
                                            {
                                                    (content.targetSpreadsheet)
                                                        ?
                                                        content.targetSpreadsheet.userFilename
                                                        :
                                                        <div style={{color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                            }
                                        </EmptyBox>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div className="segment-full-item">
                            <div className="segment-item-label">Survey Type:</div>
                            <div className="segment-item-input">
                                <Select ref="type"
                                        label="Type"
                                        field="type"
                                        values={{
                                          value:content.type,
                                          options:options.notificationType
                                        }}
                                        onChange={this.onChange}/>
                            </div>
                        </div>

                        {
                            (content.type != "Fire On Demand" && content.type !="Fire Now")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Start Date:</div>
                                    <div className="segment-item-input">
                                        <Date ref="date"
                                              label="Date"
                                              field="date"
                                              values={{value:content.date}}
                                              range={[0,2]}
                                              rules={{minDate:moment().add(1,"days").format("YYYY-MM-DD")}}
                                              onChange={this.onChange}/>
                                    </div>
                                </div>
                                :
                                null
                        }

                        {
                            (content.type != "Fire On Demand" && content.type !="Fire Now")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Start Time:</div>
                                    <div className="segment-item-input">
                                        <Time ref="time"
                                              label="Time"
                                              field="time"
                                              values={{value:content.time}}
                                              rules={{skipSeconds:true}}
                                              onChange={this.onChange}/>
                                    </div>
                                </div>
                                :
                                null
                        }

                        <div className="segment-full-item">
                            <div className="segment-item-label">Duration:</div>
                            <div className="segment-item-input">
                                <Select ref="duration"
                                        label="Duration"
                                        field="duration"
                                        values={{
                                          value:content.duration,
                                          options:options.surveyDuration
                                        }}
                                        onChange={this.onChange}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Prompt User:</div>
                            <div className="segment-item-input">
                                <Select ref="prompt"
                                        label="Prompt User"
                                        field="prompt"
                                        values={{
                                          value:content.prompt,
                                          options:options.surveyPrompt
                                        }} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div style={{width:"100%",borderBottom:"1px dashed #000000"}}/>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Subject:</div>
                            <div className="segment-item-input">
                                <Box ref="notificationSubject"
                                     label="Notification Subject"
                                     field="notificationSubject"
                                     values={{value:content.notificationSubject}}
                                     rules={{required:true}}
                                     onChange={this.onChange}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Message:</div>
                            <div className="segment-item-input">
                                <Area ref="notificationMessage"
                                      label="Notification Message"
                                      field="notificationMessage"
                                      values={{value:content.notificationMessage}}
                                      rules={{required:true}}
                                      onChange={this.onChange}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                          <div style={{width:"100%",borderBottom:"1px dashed #000000"}}/>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Questions:</div>
                            <div className="segment-item-input">
                                <EmptyBox onClick={()=>{this.setState({step:"upload",uploadType:"questions"})}} style={{padding:"5px",cursor:"pointer"}}>
                                    {
                                            (content.questions)
                                                ?
                                                content.questions.userFilename
                                                :
                                                <div style={{color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                    }
                                </EmptyBox>
                            </div>
                        </div>

                        <div style={{margin:"20px auto",fontSize:"14px"}}>
                            <TextButton onClick={this.onDownloadTemplate}>Download Question Template</TextButton>
                        </div>

                        <div className="segment-buttons">
                            <div className="page-error">{this.state.error}</div>
                            <Button onClick={this.onCancel}>Cancel</Button>
                            <Button onClick={this.onAdd}>Create</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "upload")}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Dropzone label="Attachments" accessibleForPreview={false}
                              onError={this.onDropzoneError} onAdded={this.onFileDropped} onProgress={this.onUploadProgress}
                              onUploaded={this.onUploadComplete} filetypes={Spreadsheet}
                              dragAndDropLabel={(this.state.uploadType == "questions") ? "Only accepts templates downloaded from Smartdorm" : "Accepts spreadsheets generated from tenant list, ensure column ID exists"}
                            />
                    <div className="segment-buttons">
                        <Button index="add" onClick={this.goToStep}>Back</Button>
                    </div>
                </Step>

                <Step active={(this.state.step == "add-tag")}>
                    <div style={{padding:"10px 0 30px 0"}}>
                        <h3>Add search tags below</h3>
                        <div style={{width:"800px", maxWidth:"96%",fontSize:"0.85em",margin:"20px auto",textAlign:"center"}}>You do not need to add in tags @status(!Check Out), @dorm() & @app(Registered) as these tags are automatically added into every survey</div>
                        <AddSearchTag listLabel="resident-list"
                                      tags={content.tags}
                                      onChange={this.onChangeTags}
                                      />
                        <div className="segment-buttons">
                            <Button index="add" onClick={this.goToStep}>Done</Button>
                        </div>
                    </div>
                </Step>

            </AddListItem>
        )
    }
}
