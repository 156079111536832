import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import {Databox,DataboxPhoto,PageMenu,UploadPhoto,Segment,Step,
        LoadGraphic,DynamicDetailArea} from '../components/Common';
import {EditableBox,EditableSelect,EditableDate,Select,
        EditSizeLabel,EditSizeLink,BoolSlider,Button,Info} from '../components/Input';
import {StaffCardTemplate} from '../pages/PrintCard';
import {Link} from 'react-router-dom';
import '../styles/staff.scss';
import moment from 'moment';
import PropTypes from 'prop-types';

export default class Staff extends React.Component{

    id = this.props.match.params.staffID;

    state={
        error:"",
        permissions:{},
        content:{
            permissions:{},
        },
        pageMenuButtons:[],
    };

    settings={
        /**** Page Setup ****/
        title:"Staff",
        permission:"staff",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/staff-list",
        label:"staff",
    };

    componentDidMount=()=>{
        if(this.id){
            const permissions=u.getPermissions("staff");
            this.setState({
                pageMenuButtons:[
                    {label:"Access",mobileLabel:"Access Logs",ref:"access",image:"/images/nav-menu/security.png",title:"View access logs",enabled:true,padding:"6px",onClick:this.goToAccessLogs},
                    {label:"Set/Revoke Admin", mobileLabel:"Set/Revoke Admin",ref:"admin",image:"/images/page-menu/administrator.png",title:"Create/Revoke administrator",enabled:(permissions.create==2 || permissions.update == 2),padding:"6px"},
                    {label:"Photo", mobileLabel:"Upload Photo",ref:"photo",image:"/images/page-menu/upload-photo.png",title:"Upload staff photo",enabled:(permissions.update==2),padding:"3px"},
                    {label:"Print Card", mobileLabel:"Print Access Card",ref:"printCard",image:"/images/page-menu/print-card.png",title:"Print access card",enabled:(permissions.update==2),padding:"2px"},
                ],
                permissions:permissions,
            })
            this.getContent(this.id);
        }
        else {
            this.setState({error:"Staff Not Found"});
        }
    }

    goToAccessLogs=()=>{
        const start = moment().format("DD-MM-YYYY");
        const end = moment().subtract(14,"days").format("DD-MM-YYYY");
        store.set("security-list","@id("+this.state.content.staffID+")~:dateStart("+end+")~:dateEnd("+start+")");
        window.open("/security-list","_blank");
    }

    getContent=(id)=>{
        u.post({
            url:"/api/get-staff",
            data:{
                staffID:id
            },
            success:(callback)=>{
                this.setState({content:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,onError)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    onChangeAccess=(value,field)=>{
        this.setState({error:""});
        u.post({
            url:"/api/update-staff-access",
            data:{
                oldValue:this.state.content[field],
                newValue:value,
                id:this.state.content.staffID,
                field:field,
            },
            success:(callback)=>{
                this.onContentChange(value,field);
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    render=()=>{
        const content=this.state.content;
        if(typeof content.staffID == 'undefined'){
           return <div/>
        }

        const pageComponents=(content.staffID) ? {
            photo:{
              component:UploadPhoto,
              props:{
                id:this.id,
                url:"/api/commit-staff-avatar",
                fieldname:"avatarLink",
                onUpload:this.onContentChange
            }},
            printCard:{
              component:PrintAccessCard,
              props:{
                content:content
            }},
            admin:{
                component:SetAdministrator,
                props:{
                    content:content,
                    onContentChange:this.onContentChange
                }
            }
        } : {};

        return (
            <AppLayout settings={this.settings}>
                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                />

                <DynamicDetailArea settings={this.settings}
                                   content={content}
                                   onChange={this.onContentChange}/>

                <Databox active={true} title="Access Rights">
                    <Access content={this.state.content} onChange={this.onChangeAccess}/>
                </Databox>

            </AppLayout>
        )
    }
}

class SetAdministrator extends React.Component{

    state={
      error:"",
      step:0,
      loading:false,
      active:false
    };

    static getDerivedStateFromProps(props,state){
        if(props.content.isAdmin == 1 && state.step == 0){
            state.step=2;
        }
        else if(props.content.isAdmin == 0 && state.step == 2){
            state.step=0;
        }
        return state;
    }

    show=(active)=>{
        if(!active){
            if(this.props.content.isAdmin == 1){
                this.setState({step:2});
            }
            else if(this.props.content.isAdmin == 0){
                this.setState({step:0});
            }
        }

        this.setState({active:active});
    }

    onCancel=()=>{
        if(this.state.step == 2){
            this.setState({error:"",loading:false});
        }
        else{
            this.setState({step:0,error:"",loading:false});
        }
        this.props.onToggleForm(this.props.reference,false);
    }

    onSendEmail=()=>{
        this.setState({error:"",loading:true});
        u.post({
            url:"/api/set-administrator",
            data:{
                staffID:this.props.content.staffID,
                action:true,
            },
            success:(callback)=>{
                this.setState({step:1,loading:false});
                this.props.onContentChange(1,"isAdmin");
                this.props.onContentChange('Yes',"isAdminLabel");
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onRevokeAccount=()=>{
        this.setState({error:"",loading:true});
        u.post({
            url:"/api/set-administrator",
            data:{
                staffID:this.props.content.staffID,
                action:false
            },
            success:(callback)=>{
                this.setState({step:3,error:"",loading:false});
                this.props.onContentChange(0,"isAdmin");
                this.props.onContentChange("No","isAdminLabel");
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        return (
            <Segment active={this.state.active} align="center" >
                <div className="list-add">
                    <div className="segment-title">{(this.props.content.isAdmin == 1) ? "Revoke Administrator" : "Create Admin Account"}</div>
                    <div className="segment-error">{this.state.error}</div>
                    <Step active={(this.state.step == 0)}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Sending account creation email..."/>
                            <h3>Send Account Creation Email?</h3>
                            <div className="segment-note">Note: An email containing steps to creating an administrator account will be sent to {this.props.content.staffName}</div>
                            <div className="segment-buttons">
                                <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onSendEmail}>Send</Button>
                            </div>
                        </div>
                    </Step>
                    <Step active={(this.state.step == 1)}>
                        <div className="segment-content">
                            <div style={{margin:"10px 0"}}>
                                <img style={{display:"inline-block",width:"50px", verticalAlign:"middle",marginRight:"20px"}} src="/images/input/confirm.png"/>
                                <h3 style={{display:"inline-block", verticalAlign:"middle"}}>Email sent successfully</h3>
                            </div>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                    <Step active={(this.state.step == 2)}>
                        <div className="segment-content">
                            <LoadGraphic active={this.state.loading} text="Revoking admin account..."/>
                            <h3>Revoke Administrator Account?</h3>
                            <div className="segment-note">Note: {this.props.content.staffName} will lose access but his configurations and data will still be saved</div>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onSendEmail}>Resend</Button>
                                <Button type="medium" onClick={this.onRevokeAccount}>Revoke</Button>
                                <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                            </div>
                        </div>
                    </Step>
                    <Step active={(this.state.step == 3)}>
                        <div className="segment-content">
                            <h3>{this.props.content.staffName}'s administrator account has been successfully revoked</h3>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}

export class Access extends React.Component{

    state={
      error:"",
      hierarchy:[],
      permissions:{}
    };

    componentDidMount=()=>{
        this.getAccessPoints();
        this.setState({permissions:u.getPermissions("staff")});
    }

    getAccessPoints=()=>{
        u.post({
            url:"/api/get-access-points",
            data:{},
            success:(content)=>{
                this.setState({hierarchy:content});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onSelectAll=(dorm,zone)=>{
        let hierarchy = this.state.hierarchy;
        const access = Object.assign({},this.props.content.turnstileAccess);
        const turnstiles = hierarchy[dorm].zones[zone].turnstiles;
        const selected = (turnstiles.filter((item)=>{return !(access[item.value])}).length > 0);

        for (var i=0; i<turnstiles.length; i++){
            access[turnstiles[i].value] = selected;
        }
        this.props.onChange(access,"turnstileAccess");
    }

    render=()=>{
        const content=this.props.content;
        const align = (this.state.hierarchy.length > 2) ? "left" : "center";
        const edit = (this.state.permissions.update == 2);
        return(
            <div style={{textAlign:align}}>
                <h3 style={{textAlign:"center"}}>Data Access <Info title="Data access grants users permission to view information from the selected dormitories, within the boundaries of their permission groups"/></h3>
                <div className="access-hierarchy">
                {
                    this.state.hierarchy.map(
                        (dorms,index)=>{
                            return (
                                <div key={index} className="access-container">
                                    <AccessItem key={index} enabled={edit} access={content.dataAccess} content={dorms} field="dataAccess" onChange={this.props.onChange}/>
                                </div>
                            )
                        }
                    )
                }
                </div>

                {
                    this.state.hierarchy.map(
                        (dorms,index)=>{
                            return (
                                <div className="ts-container" key={index}>
                                    <h3 style={{textAlign:"center"}}>Turnstiles Access - {dorms.label} <Info title="Turnstile access allows the respective staff to scan in/out of the selected turnstiles"/></h3>

                                    {
                                        (dorms.zones.length == 0)
                                          ?
                                          <div className="access-placeholder">
                                              <div>No Turnstiles Registered</div>
                                          </div>
                                          :
                                          <div>
                                            {
                                                dorms.zones.map(
                                                    (zone,zindex)=>{
                                                        return (
                                                            <div className="access-container" key={zindex}>
                                                                <div className="access-label" onClick={()=>{this.onSelectAll(index,zindex)}} title="Select All"><b>{zone.label}</b></div>
                                                                <div className="access-current">
                                                                      {
                                                                         zone.turnstiles.map(
                                                                            (ts,tsindex)=>{
                                                                                return(
                                                                                    <AccessItem key={tsindex} enabled={edit} access={content.turnstileAccess} content={ts} field="turnstileAccess" onChange={this.props.onChange}/>
                                                                                )
                                                                            }
                                                                         )
                                                                      }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                )
                                              }
                                          </div>
                                   }
                                </div>
                            )
                        }
                    )
                }
            </div>
        )
    }
}

class AccessItem extends React.Component{

    state={error:""};

    onChange=(value)=>{
        let access = Object.assign({},this.props.access);
        access[this.props.content.value] = value;
        this.props.onChange(access,this.props.field);
    }

    render=()=>{
        const content = this.props.content;
        const haveAccess = (this.props.access) ? ((this.props.access[this.props.content.value]) ? this.props.access[this.props.content.value] : false) : false;

        return (
            <div className="access-row">
                <div className="access-name">{content.label}</div>
                <div className="access-slider">
                    <BoolSlider ref="slider" value={haveAccess} onChange={this.onChange} enabled={this.props.enabled}/>
                </div>
            </div>
        )
    }
}

class PrintAccessCard extends React.Component{

    state={active:false,cardOptions:[],loading:false};

    componentDidMount=()=>{
        this.getCardThemes();
    }

    getCardThemes=()=>{
        u.post({
            url:"/api/get-card-themes",
            data:{},
            success:(cardOptions)=>{
                if(cardOptions.length == 0){
                    cardOptions.push({value:"default",label:"Default"});
                }
                this.setState({cardOptions:cardOptions,cardTheme:cardOptions[0].value});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onChangeTheme=(value)=>{

        this.setState({cardTheme:value});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onPrint=()=>{
        let content = Object.assign({},this.props.content);
        content['cardTheme']=this.state.cardTheme;
        content['category']="staff";
        store.set("print-card",content);
        window.open("/print-card","_blank","width=800,height=800,left=200,top=100");
    }

    onPDF=()=>{
        this.refs['card'].print();
    };

    render=()=>{
        return (
            <Segment active={this.state.active} >
                <div className="list-add">
                    <div className="segment-title">Print Access Card</div>
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <LoadGraphic active={this.state.loading} text="Processing..."/>
                        <div style={{width:"500px",maxWidth:"90%",margin:"20px auto"}}>
                            <Select label="Card Theme" values={{value:this.state.cardTheme,options:this.state.cardOptions}} onChange={this.onChangeTheme}/>
                        </div>

                        <div style={{border:"1px solid black",display:"inline-block"}}>
                            <StaffCardTemplate ref="card" content={this.props.content} cardTheme={this.state.cardTheme} onStart={()=>{this.setState({loading:true})}} onEnd={()=>{this.setState({loading:false})}} onError={(error)=>{this.setState({error:error})}}/>
                        </div>

                        <div className="segment-buttons">
                            <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                            <Button type="medium" onClick={this.onPrint}>Print</Button>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}
