import React from 'react';
import {LoadGraphic} from '../components/Common';
import u from '../utilities/Utilities';
import store from 'store';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import moment from 'moment';
import "../styles/print-attendance-event.scss";

export default class PrintEventQR extends React.Component{

    state={
        loading:true,
        loadText:"Loading...",
        event:{
          eventID:"none",
        },
        dimensions:{},
    }

    componentDidMount=()=>{
        const event = store.get("event-qr-details");
        if(event){
            this.setState({loading:false,event:event});
        }
        else{
            this.setState({loadText:"Event Not Found"});
        }
    }

    render=()=>{
        const {event,dimensions} = this.state;
        const {width} = dimensions;

        return(
            <div className="page-container" style={{textAlign:"center"}}>
                <div className="print-event-container">
                    <div className="pd-header">
                        <img src={"/images/app/mwmd-text-logo.png"}/>
                    </div>

                    <div style={{position:"relative"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <h1 className="event-label">{event.eventLabel}</h1>
                        <h2 className="event-date">{moment(event.validFromDateTime).format("dddd, DD MMM YYYY")}</h2>
                        <h2 className="event-time">{moment(event.validFromDateTime).format("h:mma")} - {moment(event.validToDateTime).format("h:mma")}</h2>

                        <div className="event-qr-container">
                            <Measure bounds onResize={contentRect => {this.setState({dimensions:contentRect.bounds})}}>
                                {
                                    ({measureRef})=>
                                        (
                                            <div ref={measureRef} className="event-qr">
                                                <QRCode value={event.eventID}
                                                        level="M"
                                                        fgColor="#3b3b3c"
                                                        bgColor="#fff"
                                                        size={width}
                                                        imageSettings={{
                                                           src:"/images/app/event-qr-image.png",
                                                           width:70,
                                                           height:70,
                                                        }}
                                                        />
                                            </div>
                                        )
                                }
                            </Measure>
                        </div>
                    </div>

                    <div className="event-scan-instruction">
                        <img src="/images/app/event-instruction.png" className="event-scan-image"/>
                        <div style={{fontSize:"18px",marginBottom:"10px"}}>
                            <b>Check-in Instructions</b>
                        </div>
                        <div>
                            <ol>
                                <li>Login to MWMD Dormitories Mobile App</li>
                                <li>Click on <b>Attend Event</b> in the Main Menu</li>
                                <li>Scan the above QR Code to check in</li>
                                <li>QR Code is only valid during above hours</li>
                            </ol>
                        </div>
                    </div>

                    <div className="pd-footer">
                        <img src="/images/app/footer.png"/>
                    </div>
                </div>
            </div>
        )
    }

}
