import React from 'react';
import {Databox,LoadGraphic,Step} from '../components/Common';
import PublicPageLayout from '../components/PublicPageLayout';
import {Input,Button,TextButton} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';
import '../styles/create-user.scss';
import '../styles/visitor-self-submission.scss';

export default class VisitorSelfSubmission extends React.Component{

    dormCode=this.props.match.params.dormCode;

    state={
        loading:false,
        loadText: "Submitting...",
        step:"register",
        entryData:{
            validStart:moment().format("YYYY-MM-DDTHH:mm:00"),
        },
        dormName:"",
        fields:{},
        error:"",
    };

    componentDidMount=()=>{
        //const query = qs.parse(this.props.location.search);
        u.post({
            url:"/noauth/get-dorm-self-submission-info",
            data:{
                dormCode:this.dormCode
            },
            success:({fields,dormName,dormID})=>{
                this.setState({
                    fields:fields,
                    dormName:dormName,
                    dormID:dormID,
                    instantiated:true,
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onValidate=(fields)=>{
        this.setState({error:""});
        const input = fields.map((item)=>{return item.field});
        const entryData = this.state.entryData;

        for(var i=0; i<input.length; i++){
            const current = this.refs[input[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.setState({loading:true,error:"",loadText:"Submitting..."});

        u.post({
            url:"/noauth/visitor-self-submission",
            data:{
              ...entryData,
              dormID:this.state.dormID,
            },
            success:(callback)=>{
                this.setState({step:"success",loading:false});
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onChange=(value,label,index)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onFocus=(field)=>{
        if(field.scrollToBottom){
            const objDiv = document.getElementById("root");
            objDiv.scrollTop = objDiv.scrollHeight;
        }
    }

    render=()=>{
        const enabled=(typeof this.state.passToken != "undefined");
        const entryData = this.state.entryData;
        const fields=this.state.fields;

        if(!this.state.instantiated){
            return (
                <div className="page-error">{this.state.error}</div>
            )
        }
        return(
            <PublicPageLayout title="Visitor Self-Submission" url="/visitor-self-submission">
                <div className="self-submit-container" id="container">
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                    <Step active={(this.state.step == "register")}>
                        <h3>Welcome to {this.state.dormName}</h3>

                        <div style={{marginBottom:"20px"}}>Please complete the following to register</div>

                        {
                            fields.map(
                                (item,index)=>{
                                    return (
                                        <Input key={index}
                                               ref={item.field}
                                               fields={{
                                                 ...item,
                                                 onChange:this.onChange
                                               }}
                                               onFocus={this.onFocus}
                                               content={this.state.entryData}
                                               />
                                    )
                                }
                            )
                        }

                        <div className="segment-buttons" style={{marginTop:"30px"}}>
                            <div className="segment-error">{this.state.error}</div>
                            <Button style={{width:"250px"}} onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                        </div>

                    </Step>

                    <Step active={(this.state.step == "success")}>
                        <div style={{position:"relative",paddingTop:"100px"}}>
                            <img src="/images/app/tick-128.png" style={{height:"128px",display:"inline-block",verticalAlign:"middle",margin:"10px"}}/>
                            <h3>Submission Successful</h3>
                            <div className="disclaimer-text" style={{margin:"0 auto 20px auto"}}>
                                Please present your identification to the visitor reception to complete your registration
                            </div>
                            <TextButton onClick={
                                ()=>{
                                    this.setState({
                                        entryData:{
                                            validStart:moment().format("YYYY-MM-DDTHH:00:00"),
                                        },
                                        step:"register",
                                    })
                                }
                            }>Back to Registration</TextButton>
                        </div>
                    </Step>
                </div>
            </PublicPageLayout>
        )
    }
}
