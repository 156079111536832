import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,Step,
        AddListItem,DownloadTemplate,DynamicListArea,BulkEdit,
        LoadGraphic,Dropzone} from '../components/Common';
import {Select,TextButton,Button,Input, MiniMinusButton} from '../components/Input';
import u from '../utilities/Utilities';
import {Spreadsheet,Photo} from '../data/Mimetypes';

export default class ParcelList extends React.Component{
    r={};

    settings={
        /**** Page Setup ****/
        title:"Parcel Collection",
        permission:"parcel",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/parcel-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/create-parcel-entry",
        templateURL:"/api/parcel-bulk-add-template",
        bulkURL:"/api/bulk-add-parcel",
        deleteURL:"/api/delete-parcel-entries",
        spreadsheetURL:"/api/generate-parcel-spreadsheet",
        overviewURL:"/api/get-all-parcel-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"parcel-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.r['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        download:{component:DownloadTemplate,props:{skipParams:true}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("parcel");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add Entry",ref:"add",enabled:(permissions.create==2)},
                {label:"Delete Entries",ref:"delete",enabled:(permissions.delete==2)},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:(permissions.create==2)},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
            ]
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    render=()=>{
        return (
            <AppLayout ref={(ref)=>{this.r["layout"]=ref}} settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ListItem key={index} 
                                          index={index} 
                                          content={content} 
                                          onChange={onChange} 
                                          onContentChange={onContentChange} 
                                          registerReference={registerRef} 
                                          permissions={this.state.permissions}
                                          settings={this.settings}
                                          />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class ListItem extends React.Component{

    state={
        error:"",
        step:"view",
        loading:false,
        loadText:"",
    };

    onRemovePhoto=(field,index)=>{
        this.removeField=field;
        this.removeIndex=index;
        this.setState({step:"remove",error:""});
    }

    onConfirmRemove=()=>{
        this.setState({loading:true,loadText:"Removing..."});
        const {content}=this.props;

        u.post({
            url:"/api/delete-parcel-photo",
            data:{
                parcelID:content.parcelID,
                field:this.removeField,
                photo:content[this.removeField][this.removeIndex],
            },
            success:()=>{
                content[this.removeField].splice(this.removeIndex,1);
                this.props.onChange(this.props.removeField,content[this.props.removeField],this.props.index);
                this.setState({step:"view",loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onCommitPhoto=(field,url)=>{
        const {content}=this.props;
        u.post({
            url:"/api/add-parcel-photo",
            data:{
                parcelID:content.parcelID,
                field:field,
                photo:url
            },
            success:()=>{
                content[field].push(url);
                this.props.onChange(field,content[field],this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const {content,settings} = this.props;

        return (
            <div style={{textAlign:"center"}}>

                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <div className="list-segment-error">{this.state.error}</div>
                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={settings.listLabel} 
                                    permission={settings.permission} 
                                    content={this.props.content} 
                                    onChange={this.props.onChange} 
                                    index={this.props.index}
                                    />

                    <div style={{margin:"20px auto",position:"relative",textAlign:"center"}}>
                        {
                            (content.collectionPhotos.length > 0)
                                ?
                                <div style={{position:"relative",textAlign:"center"}}>
                                    <h3>Collection Photos</h3>
                                    {
                                        content.collectionPhotos.map(
                                            (item,index)=>{
                                                return (
                                                    <div className='list-photo-container' key={index}>
                                                        <div className='list-photo-remove'>
                                                            <MiniMinusButton onClick={()=>{this.onRemovePhoto("collectionPhotos",index)}}/>
                                                        </div>
                                                        <div>
                                                            <a href={item} rel="noopener" target="_blank">
                                                                <img className='list-photo' src={item}/>
                                                            </a>
                                                        </div>
                                                    </div>
                                        )})
                                    }

                                </div>
                                :
                                <div className='list-placeholder'>
                                    <b>No Collection Photos</b>
                                </div>
                        }
                        
                        <div style={{margin:"10px auto"}}>
                            <Dropzone label={"Collection-"+this.props.index}
                                    accessibleForPreview={true}
                                    onAdded={(filename)=>{this.setState({loading:true,loadText:"Uploading..."})}}
                                    onUploaded={(url)=>{this.onCommitPhoto("collectionPhotos",url)}}
                                    onError={()=>{this.setState({loading:false})}}
                                    onProgress={(progress)=>{this.setState({loadText:"Uploading " + progress + " %"})}}
                                    filetypes={Photo}
                                    buttonOnly={true}
                                    >
                                        Add Photo
                                    </Dropzone>
                        </div>

                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{position:"relative",padding:"20px 0",textAlign:"center"}}>
                        <h2 className='text-negative'>Confirm Delete Image?</h2>
                        <div className='list-segment-note'>Note. This action cannot be undone.</div>
                        <div style={{margin:"20px auto"}}>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onConfirmRemove}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{
    r={};

    state={
        error:"",
        options:{},
        entryData:{
        },
        dormOptions:[],
    };

    show=(show)=>{
        this.r[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getDormOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:[]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions})
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onComplete=()=>{
        this.setState({
            error:"",
            entryData:{}
        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label,index)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onValidate=(fields)=>{
        let keys = fields.map((item)=>{
          return item.field;
        });

        for(var i=0; i<keys.length; i++){
            if(keys[i] != "divider" && this.r[keys[i]]){
                const current=this.r[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
            }
        }


        this.r[this.props.reference].onValidated(this.state.entryData);
    }

    render=()=>{
        const {entryData,options,dormOptions}=this.state;
        const fields=[
            {dataType:"select",field:"dormID",label:"Dormitory",options:dormOptions},
            {dataType:"box",field:"trackingReference",label:"Tracking Ref.",rules:{required:true}},
            {dataType:"area",field:"otherReferences",label:"Other Refs.",rules:{placeholder:"Optional"}},
            {dataType:"area",field:"remarks",label:"Remarks",rules:{placeholder:"Optional"}},
        ];

        return (
           <AddListItem ref={(ref)=>{this.r[this.props.reference]=ref}}
                        settings={this.props.settings}
                        reference={this.props.reference}
                        filetypes={Spreadsheet}
                        onToggleForm={this.props.onToggleForm}
                        onComplete={this.onComplete}
                        onCancel={this.onCancel}
                        stepScroll={true}
                        dragAndDropLabel="Upload completed template spreadsheet here"
                        >

                <div>
                    <h3>Fill in the following to create parcel</h3>
                    {
                        fields.map(
                            (item,index)=>{
                                return (
                                    <Input ref={(ref)=>{this.r[item.field]=ref}}
                                           key={index}
                                           fields={{
                                             ...item,
                                             onChange:this.onChange
                                           }}
                                           content={entryData}
                                           />
                                )
                            }
                        )
                    }
                </div>

                <div className="segment-buttons" style={{fontSize:"0.85em"}}>
                    <TextButton onClick={()=>{this.r[this.props.reference].onBulkUpload()}}>Bulk Upload</TextButton>
                </div>

                <div className="segment-buttons">
                    <div className="segment-error">{this.state.error}</div>
                    <Button onClick={this.onCancel}>Cancel</Button>
                    <Button onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                </div>
           </AddListItem>
        )
    }
}
