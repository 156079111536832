import React from 'react';
import PortalLayout from '../../components/PortalLayout';
import OverviewList from '../../components/OverviewList';
import {PageMenu,CPGenerateSpreadsheet} from '../../components/Common';
import u from '../../utilities/Utilities';
import {Link} from 'react-router-dom';

export default class PortalHistoryList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Portal History",
        permission:"portal",  //Accepts combined permission module as well
        url:"/history-list",

        /**** Action Setup ****/
        addType:2,
        spreadsheetURL:"/client/generate-portal-history-spreadsheet",
        overviewURL:"/client/get-all-portal-history",
        listSettingsURL:"/client/cp-apply-list-user-settings",
        macroURL:"/client/cp-update-user-tag-macro",

        /**** List Setup ****/
        list:true,
        listLabel:"cp-history-list",
        orderByField:"dateTime",
        orderByType:"descending",
        disableChild:true,
        timeModeField:"event",
        timeMode:true,
        timeModeDateField:"dateTime",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        spreadsheet:{component:CPGenerateSpreadsheet,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        error:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getAllPermissions();

        this.setState({
          permission:permissions,
          pageMenuButtons:[
              {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:true},
          ]
        });
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    /************************************* Page Specific **************************************/

    render=()=>{
        return (
            <PortalLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          selected={this.state.selected}
                          settings={this.settings}
                          components={this.pageComponents}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange)=>{
                            return (
                                <div>
                                    <span title={"@user("+content.clientUserName+")"}>{content.clientUserName}</span>
                                    <span title={"@action("+content.action+")"}> {content.action} </span>
                                    <span title={"@target("+content.targetName+")"}> {(content.targetLink==null) ? content.targetName : <Link to={content.targetLink} id={content.targetLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.targetName}</Link>}</span>
                                    <span title={"@field("+content.targetField+")"}>{(content.targetField==null) ? "" : " (" + content.targetField + ")"}</span>
                                    <span title={"@from("+content.from+")"}>{(content.from == null) ? "" : ((content.fromLink == null) ? " from "+content.from : <span> from <Link to={content.fromLink} id={content.fromLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.from}</Link></span>)}</span>
                                    <span title={"@to("+content.to+")"}>{(content.to == null) ? "" : ((content.toLink == null) ? " to "+content.to : <span> to <Link to={content.toLink} id={content.toLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.to}</Link></span>)}</span>
                                </div>
                            )
                        }
                    }
                </OverviewList>
            </PortalLayout>
        )
    }
}
