import React from 'react';
import AppLayout from '../components/PublicPageLayout';
import {Step,LoadGraphic} from '../components/Common';
import {Button,Select,ProgressSlider,Box,TextButton} from '../components/Input';
import queryString from 'query-string';
import "../styles/submit-vitals.scss"
import u from '../utilities/Utilities';
import moment from 'moment';
import store from 'store';

export default class SubmitVitals extends React.Component{

    state={
        step:"fin",
        error:"",
        entryData:{
            breathingDifficulty:0,
            chestPain:0,
            requiresAttention:0,
        },
        loading:true,
        loadText:"Finding dormitory...",
        options:[{value:0,label:u.tx("no")},{value:1,label:u.tx("yes")}],
        slideRight:false,
        vitalConfig:{},
    };

    prevStep=[];

    componentDidMount=()=>{
        const query = queryString.parse(this.props.location.search);
        if(query.auto=="none"){
        }
        else{
          const fin = store.get('vitals-fin');
          if(fin){
            let entryData=this.state.entryData;
            entryData.fin = fin;
            this.setState({entryData:entryData});
          }
        }
        this.getDormInfo(this.props.match.params.dormCode);
    }

    onChange=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label] = value;
        this.setState({entryData:entryData});
    }

    getDormInfo=(dormCode)=>{
        this.setState({loading:true,loadText:"Acquiring Dormitory..."});
        u.post({
            url:"/noauth/get-estate-info",
            data:{
                dormCode:dormCode
            },
            success:({dormID,dormName,vitalConfig})=>{
                this.setState({dormID:dormID,dormName:dormName,loading:false,vitalConfig:vitalConfig});
            },
            error:(error)=>{
                this.setState({loadText:error});
            }
        });
    }

    onFin=()=>{
        const fin = this.refs['fin'].validate();
        if(!fin.valid){
            this.setState({error:fin.value});
            return 0;
        }

        this.setState({loading:true,loadText:"Validating FIN..."});

        u.post({
            url:"/noauth/check-vital-type",
            data:{
                dormID:this.state.dormID,
                searchParam:fin.value
            },
            success:({takeNow,submitLast,nextVitalCheckStart,nextVitalCheckEnd,vitalType,tenantID,tenantName})=>{
                this.prevStep.push(this.state.step);

                if(takeNow){
                    let entryData = this.state.entryData;
                    entryData.vitalType=vitalType;
                    entryData.tenantName=tenantName;
                    entryData.tenantID=tenantID;
                    entryData.dormID=this.state.dormID;
                    this.setState({entryData:entryData,loading:false,step:this.getNextStep(1)});
                }
                else{
                    this.setState({
                        loading:false,
                        step:(submitLast) ? "complete" : "fail",
                        nextVitalCheckEnd:nextVitalCheckEnd,
                        nextVitalCheckStart:nextVitalCheckStart
                    });
                }

            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onSubmit=()=>{
        this.setState({loading:true,loadText:"Submitting vitals..."});
        let entryData = this.state.entryData;
        store.set("vitals-fin",entryData.fin);
        delete entryData.fin;

        u.post({
            url:"/noauth/create-vitals-entry",
            data:this.state.entryData,
            success:({nextVitalCheckStart,nextVitalCheckEnd})=>{
                this.setState({step:"complete",loading:false,nextVitalCheckStart:nextVitalCheckStart,nextVitalCheckEnd:nextVitalCheckEnd});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onNext=(e)=>{
        if(typeof e != "undefined"){
            console.log("preventing");
            e.preventDefault();
        }
        console.log("Here");

        this.setState({error:"",slideRight:false});
        if(this.state.step == "fin"){
            this.onFin();
        }
        else{
            const keys=["temperature","systolicBP","diastolicBP","oxygenSaturation","pulse","breathingDifficulty","chestPain","requiresAttention"];
            for(var i=0; i<keys.length; i++){
                if(this.refs[keys[i]]){
                    const current = this.refs[keys[i]].validate();
                    if(!current.valid){
                        this.setState({error:current.value});
                        return 0;
                    }
                }
            }

            if(this.state.step != 7){
                this.prevStep.push(this.state.step);
                this.setState({step:this.getNextStep(this.state.step+1)});
            }
            else{
                this.onSubmit();
            }
        }
    }

    getNextStep=(step)=>{
        const config = this.state.vitalConfig;
        if(step==1){
            if(!config.temperature){step=2}
            else{return step;}
        }

        if(step==2){
            if(!config.bloodPressure){step=3}
            else{return step}
        }

        if(step==3){
            if(!config.oxygenSaturation && !config.pulse){step=4}
            else{return step}
        }

        if(step==4){
            if(!config.breathingDifficulty){step=5}
            else{return step}
        }

        if(step==5){
            if(!config.chestPain){step=6}
            else{return step}
        }

        if(step==6){
            if(!config.requiresAttention){step=7}
            else{return step}
        }

        return step;
    }

    onEnter=(e)=>{
        if(e){
            e.preventDefault();
        }
        this.onNext();
    }

    onBack=()=>{
        console.log("backing");
        const lastStep = this.prevStep.pop();
        this.setState({step:lastStep,slideRight:true,error:""});
    }

    onReset=()=>{
        this.setState({entryData:{
            breathingDifficulty:0,
            chestPain:0,
            requiresAttention:0,
        },step:"fin"});
    }

    txLocale=(key)=>{
        u.txLocale(key);
        this.setState({options:[{value:0,label:u.tx("no")},{value:1,label:u.tx("yes")}]});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const r = this.state.slideRight;
        const config = this.state.vitalConfig;
        return (
            <AppLayout title={u.tx("submit-vitals")} url="/submit-vitals" requiredPermission="none" containerStyle={{backgroundColor:"#f4f4f4"}}>
                <div style={{textAlign:"center",position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText} style={{backgroundColor:"#f4f4f4"}}/>

                    <div className="vital-step-container">
                        <Step active={(this.state.step == "fin")} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("submit-vitals")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/fin.png"/>
                                </div>

                                <div className="vital-step-input-container" >
                                    <div className="page-error">{this.state.error}</div>

                                    <form onSubmit={this.onNext}>
                                        <Box ref="fin" field="fin" values={{value:entryData.fin}} label="FIN"
                                            rules={{required:true,placeholder:u.tx("enter-fin")}}
                                            onChange={this.onChange}
                                            />
                                    </form>

                                    <div className="vital-button">
                                        <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                    </div>
                                </div>

                            </div>
                        </Step>

                        <Step active={(this.state.step == 1)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("take-temperature")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/temperature.png"/>
                                </div>

                                <div className="vital-step-input-container" >
                                    <div className="page-error">{this.state.error}</div>
                                    <form onSubmit={this.onNext}>
                                        <Box ref="temperature" field="temperature" values={{value:entryData.temperature}} label={u.tx("temperature")}
                                            rules={{required:true,placeholder:u.tx("temperature"),noLetters:true,minValue:30,maxValue:50}}
                                            onChange={this.onChange} type="number" pattern="[0-9.]*"
                                        />
                                    </form>
                                    <div className="vital-button">
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == 2)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("take-blood-pressure")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/blood-pressure.png"/>
                                </div>

                                <div className="vital-step-input-container" >
                                    <div className="page-error">{this.state.error}</div>


                                    <div className="vital-half">
                                        <form onSubmit={(e)=>{e.preventDefault(); this.refs['diastolicBP'].focus()}}>
                                            <Box ref="systolicBP" field="systolicBP" values={{value:entryData.systolicBP}} label="SYS"
                                                rules={{required:true,placeholder:"SYS",minValue:0,maxValue:500}} type="number"
                                                onChange={this.onChange}
                                            />
                                        </form>
                                    </div>

                                    <div className="vital-half right">
                                        <form onSubmit={this.onNext}>
                                            <Box ref="diastolicBP" field="diastolicBP" values={{value:entryData.diastolicBP}} label="DIA"
                                                rules={{required:true,placeholder:"DIA",minValue:0,maxValue:500}} type="number"
                                                onChange={this.onChange}
                                            />
                                        </form>
                                    </div>

                                    <div className="vital-button">
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == 3)} right={r}>
                            {
                                (config.oxygenSaturation && config.pulse)
                                ?
                                <div className="oxygen-pulse">
                                    <h2 className="vital-step-title">{u.tx("take-oxygen-pulse")}</h2>

                                    <div className="vital-step-image-container">
                                        <img src="/images/vitals/oxygen-pulse.png"/>
                                    </div>

                                    <div className="vital-step-input-container" >
                                        <div className="page-error">{this.state.error}</div>

                                            <div className="vital-half">
                                                <form onSubmit={(e)=>{e.preventDefault(); this.refs['pulse'].focus();}}>
                                                    <Box ref="oxygenSaturation" field="oxygenSaturation" values={{value:entryData.oxygenSaturation}} label="SpO2"
                                                    rules={{required:true,placeholder:"SpO2",minValue:0,maxValue:100}} type="number"
                                                    onChange={this.onChange}
                                                />
                                                </form>
                                            </div>

                                            <div className="vital-half">
                                                <form onSubmit={this.onNext}>
                                                    <Box ref="pulse" field="pulse" values={{value:entryData.pulse}} label="PR"
                                                    rules={{required:true,placeholder:"PR",minValue:0,maxValue:500}} type="number"
                                                    onChange={this.onChange}
                                                />
                                                </form>
                                            </div>


                                        <div className="vital-button">
                                            <div className="vital-half">
                                                <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                            </div>
                                            <div className="vital-half right">
                                                <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                (
                                    (config.oxygenSaturation)
                                    ?
                                    <div className="oxygen-only">
                                        <h2 className="vital-step-title">{u.tx("take-oxygen")}</h2>

                                        <div className="vital-step-image-container">
                                            <img src="/images/vitals/oxygen.png"/>
                                        </div>

                                        <div className="vital-step-input-container" >
                                            <div className="page-error">{this.state.error}</div>
                                            <form onSubmit={this.onNext}>
                                                <Box ref="oxygenSaturation" field="oxygenSaturation" values={{value:entryData.oxygenSaturation}} label="SpO2"
                                                    rules={{required:true,placeholder:"SpO2",minValue:0,maxValue:100}} type="number"
                                                    onChange={this.onChange}
                                                />
                                            </form>

                                            <div className="vital-button">
                                                <div className="vital-half">
                                                    <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                                </div>
                                                <div className="vital-half right">
                                                    <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="pulse-only">
                                        <h2 className="vital-step-title">{u.tx("take-pulse")}</h2>

                                        <div className="vital-step-image-container">
                                            <img src="/images/vitals/pulse.png"/>
                                        </div>

                                        <div className="vital-step-input-container" >
                                            <div className="page-error">{this.state.error}</div>
                                            <form onSubmit={this.onNext}>
                                                <Box ref="pulse" field="pulse" values={{value:entryData.pulse}} label="PR"
                                                    rules={{required:true,placeholder:"PR",minValue:0,maxValue:500}} type="number"
                                                    onChange={this.onChange}
                                                />
                                            </form>

                                            <div className="vital-button">
                                                <div className="vital-half">
                                                    <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                                </div>
                                                <div className="vital-half right">
                                                    <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Step>

                        <Step active={(this.state.step == 4)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("breathing-difficulty-question")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/breathing-difficulty.png"/>
                                </div>

                                <div className="vital-step-input-container" >

                                    <div className="page-error">{this.state.error}</div>

                                    <Select ref="breathingDifficulty" field="breathingDifficulty" values={{value:entryData.breathingDifficulty,options:this.state.options}} label={u.tx("breathing-difficulty")} rules={{required:true}} onChange={this.onChange}/>

                                    <div className="vital-button">
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Step>

                        <Step active={(this.state.step == 5)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("chest-pain-question")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/chest-pain.png"/>
                                </div>

                                <div className="vital-step-input-container" >
                                    <div className="page-error">{this.state.error}</div>
                                    <Select ref="chestPain" field="chestPain" values={{value:entryData.chestPain,options:this.state.options}} label={u.tx("chest-pain")} rules={{required:true}} onChange={this.onChange}/>

                                    <div className="vital-button">
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == 6)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("requires-attention-question")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/require-attention.png"/>
                                </div>

                                <div className="vital-step-input-container" >
                                    <div className="page-error">{this.state.error}</div>
                                    <Select ref="requiresAttention" field="requiresAttention" values={{value:entryData.requiresAttention,options:this.state.options}} label={u.tx("require-attention")} rules={{required:true}} onChange={this.onChange}/>
                                    <div className="vital-button">
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("next")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == 7)} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("submit-vitals-confirm")}</h2>

                                <div className="vital-step-image-container">
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">{u.tx("resident")}:</div>
                                        <div className="segment-item-input">{entryData.tenantName}</div>
                                    </div>
                                    {
                                        (config.temperature)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">{u.tx("temperature")}:</div>
                                            <div className="segment-item-input">{entryData.temperature}</div>
                                        </div>
                                        :
                                        null
                                    }

                                    {
                                        (config.bloodPressure)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">SYS/DIA:</div>
                                            <div className="segment-item-input">{entryData.systolicBP} / {entryData.diastolicBP}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        (config.oxygenSaturation)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">SpO2:</div>
                                            <div className="segment-item-input">{entryData.oxygenSaturation}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        (config.pulse)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">PR:</div>
                                            <div className="segment-item-input">{entryData.pulse}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        (config.breathingDifficulty)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">{u.tx("breathing-difficulty")}:</div>
                                            <div className="segment-item-input">{(entryData.breathingDifficulty==1) ? u.tx("yes") : u.tx("no")}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        (config.chestPain)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">{u.tx("chest-pain")}:</div>
                                            <div className="segment-item-input">{(entryData.chestPain==1) ? u.tx("yes") : u.tx("no")}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    {
                                        (config.requiresAttention)
                                        ?
                                        <div className="segment-full-item">
                                            <div className="segment-item-label">{u.tx("requires-attention")}:</div>
                                            <div className="segment-item-input">{(entryData.requiresAttention==1) ? u.tx("yes") : u.tx("no")}</div>
                                        </div>
                                        :
                                        null
                                    }
                                    <div className="vital-button">
                                        <div className="page-error">{this.state.error}</div>
                                        <div className="vital-half">
                                            <Button type="large" onClick={this.onBack}>{u.tx("back")}</Button>
                                        </div>
                                        <div className="vital-half right">
                                            <Button type="large" onClick={this.onNext}>{u.tx("confirm")}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "complete")} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("submit-success")}</h2>

                                <div className="vital-step-image-container">
                                    <img src="/images/vitals/doctor.png"/>
                                </div>

                                <h3>{u.tx("next-reading")} <span className="text-emphasis">{moment(this.state.nextVitalCheckStart).format("h:mma")}</span> - <span className="text-emphasis">{moment(this.state.nextVitalCheckEnd).format("h:mma")}</span> {u.tx("next-reading-long")}</h3>

                                <div className="vital-step-input-container">
                                    <div style={{margin:"30px auto"}}>
                                        <Button type="medium" onClick={this.onReset}>{u.tx("reset")}</Button>
                                    </div>
                                </div>
                            </div>
                        </Step>

                        <Step active={(this.state.step == "fail")} right={r}>
                            <div>
                                <h2 className="vital-step-title">{u.tx("no-window")}</h2>

                                <div className="vital-step-image-container closed">
                                    <img src="/images/vitals/closed.png"/>
                                </div>

                                <h3>{u.tx("next-reading")} <span className="text-emphasis">{moment(this.state.nextVitalCheckStart).format("h:mma")}</span> - <span className="text-emphasis">{moment(this.state.nextVitalCheckEnd).format("h:mma")}</span> {u.tx("next-reading-long")}</h3>

                                <div className="vital-step-input-container">
                                    <div style={{margin:"30px auto"}}>
                                        <Button type="medium" onClick={this.onReset}>{u.tx("reset")}</Button>
                                    </div>
                                </div>
                            </div>
                        </Step>
                    </div>

                    <div className="vital-tx">
                        <TextButton index="en" onClick={this.txLocale}>English</TextButton>
                        <TextButton index="zh" onClick={this.txLocale}>中文</TextButton>
                        <TextButton index="ta" onClick={this.txLocale}>தமிழ்</TextButton>
                        <TextButton index="bn" onClick={this.txLocale}>বাংলা</TextButton>
                    </div>

                </div>
            </AppLayout>
        )
    }
}
