import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,
        DownloadTemplate,DynamicListArea,BulkEdit,LoadGraphic,Step,
        Segment,SegmentComplete} from '../components/Common';
import {EditSizeLabel,EditableBox,EditableSelect,Box,Select,
        TextButton,Button} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import PropTypes, { number } from 'prop-types';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';
import "../styles/security-list.scss";

export default class SecurityList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Security",
        permission:"combined",  //Accepts combined permission module as well
        combinedField:"security",     //Used when permission exists in a combined field
        url:"/security-list",

        /**** Action Setup ****/
        //addType:2,
        //addURL:"/api/add-staff",
        //templateURL:"/api/get-add-staff-template",
        //bulkURL:"/api/add-bulk-staff",
        //deleteURL:"/api/delete-staff-entries",
        spreadsheetURL:"/api/generate-access-spreadsheet",
        overviewURL:"/api/get-all-access-logs",

        /**** List Setup ****/
        list:true,
        listLabel:"security-list",
        orderByField:"dateTime",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        check:{component:CheckTurnstile,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("combined");
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Turnstile",mobileLabel:"Turnstile Status",ref:"check",image:"/images/page-menu/live-monitor.png",title:"Check turnstile status",enabled:true,padding:"3px"},
            ],
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList  ref={(ref)=>{this.settings.overview=ref}}
                               settings={this.settings}
                               onSelectChange={this.onSelectChange}
                               registerReference={this.registerReference}
                               onMutatedChange={this.onMutatedChange}
                               location={this.props.location}
                               ignorePreviousTags={true}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <SecurityItem key={index} index={index} content={content} onChange={onChange} onContentChange={onContentChange} registerReference={registerRef} permissions={this.state.permissions}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class SecurityItem extends React.Component{
    state={
        loading:false,
        loadText:"",
        step:"view",
    }

    onClearPassback=()=>{
        this.setState({loading:true,loadText:"Clearing Passback...",error:""});
        u.post({
            url:"/api/access-clear-passback",
            data:{
               type:this.props.content.type,
               id:this.props.content.id,
            },
            success:()=>{
                this.setState({loading:false,step:"success"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const content = this.props.content;

        return (
            <div style={{position:"relative",textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={"security-list"} permission="combined" content={this.props.content} onChange={this.props.onChange} index={this.props.index}/>

                    <div className="list-segment-buttons">
                        {
                            (content.result == "Passback")
                                ?
                                <Button onClick={this.onClearPassback}>Clear Passback</Button>
                                :
                                null
                        }
                    </div>
                </Step>
                <Step active={this.state.step=="success"}>
                    <SegmentComplete onComplete={()=>{this.setState({step:"view"})}}>
                        <h2>Passback cleared successfully</h2>
                    </SegmentComplete>
                </Step>
            </div>
        )
    }
}

class CheckTurnstile extends React.Component{

    state={
        step:"select",
        dormOptions:[],
        loading:false,
        loadText:"",
        content:[],
        active:false,
    };

    componentDidMount=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onSubmit=()=>{
        this.setState({error:""});
        const dormID = this.refs['dormID'].validate();
        if(!dormID.valid){
            this.setState({error:dormID.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Checking..."});
        u.post({
            url:"/api/check-turnstile-status",
            data:{
               dormID:dormID.value
            },
            success:(content)=>{
                this.setState({content:content,step:"view",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            },
        });
        //this.setState({step:"view"});
    }

    render=()=>{
        const content=this.state.content;
        return(
            <Segment active={this.state.active}>
                <div className="segment-content" style={{textAlign:"center"}}>
                    <div className="segment-title">Check Turnstile Status</div>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step=="select"}>
                        <div className="segment-content">
                            <h3>Select a dormitory to continue</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-input">
                                    <Select ref="dormID" label="Dormitory" values={{options:this.state.dormOptions}} rules={{required:true}}/>
                                </div>
                            </div>

                            <div className="segment-buttons">
                                <Button type="medium" onClick={this.onSubmit}>Check Status</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step =="view"}>
                        <div className="segment-content">
                            {
                                (content.length > 0)
                                ?
                                <div className="turnstile-table-container">
                                    {
                                        content.map(
                                            (item,index)=>{
                                                return(
                                                    <div key={index} className="turnstile-table">
                                                        <h3>{item.label}</h3>
                                                        <table>
                                                            <tbody>
                                                                <tr>
                                                                    <th style={{borderTopLeftRadius:"5px"}}>Turnstile</th>
                                                                    <th>Status</th>
                                                                    <th style={{borderTopRightRadius:"5px"}}>Date/Time</th>
                                                                </tr>
                                                                {
                                                                    item.content.map(
                                                                        (ts,tsi)=>{
                                                                            return (
                                                                               <tr className="table-hover" key={tsi}>
                                                                                  <td>{ts.label}</td>
                                                                                  <td className={(ts.status=="Online" ? "text-emphasis" : "text-negative")}>{ts.status}</td>
                                                                                  <td>{(ts.statusDateTime) ? moment(ts.statusDateTime).format("DD MMM YY - h:mma") : "None"}</td>
                                                                               </tr>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                </div>
                                :
                                <div>
                                    <h3 className="text-negative">No Turnstiles Attached To Dormitory</h3>
                                </div>
                            }

                            <div className="segment-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:"select",error:""})}}>Back</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
