import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList,{MiniList} from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,
        DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete,
        Dropzone} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,TextButton,ContactNumber,Checkbox,
        Input,InputPhoto,MiniMinusButton} from '../components/Input';
import {Spreadsheet,Photo,Attachment,Report} from '../data/Mimetypes';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'query-string';
import {Link} from 'react-router-dom';
import store from 'store';

export default class AbsenceReasonList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Absence Reason",
        permission:"resident",
        url:"/absence-reason-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-absence-reason-entry",
        bulkURL:"/api/add-bulk-absence-reason-entries",
        templateURL:"/api/get-add-absence-reason-template",
        deleteURL:"/api/delete-absence-reason-entries",
        spreadsheetURL:"/api/generate-absence-reason-spreadsheet",
        overviewURL:"/api/get-all-absence-reason-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"absence-reason-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");
        const query = qs.parse(this.props.location.search);
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add",ref:"add",mobileLabel:"Add Entry",title:"Add new entry",enabled:(permissions.update==2)},
              {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",title:"Delete selected entries",enabled:(permissions.update==2)},
              {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",mobileLabel:"Bulk Edit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
            ],
            createRequest:query.create,
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        const pageComponents={
            add:{component:AddEntry,props:{create:this.state.createRequest}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
            bulkEdit:{component:BulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AbsenceReasonItem key={index}
                                                   content={content}
                                                   index={index}
                                                   onChange={onChange}
                                                   onContentChange={onContentChange}
                                                   registerReference={registerRef}
                                                   settings={this.settings}
                                                   permissions={this.state.permissions}
                                                   />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

export class MiniAbsenceReasonList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Absence Reason",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/absence-reason-list",
        overviewURL:"/api/get-all-absence-reason-entries",
        spreadsheetURL:"/api/generate-absence-reason-spreadsheet",

        /**** List Setup ****/
        listLabel:"absence-reason-list",
        orderByField:"createdOn",
        orderByType:"descending",
        //detailsLink:"/absence-reason-list/", //Leave null if linking to list
    };

    refresh=()=>{
        this.refs['list'].refresh();
    }

    render=()=>{
        return(
            <MiniList ref="list"
                      settings={this.settings}
                      tags={this.props.tags}
                      pageLength={this.props.pageLength}
                      orderByField={this.props.orderByField}
                      orderByType={this.props.orderByType}
            />
        )
    }
}

export class AbsenceReasonItem extends React.Component{

    state={
      loading:false,
      loadText:"",
      step:"view",
    };

    onDownloadFile=({filename,filestamp})=>{
        this.setState({error:""});
        u.download(filename,filestamp,undefined,(error)=>{
            this.setState({error:error});
        });
    }

    onRemoveAttachment=()=>{
        this.setState({loading:true,loadText:"Removing..."});
        u.post({
            url:'/api/remove-absence-reason-attachment',
            data:{
                absenceID:this.props.content.absenceID,
                attachment:this.props.content.attachments[this.state.removeIndex]
            },
            success:()=>{
                let attachments=this.props.content.attachments;
                attachments.splice(this.state.removeIndex,1);
                this.props.onChange(attachments,"attachments",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onAddAttachment=(url)=>{
        this.setState({loading:true,loadText:"Committing Attachment..."});
        u.post({
            url:"/api/add-absence-reason-attachment",
            data:{
               absenceID:this.props.content.absenceID,
               filename:this.userFilename,
               url:url
            },
            success:(entry)=>{
                let attachments=this.props.content.attachments;
                attachments.push(entry);
                this.props.onChange(attachments,"attachments",this.props.index);
                this.setState({step:"view",loading:false,error:""})
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const {content,permissions} = this.props;
        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />
                    <div>
                        {
                            (content.attachments.length > 0)
                                ?
                                <div className="list-table-container" style={{width:"600px",maxWidth:"100%",margin:"10px auto"}}>
                                  <h3>Attachments</h3>
                                  <table className="list-table" style={{borderTop:"1px solid #f4f4f4"}}>
                                      <tbody>
                                          {
                                              content.attachments.map(
                                                  (item,index)=>{
                                                      return (
                                                          <tr key={index}>
                                                              <td style={{width:"30px"}}>
                                                                  <div style={{width:"15px",height:"15px"}}>
                                                                      <MiniMinusButton onClick={()=>{this.setState({step:"remove-attachment",removeIndex:index,error:""})}}
                                                                                       enabled={permissions.update}
                                                                                       />
                                                                  </div>
                                                              </td>
                                                              <td>{item.filename}</td>
                                                              <td style={{width:"80px"}}>
                                                                  <TextButton onClick={()=>{this.onDownloadFile(item)}}>Download</TextButton>
                                                              </td>
                                                          </tr>
                                                      )
                                                  }
                                              )
                                          }
                                      </tbody>
                                  </table>
                                </div>
                                :
                                <div className='list-placeholder'>
                                    <b>No Attachments Added</b>
                                </div>
                        }
                        {
                            (permissions.update)
                                ?
                                <div style={{margin:"20px auto",textAlign:"center"}}>
                                    <TextButton onClick={()=>{this.setState({step:"add-attachment",error:""})}}>Add Attachment</TextButton>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div className="page-error">{this.state.error}</div>
                </Step>

                <Step active={this.state.step=="remove-attachment"} style={{position:"relative",textAlign:'center'}}>
                    <h2 className='text-negative'>Confirm remove attachment?</h2>
                    <div className="list-segment-note">Note. This action cannot be undone</div>

                    <div style={{margin:'20px auto'}}>
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onRemoveAttachment}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step=="add-attachment"} style={{position:"relative",textAlign:'center'}}>
                    <Dropzone label={"Attachment"}
                              accessibleForPreview={true}
                              onAdded={(filename)=>{this.userFilename = filename; this.setState({loading:true,loadText:"Uploading..."})}}
                              onUploaded={this.onAddAttachment}
                              onError={(error)=>{this.setState({error:error,loading:false})}}
                              onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                              filetypes={Report}
                              />
                    <div className="segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                    </div>
                </Step>
            </div>
        )
    }
}

export class AddEntry extends React.Component{

    state={
        step:"add",
        options:{},
        entryData:{
          startDate:moment().format("YYYY-MM-DDT08:00:00"),
          endDate:moment().format("YYYY-MM-DDT23:59:59"),
          attachments:[],
        },
        dormOptions:[],
        sickbayOptions:[],
        sickbedOptions:[],
        popup:false,
        poppedup:false,
        loadText:"",
        loading:false,
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
    }

    static getDerivedStateFromProps(props,state){
        if(props.create && !state.popup){
            state.popup=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.popup && !this.state.poppedup){
            let entryData=this.state.entryData;
            entryData.tenantNRIC = this.props.create;
            this.setState({poppedup:true});
            this.props.onToggleForm(this.props.reference,true);
        }
    }

    getOptions=()=>{
        const {override}=this.props;
        u.post({
            url:(override?.getEstateURL) ? override?.getEstateURL : "/api/get-estate-options",
            data:{type:"dorm"},
            success:(dormOptions)=>{
                if(this.props.dormID){
                    dormOptions = dormOptions.filter(opt => opt.value == this.props.dormID);
                }

                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });

        u.post({
            url:(override?.getOptionsURL) ? override?.getOptionsURL : "/api/get-options",
            data:{
              keys:["absenceReason","absenceHospital"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getSickbayOptions=()=>{
        u.post({
            url:"/api/get-sickbay-options",
            data:{
              dormID:this.state.entryData.dormID,
              onlyVacant:true,
            },
            success:(sickbayOptions)=>{
                this.setState({sickbayOptions:sickbayOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label] = value;
        if(label=="absenceReason" && value == "Sickbay" || label=="dormID" && entryData.absenceReason=="Sickbay"){
            this.getSickbayOptions();
        }
        if(label == "sickbayID"){
            this.setState({sickbedOptions:option.bedOptions});
        }
        this.setState({entryData:entryData});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onComplete=()=>{
        this.setState({entryData:{}});
    }

    onValidate=(fields)=>{
      this.setState({error:""});
      let keys = fields.map((item)=>{return item.field});
      keys.push("attachments");
      let data = {};
      for(var i=0; i<keys.length; i++){
          if(this.refs[keys[i]]){
              const current = this.refs[keys[i]].validate();
              if(!current.valid){
                  this.setState({error:current.value});
                  return 0;
              }
              data[keys[i]] = current.value;
          }
      }
      //Additional validation
      if(moment(data.startDate).isSameOrAfter(data.endDate,"minute")){
          this.setState({error:"End date must be at least 1 minute after start date"});
          return 0;
      }

      this.refs[this.props.reference].onValidated(data);
    }

    onAddAttachment=(url)=>{
        let entryData=this.state.entryData;
        entryData.attachments.push({filename:this.userFilename,url:url});
        this.setState({entryData:entryData,step:"add",error:"",loading:false});
    }

    onRemoveAttachment=(index)=>{
       let entryData=this.state.entryData;
       entryData.attachments.splice(index,1);
       this.setState({entryData:entryData});
    }

    render=()=>{
        const options=this.state.options;
        const fields=[
            {dataType:"select",label:"Dormitory",field:"dormID",options:this.state.dormOptions,disabled:(this.props.dormID)},
            {dataType:"box",label:"Resident FIN",field:"tenantNRIC",rules:{required:true},disabled:(this.props.tenantFIN)},
            {dataType:"date",label:"Start Date",field:"startDate",range:[1,2]},
            {dataType:"time",label:"Start Time",field:"startDate",rules:{skipSeconds:true}},
            {dataType:"date",label:"End Date",field:"endDate",range:[1,2]},
            {dataType:"time",label:"End Time",field:"endDate",rules:{skipSeconds:true}},
            {dataType:"select",label:"Reason",field:"absenceReason",options:options.absenceReason,rules:{required:true}},
            {dataType:"select",label:"Sickbay",field:"sickbayID",options:this.state.sickbayOptions},
            {dataType:"select",label:"Bed",field:"sickbedID",options:this.state.sickbedOptions},
            {dataType:"select",label:"Hospital",field:"hospital",options:options.absenceHospital},
            {dataType:"box",label:"Departure Flight",field:"departureFlight"},
            {dataType:"date",label:"Departure Date",field:"departureDate",range:[1,2]},
            {dataType:"time",label:"Departure Time",field:"departureTime"},
            {dataType:"box",label:"Departing To",field:"departingTo"},
            {dataType:"box",label:"Arrival Flight",field:"arrivalFlight"},
            {dataType:"date",label:"Arrival Date",field:"arrivalDate",range:[1,2]},
            {dataType:"time",label:"Arrival Time",field:"arrivalTime"},
            {dataType:"box",label:"Departing From",field:"departingFrom"},
            {dataType:"area",label:"Remarks",field:"remarks",rules:{placeholder:"Optional"}},
        ];
        const homeLeaveFields = ["departureFlight","departureDate","departureTime","departingTo","arrivalFlight","arrivalDate","arrivalTime","departingFrom"];
        let entryData=this.state.entryData;
        if(this.props.tenantFIN){
            entryData.tenantNRIC = this.props.tenantFIN
        }

        return(
            <AddListItem ref={this.props.reference}
                         title={"Add Absence Reason"}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onComplete}
                         onRefreshList={this.props.onRefreshList}
                         onToggleForm={this.props.onToggleForm}
                         dragAndDropLabel="Accepts templates downloaded from below"
                         >
                <div className="segment-content">
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                    <Step active={this.state.step=="add"}>
                        <h3>Complete the following</h3>
                        <div style={{margin:"20px auto",fontSize:"0.85em"}}>Smartdorm will automatically toggle the resident's absence on and off on the respective start and end dates</div>
                        {
                            fields.map(
                                (item,index)=>{
                                    if((item.field == "sickbayID" || item.field== "sickbedID") && entryData.absenceReason != "Sickbay"){
                                        return (<div key={index}/>)
                                    }
                                    else if(homeLeaveFields.includes(item.field) && entryData.absenceReason != "Home Leave"){
                                        return (<div key={index}/>)
                                    }
                                    else if(item.field=="hospital" && entryData.absenceReason != "Hospitalised"){
                                        return (<div key={index}/>)
                                    }
                                    return(
                                        <Input ref={item.field}
                                               key={index}
                                               fields={{
                                                 ...item,
                                                 ...{
                                                   onChange:this.onChange,
                                                   enabled:!(item.disabled),
                                                 }
                                               }}
                                               content={entryData}
                                               />
                                    )
                                }
                            )
                        }
                        <div className="segment-full-item">
                            <div className="segment-item-label">Attachments:</div>
                            <div className="segment-item-input">
                                <InputPhoto ref="attachments"
                                            label="Attachments"
                                            field="attachments"
                                            type="attachment"
                                            values={{value:entryData.attachments}}
                                            onAdd={()=>{this.setState({step:"add-attachment",error:""})}}
                                            onRemove={this.onRemoveAttachment}
                                            onChange={this.onChange}
                                            rules={{nullText:"Click to add attachment"}}
                                            />
                            </div>
                        </div>
                        <div style={{fontSize:"0.85em"}}>
                            <TextButton onClick={()=>{this.refs[this.props.reference].onBulkUpload()}}>Bulk Upload</TextButton>
                        </div>
                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            {
                                (u.isMobileDevice())
                                    ?
                                    <Button onClick={this.onCancel}>Cancel</Button>
                                    :
                                    null
                            }
                            <Button onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step=="add-attachment"}>
                        <Dropzone label={"Attachment"}
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.userFilename = filename; this.setState({loading:true,loadText:"Uploading..."})}}
                                  onUploaded={this.onAddAttachment}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Report}
                                  />
                        <div style={{fontSize:"0.85em",margin:"20px auto"}}>Attachments are documents that are attached when you Send Report</div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}

//Add Entry - For Exported Use
export class AddAbsenceReason extends React.Component{

    settings={
        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-absence-reason-entry",
        bulkURL:"/api/add-bulk-absence-reason-entries",
        templateURL:"/api/get-add-absence-reason-template",
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    render=()=>{
        return(
            <AddEntry ref={this.props.reference}
                      title="Add Absence Reason"
                      settings={this.settings}
                      reference={this.props.reference}
                      tenantFIN={this.props.tenantFIN}
                      dormID={this.props.dormID}
                      onComplete={this.props.onComplete}
                      onRefreshList={this.props.onRefreshList}
                      onToggleForm={this.props.onToggleForm}
                      />
        )
    }
}
