import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,TextButton,
        ContactNumber,Checkbox,Input} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';

export default class AttendanceList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Attendance",
        permission:"attendance",
        url:"/attendance-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/create-attendance-entry",
        deleteURL:"/api/delete-attendance-entries",
        spreadsheetURL:"/api/generate-attendance-spreadsheet",
        overviewURL:"/api/get-all-attendance-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"attendance-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("attendance");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");
        
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Entry",ref:"add",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AttendanceItem key={index}
                                                content={content}
                                                index={index}
                                                onChange={onChange}
                                                onContentChange={onContentChange}
                                                registerReference={registerRef}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class AttendanceItem extends React.Component{
    render=()=>{
        const content = this.props.content;
        return (
            <div>
                <DynamicListArea listLabel={"attendance-list"}
                                 permission="attendance"
                                 content={this.props.content}
                                 onChange={this.props.onChange}
                                 index={this.props.index}
                                 />

            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        options:{},
        entryData:{},
        dormOptions:[],
        eventOptions:[],
    }

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        //this.getOptions();
        this.getDormOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:[
                "yesNo","attendanceType"
              ]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{type:"dorm"},
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getEventOptions=(dormID)=>{
        u.post({
            url:"/api/get-attendance-event-options",
            data:{
                dormID:dormID,
                status:"Valid",
                onlyDateValid:true,
            },
            success:(eventOptions)=>{
                this.setState({eventOptions:eventOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onComplete=()=>{
        this.setState({
            error:"",
            entryData:{}
        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label,index)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        if(label=="dormID"){
            this.getEventOptions(value);
        }
        this.setState({entryData:entryData});
    }

    onValidate=(fields)=>{
        let keys = fields.map((item)=>{
          return item.field;
        });

        for(var i=0; i<keys.length; i++){
            if(keys[i] != "divider" && this.refs[keys[i]]){
                const current=this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
            }
        }

        this.refs[this.props.reference].onValidated(this.state.entryData);
    }

    render=()=>{
        const {dormOptions,eventOptions,options,entryData}=this.state;

        const fields=[
            {dataType:"select",field:"dormID",label:"Dormitory",options:dormOptions,rules:{required:true}},
            {dataType:"select",field:"eventID",label:"Event",options:eventOptions,rules:{required:true}},
            {dataType:"box",field:"residentNRIC",label:"Resident NRIC",rules:{required:true}},
        ];

        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         onCancel={this.onCancel}
                         stepScroll={true}
                         >

                <div>
                    <h3>Fill in the following to manually create entry</h3>
                    {
                        fields.map(
                            (item,index)=>{
                                return (
                                    <Input ref={item.field}
                                           key={index}
                                           fields={{
                                             ...item,
                                             onChange:this.onChange
                                           }}
                                           content={entryData}
                                           />
                                )
                            }
                        )
                    }

                </div>

                <div className='segment-note' style={{margin:"30px auto 0 auto"}}>Note. Resident/staff will not be able to scan this resident once created</div>

                <div className="segment-buttons">
                    <div className="segment-error">{this.state.error}</div>
                    <Button onClick={this.onCancel}>Cancel</Button>
                    <Button onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                </div>

           </AddListItem>
        )
    }
}
