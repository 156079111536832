import React from 'react';
import {Helmet,HelmetProvider} from 'react-helmet-async';
import {Header,Footer} from '../components/Common';
import PropTypes from 'prop-types';

export default class PublicPageLayout extends React.Component{

    state={
        instantiated:false,
    };

    componentDidMount=()=>{
        this.setState({
            instantiated:true
        });
    }

    render=()=>{
        if(!this.state.instantiated){
            return (<div/>)
        }

        return(
            <HelmetProvider>
                <div className={"page-container" + (this.props.print ? " print" : "")} style={this.props.containerStyle}>
                    <Helmet>
                        <title>{this.props.title} | Smartdorm</title>
                        <meta name="viewport" content="width=device-width,initial-scale=1"/>
                        <meta name="viewport" content="height=device-height,initial-scale=1"/>
                    </Helmet>
                    <Header type="logo" onLogoClick={this.props.onLogoClick} style={this.props.headerStyle}/>
                    <div className="public-content" style={this.props.style}>
                        {this.props.children}
                    </div>
                    <Footer type="half"/>

                    <div id="tool-tip" className="tool-tip">
                        <div id="tool-tip-text" className="tool-tip-text"/>
                        <div className="tool-tip-triangle"/>
                    </div>
                </div>
            </HelmetProvider>
        );
    }
};
PublicPageLayout.propTypes={
    title:PropTypes.string,
    url:PropTypes.string.isRequired,
};
