import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Info,Checkbox,Box,Button,EditableBox,Select,MiniPlusButton,
        MiniMinusButton,EditSizeLabel,EditSizeLink,EditableSelect,
        EditableDate,EditableArea,EditableTime,PageSelector} from './Input';
import u from '../utilities/Utilities';
import {Segment,LoadGraphic,Step,GenerateSpreadsheet} from './Common';
import {Link} from 'react-router-dom';
import store from 'store';
import queryString from 'query-string';
import '../styles/overview-list.scss';

/**
 * Creates a themed search list which is connected to the database
 *
 * @param {string} url
 *      API call url to get content of this list
 *
 * @param {string} label
 *      label should be the name of the list, update the api call to accept this list setting change
 *
 * @param {number} numRows
 *      number of entries to render per page
 *
 * @param {array} tags
 *      default tags that are in this search list
 *
 * @param {array} suggest
 *      all acceptable functional search parameters
 *
 * @param {string} orderByField
 *      default order by field
 *
 * @param {string} orderByType
 *      ascending or descending
 *
 */
export default class OverviewList extends React.Component{

    state={
        content:[],
        pageNumber:1,
        pageCount:1,
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        columnSettings:[],
        tags:[],
        orderByField:this.props.settings.orderByField,
        orderByType:this.props.settings.orderByType,
        appliedSettings:[],
        columnSuggest:{},
        tagSuggest:[],
        showGrid:false,
    };

    references={};

    componentDidMount=(e)=>{
        const listLabel = this.props.settings.listLabel;
        const settings = u.getListSettings(listLabel);
        const tagSettings = settings.tagMacros;

        const query = queryString.parse(this.props.location.search);

        let tags = [];

        const storeTags = store.get(listLabel);

        if(query.tags){
            tags = query.tags.split("~");
        }
        else if(this.props.defaultTags){
            tags=this.props.defaultTags;
        }
        else if(storeTags && !this.props.ignorePreviousTags){
            tags=storeTags.split("~");
        }
        else{
            if(typeof tagSettings == "undefined"){
                this.setState({error:"No Tag Macros Specified"});
                return 0;
            }

            for(var i=0; i<tagSettings.length; i++){
                if(tagSettings[i].macro == "default"){
                    tags = tagSettings[i].tags;
                    break;
                }
            }

            for(var t=0; t<tags.length; t++){
                let currentTag = tags[t];
                let modifyTag = -1;
                const tagStartIndex = currentTag.indexOf("(");
                const tagEndIndex = currentTag.lastIndexOf(")");

                if(tagStartIndex != -1 && tagEndIndex != -1){
                    const bracelessCheck = currentTag.substr(0,tagStartIndex);
                    const date = (currentTag.substr(tagStartIndex+1,(tagEndIndex-1) - tagStartIndex));
                    if(date.substr(0,5) == "today"){
                        if(date.length > 5){
                            //Check operator
                            const operator = date.substr(5,1);
                            const operationValue = date.substr(6);
                            modifyTag = bracelessCheck + "(" +((operator == "+") ? moment().add(parseInt(operationValue),"d").format("DD-MM-YYYY") : moment().subtract(parseInt(operationValue),"d").format("DD-MM-YYYY"))+ ")"
                        }
                        else{
                            modifyTag = bracelessCheck + "(" + moment().format("DD-MM-YYYY") + ")";
                        }
                    }
                }

                if(modifyTag != -1){
                    tags[t] = modifyTag;
                }
            }
        }

        this.setState({
            tags:tags,
            tagSuggest:settings.tagSuggest,
            appliedSettings:u.deepCloneArray(settings.columnSettings),
            columnSettings:settings.columnSettings,
        });

        this.getContent(tags,this.state.pageNumber,this.state.orderByField,this.state.orderByType);
    }

    getContent=(tags,page,orderByField,orderByType)=>{
        this.refs['list'].isLoading(true);
        const listLabel = this.props.settings.listLabel;
        let tagStore="";
        for(var t=0; t<tags.length; t++){
            tagStore += ((t>0) ? "~" : "") + tags[t];
        }

        store.set(listLabel,tagStore);

        u.post({
            url:this.props.settings.overviewURL,
            data:{
                tags:tags,
                rows:this.props.settings.numRows,
                pageNumber:page,
                orderByField:orderByField,
                orderByType:orderByType,
            },
            success:(e)=>{
                let selected = this.state.selected;
                selected.entryCount=parseInt(e.entryCount);
                selected.tags=tags;
                this.onSelectChange(selected);

                let columnSuggest = (e.columnSuggest) ? e.columnSuggest : {};
                let moddedSuggest = {};
                for(var key in columnSuggest){
                    moddedSuggest[key] = columnSuggest[key].slice(0);
                }

                let pageContent = e.pageContent;
                if(this.props.settings.timeMode){
                    let renderedDates=[];
                    for(var t=0; t<pageContent.length; t++){
                        const contentDate = moment(pageContent[t][this.props.settings.timeModeDateField]).format("YYYY-MM-DD");
                        if(renderedDates.indexOf(contentDate) == -1){
                            pageContent[t].showDate = true;
                            renderedDates.push(contentDate);
                        }
                    }
                }

                if(parseInt(page) > parseInt(e.pageCount)){
                    const newPage = (e.pageCount == 0) ? 1 : e.pageCount;
                    this.setState({pageNumber:newPage});
                    this.refs['list'].setPageNumber(newPage);
                }

                this.setState({
                    content:pageContent,
                    entryCount:e.entryCount,
                    pageCount:e.pageCount,
                    columnSuggest:moddedSuggest,
                });
                this.refs['list'].isLoading(false);
            },
            error:(e)=>{
                this.setState({error:e});
                if(this.refs['list']) {
                    this.refs['list'].isLoading(false);
                }
            }
        });
    }

    onSelected=(index,value)=>{
        const content = this.state.content[index];
        const id = content.listID || content.id;
        this.refs['list'].onSelected(id,value);
    }

    onPageChange=(page)=>{
        this.setState({pageNumber:page});
        this.getContent(this.state.tags,page,this.state.orderByField,this.state.orderByType);
    }

    onTagChange=(tags,orderByField,orderByType)=>{
        const selected = this.state.selected;
        if(selected.all || selected.data.length > 0){
            const entryCount = (selected.all) ? (selected.entryCount - selected.data.length) : selected.data.length;
            this.props.settings.onPopup({
                confirm:()=>{
                    this.onSelectChange({
                        tags:[],
                        all:false,
                        data:[],
                        entryCount:0
                    });

                    this.setState({
                      tags:tags,
                      orderByType:orderByType,
                      orderByField:orderByField,
                      pageNumber:1,
                    });

                    this.getContent(tags,1,orderByField,orderByType);
                },
                title:entryCount + ((entryCount == 1) ? " Entry " : " Entries ") + "Selected",
                description:"Selection will be reset upon tag change, confirm to continue",
            });
        }
        else{
            this.setState({
              tags:tags,
              orderByType:orderByType,
              orderByField:orderByField,
              pageNumber:1,
            });
            this.getContent(tags,1,orderByField,orderByType);
        }
    }

    onForceTags=(tags)=>{
        this.onTagChange(tags,this.state.orderByField,this.state.orderByType);
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
        if(this.props.onSelectChange){
            this.props.onSelectChange(selected);
        }
    }

    onItemChange=(value,field,index,onError,option)=>{
        let content = this.state.content.slice(0);
        content[parseInt(index)][field] = value;
        this.setState({content:content});
    }

    onContentChange=(newContent,index)=>{
        let content = this.state.content;
        content[index] = newContent;
        this.setState({content:content});
    }

    onColumnSettingChange=(value,apply)=>{
        let currentColumnSettings = this.state.columnSettings;

        if(apply){
            //Commit to last applied
            this.setState({appliedSettings:u.deepCloneArray(currentColumnSettings)});
            return 0;
        }

        if(value == null){
            //Revert to store settings
            currentColumnSettings = u.deepCloneArray(this.state.appliedSettings);
        }
        else{
            currentColumnSettings = value.slice(0);
        }
        this.setState({columnSettings:currentColumnSettings});
    }

    onRefreshList=()=>{
        let selected=this.state.selected;
        selected.all=false;
        selected.data=[];
        this.setState({selected:selected});
        this.getContent(this.state.tags,this.state.pageNumber,this.state.orderByField,this.state.orderByType);
    }

    onRegisterReference=(index,reference)=>{
        this.references[index+""] = reference;
    }

    onAddTag=(tag)=>{
        this.refs['list'].onAddTag(tag);
    }

    onMutatedChange=(value,label,index)=>{
        if(this.references[index+""]){
            this.references[index+""].onMutatedChange(value,label,index);
        }
    }

    triggerListOptions=(ref,show)=>{
        this.refs['list'].externalButtonTrigger(ref,show);
    }

    onShowGrid=(show)=>{
        this.setState({showGrid:show});
    }

    render=()=>{
        const emptyPadding = this.props.settings.numRows * 10;
        return (
            <div className={(this.state.showGrid) ? "show-grid" : ""}>
                <div className="overview-list-error">{this.state.error}</div>
                <SearchList ref="list"
                            label={this.props.settings.listLabel}
                            suggest={this.state.tagSuggest}
                            columnSuggest={this.state.columnSuggest}
                            selected={this.state.selected}
                            headers={this.state.columnSettings}
                            onSelectChange={this.onSelectChange}
                            onPageChange={this.onPageChange}
                            onTagChange={this.onTagChange}
                            pageNumber={this.state.pageNumber}
                            pageCount={this.state.pageCount}
                            entryCount={this.state.entryCount}
                            tags={this.state.tags}
                            orderByField={this.state.orderByField}
                            orderByType={this.state.orderByType}
                            onColumnSettingChange={this.onColumnSettingChange}
                            timeMode={this.props.settings.timeMode}
                            timeModeField={this.props.settings.timeModeField}
                            hideSearchBar={this.props.settings.hideSearchBar}
                            onShowGrid={this.onShowGrid}
                            settings={this.props.settings}
                            >
                    {
                        (this.state.content.length > 0)
                            ?
                            this.state.content.map((item,index)=>{
                                const hasID = (this.state.selected.data.indexOf(item.listID || item.id) != -1);
                                const isSelected = ((hasID && !this.state.selected.all) || (!hasID && this.state.selected.all));

                                return (
                                    <SearchListItem key={index}
                                                    label={this.props.settings.listLabel}
                                                    index={index+""}
                                                    pageIndexCounter={(this.state.pageNumber-1) * this.props.settings.numRows}
                                                    selected={isSelected}
                                                    onSelected={this.onSelected}
                                                    content={item}
                                                    timeModeDateField={this.props.settings.timeModeDateField}
                                                    headers={this.state.columnSettings}
                                                    timeMode={this.props.settings.timeMode}
                                                    timeModeField={this.props.settings.timeModeField}
                                                    disableChild={this.props.settings.disableChild}
                                                    onChange={this.onItemChange}
                                                    registerReference={this.onRegisterReference}
                                                    onMutatedChange={this.onMutatedChange}
                                                    checkboxPermissions={this.props.settings.checkboxPermissions}>
                                        {
                                            this.props.children(item,index,this.onItemChange,this.onContentChange,this.onRegisterReference)
                                        }
                                    </SearchListItem>
                                )
                            })
                            :
                            <div className="overview-placeholder" style={{padding:emptyPadding + "px 0"}}>Empty</div>
                    }
                </SearchList>
            </div>
        )
    }
}
OverviewList.propTypes={
    settings:PropTypes.object.isRequired,
};


class SearchList extends React.Component{

    state={
        tagError:"",
        pageNumber:1,
        showColumnSettings:false,
        showTagSettings:false,
        loading:false,
        suggest:[],
        prevSuggest:[],
        validatedSuggest:[],
    };

    scrollAnchor=u.getKey();

    componentDidMount=(e)=>{
        window.addEventListener("resize", this.resizeTagSpace);
    }

    resizeTagSpace=()=>{
        if(window.innerWidth > 900){
            this.setState({tagWidth:null});
        }
        else{
            this.setState({tagWidth:"85%"});
        }
    }

    componentWillUnmount=()=>{
        window.removeEventListener("resize",this.resizeTagSpace);
    }

    externalButtonTrigger=(ref,show)=>{
        if(ref=="macro"){
            this.setState({showTagSettings:show});
        }
        else if(ref=="column"){
            this.setState({showColumnSettings:show});
        }
    }

    onSelected=(id,value)=>{
        //Clone select so we dont fuck with parent variable
        let selected = Object.assign({},this.props.selected);

        const addID = ((selected.all && !value) || (!selected.all && value));

        if(addID){
            selected.data.push(id);
        }
        else{
            const index = selected.data.indexOf(id);
            if(index != -1) {
                selected.data.splice(index, 1);
            }
        }
        this.props.onSelectChange(selected);
    }

    onSelectAll=(value)=>{
        //Clone select so we dont fuck with parent variable
        let selected = Object.assign({},this.props.selected);
        selected.all = value;
        selected.data = [];
        this.props.onSelectChange(selected);
    }

    onAddTag=(newTag)=>{
        if(this.state.loading){
            return 0;
        }
        this.setState({tagError:""});

        if(newTag.indexOf("~") != -1){
            this.setState({tagError:"Cannot use ~ in tags"});
            return 0;
        }

        let tags = [];
        let priorityTag = (newTag.substr(0,1) == ":");

        //Check for macros
        if(newTag.substr(0,1) == "#"){
            let added = false;
            let macro = newTag.substr(1);
            const allTagMacros = store.get("tagMacros");
            const tagMacros = allTagMacros[this.props.label];

            for(var i=0; i<tagMacros.length; i++){
                if(tagMacros[i].macro.toLowerCase() == macro.toLowerCase()){
                    added = true;
                    tags=[];
                    for(var j=0; j<tagMacros[i].tags.length; j++){
                        let macroTag = tagMacros[i].tags[j];
                        //Add all tags one at a time
                        if(macroTag.substr(0,1) == ":" || macroTag.substr(0,1) == "@"){
                            const result = this.constructor.validateTag(tags,macroTag,this.state.validateSuggest);
                            if(result.modifyTag){
                                macroTag = result.modifyTag;
                            }
                            if(!result.valid){
                                this.setState({tagError:result.value});
                                return 0;
                            }
                        }
                        if(macroTag.substr(0,1) == ":"){
                            tags.unshift(macroTag);
                        }
                        else{
                            tags.push(macroTag);
                        }
                    }
                    this.props.onTagChange(tags,this.props.orderByField,this.props.orderByType);
                    this.refs['searchBar'].clear();
                }
            }

            if(!added){
                this.setState({tagError:"Macro does not exist"});
                return 0;
            }
        }
        else{
            tags = this.props.tags.slice(0);
            ///Validate tags///
            const operator = newTag.substr(0,1);
            if(operator == ":" || operator == "@" || operator == "+"){
                const currentTags = this.props.tags.slice(0);
                const result = this.constructor.validateTag(currentTags,newTag,this.state.validateSuggest);
                if(result.modifyTag){
                    newTag = result.modifyTag;
                }
                if(!result.valid){
                    this.setState({tagError:result.value});
                    return 0;
                }

                if(result.value != "None"){
                    const index = parseInt(result.value);
                    tags.splice(index,1);
                }
            }
            ///////////////////
            if(priorityTag){
                tags.unshift(newTag)
            }
            else{
                tags.push(newTag);
            }

            this.props.onTagChange(tags,this.props.orderByField,this.props.orderByType);
            this.refs['searchBar'].clear();
        }
    }

    onRemoveTag=(index,label)=>{
        if(this.state.loading) {
            return 0;
        }
        this.setState({tagError:""});
        let tags = this.props.tags.slice(0);
        tags.splice(parseInt(index),1);
        this.props.onTagChange(tags,this.props.orderByField,this.props.orderByType);
    }

    refreshList=()=>{
        if(this.state.loading)
            return 0;

        this.setState({tagError:""});
        this.props.onTagChange(this.props.tags,this.props.orderByField,this.props.orderByType);
    }

    onSubmitPageNumbers=(newPage)=>{
        if(newPage == ""){
            newPage = 1;
        }

        if(newPage > this.props.pageCount){
            newPage = this.props.pageCount;
        }
        else if(newPage < 1){
            newPage = 1;
        }
        this.props.onPageChange(newPage,this.state.tags);
    }

    onSearchChange=()=>{
        this.setState({tagError:""});
    }

    static validateTag(currentTags,tag,validateSuggest){
        //Check if tag exists in list
        const allowedTags = validateSuggest;
        const operator = tag.substr(0,1);
        const reservedTag = (operator == ":");

        let toRemove = "None";
        let modifyTag = null;

        for(var i=0; i<allowedTags.length; i++){
            const check = allowedTags[i];
            const braced = (check.indexOf("(") != -1);
            if(tag.toLowerCase() == check.toLowerCase() && !braced){
                //Find replacement
                for(var j=0; j<currentTags.length; j++){
                    const currentNonReserved = (currentTags[j].substr(0,1) == ":") && (currentTags[j].indexOf("(") == -1);
                    if(tag == currentTags[j]){
                        return {valid:false,value:"Tag has already been added"};
                    }
                    else if(reservedTag && currentNonReserved){
                        toRemove = j + "";
                        break;
                    }
                }
                return {valid:true,value:toRemove};
            }
            else if(braced){
                const checkStartIndex = check.indexOf("(");
                const checkEndIndex = check.lastIndexOf(")");
                const tagStartIndex = tag.indexOf("(");
                const tagEndIndex = tag.lastIndexOf(")");

                //Validation Rules
                //1) Check if tag is allowed
                const bracelessCheck = check.substr(0,checkStartIndex);
                const checkValue = check.substr(checkStartIndex,checkEndIndex);
                const bracelessTag = tag.substr(0,tagStartIndex) + ((tagEndIndex + 1 == tag.length) ? "" : tag.substr(tagEndIndex+1));

                //2) Check if tags are in reverse order
                if(tagStartIndex > tagEndIndex){
                    return {valid:false,value:"Braces are in the wrong order"};
                }

                const rule = (checkStartIndex + 1 == checkEndIndex) ? "" : check.substr(checkStartIndex+1,(checkEndIndex-1) - checkStartIndex);

                if(bracelessCheck.toLowerCase() == bracelessTag.toLowerCase()){

                    if(tagStartIndex== -1 || tagEndIndex == -1){
                        return {valid:false, value: "Tag is missing braces and parameters"};
                    }

                    //3) Check if brace has validation rules
                    if(rule==""){   //() Empty Braces -- Requires some sort of input
                        if(tagStartIndex + 1 == tagEndIndex){   //No parameter found
                            return {valid:false, value:"Parameter required between braces"};
                        }

                        const parameter = tag.substring(tagStartIndex+1,tagEndIndex);
                        modifyTag = bracelessCheck + "(" + parameter + ")";
                    }
                    else if(rule == "DD-MM-YYYY"){
                        const date = (tag.substr(tagStartIndex+1,(tagEndIndex-1) - tagStartIndex));
                        if(date.substr(0,5) == "today"){
                            if(date.length > 5){
                                modifyTag = this.dateReplace(date,"DD-MM-YYYY",bracelessCheck);
                            }
                            else{
                                modifyTag = bracelessCheck + "(" + moment().format("DD-MM-YYYY") + ")";
                            }
                        }
                        else{
                            const tagContent = date.split("-");

                            if(tagContent.length != 3){
                                return {valid:false, value:"Please enter a valid date (DD-MM-YYYY) or (today)"}
                            }

                            if(tagContent[0].length != 2 || isNaN(parseInt(tagContent[0]))){
                                return {valid:false, value:"Please enter a valid DD"};
                            }
                            if (parseInt(tagContent[0]) < 1 || parseInt(tagContent[0]) > 31){
                                return {valid:false, value:"Please enter a valid DD"};
                            }

                            if(tagContent[1].length != 2 || isNaN(parseInt(tagContent[1]))){
                                return {valid:false, value:"Please enter a valid MM"};
                            }
                            if (parseInt(tagContent[1]) < 1 || parseInt(tagContent[1]) > 12){
                                return {valid:false, value:"Please enter a valid MM"};
                            }

                            if(tagContent[2].length != 4 || isNaN(parseInt(tagContent[2]))){
                                return {valid:false, value:"Please enter a valid YYYY"};
                            }

                            if(!moment(tagContent[2] + "-" + tagContent[1] + "-" + tagContent[0],"YYYY-MM-DD",true).isValid()){
                                return {valid:false, value: tagContent[0] + "-" + tagContent[1] + "-" + tagContent[2] + " is not a valid date"};
                            }

                            modifyTag = bracelessCheck + "(" + date + ")";
                        }
                    }
                    else if(rule == "DD-MM"){
                        const date = (tag.substr(tagStartIndex+1,(tagEndIndex-1) - tagStartIndex));
                        if(date.substr(0,5) == "today"){
                            if(date.length > 5){
                                modifyTag = this.dateReplace(date,"DD-MM",bracelessCheck);
                            }
                            else{
                                modifyTag = bracelessCheck + "(" + moment().format("DD-MM") + ")";
                            }
                        }
                        else{
                            const tagContent = date.split("-");
                            if(tagContent.length != 2){
                                return {valid:false, value:"Please enter a valid date (DD-MM) or (today)"}
                            }

                            if(tagContent[0].length != 2 || isNaN(parseInt(tagContent[0]))){
                                return {valid:false, value:"Please enter a valid DD"};
                            }
                            if (parseInt(tagContent[0]) < 1 || parseInt(tagContent[0]) > 31){
                                return {valid:false, value:"Please enter a valid DD"};
                            }

                            if(tagContent[1].length != 2 || isNaN(parseInt(tagContent[1]))){
                                return {valid:false, value:"Please enter a valid MM"};
                            }
                            if (parseInt(tagContent[1]) < 1 || parseInt(tagContent[1]) > 12){
                                return {valid:false, value:"Please enter a valid MM"};
                            }

                            if(!moment("2020-" + tagContent[1] + "-" + tagContent[0],"YYYY-MM-DD",true).isValid()){
                                return {valid:false, value: tagContent[0] + "-" + tagContent[1] + " is not a valid date"};
                            }
                            modifyTag = bracelessCheck + "(" + date + ")";
                        }
                    }
                    else if(rule == "YYYY"){
                        const date = (tag.substr(tagStartIndex+1,(tagEndIndex-1) - tagStartIndex));
                        if(date.substr(0,5) == "today"){
                            if(date.length > 5){
                                modifyTag = this.dateReplace(date,"YYYY",bracelessCheck);
                            }
                            else{
                                modifyTag = bracelessCheck + "(" + moment().format("YYYY") + ")";
                            }
                        }
                        else{
                            if(!moment(date + "-01-01","YYYY-MM-DD",true).isValid()){
                                return {valid:false, value: "Please enter a valid year (YYYY) or (today)"};
                            }

                            modifyTag = bracelessCheck + "(" + date + ")";
                        }
                    }
                    else if(rule == "HH:MM"){
                        const time = (tag.substr(tagStartIndex+1,(tagEndIndex-1) - tagStartIndex)).replace(/ /g,"");
                        if(time.substr(0,3) == "now"){
                            if(time.length > 3){
                                //Check operator
                                const operator = time.substr(3,1);
                                const operationValue = time.substr(4);

                                if(typeof operator == "undefined" || (operator != "+" && operator != "-")){
                                    return {valid:false,value:"Invalid time. Please enter in format now+1 or now-2"};
                                }

                                if(typeof operationValue == "undefined" || isNaN(parseInt(operationValue))){
                                    return {valid:false,value:"Invalid time. Please enter in format now+1 or now-2"};
                                }
                                modifyTag = bracelessCheck + "(" +((operator == "+") ? moment().add(parseInt(operationValue),"h").format("HH:mm") : moment().subtract(parseInt(operationValue),"h").format("HH:mm"))+ ")"
                            }
                            else{
                                modifyTag = bracelessCheck + "(" + moment().format("HH:mm") + ")";
                            }
                        }
                        else{
                            const tagContent = time.split(":");
                            if(tagContent.length != 2){
                                return {valid:false, value:"Please enter a valid time (HH:MM) or (now)"}
                            }

                            if(tagContent[0].length != 2 || isNaN(parseInt(tagContent[0]))){
                                return {valid:false, value:"Please enter a valid HH"};
                            }
                            if (parseInt(tagContent[0]) < 0 || parseInt(tagContent[0]) > 23){
                                return {valid:false, value:"HH must be between 00 & 23"};
                            }

                            if(tagContent[1].length != 2 || isNaN(parseInt(tagContent[0]))){
                                return {valid:false, value:"Please enter a valid HH"};
                            }
                            if (parseInt(tagContent[1]) < 0 || parseInt(tagContent[0]) > 59){
                                return {valid:false, value:"HH must be between 00 & 59"};
                            }

                            modifyTag = bracelessCheck + "(" + time + ")";
                        }
                    }

                    for(var k=0; k<currentTags.length; k++){
                        const bctStart = currentTags[k].indexOf("(");
                        const bctEnd = currentTags[k].lastIndexOf(")");

                        if(bctStart != -1 && bctEnd != -1){
                            const bctOperator = currentTags[k].substr(0,1);
                            const bctTag = currentTags[k].substr(1,bctStart-1);
                            const compare = bracelessTag.substr(1);
                            const bctContent = currentTags[k].substring(bctStart+1,bctEnd);
                            const bctNot = (bctContent.substr(0,1) == "!");
                            const content = tag.substring(tagStartIndex+1,tagEndIndex);
                            const contentNot = (content.substr(0,1) == "!");

                            if(bctTag.toLowerCase()==compare.toLowerCase()){
                                if((bctOperator == "@" && operator == "+") || (bctOperator == "+" && operator == "@")){
                                    return {valid:false,value:"Cannot add @ and + operators on the same field"};
                                }
                                else if(bctContent == content){
                                    return {valid:false,value:"Tag has already been added"};
                                }

                                toRemove = ((bctOperator == "+" && operator == "+") || (bctNot && contentNot)) ? "None" : k + "";
                            }
                        }
                    }

                    return {valid:true,value:toRemove,modifyTag:modifyTag};
                }
            }
        }
        return {valid:false,value:"Unidentified reserved tag"};
    }

    static dateReplace(date,format,bracelessCheck){
        //Check operator
        date = date.replace(/ /g,"");
        const operator = date.substr(5,1);
        const operationValue = date.substr(6);

        if(typeof operator == "undefined" || (operator != "+" && operator != "-")){
            return {valid:false,value:"Invalid date. Please enter in format today+1 or today-2"};
        }

        if(typeof operationValue == "undefined" || isNaN(parseInt(operationValue))){
            return {valid:false,value:"Invalid date. Please enter in format today+1 or today-2"};
        }

        return bracelessCheck + "(" +((operator == "+") ? moment().add(parseInt(operationValue),"d").format(format) : moment().subtract(parseInt(operationValue),"d").format(format))+ ")"
    }

    static getDerivedStateFromProps=(props,state)=>{
        if(props.suggest != state.prevSuggest){
            let macros = u.getTagMacros(props.label);
            if(typeof macros == "undefined"){
               macros = [];
            }
            state.prevSuggest = props.suggest;
            state.promptParent = true;
            state.macros = macros;
        }
        return state;
    }

    componentDidUpdate=(prevProps,prevState)=>{
        if(this.state.promptParent){
            this.setState({promptParent:false});
            this.onUpdateSuggest(this.state.prevSuggest,this.state.macros);
        }
    }

    onUpdateSuggest=(suggest,macros)=>{
        let validateSuggest = suggest.slice(0);
        for(var t=0; t<suggest.length; t++){
            if(suggest[t].substr(0,1) == "@"){
                const newTag = "+" + suggest[t].substr(1);
                validateSuggest.push(newTag);
            }
        }

        let macroSuggest = validateSuggest.slice(0);
        for(var i=0; i<macros.length; i++){
            macroSuggest.push("#"+macros[i].macro);
        }

        this.setState({suggest:macroSuggest,validateSuggest:validateSuggest});
    }

    updateSort=(e)=>{
        if(this.state.loading){
            return 0;
        }
        const field = e.currentTarget.id;
        if(field == this.props.orderByField){
            //Reverse orderByType
            const newSortDirection = (this.props.orderByType == "ascending") ? "descending" : "ascending";
            this.props.onTagChange(this.props.tags,this.props.orderByField,newSortDirection);
        }
        else{
            //Set New Order Field
            this.props.onTagChange(this.props.tags,field,this.props.orderByType);
        }
    }

    toggleColumnSettings=(e)=>{
        if(e)
            e.preventDefault();

        if(this.state.loading){
            return 0;
        }

        this.props.onShowGrid(!this.state.showColumnSettings);
        this.setState({showColumnSettings:!this.state.showColumnSettings});

    }

    toggleTagSettings=(e)=>{
        if(e)
            e.preventDefault();

        if(this.state.loading)
            return 0;
        this.setState({showTagSettings:!this.state.showTagSettings});
    }

    onClickTag=(tag)=>{
        this.refs['searchBar'].onClickAdd(tag);
    }

    isLoading=(value)=>{
        this.setState({loading:value});
    }

    triggerAddTag=(e)=>{
        this.refs['addMacroTagInput'].triggerAddTag(e);
    }

    render=()=>{
        const infoToolTip="<div><b>@column(interest)</b><br/>Targets specific column, only return entries with column value like interest<br/><br/><b>+column(interestA)</b><br/>Mutiple targeting of specific column, returns entries with column value like interestA or interestB<br/><br/><b>(!interest)</b><br/>! is short for NOT, entries with column value equals to interest will be removed from the list</div>";
        return (
            <div className="search-list">
                {
                    (this.props.hideSearchBar)
                        ?
                        ""
                        :
                        <div className="overview-header">
                            <div className={"overview-search" + ((this.props.loading) ? " loading" : "")}>
                                <div className="search-tag-title"><span className="search-tag-title-disposable">Search </span>Tags <Info style={{width:"15px",height:"15px",marginBottom:"3px"}} title={infoToolTip}/> :</div>
                                <div className="search-bar-form">
                                    <div className="tag-error">{this.state.tagError}</div>
                                    <div className="search-list-bar-container">
                                        <SearchBar ref="searchBar" label={this.props.label+"-search-bar"} loading={this.state.loading} suggest={this.state.suggest} columnSuggest={this.props.columnSuggest} onAddTag={this.onAddTag} onSearchChange={this.onSearchChange}/>
                                    </div>
                                </div>
                            </div>

                            <div className="overview-tags" style={{width:this.state.tagWidth}}>
                                {this.props.tags.map(
                                    (tag,index) => {
                                        return <Tag key={index} index={index+""} label={tag} onRemove={this.onRemoveTag} clickable={true} onClick={this.onClickTag}/>
                                    }
                                )}
                            </div>
                            <div className="overview-list-options">
                                {
                                    (this.props.timeMode)
                                        ?
                                        <div className="overview-list-options-all">All <Checkbox onChange={this.onSelectAll}/></div>
                                        :
                                        ""
                                }
                                <img src="/images/list/list-tag-settings.png" className={(this.state.loading) ? "loading" : ""} title="Tag macros" onClick={this.toggleTagSettings}/>
                                <img src="/images/list/list-settings.png" className={(this.state.loading) ? "loading" : ""} title="Column settings" onClick={this.toggleColumnSettings}/>
                                <img src="/images/list/list-refresh.png" className={(this.state.loading) ? "loading" : ""} title="Refresh list" onClick={this.refreshList}/>
                            </div>
                        </div>
                }

                <div id={this.scrollAnchor}/>

                <TagMacros active={this.state.showTagSettings} onUpdateSuggest={this.onUpdateSuggest} suggest={this.props.suggest}
                           columnSuggest={this.props.columnSuggest} label={this.props.label} toggleTagSettings={this.toggleTagSettings}
                           settings={this.props.settings}/>

                <ColumnSettings active={this.state.showColumnSettings} headers={this.props.headers} label={this.props.label}
                                scrollAnchor={this.scrollAnchor} toggleColumnSettings={this.toggleColumnSettings}
                                onColumnSettingChange={this.props.onColumnSettingChange}
                                settings={this.props.settings}/>

                <div className="overview-list">
                    {
                        (this.props.timeMode)
                            ?
                            ""
                            :
                            <div className="overview-item-header">
                                <div className={"overview-item-select select-mode"}>
                                    <Checkbox label="Search List - Select All" ref="checkbox"
                                              values={{value: this.props.selected.all, enabled: !this.state.loading}}
                                              onChange={this.onSelectAll}/>
                                </div>
                                <div className="overview-item-headers-container">
                                    {
                                        this.props.headers.map(
                                            (header, index)=> {
                                                const title = (header.tag) ? header.tag + "()" : header.label;
                                                return (
                                                    (header.selected)
                                                        ?
                                                        <div key={index} style={{width: header.width + "%", whiteSpace: ((header.wrap) ? "normal" : "nowrap"), wordWrap: ((header.wrap) ? "break-word" : "normal"), textOverflow: "ellipsis", textAlign: ((header.align) ? header.align : "left")}}
                                                             className={"overview-header-column overview-header-column-" + this.props.label + "-" + index + ((this.state.loading) ? " loading" : "")}
                                                             title={title} id={header.field}
                                                             onClick={this.updateSort}>
                                                            {header.label}
                                                            <img src="/images/list/list-sort.png" className={"overview-list-sort" + ((this.props.orderByField == header.field) ? " active" : "") + ((this.props.orderByType == "descending") ? " descending" : "")}/>
                                                        </div>
                                                        :
                                                        ""
                                                )
                                            }
                                        )
                                    }
                                </div>
                            </div>
                    }
                    <div className="overview-list-content">
                        <LoadGraphic active={this.state.loading} text="Preparing content..." opacity={"0.8"}/>
                        {this.props.children}
                    </div>
                </div>

                {
                    (this.props.entryCount > 0)
                        ?
                        <div className="page-total">
                            Total: {this.props.entryCount} entries
                        </div>
                        :
                        null
                }
                {
                    (this.props.pageCount > 1)
                        ?
                        <PageSelector className="list"
                                      page={this.props.pageNumber}
                                      maxPage={this.props.pageCount}
                                      onChange={this.onSubmitPageNumbers}
                                      disabled={this.state.loading}/>
                        :
                        ""
                }
            </div>
        )
    }
}
SearchList.propTypes={
    label:PropTypes.string.isRequired,
    suggest:PropTypes.array.isRequired,
    headers:PropTypes.array.isRequired,
    selected:PropTypes.object.isRequired,
    onSelectChange:PropTypes.func.isRequired,
    onPageChange:PropTypes.func.isRequired,
    onTagChange:PropTypes.func.isRequired,
    onColumnSettingChange:PropTypes.func.isRequired,
    pageCount:PropTypes.number.isRequired,
    tags:PropTypes.array.isRequired,
    orderByField:PropTypes.string.isRequired,
    orderByType:PropTypes.string.isRequired,
    columnSuggest:PropTypes.object.isRequired,
    timeMode:PropTypes.bool,
    timeModeField:PropTypes.string
};


class SearchListItem extends React.Component{

    state={
        active:false
    };

    toggleItem=(e)=>{
        e.preventDefault();
        if(this.props.disableChild){
            return 0;
        }
        this.setState({active:!this.state.active});
    }

    onCheckboxClick=(value,label,index)=>{
        this.props.onSelected(this.props.index,value);
    }

    onMutatedChange=(value,label,index)=>{
        this.setState({active:true});
        this.props.onMutatedChange(value,label,index);
    }

    render=()=>{
        return (
            <div className="overview-item">
                {
                    (this.props.timeMode && (this.props.timeModeDateField) && this.props.content.showDate)
                        ?
                        <h3 className="overview-item-date">
                            {moment(this.props.content[this.props.timeModeDateField]).format("ddd - DD MMM YYYY")}
                        </h3>
                        :
                        ""
                }
                <div className={"overview-item-title" + ((this.props.selected) ? " selected" : "") + ((this.state.active) ? " active" : "") + ((this.props.disableChild) ? " time-mode" : "")} onClick={this.toggleItem}>
                    <div className="overview-item-num">#{parseInt(this.props.index) + 1 + this.props.pageIndexCounter}</div>
                    <div className={"overview-item-select select-mode"}>
                        <Checkbox label={this.props.label+"-"+"select"} index={this.props.index} ref="checkbox" values={{value:this.props.selected}} onChange={this.onCheckboxClick}/>
                    </div>
                    <div className="overview-item-headers-container">
                        {
                            this.props.headers.map(
                                (header,index)=>{
                                    if(!header.selected){
                                        return;
                                    }

                                    const content = this.props.content[header.field];
                                    const nullText=(header.nullText) ? header.nullText : "None";
                                    const style = {width:header.width+"%", whiteSpace:((header.wrap) ? "normal" : "nowrap"), wordWrap:((header.wrap) ? "break-word" : "nowrap"), textOverflow:"ellipsis", textAlign:((header.align) ? header.align : "left")};
                                    const className= this.props.label+"-"+index;
                                    const title= (header.tag) ? (header.tag+"("+this.props.content[header.field]+")") : header.label;

                                    const isAddition = (typeof header.field == "string") ? (header.field.indexOf("+") != -1) : false;
                                    if(isAddition){
                                        const addedFields = (isAddition) ? header.field.split("+") : [];
                                        let addedValue = 0;
                                        for(var i=0; i<addedFields.length; i++){
                                            addedValue += parseInt(this.props.content[addedFields[i]]);
                                        }
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{addedValue}</div>
                                        )
                                    }

                                    if(header.type == "dateTime" || header.type=="datetime"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{ ((content) ? moment(content).format("DD MMM YY - hh:mma") : nullText)}</div>
                                        )
                                    }

                                    if(header.type == "date"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{((content) ? moment(content).format("DD MMM YY") : nullText)}</div>
                                        )
                                    }

                                    if(header.type == "time"){
                                        const moddedContent = content ? (content.indexOf("T") != -1) ? content : "2020-01-01T" + content : null;
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{((content) ? moment(moddedContent).format("hh:mma") : nullText)}</div>
                                        )
                                    }

                                    if(header.field == this.props.timeModeField){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{this.props.children}</div>
                                        )
                                    }

                                    if(header.type == "checkbox"){

                                        let enabled = true;
                                        if(this.props.checkboxPermissions){
                                            enabled = (this.props.checkboxPermissions[header.field] == 2);
                                        }
                                        return (
                                            <div key={index} style={style} className={className} title={title}>
                                                <Checkbox label={header.field} index={this.props.index} values={{value:this.props.content[header.field],enabled:enabled}} onChange={this.onMutatedChange}/>
                                            </div>
                                        )
                                    }

                                    if(header.type == "boolToInOut"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{(content == "1" || content) ? "In" : "Out"}</div>
                                        )
                                    }

                                    if(header.type == "boolToYesNo"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{(content == "1" || content) ? "Yes" : "No"}</div>
                                        )
                                    }

                                    if(header.type == "map"){
                                        const label =(content) ? u.getLabelFromValue(content,header.typeMap) : nullText;
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{label}</div>
                                        )
                                    }

                                    if(header.type == "object"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{
                                                    (this.props.content[header.field][header.typeReference])
                                                        ?
                                                        this.props.content[header.field][header.typeReference]
                                                        :
                                                        nullText
                                            }
                                            </div>
                                        )
                                    }

                                    if(header.type == "array"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{
                                                (this.props.content[header.field])
                                                    ?
                                                    this.props.content[header.field].map(
                                                        (item,index)=>{
                                                            return (
                                                                <span key={index}>{((index==0) ? "" :" | ") + item}</span>
                                                            )
                                                        }
                                                    )
                                                    :
                                                    nullText
                                        }
                                        </div>
                                        )
                                    }

                                    if(header.type == "object-array"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{
                                                (this.props.content[header.field])
                                                    ?
                                                    this.props.content[header.field].map(
                                                        (item,index)=>{
                                                            return (
                                                                <span key={index}>{((index==0) ? "" :" | ") + item[header.typeReference]}</span>
                                                            )
                                                        }
                                                    )
                                                    :
                                                    nullText
                                            }
                                            </div>
                                        )
                                    }

                                    if(header.type == "dayOfWeek"){
                                        return (
                                            <div key={index} style={style} className={className} title={title}>{
                                                (this.props.content[header.field])
                                                    ?
                                                    u.getDowLabel(this.props.content[header.field])
                                                    :
                                                    nullText
                                            }
                                            </div>
                                        )
                                    }

                                    return (
                                        <div key={index} style={style} className={className} title={title}>
                                            {
                                                (this.props.content[header.field] && (this.props.content[header.field]+"").trim().length > 0)
                                                    ?
                                                    this.props.content[header.field]
                                                    :
                                                    nullText
                                            }
                                        </div>
                                    )
                                }
                            )
                        }
                    </div>
                </div>
                <div className={"overview-item-details " + this.props.label + ((this.state.active) ? " active"  : "")}>{this.props.children}</div>
            </div>
        )
    }
}
SearchListItem.propTypes={
    label:PropTypes.string.isRequired,
    index:PropTypes.string.isRequired,
    pageIndexCounter:PropTypes.number.isRequired,
    selected:PropTypes.bool.isRequired,
    onSelected:PropTypes.func.isRequired,
    headers:PropTypes.array.isRequired,
    content:PropTypes.object.isRequired,
    onChange:PropTypes.func.isRequired,
    disableChild:PropTypes.bool,
    /*********** To Render History Like Pages ****************/
    timeMode:PropTypes.bool,
    timeModeField:PropTypes.string,
    timeModeDateField:PropTypes.string,
    /*** For changes that must go through child element ***/
    registerReference:PropTypes.func,
    onMutatedChange:PropTypes.func,
};


class SearchBar extends React.Component{

    state={
        type:"normal",
        value:"",
        funcTag:"",
        promptValue:"",
        columnSuggest:[],
        addTag:false
    };

    id=u.getKey();

    promptPlaceholder={
      "date":"DD-MM-YYYY",
      "short-date":"DD-MM",
      "year":"YYYY",
      "time":"HH:MM",
    };

    skipBlurring=false;

    componentDidMount=(e)=>{
        document.onkeydown=(e)=>{
            if(this.state.type != "normal") {
                if (e.key == "Escape" || e.key == "Esc" || e.key == "Backspace") {
                    this.onRevokePrompt(e.key);
                }
            }
        };
    }

    componentDidUpdate=(e)=>{
        if(this.state.type != "normal") {
            const input = document.getElementById(this.props.label);
            if (input) {
                input.focus();
                const value = input.value;
                input.value = "";
                input.value = value;
            }
            if(this.state.type == "transition"){
                this.setState({type:"normal"});

                if(this.state.typeExtra == "select"){
                    this.refs['tagInput'].focus();
                    this.setState({typeExtra:"undefined"});
                }
            }
        }

        if(this.state.addTag){
            this.onAddTag();
        }
    }

    onSearchChange=(value,label,index,method)=>{
        if(value.indexOf("@") == 0|| value.indexOf(":") == 0 || value.indexOf("+") == 0){
            const endTagIndex = value.indexOf("(");
            if(endTagIndex != -1){
                //Check through the suggestion list
                for(var i=0; i<this.props.suggest.length; i++){
                    if(value.substring(0,endTagIndex+1) == this.props.suggest[i].substring(0,endTagIndex+1)){

                        //Matching Functional Tag Found, identify expected value
                        const match = this.props.suggest[i];
                        const matchStart = match.indexOf("(");
                        const matchEnd = match.lastIndexOf(")");
                        const type = match.substr(0,1);
                        const tag = match.substr(1,matchStart-1);
                        const parameter = match.substring(matchStart+1,matchEnd);

                        //Modify value to remove additional parameters
                        const moddedValue = type + tag + "(";
                        const columnSuggest = (this.props.columnSuggest[tag]) ? this.props.columnSuggest[tag] : [];

                        const suggestType = (
                          (parameter == "DD-MM-YYYY")
                            ?
                            "date"
                            :
                            (
                              (parameter == "DD-MM")
                              ?
                              "short-date"
                              :
                              (
                                (parameter == "YYYY")
                                ?
                                "year"
                                :
                                (
                                  (parameter == "HH:MM")
                                  ?
                                  "time"
                                  :
                                  "prompt"
                                )
                              )
                            )
                          );

                        this.setState({
                            value:moddedValue,
                            funcTag:type+tag,
                            type:suggestType,
                            columnSuggest:columnSuggest
                        });

                        if(this.props.onSearchChange) {
                            this.props.onSearchChange(moddedValue);
                        }

                        return 0;
                    }
                }
            }
            else{
                if(method == "click"){
                    this.setState({addTag:true});
                }
            }
        }

        if(method == "click" && value.indexOf("#") != -1){
            this.setState({addTag:true});
        }

        this.setState({value:value});
        if(this.props.onSearchChange) {
            this.props.onSearchChange(value);
        }
    }

    onPromptChange=(value,label,index,method)=>{
        if(this.state.type == "date" && value.length > this.state.promptValue.length){
            if(value.length > 3){
                if(value.charAt(value.length-1) == "-" && value.charAt(value.length-2) == "-"){
                    return 0;
                }
            }
            if(value.length == 2){
                if(!isNaN(parseInt(value.substr(0,2)))){
                    value = value + "-";
                }
            }
            else if(value.length == 5){
                if(!isNaN(parseInt(value.substr(0,2))) && !isNaN(parseInt(value.substr(3,2))) && value.charAt(2) == "-"){
                    value = value + "-";
                }
            }
        }

        if(this.state.type == "short-date" && value.length > this.state.promptValue.length){
          if(value.length > 3){
              if(value.charAt(value.length-1) == "-" && value.charAt(value.length-2) == "-"){
                  return 0;
              }
          }
          if(value.length == 2){
              if(!isNaN(parseInt(value.substr(0,2)))){
                  value = value + "-";
              }
          }
        }

        if(this.state.type == "time" && value.length > this.state.promptValue.length){
            if(value.length > 3){
                if(value.charAt(value.length-1) == ":" && value.charAt(value.length-2) == ":"){
                    return 0;
                }
            }
            if(value.length == 2){
                if(!isNaN(parseInt(value.substr(0,2)))){
                    value = value + ":";
                }
            }
        }

        this.setState({promptValue:value,addTag:(method=="click")});
    }

    onRevokePrompt=(key)=>{
        if(this.state.promptValue.length == 0 && this.state.type != "normal"){
            if(key=="Escape" || key=="Esc") {
                this.setState({
                    value: this.state.value.substr(0, this.state.value.length - 1),
                    type: "transition",
                    funcTag: ""
                });
            }
            else if(key == "Backspace"){
                this.setState({type:"transition",funcTag:""});
            }
        }
    }

    onAddTag=()=>{
        let newTag = (this.state.type == "normal") ? this.state.value.trim() : this.state.promptValue.trim();
        if(newTag.length == 0){
            return 0;
        }

        if(this.state.type != "normal"){
            newTag = this.state.funcTag + "(" + newTag + ")";
        }

        this.setState({addTag:false});

        this.refs['tagInput'].closeList();

        this.props.onAddTag(newTag);
    }

    onClickAdd=(tag)=>{
        const match = tag;
        const matchStart = match.indexOf("(");
        const matchEnd = match.lastIndexOf(")");
        const type = match.substr(0,1);

        if(type == "@" || type == "+" || type == ":" && matchStart != "-1"){
            const value = type + match.substr(1,matchStart);
            const funcTag = match.substr(0,matchStart);
            const parameter = match.substring(matchStart+1,matchEnd);

            const promptType = (parameter.charAt(2) == "-" && parameter.charAt(5) == "-")
                                ?
                                "date"
                                :
                                (
                                  (parameter.charAt(2) == "-" && parameter.length == 5)
                                  ?
                                  "short-date"
                                  :
                                  (
                                    (parameter.charAt(2) == ":")
                                    ?
                                    "time"
                                    :
                                    "prompt"
                                  )
                                );

            const t= tag.substring(1,matchStart);
            const columnSuggest = (this.props.columnSuggest[t]) ? this.props.columnSuggest[t] : [];
            this.setState({type:promptType,value:value,promptValue:"",funcTag:funcTag,columnSuggest: columnSuggest});
        }
        else{
            this.setState({type:"transition",value:":",typeExtra:"select"});
        }
    }

    triggerAddTag=(e)=>{
        this.onAddTag(e);
    }

    clear=()=>{
        if(this.refs['tagInput']) {
            this.refs['tagInput'].clear();
        }
        this.state.value="";
        this.state.promptValue="";
        this.setState({funcTag:"",promptValue:"",type:"transition",value:""});
    }

    onBlur=()=>{
        if(this.props.ignoreBlur){
            return 0;
        }

        this.setState({
            value: this.state.value.substr(0, this.state.value.length - 1),
            type: "normal",
            funcTag: ""
        });
    }

    render=()=>{
        const operator = this.state.funcTag.substr(0,1);
        const tagType = (operator == ":") ? "filter" : ((operator == "@") ? "and" : ((operator=="+") ? "or" : "common"));
        const placeholder = (this.state.type == "prompt") ? "" : this.promptPlaceholder[this.state.type];//(this.state.type=="date") ? "DD-MM-YYYY" : ((this.state.type == "time") ? "HH:MM" : "");

        return (
            <div style={{position:"relative"}}>
                {
                    (this.state.type == "normal" || this.state.type == "transition")
                        ?
                        <div className="search-container">
                            <div className="normal-search-container">
                                <AutoCompleteInput ref="tagInput" autoprompt={this.state.value.length > 0} onChange={this.onSearchChange} label={this.props.label}
                                                   value={this.state.value} suggest={this.props.suggest} listHeight={this.props.listHeight}
                                                   onEnterKey={this.onAddTag}
                                />
                            </div>
                            <div className="search-go-container">
                                <img src="/images/list/list-go.png" className="search-go" title="Add Tag" onClick={this.onAddTag}/>
                            </div>
                        </div>
                        :
                        <div className="search-container">
                            <div className="functional-search-container">
                                <div className={"tag-function-text tag-text-"+tagType}>{this.state.funcTag}</div>
                                <div className="search-bar-container">
                                    <div className={"tag-bracket-left tag-text-" + tagType}>(</div>
                                    <AutoCompleteInput id={this.id} ref="tagInput" onChange={this.onPromptChange} alwaysRender={true} label={this.props.label}
                                                       value={this.state.promptValue} suggest={this.state.columnSuggest} placeholder={placeholder}
                                                       autoprompt={true} listHeight={this.props.listHeight}
                                                       onEnterKey={this.onAddTag}
                                    />
                                    <div className={"tag-bracket-right tag-text-" + tagType}>)</div>
                                </div>

                            </div>
                            <div className="search-go-container">
                                <img src="/images/list/list-go.png" className="search-go" title="Add Tag" onClick={this.onAddTag}/>
                            </div>
                        </div>
                }
            </div>
        )
    }
}
SearchBar.propTypes={
    loading:PropTypes.bool.isRequired,
    label:PropTypes.string.isRequired,
    suggest:PropTypes.array.isRequired,
    onAddTag:PropTypes.func.isRequired,
    onSearchChange:PropTypes.func,
    ignoreBlur:PropTypes.bool
};


class ColumnSettings extends React.Component{

    state={
        loading:false,
        error:"",
        optionKeyPadding:moment().format("HH:mm:sss"),
        changedColumnSettings:false,
    };

    onChangeColumnSetting=(value,label,index)=>{
        let settings = this.props.headers;
        let newValue = (label=="position") ? parseInt(value) : value;

        if(label == "width"){
            if(parseInt(value) > 100){
                newValue = "100";
            }
            else if(parseInt(value) < 1){
                newValue = "1";
            }
        }

        const newSetting = Object.assign({},settings[index]);

        if(newSetting[label] + "" == newValue + ""){
            return 0;
        }

        newSetting[label] = newValue;
        settings[index] = newSetting;
        this.props.onColumnSettingChange(settings);

        this.setState({changedColumnSettings:true});
    }

    onApplyColumnSettings=()=>{
        const {settings}=this.props;
        u.post({
            url:(settings.listSettingsURL) ? settings.listSettingsURL : "/api/apply-list-user-settings",
            data:{
                listLabel:this.props.label,
                type:"columnSettings",
                settings:this.props.headers
            },
            success:(callback)=>{
                this.props.onColumnSettingChange(null ,true);
                const columnSettings = store.get("columnSettings");
                columnSettings[this.props.label] = this.props.headers;
                store.set("columnSettings",columnSettings);
                this.setState({showColumnSettings:false,changedColumnSettings:false,error:""});
            },
            error:(error,status)=>{
                this.setState({error:error});
            }
        });
    }

    onCancelColumnSettings=()=>{
        this.props.onColumnSettingChange(null);
        this.setState({showColumnSettings:false,changedColumnSettings:false});
    }

    onColumnChangePosition=(e)=>{
        const id = e.currentTarget.id.split("-");
        const direction = id[0];
        const index = parseInt(id[1]);

        let settings = this.props.headers;

        if(direction == "up"){
            if(index == 0){
                return 0;
            }
            //position swap with position-1
            let currentPosition = Object.assign({},settings[index]);
            currentPosition['position'] = currentPosition['position']-1;
            let prevPosition = Object.assign({},settings[index-1]);
            prevPosition['position'] = prevPosition['position']+1;

            settings[index] = prevPosition;
            settings[index-1] = currentPosition;
        }

        else if (direction == "down"){
            if(index + 1 >= this.props.headers.length){
                return 0;
            }
            //postion swap with position+1
            let currentPosition = Object.assign({},settings[index]);
            currentPosition['position'] = currentPosition['position']+1;
            let prevPosition = Object.assign({},settings[index+1]);
            prevPosition['position'] = prevPosition['position']-1;

            settings[index] = prevPosition;
            settings[index+1] = currentPosition;
        }

        this.props.onColumnSettingChange(settings);
        this.setState({changedColumnSettings:true});
    }

    onDragStart=(event,index)=>{
        event.dataTransfer.setData("text",index+"");
        event.currentTarget.id = "drag";
        this.dragIndex=index;
    }

    onDrop=(event,index)=>{
        const dragTarget = event.dataTransfer.getData("text");
        const dropTarget = index;

        event.currentTarget.id = "none";

        if(dragTarget != dropTarget){
          let settings = this.props.headers;
          //sort
          const entry = settings[dragTarget];
          settings.splice(dragTarget,1);
          settings.splice(((dropTarget > dragTarget) ? dropTarget - 1 : dropTarget),0,entry);

          for(var i=0; i<settings.length; i++){
              settings[i].position = i+1;
          }

          this.props.onColumnSettingChange(settings);
          this.setState({changedColumnSettings:true});
        }
    }

    onDragHover=(event,index,dragover)=>{
        event.stopPropagation();
        event.preventDefault();
        if(index == this.dragIndex){
            return 0;
        }
        if(dragover){
            event.currentTarget.id = "hover";
        }
        else{
            event.currentTarget.id = "none";
        }
    }

    onDragEnd=(event,index)=>{
        const drag = document.getElementById("drag");
        if(drag){
          drag.id = "none";
        }
    }

    render=()=>{
      return (
         <Segment active={this.props.active} align="center" margin="20px 0" scrollAnchor={this.props.scrollAnchor}>
             <div className="column-settings-container">
                 <h3 className="list-setting-title">Column Settings</h3>
                 <div className="option-error">{this.state.error}</div>
                 <div className="column-setting-item header">
                     <div className="item-position mobile-hide">Position</div>
                     <div className="item-field input">Field</div>
                     <div className="item-selected">Show</div>
                     <div className="item-wrap"><span className="mobile-hide">Word </span>Wrap</div>
                     <div className="item-width"><span className="mobile-hide">Column </span>Width</div>
                     <div className="item-align"><span className="mobile-hide">Text </span>Align<span className="mobile-hide">ment</span></div>
                 </div>
                 {
                     this.props.headers.map(
                         (header,index)=>{
                             return (
                                 <div key={index}
                                      draggable={true}
                                      onDragEnd={(event)=>{this.onDragEnd(event,index)}}
                                      onDragStart={(event)=>{this.onDragStart(event,index)}}
                                      onDrop={(event)=>{this.onDrop(event,index)}}
                                      onDragOver={(event)=>{this.onDragHover(event,index,true)}}
                                      onDragLeave={(event)=>{this.onDragHover(event,index,false)}}
                                      className="column-setting-item input">

                                       <div className="no-ev item-position mobile-hide">
                                           <img src="/images/list/list-sort.png" className="item-position-input inverted" id={"up-"+index} onClick={this.onColumnChangePosition}/>
                                           <img src="/images/list/list-sort.png" className="item-position-input" id={"down-"+index} onClick={this.onColumnChangePosition}/>
                                       </div>
                                       <div className="no-ev item-field input">{header.label}</div>
                                       <div className="no-ev item-selected"><Checkbox index={index+""} label="selected" onChange={this.onChangeColumnSetting} values={{value:header.selected}}/></div>
                                       <div className="no-ev item-wrap"><Checkbox index={index+""} label="wrap" onChange={this.onChangeColumnSetting} values={{value:header.wrap,enabled:header.selected}}/></div>
                                       <div className="no-ev item-width">
                                           <div style={{position:"relative",maxWidth:"45px"}}>
                                           <Box index={index+""} boxStyle={{paddingRight:"10px"}} label="width" onChange={this.onChangeColumnSetting} type="number" values={{value:header.width,enabled:header.selected}}/>
                                           <div className="item-width-quantifier">%</div>
                                           </div>
                                       </div>
                                       <div className="no-ev item-align">
                                            <Select index={index+""} label="align" style={{maxWidth:"80px"}} onChange={this.onChangeColumnSetting} values={{value:header.align,enabled:header.selected,options:[{value:"left",label:"Left"}, {value:"center",label:"Center"}, {value:"right",label:"Right"}]}}/>
                                       </div>
                                 </div>
                             )
                         }
                     )
                 }
                 {
                     (this.state.changedColumnSettings)
                         ?
                         <div className="segment-buttons">
                             <div className="change-settings-note">If you do not apply your new settings, they will be lost on the next update or refresh</div>
                             <Button type="medium" onClick={this.onCancelColumnSettings} margin="20px">Cancel</Button>
                             <Button type="medium" onClick={this.onApplyColumnSettings} margin="20px">Apply</Button>
                         </div>
                         :
                         <div className="segment-buttons"><Button type="medium" onClick={this.props.toggleColumnSettings} margin="20px auto">Back</Button></div>
                 }
             </div>
         </Segment>
       )
    }
}


class TagMacros extends React.Component{

    state={
        step:0,
        error:"",
        targetMacro:{
            macroName:"",
            tags:[],
        },
        tagSettings:[],
        prevSuggest:[],
        validateSuggest:[],
        suggest:[],
    }

    componentDidMount=()=>{
        const tagSettings = u.getTagMacros(this.props.label);
        this.setState({tagSettings:tagSettings});
    }

    static getDerivedStateFromProps=(props,state)=>{
        if(props.suggest != state.prevSuggest){
            let macros = u.getTagMacros(props.label);
            if(typeof macros == "undefined"){
               macros = [];
            }
            state.prevSuggest = props.suggest;
            state.promptParent = true;
            state.macros = macros;
        }

        return state;
    }

    componentDidUpdate=(prevProps,prevState)=>{
        if(this.state.promptParent){
            this.setState({promptParent:false});
            this.onUpdateSuggest(this.state.prevSuggest,this.state.macros);
        }
    }

    onUpdateSuggest=(suggest,macros)=>{
        let validateSuggest = suggest.slice(0);
        for(var t=0; t<suggest.length; t++){
            if(suggest[t].substr(0,1) == "@"){
                const newTag = "+" + suggest[t].substr(1);
                validateSuggest.push(newTag);
            }
        }

        let macroSuggest = validateSuggest.slice(0);
        for(var i=0; i<macros.length; i++){
            macroSuggest.push("#"+macros[i].macro);
        }

        this.setState({suggest:macroSuggest,validateSuggest:validateSuggest});
    }

    onRemoveMacroTag=(tagIndex,macroIndex)=>{
        this.setState({error:""});
        let tags = this.state.tagSettings.slice(0);
        tags[parseInt(macroIndex)].tags.splice(parseInt(tagIndex),1);
        this.onChangeTagSettings(tags);
    }

    onRemoveAddMacroTag=(index)=>{
        this.setState({error:""});
        let targetMacro = this.state.targetMacro;
        targetMacro.tags.splice(index,1);
        this.setState({targetMacro:targetMacro});
    }

    onChangeTagName=(value,label,index)=>{
        let tags = this.state.tagSettings.slice(0);
        tags[parseInt(index)].macro = value;
        this.setState({error: "",tagSettings:tags});

        //Update local store
        let tagMacros = store.get("tagMacros");
        tagMacros[this.props.label] = tags;
        store.set('tagMacros',tagMacros);
        this.props.onUpdateSuggest(this.props.suggest,tags);
    }

    onChangeTagSettings=(tags)=>{
        const {settings} = this.props;
        u.post({
            url:(settings.listSettingsURL) ? settings.listSettingsURL : "/api/apply-list-user-settings",
            data:{
                listLabel:this.props.label,
                type:"tagSettings",
                settings:tags
            },
            success:(callback)=>{
                //Update local store
                let tagMacros = store.get("tagMacros");
                tagMacros[this.props.label] = tags;
                store.set('tagMacros',tagMacros);
                this.props.onUpdateSuggest(this.props.suggest,tags);
                this.setState({tagSettings:tags});
            },
            error:(error,status)=>{
                this.setState({error:error});
            }
        });
    }

    onAddMacroTag=(newTag)=>{
        this.setState({error:""});
        let targetMacro = this.state.targetMacro;
        const operator = newTag.substr(0,1);

        let result = (operator == ":" || operator == "@" || operator == "+") ? SearchList.validateTag(targetMacro.tags,newTag,this.state.validateSuggest) : ((operator == "#") ? {valid:false,value:"# tags are not allowed in macros"} : {valid:true,value:"None"});
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }

        //Check for remove
        if(result.value != "None"){
            targetMacro.tags.splice(parseInt(result.value),1);
        }
        targetMacro.tags.push(newTag);
        this.setState({targetMacro:targetMacro});
        this.refs['addMacroTagInput'].clear();
    }

    onAddNewMacro=()=>{
        this.setState({error:""});
        let newMacroTags = this.state.targetMacro.tags;
        let newMacroName = this.refs['addMacroName'].validate();

        if(!newMacroName.valid){
            this.setState({error:newMacroName.value});
            return 0;
        }

        const uniqueName = this.checkMacroName(newMacroName.value);
        if(!uniqueName.valid){
            this.setState({error:uniqueName.value});
            return 0;
        }

        if(newMacroTags.length == 0){
            this.setState({error: "Please enter at least 1 search tag to continue. Use the green tick to add tag"});
            return 0;
        }

        let tagSettings = this.state.tagSettings.slice(0);
        tagSettings.push({
            macro:newMacroName.value,
            tags:newMacroTags
        });

        this.refs['addMacroTagInput'].clear();
        this.refs['addMacroName'].clear("");

        this.setState({targetMacro:{macroName:"",tags:[]}, step:0});
        this.onChangeTagSettings(tagSettings);
    }

    checkMacroName=(newName)=>{
        for(var i=0; i<this.state.tagSettings.length; i++){
            if(this.state.tagSettings[i].macro.toLowerCase() == newName.toLowerCase()){
                return {valid:false,value:"Macro " + newName + " already exists"};
            }
        }
        return {valid:true};
    }

    onRemoveMacro=(macroIndex)=>{
        this.setState({error:""});
        let tags = this.state.tagSettings.slice(0);
        tags.splice(macroIndex,1);
        this.onChangeTagSettings(tags);
    }

    onGoToMacroAdd=()=>{
        this.setState({step:1,error:"",targetMacro:{
            macroName:"",
            tags:[],
        }});
    }

    onGoToEditMacro=(index)=>{
        let targetMacro = {
            index:index,
            macroName:this.state.tagSettings[index].macro,
            tags:this.state.tagSettings[index].tags.slice(0)
        };
        this.setState({step:2,error:"",targetMacro:targetMacro});
    }

    onApplyMacroEdit=()=>{
        let newMacro = this.state.targetMacro;
        let tags = this.state.tagSettings.slice(0);
        tags[newMacro.index] = {
            macro:newMacro.macroName,
            tags:newMacro.tags
        };

        this.onChangeTagSettings(tags);
        this.refs['addMacroTagInput'].clear();
        this.setState({targetMacro:{macroName:"",tags:[]},step:0});
    }

    render=()=>{
      const {settings} = this.props;
      return(
          <Segment active={this.props.active} align="center" margin="20px 0" scrollAnchor={this.props.scrollAnchor}>
              <div className="tag-settings-container">
                  <Step active={(this.state.step == 0)}>
                      <div>
                          <h3 className="list-setting-title">Tag Macros</h3>
                          <div className="option-error">{this.state.error}</div>
                          {
                            (this.state.tagSettings)
                              ?
                              <div>
                                  <div className="tag-settings-item header">
                                      <div className="tag-settings-remove"/>
                                      <div className="tag-settings-name">Name</div>
                                      <div className="tag-settings-tags">Tags</div>
                                      <div className="tag-settings-add"/>
                                  </div>
                                  {
                                      this.state.tagSettings.map(
                                          (macro,index)=>{
                                              return (
                                                  <div className="tag-settings-item" key={index}>
                                                      <div className="tag-settings-remove">
                                                          {
                                                              (macro.macro != "default")
                                                                  ?
                                                                  <div className="tag-settings-remove-button">
                                                                      <MiniMinusButton index={index+""} onClick={this.onRemoveMacro}/>
                                                                  </div>
                                                                  :
                                                                  ""
                                                          }
                                                      </div>
                                                      <div className="tag-settings-name">
                                                          <div className="tag-settings-name-mod">#</div>
                                                          <div className="tag-settings-name-input">
                                                              <EditableBox label="tag-setting-name"
                                                                           index={index+""}
                                                                           values={{
                                                                               value:macro.macro, enabled:(macro.macro != "default")
                                                                           }}
                                                                           rules={{
                                                                               additionalData:this.props.label,
                                                                               validate:this.checkMacroName,
                                                                               required:true,noSpecialCharacters:true,noSpaces:true
                                                                           }}
                                                                           onChange={this.onChangeTagName} 
                                                                           url={(settings.macroURL) ? settings.macroURL : "/api/update-user-tag-macro"}
                                                                           id={macro.macro} 
                                                                           field="name"
                                                                           />
                                                          </div>
                                                      </div>
                                                      <div className="tag-settings-tags">
                                                          {
                                                              macro.tags.map(
                                                                  (tag,tIndex)=>{
                                                                      return (
                                                                          <Tag key={tIndex} index={tIndex+""} clickable={false} reference={index+""} label={tag} onRemove={this.onRemoveMacroTag}/>
                                                                      )
                                                                  }
                                                              )
                                                          }
                                                      </div>
                                                      <div className="tag-settings-add">
                                                          <div className="tag-settings-add-button">
                                                              <MiniPlusButton index={index+""} onClick={this.onGoToEditMacro}/>
                                                          </div>
                                                      </div>
                                                  </div>
                                              )
                                          }
                                      )
                                  }
                              </div>
                              :
                              <div className="tag-settings-placeholder">
                                  No Tag Macros
                              </div>
                         }
                          <div className="segment-buttons">
                              <Button type="medium" onClick={this.props.toggleTagSettings}>Cancel</Button>
                              <Button type="medium" onClick={this.onGoToMacroAdd}>New</Button>
                          </div>
                      </div>
                  </Step>

                  <Step active={(this.state.step == 1)}>
                      <div>
                          <h3 className="list-setting-title">Add New Macro</h3>
                          <div className="option-error">{this.state.error}</div>
                          <div className="segment-full-item">
                              <div className="segment-item-label">Macro Name:</div>
                              <div className="segment-item-input" style={{maxWidth:"350px"}}>
                                  <Box ref="addMacroName" label="Macro Name" rules={{required:true,noSpecialCharacters:true,noSpaces:true}}/>
                              </div>
                          </div>
                          <div className="segment-full-item" style={{zIndex:2}}>
                              <div className="segment-item-label" style={{verticalAlign:"top"}}>Add Tag:</div>
                              <div className="segment-item-input" style={{position:"relative",width:"350px",maxWidth:"70%"}}>
                                  <SearchBar ref="addMacroTagInput" label="add-macro-tag-auto-suggest" loading={false} suggest={this.state.validateSuggest} ignoreBlur={true} columnSuggest={this.props.columnSuggest} onAddTag={this.onAddMacroTag} listHeight="150px"/>
                              </div>
                          </div>
                          <div className="segment-full-item" style={{zIndex:1}}>
                              <div className="segment-item-label" style={{verticalAlign:"top"}}>Current Tags:</div>
                              <div className="segment-item-input" style={{verticalAlign:"top",maxWidth:"350px"}}>
                                  {
                                      (this.state.targetMacro.tags.length > 0)
                                          ?
                                          this.state.targetMacro.tags.map(
                                              (tag, index)=> {
                                                  return (
                                                      <Tag key={index} index={index + ""} clickable={false} label={tag} onRemove={this.onRemoveAddMacroTag}/>
                                                  )
                                              }
                                          )
                                          :
                                          <div style={{padding:"6px 0"}}>None</div>
                                  }
                              </div>
                          </div>
                          <div className="segment-buttons">
                              <Button type="medium" onClick={()=>{this.setState({step:0,error:""})}}>Back</Button>
                              <Button type="medium" onClick={this.onAddNewMacro}>Add</Button>
                          </div>
                      </div>
                  </Step>

                  <Step active={(this.state.step == 2)}>
                      <div>
                          <h3 className="list-setting-title">Edit Macro</h3>
                          <div className="option-error">{this.state.error}</div>
                          <div>
                              <div className="segment-full-item">
                                  <div className="segment-item-label">Macro Name:</div>
                                  <div className="segment-item-input" style={{padding:"6px 0",maxWidth:"350px"}}>{this.state.targetMacro.macroName}</div>
                              </div>
                              <div className="segment-full-item" style={{zIndex:2,margin:"30px auto"}}>
                                  <div className="segment-item-label">Add Tag:</div>
                                  <div className="segment-item-input" style={{position:"relative",width:"350px",maxWidth:"70%"}}>
                                      <SearchBar ref="addMacroTagInput" label="add-macro-tag-auto-suggest" loading={false} suggest={this.state.validateSuggest} ignoreBlur={true} columnSuggest={this.props.columnSuggest} onAddTag={this.onAddMacroTag}/>
                                  </div>
                              </div>
                              <div className="segment-full-item" style={{zIndex:1,margin:"30px auto"}}>
                                  <div className="segment-item-label" style={{verticalAlign:"top"}}>Current Tags:</div>
                                  <div className="segment-item-input" style={{verticalAlign:"top",maxWidth:"350px"}}>
                                      {
                                          (this.state.targetMacro.tags.length > 0)
                                              ?
                                              this.state.targetMacro.tags.map(
                                                  (tag, index)=> {
                                                      return (
                                                          <Tag key={index} index={index + ""} clickable={false} label={tag} onRemove={this.onRemoveAddMacroTag}/>
                                                      )
                                                  }
                                              )
                                              :
                                              <div style={{margin:"5px 0"}}>None</div>
                                      }
                                  </div>
                              </div>
                              <div className="segment-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:0,error:""})}}>Cancel</Button>
                                  <Button type="medium" onClick={this.onApplyMacroEdit}>Apply</Button>
                              </div>
                          </div>
                      </div>
                  </Step>
              </div>
          </Segment>
        )
    }
}


/**
 * Creates a themed auto complete input box
 *
 * @param {array} values
 *      text array of text suggestions
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @function getData()
 *
 * @function clear ()
 */
class AutoCompleteInput extends React.Component{

    state = {
        value:'',
    };

    onChange=(newValue,label,index,method)=>{
        this.setState({
            value: newValue
        });

        if(this.props.onChange){
            this.props.onChange(newValue,label,index,method);
        }
    };

    getData=()=>{
        return this.state.value;
    }

    clear=()=>{
        this.setState({value:""});
    }

    onBlur=()=>{
        if(this.props.onBlur){
            this.props.onBlur();
        }
    }

    focus=()=>{
        this.refs['input'].focus();
    }

    closeList=()=>{
        this.refs['input'].closeList();
    }

    render=()=>{
        return(
            <div className="auto-complete">
                <Box ref="input" autoFocus={this.props.autoprompt} values={{value:(typeof this.props.value != "undefined") ? this.props.value : this.state.value}}
                     onChange={this.onChange} label={this.props.label} onBlur={this.onBlur} rules={{list:this.props.suggest,placeholder:this.props.placeholder,listHeight:this.props.listHeight}}
                     onEnterKey={this.props.onEnterKey}
                     />
            </div>
        );
    }
}
AutoCompleteInput.propTypes={
    index:PropTypes.string,
    label:PropTypes.string.isRequired,
    suggest:PropTypes.array.isRequired,
    autoprompt:PropTypes.bool,
};
AutoCompleteInput.defaultProps={
    index:"0"
};


/**
 * Creates a tag commonly utilized for the search list component
 *
 * @param {string} index
 *      reference to object
 *
 * @param {string } label
 *      text label that is rendered with label
 *
 * @param {string} reference
 *      additional parameter returned during onRemove event
 *
 * @param {object} values
 *
 *      @param {bool} removable
 *
 *      @param {bool} special
 *          swaps background color of the tag
 *
 * @param {function} onRemove (index,reference)
 *      Event called when a tag is removed
 *
 */
export class Tag extends React.Component{

    state={
        label:this.props.label,
        removable:(this.props.values) ? ((typeof this.props.values.removable != "undefined" ) ? this.props.values.removable : true) : true
    };

    handleRemove=(e)=>{
        e.preventDefault();
        if(this.props.onRemove){
            this.props.onRemove(this.props.index,this.props.reference);
        }
    }

    onClick=(e)=>{
        if(this.props.clickable && this.props.onClick) {
            this.props.onClick(this.props.label);
        }
    }

    render=()=>{
        const operator = this.props.label.substr(0,1);
        const tagType = (operator == ":") ? "filter" : ((operator == "@") ? "and" : ((operator=="+") ? "or" : "common"));
        const clickable = (this.props.clickable && (tagType != "normal"));

        return (
            <div className="tag">
                <div className={"tag-label " + tagType + ((clickable) ? " clickable" : "")} onClick={this.onClick}>
                    {this.props.label}
                </div>
                {
                    (this.state.removable)
                        ?
                        <span className="tag-remove" onClick={this.handleRemove}>
                            <span></span>
                        </span>
                        :
                        ""
                }
            </div>
        );
    }
}
Tag.propTypes={
    index:PropTypes.string,
    label:PropTypes.string.isRequired,
    reference:PropTypes.string,
    clickable:PropTypes.bool.isRequired,
};
Tag.defaultProps={
    index:"0"
};


/**
 * Creates a themed search list which is connected to the database
 *
 * @param {string} url
 *      API call url to get content of this list
 *
 * @param {string} label
 *      label should be the name of the list, update the api call to accept this list setting change
 *
 * @param {number} numRows
 *      number of entries to render per page
 *
 * @param {array} tags
 *      default tags that are in this search list
 *
 * @param {array} suggest
 *      all acceptable functional search parameters
 *
 * @param {string} orderByField
 *      default order by field
 *
 * @param {string} orderByType
 *      ascending or descending
 *
 */
export class MiniList extends React.Component{

    state={
        content:[],
        pageNumber:1,
        pageCount:1,
        orderByField:(this.props.orderByField) ? this.props.orderByField : this.props.settings.orderByField,
        orderByType:(this.props.orderByType) ? this.props.orderByType : this.props.settings.orderByType,
        loading:false,
        loadText:"",
        step:"view",
        selected:{
            all:true,
            data:[],
            tags:[],
        },
        dimensions:{width:0,height:0},
    };

    componentDidMount=(e)=>{
        const listLabel = this.props.settings.listLabel;
        const settings = u.getListSettings(listLabel);
        this.setState({
            tags:this.props.tags,
            columnSettings:settings.columnSettings.filter((item)=>{return item.selected}).map((item,index,arr)=>{
                item.miniListWidth = this.calculateWidth(item.width,arr.length,listLabel) + "px";
                return item;
            }),
        });

        this.getContent(this.props.tags,this.state.orderByField,this.state.orderByType);
    }

    getContent=(tags,orderByField,orderByType)=>{
        this.setState({loading:true});
        u.post({
            url:this.props.settings.overviewURL,
            data:{
                tags:this.props.tags,
                rows:0,
                pageNumber:1,
                orderByField:orderByField,
                orderByType:orderByType,
            },
            success:(e)=>{
                let pageContent = e.pageContent;
                console.log(e.pageContent);

                if(this.props.settings.timeMode){
                    let renderedDates=[];
                    for(var t=0; t<pageContent.length; t++){
                        const contentDate = moment(pageContent[t][this.props.settings.timeModeDateField]).format("YYYY-MM-DD");
                        if(renderedDates.indexOf(contentDate) == -1){
                            pageContent[t].showDate = true;
                            renderedDates.push(contentDate);
                        }
                    }
                }

                const numPages = Math.ceil(e.entryCount / this.props.pageLength);
                const currentPage = (this.state.pageNumber > numPages) ? numPages : this.state.pageNumber;
                const startIndex = (currentPage - 1) * this.props.pageLength;
                const endIndex = startIndex + this.props.pageLength - 1;

                this.setState({
                    content:pageContent,
                    entryCount:e.entryCount,
                    pageNumber:currentPage,
                    pageCount:numPages,
                    startIndex:startIndex,
                    endIndex:endIndex,
                });
                this.setState({loading:false});
            },
            error:(e)=>{
                this.setState({error:e,loading:false});
            }
        });
    }

    onChangePage=(newPage)=>{
        const numPages = this.state.pageCount;
        if(newPage < 1){
            return 0;
        }
        else if(newPage > numPages){
            return 0;
        }
        else{
            const startIndex = (newPage - 1) * this.props.pageLength;
            const endIndex = startIndex + this.props.pageLength - 1;
            this.setState({
                pageNumber:newPage,
                startIndex:startIndex,
                endIndex:endIndex,
            });
        }
    }

    onSkipToPage=(value)=>{
        const numPages = parseInt(this.state.pageCount);
        if(value > numPages){
            value = numPages;
        }
        else if (value < 1){
            value = 1;
        }
        const newPage = parseInt(value);
        const startIndex = (newPage - 1) * this.props.pageLength;
        const endIndex = startIndex + this.props.pageLength - 1;
        this.setState({
            pageNumber:newPage,
            startIndex:startIndex,
            endIndex:endIndex,
        });
    }

    onSetContext=(listID)=>{
        if(this.props.settings.detailsLink){
            return 0;
        }
        store.set(this.props.settings.listLabel,"@id("+listID+")");
    }

    refresh=()=>{
        this.getContent(this.props.tags,this.state.orderByField,this.state.orderByType);
    }

    calculateWidth=(percentage,visibleArrayLength,listLabel)=>{
        const element = document.getElementById("mini-list-" + listLabel);
        const width = element.offsetWidth;

        const isMobile = (window.innerWidth <= 500);
        const linkrequired = isMobile ? 70 : 125;
        const linkspace = (this.props.noUpdate) ? 0 : (linkrequired/visibleArrayLength);

        return (width * (parseInt(percentage)/100.0)) - linkspace;
    }

    render=()=>{
        const columns = this.state.columnSettings;
        const selected = {all:true,data:[],tags:this.props.tags,entryCount:this.state.entryCount};

        return (
            <div className="mini-list" id={"mini-list-" + this.props.settings.listLabel}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="overview-list-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                  {
                      (this.state.content.length > 0)
                          ?
                          <div>
                              <div className="mini-list-table">
                                  <table>
                                      <thead>
                                          <tr>
                                              {
                                                  columns.map(
                                                      (column,c,arr)=>{
                                                          const style = {width:column.miniListWidth, whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis", textAlign:((column.align) ? column.align : "left")};
                                                          return (
                                                            <th key={c} style={style} className={(c == 0) ? "top-left" : null}>{column.label}</th>
                                                          )
                                                      }
                                                  )
                                              }
                                              {
                                                (this.props.noLink)
                                                    ?
                                                    null
                                                    :
                                                    <th className="top-right link-space" style={{textAlign:"center", whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>Link</th>
                                              }
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              this.state.content.map((item,index)=>{
                                                    if(index > this.state.endIndex || index < this.state.startIndex){
                                                        return null;
                                                    }
                                                    return (
                                                        <tr key={index} className="item">
                                                            {
                                                                columns.map(
                                                                    (column,c)=>{
                                                                        const content = item[column.field];
                                                                        const nullText=(column.nullText) ? column.nullText : "None";
                                                                        const style = {width:column.miniListWidth, whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis", textAlign:((column.align) ? column.align : "left")};

                                                                        const isAddition = (typeof column.field == "string") ? (column.field.indexOf("+") != -1) : false;
                                                                        if(isAddition){
                                                                            const addedFields = (isAddition) ? column.field.split("+") : [];
                                                                            let addedValue = 0;
                                                                            for(var i=0; i<addedFields.length; i++){
                                                                                addedValue += parseInt(item[addedFields[i]]);
                                                                            }
                                                                            return (
                                                                                <td key={c} style={style}>{addedValue}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "dateTime"){
                                                                            return (
                                                                                <td key={c} style={style}>{ ((content) ? moment(content).format("DD MMM YY - hh:mma") : nullText)}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "date"){
                                                                            return (
                                                                                <td key={c} style={style}>{((content) ? moment(content).format("DD MMM YY") : nullText)}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "time"){
                                                                            const moddedContent = (content.indexOf("T") != -1) ? content : "2020-01-01T" + content;
                                                                            return (
                                                                                <td key={c} style={style}>{((content) ? moment(moddedContent).format("hh:mma") : nullText)}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "boolToInOut"){
                                                                            return (
                                                                                <td key={c} style={style}>{(content == "1" || content) ? "In" : "Out"}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "boolToYesNo"){
                                                                            return (
                                                                                <td key={c} style={style}>{(content == "1" || content) ? "Yes" : "No"}</td>
                                                                            )
                                                                        }

                                                                        if(column.type == "object"){
                                                                            return (
                                                                                <td key={c} style={style}>
                                                                                {
                                                                                        (item[column.field][column.typeReference])
                                                                                            ?
                                                                                            item[column.field][column.typeReference]
                                                                                            :
                                                                                            nullText
                                                                                }
                                                                                </td>
                                                                            )
                                                                        }

                                                                        if(column.type == "array"){
                                                                            return (
                                                                                <td key={c} style={style}>{
                                                                                    (item[column.field])
                                                                                        ?
                                                                                        item[column.field].map(
                                                                                            (arrItem,index)=>{
                                                                                                return (
                                                                                                    <span key={index}>{((index==0) ? "" :" | ") + arrItem}</span>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                        :
                                                                                        nullText
                                                                                }
                                                                                </td>
                                                                            )
                                                                        }

                                                                        if(column.type == "object-array"){
                                                                            return (
                                                                                <td key={c} style={style}>{
                                                                                    (item[column.field])
                                                                                        ?
                                                                                        item[column.field].map(
                                                                                            (item,index)=>{
                                                                                                return (
                                                                                                    <span key={index}>{((index==0) ? "" :" | ") + item[column.typeReference]}</span>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                        :
                                                                                        nullText
                                                                            }
                                                                                </td>
                                                                            )
                                                                        }

                                                                        return (
                                                                            <td key={c} style={style}>
                                                                                {
                                                                                    (item[column.field] && (item[column.field]+"").trim().length > 0)
                                                                                        ?
                                                                                        item[column.field]
                                                                                        :
                                                                                        nullText
                                                                                }
                                                                            </td>
                                                                        )
                                                                    }
                                                                )
                                                            }
                                                            {
                                                                (this.props.noLink)
                                                                    ?
                                                                    null
                                                                    :
                                                                    <td className="link-space" style={{textAlign:"center", whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis"}}>
                                                                        <Link to={(this.props.settings.detailsLink) ? (this.props.settings.detailsLink + (item.listID || item.id)) : ("/" + this.props.settings.listLabel)} onClick={()=>{this.onSetContext(item.listID || item.id)}} onContextMenu={()=>{this.onSetContext(item.listID || item.id)}}>
                                                                            <span className="mobile-hide">See </span>More
                                                                        </Link>
                                                                    </td>
                                                            }
                                                        </tr>
                                                    )
                                                  }
                                              )
                                          }
                                      </tbody>
                                  </table>
                              </div>

                                {
                                  (this.state.pageCount > 1)
                                      ?
                                      <PageSelector className="mini-list"
                                                    page={this.state.pageNumber}
                                                    maxPage={this.state.pageCount}
                                                    onChange={this.onChangePage}
                                                    />
                                      :
                                      ""
                                }

                              <div style={{margin:"20px auto 0 auto",textAlign:"center"}}>
                                  <Button type="medium" onClick={()=>{this.setState({step:"download"})}}>Download</Button>
                              </div>
                          </div>
                          :
                          <div className="mini-list-placeholder">
                              <b>No Entries</b><br/>
                              Refresh page to reload list
                          </div>
                  }
                </Step>

                <Step active={this.state.step == "download"}>
                    <GenerateSpreadsheet active={true}
                                         reference="download"
                                         selected={selected}
                                         settings={this.props.settings}
                                         onToggleForm={()=>{this.setState({step:"view",error:""})}}
                                         miniList={true}
                                         segmentStyle={{border:"none",padding:"0"}}
                                         ignoreScroll={true}
                    />
                </Step>

            </div>
        )
    }
}
MiniList.propTypes={
    settings:PropTypes.object.isRequired,
    tags:PropTypes.array.isRequired,
    pageLength:PropTypes.number.isRequired,
    detailsLink:PropTypes.string,
};


/**
 * Creates a themed list segment item that supports generic setup of certain components
 *
 * @param {object} settings
 *      @var {string} url
 *
 *      @var {bool} enabled
 *
 *      @var {function} onChange
 *
 *      @var {object || string} id
 *
 * @param {object} content
 *      respective content object
 *
 * @param {object} fields
 *      @var {string} databoxType
 *          image,label,link,box,select,date,time,area
 *
 *      @var {string} label
 *
 *      @var {string} field
 *
 *      @var {string} value
 *
 *      @var {object} rules
 *
 *      @var {string} options
 *          Select only
 *
 *      @var {string} type
 *          Box only - defaults to text
 *
 *      @var {array} range
 *          Date only - defaults to [5,5]
 *
 * @param {object} validation
 *      See validation folder
 */
export class ListSegmentGeneral extends React.Component{

    render=()=>{
        const settings = this.props.settings;
        const content = this.props.content;
        const fields = this.props.fields;
        const validation = this.props.validation;
        const enabled = (typeof fields.enabled != "undefined") ? fields.enabled : settings.enabled;

        if(fields.databoxType == "image"){
            return <ListSegmentPhoto image={u.getAvatarLink(content[fields.field])}/>
        }

        return (
            <div className="list-segment-item">
                <div className="list-segment-label">{fields.label}:</div>
                <div className="list-segment-input">
                    {
                        (fields.databoxType == "label")
                            ?
                            <EditSizeLabel>{content[fields.field]}</EditSizeLabel>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "link")
                            ?
                            <EditSizeLink><Link to={fields.to}>{content[fields.field]}</Link></EditSizeLink>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "box")
                            ?
                            <EditableBox values={{value:content[fields.field],enabled:enabled}} type={fields.type} rules={validation[fields.field]} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "select")
                            ?
                            <EditableSelect values={{value:content[fields.field],options:fields.options,enabled:enabled}} rules={validation[fields.field]} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "date")
                            ?
                            <EditableDate values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={validation[fields.field]} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "time")
                            ?
                            <EditableTime values={{value:content[fields.field],enabled:enabled}} range={fields.range} rules={validation[fields.field]} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                    {
                        (fields.databoxType == "area")
                            ?
                            <EditableArea values={{value:content[fields.field],enabled:enabled}} rules={validation[fields.field]} field={fields.field} id={settings.id} label={fields.label} url={settings.url} onChange={settings.onChange}/>
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
}
ListSegmentGeneral.propTypes={
    settings:PropTypes.object.isRequired,
    fields:PropTypes.object.isRequired,
    content:PropTypes.object,
    validation:PropTypes.object,
};
ListSegmentGeneral.defaultProps={
    settings:{},
    content:{},
    validation:{},
    fields:{}
};


export class ListSegmentItem extends React.Component{
    render=()=>{
        return (
            <div className="list-segment-item">
                <div className="list-segment-label">{this.props.label}:</div>
                <div className="list-segment-input">{this.props.children}</div>
            </div>
        )
    }
}


export class ListSegmentPhoto extends React.Component{
    render(){
        return <img src={this.props.image} className="list-segment-image"/>
    }
}


export class AddSearchTag extends React.Component{

    state={
        tagSuggest:[],
        columnSuggest:[],
        validateSuggest:[],
    };

    componentDidMount=()=>{
        const tagSuggest = u.getTagSuggest(this.props.listLabel);
        let moddedTagSuggest = tagSuggest.slice(0);
        for(var t=0; t<tagSuggest.length; t++){
            if(tagSuggest[t].substr(0,1) == "@"){
                const newTag = "+" + tagSuggest[t].substr(1);
                moddedTagSuggest.push(newTag);
            }
        }
        this.setState({tagSuggest:moddedTagSuggest});
        this.getContent();
    }

    getContent=()=>{
        const {url}=this.props;
        u.post({
            url:(url) ? url : "/api/get-list-suggest",
            data:{
                listname:this.props.listLabel
            },
            success:(columnSuggest)=>{
                columnSuggest = (columnSuggest) ? columnSuggest : {};
                let moddedSuggest = {};
                for(var key in columnSuggest){
                    moddedSuggest[key] = columnSuggest[key].slice(0);
                }
                this.setState({
                    columnSuggest:moddedSuggest,
                });
            },
            error:(e)=>{
                this.setState({error:e});
            }
        });
    }

    onRemoveTag=(index)=>{
        let tags=this.props.tags;
        tags.splice(index,1);
        this.props.onChange(tags,this.props.listLabel,this.props.index);
    }

    onAddTag=(newTag)=>{
        this.setState({error:""});
        let tags = this.props.tags;
        const operator = newTag.substr(0,1);

        let result = (operator == ":" || operator == "@" || operator == "+") ? SearchList.validateTag(tags,newTag,this.state.tagSuggest) : ((operator == "#") ? {valid:false,value:"# tags are not allowed"} : {valid:true,value:"None"});
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }

        //Check for remove
        if(result.value != "None"){
            tags.splice(parseInt(result.value),1);
        }

        tags.push(newTag);
        this.props.onChange(tags);
        this.refs['input'].clear();
    }

    render=()=>{
        return (
            <div className={this.props.className} style={this.props.style}>
                <div className="page-error">{this.state.error}</div>
                <div className="segment-full-item" style={{...this.props.rowStyle,zIndex:2}}>
                    <div className="segment-item-label" style={this.props.labelStyle}>Add Tag:</div>
                    <div className="segment-item-input" style={this.props.inputStyle}>
                        <div className="search-list">
                            <SearchBar ref="input"
                                    label={this.props.listLabel}
                                    loading={false}
                                    suggest={this.state.tagSuggest}
                                    ignoreBlur={true}
                                    columnSuggest={this.state.columnSuggest}
                                    onAddTag={this.onAddTag}
                                    listHeight={(this.props.listHeight) ? this.props.listHeight : "150px"}/>
                        </div>
                    </div>
                </div>
                <div className="segment-full-item" style={{...this.props.rowStyle,zIndex:1}}>
                    <div className="segment-item-label" style={this.props.labelStyle}>Current Tags:</div>
                    <div className="segment-item-input" style={this.props.inputStyle}>
                        {
                            (this.props.tags.length > 0)
                                ?
                                this.props.tags.map(
                                    (tag, index)=> {
                                        return (
                                            <Tag key={index}
                                                 index={index + ""}
                                                 clickable={false}
                                                 label={tag}
                                                 onRemove={this.onRemoveTag}/>
                                        )
                                    }
                                )
                                :
                                <div style={{padding:"6px 0"}}>None</div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}
AddSearchTag.propTypes={
    listLabel:PropTypes.string.isRequired,
    listHeight:PropTypes.string,
};
