import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,
        AddListItem,Segment,LoadGraphic,Step,DynamicListArea,
        BulkEdit,DownloadTemplate,SegmentComplete} from '../components/Common';
import {EditSizeLabel,EditSizeLink,Select,Button,
        Date,Info,Checkbox,Box,ContactNumber,
        MiniMinusButton,TextButton} from '../components/Input';
import {EstateUtilities} from './Estate';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';
import PropTypes from 'prop-types';
import "../styles/agreement-list.scss";

export default class AgreementList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Agreements",
        permission:"agreement",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/agreement-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-agreement-entry",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-agreement-entries",
        spreadsheetURL:"/api/generate-agreement-spreadsheet",
        overviewURL:"/api/get-all-agreement-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"agreement-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
        dormOptions:[],
    };

    componentDidMount=()=>{
        this.getOptions();

        const permissions = u.getPermissions("agreement");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const pricePermission = u.getPermissions("combined");
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add",mobileLabel:"Add Entry",ref:"add",image:"/images/page-menu/add.png",title:"Add new agreement entry",enabled:(permissions.create==2)},
                {label:"Delete",mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entry",enabled:(permissions.delete==2)},
                {label:"Spreadsheet",mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit agreement details",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Configure Pricing",mobileLabel:"Configure Pricing",ref:"price",image:"/images/page-menu/price.png",title:"Configured price entries",enabled:(pricePermission.price==2),onClick:this.onConfigurePrice},
            ],
            permissions:permissions,
        });
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["agreementType","renewalStatus"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });

        this.getDormOptions();
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onConfigurePrice=()=>{
        window.open("/price-list","_blank");
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onRenew=(content)=>{
        this.refs["menu"].refs["add"].onRenewEntry(content);
    }

    render=()=>{
        const pageComponents={
            add:{component:AddAgreementEntry,props:{dormOptions:this.state.dormOptions,options:this.state.options}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
            bulkEdit:{component:BulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu ref="menu"
                          buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}/>


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AgreementItem key={index}
                                               index={index}
                                               content={content}
                                               onChange={onChange}
                                               onContentChange={onContentChange}
                                               registerReference={registerRef}
                                               options={this.state.options}
                                               onRenew={this.onRenew}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class AgreementItem extends React.Component{
    render=()=>{
        const content = this.props.content;
        return (
            <div>
                <DynamicListArea content={this.props.content}
                                 onChange={this.props.onChange}
                                 index={this.props.index}
                                 listLabel={"agreement-list"}
                                 permission="agreement"/>

                {
                    ((content.type=="Agreement" || content.type=="Renewal") && content.renewedToID == undefined)
                        ?
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.props.onRenew(this.props.content)}}>Renew</Button>
                        </div>
                        :
                        null
                }

                <div className="list-segment-buttons">
                    <Link to={"/agreement/" + content.agreementID}>See More</Link>
                </div>
            </div>
        )
    }
}

class AddAgreementEntry extends React.Component{

    state={
      additional:{},
      step:"add",
      entryData:{
          renewedFrom:[],
          priceForm:[],
          startDate:moment().format("YYYY-MM-DD"),
          endDate:moment().format("YYYY-MM-DD"),
      },
      retain:{
          assignedRooms:[],
          assignedBeds:[],
      },
      loading:false,
      loadText:"",
      active:false,
    }

    show=(show)=>{
        this.setState({active:show});
        if(!show){
            this.setState({
                step:"add",
                entryData:{
                    renewedFrom:[],
                    priceForm:[],
                    startDate:moment().format("YYYY-MM-DD"),
                    endDate:moment().format("YYYY-MM-DD"),
                }
            });
        }
    }

    onCancel=()=>{
        this.setState({loading:false,error:"",step:"add"});
        this.props.onToggleForm(this.props.reference,false);
    }

    onRenewEntry=(renewalEntry)=>{
        let entryData = this.state.entryData;
        entryData.type = "Renewal";
        entryData.dormID=renewalEntry.dormID;
        entryData.clientID=renewalEntry.clientID;
        entryData.priceForm = renewalEntry.priceForm;
        entryData.agreementID = renewalEntry.agreementID;
        entryData.autoRef = true;
        entryData.reference = renewalEntry.reference;
        entryData.startDate = moment(renewalEntry.endDate).add(1,"days").format("YYYY-MM-DD");
        entryData.endDate = moment(renewalEntry.endDate).add(1,"days").format("YYYY-MM-DD");
        this.setState({entryData:entryData});
        this.props.onToggleForm(this.props.reference,true);
    }

    onChangeEntryData=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        if(field == "autoRef" && value){
            entryData.reference = "";
        }
        else if(field == "newClient" && value){
            entryData.clientID = "none";
        }
        this.setState({entryData:entryData});
    }

    onFormValidate=()=>{
        this.setState({error:""});

        const formData = this.refs['form'].validate();
        if(!formData.valid){
            this.setState({error:formData.value});
            return 0;
        }

        let entryData=this.state.entryData
        if(entryData.type == "Renewal"){
            let renewedFrom = formData.renewedFrom;
            renewedFrom.unshift({value:entryData.agreementID,label:entryData.reference});
            entryData.renewedFrom=renewedFrom;
            this.getRenewalAssignment(renewedFrom);
        }
        else{
            this.getDefaultPriceForm();
        }
    }

    getRenewalAssignment=(renewedFrom)=>{
        this.setState({loading:true,loadText:"Finding existing assignments..."});
        u.post({
            url:"/api/get-renewal-assignments",
            data:{
                renewedFrom:renewedFrom
            },
            success:(assignments)=>{
                this.setState({retain:assignments,loading:false,error:"",step:"retain"})
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onUpdateRetain=(list,value)=>{
        let retain = this.state.retain;
        retain[list] = value;
        this.setState({retain:retain});
    }

    onRetainValidate=()=>{
        let entryData = this.state.entryData;
        entryData.assignedRooms=this.state.retain.assignedRooms.filter((item)=>{return item.selected}).map((item)=>{item.selected=undefined; return item});
        entryData.assignedBeds=this.state.retain.assignedBeds.filter((item)=>{return item.selected}).map((item)=>{item.selected=undefined; return item});
        this.setState({entryData:entryData});
        this.getDefaultPriceForm();
    }

    getDefaultPriceForm=(success,error)=>{
        this.setState({loading:true,loadText:"Getting price template..."});
        u.post({
            url:"/api/get-price-template",
            data:{
                  dormID:this.state.entryData.dormID
            },
            success:({depositFactor,stampDutyFactor,priceTemplate})=>{
                let priceForm = priceTemplate.map(
                    (item)=>{
                        item.items = item.items.map(
                            (line,r)=>{
                                line.price = (line.priceType == "List Value") ? line.priceList[0] : ((line.priceType == "Minimum Value") ? line.defaultValue : "0");
                                line.quantity = "1";
                                line.remark = (line.remarkType == "List Value") ? line.remarkList[0] : "";
                                return line;
                            }
                        );
                        item.preApprovedContent = item.items.filter((item)=>{return item.required==0});
                        item.preApprovedOptions = item.preApprovedContent.map((option)=>{return option.name});
                        return item;
                    }
                )
                let entryData = this.state.entryData;
                entryData.priceForm = priceForm;
                entryData.depositFactor=depositFactor;
                entryData.stampDutyFactor=stampDutyFactor;
                entryData.preApprovedSectionContent=priceForm.slice(0);
                entryData.preApprovedSectionOptions=priceForm.map((item)=>{return item.header});
                entryData.autoDeposit = true;
                entryData.deposit=PriceForm.calculateDeposit(priceForm,depositFactor);
                entryData.autoStampDuty = true;
                entryData.stampDuty=PriceForm.calculateStampDuty(priceForm,stampDutyFactor);
                this.setState({entryData:entryData,step:"estimated-occupancy",loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onPriceValidate=()=>{
        this.setState({loading:true,error:"",loadText:"Creating agreement..."});
        let data = Object.assign({},this.state.entryData);

        u.post({
          url:"/api/add-agreement-entry",
          data:data,
          success:({agreementID,reference})=>{
              if(this.props.settings.overview){
                  this.props.settings.overview.onRefreshList();
              }
              this.setState({
                  step:"success",
                  loading:false,
                  reference:reference,
                  agreementID:agreementID,
                  entryData:{
                      renewedFrom:[],
                      priceForm:[],
                      startDate:moment().format("YYYY-MM-DD"),
                      endDate:moment().format("YYYY-MM-DD"),
                  }
              });
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
        });
    }

    onBackFromAvailable=()=>{
        this.setState({
            step:((this.state.entryData.type == "Renewal") ? "retain" : "add"),
            error:""
        });
    }

    render=()=>{
        const dormOptions = this.props.dormOptions;
        const entryData = this.state.entryData;
        const options = this.props.options;
        const infoTitle={width:"15px",height:"15px",marginBottom:"15px"};

        return(
            <Segment active={this.state.active}>
               <div style={{textAlign:"center",position:"relative"}}>
                    <div className="segment-title">Create New Agreement</div>
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="segment-content">
                          <Step active={this.state.step == "add"}>
                              <h3>Enter the following details to continue</h3>
                              <DetailForm ref="form" onChange={this.onChangeEntryData} content={entryData} options={options} dormOptions={dormOptions}/>
                              <div className="segment-buttons">
                                  {
                                    (u.isMobileDevice())
                                      ?
                                      <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                      :
                                      null
                                  }
                                  <Button type="medium" onClick={this.onFormValidate}>Next</Button>
                              </div>
                          </Step>

                          <Step active={this.state.step == "retain"}>
                              <h3>Retain Existing Assignments <Info style={infoTitle} title="Rooms/Beds are currently assigned to the existing agreement, selected entries will be automatically added to this renewal"/></h3>
                              <RetainAssignment type="room" content={this.state.retain.assignedRooms} onChange={(value)=>{this.onUpdateRetain("assignedRooms",value)}}/>
                              <RetainAssignment type="bed" content={this.state.retain.assignedBeds} onChange={(value)=>{this.onUpdateRetain("assignedBeds",value)}}/>
                              <div className="segment-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:"add",error:""})}}>Back</Button>
                                  <Button type="medium" onClick={this.onRetainValidate}>Next</Button>
                              </div>
                          </Step>

                          <Step active={this.state.step == "estimated-occupancy"}>
                              <CheckAvailability exclude={(entryData.renewedFrom) ? entryData.renewedFrom : []}
                                                 startDate={entryData.startDate}
                                                 endDate={entryData.endDate}
                                                 estimate={entryData.estimatedOccupancy}
                              />
                              <div className='segment-buttons'>
                                  <Button onClick={this.onBackFromAvailable}>Back</Button>
                                  <Button onClick={()=>{this.setState({step:"pricing",error:""})}}>Next</Button>
                              </div>
                          </Step>

                          <Step active={this.state.step == "pricing"}>
                              <h3>Quotation Pricing <Info style={infoTitle} title="Default form shows all pre-approved items, remove non-applicable items accordingly"/></h3>
                              <PriceForm content={entryData.priceForm}
                                         estimatedOccupancy={entryData.estimatedOccupancy}
                                         entryData={entryData}
                                         onChange={this.onChangeEntryData}
                                         preApprovedSectionContent={entryData.preApprovedSectionContent}
                                         preApprovedSectionOptions={entryData.preApprovedSectionOptions}
                                         onBack={()=>{this.setState({step:"estimated-occupancy",error:""})}}
                                         onValidated={this.onPriceValidate}
                                         />
                          </Step>

                          <Step active={this.state.step == "success"} scroll={true}>
                              <SegmentComplete onComplete={this.onCancel}>
                                  Successfully created agreement <Link to={"/agreement/"+this.state.agreementID}>{this.state.reference}</Link>
                              </SegmentComplete>
                          </Step>
                    </div>
               </div>
           </Segment>
        )
    }
}

class DetailForm extends React.Component{

    state={
        clientOptions:[],
        agreementOptions:[],
    };

    componentDidMount=()=>{
        this.getClientOptions();
        if(this.props.content.agreementID && this.props.content.type == "Renewal"){
            this.getMergableAgreementOptions();
        }
    }

    validate=()=>{
        const keys=["dormID","clientID","clientName","contactName","contactEmail","contactNumber","reference","type","startDate","endDate","estimatedOccupancy"];
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    return {valid:false,value:current.value};
                }
            }
        }

        return {
            valid:true,
            renewedFrom:this.state.agreementOptions.filter((item)=>{return (item.selected)}).map((item)=>{return {value:item.value,label:item.label}})
        };
    }

    getClientOptions=()=>{
        u.post({
            url:"/api/get-client-options",
            data:{},
            success:(clientOptions)=>{
                clientOptions.unshift({value:"none",label:""});
                this.setState({clientOptions:clientOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getMergableAgreementOptions=()=>{
        u.post({
            url:"/api/get-mergable-agreement-options",
            data:{
                agreementID:this.props.content.agreementID
            },
            success:(agreementOptions)=>{
                this.setState({agreementOptions:agreementOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onChangeMergable=(value,label,index)=>{
        let agreementOptions = this.state.agreementOptions;
        agreementOptions[index].selected = value;
        this.setState({agreementOptions:agreementOptions});
    }

    render=()=>{
        const content = this.props.content;
        const onChange = this.props.onChange;
        const isRenewal = (content.type == "Renewal" && (content.agreementID));
        const dormOptions = this.props.dormOptions;
        const options = this.props.options;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoTitle={width:"15px",height:"15px",marginBottom:"5px"};

        return(
            <div className="agreement-entry">
                <div className="segment-full-item">
                    <div className="segment-item-label">Dormitory</div>
                    <div className="segment-item-input">
                        <Select ref="dormID" label="Dormitory" field="dormID" values={{value:content.dormID,enabled:!isRenewal,options:dormOptions}} rules={{required:true}} onChange={onChange}/>
                    </div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Organization</div>
                    <div className="segment-item-input">
                        <div className="segment-input-checkboxable"><Select ref="clientID" label="Organization" field="clientID" values={{value:content.clientID,enabled:(!content.newClient && !isRenewal),options:this.state.clientOptions}} rules={{required:!content.newClient}} onChange={onChange}/></div>
                        <div className="segment-input-checkbox"><div className="segment-input-checkbox-text">NEW</div><Checkbox style={{margin:0}} label="newClient" values={{value:content.newClient,enabled: !isRenewal}} onChange={onChange}/></div>
                    </div>
                </div>

                {
                    (content.newClient)
                      ?
                      <div className="segment-full-item">
                          <div className="segment-item-label">Organization Name</div>
                          <div className="segment-item-input"><Box ref="clientName" label="Organization Name" field="clientName" values={{value:content.clientName}} rules={{required:true}} onChange={onChange}/></div>
                      </div>
                      :
                      null
                }

                {
                    (content.newClient)
                      ?
                      <div className="segment-full-item">
                          <div className="segment-item-label">Contact Name <Info style={infoInput} title="Organization's person of contact, will be automatically added to the address book"/></div>
                          <div className="segment-item-input"><Box ref="contactName" label="Contact Name" field="contactName" values={{value:content.contactName}} rules={{required:true}} onChange={onChange}/></div>
                      </div>
                      :
                      null
                }

                {
                    (content.newClient)
                      ?
                      <div className="segment-full-item">
                          <div className="segment-item-label">Email</div>
                          <div className="segment-item-input"><Box ref="contactEmail" label="Email" field="contactEmail" values={{value:content.contactEmail}} type="email" rules={{required:true}} onChange={onChange}/></div>
                      </div>
                      :
                      null
                }

                {
                    (content.newClient)
                      ?
                      <div className="segment-full-item">
                          <div className="segment-item-label">Phone</div>
                          <div className="segment-item-input"><ContactNumber ref="contactNumber" contactNumberField="contactNumber" countryCodeField="contactCountryCode" rules={{required:true}} onChange={onChange}/></div>
                      </div>
                      :
                      null
                }

                <div className="segment-full-item">
                    <div className="segment-item-label">Reference</div>
                    <div className="segment-item-input">
                        <div className="segment-input-checkboxable"><Box ref="reference" label="Reference" field="reference" values={{value:content.reference,enabled:!content.autoRef}} rules={{required:!content.autoRef,placeholder:((isRenewal) ? content.reference : null)}} onChange={onChange}/></div>
                        <div className="segment-input-checkbox"><div className="segment-input-checkbox-text">AUTO</div><Checkbox style={{margin:0}} label="autoRef" values={{value:content.autoRef}} onChange={onChange}/></div>
                    </div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Type</div>
                    <div className="segment-item-input">
                        <Select ref="type"
                                label="Type"
                                field="type"
                                values={{
                                    value:content.type,
                                    enabled:!isRenewal,
                                    options:options.agreementType,
                                }}
                                onChange={onChange}/>
                    </div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Start Date <Info style={infoInput} title="When will this agreement start? Rooms & Beds assigned to this agreement cannot be assigned to other agreements during this period"/></div>
                    <div className="segment-item-input">
                        <Date ref="startDate" label="Start Date" field="startDate" values={{value:content.startDate}} range={[2,10]} rules={{maxDate:content.endDate}} onChange={onChange}/>
                    </div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">End Date <Info style={infoInput} title="When will this agreement end? Rooms & Beds assigned to this agreement cannot be assigned to other agreements during this period"/></div>
                    <div className="segment-item-input">
                        <Date ref="endDate" label="End Date" field="endDate" values={{value:content.endDate}} range={[2,10]} rules={{minDate:content.endDate}} onChange={onChange}/>
                    </div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Est. Occupancy <Info style={infoInput} title="Total number of beds allocated to this agreement"/></div>
                    <div className="segment-item-input">
                        <Box ref="estimatedOccupancy"
                             label="Estimated Occupancy"
                             field="estimatedOccupancy"
                             type="number"
                             values={{value:content.estimatedOccupancy}}
                             rules={{required:true,minValue:1}}
                             onChange={onChange}/>
                    </div>
                </div>

                {
                    (this.state.agreementOptions.length > 0)
                        ?
                        <div>
                            <h4>Merge Agreements <Info style={infoTitle} title="Select the following agreements that you want to merge into this renewal, the selected agreements will be completed early on the respective start date"/></h4>
                            <div>
                                {
                                    this.state.agreementOptions.map(
                                        (item,index)=>{
                                            return (
                                                <div key={index} className="segment-full-item">
                                                    <Checkbox values={{value:item.selected}} index={index} label={item.label} onChange={this.onChangeMergable}/> {item.label}
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                        </div>
                        :
                        null
                }

            </div>
        )
    }
}

class RetainAssignment extends React.Component{

    state={
        selectAll:false
    };

    onSelectAll=(value)=>{
        let content = this.props.content;
        for(var i=0; i<content.length; i++){
            content[i].selected = value;
        }
        this.props.onChange(content);
        this.setState({selectAll:value});
    }

    onChange=(value,label,index)=>{
        let content = this.props.content;
        content[index].selected = value;
        this.props.onChange(content);
    }

    validate=()=>{
        return {
            valid:true,
            value:this.props.content
        };
    }

    render=()=>{
        const content = this.props.content;
        return(
            <div className="agreement-retain-assignment segment-table">
                <table>
                    <thead>
                        <tr>
                            <th className="segment-table-select left"><Checkbox values={{value:this.state.selectAll}} onChange={this.onSelectAll}/></th>
                            <th>{(this.props.type == "room") ? "Room" : "Bed"}</th>
                            <th className="agreement">Agreement</th>
                            <th className="occupancy right">Current Occupancy</th>
                        </tr>
                    </thead>
                    <tbody style={{maxHeight:"300px"}}>
                    {
                        content.map(
                            (item,index)=>{
                                return(
                                    <tr key={index} className="item">
                                        <td className="segment-table-select"><Checkbox values={{value:item.selected}} index={index} onChange={this.onChange}/></td>
                                        <td>{EstateUtilities.getNoLocationLabel(item,true)}</td>
                                        <td className="agreement">{item.reference}</td>
                                        <td className="occupancy">{item.occupancy} / {item.capacity}</td>
                                    </tr>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
            </div>
        )
    }
}

export class PriceForm extends React.Component{

    state={
        step:"form",
        options:[],
        newLine:{},
        newSection:{},
        preApprovedOptions:[],
        preApprovedContent:[],
        combinedPermissions:{},
    };

    componentDidMount=()=>{
        const combinedPermissions=u.getPermissions("combined");
        this.setState({combinedPermissions:combinedPermissions});
        this.getOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","priceType","taxType","remarkType","priceLineType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onRemoveSection=(index)=>{
        let content = this.props.content;
        content.splice(index,1);
        this.props.onChange(content,"priceForm");
        this.onCalculateExtra(content);
    }

    onRemoveLine=(index)=>{
        let content = this.props.content;
        content[index.headerIndex].items.splice(index.itemIndex,1);
        this.props.onChange(content,"priceForm");
        this.onCalculateExtra(content);
    }

    onChangeLine=(value,field,index)=>{
        let content = this.props.content;
        content[index.headerIndex].items[index.itemIndex][field] = value;
        if((field == "quantity" || field == "price")){
            this.onCalculateExtra(content);
        }
        if(field=="quantity" || field=="price" || field=="validation"){
            this.onCalculateValidation(content);
        }
        this.props.onChange(content,"priceForm");
    }

    onAddNewLine=(index)=>{
        const content = this.props.content[index];
        this.setState({
            addLineIndex:index,
            newLine:{},
            step:"line",
            error:"",
            preApprovedOptions:content.preApprovedOptions,
            preApprovedContent:content.preApprovedContent,
        });
    }

    onValidateNewLine=()=>{
        let content = this.props.content;

        const keys=["name","taxType","deposit","rental"];
        for(var k=0; k<keys.length; k++){
            const current = this.refs[keys[k]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        let lineItem = this.state.newLine;
        const existing = content[this.state.addLineIndex].items;

        for(var e=0; e<existing.length; e++){
            if(existing[e].name == lineItem.name){
                this.setState({error:"Line Item " + lineItem.name + " already exists"});
                return 0;
            }
        }

        //Check for unapprovedLooseItem
        if(!content[this.state.addLineIndex].allowUnapprovedItem){
            let noMatch = true;
            for(var u=0; u<this.state.preApprovedContent.length; u++){
                  if(this.state.preApprovedContent[u].name == lineItem.name){
                        noMatch = false;
                        break;
                  }
            }
            if(noMatch){
                this.setState({error:"Only pre-approved items are allowed in this section"});
                return 0;
            }
        }

        lineItem.priceList= (lineItem.priceList) ? lineItem.priceList : [];
        lineItem.remarkList=(lineItem.remarkList) ? lineItem.remarkList : [];
        existing.push(lineItem);
        this.props.onChange(content,"priceForm");
        this.onCalculateExtra(content);
        this.setState({step:"form",error:""});
    }

    onChangeNewLine=(value,label,index,option)=>{
        let newLine = this.state.newLine;
        newLine[label] = value;
        let match = false;
        if(label == "name"){
            //Check if name is associated with anything in preApproved
            let preApproved = this.state.preApprovedContent;
            for(var i=0; i<preApproved.length; i++){
                if(value == preApproved[i].name){
                    newLine = Object.assign({},preApproved[i]);
                    newLine.quantity = 1;
                    newLine.price = ((newLine.priceType == "List Value") ? newLine.priceList[0] : ((newLine.defaultValue) ? newLine.defaultValue : "0"));
                    match = true;
                    break;
                }
            }
            if(!match){
                  newLine.priceType = "No Validation";
                  newLine.priceList = [];
                  newLine.remarkType = "No Validation";
                  newLine.remarkList = [];
                  newLine.lockName = 0;
                  newLine.lockRemark = 0;
                  newLine.required = 0;
                  newLine.quantity = 1;
                  newLine.price = "0";
            }
        }
        this.setState({newLine:newLine,preApprovedNewLine:match});
    }

    onAddNewSection=()=>{
        this.setState({
              step:"section",
              newSection:{},
              error:"",
        });
    }

    onChangeNewSection=(value,label,index,option)=>{
        let newSection = this.state.newSection;
        newSection[label]=value;
        let match = false;
        if(label == "header"){
            let preApproved = this.props.preApprovedSectionContent;
            for(var i=0; i<preApproved.length; i++){
                if(value == preApproved[i].header){
                    newSection = Object.assign({},preApproved[i]);
                    match = true;
                    break;
                }
            }
            if(!match){
                const monthlyRecurring = newSection.monthlyRecurring;
                newSection = {};
                newSection.header = value;
                newSection.monthlyRecurring = monthlyRecurring;
                newSection.rental=[];
                newSection.looseItems=[];
                newSection.allowUnapprovedItem = 1;
            }
        }
        this.setState({newSection:newSection,preApprovedNewSection:match});
    }

    onValidateNewSection=()=>{
        let content = this.props.content;

        const keys=["header","monthlyRecurring"];
        for(var k=0; k<keys.length; k++){
            const current = this.refs[keys[k]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        const existing = this.props.content;
        let newSection = this.state.newSection;

        for(var e=0; e<existing.length; e++){
            if(existing[e].header == newSection.header){
                this.setState({error:"Section " + newSection.header + " already exists"});
                return 0;
            }
        }
        if(typeof newSection.items == "undefined"){
            newSection.items=[];
        }
        content.push(newSection);
        this.props.onChange(content,"priceForm");
        this.setState({step:"form",error:""});
    }

    onDragStart=(event,index,lineIndex)=>{
        event.dataTransfer.setData("text",lineIndex+"");
        event.currentTarget.id = "drag";
        this.dragIndex=lineIndex;
        this.dragHeader=index;
    }

    onDrop=(event,index,lineIndex)=>{
        if(index != this.dragHeader){
           return 0;
        }

        const dragTarget = event.dataTransfer.getData("text");
        const dropTarget = lineIndex;

        event.currentTarget.id = "none";

        if(dragTarget != dropTarget){
            let content = this.props.content;
            content[this.dragHeader].items.splice(dropTarget,0,content[this.dragHeader].items.splice(dragTarget,1)[0]);
            this.props.onChange(content,"priceForm");
        }
    }

    onDragHover=(event,index,lineIndex,dragover)=>{
        event.stopPropagation();
        event.preventDefault();
        if(index != this.dragHeader){
           return 0;
        }
        if(lineIndex == this.dragIndex){
            return 0;
        }
        if(dragover){
            //above or below
            if(this.dragIndex < lineIndex){
                event.currentTarget.id = "hover-down";
            }
            else{
                event.currentTarget.id = "hover-up";
            }
        }
        else{
            event.currentTarget.id = "none";
        }
    }

    onDragEnd=(event,index,lineIndex)=>{
        const drag = document.getElementById("drag");
        if(drag){
            drag.id = "none";
        }
    }

    onValidateForm=()=>{
        //Check if there are unassign items
        let content = this.props.content;
        const permissions = u.getPermissions("combined");
        const hasPerm = (permissions.price == 2);

        if(content.length == 0){
            this.setState({error:"Price Quotation is empty"});
            return 0;
        }
        for(var h=0; h<content.length; h++){
            const section = content[h];
            for(var i=0; i<section.items.length; i++){
                const item = section.items[i];

                //Validate name
                const name = this.refs["name-"+h+"-"+i].validate();
                if(!name.valid){
                    this.setState({error:name.value});
                    return 0;
                }
                //Validate quantity
                const quantity = this.refs["quantity-"+h+"-"+i].validate();
                if(!quantity.valid){
                    this.setState({error:quantity.value});
                    return 0;
                }

                //Validate price
                const priceObj = this.refs["price-"+h+"-"+i];
                const price = this.refs["price-"+h+"-"+i].validate();
                if(!price.valid){
                    this.setState({error:price.value});
                    return 0;
                }

                //Must conform to values in list unless, permission.combined.price exists
                if(!hasPerm){
                    if(item.priceType == "List Value"){
                        let matchList = false;
                        if(!matchList){
                          for(var p=0; p<item.priceList.length; p++){
                              if(item.priceList[p] == price.value){
                                  matchList = true;
                                  break;
                              }
                          }
                        }
                        if(!matchList){
                            priceObj.setError();
                            this.setState({error:"You do not have the permission to use pricing that is not pre-approved"});
                            return 0;
                        }
                    }
                    else if(item.priceType == "Minimum Value"){
                        if(price.value < item.minimumValue){
                           priceObj.setError();
                           this.setState({error:"You do not have permission to use pricing that is below the minimum " + item.minimumValue});
                           return 0;
                        }
                    }
                }

                //Validate remarks
                const remarks = this.refs["remark-"+h+"-"+i].validate();
                if(!remarks.valid){
                    this.setState({error:remarks.value});
                    return 0;
                }

                //Fixed to list?
                if(!hasPerm){
                    if(item.remarkType == "List Value"){
                        let matchList = false;
                        for(var r=0; r<item.remarkList.length; r++){
                            if(remarks.value == item.remarkList[r]){
                                matchList = true;
                            }
                        }
                        if(!matchList){
                            this.setState({error:"You do not have permission to use remark that is not pre-approved"});
                            return 0;
                        }
                    }
                }
            }
        }

        this.props.onValidated();
    }

    onCalculateExtra=(content)=>{
        let entryData = this.props.entryData;
        if(entryData.autoDeposit){
            this.props.onChange(PriceForm.calculateDeposit(content,entryData.depositFactor),"deposit");
        }
        if(entryData.autoStampDuty){
            this.props.onChange(PriceForm.calculateStampDuty(content,entryData.stampDutyFactor),"stampDuty");
        }
    }

    onCalculateValidation=(content)=>{
        let entryData=this.props.entryData;
        this.props.onChange(PriceForm.calculateValidation(content),"validation");
    }

    onChangeAuto=(value,label)=>{
        let entryData = this.props.entryData;
        if(label == "autoDeposit" && value){
            this.props.onChange(PriceForm.calculateDeposit(this.props.content,entryData.depositFactor),"deposit");
        }
        else if(label == "autoStampDuty" && value){
            this.props.onChange(PriceForm.calculateStampDuty(this.props.content,entryData.stampDutyFactor),"stampDuty");
        }
        this.props.onChange(value,label);
    }

    onSectionMove=(index,dir)=>{
        let content=this.props.content;
        let newContent = content.slice(0);
        if(dir=="up"){
            newContent=u.arrayMove(content,index,index-1);
        }
        else if(dir=="down"){
            newContent=u.arrayMove(content,index,index+1);
        }
        this.props.onChange(newContent,"priceForm");
    }

    static calculateDeposit(content,depositFactor){
        let depositSubTotal = 0.0;
        for(var s=0; s<content.length; s++){
            const section = content[s];
            for(var i=0; i<section.items.length; i++){
                const item = section.items[i];
                if(item.deposit){
                    const quantity = !(isNaN(parseInt(item.quantity))) ? parseInt(item.quantity) : 0;
                    const price = !(isNaN(parseInt(item.price))) ? parseInt(item.price) : 0.0;
                    depositSubTotal += quantity * price;
                }
            }
        }
        return (depositSubTotal * parseFloat(depositFactor)).toFixed(2);
    }

    static calculateStampDuty(content,stampDutyFactor){
        let stampDutySubTotal = 0.0;
        for(var s=0; s<content.length; s++){
            const section = content[s];
            for(var i=0; i<section.items.length; i++){
                const item = section.items[i];
                if(item.taxType == "Stamp Duty"){
                    const quantity = !(isNaN(parseInt(item.quantity))) ? parseInt(item.quantity) : 0;
                    const price = !(isNaN(parseInt(item.price))) ? parseInt(item.price) : 0.0;
                    stampDutySubTotal += quantity * price;
                }
            }
        }
        return (stampDutySubTotal * parseFloat(stampDutyFactor)).toFixed(2);
    }

    static calculateValidation(content){
        let validation = 0;
        for(var s=0; s<content.length; s++){
            const section = content[s];
            for(var i=0; i<section.items.length; i++){
                const item=section.items[i];
                const quantity = !(isNaN(parseInt(item.quantity))) ? parseInt(item.quantity) : 0;
                const value = !(isNaN(parseInt(item.validation))) ? parseInt(item.validation) : 0;
                validation += quantity * value;
            }
        }
        return validation;
    }

    render=()=>{
        const content = this.props.content;
        const entryData = this.props.entryData;
        const options = this.state.options;
        const newLine = this.state.newLine;
        const newSection = this.state.newSection;
        const infoInput = {width:"15px",height:"15px",marginBottom:"3px"};
        const preApprovedOptions=this.state.preApprovedOptions;
        const preApprovedSectionOptions = this.props.preApprovedSectionOptions;
        const estimatedOccupancy=this.props.estimatedOccupancy;
        const combinedPermissions=this.state.combinedPermissions;

        return(
          <div className="agreement-price-form">
              <Step active={this.state.step == "form"}>
                  <div>
                      {
                          (content.length == 0)
                            ?
                            <div style={{borderRadius:"10px",background:"#f4f4f4",padding:"50px 0",textAlign:"center",width:"1000px",maxWidth:"100%",margin:"20px auto"}}>
                                <b>Price Table Empty</b><br/>
                                Setup <Link to="/price-list" target="_blank">Price Configuration</Link> to pre-populate the price table
                            </div>
                            :
                            null
                      }

                      <TextButton onClick={()=>{this.setState({step:"section",error:""})}}>Add Section</TextButton>

                      {
                        content.map(
                            (item,index,arr)=>{
                                return (
                                  <div className="price-table-container" key={index} style={{zIndex:(arr.length - index)}}>
                                      <div>
                                          <div className="section-header">{item.header}</div>
                                          <div className="section-remove">
                                              <img src="/images/input/down-arrow.png"
                                                   title="Move this section up"
                                                   onClick={()=>{this.onSectionMove(index,"up")}}
                                                   className={"section-move top" + ((index == 0) ? " disabled" : "")}/>
                                          </div>
                                          <div className="section-remove">
                                              <img src="/images/input/down-arrow.png"
                                                   title="Move this section down"
                                                   onClick={()=>{this.onSectionMove(index,"down")}}
                                                   className={"section-move" + ((index + 1 == arr.length) ? " disabled" : "")}/>
                                          </div>
                                          <div className="section-remove">
                                              <div style={{width:"20px",height:"20px"}} title="Remove Section">
                                                  <MiniMinusButton index={index} onClick={this.onRemoveSection}/>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="price-table">
                                          <div>
                                              <div className="price-table-left price-header">&nbsp;</div>
                                              <div className="price-item price-header">Item</div>
                                              <div className="price-quantity price-header">Quantity</div>
                                              <div className="price-value price-header">Unit Price</div>
                                              <div className="price-remark price-header">Remark</div>
                                              <div className="price-validation price-header">Validation</div>
                                              <div className="price-table-right price-header">&nbsp;</div>
                                          </div>
                                          <div>
                                              {
                                                  (item.items.length > 0)
                                                    ?
                                                    item.items.map(
                                                        (line,lineIndex,itemArray)=>{
                                                            return(
                                                                <div key={lineIndex}
                                                                     className="price-line"
                                                                     style={{zIndex:(itemArray.length - lineIndex)}}
                                                                     draggable={true}
                                                                     onDragStart={(event)=>{this.onDragStart(event,index,lineIndex)}} onDragEnd={(event)=>{this.onDragEnd(event,index,lineIndex)}}
                                                                     onDragOver={(event)=>{this.onDragHover(event,index,lineIndex,true)}} onDragLeave={(event)=>{this.onDragHover(event,index,lineIndex,false)}}
                                                                     onDrop={(event)=>{this.onDrop(event,index,lineIndex)}}
                                                                     >
                                                                    <div className="price-table-left">
                                                                        <div style={{width:"16px",height:"16px",margin:"auto"}} title={(line.required) ? "Compulsory Line" : "Remove Line"}>
                                                                            <MiniMinusButton index={{headerIndex:index,itemIndex:lineIndex}}
                                                                                             enabled={line.required != 1}
                                                                                             onClick={(index)=>{this.onRemoveLine(index)}}
                                                                                             />
                                                                        </div>
                                                                    </div>
                                                                    <div className="price-item">
                                                                        <Box field="name"
                                                                             index={{headerIndex:index,itemIndex:lineIndex}}
                                                                             values={{value:line.name,enabled:false}}
                                                                             rules={{required:true}}
                                                                             onChange={this.onChangeLine}
                                                                             label={"Item " + lineIndex + " Name"}
                                                                             ref={"name-"+index+"-"+lineIndex}/>
                                                                    </div>
                                                                    <div className="price-quantity">
                                                                        <Box field="quantity"
                                                                             index={{headerIndex:index,itemIndex:lineIndex}}
                                                                             values={{value:line.quantity}}
                                                                             type="number"
                                                                             rules={{required:true,minValue:1}}
                                                                             onChange={this.onChangeLine}
                                                                             label={"Item " + lineIndex + " Quantity"}
                                                                             ref={"quantity-"+index+"-"+lineIndex}/>
                                                                    </div>
                                                                    <div className="price-value">
                                                                        <Box field="price"
                                                                             index={{headerIndex:index,itemIndex:lineIndex}}
                                                                             values={{value:line.price}}
                                                                             rules={{required:true,list:line.priceList,isFloat:true,ignoreListFilter:true}}
                                                                             onChange={this.onChangeLine}
                                                                             label={"Item " + lineIndex + " Price"}
                                                                             ref={"price-"+index+"-"+lineIndex}
                                                                             />
                                                                    </div>
                                                                    <div className="price-remark">
                                                                        <Box field="remark"
                                                                             index={{headerIndex:index,itemIndex:lineIndex}}
                                                                             values={{value:line.remark,enabled:!line.lockRemark}}
                                                                             rules={{list:line.remarkList}}
                                                                             onChange={this.onChangeLine}
                                                                             label={"Item " + lineIndex}
                                                                             ref={"remark-"+index+"-"+lineIndex}/>
                                                                    </div>
                                                                    <div className="price-validation">
                                                                        <Box field="validation"
                                                                             index={{headerIndex:index,itemIndex:lineIndex}}
                                                                             values={{value:line.validation}}
                                                                             type="number"
                                                                             rules={{defaultValue:"0"}}
                                                                             onChange={this.onChangeLine}
                                                                             label={"Item " + lineIndex}
                                                                             ref={"remark-"+index+"-"+lineIndex}/>
                                                                    </div>
                                                                    <div className="price-table-right">
                                                                        <Info style={{width:"16px",height:"16px"}} title={"Monthly Recurring: "+((item.monthlyRecurring) ? "Yes" : "No")+"</br>Tax Type: " + line.taxType + "<br/>Deposit: " + ((line.deposit) ? "Yes" : "No") +"</br>Rental Line: "+ ((line.rental) ? "Yes" : "No")}/>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    )
                                                    :
                                                    <div style={{padding:"50px 0",textAlign:'center',background:"#f4f4f4"}}>
                                                        <b>No Items Added</b>
                                                    </div>
                                              }
                                          </div>
                                          <div style={{margin:"10px auto"}}><TextButton index={index} onClick={this.onAddNewLine}>Add Line</TextButton></div>
                                      </div>
                                  </div>
                                )
                            }
                        )
                      }

                      <div style={{margin:"30px auto 20px auto"}}>
                            <div><b>Validation</b></div>
                            <div style={{fontSize:"0.8em"}}>(Quote/Occupancy): <span className={((parseInt(entryData.validation) > parseInt(estimatedOccupancy)) ? "text-negative" : "")}>{(entryData.validation) ? entryData.validation : 0}</span> / {estimatedOccupancy}</div>
                      </div>

                      <div>
                          <div style={{display:"inline-block",verticalAlign:"top",margin:"20px"}}>
                              <b>Deposit</b>
                              <div className="segment-full-item">
                                  <div className="segment-input-checkboxable">
                                      <Box ref="deposit"
                                           label="Deposit"
                                           field="deposit"
                                           values={{
                                                  value:entryData.deposit,
                                                  enabled:(!entryData.autoDeposit)
                                           }}
                                           rules={{required:!entryData.autoDeposit}}
                                           onChange={this.props.onChange}
                                           />
                                  </div>
                                  <div className="segment-input-checkbox">
                                      <div className="segment-input-checkbox-text">AUTO</div>
                                      <Checkbox style={{margin:0}}
                                                label="autoDeposit"
                                                values={{value:entryData.autoDeposit,enabled:combinedPermissions.price}}
                                                onChange={this.onChangeAuto}
                                                />
                                  </div>
                              </div>
                          </div>

                          <div style={{display:"inline-block",verticalAlign:"top",margin:"20px"}}>
                              <b>Stamp Duty</b>
                              <div className="segment-full-item">
                                  <div className="segment-input-checkboxable">
                                      <Box ref="stampDuty"
                                           label="Stamp Duty"
                                           field="stampDuty"
                                           values={{
                                                  value:entryData.stampDuty,
                                                  enabled:(!entryData.autoStampDuty)
                                              }}
                                           rules={{required:!entryData.autoStampDuty}}
                                           onChange={this.props.onChange}
                                           />
                                  </div>
                                  <div className="segment-input-checkbox">
                                      <div className="segment-input-checkbox-text">AUTO</div>
                                      <Checkbox style={{margin:0}}
                                                label="autoStampDuty"
                                                values={{value:entryData.autoStampDuty,enabled:combinedPermissions.price}}
                                                onChange={this.onChangeAuto}
                                                />
                                  </div>
                              </div>
                          </div>
                      </div>

                      <div className="segment-buttons">
                          <div className="segment-error">{this.state.error}</div>
                          <Button type="medium" onClick={this.props.onBack}>Back</Button>
                          <Button type="medium" onClick={this.onValidateForm}>Create</Button>
                      </div>
                  </div>
              </Step>

              <Step active={this.state.step == "line"} scroll={true}>
                    <div className="segment-content">
                        <h3>Add New Line</h3>
                        <div className="segment-full-item" style={{zIndex:3}}>
                            <div className="segment-item-label">Line Name</div>
                            <div className="segment-item-input">
                                <Box ref="name" label="Line Name" field="name" values={{value:newLine.name}} rules={{required:true,list:preApprovedOptions}} onChange={this.onChangeNewLine}/>
                            </div>
                        </div>
                        <div className="segment-full-item" style={{zIndex:2}}>
                            <div className="segment-item-label">Tax Type <Info style={infoInput} title="Is this item subjected to any of the following tax types?"/></div>
                            <div className="segment-item-input">
                                <Select ref="taxType" values={{value:newLine.taxType,options:options.taxType,enabled:!(this.state.preApprovedNewLine)}} label="Tax Type" field="taxType" onChange={this.onChangeNewLine}/>
                            </div>
                        </div>
                        <div className="segment-full-item" style={{zIndex:2}}>
                            <div className="segment-item-label">Deposit <Info style={infoInput} title="Include this item into automatic deposit calculation?"/></div>
                            <div className="segment-item-input"><Select ref="deposit" values={{value:newLine.deposit,options:options.yesNo,enabled:!(this.state.preApprovedNewLine)}} label="Deposit" field="deposit" onChange={this.onChangeNewLine}/></div>
                        </div>
                        <div className="segment-full-item" style={{zIndex:2}}>
                            <div className="segment-item-label">Rental <Info style={infoInput} title="Should this item be added into the rental price analytics?"/></div>
                            <div className="segment-item-input">
                                <Select ref="rental" label="Rental" field="rental" values={{value:newLine.rental, options:options.yesNo, enabled:!(this.state.preApprovedNewLine)}} onChange={this.onChangeNewLine}/>
                            </div>
                        </div>
                        <div className="segment-buttons" style={{zIndex:1}}>
                            <div className="segment-error">{this.state.error}</div>
                            <Button type="medium" onClick={()=>{this.setState({step:"form",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onValidateNewLine}>Create</Button>
                        </div>
                    </div>
              </Step>

              <Step active={this.state.step == "section"} scroll={true}>
                  <div className="segment-content">
                      <h3>Add New Section</h3>
                      <div className="segment-full-item" style={{zIndex:3}}>
                          <div className="segment-item-label">Section Name</div>
                          <div className="segment-item-input">
                              <Box ref="header" label="Section Name" field="header" values={{value:newSection.header}} rules={{required:true,list:preApprovedSectionOptions}} onChange={this.onChangeNewSection}/>
                          </div>
                      </div>
                      <div className="segment-full-item" style={{zIndex:2}}>
                          <div className="segment-item-label">Monthly Recurring</div>
                          <div className="segment-item-input">
                              <Select ref="monthlyRecurring" values={{value:newSection.monthlyRecurring,options:options.yesNo,enabled:!(this.state.preApprovedNewSection)}} label="Monthly Recurring" field="monthlyRecurring" onChange={this.onChangeNewSection}/>
                          </div>
                      </div>
                      <div className="segment-buttons" style={{zIndex:1}}>
                          <div className="segment-error">{this.state.error}</div>
                          <Button type="medium" onClick={()=>{this.setState({step:"form",error:""})}}>Back</Button>
                          <Button type="medium" onClick={this.onValidateNewSection}>Create</Button>
                      </div>
                  </div>
              </Step>
          </div>
        )
    }
}

export class CheckAvailability extends React.Component{

    state={
        loading:true,
        loadText:"",
        content:{
            quotations:0,
            bookings:0,
            agreements:0,
        },
    }

    componentDidMount=()=>{
        this.checkAvailability();
    }

    checkAvailability=()=>{
        this.setState({loading:true,loadText:"Checking Availability..."});
        u.post({
            url:"/api/check-estimated-occupancy",
            data:{
                startDate:this.props.startDate,
                endDate:this.props.endDate,
                exclude:this.props.exclude,
            },
            success:(content)=>{
                this.setState({content:content,loading:false,error:""});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    render=()=>{
        const content=this.state.content;
        return(
            <div className='check-availability'>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div>
                    <h3>Estimated Occupancy</h3>
                    <div style={{margin:"20px auto"}}>Period of {moment(this.props.startDate).format("DD MMM YYYY")} to {moment(this.props.endDate).format("DD MMM YYYY")}</div>

                    {
                        (this.props.estimate)
                            ?
                            <div style={{margin:"20px auto"}}>
                                <b>Requirement for this agreement: <span className="text-emphasis">{this.props.estimate} Beds</span></b>
                            </div>
                            :
                            null
                    }

                    <div className="list-table" style={{fontSize:"12px",maxHeight:"none"}}>
                        <table>
                            <thead>
                                <tr>
                                    <th className="left">Type</th>
                                    <th className="right">Estimated Occupancy</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Quotations</td>
                                    <td>{u.integerComma(content.quotations)} Beds</td>
                                </tr>
                                <tr>
                                    <td>Bookings</td>
                                    <td>{u.integerComma(content.bookings)} Beds</td>
                                </tr>
                                <tr>
                                    <td>Agreements</td>
                                    <td>{u.integerComma(content.agreements)} Beds</td>
                                </tr>
                                <tr>
                                    <td>Quotations + Bookings</td>
                                    <td>{u.integerComma(content.quotations + content.bookings)} Beds</td>
                                </tr>
                                <tr>
                                    <td>Bookings + Agreements</td>
                                    <td>{u.integerComma(content.bookings + content.agreements)} Beds</td>
                                </tr>
                                <tr>
                                    <td>Quotations + Bookings + Agreements</td>
                                    <td>{u.integerComma(content.quotations+content.bookings+content.agreements)} Beds</td>
                                </tr>
                            </tbody>
                        </table>

                        {
                            (this.props.exclude.length > 0)
                                ?
                                <div className="text-negative" style={{margin:"20px auto"}}>
                                    Agreements that are renewed as part of this agreement are not calculated in the above
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
CheckAvailability.propTypes={
    startDate:PropTypes.string.isRequired,
    endDate:PropTypes.string.isRequired,
    estimate:PropTypes.string,
    exclude:PropTypes.array,
};
