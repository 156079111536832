import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,Segment,LoadGraphic,Step,DynamicListArea,BulkEdit,DownloadTemplate} from '../components/Common';
import {EditSizeLabel,EditSizeLink,EditableBox,Select,Button,Date,Box,Info,TextButton,MiniMinusButton} from '../components/Input';
import {} from '../data/Options';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';

export default class PriceList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Pricing",
        permission:"combined",  //Accepts combined permission module as well
        combinedField:"price",     //Used when permission exists in a combined field
        url:"/price-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/add-price-entry",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-price-entries",
        spreadsheetURL:"/api/generate-price-spreadsheet",
        overviewURL:"/api/get-all-price-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"price-list",
        orderByField:"header",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddPriceEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        configureSettings:{component:ConfigureSettings,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
    };

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","priceType","taxType","remarkType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("combined");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add",mobileLabel:"Add Entry",ref:"add",image:"/images/page-menu/add.png",title:"Add new price entry",enabled:true},
                {label:"Delete",mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entry",enabled:true},
                {label:"Spreadsheet",mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit price details",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Configure Deposit",mobileLabel:"Configure Settings",ref:"configureSettings",image:"/images/page-menu/edit.png",title:"Configure settings",enabled:true,padding:"3px"},
            ],
            permissions:permissions,
        });
        this.getOptions();
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <PriceItem key={index}
                                              index={index}
                                              content={content}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              options={this.state.options}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class PriceItem extends React.Component{
    state={
        step:"view",
        error:"",
        lineItem:{},
        loading:false,
        loadText:"",
    };

    onCreateLine=()=>{
        let defaultLineItem = {
            priceList:[""],
            remarkList:[""],
        };
        this.settings={
            editType:"add",
        }
        this.setState({lineItem:defaultLineItem,step:"configure-line",error:""});
    }

    //Updating state from entry data in child component
    onChangeLine=(content)=>{
        this.setState({lineItem:content});
    }

    onValidated=()=>{
        this.setState({loading:true,loadText:"Adding line item..."});

        let lineItem = this.state.lineItem;
        const settings = this.settings;
        let content = this.props.content;

        if(lineItem.priceList[0] == ""){
            lineItem.priceList = [];
        }
        if(lineItem.remarkList[0] == ""){
            lineItem.remarkList = [];
        }

        u.post({
            url:"/api/update-price-table",
            data:{
                priceID:this.props.content.priceID,
                type:settings.editType,
                newEntry:lineItem,
                oldEntry:(settings.editType == "edit") ? content.items[settings.index] : null,
            },
            success:()=>{
                if(settings.editType == "add"){
                    content.items.push(lineItem);
                }
                else if(settings.editType == "edit"){
                    content.items[settings.index] = lineItem;
                }
                this.props.onChange(content.items,"items",this.props.index);
                this.setState({step:"view",loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onUpdateLine=(index)=>{
        this.settings={
            editType:"edit",
            index:index
        };

        const content = this.props.content;
        let lineItem = Object.assign({},content.items[index]);

        if(lineItem.priceList.length == 0){
            lineItem.priceList = [""];
        }
        if(lineItem.remarkList.length == 0){
            lineItem.remarkList = [""];
        }
        this.setState({lineItem:lineItem,step:"configure-line",error:""});
    }

    onRemoveLine=(index)=>{
        this.settings={
            editType:"remove",
            index:index,
        };
        this.setState({step:"remove-confirm",error:"",lineItem:this.props.content.items[index]});
    }

    onRemoveConfirm=()=>{
        this.setState({loading:true,loadText:"Removing line item..."});
        const settings = this.settings;
        u.post({
            url:"/api/update-price-table",
            data:{
                priceID:this.props.content.priceID,
                type:"remove",
                oldEntry:this.state.lineItem
            },
            success:()=>{
                let content = this.props.content;
                content.items.splice(settings.index,1);
                this.props.onChange(content.items,"items",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancelLine=()=>{
        this.setState({step:"view",error:""});
    }

    onSwapLine=(dragTarget,dropTarget)=>{
        this.setState({loading:true,loadText:"Re-ordering..."});
        let newOrder = this.props.content.items.slice(0);
        newOrder.splice(dropTarget,0,newOrder.splice(dragTarget,1)[0]);

        u.post({
            url:"/api/reorder-price-table",
            data:{
               id:this.props.content.priceID,
               field:"items",
               oldValue:this.props.content.items,
               newValue:newOrder
            },
            success:()=>{
                let content = this.props.content;
                content.items = newOrder;
                this.props.onChange(content.items,"items",this.props.index);
                this.setState({loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    render=()=>{
        const content = this.props.content;
        const options = this.props.options;
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"7px"};

        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>
                <Step active={this.state.step == "view"}>
                    <DynamicListArea content={this.props.content} onChange={this.props.onChange} index={this.props.index} listLabel={"price-list"} permission="combined" combinedField="price"/>

                    <div className="list-segment-table">
                        <div>
                            <h4>Items <Info style={infoPlaceholder} title="Items will be pre-populated into price table when creating at agreement"/></h4>
                        </div>
                        {
                            (content.items.length > 0)
                              ?
                              <div>
                                  <PriceTable content={content.items}
                                              onRemove={this.onRemoveLine}
                                              onUpdate={this.onUpdateLine}
                                              onSwap={this.onSwapLine}
                                              />
                                  <div style={{fontSize:"0.8em"}}>
                                      <TextButton onClick={()=>{this.onCreateLine()}}>Add Item</TextButton>
                                  </div>
                              </div>
                              :
                              <div className="segment-placeholder">
                                  No Items
                                  <div style={{marginTop:"5px"}}>
                                      <TextButton onClick={()=>{this.onCreateLine()}}>Add Item</TextButton>
                                  </div>
                              </div>
                        }
                    </div>
                </Step>

                <Step active={this.state.step == "configure-line"}>
                    <div className="list-segment-table">
                        <ConfigureLineItem content={this.state.lineItem}
                                           options={options}
                                           onChange={this.onChangeLine}
                                           onValidated={this.onValidated}
                                           onCancel={this.onCancelLine}/>
                    </div>
                </Step>

                <Step active={this.state.step == "remove-confirm"}>
                    <div style={{padding:"50px",textAlign:"center"}}>
                        <h2 className="text-negative">Remove Line Item - {this.state.lineItem.name}?</h2>
                        <div className="list-segment-note">Note. This action will not affect existing agreements</div>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onRemoveConfirm}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddPriceEntry extends React.Component{

    state={
        additional:{},
        dormOptions:[],
        entryData:{
          items:[],
        },
        options:{},
        step:"add",
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
        this.getDormOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","priceType","taxType","remarkType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
              type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onValidate=()=>{
        const keys=["dormID","header","monthlyRecurring","allowUnapprovedItem"];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }
        data.items = this.state.entryData.items;
        this.refs[this.props.reference].onValidated(data);
    }

    onChange=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    /************ Line Functions ****************/
    onCreateLine=()=>{
        let defaultLineItem = {
            priceList:[""],
            remarkList:[""]
        };
        this.settings={
            editType:"add",
        }
        this.setState({lineItem:defaultLineItem,step:"configure-line",error:""});
    }

    onChangeLine=(content)=>{
        this.setState({lineItem:content});
    }

    onCancelLine=()=>{
        this.setState({error:"",step:"add"});
    }

    onAddLine=()=>{
        let lineItem = this.state.lineItem;
        const settings = this.settings;
        let entryData = this.state.entryData;

        if(lineItem.priceList[0] == ""){
            lineItem.priceList = [];
        }
        if(lineItem.remarkList[0] == ""){
            lineItem.remarkList = [];
        }

        if(settings.editType == "add"){
            entryData.items.push(lineItem);
        }
        else if(settings.editType == "edit"){
            entryData.items[settings.index] = lineItem;
        }

        this.setState({error:"",step:"add",entryData:entryData});
    }

    onRemoveLine=(index)=>{
        let entryData = this.state.entryData;
        entryData.items.splice(index,1);
        this.setState({entryData:entryData});
    }

    onUpdateLine=(index)=>{
        this.settings={
            editType:"edit",
            index:index
        };

        const entryData = this.state.entryData;
        let lineItem = Object.assign({},entryData.items[index]);
        if(lineItem.priceList.length == 0){
            lineItem.priceList = [""];
        }
        if(lineItem.remarkList.length == 0){
            lineItem.remarkList = [""];
        }
        this.setState({lineItem:lineItem,step:"configure-line",error:""});
    }

    onSwapLine=(dragTarget,dropTarget)=>{
        let entryData = this.state.entryData;
        entryData.items.splice(dropTarget,0,entryData.items.splice(dragTarget,1)[0]);
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const rentalItem = this.state.rentalItem;
        const options = this.state.options;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"9px"};

        return(
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         filetypes={Spreadsheet}
                         additional={this.state.additional}
                         onToggleForm={this.props.onToggleForm}>

                <div className="segment-content">
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <Step active={this.state.step == "add"}>
                            <div>
                                <h3>Complete the following to continue</h3>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Dormitory</div>
                                    <div className="segment-item-input"><Select ref="dormID" values={{value:entryData.dormID,options:this.state.dormOptions}} label="Dormitory" field="dormID" onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Section Header</div>
                                    <div className="segment-item-input"><Box ref="header" values={{value:entryData.header}} label="Header" field="header" rules={{required:true}} onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Monthly Recurring <Info style={infoInput} title="Is this section of items billed monthly or one time"/></div>
                                    <div className="segment-item-input"><Select ref="monthlyRecurring" values={{value:entryData.monthlyRecurring,options:options.yesNo}} label="Monthly Recurring" field="monthlyRecurring" onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Allow Unapproved <Info style={infoInput} title="Allow user to add items into this section that are not approved in the table below"/></div>
                                    <div className="segment-item-input"><Select ref="allowUnapprovedItem" values={{value:entryData.allowUnapprovedItem,options:options.yesNo}} label="Allow Unapprove Item" field="allowUnapprovedItem" onChange={this.onChange}/></div>
                                </div>

                                <div>
                                    <h4>Items <Info style={infoPlaceholder} title="Items will be pre-populated into the price form when creating an agreement"/></h4>
                                </div>

                                {
                                    (entryData.items.length > 0)
                                      ?
                                      <div>
                                          <PriceTable content={entryData.items}
                                                      onRemove={this.onRemoveLine}
                                                      onUpdate={this.onUpdateLine}
                                                      onSwap={this.onSwapLine}
                                                      />
                                          <div style={{fontSize:"0.8em"}}>
                                              <TextButton onClick={this.onCreateLine}>Add Item</TextButton>
                                          </div>
                                      </div>
                                      :
                                      <div className="segment-placeholder">
                                          No Items
                                          <div style={{marginTop:"5px"}}>
                                              <TextButton onClick={this.onCreateLine}>Add Item</TextButton>
                                          </div>
                                      </div>
                                }

                                <div className="segment-buttons">
                                    {
                                        (u.isMobileDevice())
                                            ?
                                            <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                            :
                                            null
                                    }
                                    <Button type="medium" onClick={this.onValidate}>Create</Button>
                                </div>
                            </div>
                        </Step>

                        <Step active={this.state.step == "configure-line"}>
                            <ConfigureLineItem content={this.state.lineItem}
                                               options={options}
                                               onChange={this.onChangeLine}
                                               onValidated={this.onAddLine}
                                               onCancel={this.onCancelLine}/>
                        </Step>
                    </div>
                </div>

            </AddListItem>
        )
    }
}

export class ConfigureLineItem extends React.Component{

    state={
        error:""
    };

    onChange=(value,field,index,option)=>{
        let content = this.props.content;
        if(field == "priceList"){
            content.priceList[index]=value;
        }
        else if(field == "remarkList"){
            content.remarkList[index]=value;
        }
        else{
            content[field] = value;
        }
        this.props.onChange(content);
    };

    onRemoveListItem=(list,index)=>{
        let content = this.props.content;
        content[list].splice(index,1);
        this.props.onChange(content);
    }

    onAddOption=(list)=>{
        this.setState({error:""});
        let content = this.props.content;
        content[list].push("");
        this.props.onChange(content);
    }

    onCancel=()=>{
        this.setState({error:""});
        this.props.onCancel();
    }

    onValidate=()=>{
        this.setState({error:""});
        const keys=["name","priceType","priceList","defaultValue","minimumValue","remarkType","remarkList","lockRemark","taxType","deposit"];
        const content = this.props.content;
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
            }
            if(keys[i] == "priceList" && content.priceType == "List Value"){
                for(var p=0; p<content.priceList.length; p++){
                    const priceListItem = this.refs["price-list-"+p].validate();
                    if(!priceListItem.valid){
                        this.setState({error:priceListItem.value});
                        return 0;
                    }
                }
            }
            if(keys[i] == "remarkList" && content.remarkType == "List Value"){
                for(var r=0; r<content.remarkList.length; r++){
                    const remarkListItem = this.refs['remark-list-'+r].validate();
                    if(!remarkListItem.valid){
                        this.setState({error:remarkListItem.value});
                        return 0;
                    }
                }
            }
        }
        this.props.onValidated();
    }

    priceTypeDesc="<b>List Value</b><br/>User may only choose from a list of preapprove prices<br/><br/>" +
                  "<b>Minimum Value</b><br/>User may enter any value that is equal or above the minimum value<br/><br/>" +
                  "<b>No Validation</b><br/>User may enter any value<br/><br/>" +
                  "<b>Super User</b><br/>User with Price Permission can override any validation during creation & update";

    remarkTypeDesc="<b>List Value</b><br/>User may only choose from a list of preapprove remarks<br/><br/>" +
                   "<b>No Validation</b><br/>User may enter any value<br/><br/>" +
                   "<b>Super User</b><br/>User with Price Permission can override any validation during creation & update";

    render=()=>{
        const options=this.props.options;
        const content = this.props.content;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"9px"};

        return(
            <div className="configure-line-item">
                <h3>Line Details</h3>

                <div className="segment-error">{this.state.error}</div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Line Name</div>
                    <div className="segment-item-input"><Box ref="name" values={{value:content.name}} label="Name" field="name" rules={{required:true}} onChange={this.onChange}/></div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Pricing Type <Info style={infoInput} title={this.priceTypeDesc}/></div>
                    <div className="segment-item-input"><Select ref="priceType" values={{value:content.priceType,options:options.priceType}} label="Price Type" field="priceType" onChange={this.onChange}/></div>
                </div>

                {
                  (content.priceType == "List Value")
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Price List</div>
                        <div className="segment-item-input">
                            {
                                content.priceList.map(
                                    (item,index)=>{
                                        return(
                                            <div className="segment-input-list-item" key={index}>
                                                <div className="segment-input-removable">
                                                    <Box ref={"price-list-"+index} values={{value:item}} label={"Price List Item " + (index+1)} field="priceList" index={index} rules={{required:true,isFloat:true,placeholder:"Enter List Value"}} onChange={this.onChange}/>
                                                </div>
                                                <div className="segment-input-remove"><MiniMinusButton enabled={(index != 0)} onClick={()=>{this.onRemoveListItem("priceList",index)}}/></div>
                                            </div>
                                        )
                                })
                            }
                            <div className="segment-input-textbutton">
                                <TextButton style={{margin:0}} onClick={()=>{this.onAddOption("priceList")}}>Add Option</TextButton>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }

                {
                  (content.priceType == "Minimum Value")
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Default Price</div>
                        <div className="segment-item-input"><Box ref="defaultValue" values={{value:content.defaultValue}} label="Default Value" field="defaultValue" rules={{required:true,isFloat:true,minValue:content.minimumValue}} onChange={this.onChange}/></div>
                    </div>
                    :
                    null
                }

                {
                  (content.priceType == "Minimum Value")
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Minimum Value <Info style={infoInput} title="User may choose any price value equal or greater than the minimum value"/></div>
                        <div className="segment-item-input"><Box ref="minimumValue" values={{value:content.minimumValue}} label="Minimum Value" field="minimumValue" rules={{required:true,isFloat:true}} onChange={this.onChange}/></div>
                    </div>
                    :
                    null
                }

                <div className="segment-full-item">
                    <div className="segment-item-label">Remarks Type <Info style={infoInput} title={this.remarkTypeDesc}/></div>
                    <div className="segment-item-input"><Select ref="remarkType" values={{value:content.remarkType,options:options.remarkType}} label="Remark Type" field="remarkType" onChange={this.onChange}/></div>
                </div>

                {
                  (content.remarkType == "List Value")
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Remarks List</div>
                        <div className="segment-item-input">
                            {
                                content.remarkList.map(
                                    (item,index)=>{
                                        return(
                                            <div className="segment-input-list-item" key={index}>
                                                <div className="segment-input-removable"><Box ref={"remark-list-"+index} values={{value:item}} label={"Remark List Item " + (index+1)} field="remarkList" index={index} rules={{required:true,placeholder:"Enter List Value"}} onChange={this.onChange}/></div>
                                                <div className="segment-input-remove"><MiniMinusButton enabled={(index != 0)} onClick={()=>{this.onRemoveListItem("remarkList",index)}}/></div>
                                            </div>
                                        )
                                })
                            }
                            <div className="segment-input-textbutton">
                                <TextButton style={{margin:0}} onClick={()=>{this.onAddOption("remarkList")}}>Add Option</TextButton>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }

                <div className="segment-full-item">
                    <div className="segment-item-label">Tax Type <Info style={infoInput} title="Is this item subjected to any of the following tax types?"/></div>
                    <div className="segment-item-input"><Select ref="taxType" values={{value:content.taxType,options:options.taxType}} label="Tax Type" field="taxType" onChange={this.onChange}/></div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Deposit <Info style={infoInput} title="Include this item into automatic deposit calculation?"/></div>
                    <div className="segment-item-input"><Select ref="deposit" values={{value:content.deposit,options:options.yesNo}} label="Deposit" field="deposit" onChange={this.onChange}/></div>
                </div>

                <div>
                  <div className="segment-full-item">
                      <div style={{width:"100%",height:"1px",background:"3b3b3c"}}/>
                  </div>
                </div>

                <h3>Controls</h3>
                <div className="segment-full-item">
                    <div className="segment-item-label">Rental Line <Info style={infoInput} title="Should this line item be added into your rental price analytics?"/></div>
                    <div className="segment-item-input"><Select ref="rental" values={{value:content.rental,options:options.yesNo}} label="Rental" field="rental" onChange={this.onChange}/></div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Required <Info style={infoInput} title="Is this line item compulsory, or is the user allowed to removed it from the agreement?"/></div>
                    <div className="segment-item-input"><Select ref="required" values={{value:content.required,options:options.yesNo}} label="Required" field="required" onChange={this.onChange}/></div>
                </div>

                <div className="segment-full-item">
                    <div className="segment-item-label">Lock Remark <Info style={infoInput} title="Lock Remark prevents users from updating this item's remark in agreement's price form"/></div>
                    <div className="segment-item-input"><Select ref="lockRemark" values={{value:content.lockRemark,options:options.yesNo}} label="Lock Remark" field="lockRemark" onChange={this.onChange}/></div>
                </div>

                <div className="segment-buttons">
                    <div className="segment-error">{this.state.error}</div>
                    <Button type="medium" onClick={this.onCancel}>Back</Button>
                    <Button type="medium" onClick={this.onValidate}>Add</Button>
                </div>
            </div>
        )
    }
}

class PriceTable extends React.Component{

    onRemoveLine=(index)=>{
        this.props.onRemove(index);
    }

    onUpdateLine=(index)=>{
        this.props.onUpdate(index);
    }

    onDragStart=(event,index)=>{
        event.dataTransfer.setData("text",index+"");
        event.currentTarget.id = "drag";
        this.dragIndex=index;
    }

    onDrop=(event,index)=>{
        const dragTarget = event.dataTransfer.getData("text");
        const dropTarget = index;

        event.currentTarget.id = "none";

        if(dragTarget != dropTarget){
            this.props.onSwap(dragTarget,dropTarget);
        }
    }

    onDragHover=(event,index,dragover)=>{
        event.stopPropagation();
        event.preventDefault();
        if(index == this.dragIndex){
            return 0;
        }
        if(dragover){
            //above or below
            if(this.dragIndex < index){
                event.currentTarget.id = "hover-down";
            }
            else{
                event.currentTarget.id = "hover-up";
            }
        }
        else{
            event.currentTarget.id = "none";
        }
    }

    onDragEnd=(event,index)=>{
        const drag = document.getElementById("drag");
        if(drag){
          drag.id = "none";
        }
    }

    render=()=>{
        const content = this.props.content;
        return (
            <div className="segment-table">
                <table>
                    <thead>
                        <tr>
                            <th className="segment-table-button left"/>
                            <th>Item</th>
                            <th style={{textAlign:"center"}}>Tax</th>
                            <th style={{textAlign:"center"}}>Deposit</th>
                            <th style={{textAlign:"center"}}>Rental</th>
                            <th style={{textAlign:"center"}}>Price</th>
                            <th style={{textAlign:"center"}}>Remark</th>
                            <th className="segment-table-button right"/>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            content.map(
                                (item,index)=>{
                                    return(
                                        <tr className="item" key={index}
                                            draggable={true}
                                            onDragStart={(event)=>{this.onDragStart(event,index)}} onDragEnd={(event)=>{this.onDragEnd(event,index)}}
                                            onDragOver={(event)=>{this.onDragHover(event,index,true)}} onDragLeave={(event)=>{this.onDragHover(event,index,false)}}
                                            onDrop={(event)=>{this.onDrop(event,index)}}
                                            >
                                            <td className="segment-table-button" style={{textAlign:"left"}}>
                                                <div style={{width:"15px",height:"15px"}}><MiniMinusButton onClick={()=>{this.onRemoveLine(index)}}/></div>
                                            </td>
                                            <td>{item.name}</td>
                                            <td style={{textAlign:"center"}}>{item.taxType}</td>
                                            <td style={{textAlign:"center"}}>{(item.deposit == "1") ? "Yes" : "No"}</td>
                                            <td style={{textAlign:"center"}}>{(item.rental == "1") ? "Yes" : "No"}</td>
                                            <td style={{textAlign:"center"}}>
                                            {
                                              (item.priceType == "No Validation")
                                                ?
                                                "Any"
                                                :
                                                (item.priceType == "Minimum Value")
                                                  ?
                                                  ">= $" + item.minimumValue
                                                  :
                                                  item.priceList.map(
                                                    (price,pindex)=>{
                                                        return <div key={pindex}>${price}</div>
                                                    }
                                                  )
                                            }
                                            </td>
                                            <td style={{textAlign:"center"}}>
                                            {
                                                (item.remarkType == "No Validation")
                                                  ?
                                                  "Any"
                                                  :
                                                  (item.remarkList.length == 0)
                                                    ?
                                                    "None"
                                                    :
                                                    item.remarkList.map(
                                                      (remark,rindex)=>{
                                                          return <div key={rindex}>{remark}</div>
                                                      }
                                                    )
                                            }
                                            </td>
                                            <td className="segment-table-button" style={{textAlign:"right"}}>
                                                <img src="/images/input/edit.png" title="Edit Line Item" onClick={()=>{this.onUpdateLine(index)}} className="segment-table-button-image" style={{width:"15px",height:"15px",marginTop:"2px"}}/>
                                            </td>
                                        </tr>
                                    )
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

class ConfigureSettings extends React.Component{

    state={
        active:false,
        content:[]
    }

    show=(show)=>{
        this.setState({active:show});
    }

    componentDidMount=()=>{
        this.getPriceConfig();
    }

    getPriceConfig=()=>{
        u.post({
            url:"/api/get-price-config",
            data:{},
            success:(content)=>{
                this.setState({content:content});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        let content = this.state.content;
        content[index.i].values[index.e].value=value;
        this.setState({content:content});
    }

    render=()=>{
        const content = this.state.content;
        const onChange = this.onChange;
        const url = "/api/update-price-config";

        return(
            <Segment active={this.state.active}>
                <div className="segment-title">Configure Price Settings</div>
                <div className="segment-error">{this.state.error}</div>
                {
                    content.map(
                        (item,index)=>{
                            return(
                                <div key={index} style={{textAlign:"center",marginBottom:"50px"}}>
                                    <b>{item.label}</b>
                                    <div className="segment-table">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="left">Dormitory</th>
                                                    <th className="right">{item.label} ({item.quantifier})</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    item.values.map(
                                                        (entry,e)=>{
                                                            return (
                                                              <tr className="item" key={e}>
                                                                  <td>{entry.dormName}</td>
                                                                  <td>
                                                                      <EditableBox label={item.label}
                                                                                   field={item.key}
                                                                                   values={{value:entry.value}}
                                                                                   index={{i:index,e:e}}
                                                                                   rules={{required:true,isFloat:true,minValue:0}}
                                                                                   id={entry.dormID}
                                                                                   url={url}
                                                                                   onChange={onChange}/>
                                                                  </td>
                                                              </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <div style={{maxWidth:"800px", fontSize:"0.8em",textAlign:"center",margin:"20px auto"}}>{item.description}</div>
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </Segment>
        )
    }
}
