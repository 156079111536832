import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,
        DynamicListArea,Dropzone,DownloadTemplate,LoadGraphic,Step,Segment,
        SegmentComplete,ResolutionStep} from '../components/Common';
import {Button,Select,Area,Box,EditSizeLabel,Date,Time,
        EmptyBox,EditSizeLink,TextButton,ContactNumber,
        Checkbox,InputPhoto,Info,Table} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Photo} from "../data/Mimetypes";
import {AddTask} from './TaskList';
import {Link} from 'react-router-dom';
import store from 'store';
import "../styles/feedback-list.scss";

export default class FeedbackList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Feedback",
        permission:"feedback",
        url:"/feedback-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-feedback-entry",
        deleteURL:"/api/delete-feedback-entries",
        spreadsheetURL:"/api/generate-feedback-spreadsheet",
        overviewURL:"/api/get-all-feedback-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"feedback-list",
        orderByField:"reportedOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddFeedback,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
        options:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("feedback");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Entry",ref:"add",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onDeleteStep=(success)=>{
        this.refs['layout'].onPopup({
          confirm:()=>{
              success();
              this.refs['layout'].onCancelPopup();
          },
          title:"Confirm delete step?",
          description:"Note. This action cannot be undone",
        });
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <FeedbackItem key={index}
                                              content={content}
                                              index={index}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              options={this.state.options}
                                              permissions={this.state.permissions}
                                              onDeleteStep={this.onDeleteStep}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class FeedbackItem extends React.Component{

    taskMaxHeight=120;

    state={
        step:"view",
        loading:false,
        loadText:"",
        showDeliverable:false,
    }

    onViewImage=(index,photoArray)=>{
        const image = this.props.content[photoArray][index];
        this.setState({
            step:"view-photo",
            photoArray:photoArray,
            selectedImage:image.url,
            selectedImageIndex:index,
            selectedImageName:image.filename,
        });
    }

    onUploadPhoto=(url)=>{
        this.setState({loading:true,loadText:"Processing...",error:""});
        u.post({
            url:"/api/add-feedback-photo",
            data:{
              feedbackID:this.props.content.feedbackID,
              field:"supportingPhoto",
              filename:url
            },
            success:()=>{
                let images=this.props.content.supportingPhoto;
                images.push({filename:this.state.filename,url:url});
                this.props.onChange(images,"supportingPhoto",this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDeletePhoto=()=>{
        this.setState({loading:true});
        u.post({
            url:"/api/delete-feedback-photo",
            data:{
                feedbackID:this.props.content.feedbackID,
                field:this.state.photoArray,
                imageURL:this.state.selectedImage,
                imageName:this.state.selectedImageName,
            },
            success:()=>{
                const field = this.state.photoArray;
                let images = this.props.content[field];
                images.splice(this.state.selectedImageIndex,1);
                this.props.onChange(images,field,this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onStatusChange=()=>{
        this.setState({error:""});
        let data={};
        this.setState({loading:true,loadText:"Updating..."});
        data.status=(this.props.content.status == "Pending" || this.props.content.status == "In Progress") ? "Completed" : "Verified";
        data.feedbackID=this.props.content.feedbackID;
        u.post({
            url:"/api/feedback-update-status",
            data:data,
            success:()=>{
                this.props.onChange(data.status,"status",this.props.index);
                if(data.status != "Pending"){
                    this.props.onChange(u.getUserID(),data.status.toLowerCase()+"ByID",this.props.index);
                    this.props.onChange(u.getFullName(),data.status.toLowerCase()+"ByName",this.props.index);
                }
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onChangeIntercept=(value,label,index)=>{
        if(label=="status"){
            if(value != "Pending"){
                this.props.onChange(u.getUserID(),value.toLowerCase()+"ByID",index);
                this.props.onChange(u.getFullName(),value.toLowerCase()+"ByName",index);
            }
        }
        this.props.onChange(value,label,index);
    }

    onViewReport=()=>{
        store.set("task-report",this.props.content);
        window.open("/task-report","_blank");
    }

    setDef=(value,type)=>{
        if(!value){
            return "None";
        }
        else if(type == "datetime"){
            return moment(value).format("DD MMM YYYY - h:mma");
        }
        else if(type == "date"){
            return moment(value).format("DD MMM YYYY");
        }
        else if(type == "time"){
            return moment("2000-01-01T" + value).format("h:mma");
        }
        else if(type == "dow"){
            const labels = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
            let dow = "";
            for(var i=0; i<value.length; i++){
                if(value[i]){
                    dow = dow + ((dow.length > 0) ? " / " : "") + labels[i];
                }
            }
            return dow;
        }

        return value;
    };

    onMarkDuplicate=()=>{
        this.setState({error:""});
        let data={};
        this.setState({loading:true,loadText:"Updating..."});
        u.post({
            url:"/api/feedback-update-status",
            data:{
                feedbackID:this.props.content.feedbackID,
                status:"Duplicate",
            },
            success:()=>{
                this.props.onChange("Duplicate","status",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onAddResolutionStep=()=>{
        if(this.state.adding){
            return 0;
        }
        this.setState({error:""});
        const newStep = this.refs['resolution-step'].validate();
        if(!newStep.valid){
            this.setState({error:newStep.value});
            return 0;
        }
        this.setState({adding:true});
        const content=this.props.content;
        u.post({
            url:"/api/add-resolution-step",
            data:{
                feedbackID:content.feedbackID,
                remark:newStep.value
            },
            success:(step)=>{
                let resolutionStack=content.resolutionStack;
                resolutionStack.unshift(step);
                this.props.onChange(resolutionStack,"resolutionStack",this.props.index);
                this.refs['resolution-step'].clear();
                this.setState({adding:false});
            },
            error:(error)=>{
                this.setState({error:error,adding:false});
            }
        })
    }

    onDeleteStep=(index)=>{
        const itemIndex=this.props.index;
        const stepIndex=index;

        this.props.onDeleteStep(()=>{
            const content=this.props.content;
            u.post({
                  url:"/api/delete-resolution-step",
                  data:{
                      feedbackID:content.feedbackID,
                      step:content.resolutionStack[stepIndex]
                  },
                  success:()=>{},
                  error:(error)=>{this.setState({error:error})}
              });
              let resolutionStack = content.resolutionStack;
              resolutionStack.splice(stepIndex,1);
              this.props.onChange(resolutionStack,"resolutionStack",itemIndex);
        });
    }

    onAddTask=(task)=>{
        console.log(task);
        let associatedTask = this.props.content.associatedTask;
        associatedTask.push(task);
        this.props.onChange(associatedTask,"associatedTask",this.props.index);
    }

    onLayout=(event)=>{
        console.log(event);
    }

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        return (
            <div style={{position:"relative",textAlign:'center'}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                    <DynamicListArea listLabel={"feedback-list"}
                                     permission="feedback"
                                     content={this.props.content}
                                     onChange={this.onChangeIntercept}
                                     index={this.props.index}
                                     />

                     {
                         (content.supportingPhoto.length > 0)
                             ?
                             <div className="list-segment extended" style={{textAlign:'center',margin:"30px auto"}}>
                                 <h3 style={{textAlign:"center"}}>Supporting Photos</h3>
                                 <div className="list-image-container">
                                 {
                                     content.supportingPhoto.map(
                                         (item,index)=>{
                                             return(
                                                 <div key={index} className="list-image-item">
                                                     <div className="list-image-flex"><img src={item.url} onClick={()=>{this.onViewImage(index,"supportingPhoto")}}/></div>
                                                 </div>
                                             )
                                         }
                                     )
                                 }
                                 </div>
                             </div>
                             :
                             null
                     }

                    <div className="list-table-container">
                        <h3>Associated Tasks</h3>
                        <Table content={content.associatedTask}
                               fields={
                                  [
                                      {field:"taskID",label:"Reference",type:"link",to:"/task-list?tags=@id(%(taskID)%)"},
                                      {field:"status",label:"Status"},
                                      {field:"assignedTo",label:"Assign To"},
                                      {field:"taskDescription",label:"Description"},
                                  ]
                               }
                               maxHeight={250}
                               emptyText={
                                 <div>
                                    <b style={{fontSize:"1.01em"}}>No Tasks Added</b>
                                    <div style={{margin:"5px auto"}}>Click add task below to associate a task to this entry</div>
                                 </div>
                               }
                               containerStyle={{width:"1200px"}}
                               />
                        {
                            (permissions.update && (content.status == "Pending" || content.status == "In Progress"))
                                ?
                                <div style={{margin:"20px auto"}}>
                                    <TextButton onClick={()=>{this.setState({step:"add-task",error:""})}}>Add Task</TextButton>
                                </div>
                                :
                                null
                        }
                    </div>


                    {
                        (permissions.update && (content.status == "Pending" || content.status == "In Progress" || content.status == "Completed"))
                            ?
                            <div className="list-segment-buttons">
                                <Button onClick={()=>{this.setState({step:"upload-photo",error:""})}}>Add Photo</Button>
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"status-change"})}}>
                                    {(content.status == "Pending" || content.status == "In Progress") ? "Set Complete" : "Set Verified"}
                                </Button>
                            </div>
                            :
                            null
                    }

                    <div className="list-segment-buttons">
                        {
                            (permissions.update)
                                ?
                                <TextButton onClick={()=>{this.setState({error:"",step:"duplicate"})}}>Mark Duplicate</TextButton>
                                :
                                null
                        }
                        <TextButton onClick={()=>{this.setState({step:"resolution",error:""})}}>Resolution Steps</TextButton>
                    </div>

                </Step>

                <Step active={this.state.step == "view-photo"}>
                    <div className="list-image-container">
                        <h2>Photo: {this.state.selectedImageName}</h2>
                        <img className="list-view-image" src={this.state.selectedImage} onClick={()=>{window.open(this.state.selectedImage,"_blank")}}/>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            {
                                (permissions.update)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"delete-photo"})}}>Delete</Button>
                                :
                                null
                            }

                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "upload-photo"}>
                    <div className="list-upload-photo">
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "delete-photo"}>
                    <div style={{padding:"30px 0 20px 0",textAlign:'center'}}>
                      <h2 className="text-negative">Confirm Delete Photo: {this.state.selectedImageName}</h2>
                      <div>Note. This action cannot be reversed</div>
                    </div>

                    <div className="segment-buttons">
                          <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                          <Button type="medium" onClick={this.onDeletePhoto}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "status-change"}>
                    <div style={{padding:"20px 0"}}>
                        <h2 className="text-emphasis">Declare Entry {(content.status == "Pending" || content.status == "In Progress") ? "Completed" : "Verified"}?</h2>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:'view',error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onStatusChange}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "duplicate"}>
                    <div style={{padding:"20px 0"}}>
                        <h2 className="text-emphasis">Declare Entry As Duplicate?</h2>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:'view',error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onMarkDuplicate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "resolution"}>
                    <h2>Resolution Steps</h2>

                    {
                        (content.resolutionStack.length > 0)
                            ?
                            <div className="resolution-step-container">
                                {
                                    content.resolutionStack.map(
                                        (item,index)=>{
                                            return (
                                                <ResolutionStep key={index}
                                                                content={item}
                                                                index={index}
                                                                onDelete={this.onDeleteStep}
                                                                />
                                            )
                                        }
                                    )
                                }
                            </div>
                            :
                            <div className="list-segment-placeholder" style={{padding:"50px 0",width:"600px"}}>
                                No Steps Documented
                            </div>
                    }

                    <div className="segment-full-item" style={{fontSize:"12px"}}>
                        <Area rules={{
                                required:true,
                                rows:5,
                                placeholder:"Enter a new resolution step"
                              }}
                              ref="resolution-step"
                              label="Resolution Step"
                              />
                    </div>

                    <div className="list-segment-buttons">
                        <div className="list-segment-error">{this.state.error}</div>
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onAddResolutionStep}>Add Step</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "add-task"}>
                    <div style={{position:"relative",fontSize:"14px"}}>
                        <AddTask reference="add"
                                 settings={{
                                    addType:0,
                                    addURL:"/api/add-feedback-task",
                                    onToggleForm:()=>{
                                        this.setState({step:"view",error:""})
                                    }
                                 }}
                                 inList={true}
                                 success="Successfully Created Associated Task Entry"
                                 onComplete={this.onAddTask}
                                 onToggleForm={()=>{
                                    this.setState({step:"view",error:""})
                                 }}
                                 feedback={content}
                                 />
                    </div>
                </Step>
            </div>
        )
    }
}

class AddFeedback extends React.Component{

    state={
      error:"",
      options:[],
      estateOptions:{
        dorm:[],
        cluster:[],
        building:[],
        level:[],
        room:[]
      },
      staffOptions:[],
      entryData:{
          supportingPhoto:[],
          deadlineDate:moment().add(1,"days").format("YYYY-MM-DD"),
          deadlineTime:moment().format("HH:00:00"),
      },
      loading:false,
      loadText:'',
      step:"add",
    };

    componentDidMount=()=>{
        this.getOptions();
        this.getEstateOptions("dorm");
        this.getStaffOptions();
        this.getFormOptions();
    }

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "dormID",
            "tenantFIN",
            "feedbackType",
            "assignedToID",
            "taskLocation",
            "locationDetailsText",
            "clusterID",
            "buildingID",
            "levelID",
            "roomID",
            "remarks",
        ];

        for(var i=0; i<values.length; i++){
            if(this.refs[values[i]]){
                const field = this.refs[values[i]].validate();
                if(!field.valid){
                    this.setState({error:field.value});
                    return 0;
                }
            }
        }

        let data = Object.assign({},this.state.entryData);
        if(data.taskLocation != "User Input"){
            data.locationDetails={
              clusterID:data.clusterID,
              buildingID:data.buildingID,
              levelID:data.levelID,
              roomID:data.roomID,
          };
        }
        data.deadline = data.deadlineDate + "T" + data.deadlineTime;

        delete data.clusterID;
        delete data.buildingID;
        delete data.levelID;
        delete data.roomID;

        this.refs['add'].onValidated(data);
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["feedbackType","taskLocation"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getEstateOptions=(type,id)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:type,
                id:id,
            },
            success:(estateOption)=>{
                let estateOptions = this.state.estateOptions;
                estateOptions[type]=estateOption;
                this.setState({estateOptions:estateOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getStaffOptions=()=>{
        u.post({
            url:"/api/get-staff-options",
            data:{
                permission:{
                    task:{view:2}
                }
            },
            success:(staffOptions)=>{
                staffOptions.unshift({value:"None",label:"None"});
                this.setState({staffOptions:staffOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getFormOptions=()=>{
        u.post({
            url:"/api/get-form-options",
            data:{},
            success:(formOptions)=>{
                this.setState({formOptions:formOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.setState({entryData:{
            supportingPhoto:[],
            deadlineDate:moment().add(1,"days").format("YYYY-MM-DD"),
            deadlineTime:moment().format("HH:00:00"),
        },error:""});
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onChangeEstate=(value,label)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;

        if(label == "clusterID"){
            this.getEstateOptions("building",value);
            entryData.buildingID="";
            entryData.levelID="";
            entryData.roomID="";
        }
        else if(label == "buildingID"){
            this.getEstateOptions("level",value);
            entryData.levelID="";
            entryData.roomID="";
        }
        else if(label == "levelID"){
            this.getEstateOptions("room",value);
            entryData.roomID="";
        }
        this.setState({entryData:entryData});
    }

    onChangeLocation=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        let estateOptions = this.state.estateOptions;
        estateOptions.cluster=[];
        estateOptions.building=[];
        estateOptions.level=[];
        estateOptions.room=[];
        entryData.clusterID="";
        entryData.buildingID="";
        entryData.levelID="";
        entryData.roomID="";
        this.setState({entryData:entryData,estateOptions:estateOptions});

        if(value != "User Input"){
            this.getEstateOptions("cluster",entryData.dormID);
        }
    }

    onPhotoUploaded=(url)=>{
        let entryData = this.state.entryData;
        entryData.supportingPhoto.push({filename:this.state.filename,url:url});
        this.setState({entryData:entryData,step:"add",error:"",loading:false});
    }

    onPhotoRemoved=(index)=>{
        let entryData=this.state.entryData;
        entryData.supportingPhoto.splice(index,1);
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData=this.state.entryData;
        const options=this.state.options;
        const location=this.state.estateOptions;
        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onCancel}
                         onToggleForm={this.props.onToggleForm}>
                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step == "add"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-error">{this.state.error}</div>

                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dormID" field="dormID" label="Dormitory" values={{value:entryData.dormID,options:location.dorm}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Resident FIN <Info title="NRIC/FIN of the resident who reported the issue"/></div>
                                <div className="segment-item-input">
                                    <Box ref="tenantFIN" field="tenantFIN" label="Resident FIN" values={{value:entryData.tenantFIN}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Feedback Type</div>
                                <div className="segment-item-input">
                                    <Select ref="feedbackType" field="feedbackType" label="Feedback Type" values={{value:entryData.feedbackType,options:options.feedbackType}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Assign To <Info title="This is the person that the feedback entry will be assigned to"/></div>
                                <div className="segment-item-input">
                                    <Select ref="assignedToID" field="assignedToID" label="Assign To" values={{value:entryData.assignedToID,options:this.state.staffOptions}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Location <Info title="Where is the issue location? Choose specific estates to tag the task for future seaching"/></div>
                                <div className="segment-item-input">
                                    <Select ref="taskLocation" field="taskLocation" label="Issue Location" values={{value:entryData.taskLocation,options:options.taskLocation}} rules={{required:true}} onChange={this.onChangeLocation}/>
                                    {
                                        (entryData.taskLocation == "User Input")
                                            ?
                                            <div>
                                                <Box ref="locationDetailsText" style={{margin:"5px 0"}} field="locationDetailsText" label="Issue Location" values={{value:entryData.locationDetailsText}} rules={{required:true}} onChange={this.onChange}/>
                                            </div>
                                            :
                                            <div>
                                                <Select ref="clusterID" style={{margin:"5px 0"}} label="Zone" field="clusterID" values={{value:entryData.clusterID,options:location.cluster}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                {
                                                  (entryData.taskLocation == "building" || entryData.taskLocation == "level" || entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="buildingID" style={{margin:"5px 0"}} label="Building" field="buildingID" values={{value:entryData.buildingID,options:location.building}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.taskLocation == "level" || entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="levelID" style={{margin:"5px 0"}} label="Level" field="levelID" values={{value:entryData.levelID,options:location.level}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="roomID" style={{margin:"5px 0"}} label="Room" field="roomID" values={{value:entryData.roomID,options:location.room}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                            </div>
                                    }
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Remarks <Info title="What is the feedback? Put detailed information of the issue that needs to be resolved"/></div>
                                <div className="segment-item-input">
                                    <Area ref="remarks" field="remarks" label="Remarks" values={{value:entryData.remarks}} rules={{required:true,rows:5}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Supporting Photo <Info title="Will the task or location be clearer with an image? Upload up to 5 images to help the user understand the task"/></div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="supportingPhoto" field="supportingPhoto" label="Supporting Photo" values={{value:entryData.supportingPhoto}} rules={{max:5}} onChange={this.onChange}
                                                onAdd={()=>{this.setState({step:"photo",error:""})}}
                                                onRemove={this.onPhotoRemoved}
                                    />
                                </div>
                            </div>

                            <div className="segment-buttons">
                                <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "photo"}>
                        <div className="segment-error">{this.state.error}</div>
                        <Dropzone label="add-task-dropzone"
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.setState({filename:filename,loading:true,loadText:"Uploading "+filename+"..."})}}
                                  onUploaded={this.onPhotoUploaded}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Photo}
                        />
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Back</Button>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}
