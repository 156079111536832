import React from 'react';
import AppLayout,{CPMiniList} from '../../components/PortalLayout';
import u from '../../utilities/Utilities';
import {Databox,PageMenu,UploadPhoto,LoadGraphic,
        DynamicDetailArea} from '../../components/Common';
import {AddAbsenceReason,MiniAbsenceReasonList} from './PortalAbsenceReason';
import {MonitorVitals,MonitorCovid} from '../Resident';
import '../../styles/resident.scss';
import moment from 'moment';

export default class Resident extends React.Component{

    tenantID=this.props.match.params.tenantID;

    state={
        error:"",
        permissions:{},
        content:{
            history:[],
            future:[],
            vitalsList:[],
            covidList:[],
            reportSickList:[],
            accessories:[],
        },
        pageMenuButtons:[],
        options:{},
        combinedPermissions:{},
    };

    settings={
        /**** Page Setup ****/
        title:"Resident",
        permission:"portal",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/resident",
        label:"cp-resident",
        hasNotebook:true,
    };

    componentDidMount=()=>{
        if(this.tenantID){
            const permissions = u.getPermissions(this.settings.permission);
            this.setState({
              pageMenuButtons:[
                  //{label:"Photo",ref:"photo",mobileLabel:"Upload Photo",image:"/images/page-menu/upload-photo.png",title:"Upload resident photo",enabled:(permissions.update==2),padding:"3px"},
                  {label:"Absence Reason",ref:"addAbsenceReason",title:"Set absence reason for this resident",enabled:(permissions.update==2),padding:"2px"},
              ],
              permissions:permissions,
              combinedPermissions:u.getPermissions("combined"),
            });
            this.getContent(this.tenantID);
            this.getOptions();
        }
        else {
            this.setState({error:"Resident Not Found"});
        }
    }

    getOptions=()=>{
      u.post({
        url:"/client/cp-get-options",
        data:{
            keys:[
                "yesNo",
            ],
        },
        success:(options)=>{
            this.setState({options:options});
        },
        error:(error)=>{
            this.setState({error:error});
        }
      })
    }

    getContent=(tenantID)=>{
        u.post({
            url:"/client/cp-get-tenant",
            data:{
                tenantID:tenantID,
            },
            success:(callback)=>{
                this.setState({content:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,onError)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    onAbsenceReason=()=>{
       const content=this.state.content;
       window.open("/portal/absence-reason-list?create="+content.nric,"_blank");
    }

    render=()=>{
        const content=this.state.content;
        const combinedPermissions=this.state.combinedPermissions;
        const pageComponents=(content.tenantID) ? {
            photo:{
                component:UploadPhoto,
                props:{
                  id:this.tenantID,
                  url:"/api/commit-tenant-avatar",
                  fieldname:"avatarLink",
                  onUpload:this.onContentChange
              }
            },
            addAbsenceReason:{
                component:AddAbsenceReason,
                props:{
                    dormID:content.dormID,
                    tenantFIN:content.nric,
                    reference:"addAbsenceReason",
                    onRefreshList:()=>{
                        if(this.refs['mini-absence-reason']){
                            this.refs['mini-absence-reason'].refresh();
                        }
                    },
                    onToggleForm:()=>{

                    }
                }
            },
        } : {};

        return (
            <AppLayout settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={pageComponents}
                          />

                <DynamicDetailArea settings={this.settings}
                                   content={content}
                                   onChange={this.onContentChange}/>

                <div className="no-print">

                    {
                        (combinedPermissions.accessory)
                            ?
                            <AccessoryOwner content={content.accessories}
                                            dormID={content.dormID}
                                            ownershipType="Resident"
                                            onChange={this.onContentChange}
                                            resident={content}
                                            enabled={false}
                                            />
                            :
                            null
                    }

                    <Databox active={true} style={{paddingLeft:"10px",paddingRight:"10px"}} title="Absence Reason">
                        <MiniAbsenceReasonList ref="mini-absence-reason"
                                               tags={["@residentID("+this.tenantID+")"]}
                                               pageLength={10}
                                               />
                    </Databox>

                    <Databox active={true} style={{paddingLeft:"10px",paddingRight:"10px"}} title="Misconduct">
                        <MiniMisconductList ref="mini-misconduct"
                                            tags={["@residentID("+this.tenantID+")"]}
                                            pageLength={10}
                                            noLink={true}
                                            />
                    </Databox>

                    <Databox active={true} title="Report Sick">
                        <MiniReportSickList ref="mini-report-sick"
                                            tags={["@residentID("+this.tenantID+")"]}
                                            pageLength={10}
                                            noLink={true}
                                            />
                    </Databox>

                    <Databox active={true} title="Vitals Records">
                        <MonitorVitals content={content} 
                                       onChange={this.onContentChange} 
                                       options={this.state.options}
                                       noUpdate={true}
                                       overrideURL={{
                                        generateFileURL:"/client/cp-download-tenant-vitals",
                                        downloadFileURL:"/client/cp-download-file",
                                       }}
                                       />
                    </Databox>

                    <Databox active={true} title="Covid Test Records">
                        <MonitorCovid content={content} 
                                      onChange={this.onContentChange} 
                                      options={this.state.options}
                                      noUpdate={true}
                                      overrideURL={{
                                        generateFileURL:"/client/cp-download-tenant-covid-list",
                                        downloadFileURL:"/client/cp-download-file",
                                      }}
                                      />
                    </Databox>

                    <Databox active={(content.history.length>0)} title="Room History">
                      <div className="tenant-client-history">
                          <table>
                            <tbody>
                                <tr>
                                    <th className='temp-list-remove'/>
                                    <th className="tch-period">Period</th>
                                    <th className="tch-client">Organization</th>
                                    <th className="tch-dorm">Location</th>
                                </tr>

                                {
                                    content.history.map(
                                        (item,index)=>{
                                            return  (
                                                <tr className="tch-item" key={index}>
                                                    <td className='temp-list-remove'/>
                                                    <td className="tch-period">{moment(item.dateStart).format("DD MMM YY") + " - " + ((item.dateEnd) ? moment(item.dateEnd).format("DD MMM YY") : "Present")}</td>
                                                    <td className="tch-client"><a href={"/client/"+item.clientID}>{item.clientName}</a></td>
                                                    <td className="tch-dorm">
                                                        {
                                                        (item.location.sickbayID)
                                                            ?
                                                            item.location.dormName + ((item.location.clusterName) ? " > Zone " + item.location.clusterName : "") + ((item.location.buildingName) ? " > Blk" + item.location.buildingName : "") + " > Sickbay " + item.location.sickbayName + "/" + item.location.sickbedName
                                                            :
                                                            item.location.dormName +" > Zone " + item.location.clusterName + " > Blk " + item.location.buildingName + " > #" + item.location.levelName + "-"  + item.location.roomName + "/" + item.location.bedName
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                          </tbody>
                      </table>
                      </div>
                    </Databox>

                    <Databox active={(content.future.length > 0)} title="Room Future">
                      <div className="tenant-client-history">
                        <table>
                            <tbody>
                                <tr>
                                    <th className='temp-list-remove'/>
                                    <th className="tch-period">Period</th>
                                    <th className="tch-client">Organization</th>
                                    <th className="tch-dorm">Location</th>
                                </tr>

                            {
                                content.future.map(
                                    (item,index)=>{
                                        return  (
                                            <tr className="tch-item" key={index}>
                                                <td className='temp-list-remove'/>
                                                <td className="tch-period">{moment(item.dateStart).format("DD MMM YY") + " - " + ((item.dateEnd) ? moment(item.dateEnd).format("DD MMM YY") : "Present")}</td>
                                                <td className="tch-client"><a href={"/client/"+item.clientID}>{item.clientName}</a> (<a href={"/agreement/"+item.agreementID}>{item.agreementReference}</a>)</td>
                                                <td className="tch-dorm">
                                                    {
                                                    (item.location.sickbayID)
                                                        ?
                                                        item.location.dormName + ((item.location.clusterName) ? " > Zone " + item.location.clusterName : "") + ((item.location.buildingName) ? " > Blk" + item.location.buildingName : "") + " > Sickbay " + item.location.sickbayName + "/" + item.location.sickbedName
                                                        :
                                                        item.location.dormName +" > Zone " + item.location.clusterName + " > Blk " + item.location.buildingName + " > #" + item.location.levelName + "-"  + item.location.roomName + "/" + item.location.bedName
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                )
                            }
                            </tbody>
                        </table>
                      </div>
                    </Databox>
                </div>
            </AppLayout>
        )
    }
}

class AccessoryOwner extends React.Component{

    state={
        error:"",
        loading:false,
        loadText:"",
        accessoryOptions:[],
        accessory:"",
        step:"view",
    }

    render=()=>{
        const content=this.props.content;
        const resident=(this.props.resident) ? this.props.resident : {};
        const estate=(this.props.estate) ? this.props.estate : {};
        return(
            <Databox active={true} align="center" title="Accessories">
                <div className="page-error">{this.state.error}</div>
                <LoadGraphic text={this.state.loadText} active={this.state.loading}/>
                <div className="accessories-component">
                    <div className="list-table-container">
                        {
                            (content.length > 0)
                                ?
                                <div className="list-table" style={{width:"98%"}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="remove left"/>
                                                <th>Accessory</th>
                                                <th>Quantity</th>
                                                <th>Issued On</th>
                                                <th>Expire On</th>
                                                <th className="right">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            content.map((item,index)=>{
                                                return (
                                                    <tr key={index} className="item">
                                                        <td>
                                                            <div style={{position:"relative",width:"20px",height:"20px"}}>
                                                                {index+1}.
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {item.accessoryLabel}
                                                        </td>
                                                        <td>
                                                            {item.quantity}
                                                        </td>
                                                        <td>
                                                            {moment(item.issued).format("DD MMM YYYY")}
                                                        </td>
                                                        <td>
                                                            {moment(item.expiry).format("DD MMM YYYY")}
                                                        </td>
                                                        <td>
                                                            {item.remarks}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="list-placeholder"><b>No Accessories Assigned</b></div>
                        }
                    </div>
                </div>
            </Databox>
        )
    }
}

class MiniMisconductList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Misconduct",
        permission:"portal",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/misconduct-list",
        spreadsheetURL:"/client/cp-generate-misconduct-spreadsheet",
        overviewURL:"/client/cp-get-all-misconduct-entries",

        /**** List Setup ****/
        listLabel:"misconduct-list",
        orderByField:"createdOn",
        orderByType:"descending",
        //detailsLink:"/absence-reason-list/", //Leave null if linking to list
    };

    refresh=()=>{
        this.refs['list'].refresh();
    }

    render=()=>{
        return(
            <CPMiniList ref="list"
                        settings={this.settings}
                        tags={this.props.tags}
                        pageLength={this.props.pageLength}
                        orderByField={this.props.orderByField}
                        orderByType={this.props.orderByType}
                        noLink={true}
                        />
        )
    }
}

class MiniReportSickList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Report Sick",
        permission:"portal",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/report-sick-list",
        spreadsheetURL:"/client/cp-generate-report-sick-spreadsheet",
        overviewURL:"/client/cp-get-all-report-sick-entries",

        /**** List Setup ****/
        listLabel:"report-sick-list",
        orderByField:"reportedOn",
        orderByType:"descending",
        //detailsLink:"/absence-reason-list/", //Leave null if linking to list
    };

    refresh=()=>{
        this.refs['list'].refresh();
    }

    render=()=>{
        return(
            <CPMiniList ref="list"
                        settings={this.settings}
                        tags={this.props.tags}
                        pageLength={this.props.pageLength}
                        orderByField={this.props.orderByField}
                        orderByType={this.props.orderByType}
                        noLink={true}
                        />
        )
    }
}