import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,DynamicListArea} from '../components/Common';
import {EditSizeLabel,EditableBox,EditableSelect,Box,Select,TextButton,Button,BoolSlider,Checkbox} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import {Spreadsheet} from '../data/Mimetypes';
import '../styles/permission-group-list.scss';
import '../styles/staff.scss';
import moment from 'moment';
import store from 'store';

export default class PermissionGroupList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Permission Group",
        permission:"permission",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/permission-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-permission-group",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-permission-group-entries",
        spreadsheetURL:"/api/generate-permission-group-spreadsheet",
        overviewURL:"/api/get-all-permission-groups",

        /**** List Setup ****/
        list:true,
        listLabel:"permission-list",
        orderByField:"groupName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        error:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("permission");
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add", mobileLabel:"Add Group",ref:"add",image:"/images/page-menu/add.png",title:"Add new group",enabled:(permissions.create==2)},
                {label:"Delete", mobileLabel:"Delete Group",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected group",enabled:(permissions.delete==2)},
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
            ]
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error">{this.state.error}</div>
                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}
                          />

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <PermissionGroupItem key={index} index={index} content={content} onChange={onChange} onContentChange={onContentChange} registerReference={registerRef} permissions={this.state.permissions}/>
                            )
                        }
                    }
                </OverviewList>
            </AppLayout>
        )
    }
}

class PermissionGroupItem extends React.Component{

    state={
        error:"",
    };

    onChangePermission=(value,key,module,onError,option)=>{
        this.setState({error:""});
        let allPermissions = this.props.content.permissions;
        if(typeof allPermissions == "undefined"){
            allPermissions = {};
        }
        if(typeof allPermissions[module] == "undefined"){
            allPermissions[module] = {};
        }
        allPermissions[module][key] = parseInt(value);

        if(value == 2){
            allPermissions[module].view = 2;
        }

        this.props.onChange(allPermissions,"permissions",this.props.index);
    };

    onChangeSlider=(value,index)=>{
        this.setState({error:""});
        const oldValue = (!value) ? 2 : 0;
        const newValue = (value) ? 2 : 0;
        u.post({
            url:"/api/edit-group-permissions",
            data:{
               oldValue:oldValue,
               newValue:newValue,
               id:this.props.content.groupID,
               field:index.itemKey,
               additionalData:index.moduleKey
            },
            success:()=>{
                this.onChangePermission(newValue,index.itemKey,index.moduleKey);
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    };

    render=()=>{
        const content = this.props.content;
        const edit = (this.props.permissions.update == 2);
        const url = "/api/update-permission-group-info";
        const index = this.props.index+""
        const id = {groupID:content.groupID,tenantID:content.tenantID};

        return (
            <div style={{textAlign:"center"}}>

                <DynamicListArea content={this.props.content} onChange={this.props.onChange} index={this.props.index} listLabel={"permission-list"} permission="permission"/>

                <div className="permissions-divider"/>
                <div>{this.state.error}</div>
                <div className="permissions-div">
                    <Permissions content={content} permissions={edit} url="edit-group-permissions" onChange={this.onChangePermission} type="slider" onChangeSlider={this.onChangeSlider} onError={(error)=>{this.setState({error:error})}}/>
                </div>

                {
                    (this.props.isSuperUser)
                        ?
                        <div>
                            <div className="permissions-divider"/>
                            <h3>Cargo Lift Access</h3>
                            {
                                this.state.cargoLiftOptions.map(
                                    (item,index)=>{
                                        return (
                                            <div key={index} style={{textAlign:"center",margin:"15px auto"}}>
                                                <div className="bold" style={{margin:"10px auto"}}>
                                                    <TextButton onClick={()=>{this.onSelectAll(index)}}>{item.block}</TextButton>
                                                </div>
                                                {
                                                    item.options.map(
                                                        (option,oindex)=>{
                                                            return <div key={oindex} style={{display:"inline-block",margin:"5px 7px"}}>
                                                                        <div><Checkbox values={{value:option.selected}} label="Cargo Lift Option" index={oindex+""} onChange={(value)=>{this.onChangeCargo(value,index,oindex)}}/></div>
                                                                        <div>{option.label}</div>
                                                                    </div>
                                                                }
                                                    )
                                                }
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>
                        :
                        ""
                }
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{
            permissions:{}
        },
    };


    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    onChangeEntry=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onAddEntry=()=>{
        this.setState({error:""});
        const inputs = ['groupName'];
        for(var i=0; i<inputs.length; i++){
            const current = this.refs[inputs[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        let permissionCheck = false;
        const permissions = this.state.entryData.permissions;
        for(var module in permissions){
            for(var item in permissions[module]){
                if(permissions[module][item] == 2){
                    permissionCheck = true;
                    break;
                }
            }
        }
        if(!permissionCheck){
            this.setState({error:"Please select at least 1 permission for this group"});
            return 0;
        }

        let entryData = this.state.entryData;

        this.refs[this.props.reference].onValidated(entryData);
    };

    onComplete=()=>{
        this.setState({
            error:"",
            entryData:{permissions:{}},
        });
    };

    onChangeSlider=(value,index)=>{
        let permissions = this.state.entryData.permissions;
        if(typeof permissions[index.moduleKey]=="undefined"){
            permissions[index.moduleKey]={};
        }
        if(typeof permissions[index.moduleKey][index.itemKey] == "undefined"){
            permissions[index.moduleKey][index.itemKey] = {};
        }
        permissions[index.moduleKey][index.itemKey] = (value) ? 2 : 0;
        if(value){
            permissions[index.moduleKey].view = 2;
        }
        this.onChangeEntry(permissions,"permissions");
    }

    render=()=>{
        const content=this.state.entryData;
        return (
            <AddListItem settings={this.props.settings}
                         ref={this.props.reference}
                         reference={this.props.reference}
                         onComplete={this.onComplete}
                         stepScroll={true}
                         >

                <div style={{textAlign:"center"}}>
                    <h3>Complete the following to continue</h3>
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content">
                        <div className="segment-margin-area">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Group Name:</div>
                                <div className="segment-item-input">
                                    <Box ref="groupName" label="Group Name" field="groupName" values={{value:content.groupName}} rules={{required:true}} onChange={this.onChangeEntry}/>
                                </div>
                            </div>
                        </div>

                        <div className="segment-margin-area permissions-div-add">
                             <Permissions content={content} type="slider" permission={true} url="None" onChangeSlider={this.onChangeSlider}/>
                        </div>

                        <div className="segment-margin-area">
                            {
                                (u.isMobileDevice())
                                ?
                                <Button type="medium" onClick={()=>{this.props.onToggleForm(this.props.reference,false)}}>Cancel</Button>
                                :
                                ""
                            }
                            <Button type="medium" onClick={this.onAddEntry}>Add Entry</Button>
                        </div>
                    </div>
                </div>
            </AddListItem>
        )
    }
}

export class Permissions extends React.Component{
    state={
        permissionTypes:[]
    };

    componentDidMount=()=>{
        this.setState({
            permissionTypes:store.get("permissionTypes")
        });
    }

    render=()=>{
        const content = this.props.content;
        const edit = (this.props.permissions && !this.props.noEdit);
        const editPermissionAPI = this.props.url;

        return(
            <div>
                {
                    this.state.permissionTypes.map(
                        (module, aspIndex)=> {
                            const asp = (typeof content.permissions[module.moduleKey] == "undefined") ? {} : content.permissions[module.moduleKey];
                            return (
                                <div className="permission-type" key={aspIndex}>
                                    <div className="permission-type-module">{module.module}</div>
                                    <div className="permission-type-content">
                                        {
                                            module.permissions.map(
                                                (item, ispIndex)=> {
                                                    const isp = (typeof asp[item.key] == "undefined") ? "0" : asp[item.key] + "";
                                                    return (
                                                        <div className="permission-type-item" key={ispIndex}>
                                                            <div className="permission-type-label">
                                                                <span className="permission-type-label-content">{item.label}</span>
                                                                <div className="permission-type-popup">
                                                                    {
                                                                        item.elaboration.map(
                                                                            (elab, elabIndex)=> {
                                                                                return (
                                                                                    <div key={elabIndex}
                                                                                         className="permission-type-popup-item">
                                                                                        <div className="permission-type-popup-header">{elab.header}</div>
                                                                                        <div className="permission-type-popup-input">{elab.text}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="permission-type-input">
                                                                <BoolSlider index={{moduleKey:module.moduleKey,itemKey:item.key}} value={(isp==2)} onChange={this.props.onChangeSlider} enabled={edit} onNoPermission={this.props.onError}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        )
    }
}
Permissions.propTypes={
    noEdit:PropTypes.bool,
    content:PropTypes.object.isRequired,
    permission:PropTypes.bool,
    url:PropTypes.string,
    type:PropTypes.string,
};
