import React from 'react';
import {Databox,LoadGraphic,Step} from '../components/Common';
import PublicPageLayout from '../components/PublicPageLayout';
import {Box,Button,TextButton} from '../components/Input';
import sha from 'sha1';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import '../styles/create-user.scss';

export default class Download extends React.Component{

    state={
        error:"",
        step:"success",
        loading:true,
        loadText:"Preparing download..."
    };

    filename = this.props.match.params.filename;

    filestamp = this.props.match.params.filestamp;

    scrollAnchor=u.getKey();

    componentDidMount=()=>{
        if(this.filename && this.filestamp){
            this.downloadFile();
        }
        else {
            this.setState({step:"fail",error:"File not found"});
        }
    }

    downloadFile=()=>{
        u.download(this.filename,this.filestamp,()=>{
            this.setState({loading:false});
        },(error)=>{
            this.setState({step:"fail",loading:false,error:error})
        });
    }

    render=()=>{
        return(
            <PublicPageLayout title="Reset Password" url="/reset-password">
                <div style={{position:"relative",textAlign:"center"}}>
                    <div className="create-container">
                        <div className="create-message-container">
                            <h2 className="create-message">Download File</h2>
                        </div>

                        <div style={{position:"relative",padding:"20px 0"}}>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <Step active={this.state.step == "success"}>
                                <h3>
                                    <img src="/images/input/confirm.png" style={{height:"60px",display:"inline-block",verticalAlign:"middle",margin:"0 10px"}}/>
                                    <span style={{display:"inline-block",verticalAlign:"middle",margin:"20px 10px 5px 10px"}}>File Download Started</span>
                                </h3>
                                <div className="disclaimer-text" style={{margin:"0 auto 20px auto"}}>
                                      <div>Note: If your download does not start after 5 seconds, please <TextButton onClick={this.downloadFile} style={{margin:0}}>click here</TextButton> to download</div>
                                </div>
                            </Step>

                            <Step active={this.state.step == "fail"}>
                                <h3 className="text-negative">
                                    <img src="/images/input/cancel.png" style={{height:"60px",display:"inline-block",verticalAlign:"middle",margin:"0 10px"}}/>
                                    <span style={{display:"inline-block",verticalAlign:"middle",margin:"20px 10px 5px 10px"}}>{this.state.error}</span>
                                </h3>
                                <div className="disclaimer-text" style={{margin:"0 auto 20px auto"}}>Note: If this problem persists, please contact your system administrator</div>
                            </Step>
                        </div>
                    </div>
                </div>
            </PublicPageLayout>
        )
    }
}
