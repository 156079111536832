import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList,{MiniList} from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,
        Segment,LoadGraphic,Step,DynamicListArea,BulkEdit,
        DownloadTemplate,Dropzone,SegmentComplete,ResolutionStep,
        ResolutionStack} from '../components/Common';
import {EditSizeLabel,EditSizeLink,EditableSelect,InputPhoto,
        EditableBox,Select,Button,Date,Box,Info,Area,List,
        TextButton,MiniMinusButton,EmptyBox,Checkbox} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet,Photo,Attachment,Report} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';
import "../styles/misconduct-list.scss";

export default class MisconductList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Misconduct",
        permission:"misconduct",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/misconduct-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/add-misconduct-entry",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-misconduct-entries",
        spreadsheetURL:"/api/generate-misconduct-spreadsheet",
        overviewURL:"/api/get-all-misconduct-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"misconduct-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddMisconductEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
        offenseOptions:[],
    };

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:[
                    "offenseStatus"
                ],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const offensePermission = u.getPermissions("combined");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add Entry",ref:"add",enabled:permissions.create},
                {label:"Delete Entries",ref:"delete",enabled:permissions.delete},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled},
                {label:"Configure Offenses",ref:"configureOffenses",enabled:offensePermission.offense,
                    onClick:()=>{window.open("/offense-list","_blank")}
                },
            ],
            permissions:permissions,
        });
        this.getOptions();
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onDeleteStep=(success)=>{
        this.refs['layout'].onPopup({
          confirm:()=>{
              success();
              this.refs['layout'].onCancelPopup();
          },
          title:"Confirm delete step?",
          description:"Note. This action cannot be undone",
        });
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}
                          />


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <MisconductItem key={index}
                                                index={index}
                                                content={content}
                                                onChange={onChange}
                                                onContentChange={onContentChange}
                                                registerReference={registerRef}
                                                options={this.state.options}
                                                settings={this.settings}
                                                permissions={this.state.permissions}
                                                onDeleteStep={this.onDeleteStep}
                                                />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

export class MiniMisconductList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Misconduct",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/misconduct-list",
        spreadsheetURL:"/api/generate-misconduct-spreadsheet",
        overviewURL:"/api/get-all-misconduct-entries",

        /**** List Setup ****/
        listLabel:"misconduct-list",
        orderByField:"createdOn",
        orderByType:"descending",
        //detailsLink:"/absence-reason-list/", //Leave null if linking to list
    };

    refresh=()=>{
        this.refs['list'].refresh();
    }

    render=()=>{
        return(
            <MiniList ref="list"
                      settings={this.settings}
                      tags={this.props.tags}
                      pageLength={this.props.pageLength}
                      orderByField={this.props.orderByField}
                      orderByType={this.props.orderByType}
            />
        )
    }
}

class MisconductItem extends React.Component{

    state={
        step:"view",
        error:"",
        loading:false,
        loadText:"",
        removeIndex:0,
        photoIndex:0,
        offenseTypeOptions:[],
        yesNoOptions:[{value:0,label:"No"},{value:1,label:"Yes"}],
    };

    onRemoveRequest=(index)=>{
        this.setState({removeIndex:index,step:"remove",error:""});
    }

    onRemoveConfirm=()=>{
        this.setState({loading:false,loadText:"Removing offense..."});
        const content=this.props.content;
        u.post({
            url:"/api/misconduct-remove-offense",
            data:{
                misconductID:content.misconductID,
                offense:content.offenses[this.state.removeIndex]
            },
            success:()=>{
                const offenses=content.offenses;
                offenses.splice(this.state.removeIndex,1);
                this.setState({step:"view",loading:false,removeIndex:0});
                this.props.onChange(offenses,"offenses",this.props.index);
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onAddOffenseRequest=()=>{
        this.setState({loading:true,loadText:"Getting offenses..."});
        u.post({
            url:"/api/get-offense-types",
            data:{
                dormID:this.props.content.dormID,
            },
            success:(offenseTypeOptions)=>{
                this.setState({
                  offenseTypeOptions:offenseTypeOptions,
                  loading:false,
                  error:"",
                  step:"add",
                });
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onAddOffense=()=>{
        this.setState({loading:true,loadText:"Adding offense..."});
        const offenseID = this.refs['offenseID'].validate();
        if(!offenseID.valid){
            this.setState({error:offenseID.value});
            return 0;
        }
        const content=this.props.content;
        u.post({
            url:"/api/misconduct-add-offense",
            data:{
                misconductID:content.misconductID,
                offenseID:offenseID.value,
            },
            success:(offenseEntry)=>{
                let offenses=content.offenses;
                offenses.push(offenseEntry);
                this.props.onChange(offenses,"offenses",this.props.index);
                this.setState({step:"view",loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onChangeOffense=(value,label,index)=>{
        let offenses=this.props.content.offenses;
        offenses[index][label]=value;
        this.props.onChange(offenses,"offenses",this.props.index);
    }

    onViewPhoto=()=>{

    }

    onFileDrop=(filename,file,ext)=>{
        ext = ext.toLowerCase();
        this.userFilename = filename;
        this.isAttachment = (ext != "jpg" && ext != "jpeg" && ext !="png");
        this.setState({loading:true,loadText:"Uploading..."});
    }

    onAddPhoto=(url)=>{
        this.setState({loading:true,loadText:"Committing..."});
        if(this.isAttachment){
          u.post({
              url:"/api/misconduct-add-attachment",
              data:{
                  misconductID:this.props.content.misconductID,
                  filename:url
              },
              success:(attachment)=>{
                  let attachments=this.props.content.attachments;
                  attachments.unshift(attachment);
                  this.props.onChange(attachments,"attachments",this.props.index);
                  this.setState({step:"view",loading:false,error:""});
              },
              error:(error)=>{
                  this.setState({error:error,loading:false});
              }
          });
        }
        else{
          u.post({
              url:"/api/misconduct-add-photo",
              data:{
                  misconductID:this.props.content.misconductID,
                  filename:url
              },
              success:()=>{
                  let photos=this.props.content.photos;
                  photos.push({filename:this.userFilename,url:url});
                  this.props.onChange(photos,"photos",this.props.index);
                  this.setState({step:"view",loading:false,error:""});
              },
              error:(error)=>{
                  this.setState({error:error,loading:false});
              }
          });
        }

    }

    onRemovePhoto=()=>{
        this.setState({loading:true,loadText:"Removing photo..."});
        const content=this.props.content;
        u.post({
            url:"/api/misconduct-remove-photo",
            data:{
                misconductID:content.misconductID,
                photo:content.photos[this.state.photoIndex],
            },
            success:()=>{
                let photos=content.photos;
                photos.splice(this.state.photoIndex,1);
                this.props.onChange(photos,"photos",this.props.index);
                this.setState({step:"view",loading:false,error:"",photoIndex:0});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onIncidentReport=()=>{
        let acknowledgement=this.refs['acknowledgement'].validate();
        let report = Object.assign({},this.props.content);
        report.acknowledgement=(acknowledgement.value==1);
        store.set("incident-report",report);
        window.open("/misconduct-incident-report","_blank");
    }

    onSendIncidentReport=()=>{
        this.setState({loading:true,loadText:"Sending report..."});
        u.post({
            url:"/api/misconduct-send-incident-report",
            data:{
                misconductID:this.props.content.misconductID,
            },
            success:()=>{
                this.setState({step:"sent",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onAddResolutionStep=()=>{
        if(this.state.adding){
            return 0;
        }
        this.setState({error:""});
        const newStep = this.refs['resolution-step'].validate();
        if(!newStep.valid){
            this.setState({error:newStep.value});
            return 0;
        }
        this.setState({adding:true});
        const content=this.props.content;
        u.post({
            url:"/api/add-misconduct-resolution-step",
            data:{
                misconductID:content.misconductID,
                remark:newStep.value
            },
            success:(step)=>{
                let resolutionStack=content.resolutionStack;
                resolutionStack.unshift(step);
                this.props.onChange(resolutionStack,"resolutionStack",this.props.index);
                this.refs['resolution-step'].clear();
                this.setState({adding:false});
            },
            error:(error)=>{
                this.setState({error:error,adding:false});
            }
        })
    }

    onDeleteStep=(index)=>{
        const itemIndex=this.props.index;
        const stepIndex=index;

        this.props.onDeleteStep(()=>{
            const content=this.props.content;
            u.post({
                  url:"/api/delete-misconduct-resolution-step",
                  data:{
                      misconductID:content.misconductID,
                      step:content.resolutionStack[stepIndex]
                  },
                  success:()=>{},
                  error:(error)=>{this.setState({error:error})}
              });
              let resolutionStack = content.resolutionStack;
              resolutionStack.splice(stepIndex,1);
              this.props.onChange(resolutionStack,"resolutionStack",itemIndex);
        });
    }

    onDeleteAttachmentRequest=(index)=>{
        this.setState({removeAttachment:index,step:"remove-attachment",error:""});
    }

    onDeleteAttachment=()=>{
        this.setState({loading:true,loadText:"Deleting Attachment..."});
        const content=this.props.content;
        u.post({
            url:"/api/misconduct-remove-attachment",
            data:{
                misconductID:content.misconductID,
                attachment:content.attachments[this.state.removeAttachment]
            },
            success:()=>{
                let content=this.props.content;
                content.attachments.splice(this.state.removeAttachment,1);
                this.props.onChange("attachments",content.attachments,this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDownloadAttachment=(filename,filestamp)=>{
       u.download(filename,filestamp);
    }

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        const options = this.props.options;
        const infoPlaceholder = {width:"15px",height:"15px",marginBottom:"7px"};
        const demerits = content.offenses.filter(
                            (item)=>{return item.status == "Active"}
                          ).reduce(
                            (acc,curr)=>{return acc + parseInt(curr.demerits)},
                            0
                          );
        const canEdit = (permissions.update && content.latestEntry);

        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step=="view"}>
                    <DynamicListArea content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     combinedField={this.props.settings.combinedField}
                                     />

                    <div className="list-table-container">
                        <h3>Reported Offenses ({demerits} Demerits)</h3>
                        {
                            (content.offenses.length > 0)
                                ?
                                <div className="list-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="remove"/>
                                                <th>Offense</th>
                                                <th>Penalty</th>
                                                <th style={{width:"100px",textAlign:"center"}}>Demerits</th>
                                                <th style={{width:"150px"}}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {
                                              content.offenses.map(
                                                  (item,index,arr)=>{
                                                      return(
                                                          <tr key={index} className="item">
                                                              <td className="remove">
                                                                  <div style={{position:"relative",width:"15px",height:"15px"}}>
                                                                      <MiniMinusButton onClick={()=>{this.onRemoveRequest(index)}}
                                                                                       enabled={canEdit}
                                                                                       />
                                                                  </div>
                                                              </td>
                                                              <td style={{textDecoration:((item.status == "Waived") ? "line-through" : undefined)}}>{item.label}</td>
                                                              <td style={{textDecoration:((item.status != "Active") ? "line-through" : undefined)}}>({item.occurrence}) {item.penalty}</td>
                                                              <td style={{textDecoration:((item.status != "Active") ? "line-through" : undefined),width:"100px",textAlign:"center"}}>{item.demerits}</td>
                                                              <td style={{width:"150px"}}>
                                                                  <EditableSelect values={{
                                                                                    value:item.status,
                                                                                    options:options.offenseStatus,
                                                                                    enabled:canEdit
                                                                                  }}
                                                                                  field="status"
                                                                                  id={{misconductID:content.misconductID,offense:item}}
                                                                                  url="/api/update-misconduct-info"
                                                                                  index={index}
                                                                                  label="Offense Status"
                                                                                  onChange={this.onChangeOffense}
                                                                                  />
                                                              </td>
                                                          </tr>
                                                      )
                                                  }
                                              )
                                          }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="list-placeholder">
                                    <h3>No Offenses</h3>
                                    <div>Click below to add offense</div>
                                </div>
                        }

                        {
                            (canEdit)
                                ?
                                <div style={{margin:"20px auto 30px auto"}}>
                                    <TextButton onClick={this.onAddOffenseRequest}>
                                        Add Offense
                                    </TextButton>
                                </div>
                                :
                                null
                        }
                    </div>

                    <div className="text-negative" style={{margin:"20px auto",fontSize:"14px",textAlign:"center"}}>
                        <b>Accumulated Demerits: {content.prevDemerit + demerits}</b>
                    </div>

                    <div className="list-image-container" style={{textAlign:"center"}}>
                        <h3>Supporting Photos</h3>
                        {
                            (content.photos.length > 0)
                              ?
                              content.photos.map((item,index)=>{
                                return(
                                    <img key={index} src={item.url} className="list-image-item" onClick={()=>{this.setState({step:"view-photo",photoIndex:index,error:""})}}/>
                                )
                            })
                              :
                              <div className="list-placeholder">
                                  <h3>No Photos</h3>
                                  <div>Photos are included into the misconduct report</div>
                              </div>
                        }
                    </div>

                    {
                      (content.attachments.length > 0)
                          ?
                          <div className="list-table-container">
                              <div className="list-table">
                                  <table>
                                      <thead>
                                          <tr>
                                              <th className="remove"/>
                                              <th>Attachment</th>
                                              <th className="attachment-short"/>
                                              <th className="attachment-short"/>
                                              <th className="attachment-download"/>
                                          </tr>
                                      </thead>
                                      <tbody>
                                        {
                                            content.attachments.map(
                                                (item,index,arr)=>{
                                                    return(
                                                        <tr key={index} className="item">
                                                            <td className="remove">
                                                                <div style={{position:"relative",width:"15px",height:"15px"}}>
                                                                    <MiniMinusButton onClick={()=>{this.onDeleteAttachmentRequest(index)}}
                                                                                     enabled={canEdit}
                                                                                     />
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {item.filename}
                                                            </td>
                                                            <td className="attachment-short">{item.staffName}</td>
                                                            <td className="attachment-short">{moment(item.dateTime).format("DD MMM YY hh:mma")}</td>
                                                            <td className="attachment-download">
                                                                <img className="misconduct-download"
                                                                     src="/images/input/down-arrow.png"
                                                                     onClick={()=>{this.onDownloadAttachment(item.filename,item.filestamp)}}
                                                                     />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                      </tbody>
                                  </table>
                              </div>
                          </div>
                          :
                          null
                    }

                    <div style={{margin:"20px auto",textAlign:"center"}}>
                        {
                            (canEdit)
                                ?
                                <TextButton onClick={()=>{this.setState({step:"add-photo",error:""})}}>Add Photo/Attachment</TextButton>
                                :
                                null
                        }
                        <TextButton onClick={()=>{this.setState({step:"resolution",error:""})}}>Resolution Steps</TextButton>
                    </div>

                    <div style={{margin:"20px auto",textAlign:"center"}}>
                        <Button onClick={()=>{this.setState({step:"view-report",error:""})}}>View Report</Button>
                        <Button onClick={()=>{this.setState({step:"send-report",error:""})}}>Send Report</Button>
                    </div>

                    <div style={{margin:"20px auto",textAlign:"center"}}>
                        <ResolutionStack content={content.participants}/>
                    </div>
                </Step>

                <Step active={this.state.step=="add"} scroll={true}>
                    <div style={{fontSize:"14px",textAlign:"center"}}>
                        <h3>Add Offense</h3>

                        <div className="segment-full-item" style={{margin:"20px auto"}}>
                            <div className="segment-item-label">Offense:</div>
                            <div className="segment-item-input">
                                <Select ref="offenseID"
                                        values={{options:this.state.offenseTypeOptions}}
                                        label="Offense"
                                        rules={{required:true}}
                                        />
                            </div>
                        </div>

                        <div className="segment-buttons">
                            <div className="page-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onAddOffense}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"} scroll={true}>
                    {
                        (content.offenses[this.state.removeIndex])
                            ?
                            <div style={{fontSize:"14px",textAlign:"center"}}>
                                <h3 className="text-negative">Remove Offense - {content.offenses[this.state.removeIndex].label}</h3>
                                <div className="list-segment-note">Note. This action cannot be undone</div>
                                <div className="segment-buttons">
                                    <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                    <Button onClick={this.onRemoveConfirm}>Confirm</Button>
                                </div>
                            </div>
                            :
                            null
                    }
                </Step>

                <Step active={this.state.step=="remove-attachment"} scroll={true}>
                    {
                        (content.attachments[this.state.removeAttachment])
                            ?
                            <div style={{fontSize:"14px",textAlign:"center"}}>
                                <h3 className="text-negative">Remove Attachment - {content.attachments[this.state.removeAttachment].filename}</h3>
                                <div className="list-segment-note">Note. This action cannot be undone</div>
                                <div className="segment-buttons">
                                    <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                    <Button onClick={this.onDeleteAttachment}>Confirm</Button>
                                </div>
                            </div>
                            :
                            null
                    }
                </Step>

                <Step active={this.state.step=="view-photo"} scroll={true}>
                    <div style={{padding:"20px 0",position:"relative",textAlign:"center"}}>
                        <div className="list-image-container">
                            {
                                content.photos.map((item,index)=>{
                                    return(
                                        <img key={index} src={item.url} className="list-image-item" onClick={()=>{this.setState({photoIndex:index,error:""})}}/>
                                    )
                                })
                            }
                        </div>

                        <div>
                            {
                                (content.photos.length > this.state.photoIndex)
                                    ?
                                    <img src={content.photos[this.state.photoIndex].url} className="list-view-image" onClick={()=>{window.open(content.photos[this.state.photoIndex].url,"_blank")}}/>
                                    :
                                    null
                            }
                        </div>

                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={()=>{this.setState({step:"remove-photo",error:""})}}>Delete Photo</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="add-photo"} scroll={true}>
                    <div style={{fontSize:"14px",textAlign:"center"}}>
                        <Dropzone label={"Photo"}
                                  accessibleForPreview={true}
                                  onAdded={this.onFileDrop}
                                  onUploaded={this.onAddPhoto}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Attachment}
                                  />

                        <div style={{margin:"20px auto"}}>Photos will be included into the misconduct report, whereas attachments (non-photos) will be attached when you Send Report</div>

                        <div style={{margin:"20px auto"}}>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="attachment"} scroll={true}>
                    <div style={{fontSize:"14px",textAlign:"center"}}>
                        <h3>Add Attachments</h3>
                        <div>Note. Attachments will not appear in the misconduct report, Attachments will be attached to the email when you use the send report function</div>
                        <Dropzone label={"Attachment"}
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.userFilename = filename; this.setState({loading:true,loadText:"Uploading..."})}}
                                  onUploaded={this.onAddAttachment}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Attachment}
                                  />

                        <div style={{margin:"20px auto"}}>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove-photo"} scroll={true}>
                    <div style={{fontSize:"14px",textAlign:"center"}}>
                        {
                            (content.photos.length > this.state.photoIndex)
                                ?
                                <h3 className="text-negative">Remove Photo - {content.photos[this.state.photoIndex].filename}?</h3>
                                :
                                null
                        }
                        <div className="list-segment-note">Note. This action cannot be undone</div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view-photo",error:""})}}>Back</Button>
                            <Button onClick={this.onRemovePhoto}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="send-report"} scroll={true}>
                    <div style={{padding:"30px 0",textAlign:"center",fontSize:"14px"}}>
                        <h3>Send Incident Report To Organization?</h3>
                        <div className="list-segment-note">Report will be sent to all client contact email marked as <b>Operations</b> in the address book</div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button onClick={this.onSendIncidentReport}>Send</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="sent"} scroll={true}>
                    <div style={{padding:"30px 0",textAlign:"center"}}>
                        <SegmentComplete onComplete={()=>{this.setState({step:"view",error:""})}}>
                            <h2>Incident Report Sent Successfully</h2>
                        </SegmentComplete>
                    </div>
                </Step>

                <Step active={this.state.step=="view-report"} scroll={true}>
                    <div style={{padding:"30px 0",textAlign:"center"}}>
                        <h2>Show Incident Report With Acknowledgment?</h2>
                        <div style={{margin:"20px auto",width:"400px",maxWidth:"96%"}}>
                            <Select ref="acknowledgement"
                                    values={{options:this.state.yesNoOptions}}
                                    label="Acknowledgement"
                                    />
                        </div>
                        <div className="list-segment-buttons">
                            <div className="list-segment-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onIncidentReport}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "resolution"} style={{textAlign:"center"}} scroll={true}>
                    <h2>Resolution Steps</h2>

                    {
                        (content.resolutionStack.length > 0)
                            ?
                            <div className="resolution-step-container">
                                {
                                    content.resolutionStack.map(
                                        (item,index)=>{
                                            return (
                                                <ResolutionStep key={index}
                                                                content={item}
                                                                index={index}
                                                                onDelete={this.onDeleteStep}
                                                                />
                                            )
                                        }
                                    )
                                }
                            </div>
                            :
                            <div className="list-segment-placeholder" style={{padding:"50px 0",width:"600px"}}>
                                No Steps Documented
                            </div>
                    }

                    <div className="segment-full-item" style={{fontSize:"12px"}}>
                        <Area rules={{
                                required:true,
                                rows:5,
                                placeholder:"Enter a new resolution step"
                              }}
                              ref="resolution-step"
                              label="Resolution Step"
                              />
                    </div>

                    <div className="list-segment-buttons">
                        <div className="list-segment-error">{this.state.error}</div>
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onAddResolutionStep}>Add Step</Button>
                    </div>
                </Step>

            </div>
        )
    }
}

class AddMisconductEntry extends React.Component{

    state={
        additional:{},
        dormOptions:[],
        estateOptions:{
            cluster:[],
            building:[],
            level:[],
            room:[],
        },
        offenseTypeOptions:[],
        entryData:{
            offenses:[],
            photos:[],
            attachments:[],
            resident:[],
        },
        options:{},
        tenantInfo:{},
        step:"resident",
        loading:false,
        loadText:"",
        location:{},
        residentList:[],
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getDormOptions();
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getEstateOptions=(type,parent)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:type,
                id:parent,
            },
            success:(options)=>{
                let estateOptions=this.state.estateOptions;
                estateOptions[type]=options;
                this.setState({estateOptions:estateOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        this.setState({
          step:"resident",
          error:"",
          entryData:{
            offenses:[],
            photos:[],
            attachments:[],
            resident:[],
          }
      });
        this.props.onToggleForm(this.props.reference,false);
    }

    onResident=()=>{
        const keys=['dormID','identifier'];
        let data={};
        for(var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }

        this.setState({loading:true,loadText:"Finding Resident..."});
        u.post({
            url:"/api/get-offense-types",
            data:{
                dormID:data.dormID,
            },
            success:(offenseTypeOptions)=>{
                this.setState({offenseTypeOptions:offenseTypeOptions});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });

        u.post({
            url:"/api/search-for-tenant",
            data:{
                dormID:data.dormID,
                searchParam:data.identifier,
                demerit:true,
            },
            success:(tenantInfo)=>{
                let entryData=this.state.entryData;
                entryData.dormID=tenantInfo.dormID;
                entryData.dormName=tenantInfo.dormName;
                entryData.resident=[{tenantID:tenantInfo.tenantID,tenantName:tenantInfo.tenantName,nric:tenantInfo.nric,demerit:tenantInfo.demerit}];

                this.setState({
                    loading:false,
                    entryData:entryData,
                    step:"details",
                    error:""
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onRoom=()=>{
      const keys=['dorm','cluster',"building","level","room"];
      let data={};
      for(var i=0; i<keys.length; i++){
          const current=this.refs[keys[i]].validate();
          if(!current.valid){
              this.setState({error:current.value});
              return 0;
          }
          data[keys[i]+"ID"]=current.value;
          data[keys[i]+"Name"]=current.option.label;
      }
      this.setState({loading:true,loadText:"Finding Residents..."});

      u.post({
          url:"/api/get-offense-types",
          data:{
              dormID:data.dormID,
          },
          success:(offenseTypeOptions)=>{
              this.setState({offenseTypeOptions:offenseTypeOptions});
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
      });

      u.post({
          url:"/api/misconduct-find-residents",
          data:data,
          success:(residentList)=>{
              let entryData=this.state.entryData;
              entryData.dormID=data.dormID;
              entryData.dormName=data.dormName;
              entryData.roomID=data.roomID;
              entryData.roomName=data.roomName;

              this.setState({
                  loading:false,
                  entryData:entryData,
                  residentList:residentList,
                  step:"resident-select",
                  error:""
              });
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
      });
    }

    onAddPhoto=(url)=>{
        let entryData=this.state.entryData;
        entryData.photos.push({filename:this.userFilename,url:url});
        this.setState({entryData:entryData,step:"details",error:"",loading:false});
    }

    onRemovePhoto=(index)=>{
       let entryData=this.state.entryData;
       entryData.photos.splice(index,1);
       this.setState({entryData:entryData});
    }

    onAddAttachment=(url)=>{
        let entryData=this.state.entryData;
        entryData.attachments.push({filename:this.userFilename,url:url});
        this.setState({entryData:entryData,step:"details",error:"",loading:false});
    }

    onRemoveAttachment=(index)=>{
       let entryData=this.state.entryData;
       entryData.attachments.splice(index,1);
       this.setState({entryData:entryData});
    }

    onAddOffense=()=>{
        const offense=this.refs['offense'].validate();
        if(!offense.valid){
            this.setState({error:offense.value});
            return 0;
        }
        let entryData=this.state.entryData;
        entryData.offenses.push(offense.option);
        this.setState({entryData:entryData,step:"details",error:"",loading:false});
    }

    onRemoveOffense=(index)=>{
        let entryData=this.state.entryData;
        entryData.offenses.splice(index,1);
        this.setState({entryData:entryData,error:""});
    }

    onValidate=()=>{
        this.setState({error:""});
        const keys=["offenses","photos","remarks","attachments"];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }

        data.resident=this.state.entryData.resident;
        data.dormID=this.state.entryData.dormID;

        this.refs[this.props.reference].onValidated(data);
    }

    onChange=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    onComplete=()=>{
        this.setState({
          step:"resident",
          error:"",
          entryData:{
            offenses:[],
            photos:[],
            attachments:[],
            resident:[],
          }
        });
    }

    getByRoom=()=>{
        this.getEstateOptions("cluster",this.state.entryData.dormID);
        this.setState({step:"room",error:""});
    }

    onChangeEstate=(value,type,index)=>{
        let location=this.state.location;
        let estateOptions=this.state.estateOptions;
        location[type]=value;

        const loading=[{value:"none",label:"Loading..."}];

        if(type=="dorm"){
            estateOptions.cluster=loading;
            estateOptions.building=loading;
            estateOptions.level=loading;
            estateOptions.room=loading;
            this.getEstateOptions("cluster",value);
        }
        if(type == "cluster"){
            estateOptions.building=loading;
            estateOptions.level=loading;
            estateOptions.room=loading;

            if(value != "none")
                this.getEstateOptions("building",value);
        }
        else if(type == "building"){
            estateOptions.level=loading;
            estateOptions.room=loading;

            if(value != "none")
                this.getEstateOptions("level",value);
        }
        else if(type == "level"){
            estateOptions.room=loading;

            if(value != "none")
                this.getEstateOptions("room",value);
        }

        this.setState({location:location,estateOptions:estateOptions});
    }

    onChangeSelect=(value,field,index)=>{
        let residentList=this.state.residentList;
        residentList[index].selected=value;
        this.setState({residentList:residentList});
    }

    onRoomSelected=()=>{
        this.setState({error:""});
        let selectedResident=this.state.residentList.filter(
            (item)=>{
                return item.selected
            }
        );
        if(!selectedResident.length > 0){
            this.setState({error:"Select at least 1 resident to continue"});
            return 0;
        }
        let entryData=this.state.entryData;
        entryData.resident=selectedResident.filter((item)=>{return item.tenantID});
        this.setState({
            entryData:entryData,
            step:"details",
            error:""
        });
    }

    onSelectAll=(value)=>{
        let residentList=this.state.residentList.map(
            (item)=>{
                item.selected=value;
                return item;
            }
        );
        this.setState({residentList:residentList});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"9px"};
        const location=this.state.location;
        const estateOptions=this.state.estateOptions;
        const residentList=this.state.residentList;

        return(
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         filetypes={Spreadsheet}
                         additional={this.state.additional}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         >

                <div className="segment-content" style={{position:"relative",textAlign:"center"}}>
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                    <Step active={this.state.step == "resident"}>
                        <div>
                            <h3>Enter NRIC, FIN or Work Permit No. to find resident</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dormID"
                                            values={{options:this.state.dormOptions}}
                                            label="Dormitory"
                                            />
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">NRIC/FIN/WP:</div>
                                <div className="segment-item-input">
                                    <Box ref="identifier"
                                         label="Identifier"
                                         rules={{required:true}}
                                         />
                                </div>
                            </div>

                            <div style={{margin:"20px auto 0 auto",fontSize:"14px"}}>
                                <TextButton onClick={()=>{this.setState({step:"room",error:""})}}>Create By Room</TextButton>
                            </div>

                            <div className="segment-buttons">
                                {
                                    (u.isMobileDevice())
                                        ?
                                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                        :
                                        null
                                }
                                <Button type="medium" onClick={this.onResident}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "room"}>
                        <div>
                            <h3>Select room to find resident</h3>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dorm"
                                            field="dorm"
                                            values={{
                                                value:location.dorm,
                                                options:this.state.dormOptions
                                            }}
                                            rules={{required:true}}
                                            label="Dormitory"
                                            onChange={this.onChangeEstate}
                                            />
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Zone</div>
                                <div className="segment-item-input">
                                    <Select ref="cluster"
                                            field="cluster"
                                            values={{
                                                value:location.cluster,
                                                options:estateOptions.cluster
                                            }}
                                            rules={{required:true}}
                                            label="Zone"
                                            onChange={this.onChangeEstate}
                                            />
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Building</div>
                                <div className="segment-item-input">
                                    <Select ref="building"
                                            field="building"
                                            values={{
                                                value:location.building,
                                                options:estateOptions.building
                                            }}
                                            rules={{required:true}}
                                            label="Building"
                                            onChange={this.onChangeEstate}
                                            />
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Level</div>
                                <div className="segment-item-input">
                                    <Select ref="level"
                                            field="level"
                                            values={{
                                                value:location.level,
                                                options:estateOptions.level
                                            }}
                                            rules={{required:true}}
                                            label="Level"
                                            onChange={this.onChangeEstate}
                                            />
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Room</div>
                                <div className="segment-item-input">
                                    <Select ref="room"
                                            field="room"
                                            values={{
                                                value:location.room,
                                                options:estateOptions.room
                                            }}
                                            rules={{required:true}}
                                            label="Room"
                                            onChange={this.onChangeEstate}
                                            />
                                </div>
                            </div>

                            <div style={{margin:"20px auto 0 auto",fontSize:"14px"}}>
                                <TextButton onClick={()=>{this.setState({step:"resident",error:""})}}>Create By FIN</TextButton>
                            </div>

                            <div className="segment-buttons">
                                {
                                    (u.isMobileDevice())
                                        ?
                                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                        :
                                        null
                                }
                                <Button type="medium" onClick={this.onRoom}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "resident-select"}>
                        <h3>Select Residents</h3>
                        {
                            (residentList.length > 0)
                                ?
                                <div className="list-table" style={{fontSize:"12px",maxHeight:"none"}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="remove">
                                                    <Checkbox values={{
                                                                  value:(residentList.filter(
                                                                            (item)=>{
                                                                              return item.selected
                                                                            }
                                                                        ).length == residentList.length)
                                                              }}
                                                              label="Select All"
                                                              onChange={this.onSelectAll}
                                                              />
                                                </th>
                                                <th style={{width:"120px",padding:"0 20px"}}>Demerit</th>
                                                <th>Resident</th>
                                                <th>FIN</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (residentList)
                                                    ?
                                                    residentList.map(
                                                        (item,index)=>{
                                                            return (
                                                               <tr key={index} className="item">
                                                                  <td className="remove">
                                                                      <Checkbox values={{
                                                                                  value:item.selected
                                                                                }}
                                                                                label="Selected"
                                                                                field={"selected"}
                                                                                index={index}
                                                                                onChange={this.onChangeSelect}
                                                                                />
                                                                  </td>
                                                                  <td style={{width:"120px",padding:"0 20px"}}>
                                                                      <span className={(item.demerit>0)?"text-negative":""}>{item.demerit} Pts</span>
                                                                  </td>
                                                                  <td>{item.tenantName}</td>
                                                                  <td>{item.nric}</td>
                                                                  <td>{item.status}</td>
                                                               </tr>
                                                            )
                                                        }
                                                    )
                                                    :
                                                    null
                                            }
                                        </tbody>
                                    </table>
                                    <div className="segment-buttons">
                                        <Button onClick={()=>{this.setState({step:"room",error:""})}}>Back</Button>
                                        <Button onClick={this.onRoomSelected}>Next</Button>
                                    </div>
                                </div>
                                :
                                <div className="list-placeholder">
                                    <b>No Occupants Registered</b>
                                    <div>Click back to select another room</div>
                                </div>
                        }
                    </Step>

                    <Step active={this.state.step == "details"}>
                        <div>
                            <h3>Complete the following to create entry</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory:</div>
                                <div className="segment-item-input text-emphasis">
                                    <EmptyBox style={{padding:"5px"}}>{entryData.dormName}</EmptyBox>
                                </div>
                            </div>
                            {
                                (entryData.resident.length == 1)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Resident:</div>
                                        <div className="segment-item-input text-emphasis">
                                            <EmptyBox style={{padding:"5px"}}>{entryData.resident[0].tenantName} (<span className={(entryData.resident[0].demerit > 0) ? "text-negative" : ""}>{entryData.resident[0].demerit} Pts</span>)</EmptyBox>
                                        </div>
                                    </div>
                                    :
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Resident:</div>
                                        <div className="segment-item-input text-emphasis">
                                            <EmptyBox style={{padding:"5px"}}>{entryData.resident.length + " Residents"}</EmptyBox>
                                        </div>
                                    </div>
                            }
                            {
                                (entryData.resident.length == 1)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Resident FIN:</div>
                                        <div className="segment-item-input text-emphasis">
                                            <EmptyBox style={{padding:"5px"}}>{entryData.resident[0].nric}</EmptyBox>
                                        </div>
                                    </div>
                                    :
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Room:</div>
                                        <div className="segment-item-input text-emphasis">
                                            <EmptyBox style={{padding:"5px"}}>{entryData.roomName}</EmptyBox>
                                        </div>
                                    </div>
                            }


                            <div className="segment-full-item">
                                <div className="segment-item-label">Offenses:</div>
                                <div className="segment-item-input">
                                    <List ref="offenses"
                                          label="Offenses"
                                          field="offenses"
                                          values={{value:entryData.offenses}}
                                          rules={{required:true}}
                                          nullText="Click to add offense"
                                          onChange={this.onChange}
                                          onAdd={()=>{this.setState({step:"add-offense",error:""})}}
                                          onRemove={this.onRemoveOffense}
                                          />
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Photos:</div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="photos"
                                                label="Photos"
                                                field="photos"
                                                values={{value:entryData.photos}}
                                                onAdd={()=>{this.setState({step:"add-photo",error:""})}}
                                                onRemove={this.onRemovePhoto}
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Attachments:</div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="attachments"
                                                label="Attachments"
                                                field="attachments"
                                                type="attachment"
                                                values={{value:entryData.attachments}}
                                                onAdd={()=>{this.setState({step:"add-attachment",error:""})}}
                                                onRemove={this.onRemoveAttachment}
                                                onChange={this.onChange}
                                                rules={{nullText:"Click to add attachment"}}
                                                />
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Remarks:</div>
                                <div className="segment-item-input">
                                    <Area ref="remarks"
                                          label="Remarks"
                                          field="remarks"
                                          values={{value:entryData.remarks}}
                                          onChange={this.onChange}
                                          />
                                </div>
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button onClick={()=>{this.setState({step:"resident",error:""})}}>Back</Button>
                                <Button onClick={this.onValidate}>Confirm</Button>
                            </div>

                        </div>
                    </Step>

                    <Step active={this.state.step=="add-photo"}>
                        <Dropzone label={"Photo"}
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.userFilename = filename; this.setState({loading:true,loadText:"Uploading..."})}}
                                  onUploaded={this.onAddPhoto}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Photo}
                                  />
                        <div style={{fontSize:"0.85em",margin:"20px auto"}}>Photos are included directly into the misconduct report</div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"details",error:""})}}>Back</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step=="add-attachment"}>
                        <Dropzone label={"Attachment"}
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.userFilename = filename; this.setState({loading:true,loadText:"Uploading..."})}}
                                  onUploaded={this.onAddAttachment}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Report}
                                  />
                        <div style={{fontSize:"0.85em",margin:"20px auto"}}>Attachments are documents that are attached when you Send Report</div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"details",error:""})}}>Back</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step=="add-offense"}>
                        <div>
                            <h3>Add Offense</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Offense:</div>
                                <div className="segment-item-input">
                                    <Select ref="offense"
                                            label="Offense"
                                            values={{options:this.state.offenseTypeOptions}}
                                            rules={{required:true}}
                                    />
                                </div>
                            </div>
                            <div className="segment-buttons">
                                <Button onClick={()=>{this.setState({step:"details",error:""})}}>Back</Button>
                                <Button onClick={this.onAddOffense}>Confirm</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}
