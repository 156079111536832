import React from 'react';
import PublicPageLayout from '../../components/PublicPageLayout';
import {PSAHeader} from './PSAOperationalStatistics';
import {io} from 'socket.io-client';
import {LoadGraphic} from '../../components/Common';
import moment from 'moment';
import u from '../../utilities/Utilities';
import store from 'store';
import {Link} from 'react-router-dom';
import "../../styles/dashboard-generic.scss";
import "../../styles/dashboards/psa-dashboards.scss";

export default class PSAOperationalStatus extends React.Component{

    socketType="Operational Status";

    tableRows=10;

    dormID = this.props.match.params.dormID;

    state={
        content:{
            tables:[],
        },
        loading:false,
        refresh:false,
        refreshRate:-1,
    };

    componentDidMount=()=>{
        this.openedOn=moment().format("YYYY-MM-DDTHH:mm:ss");
        this.getContent();
    }

    componentWillUnmount=()=>{
        clearInterval(this.contentInterval);
    }

    getContent=(refresh)=>{
        this.setState({
            error:"",
            loading:!(refresh),
            refresh:refresh,
        });
        u.post({
            url:"/api/get-dashboard-content",
            data:{
                dormID:this.dormID,
                type:this.socketType,
                testDelay:2000,
                openedOn:this.openedOn,
            },
            success:(content)=>{
                if(content.kill){
                    window.close();
                    return 0;
                }

                this.setState({
                  content:content,
                  loading:false,
                  refresh:false,
                  lastRefreshed:moment().format("YYYY-MM-DDTHH:mm:ss"),
                });
            },
            error:(error)=>{
                this.setState({
                  error:error,
                  loading:false,
                  refresh:false
                });
            }
        });
    }

    psaColors=[
      u.hexToRgb("#229dce"),
      u.hexToRgb("#37a7b6"),
      u.hexToRgb("#63c08a"),
      u.hexToRgb("#8ed75e"),
      u.hexToRgb("#a5e348"),
      u.hexToRgb("#cac9d0"),
    ];

    onSelectHeader=(tableIndex,headerIndex)=>{
        let content=this.state.content;
        content.tables = content.tables.map(
            (item,tIndex)=>{
                item.headers = item.headers.map(
                  (header,hIndex)=>{
                      if(!(tIndex == tableIndex && hIndex == headerIndex)){
                        header.selected=false;
                        header.twice=false;
                      }
                      return header;
                  }
                )
                return item;
            }
        );
        let header = content.tables[tableIndex].headers[headerIndex];
        let searchDescending=true;
        if(header.selected){
            header.twice=!header.twice;
            searchDescending=!header.twice;
        }
        else{
            header.selected=true;
            searchDescending=true;
        }

        //Perform sort
        let sortContent = content.tables[tableIndex].content;
        sortContent = sortContent.sort(
            (a,b)=>{
                if(searchDescending){
                    return (a[header.field] > b[header.field]) ? -1 : 1;
                }
                return (a[header.field] < b[header.field]) ? -1 : 1;
            }
        );

        this.setState({content:content});
    }

    onChangeRefresh=(valueMS)=>{
        //console.log("Changing Refresh",valueMS);
        //Check difference from refresh
        this.setState({refreshRate:valueMS});
        if(valueMS == "-1"){
            if(this.contentInterval){
                clearInterval(this.contentInterval);
            }
        }
        else{
          const msSinceRefresh = moment().diff(moment(this.state.lastRefreshed));
          //console.log("MS Since Refresh",msSinceRefresh);
          if(msSinceRefresh > valueMS){
              this.getContent(true);
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              this.contentInterval = setInterval(()=>{
                  this.getContent(true);
              },valueMS);
          }
          else{
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              setTimeout(()=>{
                  this.getContent(true);
                  if(this.contentInterval){
                      clearInterval(this.contentInterval);
                  }
                  this.contentInterval = setInterval(()=>{
                      this.getContent(true);
                  },valueMS);
              },(valueMS - msSinceRefresh));
          }
        }
    }

    render=()=>{
        const content=this.state.content;

        return(
            <PublicPageLayout url="/psa-operational-status"
                              title={this.socketType}
                              style={{position:"relative",zIndex:102}}>

                <LoadGraphic active={this.state.loading} text="Preparing"/>

                <PSAHeader label={(content.label) ? content.label : this.socketType.toUpperCase()}
                           onChange={this.onChangeRefresh}
                           loading={this.state.loading}
                           lastRefreshed={this.state.lastRefreshed}
                           refresh={this.state.refresh}
                           refreshRate={this.state.refreshRate}
                           />

                <div className="psa-tables-container" style={{minHeight:"400px"}}>
                    {
                        content.tables.map(
                            (item,index)=>{
                                let padLength = (this.tableRows - item.content.length);
                                if(padLength < 0){
                                    padLength = 0;
                                }
                                const tablePadding=Array(padLength).fill("");

                                return (
                                    <div key={index} className={"psa-table-container " + (index%2==0 ? "tleft" : "tright")}>
                                        <h3 className="psa-table-title" style={{color:this.psaColors[index].hex}}>{item.label}</h3>
                                        <div className="psa-table">
                                            <table>
                                                <thead className={(item.content.length > this.tableRows ? "oversize" : "")} style={{backgroundColor:this.psaColors[index].hex}}>
                                                    <tr>
                                                        {
                                                            item.headers.map(
                                                                (header,h)=>{
                                                                    return (
                                                                        <th key={h}
                                                                            style={{backgroundColor:this.psaColors[index].hex}}
                                                                            onClick={()=>{this.onSelectHeader(index,h)}}
                                                                            >
                                                                        {header.label}
                                                                        {
                                                                            (header.selected)
                                                                                ?
                                                                                <img src="/images/app/nav-triangle.png" className={"dashboard-selected" + ((header.twice) ? " twice" : "")}/>
                                                                                :
                                                                                null
                                                                        }
                                                                        </th>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody style={{maxHeight:(40*this.tableRows)+"px"}}>
                                                    {
                                                        item.content.map(
                                                            (row,ri)=>{
                                                                return (
                                                                    <Link key={ri} to={u.linkReplace(item.link,row)}>
                                                                        <tr className={"item " + (ri%2 == 0 ? "even" : "odd")}>
                                                                          {
                                                                            item.headers.map(
                                                                                (header,j)=>{
                                                                                    return (
                                                                                        <td key={(ri*item.headers.length) + j}>
                                                                                            {
                                                                                                (header.type)
                                                                                                    ?
                                                                                                    null
                                                                                                    :
                                                                                                    row[header.field]

                                                                                            }
                                                                                            {
                                                                                              (header.type == "dateTime")
                                                                                                  ?
                                                                                                  moment(row[header.field]).format("DD MMM 22 (HH:mm)")
                                                                                                  :
                                                                                                  null

                                                                                            }
                                                                                            {
                                                                                                (header.type == "date")
                                                                                                  ?
                                                                                                  moment(row[header.field]).format("DD MMM YY")
                                                                                                  :
                                                                                                  null
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            )
                                                                          }
                                                                        </tr>
                                                                    </Link>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    {
                                                        tablePadding.map(
                                                            (emptyRow,ei)=>{
                                                                return(
                                                                    <tr key={index} className={"item " + ((item.content.length + ei)%2 == 0 ? "even" : "odd")}>
                                                                      {
                                                                        item.headers.map(
                                                                            (header,j)=>{
                                                                                return (
                                                                                    <td key={(ei*item.headers.length) + j}>
                                                                                            <div className="pad">NONE</div>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        )
                                                                    }
                                                                    </tr>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </PublicPageLayout>
        )
    }
}
