import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,
        DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,
        SegmentComplete,Dropzone} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,
        TextButton,ContactNumber,Checkbox,Input,
        MiniMinusButton,PageSelector} from '../components/Input';
import u from '../utilities/Utilities';
import {Spreadsheet} from '../data/Mimetypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';

export default class AttendanceEventList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Event",
        permission:"attendance",
        url:"/attendance-event-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/create-attendance-event-entry",
        deleteURL:"/api/delete-attendance-event-entries",
        spreadsheetURL:"/api/generate-attendance-event-spreadsheet",
        overviewURL:"/api/get-all-attendance-event-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"attendance-event-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    }

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("attendance");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Entry",ref:"add",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
              {label:"View Attendance",ref:"attendance",enabled:true,onClick:this.onAttendance},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onAttendance=()=>{
        window.open("/attendance-list");
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AttendanceEventItem key={index}
                                                     content={content}
                                                     index={index}
                                                     onChange={onChange}
                                                     onContentChange={onContentChange}
                                                     registerReference={registerRef}
                                                     settings={this.settings}
                                                     permissions={this.state.permissions}
                                                     />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }

}

class AttendanceEventItem extends React.Component{

    pageLength = 10;

    state={
        step:"view",
        loading:false,
        loadText:"",
        currentPage:1,
    }

    onViewAttendance=()=>{
        window.open("/attendance-list?tags=@eventID("+this.props.content.eventID+")");
    }

    onQRCode=()=>{
        store.set("event-qr-details",this.props.content);
        window.open("/event-print-qr");
    }

    onBulkUploadInvite=(filename)=>{
        u.post({
            url:"/api/event-invite-bulk-upload",
            data:{
               eventID:this.props.content.eventID,
               filename:filename,
            },
            success:(newInviteList)=>{
                this.props.onChange(newInviteList,"inviteList",this.props.index);
                this.setState({step:"view",loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onAddInvite=()=>{
        this.setState({error:""});
        let nric = this.refs['residentNRIC'].validate();
        if(!nric.valid){
            this.setState({error:nric.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Adding..."});
        u.post({
            url:"/api/event-add-single-invite",
            data:{
                eventID:this.props.content.eventID,
                nric:nric.value,
            },
            success:(invitee)=>{
                let inviteList = this.props.content.inviteList;
                inviteList.unshift(invitee);
                this.props.onChange(inviteList,"inviteList",this.props.index);
                this.setState({loading:false,step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onRemoveInvite=(index)=>{
        this.setState({
          step:"remove-invite",
          removeInvite:this.props.content.inviteList[index],
        });

        this.removeIndex=index;
    }

    onConfirmRemove=()=>{
        this.setState({loading:true,loadText:"Removing..."});
        u.post({
            url:"/api/event-remove-invite",
            data:{
                eventID:this.props.content.eventID,
                entry:this.state.removeInvite,
            },
            success:()=>{
                const inviteList=this.props.content.inviteList;
                inviteList.splice(this.removeIndex,1);
                this.props.onChange(inviteList,"inviteList",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDownloadTemplate=()=>{
        this.setState({loading:true,loadText:"Preparing..."});
        u.autoDownload({
            url:"/api/get-event-add-invitee-template",
            data:{
                eventID:this.props.content.eventID
            },
            success:()=>{
                this.setState({loading:false,error:""});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onChangePage=(newPage)=>{
        this.setState({currentPage:newPage});
    }

    onExportInviteList=()=>{
        this.setState({loading:true,loadText:"Preparing.."});
        u.autoDownload({
            url:"/api/export-invite-list",
            data:{
                eventID:this.props.content.eventID,
                testDelay:2000,
            },
            success:()=>{
                this.setState({loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const {settings,content,permissions} = this.props;
        const {loading,loadText,currentPage,removeInvite} = this.state;

        const maxPage = Math.ceil(content.inviteList.length/this.pageLength);
        const pageStart = (currentPage-1) * this.pageLength;
        const pageEnd = pageStart + this.pageLength;

        return (
            <div>
                <LoadGraphic active={loading} text={loadText}/>
                <Step active={this.state.step=="view"}>
                    <div className="page-error">{this.state.error}</div>

                    <DynamicListArea listLabel={settings.listLabel}
                                     permission={settings.permission}
                                     content={content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />

                    {
                       (content.attendanceType == "Invite Only")
                          ?
                          (
                              (content.inviteList.length > 0)
                                  ?
                                  <div className="list-table" style={{margin:"30px auto",maxHeight:"none"}}>
                                      <h3>Invite List</h3>
                                      <table>
                                          <thead>
                                              <tr>
                                                  <th className="remove left"/>
                                                  <th>Resident</th>
                                                  <th>NRIC/FIN</th>
                                                  <th>ORG</th>
                                                  <th className="right">Attended</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                              {
                                                  content.inviteList.map(
                                                      (item,index)=>{
                                                          if(index < pageStart || index >= pageEnd){
                                                             return null;
                                                          }
                                                          return (
                                                              <tr className="item" key={index}>
                                                                  <td className="remove">
                                                                      <div style={{position:"relative",width:"15px",height:"15px"}}>
                                                                          <MiniMinusButton index={index}
                                                                                           onClick={this.onRemoveInvite}/>
                                                                      </div>
                                                                  </td>
                                                                  <td>{item.residentName}</td>
                                                                  <td>{item.residentNRIC}</td>
                                                                  <td>{item.residentORG}</td>
                                                                  <td>{(item.attended == 0) ? "No" : item.attended+" Time(s)"}</td>
                                                              </tr>
                                                          )
                                                      }
                                                  )
                                              }
                                          </tbody>
                                      </table>

                                      <div style={{margin:"30px auto 20px auto"}}>
                                          <PageSelector maxPage={maxPage}
                                                        page={currentPage}
                                                        onChange={this.onChangePage}
                                                        />
                                      </div>

                                      <div style={{margin:"20px auto 0 auto"}}>
                                          {
                                            (permissions.update)
                                                ?
                                                <Button onClick={()=>{this.setState({step:"add-invite",error:""})}}>Add Invite Entry</Button>
                                                :
                                                null
                                          }
                                          <Button onClick={this.onExportInviteList}>Export Invite List</Button>
                                      </div>
                                  </div>
                                  :
                                  <div className="list-placeholder">
                                      <b>No Invitees Added</b>
                                      <div style={{margin:"10px auto 0 auto"}}>
                                          {
                                              (permissions.update)
                                                  ?
                                                  <span><TextButton style={{margin:"0"}} onClick={()=>{this.setState({step:"add-invite",error:""})}}>Click here</TextButton> to add invitees</span>
                                                  :
                                                  "No permission to add invitee"
                                          }
                                      </div>
                                  </div>
                          )
                          :
                          <div className="list-segment-buttons">
                              <Button onClick={this.onQRCode}>Print Event QR Code</Button>
                          </div>
                    }

                    <div style={{margin:"10px auto 20px auto",textAlign:"center"}}>
                        <TextButton onClick={this.onViewAttendance}>View Attendance</TextButton>
                    </div>
                </Step>

                <Step active={this.state.step=="add-invite"}>
                    <div style={{textAlign:"center"}}>
                        <h3>Add Invitee</h3>

                        <div style={{fontSize:"14px"}}>
                            <div className="segment-full-item">
                                <div className="segment-item-input">
                                    <Box ref="residentNRIC"
                                         label="Resident FIN"
                                         rules={{required:true,placeholder:"Resident NRIC/FIN"}}
                                         />
                                </div>
                            </div>
                        </div>

                        <div className="segment-full-item" style={{textAlign:"center",fontSize:"14px"}}>
                            Only residents who are invited can be scanned as an attendee
                        </div>

                        <div style={{margin:"20px auto"}}>
                            <TextButton onClick={()=>{this.setState({step:"bulk-add-invite",error:""})}}>Bulk Upload</TextButton>
                        </div>

                        <div className="list-segment-buttons">
                            <div className="page-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onAddInvite}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="bulk-add-invite"}>
                    <div style={{textAlign:"center"}}>
                        <Dropzone label={"add-invitee"}
                                  accessibleForPreview={false}
                                  onAdded={()=>{this.setState({loading:true,loadText:"Uploading..."})}}
                                  onUploaded={this.onBulkUploadInvite}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  filetypes={Spreadsheet}
                                  dragAndDropLabel="Upload completed templates here"
                                  />

                        <div className="list-segment-note">
                            Note. This is a truthful template, any name removed from the template will be removed from the invite list
                        </div>

                        <div className='list-segment-buttons'>
                            <div className="page-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onDownloadTemplate}>Download Template</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove-invite"}>
                    <div style={{padding:"20px 0",textAlign:"center"}}>
                        <h2 className="text-negative">Remove invite for {removeInvite?.residentName}?</h2>
                        <div className="list-segment-note">
                            Note. This action can be undone by adding the resident again
                        </div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onConfirmRemove}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        options:{},
        entryData:{
          validFromDateTime:moment().format("YYYY-MM-DDT08:00:00"),
          validToDateTime:moment().format("YYYY-MM-DDT18:00:00"),
        },
        dormOptions:[],
    }

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
        this.getDormOptions();
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:[
                "yesNo","attendanceType"
              ]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onComplete=()=>{
        this.setState({
            error:"",
            entryData:{
              validFromDateTime:moment().format("YYYY-MM-DDT08:00:00"),
              validToDateTime:moment().format("YYYY-MM-DDT18:00:00"),
            }
        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label,index)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onValidate=(fields)=>{
        let keys = fields.map((item)=>{
          return item.field;
        });

        for(var i=0; i<keys.length; i++){
            if(keys[i] != "divider" && this.refs[keys[i]]){
                const current=this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
            }
        }

        const {entryData} = this.state;
        if(moment(entryData.validFromDateTime).isAfter(entryData.validToDateTime)){
            this.setState({error:"To date/time cannot be before From date/time"})
            return 0;
        }

        this.refs[this.props.reference].onValidated(entryData);
    }

    render=()=>{
        const {entryData,options,dormOptions}=this.state;
        const fields=[
            {dataType:"select",field:"dormID",label:"Dormitory",options:dormOptions},
            {dataType:"box",field:"eventLabel",label:"Event Name",rules:{required:true}},
            {dataType:"select",field:"attendanceType",label:"Type",options:options.attendanceType},
            {dataType:"select",field:"validation",label:"Validation",options:options.yesNo,info:"If validation is turned on, each resident will only be able to create one attendance entry for this event"},
            {dataType:"date",field:"validFromDateTime",label:"From Date"},
            {dataType:"time",field:"validFromDateTime",label:"From Time"},
            {dataType:"date",field:"validToDateTime",label:"To Date"},
            {dataType:"time",field:"validToDateTime",label:"To Time"},
        ];

        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         onCancel={this.onCancel}
                         stepScroll={true}
                         >

                <div>
                    <h3>Fill in the following to create entry</h3>
                    {
                        fields.map(
                            (item,index)=>{
                                return (
                                    <Input ref={item.field}
                                           key={index}
                                           fields={{
                                             ...item,
                                             onChange:this.onChange
                                           }}
                                           content={entryData}
                                           />
                                )
                            }
                        )
                    }
                    <div style={{margin:"30px auto 0 auto",fontSize:"0.8em"}}>
                        QR Code can only be scanned between From & To dates, so buffer should be included
                    </div>
                </div>

                <div className="segment-buttons">
                    <div className="segment-error">{this.state.error}</div>
                    <Button onClick={this.onCancel}>Cancel</Button>
                    <Button onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                </div>

           </AddListItem>
        )
    }
}
