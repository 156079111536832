const fileImageReference={
    document:"/images/filetypes/word.png",
    pdf:"/images/filetypes/pdf.png",
    excel:"/images/filetypes/excel.png",
    image:"/images/filetypes/picture.png",
};

//Dropzone mimetype categories
export const Spreadsheet=[
    {ext:["csv"],mimetype:"text/csv",fileImageReference:fileImageReference.excel,type:"excel"},
    {ext:["xlsx"],mimetype:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",fileImageReference:fileImageReference.excel,type:"excel"},
    {ext:["xla","xlc","xlm","xls","xlt","xlw"],mimetype:"application/vnd.ms-excel",fileImageReference:fileImageReference.excel,type:"excel"},
];

export const Photo=[
    {ext:["jpg","jpeg"],mimetype:"image/jpeg",fileImageReference:fileImageReference.image,type:"image"},
    {ext:["png"],mimetype:"image/png",fileImageReference:fileImageReference.image,type:"image"}
];

export const Report=[
    {ext:["pdf"],mimetype:"application/pdf",fileImageReference:fileImageReference.pdf,type:"pdf"},
    {ext:["doc","dot"],mimetype:"application/msword",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["docx"],mimetype:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",fileImageReference:fileImageReference.document,type:"document"},
    /*{ext:["dotx"],mimetype:"application/vnd.openxmlformats-officedocument.wordprocessingml.template",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["docm"],mimetype:"application/vnd.ms-word.document.macroEnabled.12",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["dotm"],mimetype:"application/vnd.ms-word.template.macroEnabled.12",fileImageReference:fileImageReference.document,type:"document"},*/
    {ext:["csv"],mimetype:"text/csv",fileImageReference:fileImageReference.excel,type:"excel"},
    {ext:["xlsx"],mimetype:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",fileImageReference:fileImageReference.excel,type:"excel"},
    //{ext:["xla","xlc","xlm","xls","xlt","xlw"],mimetype:"application/vnd.ms-excel",fileImageReference:fileImageReference.excel,type:"excel"},
];

export const Attachment = [
    {ext:["jpg","jpeg"],mimetype:"image/jpeg",fileImageReference:fileImageReference.image,type:"image"},
    {ext:["png"],mimetype:"image/png",fileImageReference:fileImageReference.image,type:"image"},
    {ext:["pdf"],mimetype:"application/pdf",fileImageReference:fileImageReference.pdf,type:"pdf"},
    {ext:["doc","dot"],mimetype:"application/msword",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["docx"],mimetype:"application/vnd.openxmlformats-officedocument.wordprocessingml.document",fileImageReference:fileImageReference.document,type:"document"},
    /*{ext:["dotx"],mimetype:"application/vnd.openxmlformats-officedocument.wordprocessingml.template",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["docm"],mimetype:"application/vnd.ms-word.document.macroEnabled.12",fileImageReference:fileImageReference.document,type:"document"},
    {ext:["dotm"],mimetype:"application/vnd.ms-word.template.macroEnabled.12",fileImageReference:fileImageReference.document,type:"document"},*/
    {ext:["csv"],mimetype:"text/csv",fileImageReference:fileImageReference.excel,type:"excel"},
    {ext:["xlsx"],mimetype:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",fileImageReference:fileImageReference.excel,type:"excel"},
    /*{ext:["xla","xlc","xlm","xls","xlt","xlw"],mimetype:"application/vnd.ms-excel",fileImageReference:fileImageReference.excel,type:"excel"},*/
];
