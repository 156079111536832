import React from 'react';
import {MultipleCard} from './PrintCard';

export default class PrintMultipleCard extends React.Component{

    render=()=>{
        return(
            <MultipleCard/>
        )
    }
}
