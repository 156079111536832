import React from 'react';
import {Button,TextButton} from './Input';
import PropTypes from 'prop-types';
import "../styles/popup.scss";


export default class Popup extends React.Component{

    onCancel=()=>{
        if(this.props.content.addCancel){
            this.props.content.addCancel();
        }
        this.props.content.cancel();
    }

    onConfirm=()=>{
        this.props.content.confirm();
        this.props.content.cancel();
    }

    render=()=>{
        const content=this.props.content;
        return (
            <div className="popup-container">
                <div className="popup-align"/>
                <div className="popup-content">
                    <h2 className="popup-title" style={{margin:"0"}}>{content.title}</h2>
                    {
                        (content.description)
                            ?
                            <div className="popup-description">{content.description}</div>
                            :
                            ""
                    }
                    <div className="popup-buttons">
                        <Button type="medium" onClick={this.onConfirm}>Confirm</Button>
                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                    </div>
                </div>
            </div>
        )
    }
}
Popup.propTypes={
    content:PropTypes.shape({
        confirm:PropTypes.func.isRequired,
        cancel:PropTypes.func.isRequired,
        addCancel:PropTypes.func,
        title:PropTypes.string.isRequired,
        description:PropTypes.string,
    })
};


export class ComponentPopup extends React.Component{

    state={
        component:<div/>,
        show:false
    }

    show=(show)=>{
        this.setState({show:show});
    }

    setComponent=(component)=>{
        this.setState({component:component});
    }

    onClickOutside=(e)=>{
        //this.setState({show:false});
    }

    onClickContent=(e)=>{
        e.preventDefault();
        e.stopPropagation();
    }

    render=()=>{
        if(!this.state.show){
            return null;
        }
        return(
            <div className="component-popup">
                <div className="component-popup-background" onClick={this.onClickOutside}/>
                <div className="component-popup-content" onClick={this.onClickContent}>
                    {this.state.component}
                </div>
            </div>
        )
    }
}


export class PopupGallery extends React.Component{

    state={
        content:[],
        selected:{},
        active:false,
    };

    onSelectImage=(index)=>{
        let content=this.state.content;
        this.setState({
            selected:content[index],
        });
    }

    showGallery=(content)=>{
        this.setState({
            content:content,
            selected:content[0],
            active:true,
        })
    }

    hideGallery=()=>{
        this.setState({active:false});
    }

    render=()=>{
        if(!this.state.active){
            return (<div/>)
        }
        const content=this.state.content;
        return(
            <div className="popup-gallery-container">
                <div className="gallery-close">
                    <div className="gallery-close-text" onClick={()=>{this.setState({active:false})}}>
                        <img src="/images/input/cross-white.png"/>
                        Close Gallery
                    </div>
                </div>
                <div className="gallery-content-icons">
                  {
                      content.map(
                          (item,index)=>{
                              return(
                                  <img key={index} src={item.url} className="gallery-icon" onClick={()=>{this.onSelectImage(index)}}/>
                              )
                          }
                      )
                  }
                </div>
                <div className="gallery-content">
                    <img src={this.state.selected.url} className="gallery-content-item"/>
                </div>
                <div className="gallery-close">
                    <div className="gallery-close-text" onClick={()=>{window.open(this.state.selected.url,"_blank")}}>
                        View Original Image
                    </div>
                </div>
                <div className="gallery-close">
                    <div className="gallery-close-text" onClick={()=>{this.setState({active:false})}}>
                        <img src="/images/input/cross-white.png"/>
                        Close Gallery
                    </div>
                </div>
            </div>
        )
    }
}
