import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList,{MiniList,AddSearchTag,Tag} from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,
        DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete,
        Dropzone} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,TextButton,ContactNumber,Checkbox,
        Select,Area,Input,EditableBox,EditableArea,Date,Time,EmptyBox} from '../components/Input';
import {Photo} from '../data/Mimetypes';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import qs from 'query-string';
import {Link} from 'react-router-dom';
import store from 'store';
import "../styles/announcement-list.scss";

export default class AnnouncementList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Announcement",
        permission:"notification",
        url:"/announcement-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/create-announcement-entry",
        /*bulkURL:"/api/add-bulk-absence-reason-entries",
        templateURL:"/api/get-add-absence-reason-template",*/
        deleteURL:"/api/delete-announcement-entries",
        spreadsheetURL:"/api/generate-announcement-spreadsheet",
        overviewURL:"/api/get-all-announcement-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"announcement-list",
        orderByField:"start",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");
        //const query = qs.parse(this.props.location.search);
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add",ref:"add",mobileLabel:"Add Entry",title:"Add new entry",enabled:(permissions.update==2)},
              {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",title:"Delete selected entries",enabled:(permissions.update==2)},
              {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",mobileLabel:"Bulk Edit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        const pageComponents={
            add:{component:AddEntry,props:{create:this.state.createRequest}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
            bulkEdit:{component:BulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AnnouncementItem key={index}
                                                  content={content}
                                                  index={index}
                                                  onChange={onChange}
                                                  onContentChange={onContentChange}
                                                  registerReference={registerRef}
                                                  settings={this.settings}
                                                  />
                            )
                        }
                    }
                </OverviewList>
            </AppLayout>
        )
    }
}

class AnnouncementItem extends React.Component{
    state={
        step:"view",
        loading:false,
        loadText:"Uploading...",
    }

    onUploaded=(filename)=>{
        u.post({
            url:"/api/commit-announcement-image",
            data:{
                announcementID:this.props.content.announcementID,
                filename:filename
            },
            success:(url)=>{
                this.props.onChange(url,"url",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({loading:false,error:""});
            }
        });
    }

    onConfirmTerminate=()=>{
        this.setState({loading:true,loadText:"Terminating..."});
        u.post({
            url:"/api/terminate-announcement",
            data:{
                announcementID:this.props.content.announcementID,
            },
            success:(newStatus)=>{
                this.props.onChange(newStatus,"status",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const content = this.props.content;
        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step == "view"}>
                    <DynamicListArea listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />

                    <div style={{textAlign:"center"}}>
                        <h3>Preview</h3>
                        <div style={{marginBottom:"10px"}}>
                          Actual look and feel will vary according to the user's device specifications
                        </div>

                        <div className="announcement-image-container">
                            <img src="/images/app/mobile-phone-mockup.png"
                                 className="announcement-mockup"
                                  />

                            <div className="announcement-image-popup">
                                <div className="announcement-title">
                                    ANNOUNCEMENTS
                                    <div className="announcement-cancel">
                                        <b>X</b>
                                    </div>
                                </div>

                                {
                                    (content.type == "Image")
                                        ?
                                        <div className="announcement-popup-flex">
                                            <img src={content.url}
                                                 className="announcement-image"
                                             />
                                        </div>
                                        :
                                        <div className="announcement-popup-flex text">
                                           <div className="announcement-subject">
                                              <EditableArea url="/api/update-announcement-info"
                                                            id={content.announcementID}
                                                            field="subject"
                                                            label="Subject"
                                                            values={{value:content.subject}}
                                                            rules={{required:true}}
                                                            onChange={this.props.onChange}
                                                            index={this.props.index}
                                                            />
                                           </div>

                                           {
                                              (content.subject)
                                                  ?
                                                  <div className="subject-divider"/>
                                                  :
                                                  null
                                           }

                                           <div className="announcement-message">
                                              <EditableArea url="/api/update-announcement-info"
                                                            id={content.announcementID}
                                                            label="Message"
                                                            field="message"
                                                            values={{value:content.message}}
                                                            rules={{required:true,rows:15}}
                                                            onChange={this.props.onChange}
                                                            index={this.props.index}
                                                            />
                                            </div>
                                        </div>
                                }

                                <div className="announcement-selector">
                                    <div className="ball"/>
                                    <div className="ball"/>
                                    <div className="ball selected"/>
                                    <div className="ball"/>
                                    <div className="ball"/>
                                </div>
                            </div>
                        </div>

                        <div>
                            To emulate swipe, use mousewheel to scroll oversized content up or down
                        </div>

                        <div style={{margin:"20px auto"}}>
                            {
                                (content.type=="Image")
                                    ?
                                    <TextButton onClick={()=>{this.setState({step:"image",error:""})}}>Replace Image</TextButton>
                                    :
                                    "Click on the text to above to edit and hit enter or click on the tick to save changes"
                            }
                        </div>

                        {
                            (content.status == "Live" || content.status == "Pending")
                                ?
                                <div style={{margin:"20px auto"}}>
                                    <Button onClick={()=>{this.setState({step:"terminate",error:""})}}>Terminate Announcement</Button>
                                </div>
                                :
                                null
                        }
                        <div></div>
                    </div>
                </Step>

                <Step active={this.state.step=="image"} style={{textAlign:"center"}}>
                    <Dropzone label={"announcement-dropzone-"+this.props.index}
                              accessibleForPreview={true}
                              onAdded={(filename)=>{this.setState({loading:true,error:"",loadText:"Uploading..."})}}
                              onUploaded={this.onUploaded}
                              onError={(error)=>{this.setState({error:error})}}
                              onProgress={(prog)=>{this.setState({loadText:"Uploading..."+prog+"%"})}}
                              filetypes={Photo}
                              />
                </Step>

                <Step active={this.state.step=="terminate"} style={{textAlign:"center"}}>
                    <h2 className="text-negative">Confirm Terminate Announcement?</h2>
                    <div className="list-segment-note">
                        Note. The announcement will immediately stop displaying on all relevant devices
                    </div>
                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onConfirmTerminate}>Confirm</Button>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{
            type:"Image",
            start:moment().format("YYYY-MM-DDT08:00:00"),
            end:moment().add(7,"days").format("YYYY-MM-DDT23:59:59"),
            tags:[],
        },
        dormOptions:[],
        step:"add",
        loading:false,
        loadText:"",
        options:{},
    };

    componentDidMount=()=>{
        this.getDormOptions();
        this.getOptions();
    }

    show=(show)=>{
        this.refs['add'].show(show);
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm",
            },
            success:(callback)=>{
                if(callback.length > 0){
                    let entryData = this.state.entryData;
                    entryData.dormID = callback[0].value;
                    this.setState({entryData:entryData});
                }
                callback.unshift({value:"All",label:"All"});
                this.setState({dormOptions:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["announcementType","announcementTargetUser"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onChange=(value,label,index,option)=>{
        let entryData = this.state.entryData;
        entryData[label] = value;
        this.setState({entryData:entryData});
    }

    onDropzoneError=(error)=>{
        this.setState({error:error});
    }

    onRemoveAttachment=(index)=>{
        let entryData = this.state.entryData;
        entryData.attachments.splice(index,1);
        this.setState({entryData:entryData});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    goToStep=(step)=>{
        this.setState({step:step,error:""});
    }

    onFileDropped=(file)=>{
        this.setState({loading:true,loadText:"Checking file...",currentFile:file});
    }

    onUploadProgress=(progress)=>{
        this.setState({loading:true,loadText:"Uploading..." + progress + "%"});
    }

    onUploadComplete=(filename)=>{
        let entryData=this.state.entryData;
        entryData.url = filename;
        this.setState({step:"add",error:"",loading:false,entryData:entryData});
    }

    onAdd=()=>{
        this.setState({error:""});
        const datarefs = ["reference","startDate","endDate","subject","message"];
        for(var i=0; i<datarefs.length; i++){
            if(this.refs[datarefs[i]]){
              const d = this.refs[datarefs[i]].validate();
              if(!d.valid){
                  this.setState({error:d.value});
                  return 0;
              }
            }
        }
        const entryData=this.state.entryData;

        if(entryData.targetUser == "Resident Search Tags"){
            if(entryData.tags.length == 0){
                this.setState({error:"Add at least 1 Resident Search Tag"});
                return 0;
            }
        }

        if(entryData.type == "Image"){
            if(typeof entryData.url == "undefined"){
                this.setState({error:"Field: Image is required"});
                return 0;
            }
            delete entryData.subject;
            delete entryData.message;
        }
        else{
            delete entryData.url;
        }

        this.refs[this.props.reference].onValidated(entryData);
    }

    onAddComplete=()=>{
        this.setState({
            entryData:{
                type:"Image",
                start:moment().format("YYYY-MM-DDT08:00:00"),
                end:moment().add(7,"days").format("YYYY-MM-DDT23:59:59"),
                tags:[],
            }
        });
    }

    onChangeTags=(tags)=>{
        let entryData=this.state.entryData;
        entryData.tags = tags;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const content=this.state.entryData;
        const options=this.state.options;
        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onAddComplete}
                         inList={this.props.inList}>

                <div className="segment-error">{this.state.error}</div>

                <Step active={(this.state.step == "add")}>
                    <div style={{position:"relative"}}>
                        <h3>Complete the fields to create the announcement</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Dormitory:</div>
                            <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="dormID" values={{value:content.dormID,options:this.state.dormOptions}} onChange={this.onChange}/></div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Reference:</div>
                            <div className="segment-item-input"><Box ref="reference" label="Reference" field="reference" values={{value:content.reference}} rules={{required:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Type:</div>
                            <div className="segment-item-input"><Select ref="type" label="Type" field="type" values={{value:content.type,options:options.announcementType}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Start Date:</div>
                            <div className="segment-item-input"><Date ref="startDate" label="Start Date" field="start" values={{value:content.start}} range={[1,2]} rules={{minDate:moment().format("YYYY-MM-DD")}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Start Time:</div>
                            <div className="segment-item-input"><Time ref="startTime" label="Start Time" field="start" values={{value:content.start}} rules={{skipSeconds:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">End Date:</div>
                            <div className="segment-item-input"><Date ref="endDate" label="End Date" field="end" values={{value:content.end}} range={[1,2]} rules={{minDate:content.start}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">End Time:</div>
                            <div className="segment-item-input"><Time ref="endTime" label="End Time" field="end" values={{value:content.end}} rules={{skipSeconds:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Target User:</div>
                            <div className="segment-item-input"><Select ref="targetUser" label="User" field="targetUser" values={{value:content.targetUser,options:options.announcementTargetUser}} onChange={this.onChange}/></div>
                        </div>

                        {
                            (content.targetUser == "Resident Search Tags")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Search Tags:</div>
                                    <div className="segment-item-input">
                                        <EmptyBox onClick={()=>{this.setState({step:"add-tag"})}} style={{padding:"0 5px",cursor:"pointer"}}>
                                            {
                                                (content.tags.length > 0)
                                                    ?
                                                    content.tags.map(
                                                        (tag, index)=> {
                                                            return (
                                                                <Tag key={index}
                                                                     index={index + ""}
                                                                     clickable={true}
                                                                     label={tag}
                                                                     onRemove={()=>{}}
                                                                     values={{removable:false}}
                                                                    />
                                                            )
                                                        }
                                                    )
                                                    :
                                                    <div style={{padding:"5px 0",color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                            }
                                        </EmptyBox>
                                    </div>
                                </div>
                                :
                                ""
                        }

                        <div className="segment-full-item"
                             style={{
                                borderBottom:"2px solid #f4f4f4",
                                margin:"20px auto"
                             }}/>

                        <div style={{position:"relative",textAlign:"center",fontSize:"0.9em"}}>
                            <h3>Preview</h3>
                            <div>
                              Actual look and feel will vary according to the user's device specifications
                            </div>

                            <div style={{margin:"20px auto"}}>
                                {
                                    (content.type=="Image")
                                        ?
                                        <TextButton onClick={()=>{this.setState({step:"upload",error:""})}}>Add/Replace Image</TextButton>
                                        :
                                        null
                                }
                            </div>

                            <div className="announcement-image-container add">
                                <img src="/images/app/mobile-phone-mockup.png"
                                     className="announcement-mockup"
                                      />

                                <div className="announcement-image-popup">
                                    <div className="announcement-title">
                                        ANNOUNCEMENTS
                                        <div className="announcement-cancel">
                                            <b>X</b>
                                        </div>
                                    </div>

                                    {
                                        (content.type == "Image")
                                            ?
                                            <div className="announcement-popup-flex">
                                                {
                                                    (content.url)
                                                        ?
                                                        <img src={content.url} className="announcement-image"/>
                                                        :
                                                        <div className="text-negative" style={{padding:"50px 0"}}>
                                                            No Image Added
                                                        </div>
                                                }

                                            </div>
                                            :
                                            <div className="announcement-popup-flex text">
                                               <div className="announcement-subject">
                                                  <Area ref="subject"
                                                        field="subject"
                                                        label="Subject"
                                                        values={{value:content.subject}}
                                                        rules={{required:true,placeholder:"Subject"}}
                                                        onChange={this.onChange}
                                                        />
                                               </div>

                                               <div className="subject-divider"/>

                                               <div className="announcement-message">
                                                  <Area ref="message"
                                                        label="Message"
                                                        field="message"
                                                        values={{value:content.message}}
                                                        rules={{required:true,rows:15,placeholder:"Message"}}
                                                        onChange={this.onChange}
                                                        />
                                                </div>
                                            </div>
                                    }

                                    <div className="announcement-selector">
                                        <div className="ball"/>
                                        <div className="ball"/>
                                        <div className="ball selected"/>
                                        <div className="ball"/>
                                        <div className="ball"/>
                                    </div>
                                </div>
                            </div>

                            <div>
                                To emulate swipe, use mousewheel to scroll oversized content up or down
                            </div>

                        </div>

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                            <Button type="medium" onClick={this.onAdd}>Create</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "upload")}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Dropzone label="Attachments"
                              accessibleForPreview={false}
                              onError={this.onDropzoneError}
                              onAdded={this.onFileDropped}
                              onProgress={this.onUploadProgress}
                              onUploaded={this.onUploadComplete}
                              filetypes={Photo}
                    />
                    <div className="segment-buttons">
                        <Button index="add" type="medium" onClick={this.goToStep}>Back</Button>
                    </div>
                </Step>

                <Step active={(this.state.step == "add-tag")}>
                    <div style={{padding:"10px 0 30px 0"}}>
                        <h3>Add search tags below</h3>
                        <div style={{width:"800px", maxWidth:"96%",fontSize:"0.85em",margin:"20px auto",textAlign:"center"}}>You do not need to add in tags @status(!Check Out), @dorm() & @app(Registered) as these tags are automatically added</div>
                        <AddSearchTag listLabel="resident-list" tags={content.tags} onChange={this.onChangeTags} />
                        <div className="segment-buttons">
                            <Button index="add" type="medium" onClick={this.goToStep}>Done</Button>
                        </div>
                    </div>
                </Step>

            </AddListItem>
        )
    }

}
