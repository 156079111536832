import React from 'react';
import u from '../utilities/Utilities';
import moment from 'moment';
import PropTypes from 'prop-types';
import "../styles/notebook.scss";
import {TextButton, Button, MiniMinusButton} from '../components/Input';

export default class Notebook extends React.Component{

    state={
        content:[],
        currentText:"",
        active:false,
        deleteConfirm:false,
        numPages:1,
        totalPages:1,
        permissions:{}
    };

    id=this.props.notebookID;
    scroll=true;
    deleting=false;

    componentDidMount=()=>{
        this.setState({
            permissions:u.getPermissions("staff"),
        });
    }

    componentDidUpdate=()=>{
        if(this.state.scroll){
            this.setState({scroll:false});
            this.scrollToEnd();
        }
    }

    getData=(notebookID,numPages)=>{
        u.post({
            url: "/api/get-notebook",
            data: {
                notebookID: (notebookID) ? notebookID : this.props.notebookID,
                numPages: (numPages) ? numPages : this.state.numPages,
            },
            success: ({pageContent,totalPages})=>{
                this.setState({content:pageContent,totalPages:totalPages,loading:false});
                setTimeout(()=>{
                  this.scrollToEnd();
                },250);

            },
            error: (error)=> {
                this.setState({error:error,loading:false});
            }
        });
    }

    onLoadOlder=()=>{
        let numPages = this.state.numPages + 1;
        this.setState({loading:true,numPages:numPages});
        this.getData(this.id,numPages);
    }

    scrollToEnd=()=>{
        let scrollArea = document.getElementById("scrollArea");
        scrollArea.scrollTop = scrollArea.scrollHeight;
    }

    addNote=()=>{
        if(this.state.currentText.length == 0){
            return 0;
        }

        const noteInput = this.state.currentText.trim();
        const newNote = {
            staffName:u.getFullName(),
            staffID:u.getUserID(),
            staffAvatarLink:u.getUserAvatar(),
            dateTime:moment().format("YYYY-MM-DDTHH:mm:ss"),
            note: noteInput
        };

        u.post({
            url:"/api/add-note",
            data:{
                notebookID:this.props.notebookID,
                module:this.props.module,
                moduleID:this.props.moduleID,
                newNote:newNote
            },
            success:(e)=>{
                let content = this.state.content;
                content.push(newNote);
                this.setState({content:content,currentText:"",scroll:true});

                //Returns notebookID if it exists
                if(this.id == null || typeof this.id == "undefined"){
                    this.id = e.notebookID;
                }
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    toggleNotebook=()=>{
        const active = !this.state.active;
        if(active){
            this.getData();
        }
        this.setState({active:active});
    }

    updateCurrentText=(e)=>{
        this.setState({currentText:e.currentTarget.value});
    }

    onDelete=(content,index)=>{
        this.deleting=true;
        this.setState({delete:{content:content,index:index},deleteConfirm:true});
    }

    onConfirmDelete=()=>{
        this.setState({deleteConfirm:false});
        u.post({
            url:"/api/delete-note",
            data:{
                notebookID:this.props.notebookID,
                note:this.state.delete.content
            },
            success:()=>{
                let content = this.state.content;
                content.splice(this.state.delete.index,1);
                this.setState({content:content,deleteConfirm:false});
                this.deleting=false;
            },
            error:(error)=>{
                this.setState({error:error});
                this.deleting=false;
            }
        })
    }

    render=()=>{
        return(
            <div className={"notebook-container no-print" + ((this.state.active) ? " open" : "") + ((this.props.noPageMenu) ?  " no-menu" : "")}>
                <div className="notebook-title" onClick={this.toggleNotebook}>
                    <img className="notebook-icon" src="/images/notebook/notebook.png"/>
                </div>

                <div className="notebook-content-container">
                    <div className="note-content" id="scrollArea">
                        {
                            (this.state.numPages < this.state.totalPages)
                            ?
                            <div style={{textAlign:"center",fontSize:"0.75em",margin:"10px auto 20px auto"}}>
                                <TextButton enabled={!this.state.loading} onClick={this.onLoadOlder}>See More</TextButton>
                            </div>
                            :
                            null
                        }
                        {
                            this.state.content.map(
                                (note,index)=>{
                                    return  <Note content={note} key={index} index={index} onDelete={this.onDelete} permissions={this.state.permissions}/>

                                }
                            )
                        }
                    </div>

                    <div className="note-input">
                        <form onSubmit={this.addNote}>
                            <textarea className="notebook-add-content" value={this.state.currentText} onChange={this.updateCurrentText} placeholder="Enter a shared note.."/>
                        </form>
                        <div className="notebook-add" onClick={this.addNote}>
                            <div className="note-input-button-v-guide"></div>
                            <div>Add</div>
                        </div>
                    </div>
                </div>

                {
                    (this.state.deleteConfirm)
                    ?
                    <div className="delete-overlay">
                        <h3 className="text-negative">Delete this post?</h3>
                        <div style={{fontSize:"0.85em"}}>Note. This action cannot be undone</div>
                        <div style={{margin:"20px auto"}}>
                            <div className="page-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({deleteConfirm:false})}}>Back</Button>
                            <Button onClick={this.onConfirmDelete}>Confirm</Button>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}
Notebook.propTypes={
    notebookID:PropTypes.string,
    module:PropTypes.string.isRequired,
    moduleID:PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

class Note extends React.Component{

    render=()=>{
        const dateFormat = (moment().isSame(this.props.content.dateTime,"day")) ? "hh:mma" :"DD MMM YY";
        const isMine = (u.getUserID() == this.props.content.staffID);
        const canDelete = isMine || (this.props.permissions.delete == 2);

        return (
            <div className={"note-item-container" + ((isMine) ? " owner" : "")}>
                <div className="note-item">
                    <div className="note-message">{this.props.content.note}</div>
                    <div className="note-details">
                        <a href={"/staff/"+this.props.content.staffID} target="_blank">{this.props.content.staffName}</a>
                        <span>{moment(this.props.content.dateTime).format(dateFormat)}</span>
                        {
                            (canDelete)
                                ?
                                <TextButton style={{margin:"0 5px 0 10px"}} onClick={()=>{this.props.onDelete(this.props.content,this.props.index)}}>Delete</TextButton>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        )
    }
}
