export const LineThickness=[
    {value:"1",label:"Thin"},
    {value:"2",label:"Normal"},
    {value:"3",label:"Thick"},
];

export const PointRadius=[
    {value:"2",label:"Medium"},
    {value:"1",label:"Small"},
    {value:"3",label:"Large"},
    {value:"0",label:"None"},
];

export const Fill=[
    {value:false,label:"No"},
    {value:true,label:"Yes"}
];

export const ShowPointValues=[
    {value:false,label:"Hide"},
    {value:true,label:"Show"},
];

export const LineType=[
    {value:"solid",label:"Solid"},
    {value:"dotted",label:"Dotted"},
    {value:"solidCurve",label:"Solid Curve"},
    {value:"dottedCurve",label:"Dotted Curve"},
];
