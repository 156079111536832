import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import Notebook from '../components/Notebook';
import {Databox,PageMenu,UploadPhoto,Segment,SegmentComplete,
        LoadGraphic,DynamicDetailArea,Step,Dropzone} from '../components/Common';
import {EditableBox,EditableSelect,EditableDate,EditableTime,
        EditSizeLabel,EditSizeLink,Button,Area,Select,Box,Time,
        Date,TextButton,MiniMinusButton,Info,EmptyBox,PageSelector} from '../components/Input';
import {TenantCardTemplate} from '../pages/PrintCard';
import {Photo} from '../data/Mimetypes';
import {AddAbsenceReason,MiniAbsenceReasonList} from '../pages/AbsenceReasonList';
import {MiniMisconductList} from '../pages/MisconductList';
import {AddReportSick,MiniReportSickList} from './ReportSickList';
import {AccessoryOwner} from './AccessoryList';
import '../styles/resident.scss';
import moment from 'moment';

export default class Resident extends React.Component{

    tenantID=this.props.match.params.tenantID;

    state={
        error:"",
        permissions:{},
        content:{
            history:[],
            future:[],
            vitalsList:[],
            covidList:[],
            reportSickList:[],
            accessories:[],
        },
        pageMenuButtons:[],
        options:{},
        combinedPermissions:{},
    };

    settings={
        /**** Page Setup ****/
        title:"Resident",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/resident",
        label:"resident",
        hasNotebook:true,
    };

    componentDidMount=()=>{
        if(this.tenantID){
            const permissions = u.getPermissions(this.settings.permission);
            this.setState({
              pageMenuButtons:[
                  {label:"Access",mobileLabel:"Access Logs",ref:"access",image:"/images/nav-menu/security.png",title:"View access logs",enabled:(permissions.view==2),padding:"6px",onClick:this.goToAccessLogs},
                  {label:"Photo",ref:"photo",mobileLabel:"Upload Photo",image:"/images/page-menu/upload-photo.png",title:"Upload resident photo",enabled:(permissions.update==2),padding:"3px"},
                  {label:"Print Card",mobileLabel:"Print Card",ref:"printCard",image:"/images/page-menu/print-card.png",title:"Print access card",enabled:(permissions.view==2),padding:"2px"},
                  {label:"Absence Reason",ref:"addAbsenceReason",title:"Set absence reason for this resident",enabled:(permissions.update==2),padding:"2px"},
                  {label:"Report Sick",ref:"addReportSick",title:"Create report sick entry for this resident",enabled:(permissions.update==2),padding:"2px"},
              ],
              permissions:permissions,
              combinedPermissions:u.getPermissions("combined"),
            });
            this.getContent(this.tenantID);
            this.getOptions();
        }
        else {
            this.setState({error:"Resident Not Found"});
        }
    }

    getOptions=()=>{
      u.post({
        url:"/api/get-options",
        data:{
            keys:[
                "covidStatus",
                "vitalsType",
                "yesNo",
            ],
        },
        success:(options)=>{
            this.setState({options:options});
        },
        error:(error)=>{
            this.setState({error:error});
        }
      })
    }

    goToAccessLogs=()=>{
        const start = moment().format("DD-MM-YYYY");
        const end = moment().subtract(7,"days").format("DD-MM-YYYY");
        window.open("/security-list?tags=@accessCard("+this.state.content.rfid+")~:dateStart("+end+")~:dateEnd("+start+")","_blank");
    }

    getContent=(tenantID)=>{
        u.post({
            url:"/api/get-tenant",
            data:{
                tenantID:tenantID,
            },
            success:(callback)=>{
                this.setState({content:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,onError)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    onAbsenceReason=()=>{
       const content=this.state.content;
       window.open("/absence-reason-list?create="+content.nric,"_blank");
    }

    render=()=>{
        const content=this.state.content;
        const combinedPermissions=this.state.combinedPermissions;
        const pageComponents=(content.tenantID) ? {
            photo:{
                component:UploadPhoto,
                props:{
                  id:this.tenantID,
                  url:"/api/commit-tenant-avatar",
                  fieldname:"avatarLink",
                  onUpload:this.onContentChange
              }
            },
            printCard:{
                component:PrintAccessCard,
                props:{
                  content:content
              }
            },
            addAbsenceReason:{
                component:AddAbsenceReason,
                props:{
                    dormID:content.dormID,
                    tenantFIN:content.nric,
                    reference:"addAbsenceReason",
                    onRefreshList:()=>{
                        if(this.refs['mini-absence-reason']){
                            this.refs['mini-absence-reason'].refresh();
                        }
                    },
                    onToggleForm:()=>{

                    }
                }
            },
            addReportSick:{
                component:AddReportSick,
                props:{
                    tenantFIN:content.nric,
                    reference:"addReportSick",
                    onRefreshList:()=>{
                        if(this.refs['mini-report-sick']){
                            this.refs['mini-report-sick'].refresh();
                        }
                    },
                    onToggleForm:()=>{

                    }
                }
            }
        } : {};

        return (
            <AppLayout settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={pageComponents}
                          />

                <DynamicDetailArea settings={this.settings}
                                   content={content}
                                   onChange={this.onContentChange}/>

                <div className="no-print">

                    {
                        (combinedPermissions.accessory)
                            ?
                            <AccessoryOwner content={content.accessories}
                                            dormID={content.dormID}
                                            ownershipType="Resident"
                                            onChange={this.onContentChange}
                                            resident={content}
                                            />
                            :
                            null
                    }

                    <Databox active={true} style={{paddingLeft:"10px",paddingRight:"10px"}} title="Absence Reason">
                        <MiniAbsenceReasonList ref="mini-absence-reason"
                                               tags={[
                                                 "@residentID("+this.tenantID+")"
                                               ]}
                                               pageLength={10}
                                               />
                    </Databox>

                    <Databox active={true} style={{paddingLeft:"10px",paddingRight:"10px"}} title="Misconduct">
                        <MiniMisconductList ref="mini-misconduct"
                                            tags={[
                                               "@residentID("+this.tenantID+")"
                                            ]}
                                            pageLength={10}
                                            />
                    </Databox>

                    <Databox active={true} title="Report Sick">
                        <MiniReportSickList ref="mini-report-sick"
                                            tags={[
                                              "@residentID("+this.tenantID+")"
                                            ]}
                                            pageLength={10}
                                            />
                    </Databox>

                    <Databox active={true} title="Vitals Records">
                        <MonitorVitals content={content} onChange={this.onContentChange} options={this.state.options}/>
                    </Databox>

                    <Databox active={true} title="Covid Test Records">
                        <MonitorCovid content={content} onChange={this.onContentChange} options={this.state.options}/>
                    </Databox>

                    <Databox active={(content.history.length>0)} title="Room History">
                      <div className="tenant-client-history">
                          <table>
                              <tbody>
                                  <tr>
                                      <th className='temp-list-remove'/>
                                      <th className="tch-period">Period</th>
                                      <th className="tch-client">Organization</th>
                                      <th className="tch-dorm">Location</th>
                                  </tr>

                          {
                              content.history.map(
                                  (item,index)=>{
                                      return  (
                                        <tr className="tch-item" key={index}>
                                          <td className='temp-list-remove'/>
                                          <td className="tch-period">{moment(item.dateStart).format("DD MMM YY") + " - " + ((item.dateEnd) ? moment(item.dateEnd).format("DD MMM YY") : "Present")}</td>
                                          <td className="tch-client"><a href={"/client/"+item.clientID}>{item.clientName}</a></td>
                                          <td className="tch-dorm">
                                              {
                                                (item.location.sickbayID)
                                                  ?
                                                  item.location.dormName + ((item.location.clusterName) ? " > Zone " + item.location.clusterName : "") + ((item.location.buildingName) ? " > Blk" + item.location.buildingName : "") + " > Sickbay " + item.location.sickbayName + "/" + item.location.sickbedName
                                                  :
                                                  item.location.dormName +" > Zone " + item.location.clusterName + " > Blk " + item.location.buildingName + " > #" + item.location.levelName + "-"  + item.location.roomName + "/" + item.location.bedName
                                              }
                                          </td>
                                      </tr>
                                    )
                                  }
                              )
                          }
                          </tbody>
                      </table>
                      </div>
                    </Databox>

                    <Databox active={(content.future.length > 0)} title="Room Future">
                      <div className="tenant-client-history">
                          <table>
                              <tbody>
                                  <tr>
                                      <th className='temp-list-remove'/>
                                      <th className="tch-period">Period</th>
                                      <th className="tch-client">Organization</th>
                                      <th className="tch-dorm">Location</th>
                                  </tr>

                          {
                              content.future.map(
                                  (item,index)=>{
                                      return  (
                                        <tr className="tch-item" key={index}>
                                          <td className='temp-list-remove'/>
                                          <td className="tch-period">{moment(item.dateStart).format("DD MMM YY") + " - " + ((item.dateEnd) ? moment(item.dateEnd).format("DD MMM YY") : "Present")}</td>
                                          <td className="tch-client"><a href={"/client/"+item.clientID}>{item.clientName}</a> (<a href={"/agreement/"+item.agreementID}>{item.agreementReference}</a>)</td>
                                          <td className="tch-dorm">
                                              {
                                                (item.location.sickbayID)
                                                  ?
                                                  item.location.dormName + ((item.location.clusterName) ? " > Zone " + item.location.clusterName : "") + ((item.location.buildingName) ? " > Blk" + item.location.buildingName : "") + " > Sickbay " + item.location.sickbayName + "/" + item.location.sickbedName
                                                  :
                                                  item.location.dormName +" > Zone " + item.location.clusterName + " > Blk " + item.location.buildingName + " > #" + item.location.levelName + "-"  + item.location.roomName + "/" + item.location.bedName
                                              }
                                          </td>
                                      </tr>
                                    )
                                  }
                              )
                          }
                          </tbody>
                      </table>
                      </div>
                    </Databox>
                </div>

                <Notebook module={"tenant"} moduleID={this.tenantID} notebookID={content.notebookID}/>
            </AppLayout>
        )
    }
}

class PrintAccessCard extends React.Component{

    state={
      active:false,
      loading:false,
    };

    show=(show)=>{
        this.setState({active:show});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onPrint=()=>{
        let content = Object.assign({},this.props.content);
        content['category']="tenant";
        if(this.state.overrideTheme){
            content.overrideTheme=this.state.overrideTheme;
        }
        store.set("print-card",content);
        window.open("/print-card","_blank","width=800,height=800,left=200,top=100");
    }

    onPDF=()=>{
        this.refs['card'].print();
    }

    render=()=>{
        const content=this.props.content;
        return (
            <Segment active={this.state.active}>
                <div className="list-add">
                    <div className="segment-title">Print Access Card</div>
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <LoadGraphic active={this.state.loading} text={"Processing..."}/>
                        {
                            (Array.isArray(content.cardTheme) && content.cardTheme.length > 0)
                                ?
                                <div style={{margin:"20px auto",width:"350px",maxWidth:"100%"}}>
                                    <Select values={{
                                              value:this.state.overrideTheme,
                                              options:content.cardTheme,
                                            }}
                                            label="card-theme"
                                            onChange={(value)=>{this.setState({overrideTheme:value})}}
                                            />
                                </div>
                                :
                                null
                        }
                        <div className="view" style={{border:"1px solid black",display:"inline-block"}}>
                            <TenantCardTemplate ref="card"
                                                content={this.props.content}
                                                overrideTheme={this.state.overrideTheme}
                                                onStart={()=>{this.setState({loading:true})}}
                                                onEnd={()=>{this.setState({loading:false})}}
                                                onError={(error)=>{this.setState({error:error})}}
                                                />
                        </div>
                        <div className="segment-buttons">
                            <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                            <Button type="medium" onClick={this.onPrint}>Print</Button>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}

export class MonitorVitals extends React.Component{

    pageLength=10;

    state={
        permission:false,
        step:"list",
        error:"",
        loading:false,
        loadText:"",
        page:1,
        startIndex:0,
        endIndex:9,
        config:{}
    };

    componentDidMount=()=>{
        const permissions=u.getPermissions("resident");
        const config = store.get("vitalConfig");
        this.setState({
            permission:(permissions.update == 2),
            deletePermission:(permissions.delete == 2),
            config:config
        });
    }

    onAddEntry=()=>{
        this.setState({error:""});
        const key=["type","date","time","temperature","systolicBP","diastolicBP","oxygenSaturation","pulse","breathingDifficulty","chestPain","requiresAttention"];

        let data = {};
        let fd={};
        for(var i=0; i<key.length; i++){
            if(this.refs[key[i]]){
                const current = this.refs[key[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                if(key[i] != "date" && key[i] != "time"){
                    data[key[i]] = current.value;
                }
                else{
                    fd[key[i]] = current.value;
                }
            }
        }
        data.datetime=fd.date + "T" + fd.time;
        data.staffName=u.getFullName();
        data.staffID=u.getUserID();

        let postData = Object.assign({},data);
        postData.tenantID=this.props.content.tenantID;

        this.setState({loading:true,loadText:"Adding entry..."});

        u.post({
            url:"/api/create-vitals-entry",
            data:postData,
            success:()=>{
                let vitalsList = this.props.content.vitalsList;
                if(typeof vitalsList == "undefined"){
                    vitalsList = [];
                }
                vitalsList.unshift(data);
                this.props.onChange(vitalsList,"vitalsList");
                this.setState({error:"",step:"list",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    };

    onDownload=()=>{
        this.setState({loading:true,loadText:"Preparing spreadsheet..."});
        const {overrideURL}=this.props;
        u.autoDownload({
            url:(overrideURL?.generateFileURL) ? overrideURL.generateFileURL : "/api/download-tenant-vitals",
            data:{
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.setState({loading:false});
            },
            error:(error)=>{this.setState({error:error,loading:false})},
            overrideURL:overrideURL?.downloadFileURL
        });
    };

    onDeleteRequest=(index,single)=>{
        this.setState({step:"remove",error:"",index:index,single:(single) ? true : false});
    }

    onDeleteConfirm=()=>{
      if(this.state.single == true){
          this.onDeleteSingle();
      }
      else{
        this.onDelete();
      }
    }

    onDelete=()=>{
        this.setState({loading:true,loadText:"Deleting entries..."});
        u.post({
            url:"/api/remove-all-vitals",
            data:{
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.props.onChange([],"vitalsList");
                this.setState({step:"list",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    };

    onDeleteSingle=()=>{
        this.setState({loading:true,loadText:"Deleting entry..."});
        const entry = this.props.content.vitalsList[this.state.index];
        u.post({
          url:"/api/remove-vital-entry",
          data:{
              tenantID:this.props.content.tenantID,
              entry:entry,
          },
          success:()=>{
              let vitalsList = this.props.content.vitalsList;
              vitalsList.splice(this.state.index,1);
              this.props.onChange(vitalsList,"vitalsList");
              this.setState({step:"list",error:"",loading:false});
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
        })
    }

    calculateWidth = (config) =>{
        let num = 0;
        for(var key in config){
            if(config[key]==true){
                if(key == "bloodPressure"){
                    num+=2;
                }
                else{
                    num++;
                }
            }
        }
        return ((100.0 - 30.0)/num) + "%";
    }

    onChangePage=(page)=>{
        let startIndex=(parseInt(page)-1)*this.pageLength;
        let endIndex=startIndex+this.pageLength-1;
        this.setState({page:page,startIndex:startIndex,endIndex:endIndex});
    }

    render=()=>{
        const content=this.props.content;
        const permission=(this.state.permission && !this.props.noUpdate);
        const deletePermission = (this.state.deletePermission  && !this.props.noUpdate);
        const editID = this.props.content.tenantID;
        const config = this.state.config;
        const cWidth = this.calculateWidth(config);
        const options= this.props.options;
        const page=this.state.page;
        const maxPage = Math.ceil(content.vitalsList.length / parseFloat(this.pageLength));

        return(
            <div className="temperature-container" style={{textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="page-error">{this.state.error}</div>
                <Step active={this.state.step=="list"}>
                    <div>
                        <div className="temperature-list">
                        {
                            (content.vitalsList.length > 0)
                                ?
                                <div>
                                    <div>
                                        <table style={{position:"relative",textAlign:"center",width:"100%"}}>
                                            <tbody>
                                                <tr>
                                                    <th className="temp-list-remove"/>
                                                    <th className="temp-list-long left">Date</th>
                                                    <th className="temp-list-long left">Type</th>
                                                    {config.temperature ? <th className="temp-list-short" style={{width:cWidth}} title="Body Temperature">TEMP</th> : null}
                                                    {config.bloodPressure ? <th className="temp-list-short" style={{width:cWidth}} title="Systolic Blood Pressure">SBP</th> : null}
                                                    {config.bloodPressure ? <th className="temp-list-short" style={{width:cWidth}} title="Diastolic Blood Pressure">DBP</th> : null}
                                                    {config.oxygenSaturation ? <th className="temp-list-short" style={{width:cWidth}} title="Oxygen Saturation">SpO2</th> : null}
                                                    {config.pulse ? <th className="temp-list-short" style={{width:cWidth}} title="Pulse Rate">PR</th> : null}
                                                    {config.breathingDifficulty ? <th className="temp-list-short" style={{width:cWidth}} title="Breathing Difficulty">B.DIFF</th> : null}
                                                    {config.chestPain ? <th className="temp-list-short" style={{width:cWidth}} title="Chest Pains">C.PAIN</th> : null}
                                                    {config.requiresAttention ? <th className="temp-list-short" style={{width:cWidth}} title="Requires Medical Attention">ATTN</th> : null}
                                                </tr>
                                                {
                                                    content.vitalsList.map(
                                                        (item,index)=>{
                                                            if(index < this.state.startIndex || index > this.state.endIndex){
                                                                return null;
                                                            }
                                                            const isToday = moment().format("DD-MM-YYYY") == moment(item.datetime).format("DD-MM-YYYY");
                                                            const isYest = moment().subtract(1,"days").format("DD-MM-YYYY") == moment(item.datetime).format("DD-MM-YYYY");
                                                            const badTemp = (item.temperature) ? (parseFloat(item.temperature) >= parseFloat(config.temperatureMax) || parseFloat(item.temperature) <= parseFloat(config.temperatureMin)) : false;
                                                            const badSBP = (item.systolicBP) ? (parseInt(item.systolicBP) <= parseInt(config.sysMin) || parseInt(item.systolicBP) >= parseInt(config.sysMax)) : false;
                                                            const badDBP = (item.diastolicBP) ? (parseInt(item.diastolicBP) <= parseInt(config.diaMin) || parseInt(item.diastolicBP) >= parseInt(config.diaMax)) : false;
                                                            const badOXY = (item.oxygenSaturation) ? (parseInt(item.oxygenSaturation) <= parseInt(config.oxygenMin) || parseInt(item.oxygenSaturation) >= parseInt(config.oxygenMax)) : false;
                                                            const badPulse = (item.pulse) ? (parseInt(item.pulse) <= parseInt(config.pulseMin) || parseInt(item.pulse) >= parseInt(config.pulseMax)) : false;

                                                            return(
                                                                <tr key={index} className="temperature-row">
                                                                    <td className="temp-list-remove">
                                                                        <div style={{width:"20px",height:'20px',margin:"auto"}} title="Delete Entry">
                                                                            <MiniMinusButton enabled={deletePermission} onClick={()=>{this.onDeleteRequest(index,true)}}/>
                                                                        </div>
                                                                    </td>
                                                                    <td className="temp-list-long left">{(isToday) ? "Today - " + moment(item.datetime).format("h:mma") : ((isYest) ? "Yesterday - " + moment(item.datetime).format("h:mma")  : moment(item.datetime).format("DD MMM YY - h:mma"))}</td>
                                                                    <td className="temp-list-long left">{item.type}</td>
                                                                    {config.temperature ? <td title="Body Temperature" style={{width:cWidth}} className={"temp-list-short" + (badTemp ? " text-negative" : "") }>{(item.temperature) ? item.temperature : "-"}</td> : null}
                                                                    {config.bloodPressure ? <td title="Systolic Blood Pressure" style={{width:cWidth}} className={"temp-list-short" + (badSBP ? " text-negative" : "") }>{(item.systolicBP) ? item.systolicBP : "-"}</td> : null}
                                                                    {config.bloodPressure ? <td title="Diastolic Blood Pressure" style={{width:cWidth}} className={"temp-list-short" + (badDBP ? " text-negative" : "") }>{(item.diastolicBP) ? item.diastolicBP : "-"}</td> : null}
                                                                    {config.oxygenSaturation ? <td title="Oxygen Saturation" style={{width:cWidth}} className={"temp-list-short" + (badOXY ? " text-negative" : "") }>{(item.oxygenSaturation) ? item.oxygenSaturation : "-"}</td> : null}
                                                                    {config.pulse ? <td title="Pulse Rate" style={{width:cWidth}} className={"temp-list-short" + (badPulse ? " text-negative" : "") }>{(item.pulse) ? item.pulse : "-"}</td> : null}
                                                                    {config.breathingDifficulty ? <td className="temp-list-short" style={{width:cWidth}} title="Breathing Difficulty">{(item.breathingDifficulty) ? <span className="text-negative">Yes</span> : "-"}</td> : null}
                                                                    {config.chestPain ? <td className="temp-list-short" style={{width:cWidth}} title="Chest Pains">{(item.chestPain) ? <span className="text-negative">Yes</span> : "-"}</td> : null}
                                                                    {config.requiresAttention ? <td className="temp-list-short" style={{width:cWidth}} title="Requires Medical Attention">{(item.requiresAttention) ? <span className="text-negative">Yes</span> : "-"}</td> : null}
                                                                </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            (maxPage > 1)
                                                ?
                                                <div style={{margin:"30px auto 20px auto"}}>
                                                    <PageSelector page={page}
                                                                  maxPage={maxPage}
                                                                  onChange={this.onChangePage}
                                                                  />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                    <div style={{margin:"20px auto 0 auto"}}>
                                        {
                                            (deletePermission)
                                                ?
                                                <Button type="medium" onClick={this.onDeleteRequest}>Delete All</Button>
                                                :
                                                null
                                        }
                                        <Button type="medium" onClick={this.onDownload}>Download</Button>
                                        {
                                            (permission)
                                                ?
                                                <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Add Entry</Button>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                                :
                                <div className="temperature-list-placeholder" style={{padding:"20px 0"}}>
                                    <i>
                                        No Data Captured<br/><br/>
                                        Resident will be barred from entering the dormitory if no entry taken within last 24 hours
                                    </i>
                                    {
                                        (permission)
                                            ?
                                            <div style={{margin:"20px auto"}}>
                                                <TextButton onClick={()=>{this.setState({step:"add"})}}>Add Entry</TextButton>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                        }
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="add"}>
                    <div className="vitals-input">
                        <div className="segment-full-item">
                            <div className="segment-item-label">Type:</div>
                            <div className="segment-item-input">
                                <Select ref="type" field="type" label="Type" values={{options:options.vitalsType}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Date:</div>
                            <div className="segment-item-input">
                                <Date ref="date" field="date" label="Date" range={[2,1]} rules={{maxDate:moment().format("YYYY-MM-DD")}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Time:</div>
                            <div className="segment-item-input">
                                <Time ref="time" field="time" label="Time" rules={{skipSeconds:true}}/>
                            </div>
                        </div>

                        {
                            (config.temperature)
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Temperature:</div>
                                    <div className="segment-item-input">
                                        <Box ref="temperature" field="temperature" label="Temperature" rules={{required:true,minValue:30,maxValue:45,noLetters:true}}/>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {
                            (config.bloodPressure)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Systolic BP (Top):</div>
                                <div className="segment-item-input">
                                    <Box ref="systolicBP" field="systolicBP" label="Systolic BP" type="number" rules={{required:true,minValue:0,maxValue:300}}/>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.bloodPressure)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Diastolic BP (Bottom):</div>
                                <div className="segment-item-input">
                                    <Box ref="diastolicBP" field="diastolicBP" label="Diastolic BP" type="number" rules={{required:true,minValue:0,maxValue:300}}/>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.oxygenSaturation)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Oxygen Saturation:</div>
                                <div className="segment-item-input">
                                    <Box ref="oxygenSaturation" field="oxygenSaturation" label="Oxygen Saturation" type="number" rules={{required:true,minValue:0,maxValue:100}}/>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.pulse)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Pulse:</div>
                                <div className="segment-item-input">
                                    <Box ref="pulse" field="pulse" label="Pulse" type="number" rules={{required:true,minValue:0,maxValue:300}}/>
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.breathingDifficulty)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Breathing Difficulty:</div>
                                <div className="segment-item-input">
                                    <Select ref="breathingDifficulty" field="breathingDifficulty" label="Breathing Difficulty" values={{options:options.yesNo}} />
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.chestPain)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Chest Pain:</div>
                                <div className="segment-item-input">
                                    <Select ref="chestPain" field="chestPain" label="Chest Pain" values={{options:options.yesNo}} />
                                </div>
                            </div>
                            :
                            null
                        }
                        {
                            (config.requiresAttention)
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Requires Attention:</div>
                                <div className="segment-item-input">
                                    <Select ref="requiresAttention" field="requiresAttention" label="Requires Attention" values={{options:options.yesNo}} />
                                </div>
                            </div>
                            :
                            null
                        }
                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" onClick={()=>{this.setState({step:"list"})}}>Back</Button>
                            <Button type="medium" onClick={this.onAddEntry}>Add Entry</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{padding:"30px 0"}}>
                        <h2 className="text-negative">Confirm Delete {(this.state.single) ? "Entry" : "All Entries"}?</h2>
                        <div>Note. This action cannot be reversed</div>

                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" onClick={()=>{this.setState({step:"list"})}}>Back</Button>
                            <Button type="medium" onClick={this.onDeleteConfirm}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

export class MonitorCovid extends React.Component{

    pageLength=10;

    state={
        permission:false,
        step:"list",
        error:"",
        loading:false,
        loadText:"",
        page:1,
        startIndex:0,
        endIndex:9,
    };

    componentDidMount=()=>{
        const permissions=u.getPermissions("resident");
        this.setState({
            permission:(permissions.update == 2),
            deletePermission:(permissions.delete == 2),
        });
    }

    onAddEntry=()=>{
        this.setState({error:""});
        const key=["reference","date","time","result","resultDate"];
        let data = {};
        let fd={};
        for(var i=0; i<key.length; i++){
            const current = this.refs[key[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            if(key[i] != "date" && key[i] != "time"){
                data[key[i]] = current.value;
            }
            else{
                fd[key[i]] = current.value;
            }
        }
        data.testDatetime=fd.date + "T" + fd.time;
        data.staffName=u.getFullName();
        data.staffID=u.getUserID();
        data.photo=this.state.photo;

        let postData = Object.assign({},data);
        postData.tenantID=this.props.content.tenantID;

        this.setState({loading:true,loadText:"Adding entry..."});

        u.post({
            url:"/api/create-covid-entry",
            data:postData,
            success:(newData)=>{
                let covidList = this.props.content.covidList;
                if(typeof covidList == "undefined"){
                    covidList = [];
                }

                covidList.unshift(newData);
                this.props.onChange(covidList,"covidList");
                this.setState({error:"",step:"list",loading:false,photo:null});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    };

    onPhotoUploaded=(url)=>{
        this.setState({photo:url,uploading:false});
    }

    onDownload=()=>{
        this.setState({loading:true,loadText:"Preparing spreadsheet..."});
        const {overrideURL}=this.props;
        u.autoDownload({
            url:(overrideURL?.generateFileURL) ? overrideURL.generateFileURL : "/api/download-tenant-covid-list",
            data:{
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.setState({loading:false});
            },
            error:(error)=>{this.setState({error:error,loading:false})},
            overrideURL:overrideURL?.downloadFileURL
        });
    };

    onDeleteRequest=(index,single)=>{
        this.setState({step:"remove",error:"",index:index,single:(single) ? true : false});
    }

    onDeleteConfirm=()=>{
      if(this.state.single == true){
          this.onDeleteSingle();
      }
      else{
        this.onDelete();
      }
    }

    onDelete=()=>{
        this.setState({loading:true,loadText:"Deleting entries..."})
        u.post({
            url:"/api/remove-all-covid",
            data:{
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.props.onChange([],"covidList");
                this.setState({step:"list",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    };

    onDeleteSingle=()=>{
        this.setState({loading:true,loadText:"Deleting entry..."});
        const entry = this.props.content.covidList[this.state.index];
        u.post({
          url:"/api/remove-covid-entry",
          data:{
              tenantID:this.props.content.tenantID,
              entry:entry,
          },
          success:()=>{
              let covidList = this.props.content.covidList;
              covidList.splice(this.state.index,1);
              this.props.onChange(covidList,"covidList");
              this.setState({step:"list",error:"",loading:false});
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
        })
    }

    onChange=(value,label,index,option)=>{
        let covidList = this.props.content.covidList;
        covidList[index][label] = value;
        this.props.onChange(covidList,"covidList");
    }

    onChangePage=(page)=>{
        let startIndex=(parseInt(page)-1)*this.pageLength;
        let endIndex=startIndex+this.pageLength-1;
        this.setState({page:page,startIndex:startIndex,endIndex:endIndex});
    }

    render=()=>{
        const content=this.props.content;
        const permission=(this.state.permission && !this.props.noUpdate);
        const deletePermission=(this.state.deletePermission && !this.props.noUpdate);
        const options=this.props.options;
        const page=this.state.page;
        const maxPage = Math.ceil(content.covidList.length / parseFloat(this.pageLength));

        return(
            <div className="temperature-container" style={{textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="page-error">{this.state.error}</div>
                <Step active={this.state.step=="list"}>
                    <div className="temperature-list">
                        {
                            (content.covidList.length > 0)
                                ?
                                <div>
                                    <div>
                                        <table style={{position:"relative",textAlign:"center",width:"100%",minWidth:"650px"}}>
                                            <tbody>
                                                <tr>
                                                    <th className="temp-list-remove"/>
                                                    <th className="temp-list-item">Reference</th>
                                                    <th className="temp-list-item fixed">Test Date</th>
                                                    <th className="temp-list-item fixed">Result</th>
                                                    <th className="temp-list-item fixed">Result Date</th>
                                                    <th className="temp-list-item fixed">Staff</th>
                                                    <th className="temp-list-photo">Photo</th>
                                                </tr>
                                                {
                                                    content.covidList.map(
                                                        (item,index)=>{
                                                            if(index < this.state.startIndex || index > this.state.endIndex){
                                                                return null;
                                                            }

                                                            const isToday = moment().format("DD-MM-YYYY") == moment(item.testDatetime).format("DD-MM-YYYY");
                                                            const isYest = moment().subtract(1,"days").format("DD-MM-YYYY") == moment(item.testDatetime).format("DD-MM-YYYY");
                                                            const badCovid = (item.result != "Negative" && item.result != "None" && typeof item.result != "undefined");
                                                            const editID = {tenantID:this.props.content.tenantID,entry:item};

                                                            return(
                                                                <tr key={index} className="temperature-row">
                                                                    <td className="temp-list-remove">
                                                                        <div style={{width:"20px",height:"20px",margin:"auto"}} title="Delete Entry">
                                                                            <MiniMinusButton enabled={deletePermission} onClick={()=>{this.onDeleteRequest(index,true)}}/>
                                                                        </div>
                                                                    </td>
                                                                    <td className="temp-list-item">{item.reference}</td>
                                                                    <td className="temp-list-item fixed">{(item.testDatetime) ? ((isToday) ? "Today - " + moment(item.testDatetime).format("h:mma") : ((isYest) ? "Yesterday - " + moment(item.testDatetime).format("h:mma")  : moment(item.testDatetime).format("DD MMM YY - h:mma"))) : "None"}</td>
                                                                    <td className={"temp-list-item fixed" + (badCovid ? " text-negative" : "")}>
                                                                        <EditableSelect field="result" 
                                                                                        values={{
                                                                                            value:item.result,
                                                                                            options:options.covidStatus,
                                                                                            enabled:permission
                                                                                        }} 
                                                                                        label="Result" 
                                                                                        onChange={this.onChange} 
                                                                                        index={index+""} 
                                                                                        id={editID} 
                                                                                        url={"/api/update-covid-list"} 
                                                                                        rules={{nullText:"None"}}
                                                                                        />
                                                                    </td>
                                                                    <td className={"temp-list-item fixed" + (badCovid ? " text-negative" : "")}>
                                                                        <EditableDate field="resultDate" values={{value:item.resultDate,enabled:permission}} label="Result Date" onChange={this.onChange} index={index+""} id={editID} url={"/api/update-covid-list"} rules={{minDate:moment(item.testDatetime).format("YYYY-MM-DD"),maxDate:moment().format("YYYY-MM-DD"),nullText:"None"}}/>
                                                                    </td>
                                                                    <td className="temp-list-item fixed">
                                                                        {
                                                                          (item.staffName)
                                                                            ?
                                                                            item.staffName
                                                                            :
                                                                            "Self-Submitted"
                                                                        }
                                                                    </td>
                                                                    <td className="temp-list-photo">
                                                                        {
                                                                            (item.photo)
                                                                                ?
                                                                                <img onClick={()=>{window.open(item.photo,"_blank")}}
                                                                                     src="/images/filetypes/picture.png" className="covid-evidence"/>
                                                                                :
                                                                                null
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        {
                                            (maxPage > 1)
                                                ?
                                                <div style={{margin:"30px auto 20px auto"}}>
                                                    <PageSelector page={page}
                                                                  maxPage={maxPage}
                                                                  onChange={this.onChangePage}
                                                                  />
                                                </div>
                                                :
                                                null
                                        }
                                    </div>

                                    <div style={{margin:"20px auto 0 auto"}}>
                                        {
                                            (deletePermission)
                                                ?
                                                <Button type="medium" onClick={this.onDeleteRequest}>Delete All</Button>
                                                :
                                                null
                                        }
                                        <Button type="medium" onClick={this.onDownload}>Download</Button>
                                        {
                                            (permission)
                                                ?
                                                <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Add Entry</Button>
                                                :
                                                ""
                                        }
                                    </div>
                                </div>
                                :
                                <div className="temperature-list-placeholder" style={{padding:"20px 0"}}>
                                    <i>
                                        No Data Captured<br/><br/>
                                    </i>
                                    {
                                        (permission)
                                            ?
                                            <div style={{margin:"20px auto"}}>
                                                <TextButton onClick={()=>{this.setState({step:"add"})}}>Add Entry</TextButton>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                        }
                    </div>
                </Step>

                <Step active={this.state.step=="add"}>
                    <div className="vitals-input">
                        <div className="segment-full-item">
                            <div className="segment-item-label">Reference:</div>
                            <div className="segment-item-input">
                                <Box ref="reference" field="reference" label="Reference" rules={{required:true,placeholder:"Test Identifier"}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Date:</div>
                            <div className="segment-item-input">
                                <Date ref="date" field="date" label="Date" range={[2,1]} rules={{maxDate:moment().format("YYYY-MM-DD")}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Time:</div>
                            <div className="segment-item-input">
                                <Time ref="time" field="time" label="Time" rules={{skipSeconds:true}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Result:</div>
                            <div className="segment-item-input">
                                <Select ref="result" field="result" label="Result" values={{options:options.covidStatus}}/>
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Result Date:</div>
                            <div className="segment-item-input">
                                <Date ref="resultDate" field="resultDate" label="Result Date" range={[2,1]} rules={{addEmpty:true,maxDate:moment().format("YYYY-MM-DD")}}/>
                            </div>
                        </div>

                        <div className='segment-full-item'>
                            <div className="segment-item-label">Photo:</div>
                            <div className="segment-item-input">
                                <EmptyBox style={{position:"relative",padding:"5px",overflow:"hidden"}}>
                                    {
                                        (this.state.uploading)
                                            ?
                                            "Loading..."
                                            :
                                            (this.state.photo)
                                                ?
                                                this.state.userFilename
                                                :
                                                "None"
                                    }
                                    <div style={{position:"absolute",top:0,left:0,width:"100%",height:"100%",opacity:0}}>
                                        <Dropzone label="result-photo"
                                                  accessibleForPreview={true}
                                                  onAdded={(filename)=>{this.setState({userFilename:filename,uploading:true})}}
                                                  onUploaded={this.onPhotoUploaded}
                                                  onError={(error)=>{this.setState({error:error,uploading:false})}}
                                                  filetypes={Photo}
                                                  />
                                    </div>
                                </EmptyBox>
                            </div>
                        </div>

                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" onClick={()=>{this.setState({step:"list"})}}>Back</Button>
                            <Button type="medium" onClick={this.onAddEntry}>Add Entry</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{padding:"30px 0"}}>
                        <h2 className="text-negative">Confirm Delete {(this.state.single) ? "Entry" : "All Entries"}?</h2>
                        <div>Note. This action cannot be reversed</div>

                        <div style={{margin:"20px auto"}}>
                            <Button type="medium" onClick={()=>{this.setState({step:"list"})}}>Back</Button>
                            <Button type="medium" onClick={this.onDeleteConfirm}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
