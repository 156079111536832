import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,
        DownloadTemplate,DynamicListArea,BulkEdit,LoadGraphic,
        Step,Segment,SegmentComplete,ResolutionStack} from '../components/Common';
import {EditSizeLabel,EditableBox,EditableSelect,Box,Input,
        Select,Date,Time,Area,TextButton,Button,Checkbox,
        Info,MiniMinusButton,ContactNumber} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import PropTypes, { number } from 'prop-types';
import {Spreadsheet} from '../data/Mimetypes';
import {EstateUtilities} from './Estate';
import "../styles/visitor-list.scss";
import moment from 'moment';
import store from 'store';

export default class VisitorList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Visitor",
        permission:"visitor",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/visitor-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/add-new-visitor",
        //templateURL:"/api/get-add-staff-template",
        //bulkURL:"/api/add-bulk-staff",
        deleteURL:"/api/delete-visitor-entries",
        spreadsheetURL:"/api/generate-visitor-spreadsheet",
        overviewURL:"/api/get-all-visitors",

        /**** List Setup ****/
        list:true,
        listLabel:"visitor-list",
        orderByField:"name",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        printCard:{component:BulkPrintCard,props:{}},
        presetCard:{component:PresetPasses,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("visitor");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");
        
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add", mobileLabel:"Add Visitor",ref:"add",image:"/images/page-menu/add.png",title:"Add new visitor",enabled:(permissions.create==2)},
                {label:"Delete", mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit staff details",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Print Card",mobileLabel:"Print Card",ref:"printCard",image:"/images/page-menu/print-card.png",title:"Bulk print access card",enabled:(permissions.create == 2),padding:"2px"},
                {label:"Preset Cards",mobileLabel:"Manage Preset Cards",ref:"presetCard",image:"/images/page-menu/print-card.png",title:"Manage preset access cards",enabled:(permissions.view == 2),padding:"2px"},
            ]
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <VisitorItem key={index} index={index} content={content} onChange={onChange} onContentChange={onContentChange} registerReference={registerRef} permissions={this.state.permissions}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class VisitorItem extends React.Component{

    state={
        error:"",
        step:"view",
        loading:false,
        loadText:"",
        clusterOptions:[],
        tempContent:{},
        passOptions:[],
        estateConfig:{
            clusterLabel:"Zone",
        },
        clusterOptions:[],
    };

    onChangeZone=()=>{
        this.getClusterOptions();
        this.setState({step:"change-zone",error:""});
    }

    onConfirmZoneChange=()=>{
        let zone=this.refs['zone'].validate();
        if(!zone.valid){
            this.setState({error:zone.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Updating Zone..."});
        u.post({
            url:"/api/update-visitor-zone",
            data:{
                visitorID:this.props.content.visitorID,
                clusterID:zone.value,
            },
            success:()=>{
                this.props.onChange(zone.option.label,"clusterName",this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onUnlockPassback=()=>{
        this.setState({loading:true,loadText:"Unlocking passback..."});
        u.post({
            url:"/api/unlock-passback",
            data:{
                type:"staff",
                id:this.props.content.staffID,
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.setState({step:"confirm-remove",loading:false});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    getPassOptions=(dormID)=>{
        u.post({
            url:"/api/get-preset-pass-options",
            data:{
                dormID:this.props.content.dormID
            },
            success:(passOptions)=>{
                passOptions.push({label:"Auto-Generated",value:"Auto-Generated"});
                this.setState({passOptions:passOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onSetContext=()=>{
        store.set("security-list","@id("+this.props.content.visitorID+")");
    }

    getClusterOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"cluster",
                id:this.props.content.dormID,
            },
            success:(clusterOptions)=>{
                clusterOptions.unshift({value:"all",label:"All"});
                this.setState({clusterOptions:clusterOptions});
            }
        })
    }

    onRegister=()=>{
        this.getPassOptions();
        this.getClusterOptions();

        let content=Object.assign({},this.props.content);
        let tempContent = {
            name:content.name,
            nric:content.nric,
            purposeOfEntry:content.purposeOfEntry,
            vehicleNumber:content.vehicleNumber,
            contactNumber:content.contactNumber,
            email:content.email,
            validStart:moment().format("YYYY-MM-DDT09:00:00"),
            validEnd:moment().format("YYYY-MM-DDT18:00:00"),
        };

        this.setState({
          step:"register",
          error:"",
          estateConfig:EstateUtilities.getDormConfig(),
          tempContent:tempContent,
        });
    }

    onPrintPass=()=>{
        let visitor = Object.assign({},this.props.content);
        visitor.category="visitor";
        store.set("print-card",visitor);
        window.open("/print-card","_blank","width=800,height=800,left=200,top=100");
    }

    onChangeTemp=(value,field)=>{
        let tempContent=this.state.tempContent;
        tempContent[field]=value;
        this.setState({tempContent:tempContent});
    }

    onRegisterValidate=(fields)=>{
        let keys=fields.map((item)=>{return item.field});
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }

        data.visitorID = this.props.content.visitorID;

        this.setState({loading:true,loadText:"Registering..."});
        u.post({
            url:"/api/register-pending-visitor",
            data:data,
            success:(entry)=>{
                this.props.onContentChange(entry,this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onManualScan=()=>{
        this.setState({loading:true,loadText:"Creating Access Entry...",error:""});
        const newLocation = (this.props.content.location == "In") ? 0 : 1;
        u.post({
            url:"/api/visitor-manual-scan",
            data:{
                visitorID:this.props.content.visitorID,
                action:(newLocation) ? "Entry" : "Exit",
            },
            success:()=>{
                this.props.onChange((newLocation) ? "In" : "Out","location",this.props.index);
                this.setState({loading:false,step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDecline=()=>{
        this.setState({loading:true,loadText:"Updating...",error:""});
        u.post({
            url:"/api/visitor-decline-entry",
            data:{
                visitorID:this.props.content.visitorID
            },
            success:()=>{
                this.props.onChange("Declined","status",this.props.index);
                this.setState({loading:false,step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const content = this.props.content;
        const tempContent = this.state.tempContent;
        const edit = (this.props.permissions.update == 2);
        const url = "/api/update-visitor-info";
        const estateConfig = this.state.estateConfig;
        const index = this.props.index+"";
        const id = content.visitorID;

        const verifyInput=[
            {dataType:"select",field:"clusterID",label:estateConfig.clusterLabel,options:this.state.clusterOptions},
            {dataType:"select",field:"passValue",label:"Visitor Pass",options:this.state.passOptions},
            {dataType:"date",field:"validStart",label:"Entry Date",range:[1,1]},
            {dataType:"time",field:"validStart",label:"Entry Time"},
            {dataType:"date",field:"validEnd",label:"Valid To Date",range:[1,1],rules:{minDate:tempContent.validStartDate}},
            {dataType:"time",field:"validEnd",label:"Valid To Time"},
            {dataType:"area",field:"comments",label:"Comments"},
        ];

        return (
            <div style={{textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={"visitor-list"} permission="visitor" content={content} onChange={this.props.onChange} index={this.props.index}/>

                    {
                        (content.status != "Declined" && edit)
                            ?
                            <div>
                                {
                                    (content.status != "Pending")
                                        ?
                                        <div className="list-segment-buttons">
                                            <Link style={{margin:"5px"}} to={"/security-list?tags=@id("+content.visitorID+")"}>See Visitor Logs</Link>
                                            {
                                                (false)
                                                    ?
                                                    <TextButton onClick={this.onPrintPass}>Print Turnstile Pass</TextButton>
                                                    :
                                                    null
                                            }
                                        </div>
                                        :
                                        null
                                }
                                <div className="list-segment-buttons">
                                    {
                                        (content.status == "Pending")
                                            ?
                                            <Button onClick={()=>{this.setState({step:"decline",error:""})}}>Decline Entry</Button>
                                            :
                                            null
                                    }
                                    {
                                        (content.status == "Pending")
                                            ?
                                            <Button onClick={this.onRegister}>Complete Registration</Button>
                                            :
                                            <Button onClick={()=>{this.setState({step:(content.location=="In") ? "manual-out" : "manual-in",error:""})}}>Manual Check-{content.location=="In" ? "Out" : "In"}</Button>
                                    }
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className="list-segment-buttons">
                        <ResolutionStack content={content.participants}/>
                    </div>
                </Step>

                <Step active={this.state.step=="change-zone"}>
                    <div style={{fontSize:"14px"}}>
                        <h2>Select Accessible Zones</h2>

                        <div className="segment-full-item" style={{margin:"15px auto",textAlign:"center"}}>
                            <div className="segment-item-input">
                                <Select ref="zone"
                                        label="Zones"
                                        values={{
                                              value:content.zone,options:this.state.clusterOptions
                                            }}
                                />
                            </div>
                        </div>

                        <div style={{margin:"15px auto"}}>Visitor's pass will only allow access to turnstiles for the respective zones</div>

                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onConfirmZoneChange}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="register"}>
                    <div style={{position:"relative",fontSize:"14px"}}>
                        <h3>Register Visitor Entry Pass</h3>
                        {
                            verifyInput.map(
                                (item,index)=>{
                                    return (
                                        <Input key={index}
                                               ref={item.field}
                                               fields={{
                                                 ...item,
                                                 onChange:this.onChangeTemp
                                               }}
                                               content={this.state.tempContent}
                                               />
                                    )
                                }
                            )
                        }

                        <div className="segment-buttons">
                            Skip this section if your dormitory does not issue entry passes
                        </div>

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={()=>{this.onRegisterValidate(verifyInput)}}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="manual-in"}>
                    <div style={{padding:"30px 0"}}>
                        <h2>Manually Check-In Visitor?</h2>
                        <div className="text-negative">Note. This will trigger anti-passback if the visitor attempts to scan into the dormitory turnstile</div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onManualScan}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="manual-out"}>
                    <div style={{padding:"30px 0"}}>
                        <h2>Manually Check-Out Visitor?</h2>
                        <div className="text-negative">Note. This will trigger anti-passback if the visitor attempts to scan out of the dormitory turnstile</div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onManualScan}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="decline"}>
                    <div style={{padding:"30px 0"}}>
                        <h2>Decline Visitor Entry?</h2>
                        <div className="text-negative">Note. Visitor will need to redo self-submission as this entry will be invalid</div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onDecline}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{},
        options:{purposeOfEntry:[]},
        dormOptions:[],
        emailSuggest:[],
        visitorList:[],
        clusterOptions:[],
        passOptions:[],
        step:"email",
        loading:false,
        loadText:"Checking email...",
        estateConfig:{
            clusterLabel:"Zone",
        },
    };

    componentDidMount=()=>{
        this.getOptions();
        this.getDormOptions();
        this.getEmailSuggest();

        this.setState({estateConfig:EstateUtilities.getDormConfig()});
    }

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["purposeOfEntry"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(dormOptions)=>{
                let entryData = this.state.entryData;
                if(typeof entryData.dormID == "undefined" && dormOptions.length > 0){
                    entryData.dormID = dormOptions[0].value;
                    this.getClusterOptions(dormOptions[0].value);
                }
                this.setState({dormOptions:dormOptions,entryData:entryData});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getClusterOptions=(id)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"cluster",
                id:id,
            },
            success:(clusterOptions)=>{
                clusterOptions.unshift({value:"all",label:"All"});
                this.setState({clusterOptions:clusterOptions});
            }
        })
    }

    getEmailSuggest=()=>{
        u.post({
            url:"/api/get-visitor-suggest",
            data:{},
            success:(visitorList)=>{
                const emailSuggest=visitorList.map((item)=>{
                    return item.email;
                });

                this.setState({
                    emailSuggest:emailSuggest,
                    visitorList:visitorList
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getPassOptions=(dormID)=>{
        u.post({
            url:"/api/get-preset-pass-options",
            data:{
                dormID:dormID
            },
            success:(passOptions)=>{
                passOptions.push({label:"Auto-Generated",value:"Auto-Generated"});
                this.setState({passOptions:passOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        let entryData=this.state.entryData;

        entryData[label]=value;

        if(label == "passValue"){
            entryData["passLabel"] = option.label;
        }

        if(label == "dormID"){
            this.getPassOptions(value);
            this.getClusterOptions(value);
        }

        this.setState({entryData:entryData});
    }

    onEmail=()=>{
        this.setState({error:""});
        //Check if email is in list
        const email = this.refs['email'].validate();
        if(!email.valid){
            this.setState({error:email.value});
            return 0;
        }

        //Check if email in list
        const prevData = this.state.visitorList.filter((item)=>{
            return item.email == email.value;
        });

        let entryData = this.state.entryData;
        if(prevData.length > 0){
            entryData = prevData[0];
            this.setState({entryData:entryData});
        }

        if(this.state.dormOptions.length > 0){
            this.getPassOptions((entryData.dormID) ? entryData.dormID : this.state.dormOptions[0].value);
        }

        this.setState({step:"details",error:""});
    }

    onValidate=()=>{
        this.setState({error:""});
        const keys=["name","nric","contactNumber","email","companyName","purposeOfEntry","vehicleNumber","dormID","clusterID","passValue","endDate","endTime","comments"];
        let data={};
        const entryData = this.state.entryData;

        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]] = current.value;
        }

        /*if(data.passValue == "Auto-Generated" && (data.email=="" || typeof data.email == "undefined")){
            this.refs['email'].setError();
            this.refs['passValue'].setError();
            this.setState({error:"Email is required for Auto-Generated passes"});
            return 0;
        }*/

        data.validTo = data.endDate + "T" + data.endTime;
        data.validFrom = moment().format("YYYY-MM-DDTHH:mm:ss");
        data.passLabel = entryData.passLabel;

        delete data.endDate;
        delete data.endTime;

        this.refs[this.props.reference].onValidated(data);
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onComplete=(visitorID)=>{
        if(this.state.entryData.print){
            u.post({
               url:"/api/get-visitor",
               data:{
                  visitorID:visitorID
               },
               success:(visitor)=>{
                  visitor.category="visitor";
                  store.set("print-card",visitor);
                  window.open("/print-card","_blank","width=800,height=800,left=200,top=100");
               },
               error:(error)=>{
                  this.setState({error:error});
               }
            });
        }

        this.setState({
            step:"email",
            error:"",
            entryData:{}
        });
    };

    render=()=>{
        const content=this.state.entryData;
        const estateConfig=this.state.estateConfig;
        const options = this.state.options;

        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}>

                 <div className="segment-content">
                      <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                      <div className="segment-error">{this.state.error}</div>

                      <Step active={this.state.step=="email"}>
                          <div>
                              <h3>Fill in visitor's email to continue</h3>
                              <div className="segment-full-item" style={{zIndex:2,paddingBottom:"30px"}}>
                                  <div className="segment-item-input">
                                      <Box ref="email" label="Email" field="email" type="email" values={{value:content.email}} rules={{list:this.state.emailSuggest,placeholder:"Visitor Email (Leave Blank If No Email)",listHeight:"120px"}} onChange={this.onChange}/>
                                  </div>
                              </div>
                              <div className="segment-buttons" style={{zIndex:1}}>
                                  {
                                    (u.isMobileDevice())
                                        ?
                                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                        :
                                        null
                                  }
                                  <Button type="medium" onClick={this.onEmail}>Next</Button>
                              </div>
                          </div>
                      </Step>

                      <Step active={this.state.step=="details"}>
                          <div>
                              <h3>Update visitor's details before confirming</h3>
                              <div className="segment-full-item">
                                  <div className="segment-item-label">Name:</div>
                                  <div className="segment-item-input">
                                      <Box ref="name" label="Name" field="name" values={{value:content.name}} rules={{required:true}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Contact Number:</div>
                                  <div className="segment-item-input">
                                      <ContactNumber ref="contactNumber"
                                                     label="Contact Number"
                                                     countryCodeField="countryCode"
                                                     contactNumberField="contactNumber"
                                                     values={{
                                                       countryCode:content.countryCode,
                                                       contactNumber:content.contactNumber,
                                                     }}
                                                     rules={{required:true}}
                                                     onChange={this.onChange}
                                                     />
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">NRIC/FIN:</div>
                                  <div className="segment-item-input">
                                      <Box ref="nric" label="NRIC/FIN" field="nric" values={{value:content.nric}} rules={{placeholder:"Optional"}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Email:</div>
                                  <div className="segment-item-input">
                                      <Box ref="email" label="Email" field="email" type="email" values={{value:content.email}} rules={{placeholder:"Required to send turnstile pass via Email"}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Company:</div>
                                  <div className="segment-item-input">
                                      <Box ref="companyName"
                                           label="Company"
                                           field="companyName"
                                           values={{value:content.companyName}}
                                           rules={{required:true}}
                                           onChange={this.onChange}
                                           />
                                  </div>
                              </div>

                              <div className="segment-full-item" style={{zIndex:1}}>
                                  <div className="segment-item-label">Purpose of Entry:</div>
                                  <div className="segment-item-input">
                                      {
                                          (options.purposeOfEntry.length > 0)
                                              ?
                                              <Select ref="purposeOfEntry"
                                                      label="Purpose of Entry"
                                                      field="purposeOfEntry"
                                                      values={{
                                                        value:content.purposeOfEntry,
                                                        options:options.purposeOfEntry,
                                                      }}
                                                      rules={{required:true}}
                                                      onChange={this.onChange}
                                                      />
                                              :
                                              <Box ref="purposeOfEntry"
                                                   label="Purpose of Entry"
                                                   field="purposeOfEntry"
                                                   values={{value:content.purposeOfEntry}}
                                                   rules={{required:true}}
                                                   onChange={this.onChange}
                                                   />
                                      }
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Vehicle Number:</div>
                                  <div className="segment-item-input">
                                      <Box ref="vehicleNumber" label="Vehicle Number" field="vehicleNumber" values={{value:content.vehicleNumber}} rules={{placeholder:"Leave blank if no vehicle"}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item" style={{margin:"15px auto",backgroundColor:"lightgrey",width:"100%",height:"1px"}}/>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Dormitory:</div>
                                  <div className="segment-item-input">
                                      <Select ref="dormID" label="Dormitory" field="dormID" values={{value:content.dormID,options:this.state.dormOptions}} rules={{required:true}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">{estateConfig.clusterLabel}:</div>
                                  <div className="segment-item-input">
                                      <Select ref="clusterID" label={estateConfig.clusterLabel} field="clusterID" values={{value:content.clusterID,options:this.state.clusterOptions}} rules={{required:true}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Visitor Pass:</div>
                                  <div className="segment-item-input">
                                      <Select ref="passValue" label="Visitor Pass" field="passValue" values={{value:content.passValue,options:this.state.passOptions}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Valid To (Date):</div>
                                  <div className="segment-item-input">
                                      <Date ref="endDate" label="Valid To (Date)" field="endDate" values={{value:content.endDate}} range={[0,2]} rules={{minDate:moment().format("YYYY-MM-DD")}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Valid To (Time):</div>
                                  <div className="segment-item-input">
                                      <Time ref="endTime" label="Valid To (Time)" field="endTime" values={{value:content.endTime}} rules={{skipSeconds:true,defaultValue:"23:59:59"}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-full-item">
                                  <div className="segment-item-label">Comments:</div>
                                  <div className="segment-item-input">
                                      <Box ref="comments" label="Comments" field="comments" values={{value:content.comments}} rules={{placeholder:"Optional"}} onChange={this.onChange}/>
                                  </div>
                              </div>

                              <div className="segment-buttons">
                                  <Checkbox label="print" values={{value:content.print}} onChange={this.onChange}/> Print Access Card
                              </div>

                              <div className="segment-buttons">
                                  <Button type="medium" onClick={()=>{this.getEmailSuggest();this.setState({step:"email"})}}>Back</Button>
                                  <Button type="medium" onClick={this.onValidate}>Create</Button>
                              </div>
                          </div>
                      </Step>
                 </div>
            </AddListItem>
        )
    }
}

class BulkPrintCard extends React.Component{

    state={
        active:false,
        loading:false,
        step:0,
        options:[],
    };

    componentDidMount=()=>{
        u.post({
            url:"/api/get-visitor-card-themes",
            data:{},
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                console.log(error);
            }
        })
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onBack=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onSubmit=()=>{
        this.setState({error:""});

        const cardTheme = this.refs['cardTheme'].validate();
        if(!cardTheme.valid){
            this.setState({error:cardTheme.value});
            return 0;
        }

        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 entry to continue"});
            return 0;
        }

        this.setState({loading:true,error:""});
        u.post({
            url:"/api/get-selected-visitors",
            data:{
                selected:this.props.selected
            },
            success:(visitors)=>{
                visitors = visitors.map((item)=>{
                    item.cardTheme = cardTheme.value;
                    return item;
                });
                store.set("print-multi-card",visitors);
                store.set("print-multi-card-category","visitor");
                this.setState({loading:false});
                window.open("/print-multiple-card","_blank","width=800,height=800,left=200,top=100");
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        return(
            <Segment active={this.state.active} align={"center"}>
                <div className="list-add">
                    <div className="segment-title">Bulk Print Card</div>
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text="Preparing print preview..."/>
                    <div className="segment-content">
                        <h3>Please select the visitors to print</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Card Template:</div>
                            <div className="segment-item-input">
                                <Select ref="cardTheme" values={{options:this.state.options}} rules={{required:true}}/>
                            </div>
                        </div>
                        <div className="segment-selected-bg">
                            <div className="segment-selected"><u>{entries} {(entries==1) ? "entry" : "entries"} selected</u></div>
                            <div className="segment-note">Select entries from the list below</div>
                        </div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={this.onBack}>Cancel</Button>
                            <Button type="medium" onClick={this.onSubmit}>Print</Button>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}

class PresetPasses extends React.Component{

    state={
        error:"",
        content:[],
        active:false,
        dormOptions:[],
        clusterOptions:[],
        themeOptions:[],
        permissions:false,
        step:"view"
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("visitor");
        this.setState({permissions:permissions.update==2});
        this.getContent();
        this.getDormOptions();
        this.getCardThemes();
    }

    getCardThemes=()=>{
        u.post({
            url:"/api/get-visitor-card-themes",
            data:{},
            success:(options)=>{
                this.setState({themeOptions:options});
            },
            error:(error)=>{
                console.log(error);
            }
        })
    }

    show=(show)=>{
        this.setState({active:show,error:""});
    }

    getContent=()=>{
        u.post({
            url:"/api/get-preset-passes",
            data:{},
            success:(content)=>{
                this.setState({content:content,error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{type:"dorm"},
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
                if(dormOptions.length > 0){
                    this.getClusterOptions(dormOptions[0].value);
                }
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getClusterOptions=(id)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"cluster",
                id:id,
            },
            success:(clusterOptions)=>{
                clusterOptions.unshift({value:"all",label:"All"});
                this.setState({clusterOptions:clusterOptions});
            }
        })
    }

    onCreate=()=>{
        const keys=["dormID","passLabel","passValue","clusterID"];
        let data = {};
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]] = current.value;
        }

        this.setState({loading:true,loadText:"Creating new preset pass.."});

        u.post({
            url:"/api/create-preset-pass",
            data:data,
            success:(entry)=>{
                let content = this.state.content;
                for(var i=0; i<content.length; i++){
                    if(content[i].dormID == data.dormID){
                        content[i].passes.push(entry);
                        break;
                    }
                }
                this.setState({content:content,step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onRemove=()=>{
        const dormIndex = this.state.dormIndex;
        const passIndex = this.state.passIndex;
        this.setState({loading:true,loadText:"Deleting entry...",error:""});
        u.post({
            url:"/api/remove-preset-pass",
            data:{
                dormID:this.state.content[dormIndex].dormID,
                entry:this.state.content[dormIndex].passes[passIndex]
            },
            success:()=>{
                let content = this.state.content;
                content[dormIndex].passes.splice(passIndex,1);
                this.setState({step:"view",content:content,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onPrintRequest=(dormIndex,passIndex)=>{
        this.interest = Object.assign({},this.state.content[dormIndex].passes[passIndex]);
        this.interest.dormName = this.state.content[dormIndex].dormName,
        this.interest.name = this.interest.passLabel;
        this.interest.category="visitor";
        this.setState({step:"print",error:""});
    }

    onPrint=()=>{
        const cardTheme=this.refs['cardTheme'].validate();
        if(!cardTheme.valid){
            this.setState({error:cardTheme.value});
            return 0;
        }
        this.interest.cardTheme = cardTheme.value;
        this.interest.permanent = true;
        store.set("print-card",this.interest);
        window.open("/print-card","_blank","width=800,height=800,left=200,top=100");
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        const content=this.state.content;
        const canEdit = this.state.permissions;
        return(
            <Segment active={this.state.active}>
                <div className="segment-title">Manage Preset Passes</div>
                <div className="segment-error">{this.state.error}</div>

                <div style={{textAlign:"center"}}>
                    <Step active={(this.state.step == "view")}>
                        {
                            content.map(
                                (dorm,dormIndex)=>{
                                    return(
                                        <div className="visitor-table-container" key={dormIndex}>
                                            <h3>{dorm.dormName}</h3>
                                              {
                                                (dorm.passes.length > 0)
                                                ?
                                                <div className="segment-content">
                                                    <div className="segment-table visitor-table">
                                                        <table>
                                                            <thead style={{position:"sticky"}}>
                                                                <tr>
                                                                    <th className="remove"/>
                                                                    <th>Pass Label</th>
                                                                    <th className="mobile-hide">Pass Value</th>
                                                                    <th>Access</th>
                                                                    <th>Assigned To</th>
                                                                    <th className="mobile-hide">Assigned On</th>
                                                                    <th className="print">Print Pass</th>
                                                                    <th style={{position:"relative",width:(dorm.passes.length > 7) ? "3px" : "0"}}/>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    dorm.passes.map(
                                                                        (item,index)=>{
                                                                            return(
                                                                                <tr key={index} className="tr-item">
                                                                                    <td className="remove">
                                                                                        <div style={{width:"16px",height:"16px"}}>
                                                                                            <MiniMinusButton onClick={()=>{this.setState({step:"remove",dormIndex:dormIndex,passIndex:index,error:""})}} enabled={canEdit}/>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{item.passLabel}</td>
                                                                                    <td className="mobile-hide">{item.passValue}</td>
                                                                                    <td>{(item.clusterName == "All") ? "All Zones" : "Zone " + item.clusterName}</td>
                                                                                    <td>{(item.assignedToLabel) ? item.assignedToLabel : "None"}</td>
                                                                                    <td className="mobile-hide">{(item.assignedOn) ? moment(item.assignedOn).format("DD MMM YY - h:mma") : "None"}</td>
                                                                                    <td className="print"><TextButton onClick={()=>{this.onPrintRequest(dormIndex,index)}}>Print</TextButton></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                    )
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {
                                                      (canEdit)
                                                        ?
                                                        <TextButton style={{fontSize:"0.8em",margin:"20px auto"}} onClick={()=>{this.setState({dormID:dorm.dormID,step:"add"})}}>Add Pass</TextButton>
                                                        :
                                                        null
                                                    }
                                                </div>
                                                :
                                                <div className="pass-placeholder">
                                                    <div className="pass-placeholder-text"><b>No Preset Passes</b></div>
                                                    {
                                                      (canEdit)
                                                        ?
                                                        <TextButton style={{fontSize:"0.8em",margin:"0"}} onClick={()=>{this.setState({dormID:dorm.dormID,step:"add"})}}>Add Pass</TextButton>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            }
                                        </div>
                                    )
                                }
                            )
                        }

                        <div className="segment-buttons mobile-only">
                            <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "print")}>
                        <h3>Please select the card template to print</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Card Template:</div>
                            <div className="segment-item-input">
                                <Select ref="cardTheme" values={{options:this.state.themeOptions}} rules={{required:true}}/>
                            </div>
                        </div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onPrint}>Print</Button>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "add")}>
                        <h3>Fill in the following to continue</h3>
                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory:</div>
                                <div className="segment-item-input"><Select ref="dormID" label="Dormitory" values={{value:this.state.dormID,options:this.state.dormOptions}} onChange={(value)=>{this.setState({dormID:value});this.getClusterOptions(value)}}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Zone:</div>
                                <div className="segment-item-input"><Select ref="clusterID" label="Zone" values={{options:this.state.clusterOptions}}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Pass Label: <Info title="Label is displayed when printed, use a label which can be easily identified"/></div>
                                <div className="segment-item-input"><Box ref="passLabel" label="Pass Label" rules={{required:true}}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Pass Value: <Info title="To prevent fraud, use a complex string of more than 10 alphanumeric characters. If using RFID for turnstile access, enter RFID value based on the type of your card readers"/></div>
                                <div className="segment-item-input"><Box ref="passValue" label="Pass Value" rules={{required:true,minLength:5}}/></div>
                            </div>
                        </div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onCreate}>Confirm</Button>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "remove")}>
                        <h3 className="text-negative">Confirm Delete Preset Card?</h3>
                        <div className="segement-note">Note. Preset card will no longer be valid, effective immediately</div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onRemove}>Confirm</Button>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
