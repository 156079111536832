import React from 'react';
import u from '../utilities/Utilities';
import AppLayout from '../components/AppLayout';
import {LoadGraphic,Header} from '../components/Common';
import {EstateUtilities} from './Estate';
import {Link} from 'react-router-dom';
import store from 'store';
import moment from 'moment';
import '../styles/room-manifest.scss';

export default class RoomManifest extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Manifest",
        permission:"estate",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/room-manifest",
    };

    state={
        error:"",
        content:[],
        loading:true,
        loadText:"Preparing rooms..",
        dormConfig:{},
    }

    componentDidMount=()=>{
        const id = store.get("room-manifest");
        const dormConfig=store.get("dormConfig");
        this.setState({dormConfig:dormConfig});

        if(!id){
            this.setState({error: "Error! Room not specified",loadText:"Error! Room not specified"});
        }
        else{
            this.getContent(id);
        }
    }

    getContent=(id)=>{
        u.post({
            url:"/api/get-room-manifest",
            data:{
                fields:id
            },
            success:(callback)=>{
                console.log(callback);
                this.setState({content:callback,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loadText:error});
            }
        });
    }

    render=()=>{
        return(
            <AppLayout settings={this.settings}>
                <div className="page-error">{this.state.error}</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                {
                    this.state.content.map((item,index)=>{
                        return(
                            <div key={index} className={((index > 0) ? "page-break" : "")} style={{textAlign:"center"}}>
                                <RoomManifestContainer content={item} index={index} dormConfig={this.state.dormConfig}/>
                            </div>
                        )
                    })
                }
            </AppLayout>
        );
    }
}

class RoomManifestContainer extends React.Component{

    state={
      label:""
    };

    componentDidMount=()=>{
        const config = this.props.dormConfig;
        const location = this.props.content.location;
        console.log(config);
        console.log(this.props.content);
        let label = ((location.clusterName) ? config.clusterPrefix + location.clusterName + " > " : "") +
                    ((location.buildingName) ? config.buildingPrefix + location.buildingName + " > " : "") +
                    EstateUtilities.getLabel(this.props.content,config.roomPrefix);
        this.setState({label:label});
    }

    render=()=>{
        const content = this.props.content;

        return (
            <div className="room-manifest-container" style={{textAlign:"center"}}>
                {
                    (this.props.index > 0)
                        ?
                        <div className="print-only">
                            <Header type="half"/>
                        </div>
                        :
                        ""
                }
                <h3>{this.state.label}</h3>
                <div className="manifest-occupancy"><i>Occupancy: {content.physicalOccupancy} / {content.capacity}</i></div>
                <div className="room-manifest">
                    {
                        content.beds.map(
                            (item,index)=>{
                                return <RoomManifestItem key={index} content={item} dormConfig={this.props.dormConfig}/>
                            }
                        )
                    }
                </div>
            </div>
        );
    }
}

class RoomManifestItem extends React.Component{
    render=()=>{
        const content=this.props.content;
        return(
            <div className="room-manifest-item">
                <img src={u.getAvatarLink(content.tenantAvatarLink)} className="room-manifest-avatar"/>
                <div className="vertical-guide"/>
                <div className="room-manifest-content">
                    <div className="rmci-item" title="Bed">
                        {this.props.dormConfig.bedPrefix}{content.bedName}
                    </div>

                    <div className="rmci-item" title="Resident">
                        {
                            (content.tenantID)
                                ?
                                <Link to={"/resident/"+content.tenantID}>{content.tenantName}</Link>
                                :
                                "Vacant"

                        }
                    </div>

                    <div className="rmci-item" title="Work Permit (Expiry)">
                        {
                            (content.tenantID)
                                ?
                                content.tenantWorkPermit + ((content.tenantWorkPermitExpiry) ? " (" + moment(content.tenantWorkPermitExpiry).format("DD MMM YYYY") + ")" : "")
                                :
                                ""
                        }
                    </div>

                    <div className="rmci-item" title="Organization">
                        {
                            (content.tenantID)
                                ?
                                <Link to={"/client/" + content.clientID}>{content.clientName}</Link>
                                :
                                ""
                        }
                    </div>

                    <div className="rmci-item special" title="Employer">
                        {
                            (content.tenantID)
                                ?
                                ((content.tenantRegisteredCompanyName) ? content.tenantRegisteredCompanyName : "-")
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        );
    }
}
