import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import {Databox,PageMenu,Segment,LoadGraphic,Step,SegmentComplete} from '../components/Common';
import {Button,TextButton,Select} from '../components/Input';
import {Link} from 'react-router-dom';
import {Line,Bar} from 'react-chartjs-2';
import PropTypes from 'prop-types';
import {PieChart} from './Reports.js';
import '../styles/survey-result.scss';
import moment from 'moment';

const pieLegend={
    display:false,
    position:"bottom",
    boxWidth:20,
};

export default class SurveyResult extends React.Component{

    surveyID = this.props.match.params.surveyID;

    settings={
        /**** Page Setup ****/
        title:"Survey Result",
        permission:"survey",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/survey-result",
        label:"survey result",
        hasNotebook:false,
    };

    state={
        error:"",
        permissions:{},
        content:[],
        pageMenuButtons:[
            {label:"Download Results",enabled:true,padding:"3px",ref:"download"},
        ],
        resultOptions:[],
        loading:true,
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("survey");
        this.setState({permissions:permissions});

        if(this.surveyID){
            this.getContent(this.surveyID);
        }
        else {
            this.setState({error:"Survey Not Found"});
        }
    }

    getContent=(surveyID)=>{
        this.setState({loading:true});
        u.post({
            url:"/api/get-survey-results",
            data:{
                surveyID:surveyID,
                testDelay:1000,
            },
            success:({surveyID,surveyReference,resultOptions,results,participation})=>{
                this.setState({
                  content:results,
                  resultOptions:resultOptions,
                  surveyID:surveyID,
                  surveyReference:surveyReference,
                  participation:participation,
                  loading:false
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,onError)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    render=()=>{
        const content = this.state.content;
        const pageComponents={
          download:{
            component:DownloadResults,
            props:{
                surveyID:this.surveyID,
                resultOptions:this.state.resultOptions,
            }
          }
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={{
                            ...this.settings,
                            title:(content.surveyReference) ? content.surveyReference : this.settings.title,
                          }}
                          components={pageComponents}
                          selected={this.state.selected}
                />

                <LoadGraphic active={this.state.loading} text={"Preparing..."}/>

                {
                  (this.state.participation)
                  ?
                  <Participation content={this.state.participation} loading={this.state.loading}/>
                  :
                  null
                }

                {
                  content.map((item,index)=>{
                      return (
                          <ResultItem key={index} content={item} index={index}/>
                      )
                  })
                }
            </AppLayout>
        )
    }
}

class Participation extends React.Component{

    state={
        breakdownPoint:0,
        graphData: {
            labels: [],
            datasets: []
        },
        barOptions: {
            maintainAspectRatio:false,
            responsive: true,
            interaction:{
                mode:"index",
            },
            plugins:{
              tooltip: {
                callbacks:{
                      label:(context)=>{
                          var label = context.dataset.label;
                          if(label){
                              label +=": ";
                          }
                          label += context.dataset.data[context.dataIndex]  + "%";
                          return label;
                      }
                  }
              },
              datalabels:{
                align:"end",
                anchor:"end",
                offset:2,
                formatter: function(value, context) {
                   return value + "%";
                },
              }
            },
            elements: {
                line: {
                    fill: false,
                    showLines: true,
                    spanGaps: false,
                    lineTension:0,
                }
            },
            scales: {
                x:{
                    display: true,
                    gridLines: {
                        display: true
                    },
                },
                y:{
                    type: 'linear',
                    display: true,
                    grace:"10%",
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: true
                    },
                },
            }
        },
        nationality:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true
        },
        zone:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true,
        },
        age:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true,
        },
        resultIndex:0,
        pieDataLabels:{
            display:true,
            color:"#ffffff",
            formatter: function(value, context) {
               return (value >= 5) ? context.chart.data.labels[context.dataIndex] : "";
            },
        },
    };

    componentDidMount=()=>{
        const resultIndex = this.props.content.datasets.length - 1;
        const pie = this.constructPieChart(this.state.breakdownPoint,resultIndex);
        this.setState({
            graphData:this.constructGraph(this.props.content.datasets),
            resultIndex:resultIndex,
            nationality:pie.nationality,
            zone:pie.zone,
            age:pie.age,
        });
    }

    onChange=(direction,type)=>{
        const breakdownPoint = this.state.breakdownPoint;
        const resultIndex = this.state.resultIndex;

        let newPoint = breakdownPoint;
        let newResult = resultIndex;

        if(type == "point"){
            const breakdown = this.props.content.datasets[resultIndex].breakdown;

            if(direction == "left"){
                if(breakdownPoint > 0){
                    newPoint = breakdownPoint - 1;
                }
                else{
                  newPoint = breakdown.length - 1;
                }
            }
            if(direction == "right"){
                if(breakdownPoint < breakdown.length - 1){
                    newPoint = breakdownPoint + 1;
                }
                else{
                  newPoint = 0;
                }
            }
        }

        if(type == "result"){
            const datasets = this.props.content.datasets;
            if(direction == "left"){
                if(resultIndex > 0){
                    newResult = resultIndex - 1;
                }
                else{
                    newResult = datasets.length - 1;
                }
            }
            if(direction == "right"){
                if(resultIndex < datasets.length - 1){
                    newResult = resultIndex + 1;
                }
                else{
                    newResult = 0;
                }
            }
        }

        const pie = this.constructPieChart(newPoint,newResult);

        this.setState({
          nationality:pie.nationality,
          zone:pie.zone,
          age:pie.age,
          breakdownPoint:newPoint,
          resultIndex:newResult,
        });
    }

    constructGraph=(datasets)=>{
        const multiColors=[
            "#7EB7DD","#90DFAA","#FDC3B6",
            "#3A98D6","#50E381","#FD8469",
            "#2C74A3","#6FAB83","#C96A55",
            "#173D57","#215E35","#7D4234",
            "#314857","#3D5E48","#7D605A",
        ];
        let charts = [];
        let participated = [];
        let didNotParticipate = [];
        let labels = [];

        for(var i=0; i<datasets.length; i++){
            didNotParticipate.push(Math.round(datasets[i].data[0]));
            participated.push(Math.round(datasets[i].data[1]));
            labels.push(datasets[i].label);
        }

        charts.push({
            label:"Did Not Participate",
            type:"line",
            data:didNotParticipate,
            fill:false,
            borderColor: "#c0c0c0",//colors[props.index].hex,
            backgroundColor: "#c0c0c0",//colors[props.index].hex,
            borderWidth:1,
            pointRadius:1,
            pointHoverRadius:1,
            maxBarThickness:50,
            lineTension:0,
            order:0,
            datalabels:{
                display:false
            }
        });

        charts.push({
            label:"Participated",
            type:"line",
            data:participated,
            fill:false,
            borderColor: "#07abf2",//colors[props.index].hex,
            backgroundColor: "#07abf2",//colors[props.index].hex,
            borderWidth:1,
            pointRadius:2,
            pointHoverRadius:4,
            maxBarThickness:50,
            lineTension:0,
            order:0,
            datalabels:{
                color:"#07abf2"
            }
        });

        return {
            labels:labels,
            datasets:charts
        };
    }

    constructPieChart = (breakdownPoint,resultIndex) =>{
        const multiColors=[
            "#7EB7DD","#3A98D6","#2C74A3","#173D57","#314857",  //Blues
            "#3D5E48","#215E35","#6FAB83","#50E381","#90DFAA",  //Greens
            "#FDC3B6","#FD8469","#C96A55","#7D4234","#7D605A",  //Oranges
        ];

        const breakdown = this.props.content.datasets[resultIndex].breakdown[breakdownPoint];

        let ntnEmpty = true;
        const ntnLabels = breakdown.nationality.map(item=>{return item.label});
        const ntnData = breakdown.nationality.map(item=>{ if(item.value != 0){ntnEmpty=false}  return item.value});

        let zoneEmpty = true;
        const zoneLabels = breakdown.zone.map(item=>{return item.label});
        const zoneData = breakdown.zone.map(item=>{ if(item.value != 0){zoneEmpty=false}  return item.value});

        let ageEmpty = true;
        const ageLabels = breakdown.age.map(item=>{return item.label});
        const ageData = breakdown.age.map(item=>{ if(item.value != 0){ageEmpty=false}  return item.value});

        const emptyPie = {
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
            empty:true,
        };

        return {
            nationality:(!ntnEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: ntnData,
              labels: ntnLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie,

            zone : (!zoneEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: zoneData,
              labels: zoneLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie,

            age: (!ageEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: ageData,
              labels: ageLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie
        }
    }

    render=()=>{
        const content = this.props.content;
        const pieDataLabels = this.state.pieDataLabels;

        return(
            <Databox active={true} title="Participation" margin="0">
                <div>
                    <div className="question-container" style={{textAlign:"center"}}>
                        <div className="question-graph-container">
                            <Line data={this.state.graphData}
                                  options={this.state.barOptions}
                                  />
                        </div>

                        <div className="question-breakdown-container">
                            <h2>Respondent Breakdown</h2>
                            <div>
                                <div className="question-select">
                                    <img src="/images/input/arrow-left.png" className="breakdown-left" onClick={()=>{this.onChange("left","result")}}/>
                                    <div className="question-select-text">Result: {this.props.content.datasets[this.state.resultIndex].label}</div>
                                    <img src="/images/input/arrow-left.png" className="breakdown-right" onClick={()=>{this.onChange("right","result")}}/>
                                </div>

                                <div className="question-select">
                                    <img src="/images/input/arrow-left.png" className="breakdown-left" onClick={()=>{this.onChange("left","point")}}/>
                                    <div className="question-select-text">{this.props.content.datasets[this.state.resultIndex].breakdown[this.state.breakdownPoint].label}</div>
                                    <img src="/images/input/arrow-left.png" className="breakdown-right" onClick={()=>{this.onChange("right","point")}}/>
                                </div>
                            </div>
                            <div className="question-pie-container">
                                <div className="question-pie">
                                    <h3>Nationatilty</h3>
                                    <div className="question-pie-image">
                                        <PieChart content={this.state.nationality}
                                                  legend={pieLegend}
                                                  tooltipSuffix="%"
                                                  className="normal"
                                                  datalabels={pieDataLabels}
                                                  redraw={true}
                                                  />
                                    </div>
                                </div>
                                <div className="question-pie">
                                    <h3>Zone</h3>
                                    <div className="question-pie-image">
                                        <PieChart content={this.state.zone}
                                                  redraw={true}
                                                  legend={pieLegend}
                                                  tooltipSuffix="%"
                                                  className="normal"
                                                  showLabel={true}
                                                  datalabels={pieDataLabels}
                                                  />
                                    </div>
                                </div>
                                <div className="question-pie">
                                    <h3>Age</h3>
                                    <div className="question-pie-image">
                                        <PieChart content={this.state.age}
                                                  redraw={true}
                                                  legend={pieLegend}
                                                  tooltipSuffix="%"
                                                  className="normal"
                                                  datalabels={pieDataLabels}
                                                  />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Databox>
        )
    }
}

class ResultItem extends React.Component{

    state={
        loading:true
    };

    componentDidMount=()=>{
        this.setState({loading:false});
    }

    render=()=>{
        const content=this.props.content;
        return(
          <Databox active={true} title={this.props.content.question}>
              <div>
                  {
                    (content.preReq)
                    ?
                    <div className="question-requisite">(Requisite: Qn {content.preReq + " " + content.reqType.toLowerCase() + " " + content.reqValue} )</div>
                    :
                    null
                  }

                  {
                    (content.answerType == "3 Point Scale" || content.answerType == "5 Point Scale")
                    ?
                    <PointScale content={content}/>
                    :
                    null
                  }

                  {
                    (content.answerType == "Open Ended")
                    ?
                    <OpenEnded content={content}/>
                    :
                    null
                  }
              </div>
          </Databox>
        )
    }
}

class PointScale extends React.Component{

    state={
        breakdownPoint:0,
        graphData: {
            labels: [],
            datasets: []
        },
        barOptions: {
            maintainAspectRatio:false,
            responsive: true,
            interaction:{
                mode:"nearest",
            },
            plugins:{
              tooltip: {
                callbacks:{
                      label:(context)=>{
                          var label = context.label;
                          if(label){
                              label +=": ";
                          }
                          label += context.dataset.data[context.dataIndex]  + "%";
                          return label;
                      },
                      title:(context)=>{
                          return context[0].dataset.label;
                      },
                  }
              },
              datalabels:{
                align:"end",
                anchor:"end",
                offset:2,
                formatter: function(value, context) {
                   return value + "%";
                },
              },
              legend:{
                  display:false,
              }
            },
            elements: {
                line: {
                    fill: false,
                    showLines: true,
                    spanGaps: false,
                    lineTension:0,
                }
            },
            scales: {
                x:{
                  display: true,
                  gridLines: {
                      display: true
                  },
                },
                y:{
                    type: 'linear',
                    display: true,
                    grace:"10%",
                    position: 'left',
                    id: 'y-axis-1',
                    gridLines: {
                        display: true
                    },
                },
            },
        },
        nationality:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true
        },
        zone:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true,
        },
        age:{
          showLegend: false,
          backgroundColor: ["#eaeaea"],
          data: [1],
          labels: ["No Data"],
          tooltips: false,
          pie:true,
        },
        resultIndex:0,
        pieDataLabels:{
            display:true,
            color:"#ffffff",
            formatter: function(value, context) {
               return (value >= 5) ? context.chart.data.labels[context.dataIndex] : "";
            },
        }
    };

    componentDidMount=()=>{
        const graph = this.constructGraph();
        const resultIndex = this.props.content.datasets.length - 1;
        const pie = this.constructPieChart(this.state.breakdownPoint,resultIndex);
        this.setState({
            graphData:{
                labels:this.props.content.labels,
                datasets:graph
            },
            resultIndex:resultIndex,
            nationality:pie.nationality,
            zone:pie.zone,
            age:pie.age,
        });
    }

    onChange=(direction,type)=>{
        const breakdownPoint = this.state.breakdownPoint;
        const resultIndex = this.state.resultIndex;

        let newPoint = breakdownPoint;
        let newResult = resultIndex;

        if(type == "point"){
            const breakdown = this.props.content.datasets[resultIndex].breakdown;

            if(direction == "left"){
                if(breakdownPoint > 0){
                    newPoint = breakdownPoint - 1;
                }
                else{
                  newPoint = breakdown.length - 1;
                }
            }
            if(direction == "right"){
                if(breakdownPoint < breakdown.length - 1){
                    newPoint = breakdownPoint + 1;
                }
                else{
                  newPoint = 0;
                }
            }
        }

        if(type == "result"){
            const datasets = this.props.content.datasets;
            if(direction == "left"){
                if(resultIndex > 0){
                    newResult = resultIndex - 1;
                }
                else{
                    newResult = datasets.length - 1;
                }
            }
            if(direction == "right"){
                if(resultIndex < datasets.length - 1){
                    newResult = resultIndex + 1;
                }
                else{
                    newResult = 0;
                }
            }
        }

        const pie = this.constructPieChart(newPoint,newResult);

        this.setState({
          nationality:pie.nationality,
          zone:pie.zone,
          age:pie.age,
          breakdownPoint:newPoint,
          resultIndex:newResult,
        });
    }

    constructGraph=()=>{
        const datasets = this.props.content.datasets;
        const multiColors=[
            "#7EB7DD","#90DFAA","#FDC3B6",
            "#3A98D6","#50E381","#FD8469",
            "#2C74A3","#6FAB83","#C96A55",
            "#173D57","#215E35","#7D4234",
            "#314857","#3D5E48","#7D605A",
        ];
        let charts = [];
        for(var i=0; i<datasets.length; i++){
          const color = u.hexToRgb("#c0c0c0");
          charts.push(
            {
                label: datasets[i].label,
                type: 'bar',
                data: datasets[i].data.map((item)=>{return Math.round(item)}),
                borderWidth:1,
                hoverBorderWidth:1,
                borderColor: color.hex,
                backgroundColor:"rgba(" + color.r+ "," + color.g + "," + color.b + ",0.6)",
                hoverBorderColor: color.hex,
                hoverBackgroundColor: "rgba(" + color.r+ "," + color.g + "," + color.b + ",0.4)",
                //yAxisID: 'y-axis-1'
                order:i+1,
                datalabels:{
                    display:false
                }
            }
          );
        }

        charts.push(
          {
              label: 'Mean Score',
              type: 'line',
              data: this.props.content.mean,
              fill: false,
              borderColor: "#07abf2",//colors[props.index].hex,
              backgroundColor: "#07abf2",//colors[props.index].hex,
              borderWidth:1,
              pointRadius:2,
              pointHoverRadius:4,
              maxBarThickness:50,
              //yAxisID: 'y-axis-1',
              lineTension:0,
              order:0,
              datalabels:{
                  color:"#07abf2"
              }
          },
        );

        return charts;
    }

    constructPieChart = (breakdownPoint,resultIndex) =>{
        const multiColors=[
            "#7EB7DD","#3A98D6","#2C74A3","#173D57","#314857",  //Blues
            "#3D5E48","#215E35","#6FAB83","#50E381","#90DFAA",  //Greens
            "#FDC3B6","#FD8469","#C96A55","#7D4234","#7D605A",  //Oranges
        ];

        const breakdown = this.props.content.datasets[resultIndex].breakdown[breakdownPoint];

        let ntnEmpty = true;
        const ntnLabels = breakdown.nationality.map(item=>{return item.label});
        const ntnData = breakdown.nationality.map(item=>{ if(item.value != 0){ntnEmpty=false}  return item.value});

        let zoneEmpty = true;
        const zoneLabels = breakdown.zone.map(item=>{return item.label});
        const zoneData = breakdown.zone.map(item=>{ if(item.value != 0){zoneEmpty=false}  return item.value});

        let ageEmpty = true;
        const ageLabels = breakdown.age.map(item=>{return item.label});
        const ageData = breakdown.age.map(item=>{ if(item.value != 0){ageEmpty=false}  return item.value});

        const emptyPie = {
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
            empty:true,
        };

        return {
            nationality:(!ntnEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: ntnData,
              labels: ntnLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie,

            zone : (!zoneEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: zoneData,
              labels: zoneLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie,

            age: (!ageEmpty) ? {
              showLegend: false,
              backgroundColor: multiColors,
              data: ageData,
              labels: ageLabels,
              tooltips: true,
              empty:false,
              pie:true,
            } : emptyPie
        }
    }

    render=()=>{
        const content = this.props.content;
        const pieDataLabels = this.state.pieDataLabels;

        return(
            <div className="question-container" style={{textAlign:"center"}}>
                <div className="question-graph-container">
                    <Bar data={this.state.graphData}
                         options={this.state.barOptions}
                         />
                </div>

                <div className="question-breakdown-container">
                    <h2>Respondent Breakdown</h2>
                    <div>
                        <div className="question-select">
                            <img src="/images/input/arrow-left.png" className="breakdown-left" onClick={()=>{this.onChange("left","result")}}/>
                            <div className="question-select-text">Result: {this.props.content.datasets[this.state.resultIndex].label}</div>
                            <img src="/images/input/arrow-left.png" className="breakdown-right" onClick={()=>{this.onChange("right","result")}}/>
                        </div>

                        <div className="question-select">
                            <img src="/images/input/arrow-left.png" className="breakdown-left" onClick={()=>{this.onChange("left","point")}}/>
                            <div className="question-select-text">Response: {this.props.content.datasets[this.state.resultIndex].breakdown[this.state.breakdownPoint].label}</div>
                            <img src="/images/input/arrow-left.png" className="breakdown-right" onClick={()=>{this.onChange("right","point")}}/>
                        </div>
                    </div>
                    <div className="question-pie-container">
                        <div className="question-pie">
                            <h3>Nationatilty</h3>
                            <div className="question-pie-image">
                                <PieChart content={this.state.nationality}
                                          redraw={true}
                                          legend={pieLegend}
                                          tooltipSuffix="%"
                                          className="normal"
                                          datalabels={pieDataLabels}
                                          />
                            </div>
                        </div>
                        <div className="question-pie">
                            <h3>Zone</h3>
                            <div className="question-pie-image">
                                <PieChart content={this.state.zone}
                                          redraw={true}
                                          legend={pieLegend}
                                          tooltipSuffix="%"
                                          className="normal"
                                          datalabels={pieDataLabels}
                                          />
                            </div>
                        </div>
                        <div className="question-pie">
                            <h3>Age</h3>
                            <div className="question-pie-image">
                                <PieChart content={this.state.age}
                                          redraw={true}
                                          legend={pieLegend}
                                          tooltipSuffix="%"
                                          className="normal"
                                          datalabels={pieDataLabels}
                                          />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class OpenEnded extends React.Component{

    state={
        currentPage:1,
        maxPage:1,
        linesPerPage:10,
    };

    static getDerivedStateFromProps=(props,state)=>{
        state.maxPage= Math.ceil(props.content.responses.length / state.linesPerPage);
        state.indexMin = (state.currentPage - 1) * state.linesPerPage;
        state.indexMax = state.currentPage * state.linesPerPage;

        return state;
    }

    onChangePage=(dir)=>{
        if(dir == "left"){
            if(this.state.currentPage == 1){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage-1});
            }
        }
        else if(dir == "right"){
            if(this.state.currentPage == this.state.maxPage){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage+1});
            }
        }
    }

    render=()=>{
        const content = this.props.content;
        return (
            <div className="question-container">
                {
                  (content.responses.length > 0)
                    ?
                    <div className="list-table" style={{maxHeight:"none"}}>
                      <table style={{position:"relative",textAlign:"center",width:"100%"}}>
                          <tbody>
                              <tr>
                                  <th style={{width:"20%"}}>Date</th>
                                  <th style={{width:"20%"}}>Type</th>
                                  <th style={{width:"20%"}}>Participant</th>
                                  <th style={{width:"40%"}}>Response</th>
                              </tr>
                              {
                                  content.responses.map(
                                      (item,index)=>{
                                          if(index<this.state.indexMin || index >= this.state.indexMax){
                                              return null;
                                          }
                                          const today = moment().format("DD-MM-YYYY") == moment(item.date).format("DD-MM-YYYY");
                                          const yest = moment().subtract(1,"days").format("DD-MM-YYYY") == moment(item.date).format("DD-MM-YYYY");
                                          return(
                                              <tr key={index} className="item">
                                                  <td>{(today) ? "Today" : ((yest) ? "Yesterday" : moment(item.date).format("DD MMM YY"))}</td>
                                                  <td>{item.type}</td>
                                                  <td>
                                                      {
                                                          (item.type == "Resident")
                                                              ?
                                                              <Link to={"/resident/"+item.tenantID}>{item.tenantName}</Link>
                                                              :
                                                              <Link to={"/staff/"+item.staffID}>{item.staffName}</Link>
                                                      }

                                                  </td>
                                                  <td>{item.value}</td>
                                              </tr>
                                          )
                                      }
                                  )
                              }
                          </tbody>
                      </table>
                    </div>
                    :
                    <div style={{padding:"50px 0"}}>
                        <i>No Responses Submitted</i>
                    </div>
                }

                {
                  (this.state.maxPage > 1)
                  ?
                  <div style={{margin:"20px auto"}}>
                      <img src="/images/input/arrow-left.png" className="breakdown-left" onClick={()=>{this.onChangePage("left")}}/>
                          Page {this.state.currentPage} of {this.state.maxPage}
                      <img src="/images/input/arrow-left.png" className="breakdown-right" onClick={()=>{this.onChangePage("right")}}/>
                  </div>
                  :
                  null
                }
            </div>
        )
    }
}

class DownloadResults extends React.Component{

    state={
        active:false,
        step:0,
        loading:false,
        loadText:"",
    };

    onDownload=()=>{
        const result = this.refs['resultID'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Preparing results..."});
        const content = this.props.content;
        u.autoDownload({
            url:"/api/download-survey-results",
            data:{
                surveyID:this.props.surveyID,
                resultID:result.value,
            },
            success:()=>{
                this.setState({loading:false,step:1});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }

        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    };

    show=(show)=>{
        this.setState({active:show});
        if(!show){
            this.setState({loading:false,error:"",step:0});
        }
    }

    render=()=>{
        return(
            <Segment active={this.state.active} align="center">
                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="segment-title">Download Results</div>
                    <div className="segment-error">{this.state.error}</div>

                    <Step active={this.state.step==0}>
                        <div>
                            <div className="segment-content" style={{textAlign:"center"}}>
                                <h3>Select the date of results</h3>
                                <div className="segment-full-item">
                                    <Select ref="resultID" label="Result" values={{options:this.props.resultOptions}}/>
                                </div>
                                <div className="segment-buttons">
                                    <Button onClick={this.onCancel}>Cancel</Button>
                                    <Button onClick={this.onDownload}>Download</Button>
                                </div>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step==1}>
                        <div style={{textAlign:"center", padding:"10px 0 0 0"}}>
                            <SegmentComplete onComplete={()=>{this.setState({step:"0",error:""})}}>Download has successfully started</SegmentComplete>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
DownloadResults.propTypes={
    resultOptions:PropTypes.array.isRequired,
    surveyID:PropTypes.string,
};
