import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,
        Segment,LoadGraphic,SegmentComplete,Step,DynamicListArea,
        BulkEdit,DownloadTemplate} from '../components/Common';
import {EditSizeLabel,EditSizeLink,EditableBox,Select,
        Button,Date,Box,Time,Info,TextButton,MiniMinusButton,
        Area,BoolSlider} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';

export default class ExitRequestList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Exit Request",
        permission:"exit",  //Accepts combined permission module as well
        //combinedField:"price",     //Used when permission exists in a combined field
        url:"/exit-request-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/add-exit-request-entry",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-exit-request-entries",
        spreadsheetURL:"/api/generate-exit-request-spreadsheet",
        overviewURL:"/api/get-all-exit-request-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"exit-request-list",
        orderByField:"requestedOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddExitRequestEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        settings:{component:ExitRequestSettings,props:{}}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("exit");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0 && (permissions.update));
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add Entry",ref:"add",enabled:(permissions.create)},
                {label:"Delete Entries",ref:"delete",enabled:(permissions.delete)},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:combined.spreadsheet},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Configure Settings",ref:"settings",enabled:bulkEditEnabled,padding:"3px"},
            ],
            permissions:permissions,
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ExitRequestItem key={index}
                                              index={index}
                                              content={content}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              options={this.state.options}
                                              permissions={this.state.permissions}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class ExitRequestItem extends React.Component{
    state={
        step:"view",
        error:"",
        loading:false,
        loadText:"",
    };

    onApprove=()=>{
        this.setState({loading:true,loadText:"Approving...",error:""});
        u.post({
            url:"/api/update-exit-request-status",
            data:{
                exitID:this.props.content.exitID,
                status:"Approved"
            },
            success:()=>{
                this.props.onChange("Approved","status",this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onReject=()=>{
        this.setState({error:""});
        const rejectReason = this.refs['rejectReason'].validate();
        if(!rejectReason.valid){
            this.setState({error:rejectReason.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Rejecting..."});
        u.post({
            url:"/api/update-exit-request-status",
            data:{
                exitID:this.props.content.exitID,
                status:"Rejected",
                rejectReason:rejectReason.value
            },
            success:()=>{
                this.props.onChange("Rejected","status",this.props.index);
                this.props.onChange(rejectReason.value,"rejectReason",this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onException=()=>{
        this.setState({loading:true,loadText:"Loading...",error:""});
        u.post({
            url:"/api/update-exit-request-status",
            data:{
                exitID:this.props.content.exitID,
                status:"Exception"
            },
            success:()=>{
                this.props.onChange("Exception","status",this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const content = this.props.content;
        const options = this.props.options;
        const canEdit=this.props.permissions.update;

        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>
                <Step active={this.state.step == "view"}>

                    <DynamicListArea content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     listLabel={"exit-request-list"}
                                     permission="exit"/>

                    <div className="list-segment-buttons">
                        {
                            ((content.status == "Exited" || content.status == "Approved") && canEdit)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"exception"})}}>Exception</Button>
                                :
                                null
                        }
                        {
                            ((content.status == "Pending" || content.status == "Approved")  && canEdit)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"reject"})}}>Reject</Button>
                                :
                                null
                        }
                        {
                            ((content.status == "Pending") && canEdit)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"approve"})}}>Approve</Button>
                                :
                                null
                        }
                    </div>
                </Step>

                <Step active={this.state.step == "approve"}>
                    <div style={{padding:"30px 0",textAlign:"center"}}>
                        <h2 className="text-emphasis">Confirm Approve Request?</h2>
                        <div className="list-segment-note">Note. You may still reject the request before the exit date and the resident will be notified</div>
                        <div className='list-segment-buttons'>
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onApprove}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reject"}>
                    <div style={{padding:"30px 0",textAlign:"center"}}>
                        <h2 className="text-negative">Confirm Reject Request?</h2>
                        <div className="segment-full-item" style={{fontSize:"1.1em"}}>
                            <Area ref="rejectReason" label="Reject Reason" rules={{required:false,placeholder:"(Optional) Reason for rejection, this reason will be displayed to the user"}}/>
                        </div>
                        <div className="list-segment-note">Note. You may still approve the request before the exit date and the resident will be notified</div>
                        <div className='list-segment-buttons'>
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onReject}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "exception"}>
                    <div style={{padding:"30px 0",textAlign:"center"}}>
                        <h2 className="text-negative">Confirm Set As Exception?</h2>
                        <div className="list-segment-note">Note. Once set as exception, this entry will be 'cancelled' and removed from dashboards</div>
                        <div className='list-segment-buttons'>
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onException}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddExitRequestEntry extends React.Component{

    state={
        entryData:{},
        step:"add",
        options:{
           exitRequestReason:[],
           exitEscortedBy:[],
           exitLocation:[],
        }
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["exitRequestReason","exitEscortedBy","exitLocation"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        this.setState({entryData:{}});
        this.props.onToggleForm(this.props.reference,false);
    }

    onComplete=()=>{
        this.setState({entryData:{}});
    }

    onValidate=()=>{
        this.setState({error:""});
        const keys=["tenantFIN","exitFromDate","exitFromTime","exitToDate","exitToTime","requestReason","escortedBy","location"];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }
        data.exitFrom = data.exitFromDate + "T" + data.exitFromTime;
        data.exitTo = data.exitToDate + "T" + data.exitToTime;

        if(moment(data.exitFrom).isAfter(data.exitTo,"second")){
            this.setState({error:"To date/time cannot be before From date/time"});
            return 0;
        }

        this.refs[this.props.reference].onValidated(data);
    }

    onChange=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const options = this.state.options;

        return(
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}>
                <div className="segment-content">
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <div>
                            <h3>Complete the following to continue</h3>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Resident FIN</div>
                                <div className="segment-item-input"><Box ref="tenantFIN" values={{value:entryData.tenantFIN}} label="Resident FIN" field="tenantFIN" rules={{required:true}} onChange={this.onChange}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">From Date</div>
                                <div className="segment-item-input">
                                    <Date ref="exitFromDate" label="Exit From" field="exitFromDate" values={{value:entryData.exitFromDate}} range={[1,2]} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">From Time</div>
                                <div className="segment-item-input">
                                    <Time ref="exitFromTime" values={{value:entryData.exitFromTime}} label="From Time" field="exitFromTime" rules={{skipSeconds:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">To Date</div>
                                <div className="segment-item-input">
                                    <Date ref="exitToDate" label="Exit To" field="exitToDate" values={{value:entryData.exitToDate}} range={[1,2]} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">To Time</div>
                                <div className="segment-item-input">
                                    <Time ref="exitToTime" values={{value:entryData.exitToTime}} label="To Time" field="exitToTime" rules={{skipSeconds:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            {
                                (options.exitRequestReason.length > 0)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Exit Reason</div>
                                        <div className="segment-item-input">
                                            <Select ref="requestReason"
                                                    values={{
                                                        value:entryData.requestReason,
                                                        options:options.exitRequestReason,
                                                    }}
                                                    rules={{required:true}}
                                                    label="Exit Reason"
                                                    field="requestReason"
                                                    onChange={this.onChange}
                                                    />
                                        </div>
                                    </div>
                                    :
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Exit Reason</div>
                                        <div className="segment-item-input">
                                            <Area ref="requestReason" values={{value:entryData.requestReason}} rules={{required:true}} label="Exit Reason" field="requestReason" onChange={this.onChange}/>
                                        </div>
                                    </div>
                            }

                            {
                                (options.exitEscortedBy.length > 0)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Escorted By</div>
                                        <div className="segment-item-input">
                                            <Select ref="escortedBy"
                                                    values={{
                                                        value:entryData.escortedBy,
                                                        options:options.exitEscortedBy,
                                                    }}
                                                    rules={{required:true}}
                                                    label="Escorted By"
                                                    field="escortedBy"
                                                    onChange={this.onChange}
                                                    />
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                (options.exitLocation.length > 0)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Location</div>
                                        <div className="segment-item-input">
                                            <Select ref="location"
                                                    values={{
                                                        value:entryData.location,
                                                        options:options.exitLocation,
                                                    }}
                                                    rules={{required:true}}
                                                    label="Location"
                                                    field="location"
                                                    onChange={this.onChange}
                                                    />
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className="segment-buttons">
                                {
                                    (u.isMobileDevice())
                                        ?
                                        <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                        :
                                        null
                                }
                                <Button type="medium" onClick={this.onValidate}>Create</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </AddListItem>
        )
    }
}

class ExitRequestSettings extends React.Component{

    state={
        active:false,
        content:{},
        options:{},
        madeChanges:false,
        entryData:{},
        step:"view",
        loading:false,
        loadText:"",
    }

    show=(show)=>{
        this.setState({active:show});
    }

    componentDidMount=()=>{
        this.setState({canEdit:u.getPermissions("exit").update});
        //this.getOptions();
        this.getContent();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:[],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
              this.setState({error:error});
            }
        });
    }

    getContent=()=>{
        u.post({
            url:"/api/get-exit-request-settings",
            data:{},
            success:(content)=>{
                this.setState({
                  entryData:Object.assign({},content),
                  content:content,
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,options,autoUpdate)=>{
        console.log(value,label,index);
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});

        if(!autoUpdate){
            this.setState({madeChanges:true});
        }
    }

    onSubmit=()=>{
        this.setState({error:""});
        let keys=[
            "disclaimer",
            "earliestExitTime",
            "latestEntryTime",
        ];

        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.setState({loading:true,loadText:"Updating Settings..."});

        u.post({
            url:"/api/update-exit-request-settings",
            data:{
                old:this.props.content,
                new:this.state.entryData,
            },
            success:()=>{
                this.setState({loading:false,step:"complete"});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        if(this.props.onToggleForm){
            this.props.onToggleForm(this.props.reference,false);
        }
    }

    onComplete=()=>{
        this.setState({
            step:"view",
            error:"",
            madeChanges:false,
        });
    }

    render=()=>{
        const canEdit=this.state.canEdit;
        const madeChanges=this.state.madeChanges;
        const content=this.state.entryData;
        const options=this.state.options;

        return (
            <Segment active={this.state.active}>
                <div className="segment-title">Exit Request Rules</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <Step active={this.state.step == "view"}>
                    <div className="segment-content" style={{position:"relative",textAlign:"center"}}>
                        <h3>Configure Exit Request Rules</h3>

                        <div style={{margin:"20px auto"}}>
                        {
                            (canEdit)
                                ?
                                <div className="segment-error">Changes will only take effect after you click Save Changes</div>
                                :
                                <div className="segment-error">You do not have the necessary permissions to make changes</div>
                        }
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Auto Approve Requests</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.autoApprove}
                                                index="autoApprove"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        For all dormitories associated with this owner,
                                    </div>
                                    <div className="row-item" style={{marginBottom:"20px"}}>
                                        exit requests will be automatically approved immediately
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Display Disclaimer</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.displayDisclaimer}
                                                index="displayDisclaimer"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Following disclaimer will be displayed when creating an exit request
                                    </div>
                                </div>

                                <div className="segment-full-item" style={{width:"400px"}}>
                                    <Area ref="disclaimer"
                                          values={{
                                                value:content.disclaimer,
                                                enabled:(content.displayDisclaimer && canEdit)
                                           }}
                                          type="number"
                                          label="Disclaimer Message"
                                          field="disclaimer"
                                          rules={{required:true,rows:3}}
                                          onChange={this.onChange}
                                          />
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Earliest Exit Time</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitExitTime}
                                                index="limitExitTime"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Restrict the earliest exit time (Exit From) for an exit request
                                    </div>

                                    <div style={{position:"relative",margin:"10px auto"}}>
                                        <div className="row-item">
                                            to be no earlier than
                                        </div>

                                        <div className="row-item" style={{width:"250px",margin:"0 10px"}}>
                                            <Time ref="earliestExitTime"
                                                  values={{
                                                      value:content.earliestExitTime,
                                                      enabled:(content.limitExitTime && canEdit)
                                                  }}
                                                  label="Earliest Exit Time"
                                                  field="earliestExitTime"
                                                  onChange={this.onChange}
                                                  />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Latest Return Time</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitEntryTime}
                                                index="limitEntryTime"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Restrict the latest return time (Exit To) for an exit request
                                    </div>

                                    <div style={{position:"relative",margin:"10px auto"}}>
                                        <div className="row-item">
                                            to be no later than
                                        </div>

                                        <div className="row-item" style={{width:"250px",margin:"0 10px"}}>
                                            <Time ref="latestEntryTime"
                                                  values={{
                                                      value:content.latestEntryTime,
                                                      enabled:(content.limitEntryTime && canEdit)
                                                  }}
                                                  label="Latest Return Time"
                                                  field="latestEntryTime"
                                                  onChange={this.onChange}
                                                  />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={this.onCancel}>Cancel</Button>
                            <Button onClick={this.onSubmit} enabled={canEdit && madeChanges}>Save Changes</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "complete"} scroll={true}>
                    <div className="segment-content" style={{position:"relative",textAlign:"center"}}>
                        <SegmentComplete onComplete={this.onComplete}>
                            Successfully updated <span className="text-emphasis">exit request settings</span>
                        </SegmentComplete>
                    </div>
                </Step>
            </Segment>
        )
    }
}
