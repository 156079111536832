import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import {Databox,DataboxGeneral,PageMenu,UploadPhoto,Segment,Step,DynamicDetailArea,LoadGraphic} from '../components/Common';
import {EditableBox,EditableSelect,EditableDate,EditableContactNumber,ContactNumber,Box,Button,Select,
        MiniPlusButton,MiniMinusButton,TextButton,Checkbox,Menu,Info,PageSelector} from '../components/Input';
import Notebook from '../components/Notebook';
import {ResidentDownloadTemplate} from "./ResidentList";
import {EstateUtilities} from './Estate';
import moment from 'moment';
import {Link} from 'react-router-dom';
import '../styles/client.scss';

export default class Client extends React.Component{

    id = this.props.match.params.clientID;

    state={
        error:"",
        permissions:{},
        content:{
            contactList:[],
            assignedRooms:[],
            assignedBeds:[],
        },
        pageMenuButtons:[],
    };

    settings={
        /**** Page Setup ****/
        title:"Organization",
        permission:"client",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/client",
        label:"client",
        hasNotebook:true,
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const residentPerms = u.getPermissions("resident");
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Manifest",ref:"manifest",mobileLabel:"Organization Manifest",image:"/images/page-menu/room-manifest.png",title:"View room manifest",enabled:true,padding:"3px",onClick:this.onGoToManifest},
              {label:"Template",mobileLabel:"Download Add Resident Template",ref:"download",image:"/images/page-menu/template.png",title:"Download add resident template",enabled:(residentPerms.view==2)},
            ]
        });

        if(this.id){
            this.getContent(this.id);
        }
        else {
            this.setState({error:"Organization Not Found"});
        }
    }

    getContent=(id)=>{
        u.post({
            url:"/api/get-client",
            data:{
                clientID:id
            },
            success:(callback)=>{
                this.setState({content:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,error,option)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    onGoToManifest=()=>{
        store.set("room-manifest",{clientID:this.state.content.clientID});
        window.open("/room-manifest","_blank");
    }

    onUpdateContact=(contactListID,value,label)=>{
        if(this.state.content.contactListID == contactListID){
            if(label == "contactName" || label == "contactNumber" || label == "contactEmail"){
                let content = this.state.content;
                content[label]=value;
                this.setState({content:content});
            }
        }
    }

    render=()=>{
        const {content,permissions} = this.state;
        if(typeof content.clientID == "undefined"){
            return <div/>
        }

        const pageComponents={
            download:{component:ResidentDownloadTemplate,props:{clientID:content.clientID}},
        };

        return (
            <AppLayout settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}/>

                <DynamicDetailArea settings={this.settings}
                                   content={content}
                                   onChange={this.onContentChange}/>

                <AddressBook content={content} onChange={this.onContentChange} onUpdateContact={this.onUpdateContact}/>

                <PortalAdmin content={content} onChange={this.onContentChange} permissions={permissions} clientID={content.clientID}/>

                <Assignments content={content} onChange={this.onContentChange} type="room"/>

                <Assignments content={content} onChange={this.onContentChange} type="bed"/>

                <Notebook module={"client"} moduleID={this.id} notebookID={content.notebookID}/>
            </AppLayout>
        )
    }
}

class AddressBook extends React.Component {

    state = {
        content: [],
        permissions:{},
        error: "",
        step: "view",
        contactType:[],
        delete:{},
    };

    componentDidMount=()=>{
        this.setState({permissions:u.getPermissions("client")});
        this.getOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["contactType"]
            },
            success:({contactType})=>{
                this.setState({contactType:contactType});
            }
        });
    }

    onChange=(value, field, index, option)=>{
        let content = this.props.content.contactList;
        content[parseInt(index)][field] = value;
        this.props.onChange(content, "contactList");

        const contactListID = content[parseInt(index)].contactListID;
        this.props.onUpdateContact(contactListID,value,field);
    }

    enable=(enable)=>{
        this.setState({enabled:enable});
    }

    onSubmit=()=>{
        this.setState({error: ""});

        const keys = ["contactName","contactType","contactNumber","contactEmail","contactComments"];
        let data = {};
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }

            if(typeof current.contactNumberField != "undefined"){
                data[current.contactNumberField] = current.contactNumber;
                data[current.countryCodeField] = current.countryCode;
            }
            else{
                data[keys[i]] = current.value;
            }
        }


        data.clientID = this.props.content.clientID;

        this.enable(false);

        u.post({
            url: "/api/client-add-contact",
            data: data,
            success: (contactListID)=>{
                let contactList = this.props.content.contactList;
                data.contactListID = contactListID;
                data.clientID=undefined;
                contactList.push(data);
                this.props.onChange(contactList,"contactList");
                this.setState({step:"view",error:""});
                this.enable(true);
            },
            error: (error)=>{
                this.enable(true);
                this.setState({error: error});
            }
        })
    }

    onDeleteRequest=(index)=>{
        let d = this.props.content.contactList[index];
        d.index = index;
        this.setState({step:"delete",error:"",delete:d});
    }

    onDeleteConfirm=()=>{
        this.setState({error: ""});
        u.post({
            url: "/api/client-remove-contact",
            data: {
                clientID: this.props.content.clientID,
                contactListID: this.state.delete.contactListID
            },
            success: (e)=>{
                let contactList = this.props.content.contactList;
                contactList.splice(this.state.delete.index, 1);
                this.props.onChange(contactList,"contactList");
                this.setState({step:"view"});
            },
            error:(error)=>{
                this.setState({error: error});
            }
        });
    }

    onChangeCheckbox=(value,label,index)=>{
        u.post({
            url:"/api/update-client-additional-contact",
            data:{
                oldValue:this.props.content[label],
                newValue:value,
                field:label,
                id:this.props.content.clientID,
                additionalData:this.props.content.contactList[index].contactListID
            },
            success:()=>{
                this.onChange(value,label,index);
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    };

    render=()=>{
        const content = this.props.content;
        const enabled = (this.state.permissions.update == 2);
        const url = "/api/update-client-additional-contact";

        return (
            <Databox active={true} title="Address Book">
                <div className="client-contact-list">
                    <div className="page-error">{this.state.error}</div>
                    <Step active={(this.state.step == "view")}>
                        <div className="contact-list-content">
                            {
                                (content.contactList.length > 0)
                                    ?
                                    <div>
                                        <div className="data-box-menu">
                                            <MiniPlusButton label="Add New Contact" onClick={()=>{this.setState({step:"add",error:""})}}/>
                                        </div>

                                        <div>
                                            <div className="con-remove"></div>
                                            <h4 className="con-name">Name</h4>
                                            <h4 className="con-notify">Type</h4>
                                            <h4 className="con-num">Number</h4>
                                            <h4 className="con-email">Email</h4>
                                            <h4 className="con-comments">Remarks</h4>
                                        </div>

                                        <div className="contact-list-items">
                                        {
                                              content.contactList.map(
                                                  (contact, index)=> {
                                                      return (
                                                          <div key={index} className="contact-list-item">
                                                              <div className="con-remove" title="Delete Contact">
                                                                  <div style={{width:'20px',height:"20px"}}>
                                                                      <MiniMinusButton index={index} enabled={(index != 0)} onClick={this.onDeleteRequest}/>
                                                                  </div>
                                                              </div>
                                                              <div className="con-name">
                                                                  <EditableBox id={content.clientID} label="Contact Name" field="contactName" index={index} url={url} values={{value: contact.contactName, enabled: enabled}} rules={{additionalData:contact.contactListID,required: true, maxLength: 50}} onChange={this.onChange}/>
                                                              </div>
                                                              <div className="con-notify">
                                                                  <EditableSelect id={content.clientID} label="Type" field="contactType" index={index} url={url} values={{value: contact.contactType, options: this.state.contactType, enabled: (enabled && index != 0)}} rules={{additionalData:contact.contactListID}} onChange={this.onChange}/>
                                                              </div>
                                                              <div className="con-num">
                                                                  <EditableContactNumber id={content.clientID}
                                                                                         countryCodeField="countryCode"
                                                                                         contactNumberField="contactNumber"
                                                                                         values={{countryCode:contact.countryCode,contactNumber: contact.contactNumber, enabled: enabled}}
                                                                                         rules={{additionalData:contact.contactListID,required:(index==0)}}
                                                                                         index={index}
                                                                                         url={url}
                                                                                         onChange={this.onChange}/>
                                                              </div>
                                                              <div className="con-email">
                                                                  <EditableBox id={content.clientID} label="Contact Email" type="email" field="contactEmail" index={index} url={url} values={{value: contact.contactEmail, enabled: enabled}} rules={{additionalData:contact.contactListID}} onChange={this.onChange}/>
                                                              </div>
                                                              <div className="con-comments">
                                                                  <EditableBox id={content.clientID} label="Remarks" field="contactComments" index={index} url={url} values={{value: contact.contactComments, enabled: enabled}} rules={{additionalData:contact.contactListID,maxLength: 50}} onChange={this.onChange}/>
                                                              </div>
                                                          </div>
                                                      )
                                                  })
                                        }
                                        </div>
                                    </div>
                                    :
                                    <div className="contact-list-empty">
                                        <Button type="medium" onClick={this.addNewContact}>Add New Contact</Button>
                                    </div>
                            }
                        </div>
                    </Step>

                    <Step active={(this.state.step == "add")}>
                        <div style={{position:"relative",fontSize:"14px"}}>
                            <h3>Fill in the following to continue</h3>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Name</div>
                                <div className="segment-item-input"><Box ref="contactName" label="Name" rules={{required:true ,maxLength: 50}}/></div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Type</div>
                                <div className="segment-item-input"><Select ref="contactType" label="Type" values={{options:this.state.contactType}}/></div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Phone</div>
                                <div className="segment-item-input">
                                    <ContactNumber ref="contactNumber" contactNumberField="contactNumber" countryCodeField="countryCode"/>
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Email</div>
                                <div className="segment-item-input"><Box ref="contactEmail" label="Email" type="email"/></div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Remarks</div>
                                <div className="segment-item-input"><Box ref="contactComments" label="Remarks" rules={{maxLength: 50}}/></div>
                            </div>

                            <div className="contact-list-add-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                <Button type="medium" onClick={this.onSubmit}>Add</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "delete")}>
                        <div style={{position:"relative",fontSize:"14px"}}>
                            <h3 className="text-negative">Confirm delete {this.state.delete.contactName} permanently?</h3>
                            <div className="segment-note">Note. This action cannot be reversed</div>
                            <div className="contact-list-add-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                <Button type="medium" onClick={this.onDeleteConfirm}>Delete</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Databox>
        )
    }
}

class PortalAdmin extends React.Component{
    
    r={};

    pageLength=10;

    state={
        step:"view",
        page:1,
        pStart:0,
        pEnd:10,
        loading:false,
        loadText:"",
    }

    onChangePage=(newPage)=>{
        const pStart = (newPage-1) * this.pageLength;
        const pEnd = pStart + this.pageLength;
        this.setState({
            page:newPage,
            pStart:pStart,
            pEnd:pEnd
        });
    }

    onConfirmAdd=async ()=>{
        const keys=["clientUserName","clientUserEmail"];
        let data={};
        for(var i=0; i<keys.length; i++){
            let current=this.r[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }
        this.setState({loading:true,loadText:"Adding..."});
        try{
            const response = await u.postAsync({
                url:"/api/add-cp-account",
                data:{
                    ...data,
                    clientID:this.props.clientID
                }
            });
            let {content} = this.props;
            content.portalAdmin.unshift(response.data);
            this.props.onChange(content);
            this.setState({loading:false,step:"view"});
        }
        catch(e){
            const {error,status}=u.processError(e);
            this.setState({error:error,loading:false});
        }
    }

    onResendEmail=async ()=>{
        this.setState({loading:true,loadText:"Sending Email...."});
        const {content}=this.props;
        try{
            const response = await u.postAsync({
                url:"/api/send-cp-account-email",
                data:{
                    clientID:content.clientID,
                    clientUser:content.portalAdmin[this.state.resendIndex]
                }
            });
            this.setState({step:"send-success",error:"",loading:false});
        }
        catch(e){
            const {error,status} = u.processError(e);
            this.setState({loading:false,error:error});
        }
    }

    onRemoveRequest=(index)=>{
        this.request={
            type:"Remove",
            index:index
        };
        console.log(this.request);
        this.setState({step:"remove",error:""});
    }

    onTerminateRequest=(index)=>{
        this.request={
            type:"Terminated",
            index:index
        };
        console.log(this.request);
        this.setState({step:"terminate",error:""});
    }

    onReactivateRequest=(index)=>{
        this.request={
            type:"Reactivate",
            index:index
        };
        console.log(this.request);
        this.setState({step:"reactivate",error:""});
    }

    onConfirmTerminate=async ()=>{
        this.setState({loading:true,loadText:"Terminating Account..."});
        const {content}=this.props;
        try{
            const response = await u.postAsync({
                url:"/api/terminate-cp-account",
                data:{
                    clientID:content.clientID,
                    clientUser:content.portalAdmin[this.request.index],
                }
            });
            content.portalAdmin[this.request.index].status = "Terminated";
            this.props.onChange(content.portalAdmin,"portalAdmin");
            this.setState({loading:false,step:"view",error:""});
        }
        catch(e){
            const {error,status} = u.processError(e);
            this.setState({loading:false,error:error});
        }
    }

    onConfirmRemove=async ()=>{
        this.setState({loading:true,loadText:"Removing Account...."});
        const {content}=this.props;
        try{
            const response = await u.postAsync({
               url:"/api/delete-cp-account",
               data:{
                    clientID:content.clientID,
                    clientUser:content.portalAdmin[this.request.index],
               } 
            });
            content.portalAdmin.splice(this.request.index,1);
            this.props.onChange(content.portalAdmin,"portalAdmin");
            this.setState({loading:false,step:"view",error:""});
        }
        catch(e){
            const {error,status} = u.processError(e);
            this.setState({loading:false,error:error});
        }
    }

    onConfirmReactivate=async ()=>{
        this.setState({loading:true,loadText:"Reactivating Account...."});
        const {content}=this.props;
        try{
            const response = await u.postAsync({
               url:"/api/reactivate-cp-account",
               data:{
                    clientID:content.clientID,
                    clientUser:content.portalAdmin[this.request.index],
               } 
            });
            content.portalAdmin[this.request.index].status = "Pending";
            this.props.onChange(content.portalAdmin,"portalAdmin");
            this.setState({loading:false,step:"view",error:""});
        }
        catch(e){
            const {error,status} = u.processError(e);
            this.setState({loading:false,error:error});
        }
    }

    render=()=>{
        const {content,permissions}=this.props;
        const {page,pStart,pEnd} = this.state;
        const maxPage = Math.ceil(content?.portalAdmin.length / this.pageLength);
        return(
            <Databox active={true} title="Portal Administrators" align="center">
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <Step active={this.state.step=="view"}>
                    {
                        (content.portalAdmin?.length > 0)
                            ?
                            <div className='list-table portal-admin-table' style={{maxHeight:"none"}}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className='remove'/>
                                            <th>User</th>
                                            <th>Email</th>
                                            <th style={{width:"230px"}}>Created On</th>
                                            <th style={{width:"230px"}}>Last Login</th>
                                            <th style={{width:"130px"}}>Status</th>
                                            <th style={{width:"150px"}}>Activation</th>
                                            <th style={{width:"100px"}}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            content.portalAdmin.map(
                                                (item,index)=>{
                                                    if(index < pStart || index > pEnd){
                                                        return null;
                                                    }
                                                    return (
                                                        <tr key={index} className='item' title={"Created By - " + item.createdByName}>
                                                            <td className='remove'>
                                                                {index+1}.
                                                            </td>
                                                            <td>{item.clientUserName}</td>
                                                            <td>{item.clientUserEmail}</td>
                                                            <td style={{width:"230px"}}>{moment(item.createdOn).format("DD MMM YYYY - h:mma")}</td>
                                                            <td style={{width:"230px"}}>{moment(item.lastLogin).format("DD MMM YYYY - h:mma")}</td>
                                                            <td style={{width:"130px"}}>{item.status}</td>
                                                            <td style={{width:"150px"}}>
                                                                {
                                                                    (item.status == "Pending")
                                                                        ?
                                                                        <TextButton style={{margin:"0"}} onClick={()=>{this.setState({step:"confirm-send",error:"",resendIndex:index})}}>Resend Email</TextButton>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    (item.status == "Terminated")
                                                                        ?
                                                                        <TextButton style={{margin:"0"}} onClick={()=>{this.onReactivateRequest(index)}}>Re-Activate</TextButton>
                                                                        :
                                                                        null
                                                                }
                                                            </td>
                                                            <td style={{width:"100px"}}>
                                                                {
                                                                    (item.status == "Terminated")
                                                                            ?
                                                                            <TextButton style={{margin:"0"}} onClick={()=>{this.onRemoveRequest(index)}}>Remove</TextButton>
                                                                            :
                                                                            <TextButton style={{margin:"0"}} onClick={()=>{this.onTerminateRequest(index)}}>Terminate</TextButton>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            )
                                        }
                                    </tbody>
                                </table>
                                {
                                    (maxPage > 1)
                                        ?
                                        <div style={{margin:"30px auto 20px auto"}}>
                                            <PageSelector page={page}
                                                          maxPage={maxPage}
                                                          onChange={this.onChangePage}
                                                          />
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            :
                            <div className='list-placeholder'>
                                <h3>No Portal Administrators</h3>
                                <div>Portal admins can log into /supplier and create resident transactions</div>
                            </div>
                    }
                    {
                        (permissions.update)
                            ?
                            <div style={{margin:"20px auto 0 auto"}}>
                                <TextButton onClick={()=>{this.setState({step:"add",error:""})}}>Add Portal Admin</TextButton>
                            </div>
                            :
                            null
                    }
                </Step>

                <Step active={this.state.step=="add"}>
                    <div style={{position:"relative",fontSize:"14px"}}>
                        <h3>Complete the following to add portal administrator</h3>

                        <div className='segment-full-item'>
                            <div className='segment-item-label'>Name:</div>
                            <div className='segment-item-input'>
                                <Box ref={(ref)=>{this.r["clientUserName"]=ref}}
                                    label="Admin Name"
                                    rules={{required:true}}
                                    />
                            </div>
                        </div>

                        <div className='segment-full-item'>
                            <div className='segment-item-label'>Email:</div>
                            <div className='segment-item-input'>
                                <Box ref={(ref)=>{this.r["clientUserEmail"]=ref}}
                                    label="Email"
                                    type="email"
                                    rules={{required:true}}
                                    />
                            </div>
                        </div>

                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onConfirmAdd}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="terminate"}>
                    <div>
                        <h2 className='text-negative'>Confirm Terminate Administrator?</h2>
                        <div>Admin will not be able to log in effective immediately, entry will not be deleted</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onConfirmTerminate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div>
                        <h2 className='text-negative'>Confirm Remove Administrator?</h2>
                        <div>Entry will be deleted permanently, this action cannot be undone</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onConfirmRemove}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="add-complete"}>
                    <div>
                        <h2>Confirm Remove Administrator?</h2>
                        <div>Account activation email has been automatically send to the user's email account</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="confirm-send"}>
                    <div>
                        <h2>Confirm Resend Account Activation Email?</h2>
                        <div>Ensure the administrator checks his inbox and spam folders</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onResendEmail}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="reactivate"}>
                    <div>
                        <h2>Confirm Reactivate Portal User?</h2>
                        <div>Account creation email will be sent to the respective user automatically</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Cancel</Button>
                            <Button onClick={this.onConfirmReactivate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step=="send-success"}>
                    <div>
                        <h2 className='text-emphasis'>Email Sent Successfully</h2>
                        <div>Ensure the administrator checks his inbox and spam folders</div>
                        <div style={{margin:"20px auto"}}>
                            <div className='page-error'>{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </Databox>
        ) 
    }
}

export class Assignments extends React.Component{

    state={
      step:"view",
      assignment:[],
      dormOptions:[],
      clusterOptions:[],
      buildingOptions:[],
      dorm:"all",
      cluster:"all",
      building:"all",
      config:{},
      selected:[],
      permissions:{},
      menuOptions:[],
      maxPage:1,
      currentPage:1,
      indexMin:0,
      indexMax:10,
      linesPerPage:10,
      loading:false,
      loadText:"",
    };

    static getDerivedStateFromProps(props,state){
        const estate = (props.type == "room") ? props.content.assignedRooms : props.content.assignedBeds;
        if(estate != state.estate && state.config.dorm){
            state.estate = estate;
            let dormOptions = [{value:'all',label:"All " + state.config.dormLabel}];
            let clusterOptions = [{value:'all',label:"All " + state.config.clusterLabel}];
            let buildingOptions = [{value:'all',label:"All " + state.config.buildingLabel}];

            state.assignment = estate.map(
                (item,index)=>{
                    item.label = EstateUtilities.getPrefixValue(item,state.config.roomPrefix) + item.location.roomName + ((props.type == "bed") ? "/" + item.location.bedName : "");
                    item.locationLabel = EstateUtilities.getLocationLabel(item,state.config);

                    if(dormOptions.filter((option)=>{return option.value == item.location.dormID}) == 0){
                        dormOptions.push({value:item.location.dormID,label:state.config.dormPrefix + item.location.dormName});
                    }
                    if(state.config.cluster){
                        if(clusterOptions.filter((option)=>{return option.value == item.location.clusterID}) == 0){
                            clusterOptions.push({value:item.location.clusterID,label:state.config.clusterPrefix + item.location.clusterName});
                        }
                    }
                    if(state.config.building){
                        if(buildingOptions.filter((option)=>{return option.value == item.location.buildingID}) == 0){
                            buildingOptions.push({value:item.location.buildingID,label:state.config.buildingPrefix + item.location.buildingName});
                        }
                    }

                    return item;
                }
            ).filter(
                (item)=>{
                    return  ((state.dorm == "all" || item.location.dormID == state.dorm) &&
                            (state.cluster == "all" || item.location.clusterID == state.cluster) &&
                            (state.building == "all" || item.location.buildingID == state.building));
                }
            );

            state.dormOptions = dormOptions;
            state.clusterOptions = clusterOptions;
            state.buildingOptions = buildingOptions;

            state.maxPage= Math.ceil(state.assignment.length / state.linesPerPage);
            state.currentPage=1;
            state.indexMin = (state.currentPage - 1) * state.linesPerPage;
            state.indexMax = state.currentPage * state.linesPerPage;
        }

        return state;
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("client");
        let menuOptions=[
          {label:"Add " + ((this.props.type == "room") ? "Room" : "Bed"),onClick:()=>{this.onNextStep("add")},enabled:(permissions.update==2)},
          {label:"Remove " + ((this.props.type == "room") ? "Room" : "Bed"),onClick:()=>{this.onNextStep("remove")},enabled:(permissions.update==2)},
        ];
        if(this.props.type == "room"){
            menuOptions.push(
              {label:"Convert To Bed ",onClick:()=>{this.onNextStep("convert-bed")},enabled:(permissions.update==2)},
            )
        }
        else{
            menuOptions.push(
              {
                label:"Convert To Room",
                enabled:(permissions.update==2),
                onClick:()=>{
                      const estates = (this.props.type == "room") ? this.props.content.assignedRooms : this.props.content.assignedBeds;
                      let selected = estates.filter((item)=>{return item.selected}).length;
                      if(selected == 0){
                          this.setState({error:"Select 1 bed from the room you would like to convert"});
                          return 0;
                      }
                      else if(selected > 1){
                          this.setState({error:"Select only 1 bed from the room you would like to convert"});
                          return 0;
                      }
                      this.onNextStep("convert-room");
                }
              }
            )
        }
        this.setState({
            permissions:permissions,
            config:EstateUtilities.getDormConfig(),
            menuOptions:menuOptions
        });
    }

    onSelect=(value,label,index)=>{
        let assignment = this.state.assignment;
        assignment[index].selected=value;
        this.setState({assignment:assignment});
    }

    onSelectAll=(value)=>{
        const estates = (this.props.type == "room") ? this.props.content.assignedRooms : this.props.content.assignedBeds;
        let assignment = estates.map(
          (item)=>{
              if(value){
                  if(this.checkFilter(item)){
                    item.selected = true;
                  }
              }
              else{
                  item.selected = false;
              }

              return item;
          }
        ).filter(
          (item)=>{
              return this.checkFilter(item);
          }
        );
        this.setState({selectAll:value,assignment:assignment});
    }

    checkFilter=(item,dormID,clusterID,buildingID)=>{
      const dorm = (dormID) ? dormID : this.state.dorm;
      const cluster = (clusterID) ? clusterID : this.state.cluster;
      const building = (buildingID) ? buildingID : this.state.building;

      return ((dorm == "all" || item.location.dormID == dorm) &&
              (cluster == "all" || item.location.clusterID == cluster) &&
              (building == "all" || item.location.buildingID == building));
    }

    onChangeFilter=(value,label,index,option)=>{
        const estates = (this.props.type == "room") ? this.props.content.assignedRooms : this.props.content.assignedBeds;
        const assignment = estates.filter(
          (item)=>{
            return this.checkFilter(item,
                                   (label=="dorm") ? value : this.state.dorm,
                                   (label=="cluster") ? value : this.state.cluster,
                                   (label=="building") ? value : this.state.building);
        });

        if(label == "dorm"){
            this.setState({dorm:value});
        }
        else if (label == "cluster"){
            this.setState({cluster:value});
        }
        else if(label == "building"){
            this.setState({building:value});
        }

        const maxPage= Math.ceil(assignment.length / this.state.linesPerPage);
        const indexMin = (this.state.currentPage - 1) * this.state.linesPerPage;
        const indexMax = this.state.currentPage * this.state.linesPerPage;
        const currentPage = 1;

        this.setState({assignment:assignment,maxPage:maxPage,indexMin:indexMin,indexMax:indexMax,currentPage:currentPage});
    }

    onChangePage=(dir)=>{
        if(dir == "left"){
            if(this.state.currentPage == 1){
                return 0;
            }
            else{
                const currentPage = this.state.currentPage - 1;
                const indexMin = (currentPage - 1) * this.state.linesPerPage;
                const indexMax = currentPage * this.state.linesPerPage;
                this.setState({currentPage:currentPage,indexMax:indexMax,indexMin:indexMin});
            }
        }
        else if(dir == "right"){
            if(this.state.currentPage == this.state.maxPage){
                return 0;
            }
            else{
                const currentPage = this.state.currentPage + 1;
                const indexMin = (currentPage - 1) * this.state.linesPerPage;
                const indexMax = currentPage * this.state.linesPerPage;
                this.setState({currentPage:currentPage,indexMax:indexMax,indexMin:indexMin});
            }
        }
    }

    onNextStep=(step)=>{
        const estates = (this.props.type == "room") ? this.props.content.assignedRooms : this.props.content.assignedBeds;
        const selected = estates.filter(
          (item)=>{return item.selected}
        );

        this.setState({step:step,selected:selected,error:""});
    }

    onRemoveEntries=()=>{
        this.setState({loading:true,loadText:"Removing entries...",error:""});
        if(this.props.agreement){
          u.post({
            url:"/api/agreement-remove-estate",
            data:{
                type:this.props.type,
                selected:this.state.selected,
                agreementID:this.props.content.agreementID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Removed " + entries + ((entries == 1) ? " Entry" : " Entries"),
                  selected:[]
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }
        else{
          u.post({
            url:"/api/client-remove-estate",
            data:{
                type:this.props.type,
                selected:this.state.selected,
                clientID:this.props.content.clientID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Removed " + entries + ((entries == 1) ? " Entry" : " Entries"),
                  selected:[]
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }
    }

    onConvertToBed=()=>{
        this.setState({loading:true,loadText:"Converting entries...",error:""});
        if(this.props.agreement){
          u.post({
            url:"/api/agreement-convert-to-bed",
            data:{
                selected:this.state.selected,
                agreementID:this.props.content.agreementID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Converted " + entries + ((entries == 1) ? " Room" : " Rooms") + " to Beds",
                  selected:[],
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }
        else{
          u.post({
            url:"/api/client-convert-to-bed",
            data:{
                selected:this.state.selected,
                clientID:this.props.content.clientID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Converted " + entries + ((entries == 1) ? " Room" : " Rooms") + " to Beds",
                  selected:[],
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }

    }

    onConvertToRoom=()=>{
        this.setState({loading:true,loadText:"Converting entries...",error:""});
        if(this.props.agreement){
          u.post({
            url:"/api/agreement-convert-to-room",
            data:{
                selected:this.state.selected,
                agreementID:this.props.content.agreementID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Converted Selected Bed to Full Room",
                  selected:[],
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }
        else{
          u.post({
            url:"/api/client-convert-to-room",
            data:{
                selected:this.state.selected,
                clientID:this.props.content.clientID
            },
            success:({assignedRooms,assignedBeds})=>{
                this.props.onChange(assignedRooms,"assignedRooms");
                this.props.onChange(assignedBeds,"assignedBeds");

                const entries = this.state.selected.length;
                this.setState({
                  step:"reset",
                  loading:false,
                  resetText:"Successfully Converted Selected Bed to Full Room",
                  selected:[],
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
          });
        }

    }

    onReset=()=>{
        this.onSelectAll(false);
        this.setState({step:"view",error:""});
    }

    onAddChange=(assignedRooms,assignedBeds,resetText)=>{
        this.props.onChange(assignedRooms,"assignedRooms");
        this.props.onChange(assignedBeds,"assignedBeds");
        this.setState({
            step:"reset",
            resetText:resetText
        });
    }

    render=()=>{
        const content=this.props.content;
        const config=this.state.config;
        const edit=(this.state.permissions.update == 2);
        const assignment=this.state.assignment;
        return(
            <Databox title={(this.props.type == "room") ? "Assigned Rooms" : "Assigned Beds"} active={true}>
                <div style={{position:"relative"}}>
                      <div className="page-error">{this.state.error}</div>
                      <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                      <Step active={this.state.step == "view"}>
                          <div className="assignment-table-container">
                              <div className="data-box-menu mobile-hide" style={{fontSize:"16px"}}>
                                  <Menu options={this.state.menuOptions}/>
                              </div>

                              {
                                  (this.state.dormOptions.length > 1)
                                      ?
                                      <div className="assignment-select-container">
                                          <div className="assignment-select">
                                                <Select label="Dormitory" field="dorm" values={{values:this.state.dorm,options:this.state.dormOptions}} onChange={this.onChangeFilter}/>
                                          </div>
                                          {
                                            (config.cluster)
                                              ?
                                              <div className="assignment-select">
                                                    <Select label="Cluster" field="cluster" values={{values:this.state.cluster,options:this.state.clusterOptions}} onChange={this.onChangeFilter}/>
                                              </div>
                                              :
                                              null
                                          }
                                          {
                                            (config.building)
                                              ?
                                              <div className="assignment-select">
                                                    <Select label="Building" field="building" values={{values:this.state.building,options:this.state.buildingOptions}} onChange={this.onChangeFilter}/>
                                              </div>
                                              :
                                              null
                                          }
                                      </div>
                                      :
                                      null
                              }

                              {
                                  (assignment.length > 0)
                                      ?
                                      <div>
                                          <div className="assignment-table">
                                              <table>
                                                  <tbody>
                                                      <tr>
                                                          <th className="mobile-hide" style={{width:"75px",textAlign:"center"}}><Checkbox values={{value:this.state.selectAll,enabled:edit}} onChange={this.onSelectAll}/></th>
                                                          <th>{(this.props.type == "room") ? "Room" : "Bed"}</th>
                                                          <th>Location</th>
                                                          <th className="mobile-hide">Occupied <Info title={(this.props.type == "room") ? "Resident assigned to at least 1 bed in this room" : "Resident assigned to this bed"} style={{width:"14px",height:"14px",marginBottom:"2px"}}/></th>
                                                          <th className="mobile-hide">Assigned On</th>
                                                          <th className="mobile-hide">Assigned By</th>
                                                      </tr>
                                                      {
                                                        assignment.map(
                                                          (item,index)=>{
                                                              if(index<this.state.indexMin || index >= this.state.indexMax){
                                                                              return null;
                                                              }

                                                              return (
                                                                  <tr className="table-item" key={index}>
                                                                      <td className="mobile-hide" style={{width:"75px",textAlign:"center"}}><Checkbox values={{value:item.selected,enabled:edit}} index={index} onChange={this.onSelect}/></td>
                                                                      <td><Link to={"/estate-list/room/"+item.location.roomID}><b>{item.label}</b></Link></td>
                                                                      <td>{item.locationLabel}</td>
                                                                      <td className="mobile-hide">{(item.occupied) ? "Yes" : "No"}</td>
                                                                      <td className="mobile-hide">{moment(item.dateAssigned).format("DD MMM YYYY")}</td>
                                                                      <td className="mobile-hide"><Link to={"/staff/"+item.staffID}>{item.staffName}</Link></td>
                                                                  </tr>
                                                              )
                                                          }
                                                        )
                                                      }
                                                  </tbody>
                                              </table>
                                          </div>
                                          {
                                              (this.state.maxPage > 1)
                                                ?
                                                <div className="assignment-page-container">
                                                    <img className="assignment-arrow" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("left")}}/>
                                                    <div className="assignment-page">Page {this.state.currentPage} / {this.state.maxPage}</div>
                                                    <img className="assignment-arrow right" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("right")}}/>
                                                    <div className="assignment-totals">
                                                        Total Entries: {assignment.length}
                                                    </div>
                                                </div>
                                                :
                                                null
                                          }
                                      </div>
                                      :
                                      <div className="assignment-placeholder">
                                          <h3>No {(this.props.type == "room") ? "Rooms" : "Beds"} Assigned</h3>
                                          <div>
                                              {
                                                  (this.state.dormOptions.length > 1)
                                                      ?
                                                      "Change the location selector above to view assignments"
                                                      :
                                                      "You must assign rooms or beds to add residents"
                                              }
                                          </div>
                                      </div>
                                }
                          </div>
                      </Step>

                      <Step active={this.state.step == "add"}>
                          <div className="assignment-action-container">
                              <AddAssignment type={this.props.type}
                                             content={this.props.content}
                                             onCancel={()=>{this.setState({step:"view",error:""})}}
                                             onChange={this.onAddChange}
                                             startDate={content.startDate}
                                             endDate={content.endDate}
                                             agreement={this.props.agreement}
                                             />
                          </div>
                      </Step>

                      <Step active={this.state.step == "remove"}>
                          <div className="assignment-action-container">

                              <h3 className="text-negative">Remove selected entries?</h3>
                              <div className="assignment-selected-bg">
                                  <div className="assignment-selected">{this.state.selected.length} {(this.state.selected.length==1) ? "entry" : "entries"} selected</div>
                                  <div className="assignment-note">Select entries from the list before</div>
                              </div>

                              <div className="data-box-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                  {
                                    (this.state.selected.length > 0)
                                      ?
                                      <Button type="medium" onClick={this.onRemoveEntries}>Confirm</Button>
                                      :
                                      null
                                  }
                              </div>
                          </div>
                      </Step>

                      <Step active={this.state.step == "convert-bed"}>
                          <div className="assignment-action-container">

                              <h3 className="text-emphasis">Convert selected rooms to individual beds?</h3>

                              <div className="assignment-selected-bg">
                                  <div className="assignment-selected">{this.state.selected.length} {(this.state.selected.length==1) ? "entry" : "entries"} selected</div>
                                  <div className="assignment-note">Select entries from the list before</div>
                              </div>

                              <div className="data-box-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                  {
                                    (this.state.selected.length > 0)
                                    ?
                                    <Button type="medium" onClick={this.onConvertToBed}>Confirm</Button>
                                    :
                                    null
                                  }
                              </div>
                          </div>
                      </Step>

                      <Step active={this.state.step == "convert-room"}>
                          <div className="assignment-action-container">
                              <div style={{padding:"10px 0 30px 0"}}>
                                  <h3 className="text-emphasis">Convert selected bed to full room?</h3>
                                  <div className="text-note">Note. All beds in this room must either be vacant or assigned to the same client</div>
                              </div>

                              <div className="data-box-buttons">
                                  <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                  {
                                    (this.state.selected.length > 0)
                                    ?
                                    <Button type="medium" onClick={this.onConvertToRoom}>Confirm</Button>
                                    :
                                    null
                                  }
                              </div>
                          </div>
                      </Step>

                      <Step active={this.state.step=="reset"}>
                          <div className="assignment-action-container">
                              <div style={{padding:"40px 0"}}>
                                  <img src="/images/input/confirm.png" className="assignment-reset-image"/>
                                  <h3 className="assignment-reset-text">{this.state.resetText}</h3>
                              </div>
                              <div className="data-box-buttons">
                                  <Button type="medium" onClick={this.onReset}>Back</Button>
                              </div>
                          </div>
                      </Step>
                </div>
            </Databox>
        )
    }
}

class AddAssignment extends React.Component{

    state={
        selected:[],
        dormOptions:[],
        clusterOptions:[],
        buildingOptions:[],
        levelOptions:[],
        roomOptions:[],
        vacancyOptions:[],
        options:[],
        config:{},
        cluster:"all",
        building:"all",
        level:"all",
        room:"all",
        maxPage:1,
        currentPage:1,
        indexMin:0,
        indexMax:5,
        linesPerPage:20,
        loading:false,
        loadText:"None",
    };

    componentDidMount=()=>{
        this.setState({
            config:EstateUtilities.getDormConfig()
        });
        this.getDormOptions();
    }

    getDormOptions=()=>{
        u.post({
           url:"/api/get-estate-options",
           data:{
              type:"dorm",
              id:"all",
           },
           success:(dormOptions)=>{
               if(this.props.agreement){
                   // limit rooms to the dorm listed in the agreement
                   const dormID = this.props.content.dormID;
                   dormOptions = dormOptions.filter(dorm => dorm.value == dormID);
               }
              this.setState({dormOptions:dormOptions});
           },
           error:(error)=>{
              this.setState({error:error});
           }
        });
    }

    onChangeDorm=(value,label,index)=>{
        this.getVacancyOptions(value);
        this.setState({dorm:value});
    }

    getVacancyOptions=(id)=>{
      this.setState({loading:true,loadText:"Loading..."});
      u.post({
         url:"/api/get-vacancy-options",
         data:{
            type:this.props.type,
            dormID:id,
            startDate:this.props.startDate,
            endDate:this.props.endDate,
         },
         success:(options)=>{
            const config = this.state.config;

            //Subdivide based on dormConfig
            let clusterOptions=[{value:'all',label:"All " + config.clusterLabel}];
            let buildingOptions=[{value:'all',label:"All " + config.buildingLabel}];
            let levelOptions=[{value:'all',label:"All " + config.levelLabel}];
            let roomOptions=[{value:'all',label:"All " + config.roomLabel}];
            const nextType = EstateUtilities.getNextType("dorm");

            const vacancyOptions = options.map(
                (item)=>{
                    item.value = item.location[this.props.type+"ID"];
                    item.label = EstateUtilities.getPrefixValue(item,config.roomPrefix) + item.location.roomName + ((this.props.type == "bed") ? "/" + item.location.bedName : "");

                    if(config.cluster && nextType == "cluster"){
                        //Add cluster options
                        if(clusterOptions.filter((option)=>{return option.value == item.location.clusterID}) == 0){
                            clusterOptions.push({
                              value:item.location.clusterID,
                              label:EstateUtilities.getPrefixValue(item,config.clusterPrefix) + item.location.clusterName
                            });
                        }
                    }
                    if(config.building && nextType == "building"){
                        //Add building options
                        if(buildingOptions.filter((option)=>{return option.value == item.location.buildingID}) == 0){
                            buildingOptions.push({
                              value:item.location.buildingID,
                              label:EstateUtilities.getPrefixValue(item,config.buildingPrefix) + item.location.buildingName
                            });
                        }
                    }
                    if(config.level && nextType == "level"){
                        //Add level options
                        if(levelOptions.filter((option)=>{return option.value == item.location.levelID}) == 0){
                            levelOptions.push({
                              value:item.location.levelID,
                              label:EstateUtilities.getPrefixValue(item,config.levelPrefix) + item.location.levelName
                            });
                        }
                    }

                    return item;
                }
            );

            const maxPage= Math.ceil(options.length / this.state.linesPerPage);
            const indexMin = 0;
            const indexMax = this.state.linesPerPage;

            this.setState({
                clusterOptions:clusterOptions,
                buildingOptions:buildingOptions,
                levelOptions:levelOptions,
                roomOptions:roomOptions,
                vacancyOptions:vacancyOptions,
                options:vacancyOptions,
                cluster:"all",
                building:"all",
                level:"all",
                room:"all",
                maxPage: maxPage,
                indexMax:indexMax,
                indexMin:indexMin,
                loading:false,
            });
         },
         error:(error)=>{
            this.setState({error:error,loading:false});
         }
      })
    }

    onChangeEstate=(value,type,index,option,optionChange)=>{

        if(optionChange){
           return 0;
        }

        const childType = EstateUtilities.getNextType(type);

        const config = this.state.config;

        let estateOptions = {
          clusterOptions:[{value:'all',label:"All " + config.clusterLabel}],
          buildingOptions:[{value:'all',label:"All " + config.buildingLabel}],
          levelOptions:[{value:'all',label:"All " + config.levelLabel}],
          roomOptions:[{value:'all',label:"All " + config.roomLabel}],
        };

        let cluster = (type == "cluster") ? value : this.state.cluster;
        let building = (type == "cluster") ? "all" : ((type == "building") ? value : this.state.building);
        let level = ((type == "cluster") || (type == "building")) ? "all" : ((type == "level") ? value : this.state.level);
        let room = ((type == "cluster") || (type == "building") || (type == "level")) ? "all" : ((type == "room") ? value : this.state.room);

        //Calculate options
        let childOptions = estateOptions[childType+"Options"];
        let options = this.state.vacancyOptions.filter(
            (item)=>{
                const inMap = this.checkFilter(item,cluster,building,level,room);
                if(value != "all" && inMap && childType != this.props.type && childType != "none"){
                  if(childOptions.filter((option)=>{return option.value == item.location[childType+"ID"]}) == 0){
                      childOptions.push({
                        value:item.location[childType+"ID"],
                        label:EstateUtilities.getPrefixValue(item,config[childType+"Prefix"]) + item.location[childType+"Name"]});
                  }
                }
                return inMap;
            }
        );

        if(childType == "building"){
            this.setState({buildingOptions:childOptions,levelOptions:estateOptions.levelOptions,roomOptions:estateOptions.roomOptions});
        }
        else if(childType == "level"){
            this.setState({levelOptions:estateOptions.levelOptions,roomOptions:estateOptions.roomOptions});
        }
        else if(childType == "room"){
            this.setState({roomOptions:estateOptions.roomOptions});
        }

        const maxPage= Math.ceil(options.length / this.state.linesPerPage);
        const indexMin = 0;
        const indexMax = this.state.linesPerPage;

        this.setState({
          options:options,
          cluster:cluster,
          building:building,
          level:level,
          room:room,
          currentPage:1,
          maxPage:maxPage,
          indexMax:indexMax,
          indexMin:indexMin,
        });
    }

    onChangePage=(dir)=>{
        if(dir == "left"){
            if(this.state.currentPage == 1){
                return 0;
            }
            else{
                const currentPage = this.state.currentPage - 1;
                const indexMin = (currentPage - 1) * this.state.linesPerPage;
                const indexMax = currentPage * this.state.linesPerPage;
                this.setState({currentPage:currentPage,indexMax:indexMax,indexMin:indexMin});
            }
        }
        else if(dir == "right"){
            if(this.state.currentPage == this.state.maxPage){
                return 0;
            }
            else{
                const currentPage = this.state.currentPage + 1;
                const indexMin = (currentPage - 1) * this.state.linesPerPage;
                const indexMax = currentPage * this.state.linesPerPage;
                this.setState({currentPage:currentPage,indexMax:indexMax,indexMin:indexMin});
            }
        }
    }

    checkFilter = (item,clusterID,buildingID,levelID,roomID) =>{
        const cluster = (clusterID) ? clusterID : this.state.cluster;
        const building=(buildingID) ? buildingID : this.state.building;
        const level = (levelID) ? levelID : this.state.level;
        const room = (roomID) ? roomID : this.state.room;

        return ((cluster == "all" || item.location.clusterID == cluster) &&
                (building == "all" || item.location.buildingID == building) &&
                (level == "all" || item.location.levelID == level) &&
                (room == "all" || item.location.roomID == room));
    }

    onSelected=(value,label,index)=>{
        let options = this.state.options;
        options[index].selected=value;
        this.setState({options:options});
    }

    onSelectAll=()=>{
        let options = this.state.options;
        options = options.map(
            (item)=>{
                item.selected=true;
                return item;
            }
        );
        this.setState({options:options});
    }

    onClearSelection=()=>{
        let vacancyOptions=this.state.vacancyOptions;
        vacancyOptions = vacancyOptions.map(
            (item)=>{
                item.selected=false;
                return item;
            }
        )
        this.setState({vacancyOptions:vacancyOptions});
    }

    onCancel=()=>{
        this.props.onCancel();
    }

    onConfirm=()=>{
        this.setState({loading:true,loadText:"Assigning..."});
        const selected = this.state.vacancyOptions.filter((item)=>{return item.selected});
        if(this.props.agreement){
          u.post({
              url:"/api/agreement-add-estate",
              data:{
                  type:this.props.type,
                  selected:selected,
                  agreementID:this.props.content.agreementID,
              },
              success:({assignedRooms,assignedBeds})=>{
                  this.setState({loading:false});
                  this.props.onChange(
                    assignedRooms,
                    assignedBeds,
                    "Successfully added " + selected.length + ((selected.length == 1) ? " entry" : " entries")
                  );
              },
              error:(error)=>{
                  this.setState({loading:false,error:error});
              }
          })
        }
        else{
          u.post({
              url:"/api/client-add-estate",
              data:{
                  type:this.props.type,
                  selected:selected,
                  clientID:this.props.content.clientID,
              },
              success:({assignedRooms,assignedBeds})=>{
                  this.setState({loading:false});
                  this.props.onChange(
                    assignedRooms,
                    assignedBeds,
                    "Successfully added " + selected.length + ((selected.length == 1) ? " entry" : " entries")
                  );
              },
              error:(error)=>{
                  this.setState({loading:false,error:error});
              }
          })
        }

    }

    render=()=>{
        const config = this.state.config;
        const selected = this.state.vacancyOptions.filter((item)=>{return item.selected});

        return (
            <div className="add-assignment-container" style={{fontSize:"14px"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <h3>Select estates to assign</h3>

                <div className="segment-full-item">
                    <div className="segment-item-label">Dorm:</div>
                    <div className="segment-item-input">
                        <Select label="Dorm" field="dorm" values={{value:this.state.dorm,options:this.state.dormOptions}} onChange={this.onChangeDorm}/>
                    </div>
                </div>

                {
                  (config.cluster)
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Cluster:</div>
                        <div className="segment-item-input">
                            <Select label="Cluster" field="cluster" values={{value:this.state.cluster,options:this.state.clusterOptions}} onChange={this.onChangeEstate}/>
                        </div>
                    </div>
                    :
                    null
                }

                {
                  (config.building)
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Building:</div>
                        <div className="segment-item-input">
                            <Select label="Building" field="building" values={{value:this.state.building,options:this.state.buildingOptions}} onChange={this.onChangeEstate}/>
                        </div>
                    </div>
                    :
                    null
                }

                {
                  (config.level)
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Level:</div>
                        <div className="segment-item-input">
                            <Select label="Level" field="level" values={{value:this.state.level,options:this.state.levelOptions}} onChange={this.onChangeEstate}/>
                        </div>
                    </div>
                    :
                    null
                }

                {
                  (this.props.type == 'bed')
                    ?
                    <div className="segment-full-item">
                        <div className="segment-item-label">Room:</div>
                        <div className="segment-item-input">
                            <Select label="Room" field="room" values={{value:this.state.room,options:this.state.roomOptions}} onChange={this.onChangeEstate}/>
                        </div>
                    </div>
                    :
                    null
                }


                <div className="assignment-add-options">
                {
                    (this.state.options.length > 0)
                      ?
                      <div>
                        <h3>Available {(this.props.type == "room") ? "Rooms" : "Beds"}</h3>
                        {
                            this.state.options.map(
                              (item,index)=>{
                                  if(index<this.state.indexMin || index >= this.state.indexMax){
                                      return null;
                                  }
                                  return (
                                      <div key={index} className="assignment-estate">
                                          <Checkbox values={{value:item.selected}} index={index} onChange={this.onSelected}/>
                                          <div className="assignment-estate-label">
                                              {item.label}
                                          </div>
                                      </div>
                                  )
                              }
                          )
                        }

                        <div className="assignment-select-buttons">
                            <TextButton onClick={this.onSelectAll}>Select All</TextButton>
                            {
                              (selected.length > 0)
                                ?
                                <TextButton onClick={this.onClearSelection}>Clear Selection</TextButton>
                                :
                                null
                            }
                        </div>

                        {
                            (this.state.maxPage > 1)
                              ?
                              <div className="assignment-page-container assignment-select-buttons">
                                  <img className="assignment-arrow" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("left")}}/>
                                  <div className="assignment-page">Page {this.state.currentPage} / {this.state.maxPage}</div>
                                  <img className="assignment-arrow right" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("right")}}/>
                                  <div className="assignment-totals">
                                      Total Entries: {this.state.options.length}
                                  </div>
                              </div>
                              :
                              null
                        }
                      </div>
                      :
                      <div className="add-assignment-placeholder">
                          <h3>No Vacancy</h3>
                          <div>Try a different sector of the dormitory or check for vacancy using the Estate module</div>
                      </div>
                }
                </div>

                <div className="segment-error">{this.state.error}</div>
                <div className="assignment-selected-bg">
                    <div className="assignment-selected">{selected.length} {(selected.length==1) ? "entry" : "entries"} selected</div>
                    <div className="assignment-note">Select entries from the list above</div>
                </div>

                <div className="assignment-buttons">
                    <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                    {
                      (selected.length > 0)
                        ?
                        <Button type="medium" onClick={this.onConfirm}>Assign</Button>
                        :
                        null
                    }
                </div>
            </div>
        )
    }
}
