import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,GenerateSpreadsheet,MobileMenu} from '../components/Common';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';

export default class HistoryList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"History",
        permission:"combined",  //Accepts combined permission module as well
        combinedField:"history",     //Used when permission exists in a combined field
        url:"/history-list",

        /**** Action Setup ****/
        addType:2,
        //addURL:"/api/add-new-client",
        spreadsheetURL:"/api/generate-history-spreadsheet",
        overviewURL:"/api/get-all-history",

        /**** List Setup ****/
        list:true,
        listLabel:"history-list",
        orderByField:"dateTime",
        orderByType:"descending",
        disableChild:true,
        timeModeField:"event",
        timeMode:true,
        timeModeDateField:"dateTime",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        error:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getAllPermissions();
        const combined = u.getPermissions("combined");

        this.setState({
          permission:permissions,
          pageMenuButtons:[
              {label:"Spreadsheet",mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
          ]
        });
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    /************************************* Page Specific **************************************/

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          selected={this.state.selected}
                          settings={this.settings}
                          components={this.pageComponents}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange)=>{
                            return (
                                <div>
                                    <span title={"@staff("+content.staffName+")"}>{(content.staffID) ? <Link to={"/staff/" + content.staffID} id={"staffID-" + content.staffID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.staffName}</Link> : content.staffName}</span>
                                    <span title={"@action("+content.action+")"}> {content.action} </span>
                                    <span title={"@target("+content.targetName+")"}> {(content.targetLink==null) ? content.targetName : <Link to={content.targetLink} id={content.targetLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.targetName}</Link>}</span>
                                    <span title={"@field("+content.targetField+")"}>{(content.targetField==null) ? "" : " (" + content.targetField + ")"}</span>
                                    <span title={"@from("+content.from+")"}>{(content.from == null) ? "" : ((content.fromLink == null) ? " from "+content.from : <span> from <Link to={content.fromLink} id={content.fromLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.from}</Link></span>)}</span>
                                    <span title={"@to("+content.to+")"}>{(content.to == null) ? "" : ((content.toLink == null) ? " to "+content.to : <span> to <Link to={content.toLink} id={content.toLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{content.to}</Link></span>)}</span>
                                </div>
                            )
                        }
                    }
                </OverviewList>
            </AppLayout>
        )
    }
}
