import React from 'react';
import ChartJS from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotation from 'chartjs-plugin-annotation';
ChartJS.register(ChartDataLabels,ChartAnnotation);

import u from '../utilities/Utilities';
import AppLayout from '../components/AppLayout';
import {Step,LoadGraphic,Databox,Segment,PageMenu,Dropzone} from '../components/Common';
import {Button,Box,Date,Select,TextButton,Color,Time} from '../components/Input';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Doughnut,Pie,Line,Bar} from 'react-chartjs-2';
import {SketchPicker} from 'react-color';
import {EstateUtilities} from './Estate';
import store from 'store';
import "../styles/reports.scss";
import {Spreadsheet} from '../data/Mimetypes';
import {LineThickness,PointRadius,ShowPointValues,Fill,LineType} from '../data/ReportOptions';

const defaultColor="#7ecafe";
const presetColors=["#7ecafe","#f76146","#c3de2d","#fdc645","#2bc6d8","#c689c5"];

export default class Reports extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Reports",
        permission:"combined",
        combinedField:"report",
        altPermission:"report",
        url:"/reports",
        label:"reports",
    };

    state={
       error:"",
       dormOptions:[],
       clientOptions:[],
       accessoryOptions:[],
       combinedPermissions:{},
       reportPermissions:{},
       selected:"",
       options:{},
       content:[
           {
             label:"Regulatory Reports",
             permission:"view",
             reports:[
                 {button:"BCA Weekly Report",altField:"regulatory",ref:"bca",icon:"/images/reports/BCA.png",input:[],component:BCAReport},
                 {button:"URA Monthly Survey",altField:"regulatory",ref:"ura",icon:"/images/reports/URA.png",input:[],component:URAReport},
                 {button:"IMDA Usage Report",altField:"regulatory",ref:"moh",icon:"/images/reports/IMDA.png",input:[],component:UsageReport},
             ]
           },
           {
             label:"Operational Reports",
             reports:[
                 {button:"Occupancy & Booking",altField:"operation",ref:"ob",icon:"/images/reports/analytics.png",input:[],component:OccupancyReport},
                 {button:"Room Manifest",altField:"operation",ref:"rm",icon:"/images/reports/analytics.png",input:[],component:RoomManifestReport},
                 {button:"Blocked Bed List",altField:"operation",ref:"bb",icon:"/images/reports/excel.png",input:[],component:BlockedBedList},
                 {button:"Nationality Dist. (Room)",altField:"operation",icon:"/images/reports/excel.png",ref:"ndr",content:{
                       title:"Nationality Distribution (Room)",
                       description:"Generates a spreadsheet report of nationality of present occupants divided by dormitory, block, level and room, excludes Home Leave & Covid Extraction",
                       url:"/api/get-nationality-distribution-room",
                       input:[
                           {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                       ],
                     },component:SpreadsheetReport},
                 {button:"Nationality Dist. (Org)",altField:"operation",icon:"/images/reports/excel.png",ref:"ndc",content:{
                       title:"Room Nationality Report",
                       description:"Generates a spreadsheet report of the nationality of present occupants divided by room level",
                       url:"/api/get-nationality-distribution-client",
                       input:[
                           {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                       ],
                     },component:SpreadsheetReport},
                 /*{button:"Food Collection",altField:"operation",icon:"/images/reports/excel.png",ref:"fc",content:{
                         title:"Food Collection Report",
                         description:"Generate a list for all tenants who collected food between the respective timings",
                         url:"/api/get-food-collection",
                         input:[
                             {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                             {ref:"date",label:"Date",type:"date",range:[1,1]},
                             {ref:"startTime",label:"Start Time",type:"time",rules:{skipSeconds:true}},
                             {ref:"endTime",label:"End Time",type:"time",rules:{skipSeconds:true}},
                         ],
                     },component:SpreadsheetReport},*/
                 {button:"Food Distribution",altField:"operation",icon:"/images/reports/excel.png",ref:"fd",content:{
                         title:"Food Distribution List",
                         description:"Generates a spreadsheet report of all resident food preferences, selected date option will exclude resident with check out date set to before selected date under 'Exclude Check-out' sheets.",
                         url:"/api/get-food-distribution-list",
                         input:[
                             {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                             {ref:"clusterID",label:"Zone",type:"select",options:[]},
                             {ref:"date",label:"Date",type:"date",range:[1,2]},
                         ],
                     },component:SpreadsheetReport},
                 {button:"Vacancy Report",altField:"operation",icon:"/images/reports/excel.png",ref:"vp",content:{
                       title:"Vacancy Report",
                       description:"Generates a spreadsheet report of all vacant rooms & loose beds between respective dates",
                       url:"/api/get-vacant-bed-report",
                       input:[
                           {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                           {ref:"vacancyType",label:"Type",type:"select",options:[]},
                       ],
                   },component:SpreadsheetReport},
                 {button:"Daily Summary",altField:"operation",icon:"/images/reports/excel.png",ref:"ds",content:{
                       title:"Daily Summary",
                       description:"Generates a spreadsheet report of all capacity, occupied beds, arriving beds and departing beds",
                       url:"/api/get-daily-summary",
                       input:[
                           {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                           {ref:"clusterID",label:"Zone",type:"select",options:[]},
                       ],
                   },component:SpreadsheetReport},
             ],
           },
           {
             label:"Medical Reports",
             reports:[
                 {button:"Covid Status List",altField:"medical",ref:"cs",icon:"/images/reports/medical.png",input:[],component:CovidStatusList},
                 {button:"Vitals List",altField:"medical",icon:"/images/reports/medical.png",ref:"vtr",content:{
                       title:"Vitals List",
                       image:"/images/reports/medical.png",
                       description:"Gets list of residents who did not submit their vital entries and residents who fall into unusual vitals category",
                       url:"/api/get-vitals-report",
                       input:[
                           {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                           {ref:"clusterID",label:"Zone",type:"select",options:[]},
                           {ref:"date",label:"Date",type:"date",range:[1,1],rules:{}},
                           {ref:"vitalsType",label:"Reading Type",type:"select",options:[]},
                       ],
                   },component:SpreadsheetReport},
             ],
           },
           {
             label:"Owner Reports",
             reports:[],
           },
       ],
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm",
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });

        u.post({
            url:"/api/get-accessory-options",
            data:{
                dormID:"all",
                ownershipType:"all",
            },
            success:(accessoryOptions)=>{
                this.setState({accessoryOptions:accessoryOptions});
            }
        })

        u.post({
            url:"/api/get-client-options",
            data:{},
            success:(clientOptions)=>{
                this.setState({clientOptions:clientOptions});
            }
        })

        u.post({
            url:"/api/get-options",
            data:{
              keys:["vitalsType","vacancyType"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
               this.setState({error:error});
            }
        });
    }

    getOwnerReports=()=>{
        u.post({
            url:"/api/get-owner-reports",
            data:{},
            success:(ownerReports)=>{
                if(ownerReports){
                    let content = this.state.content;
                    if(ownerReports.regulatory){
                      for(var r=0; r<ownerReports.regulatory.length; r++){
                          const report = ownerReports.regulatory[r];
                          content[0].reports.push({
                              button:report.button,
                              icon:"/images/reports/excel.png",
                              ref:"ownerR"+r,
                              altField:report.altPermission,
                              content:{
                                title:report.title,
                                description:report.description,
                                url:report.url,
                                input:report.input,
                              },
                              component:SpreadsheetReport
                          });
                      }
                    }
                    if(ownerReports.operational){
                      for(var o=0; o<ownerReports.operational.length; o++){
                          const report = ownerReports.operational[o];
                          content[1].reports.push({
                              button:report.button,
                              icon:"/images/reports/excel.png",
                              ref:"ownerO"+o,
                              altField:report.altPermission,
                              content:{
                                title:report.title,
                                description:report.description,
                                url:report.url,
                                input:report.input,
                              },
                              component:SpreadsheetReport
                          });
                      }
                    }
                    if(ownerReports.medical){
                        for(var m=0; m<ownerReports.medical.length; m++){
                            const report = ownerReports.medical[m];
                            content[2].reports.push({
                                button:report.button,
                                icon:"/images/reports/medical.png",
                                ref:"ownerM"+m,
                                altField:report.altPermission,
                                content:{
                                  title:report.title,
                                  description:report.description,
                                  url:report.url,
                                  input:report.input,
                                },
                                component:SpreadsheetReport
                            });
                        }
                    }
                    if(ownerReports.owner){
                        for(var m=0; m<ownerReports.owner.length; m++){
                            const report = ownerReports.owner[m];
                            content[3].reports.push({
                                button:report.button,
                                icon:"/images/reports/excel.png",
                                ref:"ownerO"+m,
                                altField:report.altPermission,
                                content:{
                                  title:report.title,
                                  description:report.description,
                                  url:report.url,
                                  input:report.input,
                                },
                                component:SpreadsheetReport
                            });
                        }
                    }
                    this.setState({content:content});
                }
            },
            error:(error)=>{
                console.log("Owner Reports",error);
            }
        });
    }

    componentDidMount=()=>{
        this.getOwnerReports();
        this.getOptions();

        const agreementPermissions = u.getPermissions("agreement");

        const combinedPermissions = u.getPermissions("combined");

        const reportPermissions = u.getPermissions("report");

        let content=this.state.content;

        if(agreementPermissions){
            content[1].reports.push(
              {
                  button:"Rental Revenue Report",
                  altField:"operation",
                  ref:"rentalRevenue",
                  icon:"/images/reports/analytics.png",
                  input:[],
                  component:RentalRevenueReport
              },
              {
                  button:"Rental Price Report",
                  altField:"operation",
                  ref:"rentalPrice",
                  icon:"/images/reports/analytics.png",
                  input:[],
                  component:RentalPriceReport
              }
            )

            content[1].reports[7].content.input.push(
                {ref:"startDate",label:"Start Date",type:"date"},
                {ref:"endDate",label:"End Date",type:"date"},
            )
        }

        if(combinedPermissions.accessory){
            content[1].reports.push(
              {
                    button:"Accessory Report",
                    altField:"operation",
                    icon:"/images/reports/excel.png",
                    ref:"accessoryReport",
                    content:{
                        title:"Accessory Report",
                        description:"Generates a spreadsheet report of accessories assigned to the respective individuals, can be modified and uploaded to make bulk changes",
                        url:"/api/get-accessory-report",
                        input:[
                            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
                            {ref:"accessoryID",label:"Accessory",type:"select",options:[]},
                            {ref:"status",label:"Status",type:"select",options:[{value:"Active",label:"Active"},{value:"Expired",label:"Expired"}]},
                            {ref:"clientID",label:"Organization",type:"select",options:[]},
                            {ref:"date",label:"Date",type:"date",range:[5,2],rules:{}},
                        ],
                    },
                    component:SpreadsheetReport

                },
            )
        }

        this.setState({content:content,combinedPermissions:combinedPermissions,reportPermissions:reportPermissions});
    }

    onSelected=(ref)=>{
        if(this.state.selected == ref){
            this.setState({selected:""});
        }
        else{
            this.setState({selected:ref});
        }
    }

    render=()=>{
        const content = this.state.content;
        const selected = this.state.selected;
        const combinedPermissions=this.state.combinedPermissions;
        const reportPermissions=this.state.reportPermissions;

        return(
            <AppLayout settings={this.settings}>
                  {
                      content.map(
                          (group,g)=>{
                              if(g==0){
                                  return null;
                              }
                              const numReportsInGroup = group.reports.filter((item)=>{
                                    return (combinedPermissions.report > 0 || reportPermissions[item.altField] > 0);
                              }).length;

                              if(numReportsInGroup == 0){
                                  return null;
                              }

                              return (
                                  <div className="report-group" key={g}>
                                        <div className="report-content-segment">
                                            <h3>{group.label}</h3>
                                            <div className="report-button-segment">
                                                {
                                                    group.reports.map(
                                                        (item,index)=>{
                                                            const hasPermission = (combinedPermissions.report > 0 || reportPermissions[item.altField] > 0);
                                                            if(!hasPermission){
                                                                return null;
                                                            }
                                                            return(
                                                              <div className={"report-button" + ((selected == item.ref) ? " selected" : "")} key={index}  onClick={()=>{this.onSelected(item.ref)}}>
                                                                  <img src={item.icon} className="report-button-image"/>
                                                                  <div className="report-button-label">{item.button}</div>
                                                              </div>
                                                            )
                                                        }
                                                    )
                                                }
                                            </div>
                                            <div>
                                                {
                                                    group.reports.map(
                                                        (item,index)=>{
                                                            return (
                                                                <Segment key={index} active={(selected == item.ref)} align="center">
                                                                    {
                                                                      React.createElement(
                                                                        item.component,
                                                                        {
                                                                          onCancel:()=>{this.onSelected(this.state.selected)},
                                                                          dormOptions:this.state.dormOptions,
                                                                          clientOptions:this.state.clientOptions,
                                                                          accessoryOptions:this.state.accessoryOptions,
                                                                          options:this.state.options,
                                                                          ...item
                                                                        }
                                                                       )
                                                                    }
                                                                </Segment>
                                                            )
                                                        }
                                                    )
                                                }
                                            </div>

                                        </div>
                                  </div>
                              )
                          }
                      )
                  }
            </AppLayout>
        )
    }
}


export class SpreadsheetReport extends React.Component{

    state={
        loadText:"Generating Report...",
        loading:false,
        step:0,
        config:{},
        content:{},
        options:{},
    };

    componentDidMount=()=>{
        this.setState({config:EstateUtilities.getDormConfig()});
    }

    static getDerivedStateFromProps(props,state){

        if(props.content != state.content){
            state.content = props.content;
        }

        if(props.dormOptions != state.dormOptions && props.dormOptions.length != 0){
              state.dormOptions = props.dormOptions;
              state.update=true;
        }

        if(props.accessoryOptions != state.accessoryOptions && props.accessoryOptions.length != 0){
              state.accessoryOptions=props.accessoryOptions;
              state.accessory=true;
        }

        if(props.clientOptions != state.clientOptions && props.clientOptions.length != 0){
              state.clientOptions=props.clientOptions;
              state.client=true;
        }

        if(props.options != state.options){
            state.options=props.options;
            state.pushOptions = true;
        }

        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "dormID"){
                    content.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({content:content,update:false});
        }

        if(this.state.accessory){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "accessoryID"){
                    content.input[i].options = this.state.accessoryOptions;
                    break;
                }
            }
            this.setState({content:content,accessory:false});
        }

        if(this.state.client){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "clientID"){
                    content.input[i].options = this.state.clientOptions;
                    break;
                }
            }
            this.setState({content:content,client:false});
        }

        if(this.state.pushOptions){
            let content = this.state.content;
            let options = this.state.options;
            for(var i=0; i<content.input.length; i++){
                for(var key in options){
                    if(content.input[i].ref == key){
                        content.input[i].options = options[key];
                        break;
                    }
                }
            }
            this.setState({content:content,pushOptions:false});
        }
    }

    onDownload=(data)=>{
        this.setState({loading:true,loadText:"Generating Report...",error:""});
        u.post({
            url:this.props.content.url,
            data:data,
            success:(data)=>{
                if(data.ready == true) {
                    this.setState({loadText: "Downloading Report..."});
                    u.download(data.filename, data.filestamp,
                        function () {
                            this.setState({loading: false, step:2});
                        }.bind(this),
                        function (error) {
                            this.setState({loading: false, error: error});
                        }.bind(this));
                }
                else{
                    this.setState({loading:false,step:1,estimation:data.estimation});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onChangeEntry=(value,field,index,option)=>{
        if(field == "dormID" && value != "all"){
            //Check if clusterID field exists
            let checkCluster = false;
            const input = this.props.content.input;
            for(var i=0; i<input.length; i++){
                if(input[i].ref=="clusterID"){
                    this.getEstateOptions(i,"cluster",value);
                    break;
                }
            }
        }
    }

    getEstateOptions=(inputIndex,nextType,id)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:nextType,
                id:id,
            },
            success:(callback)=>{
                const config = this.state.config;
                callback.unshift({value:"all",label:"All " + config[nextType+"Label"]});

                let content = this.state.content;
                content.input[inputIndex].options = callback;
                this.setState({content:content});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    onValidate=()=>{
        let keys = this.props.content.input;
        let data = {};
        for(var i=0; i<keys.length; i++){
            const item = keys[i].ref;
            const userInput = (this.refs[item]) ? this.refs[item].validate() : {valid:true};
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }

            if(item == "endDate"){
                //Always comes with a startDate
                const startDate=this.refs['startDate'].validate();
                if(moment(userInput.value).isBefore(startDate.value)){
                    this.setState({error:"End Date cannot be before Start Date"});
                    return 0;
                }
            }

            if(item=="endTime"){
                //Always comes with a startTime
                const startTime = this.refs['startTime'].validate();
                const date = moment().format("YYYY-MM-DD");
                if(moment(date+"T"+userInput.value).isBefore(date+"T"+startTime.value)){
                    this.setState({error:"End Time cannot be before Start Time"});
                    return 0;
                }
            }

            data[item]=userInput.value;
        }

        this.onDownload(data);
    }

    onBack=()=>{
        this.setState({step:0});
    }

    render=()=>{
        const content = this.state.content;
        return(
            <div className={"report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src={(content.image) ? content.image : "/images/reports/excel.png"}/>
                    </div>
                    <h3 className="report-title">{content.title}</h3>
                </div>

                <Step active={(this.state.step == 0)}>
                    <div style={{position:"relative"}}>
                        <div>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div className="report-error">{this.state.error}</div>
                            <div className="report-description">{content.description}</div>
                            <div className="report-input">
                                {
                                    content.input.map(
                                        (item,index)=>{
                                            if(!(this.state.config.cluster) && item.ref=="clusterID"){
                                                return null;
                                            }
                                            return (<ReportInputItem key={index} ref={item.ref} content={item} onChange={this.onChangeEntry}/>)
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="report-buttons">
                            <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={this.onValidate} type="medium">Download</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report creation started and will take <span className="text-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <div className="report-note">Note. The report will be sent to your email upon creation</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 2)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report successfully generated</h3>
                            <div className="report-note">Note: If your download does not automatically start, please click <span className="text-emphasis" style={{cursor:"pointer"}} onClick={this.onDownload}>here</span></div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
SpreadsheetReport.propTypes={
    content:PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        input: PropTypes.array.isRequired,
        image: PropTypes.string,
    })
};


export class GraphReport extends React.Component{

    state={
        step:0,
        error:"",
        loading:false,
        loadText:"",
        showSettings:false,
        addData:false,
        settings:[
            {ref:"borderColor",label:"Line Color",type:"color",value:defaultColor},
            {ref:"borderWidth",label:"Line Size",type:"select",options:LineThickness,value:"1"},
            {ref:"lineType",label:"Line Type",type:"select",options:LineType,value:"solid"},
            {ref:"fill",label:"Fill",type:"select",options:Fill,value:false},
            {ref:"pointRadius",label:"Point Size",type:"select",options:PointRadius,value:"2"},
            {ref:"pointBackgroundColor",label:"Point Color",type:"color",value:defaultColor},
            {ref:"showPointValue",label:"Show Point Value",type:"select",options:ShowPointValues,value:false},
        ],
        graphData:{
            datasets:[],
            labels:[],
        },
        annotation:{
          annotations:{}
        },
    };

    onToggleSettings=()=>{
        this.setState({showSettings:!this.state.showSettings});
    }

    onChangeSetting=(value,label,index)=>{
        if(label == "borderColor" || label == "pointBackgroundColor"){
            let color=defaultColor;
            let colorIndex=0;
            for(var i=0; i<this.state.settings.length; i++){
                if(this.state.settings[i].ref == label){
                    color=this.state.settings[i].value;
                    colorIndex=i;
                }
            }
            this.setState({step:2,colorIndex:colorIndex,color:color});
        }
        else{
            let settings = this.state.settings;
            for(var j=0; j<settings.length; j++){
                if(settings[j].ref == label){
                    settings[j].value= value;
                }
            }
            this.setState({settings:settings});
        }
    }

    onChangeColor=(color)=>{
        this.setState({color:color.hex});
    }

    onSelectColor=()=>{
        let settings = this.state.settings;
        settings[this.state.colorIndex].value=this.state.color;
        if(this.state.colorIndex == 0){
            settings[5].value = this.state.color;
        }
        this.setState({settings:settings,step:0});
    }

    goToStep=(step)=>{
        const nextStep = parseInt(step);
        if(nextStep == 0){
            this.setState({
              graphData:{datasets:[], labels:[]},
              addData:false,
              annotation:{
                annotations:{}
              },
            });
            if(this.props.onReset){
                this.props.onReset();
            }
        }
        this.setState({step:nextStep,error:""});
    }

    onValidate=()=>{
        let input = this.props.content.input;
        let label = "";
        let data = {};
        let query = {};
        for(var i=0; i<input.length; i++){
            const item = input[i];
            const userInput = this.refs[item.ref].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }
            data[item.ref]=userInput.value;
            query[item.ref]=userInput;

            if(item.ref == "endDate"){
                //Always comes with a startDate
                const startDate=this.refs['startDate'].validate();
                if(moment(userInput.value).isBefore(startDate.value)){
                    this.setState({error:"End Date cannot be before Start Date"});
                    return 0;
                }
            }
            label = label + ((i>0) ? " | " : "") + ((userInput.option) ? userInput.option.label : ((moment(userInput.value,'YYYY-MM-DD',true).isValid()) ? moment(userInput.value).format("DD-MM-YY") : userInput.value));
        }

        if(data.queryType == "averageBookings" || data.queryType=="averageOccupancy"){
            if(this.state.graphData.datasets.length == 0){
                this.setState({error:"Please add at least 1 normal dataset before adding Mean"});
                return 0;
            }
        }

        let datasets = this.state.graphData.datasets;
        for(var d=0; d<datasets.length; d++){
            if(datasets[d].label == label){
                this.setState({error:label + " has already been added"});
                return 0;
            }
        }

        data.url = this.props.content.url;
        data.label=label;

        this.setState({loading:true,loadText:"Loading..."});
        this.getContent(data,query);
    }

    getContent=(data,query)=>{
        u.post({
            url:data.url,
            data:data,
            success:(callback)=>{
                let graphData = this.state.graphData;
                if(graphData.labels.length == 0){
                    graphData.labels = callback.xAxis;
                }
                const queryType = (data.queryType == "averageOccupancy" || data.queryType == "averageBookings") ? "mean" : "normal";

                if(queryType == "mean"){
                    let annotation=this.state.annotation;
                    const current = this.constructAnnotation(callback,data.label,data.settings);
                    annotation.annotations[u.getKey()]=current.line;
                    annotation.annotations[u.getKey()]=current.label;

                    this.setState({annotation:annotation});
                }
                else{
                  graphData.datasets.push(this.constructGraph(callback,queryType,data.label,data.settings));

                  graphData.datasets.sort(function(a,b){
                      if(a.label < b.label) return -1;
                      if(a.label > b.label) return 1;
                      return 0;
                  });

                  this.setState({graphData:graphData});
                }

                this.setState({step:1,error:"",loading:false,redraw:true,addData:false});

                if(this.props.onData){
                    this.props.onData(query,callback);
                }
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    constructGraph=(callback,queryType,label,autoSettings)=>{
        let settings=(autoSettings) ? autoSettings : this.state.settings;

        let data={
            pointBorderWidth:0,
        };

        for(var i=0; i<settings.length; i++){
            const settingValue = settings[i].value;
            data[settings[i].ref] = (settingValue == "true" || settingValue == "false") ? (settingValue == "true") : settingValue;
        }

        const bg = u.hexToRgb(data.borderColor);

        data.borderWidth = parseInt(data.borderWidth);
        data.backgroundColor = "rgba("+bg.r+","+bg.g+","+bg.b+",0.5)";
        data.pointBorderColor=data.pointBackgroundColor;
        data.pointHitRadius=5;
        data.pointRadius = parseInt(data.pointRadius);
        data.pointHoverRadius=data.pointRadius + 1;
        data.pointHoverBorderWidth=0;
        data.queryType=queryType;
        data.datalabels={
            display:data.showPointValue
        };

        if(data.lineType == "solid" || data.lineType == "dotted"){
            data.lineTension = 0;
        }

        if(data.lineType == "dotted" || data.lineType == "dottedCurve"){
            data.borderDash=[5,5];
        }

        data.label = label;
        data.data = callback.yAxis;

        return data;
    }

    constructAnnotation=(callback,chartLabel,autoSettings)=>{
        let settings=(autoSettings) ? autoSettings : this.state.settings;

        const yMax = Math.max(...callback.yAxis);
        const yMin = Math.min(...callback.yAxis);

        let line={
            type:"line",
            yMin:yMin,
            yMax:yMax,
        };

        for(var i=0; i<settings.length; i++){
            const settingValue = settings[i].value;
            line[settings[i].ref] = (settingValue == "true" || settingValue == "false") ? (settingValue == "true") : settingValue;
        }

        line.borderWidth = parseInt(line.borderWidth);
        line.queryType="mean";

        const label={
          type: 'label',
          xValue: callback.xAxis/2.0,
          yValue: yMax+2,
          backgroundColor: 'rgba(255,255,255,0.4)',
          color:line.borderColor,
          content: ["("+chartLabel+") - "+u.integerComma(yMax)],
          font: {
            size: 12,
            weight:"800",
          }
        };

        return {
            line:line,
            label:label
        };
    }

    onAddDataset=()=>{
        this.setState({step:0,addData:true});
    }

    render=()=>{
        const content = this.props.content;
        const graphData = this.state.graphData;
        const graphMax = (graphData.datasets.length > 0) ? Math.max(...this.state.graphData.datasets.map(
            (dataset)=>{
                return Math.max(...dataset.data);
            }
        )) : 0;

        const options={
            responsive: true,
            showLines:true,
            spanGaps:false,
            plugins:{
              legend:{
                  display:true,
                  labels:{
                      boxWidth:(this.props.smallMode ? 20 : 40),
                      padding:(this.props.smallMode ? 5 : 10),
                  }
              },
              datalabels:{
                  display:false,
                  align:"end",
                  anchor:"end",
                  offset:1,
              },
              annotation:this.state.annotation,
            },
            layout:{
                padding:{
                    right:20,
                }
            },
            scales:{
                x:{
                    ticks: {
                        autoSkip: true,
                        autoSkipPadding: 20,
                    }
                },
                y: {
                    ticks: {
                        callback: function(value, index, values) {
                            if(value >= 1000000){
                                let v = value / 1000000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }
                                return v + 'M';
                            }
                            else if(value >= 10000){
                                let v = value / 1000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }

                                return v + "k";
                            }
                            else{
                                return value;
                            }
                        }
                    },
                    suggestedMax:graphMax+10
                }
            },
        };


        return (
            <div className={"report-container"}>

                <div className="report-header">
                    <div className="report-icon">
                        <img src={(content.image) ? content.image : "/images/reports/analytics.png"}/>
                    </div>
                    <h3 className="report-title">{content.title}</h3>
                </div>

                <Step active={(this.state.step == 0)}>
                    <div style={{position:"relative"}}>
                        <div>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div className="report-error">{this.state.error}</div>
                            <div className="report-description">{content.description}</div>
                            <div className="report-input">
                                {
                                    content.input.map(
                                        (item,index)=>{
                                            const lock = (this.state.addData && item.lock);
                                            return (<ReportInputItem key={index} index={index+""} ref={item.ref} enabled={true} lock={lock} content={item} onChange={this.props.onChangeInput}/>)
                                        }
                                    )
                                }
                                <div>
                                    {
                                        (this.state.showSettings)
                                            ?
                                            <div>
                                                {
                                                    this.state.settings.map(
                                                        (item,index)=>{
                                                            return (
                                                                <ReportInputItem key={index} ref={item.ref} content={item} onChange={this.onChangeSetting}/>
                                                            )
                                                        }
                                                    )
                                                }
                                                <TextButton onClick={this.onToggleSettings}>Hide Advance</TextButton>
                                            </div>
                                            :
                                            (this.props.graphType == "line")
                                                ?
                                                <TextButton onClick={this.onToggleSettings}>Show Advance</TextButton>
                                                :
                                                ""
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="report-buttons">
                            {
                                (this.state.addData)
                                    ?
                                    <Button index="1" onClick={this.goToStep} type="medium">Back</Button>
                                    :
                                    <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            }
                            <Button onClick={this.onValidate} type="medium">{(this.state.addData) ? "Add Dataset" : "Go"}</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div className="report-content">
                        <div className="report-graph">
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div style={{maxWidth:"1500px",margin:"auto"}}>
                                <div className="mobile-hide" style={{position:"absolute",fontWeight:"bold",fontSize:"10px"}}>({content.quantifier})</div>
                                {
                                    (this.props.graphType == "line")
                                        ?
                                        <Line data={this.state.graphData}
                                              options={options}
                                              redraw={true}/>
                                        :
                                        ""
                                }
                                {
                                    (this.props.graphType == "bar")
                                        ?
                                        <Bar data={this.state.graphData}
                                             options={options}
                                             redraw={true}/>
                                        :
                                        ""
                                }
                            </div>
                            {
                                (this.props.additionalText)
                                    ?
                                    <div className="report-addon-container">
                                        {
                                            this.props.additionalText.map(
                                                (item,index)=>{
                                                    return <div className="report-addon-item" key={index}><b>{item}</b></div>
                                                }
                                            )
                                        }
                                    </div>
                                    :
                                    ""
                            }
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.goToStep} index="0" type="medium">Reset</Button>
                            {
                                (this.props.graphType == "line")
                                    ?
                                    <Button onClick={this.onAddDataset} type="medium">Add Dataset</Button>
                                    :
                                    ""
                            }
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step==2)}>
                    <div>
                        <h3>Select Color</h3>
                        <div style={{position:"relative",display:"inline-block",margin:"auto"}}>
                            <SketchPicker color={this.state.color} disableAlpha={true} presetColor={presetColors} width="300px" onChange={this.onChangeColor}/>
                        </div>
                        <div className="report-buttons">
                            <Button type="medium" onClick={this.onSelectColor}>Choose</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}
GraphReport.propTypes={
    content:PropTypes.shape({
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        input: PropTypes.array.isRequired,
        image: PropTypes.string,
    }),
    onChangeInput:PropTypes.func,
    onData:PropTypes.func,
    additionalText:PropTypes.array,
    autoQuery:PropTypes.object,
    smallMode:PropTypes.bool,
    graphType:PropTypes.string,
};
GraphReport.defaultProps={
    graphType:"line",
};


export class ReportInputItem extends React.Component{

    validate=()=>{
        return this.refs['input'].validate();
    }

    render=()=>{
        const content=this.props.content;
        const enabled = (typeof this.props.enabled == "undefined") ? true : this.props.enabled;
        let rules = content.rules;
        if(this.props.lock){
            rules.lockDate=true;
            rules.lockMonth=true;
        }

        return (
            <div className="report-input-item">
                <div className="report-input-label">{content.label}:</div>
                <div className="report-input-value">
                    {
                        (content.type=="box")
                            ?
                            <Box ref="input" label={content.label} field={content.ref} index={this.props.index} values={{enabled:!this.props.lock,value:content.value}} rules={content.rules} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (content.type=="select")
                            ?
                            <Select ref="input" label={content.label} field={content.ref} index={this.props.index} values={{enabled:!this.props.lock,value:content.value,options:content.options}} rules={content.rules} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (content.type=="date")
                            ?
                            <Date ref="input" label={content.label} field={content.ref} index={this.props.index} values={{enabled:enabled,value:content.value}} range={content.range} rules={rules} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (content.type=="time")
                            ?
                            <Time ref="input" label={content.label} field={content.ref} index={this.props.index} values={{enabled:enabled,value:content.value}} rules={rules} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                    {
                        (content.type=="color")
                            ?
                            <Color ref="input" label={content.ref} color={content.value} index={this.props.index} onChange={this.props.onChange}/>
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
}
ReportInputItem.propTypes={
    content:PropTypes.object.isRequired,
    onChange:PropTypes.func
};


export class PieChart extends React.Component{

    state={
        error:"",
        pieData:{
            datasets:[
                {
                    data: this.props.content.data,
                    backgroundColor: "",
                    borderColor: "#fff",
                    borderWidth: 2,
                    hoverBackgroundColor: "",
                    hoverBorderColor: "#fff",
                    hoverBorderWidth: 2,
                }
            ],
            labels:this.props.content.labels,
        },
    };

    componentDidMount=()=>{
        const backgroundColor = (this.props.content.backgroundColor) ? this.props.content.backgroundColor : presetColors;
        let backgroundHover = backgroundColor.map((color)=>{
            const a = u.hexToRgb(color);
            return "rgba(" + a.r + "," + a.g + "," + a.b + ",0.5)";
        });

        const pieData = this.state.pieData;
        pieData.datasets[0].backgroundColor=backgroundColor;
        pieData.datasets[0].hoverBackgroundColor=backgroundHover;

        this.setState({
            pieData:pieData,
        });
    }

    static getDerivedStateFromProps(props,state){
        if(props.content != state.content){
            state.content = props.content;
            state.update = true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let pieData = this.state.pieData;

            const backgroundColor = (this.props.content.backgroundColor) ? this.props.content.backgroundColor : presetColors;
            let backgroundHover = backgroundColor.map((color)=>{
                const a = u.hexToRgb(color);
                return "rgba(" + a.r + "," + a.g + "," + a.b + ",0.5)";
            });

            pieData.datasets[0].data = this.props.content.data;
            pieData.datasets[0].backgroundColor=backgroundColor;
            pieData.datasets[0].hoverBackgroundColor=backgroundHover;
            pieData.labels=this.props.content.labels;

            this.setState({update:false,pieData:pieData});
        }
    }

    render=()=>{
        const content = this.props.content;
        const tooltips = (typeof content.tooltips != "undefined") ? content.tooltips : true;
        const options = {
            animation: {
                animateRotate: (typeof this.props.animateRotate != "undefined") ? this.props.animateRotate : true,
                animateScale: false
            },
            plugins:{
              legend:(this.props.legend) ? this.props.legend :{
                  display: content.showLegend
              },
              datalabels:(this.props.datalabels) ? this.props.datalabels : {
                  display: false
              },
              tooltip:{
                  enabled: tooltips,
                  callbacks:{
                      label:(context)=>{
                          if(this.props.isEmpty){
                              return "Empty";
                          }

                          const label = context.label;
                          const value = context.dataset.data[context.dataIndex];
                          if(this.props.toolTipOverride){
                              return this.props.toolTipOverride(label,value);
                          }
                          return label + ": " + value + ((this.props.tooltipSuffix) ? this.props.tooltipSuffix : "");
                      }
                  }
              },
            }
        };
        let pieData=this.state.pieData;
        pieData.datasets[0].borderWidth = (this.props.noBorder) ? 0 : 2;
        pieData.datasets[0].hoverBorderWidth = (this.props.noBorder) ? 1 : 2;

        return (
            <div className={"pie-chart" + ((this.props.className) ?  " " + this.props.className : "") } onClick={this.onClick}>
                <Pie options={{
                      ...((this.props.options) ? this.props.options : options),
                      cutout:(content.doughnut) ? "50%" : "0"
                     }}
                     data={pieData}
                     redraw={this.props.redraw}
                     />
            </div>
        )
    }
}
PieChart.propTypes= {
    content: PropTypes.shape({
        showLegend: PropTypes.bool,
        backgroundColor: PropTypes.array,
        data: PropTypes.array,
        labels: PropTypes.array,
        tooltips: PropTypes.bool,
    }).isRequired,
    isEmpty:PropTypes.bool,
};


/******************************* Special Reports **************************************/
class BCAReport extends React.Component{

    state={
        step:0,
        error:"",
        loading:false,
        loadText:"",
        dormOptions:[],
        content:{
            constructionWorkers:{},
            otherWorkers:{}
        },
        input:[],
    };

    componentDidMount=()=>{
        let dayOfWeek = moment().day();
        if(dayOfWeek == 0){
            dayOfWeek = 7;
        }
        const latestQueryDate = moment().subtract(dayOfWeek,"days").add(1,"days").format("YYYY-MM-DD");

        this.setState({
            input:[
                {ref:"dormID",label:"Dormitory",type:"select",options:this.props.dormOptions},
                {ref:"startDate",label:"Week of",type:"date",range:[5,1],rules:{maxDate:latestQueryDate,defaultValue:latestQueryDate}},
            ]
        })
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let input = this.state.input;
            for(var i=0; i<input.length; i++){
                if(input[i].ref == "dormID"){
                    input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({input:input,update:false});
        }
    }

    goToStep=(step)=>{
        this.setState({step:parseInt(step),error:""});
    }

    onSubmit=()=>{
        let input = this.state.input;
        let data = {};
        for(var i=0; i<input.length; i++){
            const item = input[i];
            const userInput = this.refs[item.ref].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }
            data[item.ref]=userInput.value;
        }

        let day = moment(data.startDate).day();
        if(day == 0){
            day = 7;
        }
        data["startDate"]=moment(data.startDate).subtract(day,"days").add(1,"days").format("YYYY-MM-DD");

        this.setState({loading:true,loadText:"Preparing report..."});
        u.post({
            url:"/api/get-bca-report",
            data:data,
            success:(callback)=>{
                this.setState({content:callback,error:"",step:1,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const content=this.state.content;

        return (
            <div className={"bca-report report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src="/images/reports/BCA.png"/>
                    </div>
                    <h3 className="report-title">Building & Construction Authority Weekly</h3>
                </div>

                <Step active={(this.state.step==0)}>
                    <div>
                        <div className="report-error">{this.state.error}</div>
                        <div className="report-description">Calculates the details for Section A & B of BCA's weekly occupancy summary report</div>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <div style={{position:"relative"}}>
                            <div className="report-input">
                                {
                                    this.state.input.map(
                                        (item,index)=>{
                                            return (<ReportInputItem key={index} ref={item.ref} content={item}/>)
                                        }
                                    )
                                }
                            </div>
                            <div className="report-buttons">
                                <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                                <Button onClick={this.onSubmit} type="medium">Go</Button>
                            </div>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step==1)}>
                    <div className="bca-top-content report-content">
                        <h3>Details</h3>
                        <div className="bca-top-name">
                            Name of Dormitory: <div>{content.dormName}</div>
                        </div>
                        <div className="bca-top-period">
                            Week Commencing <br className="mobile-only"/>From: <div>{moment(content.periodStart).format("DD-MMM-YY")}</div> To: <div>{moment(content.periodEnd).format("DD-MMM-YY")}</div>
                        </div>

                        <h3>Section A</h3>
                        <div className="bca-section-a">
                            <div className="bca-data-segment">
                                <div className="bca-data-segment-title">Construction Workers</div>
                                <table className="bca-data-segment-table">
                                    <tbody>
                                    <tr className="mobile-hide">
                                        <td className="text-demphasis">Physical Occupancy</td>
                                        <td className="text-demphasis">Bed Spaces Booked / Reserved</td>
                                        <td className="text-demphasis">Bed Spaces Available for Booking</td>
                                        <td className="text-demphasis">Bed Spaces Unavailable for Booking</td>
                                        <td className="text-demphasis">Total Allocated Capacity</td>
                                    </tr>
                                    <tr>
                                        <td className="text-demphasis">(A)</td>
                                        <td className="text-demphasis">(B)</td>
                                        <td className="text-demphasis">(C)</td>
                                        <td className="text-demphasis">(A) + (B)</td>
                                        <td className="text-demphasis">(A) + (B) + (C)</td>
                                    </tr>
                                    <tr>
                                        <td>{content.constructionWorkers.physicalOccupancy}</td>
                                        <td>{content.constructionWorkers.bedSpaceReserved}</td>
                                        <td>{content.constructionWorkers.bedSpaceAvailable}</td>
                                        <td>{content.constructionWorkers.bedSpaceUnavailable}</td>
                                        <td>{content.constructionWorkers.allocatedCapacity}</td>
                                    </tr>
                                    <tr>
                                        <td>{(parseFloat(content.constructionWorkers.physicalOccupancy) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.constructionWorkers.bedSpaceReserved) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.constructionWorkers.bedSpaceAvailable) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.constructionWorkers.bedSpaceUnavailable) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.constructionWorkers.allocatedCapacity) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="bca-data-segment">
                                <div className="bca-data-segment-title">Non-Construction Workers</div>
                                <table className="bca-data-segment-table">
                                    <tbody>
                                    <tr className="mobile-hide">
                                        <td className="text-demphasis">Physical Occupancy</td>
                                        <td className="text-demphasis">Bed Spaces Booked / Reserved</td>
                                        <td className="text-demphasis">Bed Spaces Available for Booking</td>
                                        <td className="text-demphasis">Bed Spaces Unavailable for Booking</td>
                                        <td className="text-demphasis">Total Allocated Capacity</td>
                                    </tr>
                                    <tr>
                                        <td className="text-demphasis">(A)</td>
                                        <td className="text-demphasis">(B)</td>
                                        <td className="text-demphasis">(C)</td>
                                        <td className="text-demphasis">(A) + (B)</td>
                                        <td className="text-demphasis">(A) + (B) + (C)</td>
                                    </tr>
                                    <tr>
                                        <td>{content.otherWorkers.physicalOccupancy}</td>
                                        <td>{content.otherWorkers.bedSpaceReserved}</td>
                                        <td>{content.otherWorkers.bedSpaceAvailable}</td>
                                        <td>{content.otherWorkers.bedSpaceUnavailable}</td>
                                        <td>{content.otherWorkers.allocatedCapacity}</td>
                                    </tr>
                                    <tr>
                                        <td>{(parseFloat(content.otherWorkers.physicalOccupancy) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.otherWorkers.bedSpaceReserved) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.otherWorkers.bedSpaceAvailable) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.otherWorkers.bedSpaceUnavailable) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{(parseFloat(content.otherWorkers.allocatedCapacity) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div className="bca-data-segment">
                                <table className="bca-data-segment-table">
                                    <tbody>
                                    <tr className="mobile-hide">
                                        <td className="text-demphasis">Total Physical Occupancy</td>
                                        <td className="text-demphasis">Total Bed Spaces Booked / Reserved</td>
                                        <td className="text-demphasis">Total Bed Spaces Available for Booking</td>
                                        <td className="text-demphasis">Total Bed Spaces Unavailable for Booking</td>
                                        <td className="text-demphasis">Total Dorm Capacity</td>
                                    </tr>
                                    <tr>
                                        <td>{parseInt(content.otherWorkers.physicalOccupancy) + parseInt(content.constructionWorkers.physicalOccupancy)}</td>
                                        <td>{parseInt(content.otherWorkers.bedSpaceReserved) + parseInt(content.constructionWorkers.bedSpaceReserved)}</td>
                                        <td>{parseInt(content.otherWorkers.bedSpaceAvailable) + parseInt(content.constructionWorkers.bedSpaceAvailable)}</td>
                                        <td>{parseInt(content.otherWorkers.bedSpaceUnavailable) + parseInt(content.constructionWorkers.bedSpaceUnavailable)}</td>
                                        <td>{parseInt(content.otherWorkers.allocatedCapacity) + parseInt(content.constructionWorkers.allocatedCapacity)}</td>
                                    </tr>
                                    <tr>
                                        <td>{((parseFloat(content.otherWorkers.physicalOccupancy) + parseFloat(content.constructionWorkers.physicalOccupancy)) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{((parseFloat(content.otherWorkers.bedSpaceReserved) + parseFloat(content.constructionWorkers.bedSpaceReserved)) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{((parseFloat(content.otherWorkers.bedSpaceAvailable) + parseFloat(content.constructionWorkers.bedSpaceAvailable)) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>{((parseFloat(content.otherWorkers.bedSpaceUnavailable) + parseFloat(content.constructionWorkers.bedSpaceUnavailable)) * 100.0 / parseFloat(content.totalDormCapacity)).toFixed(2)}%</td>
                                        <td>-</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <h3>Section B</h3>
                        <div className="bca-section-b">
                            <div className="bca-data-segment">
                                <table className="bca-data-segment-table">
                                    <tbody>
                                    <tr>
                                        <td/>
                                        <td className="text-demphasis"><span className="mobile-hide">Construction Workers</span><span className="mobile-show">Con</span></td>
                                        <td className="text-demphasis"><span className="mobile-hide">Non-Construction Workers</span><span className="mobile-show">Non-Con</span></td>
                                        <td className="text-demphasis">Total</td>
                                    </tr>
                                    <tr>
                                        <td className="text-demphasis">Bed Spaces Returned</td>
                                        <td>{content.constructionWorkers.bedSpaceReturned}</td>
                                        <td>{content.otherWorkers.bedSpaceReturned}</td>
                                        <td>{parseInt(content.otherWorkers.bedSpaceReturned) + parseInt(content.constructionWorkers.bedSpaceReturned)}</td>
                                    </tr>
                                    <tr>
                                        <td className="text-demphasis">Bed Spaces Tenanted Out</td>
                                        <td>{content.constructionWorkers.bedSpaceTenanted}</td>
                                        <td>{content.otherWorkers.bedSpaceTenanted}</td>
                                        <td>{parseInt(content.otherWorkers.bedSpaceTenanted) + parseInt(content.constructionWorkers.bedSpaceTenanted)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="report-buttons">
                            <Button type="medium" index="0" onClick={this.goToStep}>Close</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class URAReport extends React.Component{

    state={
        step:0,
        error:"",
        loading:false,
        loadText:"",
        dormOptions:[],
        content:{
            constructionWorkers:{},
            otherWorkers:{}
        },
        input:[],
    };

    componentDidMount=()=>{
        const latestQueryDate = moment().subtract(1,"months").date(1).format("YYYY-MM-DD");
        this.setState({
            input:[
                {ref:"dormID",label:"Dormitory",type:"select",options:this.props.dormOptions},
                {ref:"startDate",label:"Month of",type:"date",range:[5,1],rules:{skipDateTo:"01",maxDate:latestQueryDate,defaultValue:latestQueryDate}},
            ]
        });
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let input = this.state.input;
            for(var i=0; i<input.length; i++){
                if(input[i].ref == "dormID"){
                    input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({input:input,update:false});
        }
    }

    goToStep=(step)=>{
        this.setState({step:parseInt(step),error:""});
    }

    onSubmit=()=>{
        let input = this.state.input;
        let data = {};
        for(var i=0; i<input.length; i++){
            const item = input[i];
            const userInput = this.refs[item.ref].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }
            data[item.ref]=userInput.value;
        }
        this.setState({loading:true,loadText:"Preparing report..."});
        u.post({
            url:"/api/get-ura-report",
            data:data,
            success:(callback)=>{
                this.setState({content:callback,error:"",step:1,loading:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const content=this.state.content;
        return (
            <div className={"ura-report report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src="/images/reports/URA.png"/>
                    </div>
                    <h3 className="report-title">Urban Redevelopment Authority Monthly</h3>
                </div>

                <Step active={(this.state.step==0)}>
                    <div>
                        <div className="report-error">{this.state.error}</div>
                        <div className="report-description">Calculates the details for Part 1.1 of URA's monthly occupancy & rental survey</div>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <div style={{position:"relative"}}>
                            <div className="report-input">
                                {
                                    this.state.input.map(
                                        (item,index)=>{
                                            return (<ReportInputItem key={index} ref={item.ref} content={item}/>)
                                        }
                                    )
                                }
                            </div>
                            <div className="report-buttons">
                                <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                                <Button onClick={this.onSubmit} type="medium">Go</Button>
                            </div>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step==1)}>
                    <div className="ura-content-div report-content">
                        <h3>Part 1.1 Available and Occupied Beds as at end of  <span className="text-emphasis">{moment(content.period).format("MMM YYYY")}</span></h3>
                        <div className="ura-data-segment">
                            <table className="ura-data-segment-table">
                                <tbody>
                                <tr>
                                    <td>Total number of available beds</td>
                                    <td>{content.availableBeds}</td>
                                </tr>
                                <tr>
                                    <td>Total number of occupied beds</td>
                                    <td>{content.occupiedBeds}</td>
                                </tr>
                                <tr>
                                    <td>
                                        Total number of vacant beds
                                    </td>
                                    <td>
                                        {parseInt(content.vacantBedsWithContract) + parseInt(content.vacantBedsWithoutContract)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        a) Number of vacant beds with contracts signed
                                    </td>
                                    <td>{content.vacantBedsWithContract}</td>
                                </tr>

                                <tr>
                                    <td>b) Number of vacant beds without contracts signed</td>
                                    <td>{content.vacantBedsWithoutContract}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="report-buttons">
                            <Button type="medium" index="0" onClick={this.goToStep}>Close</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class MOHRoomList extends React.Component{

    state={
        loadText:"Generating Report...",
        loading:false,
        step:0,
        data:{
            title:"MOH Room List",
            description:"Generates a spreadsheet report of all tenants in MOH format including bed assignment",
            url:"/api/get-moh-room-list",
            image:"/images/reports/MOH.png",
            input:[],
        },
    };

    componentDidMount=()=>{
        let data = this.state.data;
        data.input=[{ref:"dormID",label:"Dormitory",type:"select",options:[]}];
        this.setState({data:data});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let data = this.state.data;
            for(var i=0; i<data.input.length; i++){
                if(data.input[i].ref == "dormID"){
                    data.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({data:data,update:false});
        }
    }

    onValidate=()=>{
        let keys = this.state.data.input;
        let data = {};
        for(var i=0; i<keys.length; i++){
            const item = keys[i].ref;
            const userInput = this.refs[item].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }

            if(item == "endDate"){
                //Always comes with a startDate
                const startDate=this.refs['startDate'].validate();
                if(moment(userInput.value).isBefore(startDate.value)){
                    this.setState({error:"End Date cannot be before Start Date"});
                    return 0;
                }
            }

            data[item]=userInput.value;
        }
        this.setState({validatedData:data,step:3});
    }

    onBack=()=>{
        this.setState({step:0});
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading " + filename + "...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Uploading report..."});
        u.post({
            url:this.state.data.url,
            data:{
                dormID:this.state.validatedData.dormID,
                filename:filename
            },
            success:(data)=>{
                if(data.ready == true) {
                    let validatedData = this.state.validatedData;
                    validatedData.filename = data.filename;
                    validatedData.filestamp = data.filestamp;
                    this.setState({validatedData:validatedData});
                    this.onDownload(data.filename,data.filestamp);
                }
                else{
                    this.setState({loading:false,step:1,estimation:data.estimation});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onDownload=(filename,filestamp)=>{
        this.setState({loadText: "Downloading Report..."});
        u.download(filename, filestamp,
            ()=>{
                this.setState({loading: false, step:2});
            },
            (error)=>{
                this.setState({loading: false, error: error});
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:this.state.loadText + progress + "%"});
    }

    render=()=>{
        const content = this.state.data;
        return(
            <div className={"report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src={(content.image) ? content.image : "/images/reports/excel.png"}/>
                    </div>
                    <h3 className="report-title">{content.title}</h3>
                </div>

                <Step active={(this.state.step == 0)}>
                    <div style={{position:"relative"}}>
                        <div>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div className="report-error">{this.state.error}</div>
                            <div className="report-description">{content.description}</div>
                            <div className="report-input">
                                {
                                    content.input.map(
                                        (item,index)=>{
                                            return (<ReportInputItem key={index} ref={item.ref} content={item}/>)
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="report-buttons">
                            <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={this.onValidate} type="medium">Next</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report creation started and will take <span className="text-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <div className="report-note">Note. The report will be sent to your email upon creation</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 2)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report successfully generated</h3>
                            <div className="report-note">Note: If your download does not automatically start, please click <span className="text-emphasis" style={{cursor:"pointer"}} onClick={()=>{this.onDownload(this.state.validatedData.filename,this.state.validatedData.filestamp)}}>here</span></div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 3)}>
                    <div style={{position:"relative"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <h4>Upload MOH Spreadsheet</h4>
                        <Dropzone accessibleForPreview={false} onAdded={this.onFileAdded} onUploaded={this.onFileUploaded} onError={this.onFileError} onProgress={this.onFileProgress}
                                    filetypes={Spreadsheet} label={"CIF Room List"} dragAndDropLabel={"Accepts MOH patient spreadsheet"}
                        />
                        <div className="report-buttons">
                            <Button onClick={()=>{this.setState({step:0})}} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class RoomManifestReport extends React.Component{

    state={
        loadText:"Generating Report...",
        loading:false,
        step:0,
        options:{
          clusterOptions:[],
          buildingOptions:[],
          levelOptions:[],
        },
        dormOptions:[],
        config:{},
    };

    componentDidMount=()=>{
        const config = EstateUtilities.getDormConfig();
        this.setState({
            options:{
                clusterOptions:[{value:"all",label:"All " + config.clusterLabel}],
                buildingOptions:[{value:"all",label:"All " + config.buildingLabel}],
                levelOptions:[{value:"all",label:"All " + config.levelLabel}],
            },
            config:config,
        });
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
        }
        return state;
    }

    getEstateOptions=(type,id)=>{
        const nextType = EstateUtilities.getNextType(type);
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:nextType,
                id:id,
            },
            success:(callback)=>{
                callback.unshift({value:"all",label:'All ' + this.state.config[nextType+"Label"]});
                let options = this.state.options;
                options[nextType+"Options"] = callback;
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    onChangeEstate=(value,type,index,option)=>{
        if(value != "all" && value != "none"){
            this.getEstateOptions(type,value);
        }

        let options = this.state.options;
        const config = this.state.config;
        if(type == "dorm"){
           options.buildingOptions = [{value:"all",label:'All ' + config.buildingLabel}];
           options.levelOptions = [{value:"all",label:'All ' + config.levelLabel}];
        }
        if(type == "cluster"){
            options.levelOptions = [{value:"all",label:'All ' + config.levelLabel}];
        }
    }

    onGo=()=>{
        const keys=["dorm","cluster","building","level"];
        let location = {};
        for(var i=0; i<keys.length; i++){
            const current = (this.refs[keys[i]]) ? this.refs[keys[i]].validate() : {valid:true}
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            location[keys[i]+"ID"] = current.value;
        }
        store.set("room-manifest",location);
        window.open("/room-manifest","__blank");
    };

    render=()=>{
        const content = this.props.content;
        const options = this.state.options;
        const config = this.state.config;
        return(
            <div className={"report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src="/images/reports/analytics.png"/>
                    </div>
                    <h3 className="report-title">Room Manifest</h3>
                </div>

                <div style={{position:"relative"}}>
                    <div>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <div className="report-error">{this.state.error}</div>
                        <div className="report-description">Gets room manifest for all rooms in selection into printable format</div>
                        <div className="report-input">
                            <div className="report-input-item">
                                <div className="report-input-label">Dormitory:</div>
                                <div className="report-input-value">
                                    <Select ref="dorm" field="dorm" label="Dormitory" values={{options:this.state.dormOptions}} onChange={this.onChangeEstate}/>
                                </div>
                            </div>
                            {
                              (config.cluster)
                                ?
                                <div className="report-input-item">
                                    <div className="report-input-label">{config.clusterSingle}:</div>
                                    <div className="report-input-value">
                                        <Select ref="cluster" field="cluster" label={config.clusterSingle} values={{options:options.clusterOptions}} onChange={this.onChangeEstate}/>
                                    </div>
                                </div>
                                :
                                null
                            }
                            {
                              (config.building)
                                ?
                                <div className="report-input-item">
                                    <div className="report-input-label">{config.buildingSingle}:</div>
                                    <div className="report-input-value">
                                        <Select ref="building" field="building" label={config.buildingSingle} values={{options:options.buildingOptions}} onChange={this.onChangeEstate}/>
                                    </div>
                                </div>
                                :
                                null
                            }

                            <div className="report-input-item">
                                <div className="report-input-label">{config.levelSingle}:</div>
                                <div className="report-input-value">
                                    <Select ref="level" field="level" label={config.levelSingle} values={{options:options.levelOptions}} onChange={this.onChangeEstate}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report-buttons">
                        <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                        <Button onClick={this.onGo} type="medium">Go</Button>
                    </div>
                </div>

            </div>
        )
    }
}

class OccupancyReport extends React.Component{

    state={
        content:{
            title:"Occupancy & Bookings Report",
            description:"Graphical summary of occupancy or bookings between the respective dates",
            url:"/api/get-occupancy-report",
            input:[],
            quantifier:"Pax",
        }
    };

    componentDidMount=()=>{
        const config = EstateUtilities.getDormConfig();
        let content = this.state.content;
        const agreementPermissions=u.getPermissions("agreement");
        const typeOptions = (agreementPermissions.view) ? [
            {value:"Occupancy",label:"Occupancy"},
            {value:"Bookings",label:"Bookings"},
            {value:"Vacancy",label:"Vacancy"},
            {value:"averageOccupancy", label:"Occupancy (Mean)"},
            {value:"averageBookings", label:"Bookings (Mean)"}
        ] : [
            {value:"Occupancy",label:"Occupancy"},
            {value:"Vacancy",label:"Vacancy"},
            {value:"averageOccupancy", label:"Occupancy (Mean)"},
        ];

        content.input=[
            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
            {ref:"queryType",label:"Type",type:"select",options:typeOptions},
            {ref:"startDate",label:"Start",type:"date",range:[5,2],lock:true,rules:{}},
            {ref:"endDate",label:"End",type:"date",range:[5,2],lock:true,rules:{}},
        ];

        if(config.cluster){
            content.input.splice(1,0,{ref:"clusterID",label:config.clusterSingle,type:"select",options:[{value:"all",label:"All " + config["clusterLabel"]}]});
        }

        this.setState({content:content,config:config});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "dormID"){
                    content.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({content:content,update:false});
            if(this.state.config.cluster){
                this.getEstateOptions("dorm",this.state.dormOptions[0].value);
            }
        }
    }

    onChangeInput=(value,label,index,option)=>{
        let content = this.state.content;
        content.input[index].value=value;
        this.setState({content:content});
        if(this.state.config.cluster && label == "dormID"){
            this.getEstateOptions("dorm",value);
        }
    }

    getEstateOptions=(type,id)=>{
        const nextType = EstateUtilities.getNextType(type);
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:nextType,
                id:id
            },
            success:(callback)=>{
                callback.unshift({value:"all",label:"All " + this.state.config[nextType + "Label"]});
                let content = this.state.content
                for(var i=0; i<content.input.length; i++){
                    if(content.input[i].ref==nextType + "ID"){
                        content.input[i].options = callback;
                        this.setState({content:content});
                        break;
                    }
                }
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    render=()=>{
        return (
          <GraphReport content={this.state.content}
                       smallMode={this.props.smallMode}
                       onChangeInput={this.onChangeInput}
                       autoQuery={this.props.autoQuery}
                       onCancel={this.props.onCancel}/>)
    }
}

class BlockedBedList extends React.Component{

    state={
        loadText:"Generating Report...",
        loading:false,
        step:0,
        data:{
            title:"Blocked Bed List",
            description:"Generates a spreadsheet of beds which are vacant but not cleaned, spreadsheet can be re-uploaded to set beds as cleaned",
            downloadURL:"/api/get-blocked-bed-list",
            uploadURL:"/api/set-blocked-bed-list",
            input:[],
        },
        validatedData:{},
        config:{},
    };

    componentDidMount=()=>{
        const config = EstateUtilities.getDormConfig();
        let data = this.state.data;
        data.input= [
            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
        ]
        if(config.cluster){
            data.input.push(
                {ref:"clusterID",label:config.clusterSingle,type:"select",options:[]},
            )
        }
        this.setState({data:data,config:config});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let data = this.state.data;
            for(var i=0; i<data.input.length; i++){
                if(data.input[i].ref == "dormID"){
                    data.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({data:data,update:false});
            if(this.state.config.cluster){
                this.getEstateOptions("dorm",this.state.dormOptions[0].value);
            }
        }
    }

    onValidate=()=>{
        let keys = this.state.data.input;
        let data = {};
        for(var i=0; i<keys.length; i++){
            const item = keys[i].ref;
            const userInput = (this.refs[item]) ? this.refs[item].validate() : {valid:true};
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }

            if(item == "endDate"){
                //Always comes with a startDate
                const startDate=this.refs['startDate'].validate();
                if(moment(userInput.value).isBefore(startDate.value)){
                    this.setState({error:"End Date cannot be before Start Date"});
                    return 0;
                }
            }

            data[item]=userInput.value;
        }

        this.setState({loadText:"Uploading report..."});
        u.post({
            url:this.state.data.downloadURL,
            data:data,
            success:(data)=>{
                if(data.ready == true) {
                    let validatedData = this.state.validatedData;
                    validatedData.filename = data.filename;
                    validatedData.filestamp = data.filestamp;
                    this.setState({validatedData:validatedData});
                    this.onDownload(data.filename,data.filestamp);
                }
                else{
                    this.setState({loading:false,step:1,estimation:data.estimation});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onBack=()=>{
        this.setState({step:0});
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading " + filename + "...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Uploading report..."});
        u.post({
            url:this.state.data.uploadURL,
            data:{
                dormID:this.state.validatedData.dormID,
                filename:filename
            },
            success:(beds)=>{
                this.setState({loading:false,beds:beds,step:4});
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onDownload=(filename,filestamp)=>{
        this.setState({loadText: "Downloading Report..."});
        u.download(filename, filestamp,
            ()=>{
                this.setState({loading: false, step:2});
            },
            (error)=>{
                this.setState({loading: false, error: error});
        });
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:this.state.loadText + progress + "%"});
    }

    onChangeEntry=(value,field,index,option)=>{
        if(field == "dormID" && value != "all"){
            this.getEstateOptions("dorm",value);
        }
    }

    getEstateOptions=(type,id)=>{
        const nextType = EstateUtilities.getNextType(type);
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:nextType,
                id:id
            },
            success:(callback)=>{
                callback.unshift({value:"all",label:"All " + this.state.config[nextType + "Label"]});
                let data = this.state.data
                for(var i=0; i<data.input.length; i++){
                    if(data.input[i].ref==nextType + "ID"){
                        data.input[i].options = callback;
                        this.setState({data:data});
                        break;
                    }
                }
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    render=()=>{
        const content = this.state.data;
        return(
            <div className={"report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src={(content.image) ? content.image : "/images/reports/excel.png"}/>
                    </div>
                    <h3 className="report-title">{content.title}</h3>
                </div>

                <Step active={(this.state.step == 0)}>
                    <div style={{position:"relative"}}>
                        <div>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div className="report-error">{this.state.error}</div>
                            <div className="report-description">{content.description}</div>
                            <div className="report-input">
                                {
                                    content.input.map(
                                        (item,index)=>{
                                            return (<ReportInputItem key={index} ref={item.ref} content={item} onChange={this.onChangeEntry}/>)
                                        }
                                    )
                                }
                            </div>
                        </div>
                        <div className="report-buttons">
                            <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={()=>{this.setState({step:3})}} type="medium">Upload Blocked List</Button>
                            <Button onClick={this.onValidate} type="medium">Download Blocked List</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report creation started and will take <span className="text-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <div className="report-note">Note. The report will be sent to your email upon creation</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 2)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report successfully generated</h3>
                            <div className="report-note">Note: If your download does not automatically start, please click <span className="text-emphasis" style={{cursor:"pointer"}} onClick={()=>{this.onDownload(this.state.validatedData.filename,this.state.validatedData.filestamp)}}>here</span></div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 3)}>
                    <div style={{position:"relative"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <h4>Upload Blocked Bed Spreadsheet</h4>
                        <Dropzone accessibleForPreview={false} onAdded={this.onFileAdded} onUploaded={this.onFileUploaded} onError={this.onFileError} onProgress={this.onFileProgress}
                                    filetypes={Spreadsheet} label={"Blocked Bed List"} dragAndDropLabel={"Accepts edited spreadsheet downloaded from Blocked Bed List"}
                        />
                        <div className="report-buttons">
                            <Button onClick={()=>{this.setState({step:0})}} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 4)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3 className="text-emphasis">{this.state.beds} Beds have been unblocked</h3>
                            <div className="report-note">Beds can be reassigned to new residents</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class CovidStatusList extends React.Component{

    state={
        loadText:"Generating Report...",
        loading:false,
        step:0,
        data:{
            title:"Covid Status List",
            description:"Generates a spreadsheet of residents and their list of Covid Status. Select dormitory before getting upload template",
            downloadURL:"/api/get-covid-status-list",
            uploadURL:"/api/set-covid-status-list",
            input:[],
        },
        validatedData:{},
    };

    componentDidMount=()=>{
        let data = this.state.data;
        data.input= [
            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
        ]
        this.setState({data:data});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let data = this.state.data;
            for(var i=0; i<data.input.length; i++){
                if(data.input[i].ref == "dormID"){
                    data.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({data:data,update:false});
        }
    }

    onValidate=()=>{
        let keys = this.state.data.input;
        let data = {};
        for(var i=0; i<keys.length; i++){
            const item = keys[i].ref;
            const userInput = this.refs[item].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }

            if(item == "endDate"){
                //Always comes with a startDate
                const startDate=this.refs['startDate'].validate();
                if(moment(userInput.value).isBefore(startDate.value)){
                    this.setState({error:"End Date cannot be before Start Date"});
                    return 0;
                }
            }

            data[item]=userInput.value;
        }

        this.setState({loadText:"Uploading report..."});
        u.post({
            url:this.state.data.downloadURL,
            data:{
                dormID:data.dormID
            },
            success:(data)=>{
                if(data.ready == true) {
                    let validatedData = this.state.validatedData;
                    validatedData.filename = data.filename;
                    validatedData.filestamp = data.filestamp;
                    this.setState({validatedData:validatedData});
                    this.onDownload(data.filename,data.filestamp);
                }
                else{
                    this.setState({loading:false,step:1,estimation:data.estimation});
                }
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onBack=()=>{
        this.setState({step:0});
    }

    onFileAdded=(filename)=>{
        this.setState({loading:true,loadText:"Uploading " + filename + "...",error:""});
    }

    onFileUploaded=(filename)=>{
        this.setState({loadText:"Uploading report..."});
        u.post({
            url:this.state.data.uploadURL,
            data:{
                dormID:this.state.validatedData.dormID,
                filename:filename
            },
            success:(entries)=>{
                this.setState({loading:false,entries:entries,step:4});
            },
            error:(error,status)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onDownload=(filename,filestamp)=>{
        this.setState({loadText: "Downloading template..."});
        u.download(filename,filestamp,()=>{
                this.setState({loading: false, step:2});
            },
            (error)=>{
                this.setState({loading: false, error: error});
            }
        );
    }

    onFileError=(error)=>{
        this.setState({loading:false,error:error});
    }

    onFileProgress=(progress)=>{
        this.setState({loadText:this.state.loadText + progress + "%"});
    }

    getUploadTemplate=()=>{
        this.setState({loading:true,loadText:"Downloading template..."});
        const dormID=this.refs['dormID'].validate();
        u.autoDownload({
            url:"/api/get-upload-covid-template",
            data:{
                dormID:dormID.value,
            },
            success:()=>{
                this.setState({loading:false,error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    render=()=>{
        const content = this.state.data;
        return(
            <div className={"report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src="/images/reports/medical.png"/>
                    </div>
                    <h3 className="report-title">{content.title}</h3>
                </div>

                <Step active={(this.state.step == 0)}>
                    <div style={{position:"relative"}}>
                        <div>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <div className="report-error">{this.state.error}</div>
                            <div className="report-description">{content.description}</div>
                            <div className="report-input">
                                {
                                    content.input.map(
                                        (item,index)=>{
                                            return (<ReportInputItem key={index} ref={item.ref} content={item}/>)
                                        }
                                    )
                                }
                            </div>
                        </div>

                        <div style={{fontSize:"0.85em",margin:"0 auto 10px auto"}}>
                            <TextButton onClick={this.getUploadTemplate}>Get Upload Template</TextButton>
                        </div>

                        <div className="report-buttons">
                            <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={()=>{this.setState({step:3})}} type="medium">Upload Covid List</Button>
                            <Button onClick={this.onValidate} type="medium">Download Covid List</Button>
                        </div>

                    </div>
                </Step>

                <Step active={(this.state.step == 1)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report creation started and will take <span className="text-emphasis">~{this.state.estimation} minutes</span> to generate</h3>
                            <div className="report-note">Note. The report will be sent to your email upon creation</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 2)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3>Report successfully generated</h3>
                            <div className="report-note">Note: If your download does not automatically start, please click <span className="text-emphasis" style={{cursor:"pointer"}} onClick={()=>{this.onDownload(this.state.validatedData.filename,this.state.validatedData.filestamp)}}>here</span></div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 3)}>
                    <div style={{position:"relative"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                        <h4>Upload Covid Status List</h4>
                        <div className="report-error">{this.state.error}</div>
                        <Dropzone accessibleForPreview={false} onAdded={this.onFileAdded} onUploaded={this.onFileUploaded} onError={this.onFileError} onProgress={this.onFileProgress}
                                    filetypes={Spreadsheet} label={"Covid Report"} dragAndDropLabel={"Get the upload template, copy & paste details from hospital file and upload here"}
                        />
                        <div className="report-buttons">
                            <Button onClick={()=>{this.setState({step:0})}} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == 4)}>
                    <div className="report-content">
                        <div className="report-estimate">
                            <h3 className="text-emphasis">{this.state.entries} results have been uploaded</h3>
                            <div className="report-note">Entries can be found on the respective resident's page under Covid Status</div>
                        </div>
                        <div className="report-buttons">
                            <Button onClick={this.onBack} type="medium">Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class UsageReport extends React.Component{

    state={
        step:0,
        error:"",
        loading:false,
        loadText:"",
        dormOptions:[],
        content:{
            constructionWorkers:{},
            otherWorkers:{}
        },
        input:[],
    };

    componentDidMount=()=>{
        this.setState({
            input:[
                {ref:"dormID",label:"Dormitory",type:"select",options:this.props.dormOptions},
                {ref:"startDate",label:"Start Date",type:"date",range:[5,1]},
            ]
        });
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let input = this.state.input;
            for(var i=0; i<input.length; i++){
                if(input[i].ref == "dormID"){
                    input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({input:input,update:false});
        }
    }

    onSubmit=()=>{
        let input = this.state.input;
        let data = {};
        for(var i=0; i<input.length; i++){
            const item = input[i];
            const userInput = this.refs[item.ref].validate();
            if(!userInput.valid){
                this.setState({error:userInput.value});
                return 0;
            }
            data[item.ref]=userInput.value;
        }

        data["endDate"] = moment(data.startDate).add(1,"months").format("YYYY-MM-DD");

        this.setState({loading:true,loadText:"Preparing report..."});
        u.post({
            url:"/api/get-usage-report",
            data:data,
            success:(content)=>{
                this.setState({loading:false,error:""});
                content.startDate = data.startDate;
                content.endDate = data.endDate;
                store.set("usage",content);
                window.open("/usage-report","_blank");
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    render=()=>{
        const content=this.state.content;

        return (
            <div className={"bca-report report-container"}>
                <div className="report-header">
                    <div className="report-icon">
                        <img src="/images/reports/IMDA.png"/>
                    </div>
                    <h3 className="report-title">Usage Report</h3>
                </div>

                <div>
                    <div className="report-error">{this.state.error}</div>
                    <div className="report-description">Generates a printable usage report used for IMDA SME Go Digital grant claims</div>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div style={{position:"relative"}}>
                        <div className="report-input">
                            {
                                this.state.input.map(
                                    (item,index)=>{
                                        return (<ReportInputItem key={index} ref={item.ref} content={item}/>)
                                    }
                                )
                            }
                        </div>
                        <div className="report-buttons">
                            <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={this.onSubmit} type="medium">Go</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class RentalRevenueReport extends React.Component{

    data=[];

    state={
        content:{
            title:"Rental Revenue Report",
            description:"Graphical summary of the revenue generated by the respective rental type",
            url:"/api/get-revenue-report",
            input:[],
            quantifier:"$",
        }
    };

    componentDidMount=()=>{
        let content = this.state.content;
        content.input=[
            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
            {ref:"startDate",label:"Start",type:"date",range:[5,2],rules:{skipDateTo:"01"},lock:true},
            {ref:"endDate",label:"End",type:"date",range:[5,2],rules:{skipDateTo:"01",modifyDate:"end"},lock:true},
        ];
        this.setState({content:content});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "dormID"){
                    content.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({content:content,update:false});
        }
    }

    onChangeInput=(value,label,index,option)=>{
        let content = this.state.content;
        content.input[index].value=value;
        this.setState({content:content});
    }

    onData=(query,callback)=>{
        if(typeof query == "undefined" || query.skip){
            return 0;
        }

        this.data.push({query:query,callback:callback});
        //Mean per occupant
        let totalRevenue = 0;
        let totalOccupants = 0;
        let totalCapacity = 0;
        for(var i=0; i<this.data.length; i++){
            for(var j=0; j<this.data[i].callback.yAxis.length; j++){
                if(this.data[i].callback.yAxis[j] != 0) {
                    const r = parseFloat(this.data[i].callback.yAxis[j]);
                    const c = parseInt(this.data[i].callback.capacity[j]);
                    const o = parseInt(this.data[i].callback.occupancy[j]);

                    totalRevenue += r;
                    totalOccupants += o;
                    totalCapacity += c;
                }
            }
        }

        if(totalOccupants == 0){
            totalOccupants = 1.0;
        }
        if(totalCapacity == 0){
            totalCapacity = 1.0;
        }

        let additionalText=[
            "Per Occupant: $" + (totalRevenue/totalOccupants).toFixed(2),
            "Per Owned Bed: $" + (totalRevenue/totalCapacity).toFixed(2)
        ];
        this.setState({additionalText:additionalText});
    }

    onResetData=()=>{
        this.data=[];
        this.setState({additionalText:[]});
    }

    render=()=>{
        return (
          <GraphReport content={this.state.content}
                       smallMode={this.props.smallMode}
                       onChangeInput={this.onChangeInput}
                       onData={this.onData}
                       additionalText={this.state.additionalText}
                       onReset={this.onResetData}
                       autoQuery={this.props.autoQuery}
                       onCancel={this.props.onCancel}
                       />)
    }
}

class RentalPriceReport extends React.Component{

    data=[];

    state={
        content:{
            title:"Rental Price Report",
            description:"Graphical summary of rental prices for each rental type between the respective dates",
            url:"/api/get-price-report",
            input:[],
            quantifier:"$",
        },
        additionalText:[],
    };

    componentDidMount=()=>{
        let content = this.state.content;
        content.input=[
            {ref:"dormID",label:"Dormitory",type:"select",options:[]},
            {ref:"startDate",label:"Start",type:"date",range:[5,2],rules:{skipDateTo:"01"},lock:true},
            {ref:"endDate",label:"End",type:"date",range:[5,2],rules:{skipDateTo:"01",modifyDate:"end"},lock:true},
        ];
        this.setState({content:content});
    }

    static getDerivedStateFromProps(props,state){
        if(props.dormOptions != state.dormOptions && props.dormOptions.length > 0){
            state.dormOptions = props.dormOptions;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            let content = this.state.content;
            for(var i=0; i<content.input.length; i++){
                if(content.input[i].ref == "dormID"){
                    content.input[i].options = this.state.dormOptions;
                    break;
                }
            }
            this.setState({content:content,update:false});
        }
    }

    onChangeInput=(value,label,index,option)=>{
        let content = this.state.content;
        content.input[index].value=value;
        this.setState({content:content});
    }

    onData=(query,callback)=>{
        if(typeof query == "undefined" || query.skip){
            return 0;
        }

        query.capacity=(query.roomTypeID) ? query.roomTypeID.option.capacity : 0;
        this.data.push({query:query,callback:callback});

        let totalBedRevenue=0;
        let totalDuration=0;

        for(var i=0; i<this.data.length; i++){
            for(var j=0; j<this.data[i].callback.yAxis.length; j++){
                if(parseInt(this.data[i].callback.yAxis.length[j]) != 0) {
                    totalBedRevenue += parseFloat(this.data[i].callback.yAxis[j]);
                    totalDuration ++;
                }
            }
        }
        const meanPricePerBed = (parseFloat(totalBedRevenue) / totalDuration).toFixed(2);
        this.setState({additionalText:["Mean Per Rented Bed: $" + meanPricePerBed]});
    }

    onResetData=()=>{
        this.data=[];
        this.setState({additionalText:[]});
    }

    render=()=>{
        return (
          <GraphReport content={this.state.content}
                       smallMode={this.props.smallMode}
                       onChangeInput={this.onChangeInput}
                       onData={this.onData}
                       additionalText={this.state.additionalText}
                       onReset={this.onResetData}
                       autoQuery={this.props.autoQuery}
                       onCancel={this.props.onCancel}
                       />
        )
    }
}
