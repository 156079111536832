import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,
        AddListItem,Segment,LoadGraphic,Step,DynamicListArea,
        BulkEdit,DownloadTemplate,Databox} from '../components/Common';
import {EditSizeLabel,EditSizeLink,EditableBox,
        EditableDate,EditableArea,EmptyBox,
        Select,Button,Date,Box,Info,TextButton,Area,
        MiniMinusButton} from '../components/Input';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';

export default class AccessoryList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Accessory",
        permission:"combined",  //Accepts combined permission module as well
        combinedField:"accessory",     //Used when permission exists in a combined field
        url:"/accessory-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/add-accessory-entry",
        //templateURL:"/api/get-add-tenant-template",
        //bulkURL:"/api/add-bulk-tenants",
        deleteURL:"/api/delete-accessory-entries",
        spreadsheetURL:"/api/generate-accessory-spreadsheet",
        overviewURL:"/api/get-all-accessory-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"accessory-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddAccessoryEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
    };

    getOptions=()=>{
        /*u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","priceType","taxType","remarkType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });*/
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");
        this.setState({
            pageMenuButtons:[
                {label:"Add",mobileLabel:"Add Entry",ref:"add",image:"/images/page-menu/add.png",title:"Add new price entry",enabled:true},
                {label:"Delete",mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entry",enabled:true},
                {label:"Spreadsheet",mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit price details",enabled:bulkEditEnabled,padding:"3px"},
            ],
            permissions:permissions,
        });
        this.getOptions();
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <AccessoryItem key={index}
                                               index={index}
                                               content={content}
                                               onChange={onChange}
                                               onContentChange={onContentChange}
                                               registerReference={registerRef}
                                               options={this.state.options}
                                               settings={this.settings}
                                               />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class AccessoryItem extends React.Component{
    state={
        step:"view",
        error:"",
        loading:false,
        loadText:"",
    };

    onRemoveRequest=(index)=>{
        this.removeIndex=index;
        this.setState({step:"remove",error:""});
    }

    onRemoveAssigned=()=>{
        this.setState({loading:true,loadText:"Removing line item..."});
        const settings = this.settings;
        u.post({
            url:"/api/accessory-remove-assign",
            data:{
                entry:this.props.content.assigned[this.removeIndex],
            },
            success:()=>{
                let assigned = this.props.content.assigned;
                assigned.splice(this.removeIndex,1);
                this.props.onChange(assigned,"assigned",this.props.index);
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChangeAssigned=(value,label,index)=>{
        let assigned=this.props.content.assigned;
        assigned[index][label]=value;
        this.props.onChange(assigned,"assigned",this.props.index);
    }

    onExport=()=>{
        this.setState({loading:true,loadText:"Exporting..."});
        u.autoDownload({
            url:"/api/accessory-export-assign",
            data:{
                accessoryID:this.props.content.accessoryID,
            },
            success:()=>{
                this.setState({loading:false,error:""})
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    render=()=>{
        const content = this.props.content;
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"7px"};

        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>
                <Step active={this.state.step == "view"}>
                    <DynamicListArea content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     combinedField={this.props.settings.combinedField}
                                     />
                    {
                          (content.assigned.length > 0)
                              ?
                              <div className="list-table-container">
                                  <h3>Assigned To</h3>

                                  <div className="list-table">
                                      <table>
                                          <thead>
                                              <tr>
                                                  <th className="remove left"/>
                                                  <th>{(content.ownershipType == "Room") ? "Room" : "Resident"}</th>
                                                  <th>Quantity</th>
                                                  <th>Issue On</th>
                                                  <th>Expire On</th>
                                                  <th className="right">Remarks</th>
                                              </tr>
                                          </thead>
                                          <tbody>
                                          {
                                              content.assigned.map((item,index)=>{
                                                  return (
                                                     <tr key={index} className="item">
                                                        <td>
                                                            <div style={{position:"relative",width:"15px",height:"15px"}}>
                                                                <MiniMinusButton enabled={true} onClick={()=>{this.onRemoveRequest(index)}}/>
                                                            </div>
                                                        </td>
                                                        <td>
                                                        {
                                                          (content.ownershipType=="Room")
                                                              ?
                                                              (item.type == "room")
                                                                  ?
                                                                  <Link to={"/estate-list/room/"+item.roomID}>{item.estateLabel}</Link>
                                                                  :
                                                                  <Link to={"/estate-list/sickbay/"+item.sickbayID}>{item.estateLabel}</Link>
                                                              :
                                                              <Link to={"/resident/"+item.residentID}>{item.residentName}</Link>
                                                        }
                                                        </td>
                                                        <td>
                                                            <EditableBox label="Quantity"
                                                                         field="quantity"
                                                                         type="number"
                                                                         rules={{required:true,minValue:1}}
                                                                         values={{value:item.quantity}}
                                                                         onChange={this.onChangeAssigned}
                                                                         index={index}
                                                                         url="/api/accessory-update-assign"
                                                                         id={{entry:item}}
                                                                         />
                                                        </td>
                                                        <td>
                                                            <EditableDate label="Issue On"
                                                                          field="issued"
                                                                          rules={{addEmpty:true}}
                                                                          values={{value:item.issued}}
                                                                          onChange={this.onChangeAssigned}
                                                                          index={index}
                                                                          range={[20,1]}
                                                                          url="/api/accessory-update-assign"
                                                                          id={{entry:item}}
                                                                          />
                                                        </td>
                                                        <td>
                                                            <EditableDate label="Expire On"
                                                                          field="expiry"
                                                                          rules={{addEmpty:true}}
                                                                          values={{value:item.expiry}}
                                                                          onChange={this.onChangeAssigned}
                                                                          index={index}
                                                                          url="/api/accessory-update-assign"
                                                                          id={{entry:item}}
                                                                          />
                                                        </td>
                                                        <td>
                                                            <EditableArea label="Remarks"
                                                                          field="remarks"
                                                                          values={{value:item.remarks}}
                                                                          onChange={this.onChangeAssigned}
                                                                          index={index}
                                                                          url="/api/accessory-update-assign"
                                                                          id={{entry:item}}
                                                                          />
                                                        </td>
                                                     </tr>
                                                  )
                                              })
                                          }
                                          </tbody>
                                      </table>
                                  </div>
                                  <div className="list-segment-buttons">
                                      <Button onClick={this.onExport}>Export Assigned</Button>
                                  </div>
                              </div>
                              :
                              <div className="list-placeholder">
                                  <b>No Active Assignments</b>
                              </div>
                    }
                </Step>

                <Step active={this.state.step=="remove"}>
                    <div style={{position:"relative",padding:"30px auto",textAlign:"center"}}>
                        <h2 className="text-negative">Remove Assigned Accessory?</h2>
                        <div className="list-segment-note">Note. This action cannot be undone</div>
                        <div className="list-segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onRemoveAssigned}>Confirm</Button>
                        </div>
                    </div>
                </Step>

            </div>
        )
    }
}

class AddAccessoryEntry extends React.Component{

    state={
        additional:{},
        dormOptions:[],
        entryData:{
        },
        options:{},
        step:"add",
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
        this.getDormOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","accessoryOwnershipType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
              type:"dorm"
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onValidate=()=>{
        const keys=["dormID","label","ownershipType"];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }
        this.refs[this.props.reference].onValidated(data);
    }

    onChange=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const rentalItem = this.state.rentalItem;
        const options = this.state.options;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"9px"};

        return(
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         filetypes={Spreadsheet}
                         additional={this.state.additional}
                         onToggleForm={this.props.onToggleForm}>

                <div className="segment-content">
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <Step active={this.state.step == "add"}>
                            <div>
                                <h3>Complete the following to continue</h3>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Dormitory</div>
                                    <div className="segment-item-input"><Select ref="dormID" values={{value:entryData.dormID,options:this.state.dormOptions}} label="Dormitory" field="dormID" onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Accessory:</div>
                                    <div className="segment-item-input"><Box ref="label" values={{value:entryData.label}} label="Label" field="label" rules={{required:true}} onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Ownership:</div>
                                    <div className="segment-item-input"><Select ref="ownershipType" values={{value:entryData.ownershipType,options:options.accessoryOwnershipType}} label="Ownership" field="ownershipType" rules={{required:true}} onChange={this.onChange}/></div>
                                </div>

                                <div className="segment-buttons">
                                    {
                                        (u.isMobileDevice())
                                            ?
                                            <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                            :
                                            null
                                    }
                                    <Button type="medium" onClick={this.onValidate}>Create</Button>
                                </div>
                            </div>
                        </Step>
                    </div>
                </div>

            </AddListItem>
        )
    }
}

export class AccessoryOwner extends React.Component{

    state={
        error:"",
        loading:false,
        loadText:"",
        accessoryOptions:[],
        accessory:"",
        step:"view",
    }

    getAccessoryOptions=()=>{
        this.setState({loading:true,loadText:"Getting accessories..."});
        u.post({
            url:"/api/get-accessory-options",
            data:{
                dormID:this.props.dormID,
                ownershipType:this.props.ownershipType
            },
            success:(accessoryOptions)=>{
                this.setState({loading:false,step:"add",accessoryOptions:accessoryOptions});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onRemoveRequest=(index)=>{
        this.removeIndex=index;
        this.setState({step:"remove",error:""});
    }

    onRemoveAssigned=()=>{
        this.setState({loading:true,loadText:"Removing line item..."});
        u.post({
            url:"/api/accessory-remove-assign",
            data:{
                entry:this.props.content[this.removeIndex],
            },
            success:()=>{
                let accessories = this.props.content;
                accessories.splice(this.removeIndex,1);
                this.props.onChange(accessories,"accessories");
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChangeAssigned=(value,label,index)=>{
        let accessories=this.props.content;
        accessories[index][label]=value;
        this.props.onChange(accessories,"accessories");
    }

    onAddAccessory=()=>{
        this.setState({error:""});
        const keys=['accessoryID','quantity','expiry','remarks','issued'];
        let data={};
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }
        data.location=(this.props.estate) ? this.props.estate.location : undefined;
        data.estateLabel = (this.props.estate) ? this.props.estate.label : undefined;
        data.residentID=(this.props.resident) ? this.props.resident.tenantID : undefined;
        this.setState({loading:true,loadText:"Adding accessory..."});

        u.post({
            url:"/api/assign-accessory",
            data:data,
            success:(entry)=>{
                let accessories=this.props.content;
                accessories.push(entry);
                this.props.onChange(accessories,"accessories");

                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    render=()=>{
        const content=this.props.content;
        const resident=(this.props.resident) ? this.props.resident : {};
        const estate=(this.props.estate) ? this.props.estate : {};
        return(
            <Databox active={true} align="center" title="Accessories">
                <div className="page-error">{this.state.error}</div>
                <LoadGraphic text={this.state.loadText} active={this.state.loading}/>
                <div className="accessories-component">
                    <Step active={(this.state.step == "view")}>
                        <div className="list-table-container">
                            {
                                (content.length > 0)
                                    ?
                                    <div className="list-table" style={{width:"98%"}}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="remove left"/>
                                                    <th>Accessory</th>
                                                    <th>Quantity</th>
                                                    <th>Issued On</th>
                                                    <th>Expire On</th>
                                                    <th className="right">Remarks</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                content.map((item,index)=>{
                                                    return (
                                                       <tr key={index} className="item">
                                                          <td>
                                                              <div style={{position:"relative",width:"20px",height:"20px"}}>
                                                                  <MiniMinusButton enabled={true} onClick={()=>{this.onRemoveRequest(index)}}/>
                                                              </div>
                                                          </td>
                                                          <td>
                                                              {item.accessoryLabel}
                                                          </td>
                                                          <td>
                                                              <EditableBox label="Quantity"
                                                                           field="quantity"
                                                                           type="number"
                                                                           rules={{required:true,minValue:1}}
                                                                           values={{value:item.quantity}}
                                                                           onChange={this.onChangeAssigned}
                                                                           index={index}
                                                                           url="/api/accessory-update-assign"
                                                                           id={{entry:item}}
                                                                           />
                                                          </td>
                                                          <td>
                                                              <EditableDate label="Issue On"
                                                                            field="issued"
                                                                            rules={{addEmpty:true}}
                                                                            values={{value:item.issued}}
                                                                            onChange={this.onChangeAssigned}
                                                                            index={index}
                                                                            range={[20,1]}
                                                                            url="/api/accessory-update-assign"
                                                                            id={{entry:item}}
                                                                            />
                                                          </td>
                                                          <td>
                                                              <EditableDate label="Expire On"
                                                                            field="expiry"
                                                                            rules={{addEmpty:true}}
                                                                            values={{value:item.expiry}}
                                                                            onChange={this.onChangeAssigned}
                                                                            index={index}
                                                                            url="/api/accessory-update-assign"
                                                                            id={{entry:item}}
                                                                            />
                                                          </td>
                                                          <td>
                                                              <EditableArea label="Remarks"
                                                                            field="remarks"
                                                                            values={{value:item.remarks}}
                                                                            onChange={this.onChangeAssigned}
                                                                            index={index}
                                                                            url="/api/accessory-update-assign"
                                                                            id={{entry:item}}
                                                                            />
                                                          </td>
                                                       </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <div className="list-placeholder"><b>No Accessories Assigned</b></div>
                            }
                            <div style={{margin:"30px auto 0 auto"}}>
                                <Button onClick={this.getAccessoryOptions}>Add Accessory</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "add")}>
                        <div style={{margin:"5px 0",fontSize:"14px"}}>
                            <div>
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Owned By:</div>
                                    <div className="segment-item-input">
                                        <EmptyBox><div className="text-emphasis" style={{padding:"3px"}}>{(this.props.ownershipType=="Resident") ? resident.tenantName : estate.label}</div></EmptyBox>
                                    </div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Accessory:</div>
                                    <div className="segment-item-input">
                                        <Select ref="accessoryID"
                                                values={{options:this.state.accessoryOptions}}
                                                label="Accessory"
                                                />
                                    </div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Quantity:</div>
                                    <div className="segment-item-input">
                                        <Box ref="quantity"
                                             label="Quantity"
                                             type="number"
                                             rules={{required:true,minValue:1}}
                                             />
                                    </div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Issued On:</div>
                                    <div className="segment-item-input">
                                        <Date ref="issued"
                                              label="Issued On"
                                              rules={{addEmpty:true}}
                                              range={[5,1]}/>
                                    </div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Expire On:</div>
                                    <div className="segment-item-input">
                                        <Date ref="expiry"
                                              label="Expiry"
                                              rules={{minDate:moment().format("YYYY-MM-DD"),addEmpty:true}}
                                              range={[0,5]}/>
                                    </div>
                                </div>

                                <div className="segment-full-item">
                                    <div className="segment-item-label">Remarks:</div>
                                    <div className="segment-item-input">
                                        <Area ref="remarks"
                                              label="Remarks"
                                              />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button onClick={this.onAddAccessory}>Add</Button>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "remove")}>
                        <div style={{padding:"10px 0",fontSize:"14px"}}>
                            <h3 className="text-negative">Remove Assigned Accessory?</h3>
                            <div><i>Note. This action cannot be undone</i></div>

                            <div style={{margin:"20px auto"}}>
                                <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                <Button onClick={this.onRemoveAssigned}>Confirm</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Databox>
        )
    }
}
