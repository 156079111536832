import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,Dropzone,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Select,Area,Box,EditSizeLabel,Date,Time,DayOfWeek,EmptyBox,EditSizeLink,TextButton,ContactNumber,Checkbox,InputPhoto,Info} from '../components/Input';
import u from '../utilities/Utilities';
import {Photo} from '../data/Mimetypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';

export default class SchedulerList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Scheduler",
        permission:"task",
        url:"/scheduler-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-scheduler-entry",
        deleteURL:"/api/delete-scheduler-entries",
        spreadsheetURL:"/api/generate-scheduler-spreadsheet",
        overviewURL:"/api/get-all-scheduler-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"scheduler-list",
        orderByField:"reference",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddSchedule,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("task");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add",ref:"add",mobileLabel:"Add Schedule",image:"/images/page-menu/add.png",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",mobileLabel:"Bulk Edit",image:"/images/page-menu/edit.png",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
              {label:"Setup Forms",ref:"form",title:"Configured scheduler",enabled:(permissions.create==2),onClick:this.onForm},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onForm=()=>{
        window.location = "/form-list";
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <SchedulerItem key={index}
                                              content={content}
                                              index={index}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              permissions={this.state.permissions}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class SchedulerItem extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
        photoArray:"supportingPhoto",
    }

    onViewImage=(index,photoArray)=>{
        const image = this.props.content[photoArray][index];
        this.setState({
            step:"view-photo",
            photoArray:photoArray,
            selectedImage:image.url,
            selectedImageIndex:index,
            selectedImageName:image.filename,
        });
    }

    onUploadPhoto=(url)=>{
        this.setState({loading:true,loadText:"Processing...",error:""});
        u.post({
            url:"/api/add-scheduler-photo",
            data:{
              schedulerID:this.props.content.schedulerID,
              filename:url
            },
            success:()=>{
                const field = this.state.photoArray;
                let images=this.props.content[field];
                images.push({filename:this.state.filename,url:url});
                this.props.onChange(images,field,this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDeletePhoto=()=>{
        this.setState({loading:true});
        u.post({
            url:"/api/delete-scheduler-photo",
            data:{
                schedulerID:this.props.content.schedulerID,
                imageURL:this.state.selectedImage,
                imageName:this.state.selectedImageName,
            },
            success:()=>{
                const field = this.state.photoArray;
                let images = this.props.content[field];
                images.splice(this.state.selectedImageIndex,1);
                this.props.onChange(images,field,this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        return (
            <div style={{position:"relative",textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={"scheduler-list"}
                                     permission="task"
                                     content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />
                  {
                     (content.supportingPhoto.length > 0)
                         ?
                         <div className="list-segment extended" style={{textAlign:'center'}}>
                             <h3>Supporting Photos</h3>
                             <div className="list-image-container">
                             {
                                 content.supportingPhoto.map(
                                     (item,index)=>{
                                         return(
                                             <div key={index} className="list-image-item">
                                                 <div className="list-image-flex"><img src={item.url} onClick={()=>{this.onViewImage(index,"supportingPhoto")}}/></div>
                                             </div>
                                         )
                                     }
                                 )
                             }
                             </div>
                         </div>
                         :
                         null
                 }
                 {
                     (permissions.update)
                         ?
                         <div className="list-segment-buttons">
                             <TextButton onClick={()=>{this.setState({step:"upload-photo",error:""})}}>Add Supporting Photo</TextButton>
                         </div>
                         :
                         null
                 }
                </Step>
                <Step active={this.state.step == "view-photo"}>
                    <div className="list-image-container">
                        <h2>Photo: {this.state.selectedImageName}</h2>
                        <img className="list-view-image" src={this.state.selectedImage} onClick={()=>{window.open(this.state.selectedImage,"_blank")}}/>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            {
                                (permissions.update)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"delete-photo"})}}>Delete</Button>
                                :
                                null
                            }
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "upload-photo"}>
                    <div className="list-upload-photo">
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "delete-photo"}>
                    <div style={{padding:"30px 0 20px 0",textAlign:'center'}}>
                      <h2 className="text-negative">Confirm Delete Photo: {this.state.selectedImageName}</h2>
                      <div>Note. This action cannot be reversed</div>
                    </div>

                    <div className="segment-buttons">
                          <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                          <Button type="medium" onClick={this.onDeletePhoto}>Confirm</Button>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddSchedule extends React.Component{

    state={
      error:"",
      options:[],
      estateOptions:{
        dorm:[],
        cluster:[],
        building:[],
        level:[],
        room:[],
        clusterAll:[],
        buildingAll:[],
        levelAll:[],
        roomAll:[],
      },
      staffOptions:[],
      formOptions:[],
      entryData:{
          targetUser:"staff",
          supportingPhoto:[],
          startingFrom:moment().add(1,"days").format("YYYY-MM-DD"),
          optionLevel:0,
      },
      loading:false,
      loadText:'',
      step:"add",
    };

    componentDidMount=()=>{
        this.getOptions();
        this.getEstateOptions("dorm");
        this.getStaffOptions();
        this.getFormOptions();
    }

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "dormID",
            "reference",
            "taskType",
            "formID",
            "dayOfWeek",
            "frequencyInWeeks",
            "startingFrom",
            "deadlineInDays",
            "targetUser",
            "assignedPrimaryID",
            "assignedSecondaryID",
            "tenantFIN",
            "locationType",
            "locationDetailsText",
            "randomCount",
            "clusterID",
            "buildingID",
            "levelID",
            "roomID",
            "taskDescription",
        ];

        for(var i=0; i<values.length; i++){
            if(this.refs[values[i]]){
                const field = this.refs[values[i]].validate();
                if(!field.valid){
                    this.setState({error:field.value});
                    return 0;
                }
            }
        }

        let data = Object.assign({},this.state.entryData);
        if(data.locationType != "others"){
            data.locationDetails={
              clusterID:data.clusterID,
              buildingID:data.buildingID,
              levelID:data.levelID,
              roomID:data.roomID,
          };
        }

        delete data.clusterID;
        delete data.buildingID;
        delete data.levelID;
        delete data.roomID;
        delete data.deadlineDate;
        delete data.deadlineTime;

        this.refs['add'].onValidated(data);
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:["taskType","schedulerLocationType","schedulerLocationRoomSubset","taskTargetUser"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getEstateOptions=(type,id,label)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:type,
                id:id,
            },
            success:(estateOption)=>{
                let estateOptions = this.state.estateOptions;
                estateOptions[type]=estateOption;
                estateOptions[type+"All"] = estateOption.map((item)=>{
                    return {value:item.value,label:"in " + item.label};
                });
                estateOptions[type+"All"].push({value:"all",label:"in all " + label});
                this.setState({estateOptions:estateOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getStaffOptions=()=>{
        u.post({
            url:"/api/get-staff-options",
            data:{
                permission:{
                    task:{view:2}
                }
            },
            success:(staffOptions)=>{
                staffOptions.unshift({value:"None",label:"None"});
                this.setState({staffOptions:staffOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getFormOptions=()=>{
        u.post({
            url:"/api/get-form-options",
            data:{},
            success:(formOptions)=>{
                this.setState({formOptions:formOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.setState({
          entryData:{
              supportingPhoto:[],
              startingFrom:moment().add(1,"days").format("YYYY-MM-DD"),
              optionLevel:0,
          },
          error:""
        });
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        if(label == "targetUser" && value == "residentRoom"){
            if(entryData.taskLocation != "room"){
                const option = {value:"room",label:"Specified Room",optionLevel:4};
                this.onChangeLocation("room","taskLocation",0,option);
            }
        }
        this.setState({entryData:entryData});
    }

    onChangeEstate=(value,label,index,option)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;

        if(value != "all"){
            if(label == "dormID"){
                this.getEstateOptions("cluster",value,"Clusters");
                entryData.clusterID="";
                entryData.buildingID="";
                entryData.levelID="";
                entryData.roomID="";
            }
            if(label == "clusterID" && entryData.optionLevel > 1){
                this.getEstateOptions("building",value,"Buildings");
                entryData.buildingID="";
                entryData.levelID="";
                entryData.roomID="";
            }
            else if(label == "buildingID" && entryData.optionLevel > 2){
                this.getEstateOptions("level",value,"Levels");
                entryData.levelID="";
                entryData.roomID="";
            }
            else if(label == "levelID" && entryData.optionLevel > 3){
                this.getEstateOptions("room",value,"Rooms");
                entryData.roomID="";
            }
        }
        else{
            if(entryData.optionLevel > 1 && index < 2){
                entryData.buildingID = "all";
            }
            if(entryData.optionLevel > 1 && index < 2){
                entryData.buildingID = "all";
            }
            if(entryData.optionLevel > 2 && index < 3){
                entryData.levelID = "all";
            }
            if(entryData.optionLevel > 3 && index < 4){
                entryData.roomID = "all";
            }
        }
        this.setState({entryData:entryData});
    }

    onChangeLocation=(value,label,index,option)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        entryData.optionLevel=option.optionLevel;
        entryData.optionAll=option.all;
        let estateOptions = this.state.estateOptions;
        estateOptions.cluster=[];
        estateOptions.clusterAll=[];
        estateOptions.building=[];
        estateOptions.buildingAll=[];
        estateOptions.level=[];
        estateOptions.levelAll=[];
        estateOptions.room=[];
        estateOptions.roomAll=[];
        entryData.clusterID="";
        entryData.buildingID="";
        entryData.levelID="";
        entryData.roomID="";

        if(value.substr(0,6) == "random"){
            entryData.randomLocation = true;
        }
        else{
            entryData.randomLocation = false;
        }

        this.setState({entryData:entryData,estateOptions:estateOptions});

        if(value != "others"){
            this.getEstateOptions("cluster",entryData.dormID,"Zones");
        }
    }

    onPhotoUploaded=(url)=>{
        let entryData = this.state.entryData;
        entryData.supportingPhoto.push({filename:this.state.filename,url:url});
        this.setState({entryData:entryData,step:"add",error:"",loading:false});
    }

    onPhotoRemoved=(index)=>{
        let entryData=this.state.entryData;
        entryData.supportingPhoto.splice(index,1);
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData=this.state.entryData;
        const options=this.state.options;
        const location=this.state.estateOptions;

        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onCancel}
                         onToggleForm={this.props.onToggleForm}
                         stepScroll={true}>
                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step == "add"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-error">{this.state.error}</div>

                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dormID" field="dormID" label="Dormitory" values={{value:entryData.dormID,options:location.dorm}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Reference <Info title="Label your tasks intuitively such as 'Blk A Routine Inspection', so it can be referenced and updated easily in the future"/></div>
                                <div className="segment-item-input">
                                    <Box ref="reference" field="reference" label="Reference" values={{value:entryData.reference}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Task Type</div>
                                <div className="segment-item-input">
                                    <Select ref="taskType" field="taskType" label="Task Type" values={{value:entryData.taskType,options:options.taskType}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Deliverable <Info title="By default, assigned staff will only need to take completion photo. You may create a custom Form in the Form list for users to perform before completing the task"/></div>
                                <div className="segment-item-input">
                                    <Select ref="formID" field="formID" label="Deliverable" values={{value:entryData.formID,options:this.state.formOptions}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Day Of Week <Info title="Which day of the week should this task be created?"/></div>
                                <div className="segment-item-input">
                                    <DayOfWeek ref="dayOfWeek" field="dayOfWeek" label="Day Of Week" values={{value:entryData.dayOfWeek}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Frequency <Info title="<b>Frequency In Weeks</b><br/>How many weeks in between the creation of this task? I.E. (If you enter 2) Task will be created once every 2 weeks"/></div>
                                <div className="segment-item-input">
                                    <Box ref="frequencyInWeeks" field="frequencyInWeeks" label="Frequency (Weeks)" values={{value:entryData.frequencyInWeeks}} type="number" rules={{required:true,minValue:1}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Starting From <Info title="When will the system begin creating tasks? First task entry will begin on the week that you have entered"/></div>
                                <div className="segment-item-input">
                                    <Date ref="startingFrom" field="startingFrom" label="Starting From" values={{value:entryData.startingFrom}} rules={{minDate:moment().add(1,"days").format("YYYY-MM-DD")}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Deadline <Info title="<b>Deadline (Days)</b><br/>How many days does the user have to complete task?"/></div>
                                <div className="segment-item-input">
                                    <Box ref="deadlineInDays" field="deadlineInDays" label="Deadline (Days)" values={{value:entryData.deadlineInDays}} type="number" rules={{required:true,minValue:1}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Target User <Info title="<b>Staff</b><br/>Assign task to selected staff<br/><br/><b>Resident (FIN)</b><br/>Assign to resident of the inputed FIN<br/><br/><b>Resident (Room)</b><br/>Target all residents registered to the room in 'Location'"/></div>
                                <div className="segment-item-input">
                                    <Select ref="targetUser"
                                            field="targetUser"
                                            label="Target User"
                                            values={{
                                                value:entryData.targetUser,
                                                options:options.taskTargetUser
                                            }}
                                            rules={{required:true}}
                                            onChange={this.onChange}
                                            />
                                </div>
                            </div>

                            {
                                (entryData.targetUser == "staff")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Primary <Info title="This is the person that the task will be assigned to"/></div>
                                        <div className="segment-item-input">
                                            <Select ref="assignedPrimaryID" field="assignedPrimaryID" label="Assigned Primary" values={{value:entryData.assignedPrimaryID,options:this.state.staffOptions}} rules={{required:true}} onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                (entryData.targetUser == "staff")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Secondary <Info title="Recommended to assign to the person that is verifying the task completion. If verification is not required, secondary can also be the 2nd person in charge of completing the task"/></div>
                                        <div className="segment-item-input">
                                            <Select ref="assignedSecondaryID" field="assignedSecondaryID" label="Assigned Secondary" values={{value:entryData.assignedSecondaryID,options:this.state.staffOptions}} rules={{required:true}} onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                (entryData.targetUser == "residentFIN")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Resident FIN <Info title="FIN number of the resident that this task is assigned to"/></div>
                                        <div className="segment-item-input">
                                            <Box ref="tenantFIN" field="tenantFIN" label="Resident FIN" values={{value:entryData.tenantFIN}} rules={{required:true}} onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className="segment-full-item">
                                <div className="segment-item-label">Location <Info title="Where is the task location? Choose specific estates to tag the task for future seaching"/></div>
                                <div className="segment-item-input">
                                    <Select ref="locationType"
                                            field="locationType"
                                            label="Location"
                                            values={{
                                                value:entryData.locationType,
                                                options:(entryData.targetUser == "residentRoom") ? options.schedulerLocationRoomSubset : options.schedulerLocationType
                                            }}
                                            rules={{required:true}}
                                            onChange={this.onChangeLocation}/>
                                    {
                                        (entryData.locationType == "others")
                                            ?
                                            <div>
                                                <Box ref="locationDetailsText" style={{margin:"5px 0"}} field="locationDetailsText" label="Task Location" values={{value:entryData.locationDetailsText}} rules={{required:true}} onChange={this.onChange}/>
                                            </div>
                                            :
                                            <div>
                                                {
                                                  (entryData.optionLevel > 0)
                                                    ?
                                                    <Select ref="clusterID" style={{margin:"5px 0"}} index={1} label="Zone" field="clusterID" values={{value:entryData.clusterID,options:((entryData.optionAll) ? location.clusterAll : location.cluster)}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.optionLevel > 1)
                                                    ?
                                                    <Select ref="buildingID" style={{margin:"5px 0"}} index={2} label="Building" field="buildingID" values={{value:entryData.buildingID,options:((entryData.optionAll) ? location.buildingAll : location.building)}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.optionLevel > 2)
                                                    ?
                                                    <Select ref="levelID" style={{margin:"5px 0"}} index={3} label="Level" field="levelID" values={{value:entryData.levelID,options:((entryData.optionAll) ? location.levelAll : location.level)}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.optionLevel > 3)
                                                    ?
                                                    <Select ref="roomID" style={{margin:"5px 0"}} index={4} label="Room" field="roomID" values={{value:entryData.roomID,options:((entryData.optionAll) ? location.roomAll : location.room)}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                            </div>
                                    }
                                </div>
                            </div>

                            {
                                (entryData.randomLocation)
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Number of Rooms <Info title="How many random rooms to create task for?"/></div>
                                        <div className="segment-item-input">
                                            <Box ref="randomCount"
                                                 field="randomCount"
                                                 label="Number of Rooms"
                                                 values={{
                                                    value:entryData.randomCount,
                                                  }}
                                                 type="number"
                                                 rules={{required:true,minValue:1}}
                                                 onChange={this.onChange}
                                                 />
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className="segment-full-item">
                                <div className="segment-item-label">Task Description <Info title="What is the task? Put detailed information of the task that needs to be completed"/></div>
                                <div className="segment-item-input">
                                    <Area ref="taskDescription" field="taskDescription" label="Task Description" values={{value:entryData.taskDescription}} rules={{required:true,rows:5}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Supporting Photo <Info title="Will the task or location be clearer with an image? Upload up to 5 images to help the user understand the task"/></div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="supportingPhoto" field="supportingPhoto" label="Supporting Photo" values={{value:entryData.supportingPhoto}} rules={{max:5}} onChange={this.onChange}
                                                onAdd={()=>{this.setState({step:"photo",error:""})}}
                                                onRemove={this.onPhotoRemoved}
                                    />
                                </div>
                            </div>

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "photo"}>
                        <div className="segment-error">{this.state.error}</div>
                        <Dropzone label="add-task-dropzone"
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.setState({filename:filename,loading:true,loadText:"Uploading "+filename+"..."})}}
                                  onUploaded={this.onPhotoUploaded}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Photo}
                        />
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Back</Button>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}
