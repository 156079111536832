import React from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';

function hasGetUserMedia() {
    return !!(navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia);
}

export default class Webcam extends React.Component {
    
    state = {
        hasUserMedia: false,
        videoSource:null,
        audioSource:null,
    };

    componentDidMount=()=>{
        this.loadWebcam();
    }

    loadWebcam=(override)=>{
        if (!hasGetUserMedia()) {
            return;
        }

        Webcam.mountedInstances.push(this);

        if (!this.state.hasUserMedia && !Webcam.userMediaRequested) {
            this.requestUserMedia(override);
        }

    }

    componentWillUnmount=()=>{
        let index = Webcam.mountedInstances.indexOf(this);
        Webcam.mountedInstances.splice(index, 1);

        if (Webcam.mountedInstances.length === 0 && this.state.hasUserMedia) {
            if (this.stream.stop) {
                this.stream.stop();
            } else {
                if (this.stream.getVideoTracks) {
                    for (let track of this.stream.getVideoTracks()) {
                        track.stop();
                    }
                }
                if (this.stream.getAudioTracks) {
                    for (let track of this.stream.getAudioTracks()) {
                        track.stop();
                    }
                }
            }
            Webcam.userMediaRequested = false;
        }
    }

    componentWillReceiveProps=(props)=>{
        if(props.videoSource !== undefined && props.videoSource !== null){
            if(props.videoSource != this.state.videoSource){
                this.requestUserMedia(props.videoSource);
            }
        }
    }

    requestUserMedia=async (overrideVideoSource)=>{
        navigator.mediaDevices.getUserMedia = navigator.mediaDevices.getUserMedia || navigator.mediaDevices.webkitGetUserMedia || navigator.mediaDevices.mozGetUserMedia || navigator.mediaDevices.msGetUserMedia;
        this.stream = await navigator.mediaDevices.getUserMedia({audio:true,video:true});

        let sourceSelected = async () => {
            if(this.stream.active){
                Webcam.mountedInstances.forEach((instance) => instance.handleUserMedia());
            }
            else{
                this.setState({hasUserMedia: false});
                return;
            }
        };

        if (overrideVideoSource) {
            this.setState({videoSource:overrideVideoSource});
            sourceSelected(this.state.audioSource, overrideVideoSource);
        }
        else {
            if ('mediaDevices' in navigator) {
                navigator.mediaDevices.enumerateDevices().then((devices) => {
                    let audioSource = null;
                    let videoSource = null;

                    let allVideoSources = [];

                    devices.forEach((device) => {
                        if (device.kind === 'audio') {
                            audioSource = device.id;
                        }
                        else if (device.kind === 'video' || device.kind ==="videoinput") {
                            videoSource = device.id;
                            allVideoSources.push(device);
                            if(this.props.onMobileLog)
                                this.props.onMobileLog("Devices: " + JSON.stringify(device));
                        }
                    });

                    //FORCING BACK CAMERA ON CHROME MOBILE
                    for(var i=0; i<allVideoSources.length; i++){
                        if(allVideoSources[i].label.includes("back")){
                            videoSource=allVideoSources[i].deviceId;
                            if(this.props.onMobileLog)
                                this.props.onMobileLog("Setting " + allVideoSources[i].label + " as device");
                        }
                    }

                    if(this.props.onPromptSources){
                        this.setState({videoSource:videoSource,audioSource:audioSource});
                        this.props.onPromptSources(allVideoSources,videoSource);
                    }
                    sourceSelected(audioSource, videoSource);
                })
                    .catch((error) => {
                        console.log(`${error.name}: ${error.message}`); // eslint-disable-line no-console
                    });
            } else {
                MediaStreamTrack.getSources((sources) => {
                    let audioSource = null;
                    let videoSource = null;

                    let allVideoSources = [];

                    if(this.props.onMobileLog) {
                        this.props.onMobileLog("Coming into media stream track");

                        this.props.onMobileLog(JSON.stringify(sources));
                    }

                    sources.forEach((source) => {
                        if (source.kind === 'audio') {
                            audioSource = source.id;
                        } else if (source.kind === 'video' || source.kind =="videoinput") {
                            videoSource = source.id;
                            allVideoSources.push(source);
                        }
                    });

                    if(this.props.onPromptSources){
                        this.setState({videoSource:videoSource});
                        this.props.onPromptSources(allVideoSources,videoSource);
                    }

                    sourceSelected(audioSource, videoSource);
                });
            }
        }

        Webcam.userMediaRequested = true;
    }

    handleUserMedia=()=>{
        const video = document.querySelector('#webcamVideo');
        video.srcObject=this.stream;

        this.setState({hasUserMedia: true});
        this.props.onUserMedia();
    }

    getScreenshot=()=>{
        if (!this.state.hasUserMedia) return null;
        let canvas = this.getCanvas();
        return canvas.toDataURL(this.props.screenshotFormat);
    }

    getCanvas=()=>{
        if (!this.state.hasUserMedia) return null;

        const video = findDOMNode(this);
        if (!this.ctx) {
            let canvas = document.createElement('canvas');
            const aspectRatio = video.videoWidth / video.videoHeight;

            canvas.width = video.clientWidth;
            canvas.height = video.clientWidth / aspectRatio;

            this.canvas = canvas;
            this.ctx = canvas.getContext('2d');
        }

        const {ctx, canvas} = this;
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        return canvas;
    }

    render=()=>{
        return (
            <video id="webcamVideo"
                autoPlay
                width={this.props.width}
                height={this.props.height}
                muted={this.props.muted}
                className={this.props.className}
            />
        );
    }
}
Webcam.defaultProps = {
    audio: false,
    height: 480,
    width: 640,
    screenshotFormat: 'image/png',
    onUserMedia: () => {}
};
Webcam.propTypes = {
    audio: PropTypes.bool,
    muted: PropTypes.bool,
    onUserMedia: PropTypes.func,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    screenshotFormat: PropTypes.oneOf([
        'image/webp',
        'image/png',
        'image/jpeg'
    ]),
    className: PropTypes.string
};
Webcam.mountedInstances = [];
Webcam.userMediaRequested = false;