import React from 'react';
import moment from 'moment';
import u from '../utilities/Utilities';
import store from 'store';
import "../styles/task-report.scss";

export default class MisconductReport extends React.Component{
    state={
        content:{
            offenses:[],
            photos:[],
        },
        photos:[],
    }

    componentDidMount=()=>{
        const content = store.get("incident-report");
        const photos=this.imageArrayBreak(content.photos);
        this.setState({
          content:content,
          photos:photos,
        });
    }

    setDef=(value,type)=>{
        if(!value){
            return "None";
        }
        else if(type == "datetime"){
            return moment(value).format("DD MMM YYYY - h:mma");
        }
        else if(type == "date"){
            return moment(value).format("DD MMM YYYY");
        }
        else if(type == "time"){
            return moment("2000-01-01T" + value).format("h:mma");
        }
        else if(type == "dow"){
            const labels = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
            let dow = "";
            for(var i=0; i<value.length; i++){
                if(value[i]){
                    dow = dow + ((dow.length > 0) ? " / " : "") + labels[i];
                }
            }
            return dow;
        }

        return value;
    };

    imageArrayBreak=(array)=>{
        if(!array){
            return [];
        }
        const isEven = (array.length % 2 == 0);
        let twoArray = [];
        let row = [];
        for(var i=0; i<array.length; i++){
            row.push(array[i]);
            if(i%2 == 1){
                //new object
                twoArray.push(row);
                row = [];
            }
        }
        if(!isEven){
            row.push[{}];
            twoArray.push(row);
        }
        return twoArray;
    }

    render=()=>{
        const content=this.state.content;
        return(
          <div className="task-report-container">
              <img className="mwmd-header-image" src="/images/app/mwmd-icon.png"/>
              <div className="mwmd-title">Notice on Infringement of Dormitory House Rules</div>

              <div className="report-section">
                  <div className="report-section-title">Incident Details</div>
                  <div className="report-table">
                      <table>
                          <tbody>
                              <tr className="report-row-double">
                                <td><b>Dormitory</b></td>
                                <td>{this.setDef(content.dormName)}</td>
                                <td><b>Entry ID</b></td>
                                <td>{this.setDef(content.misconductID)}</td>
                              </tr>

                              <tr className="report-row-double">
                                <td><b>Prepared By</b></td>
                                <td>{this.setDef(content.createdByName)}</td>
                                <td><b>Prepared On</b></td>
                                <td>{this.setDef(content.createdOn,"datetime")}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              <div className="report-section">
                  <div className="report-section-title">Offender Details</div>
                  <div className="report-table">
                      <table>
                          <tbody>
                              <tr>
                                  <td className="report-item"><b>Organization</b></td>
                                  <td className="report-item">{this.setDef(content.clientName)}</td>
                              </tr>
                              <tr>
                                  <td className="report-item"><b>Resident</b></td>
                                  <td className="report-item">{this.setDef(content.residentName)}</td>
                              </tr>
                              <tr>
                                  <td className="report-item"><b>Resident FIN</b></td>
                                  <td className="report-item">{this.setDef(content.maskedResidentNRIC)}</td>
                              </tr>
                              <tr>
                                  <td className="report-item"><b>Offender Type</b></td>
                                  <td className="report-item">{this.setDef(content.offenderType)}</td>
                              </tr>
                              <tr>
                                  <td className="report-item"><b>Remarks</b></td>
                                  <td className="report-item">{this.setDef(content.remarks)}</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>

              {
                  (content.offenses.length > 0)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Offence Details</div>
                          <div className="report-table">
                              <table>
                                  <tbody>
                                      {
                                          content.offenses.map(
                                              (item,index)=>{
                                                  return (
                                                    <tr key={index}>
                                                        <td className="report-item"><b>{item.label}</b></td>
                                                        <td className="report-item">
                                                            <span style={{textDecoration:((item.status == "Active") ? undefined : "line-through")}}>({this.setDef(item.occurrence)}) {this.setDef(item.penalty)}</span>
                                                            {
                                                                (item.status == "Active")
                                                                    ?
                                                                    null
                                                                    :
                                                                    <span> (<span className="text-emphasis">{item.status}</span>)</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                  )
                                              }
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }

              {
                  (this.state.photos.length > 0)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Supporting Photos</div>
                          <div className={"report-table" + ((this.state.photos.length < 2) ? " half" : "")}>
                              <table>
                                  <tbody>
                                      {
                                          this.state.photos.map(
                                              (row,rowIndex)=>{
                                                return(
                                                    <tr key={rowIndex}>
                                                        {
                                                            row.map(
                                                                (item,index)=>{
                                                                    return(
                                                                        <td className="report-item" key={index}>
                                                                            <img src={item.url} className="report-image"/>
                                                                            <div className="report-image-name">{item.filename}</div>
                                                                        </td>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                )
                                              }
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }

              {
                  (content.acknowledgement)
                      ?
                      <div className="report-section">
                          <div className="report-section-title">Acknowledgement</div>
                          <div className="report-section-desc">
                              I acknowledge that, I have infringed the dormitory rules and regulations as indicated in the above report.
                              <br/><br/>
                              I understand the seriousness of the offence(s), and shall not repeat the offences(s) again to ensure a safe & hygienic living environment.
                              <br/><br/>
                              I acknowledge that any repeated infringement of the rules and regulations might lead to eviction from the dormitory, or the revokation of my work permit from the Ministry of Manpower (MOM).
                          </div>
                          <div className="report-table">
                              <table>
                                  <tbody>
                                      <tr>
                                          <td className="report-item"><b>Date:</b></td>
                                          <td className="report-item"/>
                                      </tr>
                                      <tr>
                                          <td className="report-item"><b>FIN No:</b></td>
                                          <td className="report-item"/>
                                      </tr>
                                      <tr>
                                          <td className="report-item"><b>Signature:</b></td>
                                          <td className="report-item" style={{height:"80px"}}/>
                                      </tr>
                                  </tbody>
                              </table>
                          </div>
                      </div>
                      :
                      null
              }
          </div>
        )
    }
}
