import React from 'react';
import PublicLayout from '../components/PublicPageLayout';
import {LoadGraphic} from '../components/Common';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import moment from 'moment';
import qs from 'query-string';
import u from '../utilities/Utilities';
import "../styles/visitor-card.scss";

export default class VisitorCard extends React.Component{

    state={
        content:{
            passValue:"none",
        },
        loading:true,
        loadText:"Checking token...",
        dimensions:{width:0,height:0},
    }

    componentDidMount=()=>{
        const query = qs.parse(this.props.location.search);
        if(typeof query.token == "undefined"){
            this.setState({loadText:"Error! Token not found"});
        }
        else{
            this.getContent(query.token);
        }
    }

    getContent=(token)=>{
        u.post({
            url:"/noauth/get-visitor-card",
            data:{
                token:token
            },
            success:(visitor)=>{
                this.setState({content:visitor,loading:false});
            },
            error:(error)=>{
                this.setState({loadText:error});
            }
        });
    }

    render=()=>{
        const content=this.state.content;
        const width = this.state.dimensions.width;
        return(
            <PublicLayout title="Visitor" url="/visitor-card">
                <div className="visitor-card-container">
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="visitor-card">
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="visitor-qr">
                                            <QRCode value={content.passValue}
                                                    level="M"
                                                    fgColor="#000"
                                                    size={width-20}
                                                    imageSettings={{
                                                        src:"/images/app/app-logo-small.png",
                                                        width:30,
                                                        height:30,
                                                        excavate:true,
                                                    }}
                                                    />
                                        </div>
                                    )
                            }
                        </Measure>

                        <div className="visitor-content">
                            <div className="visitor-details name"><b>{(content.name) ? content.name : "None"}</b></div>
                            <div className="visitor-details">{moment(content.validStart).format("DD MMM YY h:mma")} - {moment(content.validEnd).format("h:mma")}</div>
                            <div className="visitor-details">{(content.dormName) ? content.dormName : "None"}</div>
                            <div className="visitor-details">Visitor Pass</div>
                        </div>
                    </div>

                    <br/>

                    <div className="visitor-card-disclaimer">
                        <ul>
                          <li>Visitor card can be used to access turnstiles during the validity period</li>
                          <li>Scan the above QR Code to unlock turnstile for yourself only</li>
                          <li>Access will be restricted if you scan in multiple times without scanning out</li>
                          <li>If access is restricted due to anti-passback, unlock at the guardroom</li>
                        </ul>
                    </div>

                </div>
            </PublicLayout>
        )
    }
}
