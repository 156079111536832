import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,LoadGraphic,MobileMenu,AddListItem,Step,Dropzone} from '../components/Common';
import {Button,EditableBox,EditableArea,EditSizeLabel,EditSizeLink,TextButton,MiniMinusButton,Select,Box,EmptyBox,Date,Area,Time} from '../components/Input';
import u from '../utilities/Utilities';
import {Spreadsheet,Attachment} from '../data/Mimetypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {AddSearchTag,Tag} from '../components/OverviewList';
import '../styles/notification-list.scss';

export default class NotificationList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Notifications",
        permission:"notification",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/notification-list",

        /**** Action Setup ****/
        addType:1,
        addURL:"/api/create-notification-entry",
        //templateURL:"/api/get-add-staff-template",
        //bulkURL:"/api/add-bulk-staff",
        deleteURL:"/api/delete-notification-entries",
        spreadsheetURL:"/api/generate-notification-spreadsheet",
        overviewURL:"/api/get-all-notification",

        /**** List Setup ****/
        list:true,
        listLabel:"notification-list",
        orderByField:"publishDate",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{}
    };

    getOptions=()=>{
       u.post({
          url:"/api/get-options",
          data:{
              keys:["targetUser","notificationType"]
          },
          success:(options)=>{
              this.setState({options:options});
          },
          error:(error)=>{
              this.setState({error:error});
          }
       });
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("notification");
        const combined = u.getPermissions("combined");
        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add",ref:"add",mobileLabel:"Add Entry",image:"/images/page-menu/add.png",title:"Add new entry",enabled:(permissions.create==2)},
                {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
                {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Setup Announcement",ref:"announcement",title:"Setup announcement",enabled:true,onClick:()=>{window.location="/announcement-list"}},
            ]
        });
        this.getOptions();
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        const pageComponents={
            add:{component:AddNotification,props:{options:this.state.options}},
            delete:{component:DeleteListItem,props:{}},
            spreadsheet:{component:GenerateSpreadsheet,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}
                />

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                              <NotificationItem key={index}
                                                content={content}
                                                index={index}
                                                onChange={onChange}
                                                onContentChange={onContentChange}
                                                registerReference={registerRef}
                                                options={this.state.options}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class NotificationItem extends React.Component{

    state={
        error:"",
        step:0,
        permissions:{},
        loading:false,
        loadText:"",
        step:"view",

    };

    componentDidMount=()=>{
        this.setState({permissions:u.getPermissions("notification")});
        this.props.registerReference(this.props.index,this);
    }

    onMutatedChange=(value,label,index)=>{

    }

    onDownloadFile=(e)=>{
        this.setState({error:""});
        const index = parseInt(e.currentTarget.id);
        const target = this.props.content.attachments[index];
        /*u.download(target.filename,target.filestamp,undefined,(error)=>{
            this.setState({error:error});
        });*/
        window.open(target.filestamp,"_blank");
    }

    onTerminate=()=>{
        this.setState({loading:true,error:""});
        u.post({
            url:"/api/terminate-notification",
            data:{
                notificationID:this.props.content.notificationID
            },
            success:()=>{
                this.props.onChange("Terminated","status",this.props.index);
                this.setState({loading:false,step:"view"});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onPublish=()=>{
        this.setState({loading:true,loadText:"Firing notification..."});
        u.post({
            url:"/api/fire-notification",
            data:{
                notificationID:this.props.content.notificationID
            },
            success:()=>{
                this.setState({step:"publish-complete",loading:false});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onDownloadTarget=()=>{
        this.setState({error:""});
        const target = this.props.content.targetUser;
        u.download(target.filename,target.filestamp,undefined,(error)=>{
            this.setState({error:error});
        });
    }

    onDownloadLatest=()=>{
        this.setState({loading:true,loadText:"Downloading latest viewership..."});
        u.post({
            url:"/api/download-notification-viewership",
            data:{
                notificationID:this.props.content.notificationID
            },
            success:({filename,filestamp})=>{
                u.download(filename,filestamp,
                    ()=>{
                        this.setState({loading:false});
                        },
                    (error)=>{
                        this.setState({error:error,loading:false})
                    });
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    };

    render=()=>{
        const content=this.props.content;
        const editID=content.notificationID;
        const url = "/api/update-notification-info";
        const editPermission = (this.state.permissions.update == 2);

        return (
            <div style={{position:"relative"}}>
                <div className="page-error">{this.state.error}</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step == "view"}>
                    <div>
                        <div className="list-segment extended" style={{verticalAlign:"top"}}>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Reference:</div>
                                <div className="list-segment-input"><EditableBox label="Reference" values={{value:content.reference,enabled:editPermission}} field="reference" rules={{required:true}} id={editID} url={url} onChange={this.props.onChange} index={this.props.index+""}/></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Status:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.status}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Target Dormitory:</div>
                                <div className="list-segment-input"><EditSizeLabel>{content.targetDorm.label}</EditSizeLabel></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Target User:</div>
                                <div className="list-segment-input">
                                    <EditSizeLabel labelStyle={{padding:"2px 5px",maxHeight:"33px",overflow:"auto"}}>
                                        {
                                            (content.targetUser)
                                                ?
                                                <div>
                                                {
                                                    (Array.isArray(content.targetUser))
                                                        ?
                                                        content.targetUser.map(
                                                            (item,index)=>{
                                                                return (
                                                                    <Tag key={index}
                                                                         index={index + ""}
                                                                         clickable={false}
                                                                         label={item}
                                                                         onRemove={()=>{}}
                                                                         values={{removable:false}}
                                                                    />
                                                                )
                                                            }
                                                        )
                                                    :
                                                    (
                                                        (content.targetUser.filename)
                                                        ?
                                                        <TextButton onClick={this.onDownloadTarget} style={{margin:"4px 0"}}>{content.targetUser.filename}</TextButton>
                                                        :
                                                        <div style={{margin:"4px 0"}}><Link to={"/resident/"+content.targetUser.tenantID}>{content.targetUser.tenantName}</Link></div>
                                                    )
                                                }
                                                </div>
                                                :
                                                <div style={{margin:"4px 0"}}>{content.targetUserType}</div>
                                        }
                                    </EditSizeLabel>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Created By:</div>
                                <div className="list-segment-input"><EditSizeLink>{(content.creatingStaffID) ? <Link to={"/staff/"+content.creatingStaffID}>{content.creatingStaffName}</Link> : "System"} ({(content.creatingDateTime) ? moment(content.creatingDateTime).format("DD MMM YYYY - h:mma") : "None"})</EditSizeLink></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Publish On:</div>
                                <div className="list-segment-input"><EditSizeLabel>{(content.publishDate) ? moment(content.publishDate).format("DD MMM YYYY") : "None"} - {content.publishType}</EditSizeLabel></div>
                            </div>
                        </div>

                        <div className="list-segment extended" style={{verticalAlign:"top"}}>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Viewership:</div>
                                <div className="list-segment-input">
                                    <EditSizeLabel nullText="None">
                                        <span title="Viewed In Inbox">{content.targetViews}</span>
                                        <span title="Installed App - Received Notification"> / {content.targetActiveCapacity}</span>
                                        <span title="Total Potential Users"> / {content.targetMaxCapacity}</span>
                                    </EditSizeLabel>
                                </div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Subject:</div>
                                <div className="list-segment-input"><EditableBox label="Subject" values={{value:content.subject,enabled:editPermission}} field="subject" rules={{required:true}} id={editID} url={url} onChange={this.props.onChange} index={this.props.index+""}/></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Message:</div>
                                <div className="list-segment-input"><EditableArea labelStyle={{whiteSpace:"pre-wrap"}} label="Message" values={{value:content.message,enabled:editPermission}} field="message" rules={{required:true,rows:6}} id={editID} url={url} onChange={this.props.onChange} index={this.props.index+""}/></div>
                            </div>
                            <div className="list-segment-item">
                                <div className="list-segment-label">Attachments:</div>
                                <div className="list-segment-input">
                                    <EditSizeLabel nullText="None">
                                        {
                                            (content.specialType == null)
                                                ?
                                                (content.attachments.length > 0)
                                                  ?
                                                  content.attachments.map(
                                                      (item,index)=>{
                                                          return <div key={index} className="text-emphasis" style={{marginBottom:"5px",cursor:"pointer"}} id={index+""} onClick={this.onDownloadFile}>{item.userFilename}</div>
                                                      }
                                                  )
                                                  :
                                                  "None"
                                                :
                                                "None"
                                        }
                                    </EditSizeLabel>
                                </div>
                            </div>
                        </div>

                        <div className="list-segment-buttons">
                        {
                            (content.status == "Pending" && editPermission)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"terminate"})}}>Terminate</Button>
                                :
                                null
                        }
                        {
                            (content.publishType == "Fire On Demand" && editPermission)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"publish"})}}>Fire Now</Button>
                                :
                                null
                        }
                            <Button type='medium' onClick={this.onDownloadLatest}>Download Viewership</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "terminate"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-negative">Confirm Terminate Notification?</h2>
                        <div className="list-segment-note">Notifications have not been sent, terminating will stop this notification from firing on {moment(content.publishDate + "T" + content.publishTime).format("DD MMM - h:mma")}</div>

                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button type="medium" onClick={this.onTerminate}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "publish"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-emphasis">Confirm Fire Notification?</h2>
                        <div className="list-segment-note">Notification will be sent to all targets immediately</div>

                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button type="medium" onClick={this.onPublish}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "publish-complete"}>
                    <div style={{textAlign:"center",padding:"20px 0"}}>
                        <h2 className="text-emphasis">Notification Successfully Fired</h2>

                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }

}
NotificationItem.propTypes={
    registerReference:PropTypes.func.isRequired
};

class AddNotification extends React.Component{

    state={
        error:"",
        entryData:{
            targetDorm:{},
            attachments:[],
            publishType:"Fire On Demand",
            publishDate:moment().format("YYYY-MM-DD"),
            publishTime:moment().format("HH:mm:ss"),
            tags:[],
        },
        dormOptions:[],
        publishTypeOptions:[
            {value:"now",label:"Immediately"},
            {value:"later",label:"Later"},
        ],
        step:"add",
        loading:false,
        loadText:"",
    };

    componentDidMount=()=>{
        this.getDormOptions();
    }

    show=(show)=>{
        this.refs['add'].show(show);
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm",
            },
            success:(callback)=>{
                callback.unshift({value:"All",label:"All"});
                this.setState({dormOptions:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        let entryData = this.state.entryData;
        if(label=="targetDorm"){
            entryData.targetDorm={value:value,label:option.label};
        }
        else{
            entryData[label] = value;
        }
        this.setState({entryData:entryData});
    }

    onDropzoneError=(error)=>{
        this.setState({error:error});
    }

    onGoToAddAttachment=()=>{
        this.setState({step:"upload",uploadType:"attachment",error:""});
    }

    onRemoveAttachment=(index)=>{
        let entryData = this.state.entryData;
        entryData.attachments.splice(index,1);
        this.setState({entryData:entryData});
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    goToStep=(step)=>{
        this.setState({step:step,error:""});
    }

    onFileDropped=(file)=>{
        this.setState({loading:true,loadText:"Checking file...",currentFile:file});
    }

    onUploadProgress=(progress)=>{
        this.setState({loading:true,loadText:"Uploading..." + progress + "%"});
    }

    onUploadComplete=(filename,fileIcon,type)=>{
        let entryData=this.state.entryData;
        if(this.state.uploadType == "attachment"){
            entryData.attachments.push({userFilename:this.state.currentFile,filename:filename,fileIcon:fileIcon,type:type});
        }
        else if(this.state.uploadType == "tenants"){
            entryData.targetSpreadsheet={userFilename:this.state.currentFile,filename:filename};
        }
        this.setState({step:"add",error:"",loading:false,entryData:entryData});
    }

    onAdd=()=>{
        this.setState({error:""});
        const datarefs = ["reference","subject","message"];
        for(var i=0; i<datarefs.length; i++){
            const d = this.refs[datarefs[i]].validate();
            if(!d.valid){
                this.setState({error:d.value});
                return 0;
            }
        }
        const entryData=this.state.entryData;
        if(entryData.publishType != "Fire On Demand" && entryData.publishType != "Fire Now"){
            //Must be later than 1hr from now
            if(moment().add(1,"hours").isAfter(entryData.publishDate+"T"+entryData.publishTime)){
                this.setState({error:"Publish date/time must be at least 1 hour from now"});
                return 0;
            }
        }

        if(entryData.targetUsers == "Target Residents"){
            const permit = this.refs['searchParam'].validate();
            if(!permit.valid){
                this.setState({error:permit.value});
                return 0;
            }
        }

        //Remove icons from attachments;
        for(var i=0; i<entryData.attachments.length; i++){
            if(entryData.attachments[i].fileIcon){
                delete entryData.attachments[i].fileIcon;
            }
        }

        this.refs['add'].onValidated(entryData);
    }

    onAddComplete=()=>{
        const dorm = {value:this.state.dormOptions[0].value,label:this.state.dormOptions[0].label}
        this.setState({
            entryData:{
                message:"",
                subject:"",
                reference:"",
                targetDorm:dorm,
                attachments:[],
                publishDate:moment().format("YYYY-MM-DD"),
                publishTime:moment().format("HH:mm:ss"),
                publishType:"Fire On Demand",
                tags:[],
            }
        });
    }

    onChangeTags=(tags)=>{
        let entryData=this.state.entryData;
        entryData.tags = tags;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const content=this.state.entryData;
        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onAddComplete}
                         inList={this.props.inList}>

                <div className="segment-error">{this.state.error}</div>

                <Step active={(this.state.step == "add")}>
                    <div style={{position:"relative"}}>
                        <h3>Complete the fields to create the notification</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Reference:</div>
                            <div className="segment-item-input"><Box ref="reference" label="Reference" field="reference" values={{value:content.reference}} rules={{required:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Publish:</div>
                            <div className="segment-item-input"><Select ref="publishType" label="Type" field="publishType" values={{value:content.publishType,options:this.props.options.notificationType}} onChange={this.onChange}/></div>
                        </div>

                        {
                            (content.publishType != "Fire On Demand" && content.publishType != "Fire Now")
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Publish Date:</div>
                                <div className="segment-item-input"><Date ref="publishDate" label="Publish Date" field="publishDate" values={{value:content.publishDate}} range={[1,2]} rules={{minDate:moment().format("YYYY-MM-DD")}} onChange={this.onChange}/></div>
                            </div>
                            :
                            ""
                        }

                        {
                            (content.publishType != "Fire On Demand" && content.publishType != "Fire Now")
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Publish Time:</div>
                                <div className="segment-item-input"><Time ref="publishTime" label="Publish Time" field="publishTime" values={{value:content.publishTime}} rules={{skipSeconds:true}} onChange={this.onChange}/></div>
                            </div>
                            :
                            ""
                        }

                        <div className="segment-full-item">
                            <div className="segment-item-label">Dormitory:</div>
                            <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="targetDorm" values={{value:content.targetDorm.value,options:this.state.dormOptions}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Target User Type:</div>
                            <div className="segment-item-input"><Select ref="targetUserType" label="User" field="targetUserType" values={{value:content.targetUserType,options:this.props.options.targetUser}} onChange={this.onChange}/></div>
                        </div>

                        {
                            (content.targetUserType == "Target Residents")
                                ?
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Permit/FIN/NRIC:</div>
                                    <div className="segment-item-input"><Box ref="searchParam" label="Permit" field="searchParam" rules={{required:true}} values={{value:content.searchParam}} onChange={this.onChange}/></div>
                                </div>
                                :
                                ""
                        }

                        {
                            (content.targetUserType == "Target Search Tags")
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Search Tags:</div>
                                <div className="segment-item-input">
                                    <EmptyBox onClick={()=>{this.setState({step:"add-tag"})}} style={{padding:"0 5px",cursor:"pointer"}}>
                                        {
                                            (content.tags.length > 0)
                                                ?
                                                content.tags.map(
                                                    (tag, index)=> {
                                                        return (
                                                            <Tag key={index}
                                                                 index={index + ""}
                                                                 clickable={true}
                                                                 label={tag}
                                                                 onRemove={()=>{}}
                                                                 values={{removable:false}}
                                                                />
                                                        )
                                                    }
                                                )
                                                :
                                                <div style={{padding:"5px 0",color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                        }
                                    </EmptyBox>
                                </div>
                            </div>
                            :
                            ""
                        }

                        {
                            (content.targetUserType == "Target Spreadsheet")
                            ?
                            <div className="segment-full-item">
                                <div className="segment-item-label">Target Spreadsheet:</div>
                                <div className="segment-item-input">
                                    <EmptyBox onClick={()=>{this.setState({step:"upload",uploadType:"tenants"})}} style={{padding:"5px",cursor:"pointer"}}>
                                        {
                                                (content.targetSpreadsheet)
                                                    ?
                                                    content.targetSpreadsheet.userFilename
                                                    :
                                                    <div style={{color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                        }
                                    </EmptyBox>
                                </div>
                            </div>
                            :
                            ""
                        }

                        <div className="segment-full-item">
                            <div className="segment-item-label">Subject:</div>
                            <div className="segment-item-input"><Box ref="subject" label="Subject" field="subject" values={{value:content.subject}} rules={{required:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Message:</div>
                            <div className="segment-item-input"><Area ref="message" label="Message" field="message" values={{value:content.message}} rules={{required:true, rows:7, retainLineBreak:true}} onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Attachments:</div>
                            <div className="segment-item-input">
                                {
                                    (content.attachments.length > 0)
                                        ?
                                        content.attachments.map(
                                            (photo,index)=>{
                                                return (
                                                    <EmptyBox key={index} style={{position:"relative",margin:"0 0 5px 0"}}>
                                                        <div>
                                                            <div style={{position:"absolute",width:"20px",height:"20px",top:"8px",left:"-30px"}}><MiniMinusButton index={index+""} onClick={this.onRemoveAttachment}/></div>
                                                            <img className="add-photo-preview" src={photo.fileIcon}/>
                                                            <div className="add-photo-name">{photo.userFilename}</div>
                                                        </div>
                                                    </EmptyBox>
                                                )
                                            }
                                        )
                                        :
                                        <EmptyBox style={{padding:"5px"}}>None</EmptyBox>
                                }
                                <div><TextButton style={{margin:"0",fontSize:"0.9em"}} onClick={this.onGoToAddAttachment}>Add Attachment</TextButton></div>
                            </div>
                        </div>

                        <div className="segment-buttons">
                            <div className="page-error">{this.state.error}</div>
                            <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                            <Button type="medium" onClick={this.onAdd}>Create</Button>
                        </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "upload")}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Dropzone label="Attachments" accessibleForPreview={false} onError={this.onDropzoneError} onAdded={this.onFileDropped} onProgress={this.onUploadProgress} onUploaded={this.onUploadComplete} filetypes={(this.state.uploadType=="attachment") ? Attachment : Spreadsheet}
                              dragAndDropLabel={(this.state.uploadType == "attachment") ? undefined : "Accepts spreadsheets generated from resident list, ensure column ID exists"}
                    />
                    <div className="segment-buttons">
                        <Button index="add" type="medium" onClick={this.goToStep}>Back</Button>
                    </div>
                </Step>

                <Step active={(this.state.step == "add-tag")}>
                    <div style={{padding:"10px 0 30px 0"}}>
                        <h3>Add search tags below</h3>
                        <div style={{width:"800px", maxWidth:"96%",fontSize:"0.85em",margin:"20px auto",textAlign:"center"}}>You do not need to add in tags @status(!Check Out), @dorm() & @app(Registered) as these tags are automatically added into every survey</div>
                        <AddSearchTag listLabel="resident-list" tags={content.tags} onChange={this.onChangeTags} />
                        <div className="segment-buttons">
                            <Button index="add" type="medium" onClick={this.goToStep}>Done</Button>
                        </div>
                    </div>
                </Step>

            </AddListItem>
        )
    }

}
