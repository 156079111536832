import React from 'react';
import store from 'store';
import u from '../utilities/Utilities';
import AppLayout from '../components/AppLayout';
import {Databox,Segment,Step,LoadGraphic,UploadPhoto,Dropzone,SegmentComplete} from '../components/Common';
import {Date,Select,Button,TextButton,Info} from '../components/Input';
import {Spreadsheet} from '../data/Mimetypes';
import {EstateUtilities} from './Estate';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import {Link} from 'react-router-dom';
import "../styles/dashboard.scss";
import PropTypes from 'prop-types';
import moment from 'moment';

export default class Dashboard extends React.Component{

    state={
        action:"No Permission",
        actionLabel:"",
        dormOptions:[],
        latestEvents:[],
        name:u.getFullName(),
        avatar:u.getUserAvatar(),
        quickSelected:"",
        quickActions:[],
        rfid:"",
        dimensions:{width:0,height:0},
    };

    componentDidMount=()=>{
        const dashboardPermissions = u.getPermissions("dashboard");
        let dashboardCount=0;
        for(var key in dashboardPermissions){
            if(dashboardPermissions[key]){
                dashboardCount++;
            }
        }
        this.setState({
            rfid:store.get("staffRFID"),
            quickActions:[
                {label:"Meal Type",component:MealType,ref:"mealType",image:"/images/dashboard/food.png",permission:(u.getPermissions("combined").mealType >= 1)},
                {label:"Go To Room",component:GoToRoom,ref:"goToRoom",image:"/images/dashboard/go-to-room.png",permission:(u.getPermissions("estate").view >= 1)},
                {label:"Dashboards",component:CustomDashboard,ref:"customDashboard",image:"/images/dashboard/custom-dashboard.png",permission:(dashboardCount>0)},
                {label:"Vitals",component:VitalsDashboard,ref:"vitalsDashboard",image:"/images/dashboard/vitals-dashboard.png",permission:(u.getPermissions("resident").view >= 1)},
                {label:"Dorm QRCode",component:PrintDorm,ref:"printDorm",image:"/images/dashboard/print-dorm.png",permission:(u.getPermissions("resident").view >= 1)},
                {label:"Profile Pic",component:ProfilePic,ref:"profilePic",image:"/images/dashboard/upload-avatar.png",permission:true,onComplete:this.onNewProfile},
                {label:"Logout",ref:"logout",image:"/images/dashboard/logout.png",permission:true},
            ]
        });

        const action = store.get("dashboard-action");
        const actionLabel = store.get("dashboard-action-label");
        store.remove("dashboard-action");
        store.remove("dashboard-action-label");
        this.setState({action:action,actionLabel:actionLabel});
        this.getDormOptions();
        this.getLatestEvents();
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm"
            },
            success:(callback)=>{
                let dormWithEmpty = callback.slice(0);
                dormWithEmpty.unshift({value:"-1",label:"Select a dormitory"});
                this.setState({dormOptions:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getLatestEvents=()=>{
        u.post({
            url:"/api/get-dashboard-history",
            data:{},
            success:(callback)=>{
                this.setState({latestEvents:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onQuickAction=(e)=>{
        const index = parseInt(e.currentTarget.id);
        const target=this.state.quickActions[index];

        if(target.ref=="logout"){
            u.logout();
            return 0;
        }

        if(target.ref=="myEvents"){
            store.set("history-list","@staff("+u.getFullName()+")");
            window.open("/history-list","_blank");
            return 0;
        }

        if(target.ref == this.state.quickSelected){
            this.setState({quickSelected:"none"});
        }
        else{
            this.setState({quickSelected:target.ref});
        }
    }

    onCancelQuickAction=()=>{
        this.setState({quickSelected:""});
    }

    onNewProfile=(link)=>{
        store.set("avatarLink",link);
        this.setState({avatar:link,quickSelected:"none"});
    }

    render=()=>{
        const { width, height } = this.state.dimensions;

        return (
            <AppLayout settings={{title:"Dashboard",permission:"none",url:"/dashboard"}}>

                {
                    (this.state.action == "No Permission")
                        ?
                        <div style={{textAlign:"center"}} className="text-negative">No permission to access {this.state.actionLabel}. Please request for the permission from your system administrator</div>
                        :
                        ""
                }

                <Databox active={true} align="center" margin={"30px auto"}>
                    <img src={u.getAvatarLink(this.state.avatar)} style={{width:"100px",maxWidth:"50%",margin:"auto",borderRadius:"50%"}}/>

                    <h2 className="theme-emphasis">Welcome Back, {this.state.name}</h2>

                    <div className="dashboard-qr">
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="dashboard-qr-image">
                                            <QRCode value={this.state.rfid}
                                                    level="H"
                                                    fgColor="#000"
                                                    size={width}
                                                    imageSettings={{
                                                        src:"/images/app/app-logo-small.png",
                                                        width:30,
                                                        height:30,
                                                        excavate:true,
                                                    }}
                                                    />
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="pending-tasks">
                        <h3>My Latest Events</h3>
                        <div style={{width:"750px",maxWidth:"95%",margin:"auto",border:"1px solid #f4f4f4",borderRadius:"5px"}}>
                        {
                            (this.state.latestEvents.length > 0)
                                ?
                                <div>
                                    <div style={{maxHeight:"200px",overflow:"auto"}}>
                                    {
                                        this.state.latestEvents.map(
                                            (item,index)=>{
                                                const isToday = (moment().diff(item.dateTime,"days") == 0);
                                                return (
                                                    <div key={index} className="pending-task-item">
                                                        <div className="pending-task-event">
                                                            <span>{u.capitalize(item.action,true)} </span>
                                                            <span>{(item.targetLink==null) ? item.targetName : <Link to={item.targetLink} id={item.targetLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.targetName}</Link>}</span>
                                                            <span>{(item.targetField==null) ? "" : " (" + item.targetField + ")"}</span>
                                                            <span>{(item.from == null) ? "" : ((item.fromLink == null) ? " from "+item.from : <span> from <Link to={item.fromLink} id={item.fromLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.from}</Link></span>)}</span>
                                                            <span>{(item.to == null) ? "" : ((item.toLink == null) ? " to "+item.to : <span> to <Link to={item.toLink} id={item.toLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.to}</Link></span>)}</span>
                                                        </div>
                                                        <div className="pending-task-date">
                                                            {moment(item.dateTime).format((isToday) ? "h:mma" : "DD MMM YY")}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    </div>
                                </div>
                                :
                                <div className="pending-task-empty">No events performed</div>
                        }
                        </div>
                    </div>

                    <div className="quick-actions">
                        <h3>Quick Actions</h3>
                        <div>
                            {
                                this.state.quickActions.map(
                                    (item,index)=>{
                                        if(!item.permission){
                                            return null;
                                        }
                                        return (
                                            <div className="quick-action-item" key={index} id={index+""} onClick={this.onQuickAction}>
                                                <img src={item.image}/>
                                                <div>{item.label}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>

                        <div style={{width:"1280px",maxWidth:"98%",margin:"auto"}}>
                            <div className="quick-action-content">
                                {
                                    this.state.quickActions.map(
                                        (item,index)=>{
                                          if(typeof item.component == "undefined"){
                                              return (<div key={index}/>)
                                          }
                                          return React.createElement(
                                              item.component,
                                              {
                                                  key:index,
                                                  active:(this.state.quickSelected == item.ref),
                                                  onCancel:this.onCancelQuickAction,
                                                  onComplete:item.onComplete,
                                                  dormOptions:this.state.dormOptions,
                                                  ref:item.ref,
                                              }
                                        );
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Databox>
            </AppLayout>
        )
    }
}

class GoToRoom extends React.Component{

    state={
        error:"",
        options:{
            clusterOptions:[],
            buildingOptions:[],
            levelOptions:[],
            roomOptions:[],
        },
        config:{},
    };

    componentDidMount=()=>{
        this.setState({
            config:EstateUtilities.getDormConfig()
        });
    }

    getEstateOptions=(type,id)=>{
        const nextType = EstateUtilities.getNextType(type);
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:nextType,
                id:id
            },
            success:(callback)=>{
                const config = this.state.config;
                let options = this.state.options;
                callback.unshift({value:"none",label:"Select " + config[nextType+"Single"]});
                options[nextType+"Options"] = callback;

                if(nextType == "cluster"){
                    options.buildingOptions = [{value:"none",label:"Select " + config.buildingSingle}];
                    options.levelOptions = [{value:"none",label:"Select " + config.levelSingle}];
                    options.roomOptions = [{value:"none",label:"Select " + config.roomSingle}];
                }
                else if(nextType == "building"){
                    options.levelOptions = [{value:"none",label:"Select " + config.levelSingle}];
                    options.roomOptions = [{value:"none",label:"Select " + config.roomSingle}];
                }
                else if(nextType == "level"){
                    options.roomOptions = [{value:"none",label:"Select " + config.roomSingle}];
                }

                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,option)=>{
        if(value != "none" && value != "all"){
            this.getEstateOptions(label,value);
        }
    }

    onSubmit=()=>{
        this.setState({error:""});
        const refs=["dorm","cluster","building","level","room"];
        let location = {};

        for(var i=0; i<refs.length; i++){
            const item = (this.refs[refs[i]]) ? this.refs[refs[i]].validate() : {valid:true};
            if(!item.valid){
                this.setState({error:item.value});
                return 0;
            }
            location[refs[i]]=item.value;
        }

        window.open("/estate-list/room/"+location.room,"_blank");
    }

    render=()=>{
        const config = this.state.config;
        const options = this.state.options;

        return(
            <Segment active={this.props.active} align="center">
                <div className="dashboard-segment">
                    <h3>Select a Room</h3>
                    <div className="segment-full-item">
                        <div className="segment-item-label">Dormitory</div>
                        <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="dorm" rules={{required:true}} values={{options:this.props.dormOptions}} onChange={this.onChange}/></div>
                    </div>

                    {
                      (config.cluster)
                        ?
                        <div className="segment-full-item">
                            <div className="segment-item-label">{config.clusterSingle}:</div>
                            <div className="segment-item-input"><Select ref="cluster" label={config.clusterSingle} field="cluster" rules={{required:true}} values={{options:options.clusterOptions}} onChange={this.onChange}/></div>
                        </div>
                        :
                        null
                    }

                    {
                      (config.building)
                        ?
                        <div className="segment-full-item">
                            <div className="segment-item-label">{config.buildingSingle} :</div>
                            <div className="segment-item-input"><Select ref="building" label={config.buildingSingle}  field="building" rules={{required:true}} values={{options:options.buildingOptions}} onChange={this.onChange}/></div>
                        </div>
                        :
                        null
                    }

                    <div className="segment-full-item">
                        <div className="segment-item-label">{config.levelSingle} :</div>
                        <div className="segment-item-input"><Select ref="level" label={config.levelSingle}  field="level" rules={{required:true}} values={{options:options.levelOptions}} onChange={this.onChange}/></div>
                    </div>

                    <div className="segment-full-item">
                        <div className="segment-item-label">{config.roomSingle} :</div>
                        <div className="segment-item-input"><Select ref="room" label={config.roomSingle}  field="room" rules={{required:true}} values={{options:options.roomOptions}} onChange={this.onChange}/></div>
                    </div>

                    <div className="segment-error">{this.state.error}</div>

                    <div className="segment-buttons">
                        <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="medium" onClick={this.onSubmit}>Go</Button>
                    </div>
                </div>
            </Segment>
        )
    }
}

class ProfilePic extends React.Component{

    id=u.getUserID();

    state={
        active:false
    };

    static getDerivedStateFromProps(props,state){
        if(props.active != state.active){
            state.active=props.active;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            this.refs["photo"].show(this.props.active);
            this.setState({update:false});
        }
    }

    onToggleForm=()=>{
        this.props.onCancel();
    }

    onComplete=(link)=>{
        //Commit to store, refresh page image
        this.props.onComplete(link);
    }

    render=()=>{
        return(
            <div className="dashboard-segment">
                <UploadPhoto ref="photo" reference="photo" id={this.id}
                             url={"/api/commit-staff-avatar"} fieldname="avatarLink" onUpload={this.onComplete}
                             onToggleForm={this.onToggleForm} noTitle={true}/>
            </div>
        )
    }
}

class PrintDorm extends React.Component{

    state={
        error:"",
    };

    onSubmit=()=>{
        const dormID = this.refs['dorm'].validate();
        if(!dormID.valid){
            this.setState({error:dormID.value});
            return 0;
        }
        store.set("print-dorm",{dormID:dormID.value,dormName:dormID.option.label});
        window.open("/print-dorm","_blank","width=800,height=800,left=200,top=100");
    }

    render=()=>{
        return(
            <Segment active={this.props.active} align="center">
                <div className="dashboard-segment" style={{padding:"30px 0"}}>
                    <h3>Select the dormitory to print</h3>
                    <div className="dashboard-segment-text">Print respectives dormitory's QRCode used for Resident's to Login to Smartdorm Mobile App</div>
                    <div className="page-error">{this.state.error}</div>
                    <div className="segment-full-item" style={{padding:"20px 0"}}>
                        <div className="segment-item-label">Dormitory</div>
                        <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="dorm" rules={{required:true}} values={{options:this.props.dormOptions}}/></div>
                    </div>
                    <div className="segment-buttons">
                        <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="medium" onClick={this.onSubmit}>Go</Button>
                    </div>
                </div>
            </Segment>
        )
    }
}

class VitalsDashboard extends React.Component{

    state={
        error:"",
    };

    onSubmit=()=>{
        const dormID = this.refs['dorm'].validate();
        if(!dormID.valid){
            this.setState({error:dormID.value});
            return 0;
        }
        window.open("/vitals-dashboard/"+dormID.value,"_blank");
    }

    render=()=>{
        return(
            <Segment active={this.props.active} align="center">
                <div className="dashboard-segment" style={{padding:"30px 0"}}>
                    <h3>Select the dormitory to monitor</h3>
                    <div className="dashboard-segment-text">View real time information about critical patients and compliance on vital submissions</div>
                    <div className="page-error">{this.state.error}</div>
                    <div className="segment-full-item" style={{padding:"20px 0"}}>
                        <div className="segment-item-label">Dormitory</div>
                        <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="dorm" rules={{required:true}} values={{options:this.props.dormOptions}}/></div>
                    </div>
                    <div className="segment-buttons">
                        <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="medium" onClick={this.onSubmit}>Go</Button>
                    </div>
                </div>
            </Segment>
        )
    }
}

class MealType extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
    };

    onDownload=()=>{
        this.setState({loading:true,loadText:"Preparing..."});
        const dormID = this.refs['dormID'].validate();
        if(!dormID.valid){
            this.setState({error:dormID.value});
            return 0;
        }
        u.post({
            url:"/api/download-meal-type-template",
            data:{
                dormID:dormID.value,
            },
            success:({filename,filestamp})=>{
                this.filename=filename;
                this.filestamp=filestamp;
                this.download(this.filename,this.filestamp);
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    download=(filename,filestamp)=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.download(filename,filestamp,
          ()=>{
            this.setState({loading:false,step:"download",error:""});
        },(error)=>{
            this.setState({loading:false,error:error});
        });
    }

    onUploaded=(url)=>{
        this.setState({loadText:"Processing..."});
        u.post({
            url:"/api/upload-meal-type-template",
            data:{
                userFilename:this.filename,
                filename:url
            },
            success:()=>{
                this.setState({step:"upload",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onCancel=()=>{
        this.setState({step:"view",error:""});
        this.props.onCancel();
    }

    render=()=>{
        return(
            <Segment active={this.props.active}>
                <div className="dashboard-segment" style={{position:"relative",textAlign:"center"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step == "view"}>
                        <h3>Meal Types</h3>

                        <div className="dashboard-segment-text">
                            Upon download, all active residents<Info style={{width:"13px",height:"13px",verticalAlign:"top"}} title="<b>Active Resident</b><br/>Any resident who is not in the checked-out state is considered to be active"/> in the selected dormitory will be generated into the spreadsheet along with
                            any relevant data that has been uploaded previously. If the resident is checked-out, his entry will not be included in the spreadsheet
                        </div>
                        <div style={{margin:"30px auto"}}>
                              <div className="segment-full-item">
                                  <div className="segment-item-label">Dormitory:</div>
                                  <div className="segment-item-input">
                                      <Select ref="dormID" label="Dormitory" values={{options:this.props.dormOptions}} rules={{required:true}}/>
                                  </div>
                              </div>
                        </div>

                        <div>
                            <Dropzone label="meal-type"
                                      accessibleForPreview={false}
                                      onAdded={(userfilename)=>{this.setState({loading:true,loadText:"Uploading..."}); this.userfilename=userfilename;}}
                                      onUploaded={this.onUploaded}
                                      onError={(error)=>{this.setState({loading:false,error:error})}}
                                      onProgress={(progress)=>{this.setState({loadText:"Uploading..." + progress + "%"})}}
                                      filetypes={Spreadsheet}
                                      buttonOnly={true}>
                                      Upload Spreadsheet
                           </Dropzone>

                           <div className="dashboard-segment-text-small">Note: Upon upload, existing data will be overwritten hence this step cannot be undone</div>
                        </div>

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={this.props.onCancel}>Cancel</Button>
                            <Button onClick={this.onDownload}>Download</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step == "download"}>
                        <div className="segment-content" style={{padding:"30px 0 10px 0"}}>
                            <h3>Download Has Started</h3>
                            <div className="segment-note"><i>Note: If your download does not automatically start, please click <span className="segment-link" onClick={
                              ()=>{
                                this.download(this.filename,this.filestamp);
                              }}>here</span></i></div>
                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <Button type="medium" onClick={this.onCancel}>Close</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step=="upload"}>
                        <div style={{padding:"30px 0 10px 0"}}>
                            <SegmentComplete onComplete={this.onCancel}>File has been successfully uploaded</SegmentComplete>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}

class CustomDashboard extends React.Component{

    state={
        error:"",
        dashboardOptions:[],
    };

    componentDidMount=()=>{
        u.post({
            url:"/api/get-custom-dashboards",
            data:{},
            success:(dashboardOptions)=>{
                this.setState({dashboardOptions:dashboardOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onSubmit=()=>{
        this.setState({error:""});
        const dormID = this.refs['dorm'].validate();
        if(!dormID.valid){
            this.setState({error:dormID.value});
            return 0;
        }
        const dashboard = this.refs['dashboard'].validate();
        if(!dashboard.valid){
            this.setState({error:dashboard.value});
            return 0;
        }

        if(dashboard.value == "indirect"){
            window.open(dashboard.option.indirect[dormID.value]+"/"+dormID.value,"_blank");
        }
        else{
            window.open(dashboard.value+"/"+dormID.value,"_blank");
        }
    }

    render=()=>{
        return(
            <Segment active={this.props.active} align="center">
                <div className="dashboard-segment" style={{padding:"30px 0"}}>
                    <h3>Select the dormitory and dashboard</h3>
                    <div className="dashboard-segment-text">Open real time dashboards to view an overview of the respective dormitory</div>
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-full-item" style={{padding:"20px 0 0 0"}}>
                        <div className="segment-item-label">Dormitory</div>
                        <div className="segment-item-input"><Select ref="dorm" label="Dormitory" field="dorm" rules={{required:true}} values={{options:this.props.dormOptions}}/></div>
                    </div>
                    <div className="segment-full-item">
                        <div className="segment-item-label">Dashboard</div>
                        <div className="segment-item-input"><Select ref="dashboard" label="Dashboard" field="dashboard" rules={{required:true}} values={{options:this.state.dashboardOptions}}/></div>
                    </div>
                    <div className="segment-buttons">
                        <Button type="medium" onClick={this.props.onCancel}>Cancel</Button>
                        <Button type="medium" onClick={this.onSubmit}>Go</Button>
                    </div>
                </div>
            </Segment>
        )
    }
}
