import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,Dropzone,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Select,Box,TextButton,Info,Input} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Photo} from "../data/Mimetypes";
import {Link} from 'react-router-dom';
import store from 'store';

export default class FormList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Forms",
        permission:"task",
        url:"/form-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-form-entry",
        deleteURL:"/api/delete-form-entries",
        spreadsheetURL:"/api/generate-form-spreadsheet",
        overviewURL:"/api/get-all-form-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"form-list",
        orderByField:"createdOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)},
        onClosePopup:()=>{this.refs['layout'].onClosePop()},
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
        options:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Form",ref:"add",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
              {label:"Setup Scheduler",ref:"scheduler",title:"Configured scheduler",enabled:(permissions.create==2),onClick:this.onScheduler},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    onScheduler=()=>{
        window.location = "/scheduler-list";
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <FormItem key={index}
                                          content={content}
                                          index={index}
                                          onChange={onChange}
                                          onContentChange={onContentChange}
                                          registerReference={registerRef}
                                          options={this.state.options}
                                          permissions={this.state.permissions}
                                          settings={this.settings}
                                          />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class FormItem extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
        tempField:{
            dataType:"select",
            options:[],
        },
        editIndex:-1,
    }

    onAddField=()=>{
        this.setState({
          tempField:{
              dataType:"select",
              label:"Ask your question here",
              options:["Option 1","Option 2"],
          },
          editIndex:-1,
          step:"field",
          error:""
        });
    }

    onEditField=(index)=>{
        let tempField=Object.assign({},this.props.content.fields[index])
        tempField.required= (tempField.rules) ? tempField.rules.required : 0;
        tempField.options = (tempField.options) ? tempField.options.map((item)=>{return item}) : [];

        this.setState({
          tempField:tempField,
          editIndex:index,
          step:"field",
          error:""
        });
    }

    onRemoveField=(index)=>{
        const remove=()=>{
            this.setState({loading:true,loadText:"Removing"});
            u.post({
                url:"/api/form-remove-field",
                data:{
                    formID:this.props.content.formID,
                    field:this.props.content.fields[index]
                },
                success:()=>{
                    let fields = this.props.content.fields;
                    fields.splice(index,1);
                    this.props.onChange(fields,"fields",this.props.index);
                    this.setState({loading:false,error:""});
                },
                error:(error)=>{
                    this.setState({error:error,loading:false});
                }
            })
        };
        this.props.settings.onPopup({
            title:"Confirm Remove Field?",
            description:"This action cannot be undone",
            confirm:remove,
            cancel:this.props.settings.onClosePopup,
            /*
            confirm:PropTypes.func.isRequired,
            cancel:PropTypes.func.isRequired,
            addCancel:PropTypes.func,
            title:PropTypes.string.isRequired,
            description:PropTypes.string,
            */
        })
    }

    onChangeTemp=(value,label)=>{
        let tempField = this.state.tempField;
        tempField[label]=value;
        this.setState({tempField:tempField});
    }

    onReorder=(newOrder)=>{
        this.setState({error:""});
        const content=this.props.content;
        u.post({
            url:"/api/update-form-order",
            data:{
                formID:content.formID,
                old:content.fields,
                new:newOrder,
            },
            success:()=>{},
            error:(error)=>{
                this.setState({error:error})
            }
        });
        this.props.onChange(newOrder,"fields",this.props.index);
    }

    onSubmit=()=>{
        this.setState({error:""});
        //Stopped here
        const configure = this.refs['configure'].validate();
        if(!configure.valid){
            this.setState({error:configure.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Updating..."});
        const tempField = this.state.tempField;
        tempField.rules={required:tempField.required};
        delete tempField.required;

        if(this.state.editIndex == -1){
            u.post({
                url:"/api/form-add-field",
                data:{
                    formID:this.props.content.formID,
                    field:tempField
                },
                success:(newField)=>{
                    let fields = this.props.content.fields;
                    fields.push(newField);
                    this.props.onChange(fields,"fields",this.props.index);
                    this.setState({loading:false,step:"view"});
                },
                error:(error)=>{
                    this.setState({error:error,loading:false});
                }
            });
        }
        else{
            u.post({
                url:"/api/form-edit-field",
                data:{
                    formID:this.props.content.formID,
                    field:tempField,
                },
                success:()=>{
                    let fields=this.props.content.fields;
                    fields[this.state.editIndex]=tempField;
                    this.props.onChange(fields,"fields",this.props.index);
                    this.setState({loading:false,step:"view"})
                },
                error:(error)=>{
                    this.setState({error:error,loading:false});
                }
            })
        }
    }

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        return (
            <div style={{position:"relative",textAlign:'center'}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                    <DynamicListArea listLabel={this.props.settings.listLabel}
                                     permission={this.props.settings.permission}
                                     content={content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />

                    <div className="list-segment extended" style={{textAlign:"center",fontSize:"14px"}}>
                        <h3>Form Fields</h3>
                        <div>
                            <Input ref="fields"
                                   fields={{
                                      dataType:"list",
                                      label:"Fields",
                                      field:"fields",
                                      onAdd:this.onAddField,
                                      onRemove:this.onRemoveField,
                                      onEdit:this.onEditField
                                   }}
                                   content={content}
                                   hideLabel={true}
                                   order={true}
                                   onReorder={this.onReorder}
                            />
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "field"}>
                    <div style={{fontSize:"14px"}}>
                        <ConfigureField ref="configure"
                                        content={this.state.tempField}
                                        isEdit={(this.state.editIndex != -1)}
                                        onChange={this.onChangeTemp}
                        />
                        <div className="list-segment-buttons">
                              <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                              <Button onClick={this.onSubmit}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        entryData:{
            fields:[],
        },
        loading:false,
        loadText:'',
        editIndex:-1,
        step:"view",
        tempField:{},
    };

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "reference",
            "fields",
            "remarks",
        ];

        for(var i=0; i<values.length; i++){
            if(this.refs[values[i]]){
                const field = this.refs[values[i]].validate();
                if(!field.valid){
                    this.setState({error:field.value});
                    return 0;
                }
            }
        }

        let entryData=this.state.entryData;
        entryData.fields = entryData.fields.map((item,index)=>{
            item.order=index;
            return item;
        })

        this.refs['add'].onValidated(entryData);
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onComplete=()=>{
        this.setState({entryData:{
          fields:[],
        },error:""});
    }

    onCancel=()=>{
        this.setState({entryData:{
          fields:[],
        },error:""});
        this.props.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onChangeTemp=(value,label)=>{
        let tempField = this.state.tempField;
        tempField[label]=value;
        this.setState({tempField:tempField});
    }

    onAddField=()=>{
        this.setState({
          tempField:{
              dataType:"select",
              label:"Ask your question here",
              options:["Option 1","Option 2"],
          },
          step:"field",
          editIndex:-1,
          error:""
        });
    }

    onEditField=(index)=>{
        let entryData=this.state.entryData;
        //Set back the required,
        let tempField = Object.assign({},entryData.fields[index]);
        tempField.required=(tempField.rules) ? tempField.rules.required : 0;
        tempField.options= (tempField.options) ? tempField.options.map(item=>{return item}) : [];

        this.setState({
            tempField:tempField,
            editIndex:index,
            step:"field",
            error:""
        })
    }

    onConfirmField=()=>{
        this.setState({error:""});
        let configure = this.refs['configure'].validate();
        if(!configure.valid){
            this.setState({error:configure.value});
            return 0;
        }

        let entryData = this.state.entryData;
        let tempField = this.state.tempField;
        tempField.rules={required:tempField.required};
        delete tempField.required;

        if(this.state.editIndex != -1){
            entryData.fields[this.state.editIndex] = tempField;
        }
        else{
            entryData.fields.push(tempField);
        }
        this.setState({entryData:entryData,step:"view"});
    }

    onReorder=(newOrder)=>{
        let entryData=this.state.entryData;
        entryData.fields=newOrder;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData=this.state.entryData;

        const fields=[
            {dataType:"box",label:"Reference",field:"reference",rules:{required:true}},
            {dataType:"list",label:"Fields",field:"fields",rules:{required:true}, onAdd:this.onAddField,onEdit:this.onEditField,order:true,onReorder:this.onReorder},
            {dataType:"area",label:"Remarks",field:"remarks"},
        ];

        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onComplete}
                         onToggleForm={this.props.onToggleForm}>

                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                    <Step active={this.state.step == "view"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-error">{this.state.error}</div>

                        <div className="segment-content">
                            {
                                fields.map(
                                    (item,index)=>{
                                        return (
                                          <Input key={index}
                                                 ref={item.field}
                                                 fields={{
                                                   ...item,
                                                   ...{onChange:this.onChange}
                                                 }}
                                                 content={entryData}
                                                 order={item.order}
                                                 onReorder={item.onReorder}
                                          />
                                        )
                                    }
                                )
                            }

                            <div className="segment-buttons">
                                <div className="segment-error">{this.state.error}</div>
                                <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "field"}>
                        <div className="segment-error">{this.state.error}</div>

                        <ConfigureField ref="configure"
                                        content={this.state.tempField}
                                        onChange={this.onChangeTemp}
                                        edit={(this.state.editIndex != -1)}
                                        />

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                            <Button onClick={this.onConfirmField}>Add</Button>
                        </div>
                    </Step>
                </div>

            </AddListItem>
        )
    }
}

class ConfigureField extends React.Component{

    state={
        fieldOptions:[
          {
            value:"select",
            label:"List",
            ruleOptions:[
                {field:"required",label:"Mandatory",options:[]}
            ],
          },
          {
            value:"box",
            label:"Text Box",
          },
          {
            value:"area",
            label:"Text Area",
          },
          {
            value:"photo",
            label:"Photo",
          },
          {
            value:"dow",
            label:"Day of Week",
          },
          {
            value:"date",
            label:"Date",
          },
          {
            value:"time",
            label:"Time",
          },
        ],
        dataOptions:[],
        options:{},
    }

    componentDidMount=()=>{
        this.getOptions();
    }

    getOptions=()=>{
      u.post({
          url:"/api/get-options",
          data:{
              keys:["yesNo"]
          },
          success:(options)=>{
              this.setState({options:options});
          },
          error:(error)=>{
              this.setState({error:error});
          }
      });
    }

    validate=()=>{
        const keys=["label","required","dataType","options"];
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                let current = this.refs[keys[i]].validate();
                if(!current.valid){
                    return current;
                }
            }
        }
        return {valid:true};
    }

    render=()=>{
        const content = this.props.content;
        const options = this.state.options;

        return(
            <div className="configure-field">
                <h3>{(this.props.edit) ? "Edit" : "Add"} Field</h3>

                <Input ref="label"
                       fields={{
                            dataType:"box",
                            label:"Label",
                            field:"label",
                            rules:{required:1},
                            onChange:this.props.onChange
                       }}
                       content={content}
                       />

                <Input ref="required"
                        fields={{
                           dataType:"select",
                           label:"Field Mandatory",
                           field:"required",
                           options:options.yesNo,
                           onChange:this.props.onChange
                        }}
                        content={content}
                 />

                <Input ref="dataType"
                       fields={{
                            dataType:"select",
                            label:"Input Type",
                            field:"dataType",
                            options:this.state.fieldOptions,
                            onChange:this.props.onChange
                       }}
                       content={content}
                       />

                {
                    (content.dataType == "select")
                        ?
                        <Input ref="options"
                               fields={{
                                 dataType:"list",
                                 label:"List Option",
                                 field:"options",
                                 isBox:true,
                                 onChange:this.props.onChange,
                                 rules:{required:true,skipSeconds:true}
                               }}
                               content={content}
                        />
                        :
                        null
                }

                <h3 style={{marginBottom:"5px"}}>Preview</h3>
                <div style={{background:"#f4f4f4",borderRadius:"5px",width:"500px",padding:"20px", margin:"0 auto"}}>
                    <div className="segment-full-item" style={{textAlign:"center",margin:"0"}}>
                        <div className="segment-item-input" style={{textAlign:"center"}}>
                            <b>{(content.label) ? content.label : "No Label"}</b>
                        </div>
                    </div>
                    <Input fields={{
                              ...content,
                              ...{options:(content.options) ? content.options.map((item)=>{return {value:item,label:item}}) : [] }
                           }}
                           containerStyle={{marginTop:"5px",textAlign:"center"}}
                           inputStyle={{textAlign:"center"}}
                           hideLabel={true}
                    />
                </div>
                {
                    (content.dataType == "photo")
                        ?
                        <div className="text-negative" style={{fontSize:"12px"}}>
                            Note. Maximum of 1 photo per input
                        </div>
                        :
                        null
                }
            </div>
        )
    }
}
