//English en en_UK en_US
export const en = {
    //Login
    "welcome-to":"WELCOME TO",
    "resident-login":"Resident Login",
    "scan-dorm-qr":"Scan Dormitory QR Code",
    "scan-qr":"Scan QR Code",
    "scan-fin":"Scan Your FIN",
    "scan-now":"Scan Now",
    "enter-manually":"Enter Manually",
    "enter-dorm-id":"Enter Dormitory ID",
    "enter-fin":"Enter Your FIN",
    "dormitory-id":"Dormitory ID",

    //Home Menu
    "contact-number":"Contact Number",
    "submit-vitals":"Submit Vitals",
    "view-inbox":"View Inbox",
    "call-helpline":"Call Helpline",
    "logout":"Log Out",
    "confirm-photo":"Confirm Photo",
    "update-photo":"Update Your Photo",
    "confirm":"Confirm",
    "take-photo":"Take Photo",
    "reset":"Reset",

    //Vitals
    "resident":"Resident",
    "take-temperature":"Take Your Temperature",
    "temperature":"Temperature",
    "take-blood-pressure":"Take Your Blood Pressure",
    "take-oxygen-pulse":"Measure Oxygen & Pulse",
    "take-oxygen":"Measure Oxygen Saturation",
    "take-pulse":"Measure Pulse",
    "breathing-difficulty-question":"Do You Have Difficulty Breathing?",
    "breathing-difficulty":"Breathing Difficulty",
    "chest-pain-question":"Do You Have Chest Pains?",
    "chest-pain":"Chest Pain",
    "requires-attention-question":"Do You Require Medical Attention?",
    "requires-attention":"Require Medical Attention",
    "submit-vitals-confirm":"Submit Vitals?",
    "submit-success":"Vitals Submitted",
    "next-reading":"Your next reading should be taken and submitted between",
    "next-reading-long":"",
    "yes":"Yes",
    "no":"No",

    //Inbox List
    "inbox":"Inbox",
    "inbox-empty":"Inbox Empty",
    "inbox-empty-message":"No notifications from Smartdorm",
    "refresh":"Refresh",
    "load-more":"Load More Entries",
    "item":"item",
    "items":"items",
    "select-all":"Select All",
    "unselect-all":"Unselect All",
    "search-for":"Search For",
    "mark-read":"Mark As Read",
    "mark-unread":"Mark as Unread",
    "delete":"Delete",

    //Inbox Item
    "inbox-item":"Inbox Item",
    "delete-confirm":"Delete this item?",
    "survey":"Survey",
    "start-survey":"Start Survey",

    //Food Collection
    "food-collection":"Food Collection",
    "food-collected":"Food Collected",
    "food-collected-sub":"Thank you & enjoy your meal",
    "no-window":"Submission Window Closed",
    "next-collection":"The next food collection window is between",
    "next-collection-long":"",

    //Error Validation
    "error-field":"Field",
    "error-required":"is required",
    "error-min-value":"cannot be less than",
    "error-max-value":"cannot be more than",
    "error-min-length":"cannot be shorter than",
    "error-max-length":"cannot be longer than",
    "error-min-duration":"cannot be before",
    "error-max-duration":"cannot be after",
    "error-characters":"characters",
    "error-mobile-number":"only accepts numbers and (+)",
    "error-characters":"cannot contain letters or special characters",
    "error-email":"has an invalid email address",
    "error-special-characters":"does not accept special characters",
    "error-no-space":"does not accept spaces",

    //Navigation Buttons
    "back":"Back",
    "next":"Next",

    //Load Indicators
    "preparing":"Preparing",
    "processing":"Processing",
    "deleting":"Deleting",
    "submitting":"Submitting",
};

//Chinese zh, zh_CN, zh_SG, zh_TW, zh_HK
export const zh = {
    "welcome-to":	"欢迎进入"	,
    "resident-login":	"居民登录"	,
    "scan-dorm-qr":	"扫描宿舍二维码"	,
    "scan-now":	"立即扫描"	,
    "enter-manually":	"手动输入"	,
    "enter-dorm-id":	"输入宿舍ID"	,
    "scan-qr":	"扫描二维码"	,
    "dormitory-id":	"宿舍ID"	,
    "enter-fin":	"输入工作准证号码"	,
    "scan-fin":	"扫描工作准证号码"	,

    "contact-number":	"联络号码"	,
    "submit-vitals":	"提交健康体征"	,
    "view-inbox":	"查看收件箱"	,
    "call-helpline":	"联系服务热线"	,
    "logout":	"退出"	,
    "confirm-photo":	"确认照片"	,
    "update-photo":	"更新个人照片"	,
    "confirm":	"确认"	,
    "take-photo":	"拍照"	,
    "reset":"重启",

    "resident":"居民",
    "take-temperature":	"测量体温"	,
    "temperature":	"体温"	,
    "take-blood-pressure":	"测量血压"	,
    "take-oxygen-pulse":	"测量氧气与心率"	,
    "take-oxygen":	"测量氧气饱和度"	,
    "take-pulse":	"测量心率"	,
    "breathing-difficulty-question":	"是否出现呼吸困难？"	,
    "breathing-difficulty":	"呼吸困难"	,
    "chest-pain-question":	"是否出现胸部疼痛？"	,
    "chest-pain":	"胸部疼痛"	,
    "requires-attention-question":	"是否需要医疗照顾？"	,
    "requires-attention":	"需要医疗"	,
    "submit-vitals-confirm":	"提交健康体征？"	,
    "submit-success":	"健康体征已提交"	,
    "next-reading":	"下一次测量应在以下时间提交"	,
    "next-reading-long":"",
    "yes":	"是"	,
    "no":	"否"	,

    "inbox":	"收件箱"	,
    "inbox-empty":	"收件箱清空"	,
    "inbox-empty-message":	"没有来自Smartdorm的通知"	,
    "refresh":	"刷新"	,
    "load-more":	"加载更多条目"	,
    "item":	"项目"	,
    "items":	"项目"	,
    "select-all":	"全选"	,
    "unselect-all":	"取消选择"	,
    "search-for":	"搜索"	,
    "mark-read":	"标记为已读"	,
    "mark-unread":	"标记为未读"	,
    "delete":	"删除"	,

    "inbox-item":	"收件箱项目"	,
    "delete-confirm":	"删除此项目？"	,
    "survey":	"问卷调查"	,
    "start-survey":	"调查开始"	,

    "error-field":	"输入框"	,
    "error-required":	"必须填写"	,
    "error-min-value":	"不可少于"	,
    "error-max-value":	"不可超过"	,
    "error-min-length":	"不可短于"	,
    "error-max-length":	"不可多过"	,
    "error-min-duration":	"不可在之前"	,
    "error-max-duration":	"不可在之后"	,
    "error-characters":	"字符"	,
    "error-mobile-number":	"仅限数字和 （+）"	,
    "error-characters":	"不可包含字母或特殊字符"	,
    "error-email":	"电子邮件地址无效"	,
    "error-special-characters":	"不接受特殊字符"	,
    "error-no-space":	"不接受空格"	,

    "back":	"回返"	,
    "next":	"往下"	,

    "preparing":	"准备中"	,
    "processing":	"处理中"	,
    "deleting":	"删除中"	,
    "submitting":	"提交中"	,

    "food-collection":	"领取餐食"	,
    "food-collected":	"餐食已被领取"	,
    "food-collected-sub":	"谢谢，请享用餐食"	,
    "no-window":"提交时段已关闭",
    "next-collection":"下一个领取餐食的时段是从",
    "next-collection-long":""
};

//Bengali bn, bn_BD
export const bn = {
    "welcome-to":	"স্বাগত"	,
    "resident-login":	"বাসিন্দা লগইন"	,
    "scan-dorm-qr":	"স্ক্যান ডরমিটরি QR কোড",
    "scan-now":	"স্ক্যান এখন"	,
    "enter-manually":	"ম্যানুয়ালি প্রবেশ করুন"	,
    "enter-dorm-id":	"ডরমিটরি আইডি"	,
    "scan-qr":	"কিউআর কোড স্ক্যান করুন"	,
    "dormitory-id":	"ডরমিটরি আইডি"	,
    "enter-fin":	"আপনার FIN লিখুন"	,
    "scan-fin":	"আপনার FIN স্ক্যান করুন"	,

    "contact-number":	"যোগাযোগের নম্বর"	,
    "next-vitals-check":	"পরবর্তী শারীরিক চেক"	,
    "next-vitals-check-long":"",
    "submit-vitals":	"শারীরিক খবর জমা দিন"	,
    "view-inbox":	"দেখুন ইনবক্স"	,
    "call-helpline":	"কল হেল্পলাইন"	,
    "logout":	"প্রস্থান"	,
    "confirm-photo":	"নিশ্চিত করুন ছবি"	,
    "update-photo":	"আপনার ফটো আপডেট করুন"	,
    "confirm":	"নিশ্চিত করুন"	,
    "take-photo":	"ছবি তোল"	,
    "reset":"রিসেট",

    "resident":"বাসিন্দা",
    "take-temperature":	"তোমার তাপমাত্রা নাও"	,
    "temperature":	"তাপমাত্রা"	,
    "take-blood-pressure":	"আপনার রক্তচাপ নিন"	,
    "take-oxygen-pulse":	"মেজার অক্সিজেন ও পালস"	,
    "take-oxygen":	"মেজার অক্সিজেন সম্পৃক্তি"	,
    "take-pulse":	"মেজার পালস"	,
    "breathing-difficulty-question":	"আপনি শ্বাসকষ্টের করেন?"	,
    "breathing-difficulty":	"শ্বাসকষ্ট"	,
    "chest-pain-question":	"আপনি বুকে ব্যথা হয়?",
    "chest-pain":	"বুক ব্যাথা"	,
    "requires-attention-question":	"আপনি চিকিত্সার দরকার করেন?"	,
    "requires-attention":	"চিকিত্সার দরকার"	,
    "submit-vitals-confirm":	"জমা দেবেন?"	,
    "submit-success":	"জমা দেওয়া হয়েছে"	,
    "next-reading":	"আপনার পরবর্তী পড়া নিয়ে যাওয়া এবং তাদের মধ্যে জমা দিতে হবে"	,
    "next-reading-long":"",
    "yes":	"হ্যাঁ"	,
    "no":	"না"	,

    "inbox":	"ইনবক্স"	,
    "inbox-empty":	"ইনবক্স খালি"	,
    "inbox-empty-message":	"স্মার্টডর্ম থেকে কোনও খবর নেই"	,
    "refresh":	"সতেজ করা"	,
    "load-more":	"আরো এন্ট্রি জুড়ুন"	,
    "item":	"পদ"	,
    "items":	"পদ"	,
    "select-all":	"সকল নির্বাচন করুন"	,
    "unselect-all":	"নির্বাচন করুন"	,
    "search-for":	"সন্ধান করা"	,
    "mark-read":	"পঠিত হিসেবে চিহ্নিত করুন"	,
    "mark-unread":	"অপঠিত হিসাবে চিহ্নিত"	,
    "delete":	"মুছে ফেলা"	,

    "inbox-item":	"ইনবক্স আইটেম"	,
    "delete-confirm":	"এই আইটেমটি মুছে ফেলতে চান?"	,
    "survey":	"জরিপ"	,
    "start-survey":	"জরিপ শুরু করুন"	,

    "error-field":	"ক্ষেত্র"	,
    "error-required":	"দরকার"	,
    "error-min-value":	"চেয়ে কম হতে পারে না"	,
    "error-max-value":	"চেয়ে বেশি হতে পারে না"	,
    "error-min-length":	"চেয়ে খাটো হতে পারে না"	,
    "error-max-length":	"চেয়ে দীর্ঘতর হতে পারে না"	,
    "error-min-duration":	"আগে হতে পারবে না"	,
    "error-max-duration":	"পরে হতে পারে না"	,
    "error-characters":	"চরিত্র"	,
    "error-mobile-number":	"শুধুমাত্র সংখ্যা গ্রহণ করে এবং (+ +)"	,
    "error-characters":	"অক্ষর বা বিশেষ অক্ষর থাকতে পারে না"	,
    "error-email":	"একটি অবৈধ ইমেল ঠিকানা নেই"	,
    "error-special-characters":	"বিশেষ অক্ষর গ্রহণ করে না"	,
    "error-no-space":	"শূণ্যস্থান গ্রহণ করে না"	,

    "back":	"পেছনে"	,
    "next":	"পরবর্তী"	,

    "preparing":	"প্রস্তুতি"	,
    "processing":	"প্রসেসিং"	,
    "deleting":	"একবার মুছে"	,
    "submitting":	"জমা দেওয়া হচ্ছে"	,

    "food-collection":	"খাদ্য সংগ্রহ"	,
    "food-collected":	"খাবার সংগ্রহ করা হয়েছে"	,
    "food-collected-sub":	"আপনাকে ধন্যবাদ এবং আপনার খাবার উপভোগ করুন"	,
    "no-window":"জমা করার জানালা বন্ধ",
    "next-collection":"পরবর্তী খাদ্য সংগ্রহ জানালা",
    "next-collection-long":"এর মধ্যে"
};

//Tamil ta, ta_IN
export const ta = {
    "welcome-to":	"நல்வரவு"	,
    "resident-login":	"குடியிருப்பாளர்கள் உள்நுழைவு"	,
    "scan-dorm-qr":	"தங்குமிடம் QR குறியீட்டை ஸ்கேன் செய்யுங்கள்"	,
    "scan-now":	"இப்போது ஸ்கேன் செய்யுங்கள்"	,
    "enter-manually":	"கைமுறையாக உள்ளிடவும்"	,
    "enter-dorm-id":	"தங்குமிடம் ஐடியை உள்ளிடவும்"	,
    "scan-qr":	"QR குறியீட்டை ஸ்கேன் செய்யுங்கள்"	,
    "dormitory-id":	"தங்குமிடம் ஐடி"	,
    "enter-fin":	"உங்கள் FIN ஐ உள்ளிடவும்"	,
    "scan-fin":	"உங்கள் FIN ஐ ஸ்கேன் செய்யுங்கள்"	,

    "contact-number":	"தொடர்பு எண்"	,
    "submit-vitals":	 "மருத்துவ ஆய்வு"	,
    "view-inbox":	"இன்பாக்ஸ்"	,
    "call-helpline":	"ஹெல்ப்லைன்"	,
    "logout":	 "வெளியேறு"	,
    "confirm-photo":	"புகைப்படத்தை உறுதிப்படுத்தவும்"	,
    "update-photo":	"உங்கள் புகைப்படத்தைப் புதுப்பிக்கவும்"	,
    "confirm":	"சரி"	,
    "reset": "மீட்டமை",
    "take-photo":	"சரி"	,

    "resident":"பெயர்",
    "take-temperature":	"உங்கள் வெப்பநிலையை எடுத்துக் கொள்ளுங்கள்"	,
    "temperature":	"உடல் வெப்ப நிலை"	,
    "take-blood-pressure":	"உங்கள் இரத்த அழுத்தத்தை எடுத்துக் கொள்ளுங்கள்"	,
    "take-oxygen-pulse":	"ஆக்ஸிஜன் மற்றும்  நாடி துடிப்பை அளவிடவும்"	,
    "take-oxygen":	"ஆக்ஸிஜன் செறிவை அளவிடவும்"	,
    "take-pulse":	"நாடி துடிப்பை அளவிடவும்"	,
    "breathing-difficulty-question":	"உங்களுக்கு சுவாசிப்பதில் சிரமம் இருக்கிறதா?"	,
    "breathing-difficulty":	"சுவாசிக்க சிரமம்"	,
    "chest-pain-question":	"உங்களுக்கு மார்பு வலி/ நெஞ்சு வலி   இருக்கிறதா? "	,
    "chest-pain":	"நெஞ்சு வலி"	,
    "requires-attention-question":	"உங்களுக்கு மருத்துவ கவனம் தேவையா?"	,
    "requires-attention":	"மருத்துவ கவனம் தேவை"	,
    "submit-vitals-confirm":	"உடலியக்கக் குறியீடுகள் சமர்ப்பிக்கவா?"	,
    "submit-success":	"உடலியக்கக் குறியீடுகள் சமர்ப்பிக்கப்பட்டன"	,
    "next-reading":	"உங்கள் அடுத்த அளவீட்டை எடுத்து"	,
    "next-reading-long":"இடையில் சமர்ப்பிக்க வேண்டும்",
    "yes":	"ஆம்"	,
    "no":	"இல்லை"	,

    "inbox":	"இன்பாக்ஸ்"	,
    "inbox-empty":	"இன்பாக்ஸ் காலியாக உள்ளது"	,
    "inbox-empty-message":	"ஸ்மார்ட் டார்மில் இருந்து அறிவிப்புகள் எதுவும் இல்லை"	,
    "refresh":	"புதுப்பிப்பு"	,
    "load-more":	"மேலும் உள்ளீடுகளை ஏற்றவும்"	,
    "item":	"குறுஞ்செய்தி"	,
    "items":	"குறுஞ்செய்திகள்"	,
    "select-all":	"அனைத்தும் தேர்ந்தெடு"	,
    "unselect-all":	"அனைத்தையும் தேர்வுநீக்கு"	,
    "search-for":	"தேடுங்கள்"	,
    "mark-read":	"படித்ததாக என்று குறி"	,
    "mark-unread":	"படிக்காதது என்று குறி"	,
    "delete":	"அழி"	,

    "inbox-item":	"இன்பாக்ஸ்"	,
    "delete-confirm":	"இந்த குறுஞ்செய்தியை நீக்கவா?"	,
    "survey":	"கணக்கெடுப்பு"	,
    "start-survey":	"கணக்கெடுப்பு தொடங்கவும்"	,

    "error-field":	"இடம்"	,
    "error-required":	"தேவை"	,
    "error-min-value":	"விட குறைவாக இருக்க கூடாது"	,
    "error-max-value":	"விட அதிகமாக இருக்க கூடாது"	,
    "error-min-length":	"விட குறைவாக இருக்க கூடாது"	,
    "error-max-length":	"விட நீண்டு இருக்க கூடாது"	,
    "error-min-duration":	"முன்பு இருக்க கூடாது"	,
    "error-max-duration":	"பிறகு இருக்க கூடாது"	,
    "error-characters":	"குறியீடுகள்"	,
    "error-mobile-number":	"எண்களை மற்றும் (+) மட்டுமே ஏற்றுக்கொள்கிறது" 	,
    "error-characters":	"எழுத்துக்கள் அல்லது சிறப்பு எழுத்துக்கள் இருக்கக்கூடாது"	,
    "error-email":	"தவறான மின்னஞ்சல் முகவரி உள்ளது"	,
    "error-special-characters":	"சிறப்பு குறியீடுகள் ஏற்காது"	,
    "error-no-space":	"இடைவெளிகளை ஏற்காது"	,

    "back":	"மீண்டும்",//"பின்பு செல்லவும்"	,
    "next":	"அடுத்தது"	,

    "preparing":	"தயாராக்கப்படுகிறது"	,
    "processing":	"செயலாக்கப்படுகிறது"	,
    "deleting":	"அழிக்கிறது"	,
    "submitting":	"சமர்ப்பிக்கப்படுகிறது"	,

    "food-collection":	"உணவு சேகரிப்பு"	,
    "food-collected":	"உணவு சேகரிக்கப்பட்டுள்ளது"	,
    "food-collected-sub":	"நன்றி மற்றும் உங்கள் உணவை அனுபவிக்கவும்"	,
    "no-window":"சமர்ப்பிக்கும் சாளரம் மூடப்பட்டதுধ",
    "next-collection":"அடுத்த உணவு சேகரிப்பு சாளரம் இடையில் உள்ளது",
    "next-collection-long":""

};
