import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,AddListItem,Segment,LoadGraphic,Step,DynamicListArea,BulkEdit,DownloadTemplate} from '../components/Common';
import {EditSizeLabel,EditSizeLink,EditableBox,
        Select,Button,Date,Box,Info,TextButton,
        MiniMinusButton,List,Area} from '../components/Input';
import {} from '../data/Options';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';
import '../styles/offense-list.scss';

export default class OffenseList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Offense Types",
        permission:"combined",  //Accepts combined permission module as well
        combinedField:"offense",     //Used when permission exists in a combined field
        url:"/offense-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-offense-entry",
        templateURL:"/api/get-add-offense-template",
        bulkURL:"/api/add-bulk-offense",
        deleteURL:"/api/delete-offense-entries",
        spreadsheetURL:"/api/generate-offense-spreadsheet",
        overviewURL:"/api/get-all-offense-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"offense-list",
        orderByField:"label",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddOffenseEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        options:{},
    };

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["yesNo","accessoryOwnershipType"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("combined");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            pageMenuButtons:[
                {label:"Add",mobileLabel:"Add Entry",ref:"add",image:"/images/page-menu/add.png",title:"Add new price entry",enabled:true},
                {label:"Delete",mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entry",enabled:true},
                {label:"Spreadsheet",mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit price details",enabled:bulkEditEnabled,padding:"3px"},
            ],
            permissions:permissions,
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>
                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>


                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <OffenseItem key={index}
                                              index={index}
                                              content={content}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              options={this.state.options}
                                              permissions={this.state.permissions}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class OffenseItem extends React.Component{
    state={
        step:"view",
        error:"",
        loading:false,
        loadText:"",
    };

    onConfirmAdd=()=>{
        this.setState({error:""});
        let newPenalty=this.refs['penalty'].validate();
        if(!newPenalty.valid){
            this.setState({error:newPenalty.value});
            return 0;
        }
        let newDemerit = this.refs['demerits'].validate();
        if(!newDemerit.valid){
            this.setState({error:newDemerit.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Adding Penalty..."});
        u.post({
            url:"/api/offense-add-penalty",
            data:{
                offenseTypeID:this.props.content.offenseTypeID,
                penalty:newPenalty.value,
                demerits:newDemerit.value,
            },
            success:(penaltyEntry)=>{
                let penalty=this.props.content.penalty;
                penalty.push(penaltyEntry);
                this.props.onChange(penalty,'penalty',this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:true,error:error});
            }
        });
    }

    onChangePenalty=(value,label,index)=>{
        let penalty=this.props.content.penalty;
        penalty[index][label]=value;
        this.props.onChange(penalty,"penalty",this.props.index);
    }

    onRemoveRequest=(index)=>{
        this.setState({step:"remove",error:"",removeIndex:index});
    }

    onRemovePenalty=()=>{
        this.setState({loading:true,loadText:"Removing penalty item..."});
        const content=this.props.content;
        u.post({
            url:"/api/offense-remove-penalty",
            data:{
                offenseTypeID:content.offenseTypeID,
                penalty:content.penalty[this.state.removeIndex],
            },
            success:()=>{
                let penalty = content.penalty;
                penalty.splice(this.state.removeIndex,1);
                this.props.onChange(penalty,"penalty",this.props.index);
                this.setState({loading:false,step:"view",error:"",removeIndex:0});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    render=()=>{
        const content = this.props.content;
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"4px"};
        const edit = this.props.permissions.offense;
        return (
            <div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>
                <Step active={this.state.step == "view"}>

                    <DynamicListArea content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     listLabel={"offense-list"}
                                     permission="combined"
                                     combinedField="offense"/>

                    {
                        (content.penalty.length > 0)
                          ?
                          <div className='list-table-container'>
                              <div>
                                  <h3>Penalties <Info style={infoPlaceholder} title="Penalty will automatically be assigned to misconduct entry based on occurrence"/></h3>
                              </div>
                              <div className='list-table'>
                                  <table>
                                      <thead>
                                          <tr>
                                            <th className="remove left"/>
                                            <th>Occurrence</th>
                                            <th>Penalty</th>
                                            <th className="right">Demerits</th>
                                          </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              (content.penalty.length > 0)
                                                  ?
                                                  content.penalty.map((item,index,arr)=>{
                                                      return (
                                                        <tr key={index} className="item">
                                                            <td className="remove">
                                                                <div style={{width:"15px",height:"15px",margin:"auto"}}>
                                                                    <MiniMinusButton enabled={(arr.length > 1)} onClick={()=>{this.onRemoveRequest(index)}}/>
                                                                </div>
                                                            </td>
                                                            <td>{index+1}</td>
                                                            <td>
                                                                <EditableBox label={"Penalty - " + (index+1)}
                                                                             field="value"
                                                                             index={index}
                                                                             values={{value:item.value,enabled:edit}}
                                                                             rules={{required:true}}
                                                                             id={{offenseTypeID:content.offenseTypeID,entry:item}}
                                                                             url="/api/update-offense-info"
                                                                             onChange={this.onChangePenalty}
                                                                             />
                                                            </td>
                                                            <td>
                                                                <EditableBox label={"Demerit - " + (index+1)}
                                                                             field="demerits"
                                                                             type="number"
                                                                             index={index}
                                                                             values={{value:item.demerits,enabled:edit}}
                                                                             rules={{required:true,minValue:0}}
                                                                             id={{offenseTypeID:content.offenseTypeID,entry:item}}
                                                                             url="/api/update-offense-info"
                                                                             onChange={this.onChangePenalty}
                                                                             />
                                                            </td>
                                                        </tr>
                                                      )
                                                  })
                                                  :
                                                  <div className="list-segment-placeholder">
                                                      No Penalty Set
                                                  </div>
                                          }
                                      </tbody>
                                  </table>
                              </div>

                              <div style={{textAlign:"center",margin:"20px auto",paddingBottom:"20px"}}>
                              {
                                  (edit && content.penalty.length < 10)
                                      ?
                                      <TextButton onClick={()=>{this.setState({step:"add",error:""})}}>Add Penalty</TextButton>
                                      :
                                      <div><i>Maximum penalty entries added (10/10)</i></div>
                              }
                              </div>


                          </div>
                          :
                          <div className="segment-placeholder">
                              No Penalties
                              {
                                  (edit)
                                    ?
                                    <div style={{marginTop:"5px"}}>
                                        <TextButton onClick={()=>{this.onCreateLine()}}>Add Penalties</TextButton>
                                    </div>
                                    :
                                    null
                              }
                          </div>
                    }
                </Step>

                <Step active={this.state.step == "add"}>
                    <div style={{padding:"50px",textAlign:"center"}}>
                        <h3>Add Penalty</h3>
                        <div style={{fontSize:"14px"}}>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Demerits:</div>
                                <div className="segment-item-input">
                                    <Box ref="demerits"
                                         label="Demerits"
                                         type="number"
                                         rules={{required:true}}
                                         />
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Penalty:</div>
                                <div className="segment-item-input">
                                    <Area ref="penalty"
                                          label="Penalty"
                                          rules={{required:true}}
                                          />
                                </div>
                            </div>
                            <div className="segment-buttons">
                                <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                                <Button onClick={this.onConfirmAdd}>Confirm</Button>
                            </div>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "remove"}>
                    <div style={{padding:"50px",textAlign:"center"}}>
                        {
                            (content.penalty.length > this.state.removeIndex)
                                ?
                                <h2 className="text-negative">Remove Penalty - {content.penalty[this.state.removeIndex].value}?</h2>
                                :
                                null
                        }
                        <div className="list-segment-note">Note. This action will not affect existing misconduct entries</div>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onRemovePenalty}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

class AddOffenseEntry extends React.Component{

    state={
        additional:{},
        dormOptions:[],
        entryData:{
            penalty:[
                {}
            ],
        },
        options:{},
        step:"add",
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getDormOptions();
        this.getOptions();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["offenseSeverity"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
              type:"dorm"
            },
            success:(dormOptions)=>{
                dormOptions.unshift({value:"all",label:"All Dormitories"});
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onValidate=()=>{
        this.setState({error:""});
        const entryData=this.state.entryData;
        const keys=["dormID","label","severity"];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current = this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }

        for(var j=0; j<entryData.penalty.length; j++){
            const penalty = this.refs['p-'+j].validate();
            if(!penalty.valid){
                this.setState({error:penalty.value});
                return 0;
            }
            const demerits = this.refs['d-'+j].validate();
            if(!demerits.valid){
                this.setState({error:demerits.value});
                return 0;
            }
        }

        data.penalty = entryData.penalty;

        this.refs[this.props.reference].onValidated(data);
    }

    onChange=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    onAddPenalty=()=>{
        let entryData=this.state.entryData;
        entryData.penalty.push({});
        this.setState({entryData:entryData});
    }

    onRemovePenalty=(index)=>{
        let entryData=this.state.entryData;
        entryData.penalty.splice(index,1);
        this.setState({entryData:entryData});
    }

    onChangePenalty=(value,label,index)=>{
        let entryData=this.state.entryData;        
        entryData.penalty[index][label]=value;
        this.setState({entryData:entryData});
    }

    onComplete=()=>{
        this.setState({
            entryData:{
                penalty:[{}],
            },
            error:"",
        });
    }

    render=()=>{
        const entryData = this.state.entryData;
        const options = this.state.options;
        const infoInput={width:"15px",height:"15px",marginBottom:"3px"};
        const infoPlaceholder={width:"15px",height:"15px",marginBottom:"9px"};

        return(
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         filetypes={Spreadsheet}
                         additional={this.state.additional}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}>

                <div className="segment-content">
                    <div className="segment-error">{this.state.error}</div>
                    <div className="segment-content" style={{textAlign:"center"}}>
                        <h3>Complete the following to continue</h3>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Dormitory<Info style={infoInput} title="Offense/Penalty only applies to the selected dormitory"/></div>
                            <div className="segment-item-input"><Select ref="dormID" values={{value:entryData.dormID,options:this.state.dormOptions}} label="Dormitory" field="dormID" onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Severity<Info style={infoInput} title="Classification of the offense, for analytical and categorization purposes"/></div>
                            <div className="segment-item-input"><Select ref="severity" values={{value:entryData.severity,options:options.offenseSeverity}} label="Severity" field="severity" onChange={this.onChange}/></div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Offense <Info style={infoInput} title="Title of the offense, such as 'Smoking in non-designated area'"/></div>
                            <div className="segment-item-input">
                                <Box ref="label"
                                     values={{value:entryData.label}}
                                     label="Offense"
                                     field="label"
                                     rules={{required:true}}
                                     onChange={this.onChange}
                                     />
                            </div>
                        </div>

                        <div className="segment-full-item">
                            <div className="segment-item-label">Penalty <Info style={infoInput} title="Each line represents the penalty at the corresponding occurrence. If the offender has more occurrences, it will default to the penalty at the final line."/></div>
                            <div className="segment-item-input">
                                {
                                    entryData.penalty.map(
                                        (item,index)=>{
                                            return (
                                                <div className="offense-add-item" style={{marginTop:(index == 0) ? "0" : "5px"}}>
                                                    <div style={{width:"15px",height:"15px",paddingRight:"5px"}}>
                                                        <MiniMinusButton enabled={(index != 0)} onClick={()=>{this.onRemovePenalty(index)}}/>
                                                    </div>
                                                    <div style={{flex:1}}>
                                                        <Box ref={"p-"+index}
                                                             field="value"
                                                             label={"Penalty " + (index+1)}
                                                             index={(index)}
                                                             onChange={this.onChangePenalty}
                                                             rules={{required:true, placeholder:"Penalty Description"}}
                                                             />
                                                    </div>
                                                    <div style={{width:"100px",marginLeft:"10px"}}>
                                                        <Box ref={"d-" + index}
                                                             field="demerits"
                                                             label={"Demerit " + (index+1)}
                                                             index={(index)}
                                                             onChange={this.onChangePenalty}
                                                             type="number"
                                                             rules={{required:true,placeholder:"Demerits",minValue:0}}
                                                             />
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                {
                                    (entryData.penalty.length < 10)
                                        ?
                                        <div style={{textAlign:'right',marginTop:"10px",fontSize:"0.85em"}}>
                                            <TextButton style={{margin:"0"}} onClick={this.onAddPenalty}>Add Penalty</TextButton>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                        </div>

                        <div className="segment-buttons">
                            <TextButton style={{margin:"0",fontSize:"14px"}} onClick={()=>{this.refs[this.props.reference].onBulkUpload()}}>Upload Spreadsheet</TextButton>
                        </div>

                        <div className="segment-buttons">
                            {
                                (u.isMobileDevice())
                                    ?
                                    <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                    :
                                    null
                            }
                            <Button type="medium" onClick={this.onValidate}>Create</Button>
                        </div>
                    </div>
                </div>

            </AddListItem>
        )
    }
}
