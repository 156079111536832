import React from 'react';
import store from 'store';
import u from '../../utilities/Utilities';
import PortalLayout from '../../components/PortalLayout';
import {Databox} from '../../components/Common';
import {Link} from 'react-router-dom';
import "../../styles/dashboard.scss";
import moment from 'moment';

export default class PortalDashboard extends React.Component{

    state={
        action:"No Permission",
        actionLabel:"",
        latestEvents:[],
        name:u.getCookie("clientUserName"),
        client:u.getCookie("clientName"),
        avatar:u.getUserAvatar(),
        quickSelected:"",
        quickActions:[],
    };

    componentDidMount=()=>{
        const dashboardPermissions = u.getPermissions("dashboard");
        let dashboardCount=0;
        for(var key in dashboardPermissions){
            if(dashboardPermissions[key]){
                dashboardCount++;
            }
        }
        this.setState({
            quickActions:[
                {label:"Logout",ref:"logout",image:"/images/dashboard/logout.png",permission:true},
            ]
        });

        const action = store.get("dashboard-action");
        const actionLabel = store.get("dashboard-action-label");
        store.remove("dashboard-action");
        store.remove("dashboard-action-label");
        this.setState({action:action,actionLabel:actionLabel});
        this.getLatestEvents();
    }

    getLatestEvents=()=>{
        u.post({
            url:"/client/cp-get-dashboard-history",
            data:{},
            success:(callback)=>{
                this.setState({latestEvents:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onQuickAction=(e)=>{
        const index = parseInt(e.currentTarget.id);
        const target=this.state.quickActions[index];

        if(target.ref=="logout"){
            u.cpLogout();
            return 0;
        }

        if(target.ref == this.state.quickSelected){
            this.setState({quickSelected:"none"});
        }
        else{
            this.setState({quickSelected:target.ref});
        }
    }

    onCancelQuickAction=()=>{
        this.setState({quickSelected:""});
    }

    render=()=>{
        return (
            <PortalLayout settings={{title:"Dashboard",permission:"none",url:"/dashboard"}}>

                {
                    (this.state.action == "No Permission")
                        ?
                        <div style={{textAlign:"center"}} className="text-negative">No permission to access {this.state.actionLabel}. Please request for the permission from your system administrator</div>
                        :
                        ""
                }

                <Databox active={true} align="center" margin={"30px auto"}>
                    <img src={u.getAvatarLink(this.state.avatar)} style={{width:"100px",maxWidth:"50%",margin:"auto",borderRadius:"50%"}}/>

                    <h2 className="theme-emphasis" style={{marginBottom:"5px"}}>Welcome Back, {this.state.name}</h2>
                    <div style={{fontSize:"12px"}}>(Representative of {this.state.client})</div>

                    <div className="pending-tasks">
                        <h3>My Latest Events</h3>
                        <div style={{width:"750px",maxWidth:"95%",margin:"auto",border:"1px solid #f4f4f4",borderRadius:"5px"}}>
                        {
                            (this.state.latestEvents.length > 0)
                                ?
                                <div>
                                    <div style={{maxHeight:"330px",overflow:"auto"}}>
                                    {
                                        this.state.latestEvents.map(
                                            (item,index)=>{
                                                const isToday = (moment().diff(item.dateTime,"days") == 0);
                                                return (
                                                    <div key={index} className="pending-task-item">
                                                        <div className="pending-task-event">
                                                            <span>{u.capitalize(item.action,true)} </span>
                                                            <span>{(item.targetLink==null) ? item.targetName : <Link to={item.targetLink} id={item.targetLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.targetName}</Link>}</span>
                                                            <span>{(item.targetField==null) ? "" : " (" + item.targetField + ")"}</span>
                                                            <span>{(item.from == null) ? "" : ((item.fromLink == null) ? " from "+item.from : <span> from <Link to={item.fromLink} id={item.fromLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.from}</Link></span>)}</span>
                                                            <span>{(item.to == null) ? "" : ((item.toLink == null) ? " to "+item.to : <span> to <Link to={item.toLink} id={item.toLinkID} onClick={u.setLinkContext} onContextMenu={u.setLinkContext}>{item.to}</Link></span>)}</span>
                                                        </div>
                                                        <div className="pending-task-date">
                                                            {moment(item.dateTime).format((isToday) ? "h:mma" : "DD MMM YY")}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    </div>
                                </div>
                                :
                                <div className="pending-task-empty">No events performed</div>
                        }
                        </div>
                    </div>

                    <div className="quick-actions">
                        <h3>Quick Actions</h3>
                        <div>
                            {
                                this.state.quickActions.map(
                                    (item,index)=>{
                                        if(!item.permission){
                                            return null;
                                        }
                                        return (
                                            <div className="quick-action-item" key={index} id={index+""} onClick={this.onQuickAction}>
                                                <img src={item.image} style={{width:"50px"}}/>
                                                <div>{item.label}</div>
                                            </div>
                                        )
                                    }
                                )
                            }
                        </div>

                        <div style={{width:"1280px",maxWidth:"98%",margin:"auto"}}>
                            <div className="quick-action-content">
                                {
                                    this.state.quickActions.map(
                                        (item,index)=>{
                                          if(typeof item.component == "undefined"){
                                              return (<div key={index}/>)
                                          }
                                          return React.createElement(
                                              item.component,
                                              {
                                                  key:index,
                                                  active:(this.state.quickSelected == item.ref),
                                                  onCancel:this.onCancelQuickAction,
                                                  onComplete:item.onComplete,
                                                  dormOptions:this.state.dormOptions,
                                                  ref:item.ref,
                                              }
                                        );
                                        }
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </Databox>
            </PortalLayout>
        )
    }
}
