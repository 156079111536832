import React from 'react';
import AppLayout from '../../components/PortalLayout';
import OverviewList from '../../components/OverviewList';
import {PageMenu,CPGenerateSpreadsheet,CPBulkEdit,Step, LoadGraphic} from '../../components/Common';
import {Button,Checkbox} from '../../components/Input';
import u from '../../utilities/Utilities';
import {ResidentItem,ResidentDownloadTemplate,AddResident} from '../ResidentList';
import "../../styles/client-portal/portal-resident.scss";

export default class PortalResidentList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Resident",
        permission:"portal",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/resident-list",

        /**** Action Setup ****/
        addType:2,
        //addURL:"",
        //templateURL:"",
        bulkURL:"/client/cp-add-bulk-tenants",
        editUploadURL:"/client/cp-upload-resident-bulk-edit-template",
        editTemplateURL:"/client/cp-download-resident-bulk-edit-template",
        spreadsheetURL:"/client/cp-generate-tenant-spreadsheet",
        overviewURL:"/client/cp-get-all-tenants",
        listSettingsURL:"/client/cp-apply-list-user-settings",
        macroURL:"/client/cp-update-user-tag-macro",

        /**** List Setup ****/
        list:true,
        listLink:"/portal/resident/",
        listLabel:"cp-resident-list",
        orderByField:"tenantName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)},
        onCancelPopup:()=>{this.refs['layout'].onCancelPopup()}
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
        promptConfirmResident:false,
    };

    componentDidMount=()=>{
        this.checkPromptConfirm();
        const permissions = u.getPermissions("portal");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (bulkEditOptions.length > 0);

        this.setState({
            pageMenuButtons:[
                {label:"Add Residents",ref:"add",enabled:(permissions.view==2)},
                {label:"Generate Spreadsheet",ref:"spreadsheet",enabled:(permissions.view==2)},
                {label:"Bulk Edit",ref:"bulkEdit",enabled:bulkEditEnabled,padding:"3px"},
            ],
            permissions:permissions,
            clientID:u.getCookie("clientID"),
            clientName:u.getCookie("clientName"),
        });
    }

    checkPromptConfirm=()=>{
        u.post({
            url:"/client/cp-prompt-confirm-assignment",
            data:{},
            success:({prompt})=>{
                this.setState({promptConfirmResident:prompt});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        const {clientID,clientName,promptConfirmResident}=this.state;
        const pageComponents={
            add:{
                component:AddResident,
                props:{
                    client:{clientID:clientID,clientName:clientName},
                    overrideURL:{
                        templateURL:"/client/cp-get-add-tenant-template",
                        bulkAddURL:"/client/cp-add-bulk-tenants",
                        validateURL:"/client/cp-validate-tenant-upload",
                        getEstateURL:"/client/cp-get-estate-options",
                        getAgreementURL:"/client/cp-get-client-agreement-options",
                        downloadFileURL:'/client/cp-download-file',
                    }
                },
            },
            download:{component:ResidentDownloadTemplate,props:{}},
            spreadsheet:{component:CPGenerateSpreadsheet,props:{}},
            bulkEdit:{component:CPBulkEdit,props:{}},
        };

        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}
                          selected={this.state.selected}/>

                {
                    (promptConfirmResident)
                        ?
                        <ConfirmResident onConfirm={()=>{this.setState({promptConfirmResident:false})}}/>
                        :
                        null
                }
                

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ResidentItem key={index}
                                              index={index}
                                              content={content}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              settings={this.settings}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }

}

class ConfirmResident extends React.Component{
    state={
        step:"view",
        loading:false,
        loadText:"",
    };

    onConfirm=()=>{
        this.setState({loading:true,loadText:"Updating..."});
        u.post({
            url:"/client/cp-confirm-assignment",
            data:{
                testDelay:1000,
            },
            success:()=>{
                this.props.onConfirm();
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    render=()=>{
        const {step,loading,loadText,declare}=this.state;
        return(
            <div className='sticky-resident'>
                <LoadGraphic active={loading} text={loadText} opacity={"0.9"}/>
                <Step active={step=="view"}>
                    <h3 className='sticky-title'>Please confirm that your resident's room/bed assignments are accurate</h3>
                    <ul className='sticky-list'>
                        <li>To ensure accurate reporting, ensure that your residents stay in the assigned room/bed</li>
                        <li>Any update to room/bed assignments should be updated in the Transfer Module</li>
                        <li>Use the Generate Spreadsheet function to export your residents assignments</li>
                    </ul>
                    <div className='sticky-buttons'>
                        <Button onClick={()=>{this.setState({step:"confirm"})}}>Confirm</Button>
                    </div>
                </Step>
                <Step active={step=="confirm"}>
                    <h3 className='sticky-title'>Confirm Verification Done?</h3>
                    <div className='sticky-declare'>
                        <Checkbox values={{value:declare}} onChange={(value)=>{this.setState({declare:value})}}/> 
                        I declare that my residents room & bed assignments are verified and accurate
                    </div>
                    <div className='sticky-buttons'>
                        <div className='page-error'>{this.state.error}</div>
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button enabled={(declare==true)} onClick={this.onConfirm}>Confirm</Button>
                    </div>
                </Step>
            </div>
        )
    }
}