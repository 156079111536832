import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import {PageMenu,Segment,Step,LoadGraphic,MobileMenu,AddListItem,
        Databox,DeleteListItem,DataboxGeneral,SegmentComplete,BulkEdit
        } from '../components/Common';
import {Box,Select,Button,Checkbox,TextButton,Info,
        LongBoolSlider,EditSizeLabel,EditSizeLink,EditableBox,
        EditableSelect,MiniMinusButton,MiniPlusButton,PageSelector} from '../components/Input';
import {Link} from 'react-router-dom';
import {PieChart} from './Reports';
import {AccessoryOwner} from './AccessoryList';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../styles/estate-list.scss';

export default class Estate extends React.Component{

    pageSettings={
        title:"Estate",
        permission:"estate",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/estate-list/asset/all",
    };

    defaultIconSize={
        asset:"large",
        dorm:"large",
        cluster:"large",
        building:"large",
        level:"small",
        room:"small",
        sickbay:"small",
    };

    state={
        estateType:"none",
        estateID:"none",
        error:"",
        loading:false,
        permissions:{},
        content: [],
        estate:[],
        sickbay:[],
        selected:{
            all:false,
            data:[]
        },
        selectMode:false,
        location:{},
        occupancyType:"physical",
        iconType:"large",
        showDetails:false,
        editMode:false,
        prefix:"none",
        pageMenuButtons:[],
        options:{},
        selectAll:false,
        combinedPermissions:{},
        accessories:[],
        agreementPermission:{},
        agreements:[],
    };

    componentDidMount=()=>{
        this.getOptions();
        const permissions = u.getPermissions("estate");
        const agreementPermission = u.getPermissions("agreement");
        this.setState({permissions:permissions,agreementPermission:agreementPermission});

        const occupancyType = store.get("occupancyType");
        if(occupancyType){
            this.setState({occupancyType:occupancyType});
        }

        const showDetails = store.get("show-details");
        if(showDetails){
            this.setState({showDetails:showDetails});
        }
    }

    static getDerivedStateFromProps(props,state){
        if(props.match.params.type != state.estateType || props.match.params.id != state.estateID){
            state.estateType = props.match.params.type;
            state.estateID = props.match.params.id;
            state.update=true;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            this.getContent(this.state.estateType,this.state.estateID);
            const permissions = this.state.permissions;
            const bulkEditOptions = u.getBulkEdit("estate-list");
            const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
            const pageMenuButtons=[
                {label:"Add",ref:"add",image:"/images/page-menu/add.png",mobileLabel:"Add Estate",title:"Add new estates",enabled:(permissions.create==2)},
                {label:"Edit",mobileLabel:"Update Details",ref:"edit",image:"/images/page-menu/edit.png",title:"Update estate details",enabled:(permissions.update == 2),padding:"3px"},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit estate details",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Delete",ref:"delete",image:"/images/page-menu/delete.png",mobileLabel:"Delete Estate",title:"Delete selected estates",enabled:(permissions.delete==2)},
                {label:"Manifest",ref:"manifest",image:"/images/page-menu/room-manifest.png",mobileLabel:"View Manifest",title:"View room manifest",enabled:(this.state.estateType == "room"),onClick:this.onGoToManifest,padding:"3px"},
            ];

            const occupancyType = store.get("occupancyType");
            if(occupancyType){
                this.setState({occupancyType:occupancyType});
            }

            const showDetails = store.get("show-details");
            if(showDetails){
                this.setState({showDetails:showDetails});
            }

            this.setState({
              update:false,
              pageMenuButtons:pageMenuButtons,
              permissions:permissions,
              combinedPermissions:u.getPermissions("combined")
            });
        }
    }

    getOptions=()=>{
        u.post({
          url:"/api/get-options",
          data:{
            keys:["yesNo","blockedReason","sickbayType"],
          },
          success:(options)=>{
              this.setState({options:options});
          },
          error:(error)=>{
              this.setState({error:error});
          }
        });
    }

    getContent=(type,id)=>{
        let estateType = (type) ? type : this.state.estateType;
        let estateID = (id) ? id : this.state.estateID;
        this.setState({loading:true});
        u.post({
            url:"/api/get-estate-info",
            data:{
                type:estateType,
                id:estateID,
            },
            success:({location,label,prefix,estates,accessories,agreements})=>{
                //Check iconType
                let defaultIconType = this.defaultIconSize[estateType];
                const userIconType = store.get("iconType-"+estateType);
                const iconType = (userIconType) ? userIconType : defaultIconType;

                this.setState({
                  loading:false,
                  label:label,
                  location:location,
                  content:estates,
                  estate:estates.filter((item)=>{return item.type != "sickbay"}),
                  sickbay:estates.filter((item)=>{return item.type == "sickbay"}),
                  prefix:prefix,
                  selectAll:false,
                  accessories:(accessories) ? accessories : [],
                  selected:{all:false,data:[]},
                  iconType:iconType,
                  showDetails: (iconType=="small") ? false : this.state.showDetails,
                  agreements:(agreements) ? agreements : [],
                });
            },
            error:(error)=>{
                this.setState({
                  error:error,
                  loading:false});
            }
        });
    }

    onContentChange=(list,value,field,index,onError)=>{
        if(list=="sickbay"){
            let sickbay = this.state.sickbay;
            sickbay[index][field] = value;
            if(field == "blocked" && value == 0){
                sickbay[index].blockedReason = undefined;
            }
            this.setState({sickbay:sickbay});
        }
        else{
            let estate = this.state.estate;
            estate[index][field] = value;
            if(field == "blocked" && value == 0){
                estate[index].blockedReason = undefined;
            }
            this.setState({estate:estate});
        }

    }

    onChangeAccessories=(value)=>{
        this.setState({accessories:value});
    }

    onSelectChange=(list,value,label,index)=>{
        let selected = this.state.selected;
        let obj = (list=="sickbay") ? this.state.sickbay[index] : this.state.estate[index];
        //Add to selected
        if(value){
            selected.data.push({type:obj.type,id:obj.location[obj.type+"ID"],index:index,list:list});
        }
        else{
            for(var i=selected.data.length-1; i>=0; i--){
                if(selected.data[i].id==label){
                    selected.data.splice(i,1);
                }
            }
        }
        this.setState({selected:selected});
    }

    onGoToManifest=()=>{
        store.set("room-manifest",{roomID:this.state.location.roomID});
        window.location="/room-manifest";
    }

    onChangeOccupancyType=(value)=>{
        const occupancyType = (value) ? "physical" : "booking";
        this.setState({occupancyType:occupancyType});
        store.set("occupancyType",occupancyType);
    }

    onChangeDetails=(value)=>{
        const showDetails = !this.state.showDetails;
        this.setState({showDetails:showDetails});
        store.set("show-details",showDetails);
        if(showDetails){
            this.onChangeIconType(true);
        }
    }

    onChangeIconType=(value)=>{
        const iconType = (value) ? "large" : "small";
        this.setState({iconType:iconType});
        store.set("iconType-" + this.state.estateType,iconType);
        if(iconType == "small" && this.state.showDetails){
            this.onChangeDetails();
        }
    }

    onRefreshList=()=>{
        this.getContent();
    }

    onChangeMenu=(value,ref)=>{
        if(!value){
          this.setState({
            selectMode:false,
            editMode:false,
            selected:{
              all:false,
              data:[]
            }});
        }
        else{
            if(ref == "bulkEdit" || ref == "delete"){
                this.setState({selectMode:true,editMode:false});
            }
            if(ref == "edit"){
                this.setState({editMode:!this.state.editMode,selectMode:false});
            }
        }
    }

    onSelectMode=(value,resetSelect)=>{
        this.setState({selectMode:value});
        if(resetSelect){
            this.setState({
              select:{
                  all:false,
                  data:[]
              }
            });
        }
    }

    onSelectAll=()=>{
        let selectAll = !this.state.selectAll;
        let selected=this.state.selected;

        if(selectAll){
            for(var i=0; i<this.state.estate.length; i++){
                const obj = this.state.estate[i];
                selected.data.push({type:obj.type,id:obj.location[obj.type+"ID"],index:i,list:"estate"});
            }
            for(var j=0; j<this.state.sickbay.length; j++){
                const obj = this.state.sickbay[j];
                selected.data.push({type:obj.type,id:obj.location[obj.type+"ID"],index:j,list:"sickbay"});
            }
        }
        else{
            selected={all:false,data:[]};
        }
        this.setState({all:selectAll,selected:selected,selectAll:selectAll});
    }

    render=()=>{
        const title=(
            <div className="estate-slider-container">
                <div className="estate-slider">
                    <LongBoolSlider label="Occupancy" value={this.state.occupancyType == "physical"} activeLabel="Physical" inactiveLabel="Booking" enabled={true} onChange={this.onChangeOccupancyType}/>
                </div>
                {
                    (this.state.estateType != "room")
                        ?
                        <div className="estate-slider">
                            <LongBoolSlider label="Details" value={this.state.showDetails} activeLabel="Show" inactiveLabel="Hidden" enabled={true} onChange={this.onChangeDetails}/>
                        </div>
                        :
                        null
                }
                <div className="estate-slider right">
                    <LongBoolSlider label="Icon Size" value={this.state.iconType == "large"} activeLabel="Large" inactiveLabel="Small" enabled={true} onChange={this.onChangeIconType}/>
                </div>
            </div>
        );
        const menuSettings={
            addType:1,
            addURL:"/api/add-estate",
            deleteURL:"/api/remove-estate",
            title:title,
            permission:"estate",    //Accepts combined permission module as well
            url:"/estate-list",
            list:true,
            noListOptions:true,
            listLabel:"estate-list",
        };
        const pageComponents={
            add:{component:AddEstate,props:{onRefreshList:this.getContent,type:this.state.estateType,id:this.state.estateID,options:this.state.options,onSelectMode:this.onSelectMode,selected:this.state.selected}},
            delete:{component:DeleteListItem,props:{onRefreshList:this.getContent}},
            edit:{component:EditEstateMode,props:{}},
            bulkEdit:{component:BulkEdit,props:{onRefreshList:this.getContent}},
        };
        const {content,estate,estateType,sickbay,permissions,combinedPermissions,
               agreementPermission,location,accessories,agreements} =this.state;
        const showDetails = (this.state.showDetails && !(estateType == "room" || estateType == "sickbay"));

        return (
            <AppLayout settings={this.pageSettings}>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={menuSettings}
                          components={pageComponents}
                          selected={this.state.selected}
                          titleComponent={<div></div>}
                          onChange={this.onChangeMenu}/>

                <div className="page-error">{this.state.error}</div>

                <div className={"estate-map" + ((this.state.iconType == "small" && !this.state.editMode) ? " small-map" : "")}>
                    <LoadGraphic active={this.state.loading} text="Preparing..."/>

                    <div className="estate-tags-container">
                        <h2 style={{marginBottom:0}}>{this.state.label}</h2>
                        <EstateTag content={this.state.location} color={"#54b848"} selectMode={this.state.selectMode}/>
                    </div>

                    {
                      (this.state.selectMode)
                        ?
                        <div style={{margin:"10px auto"}}><TextButton onClick={this.onSelectAll}>Select All</TextButton></div>
                        :
                        null
                    }

                    {
                        estate.map(
                            (item,index)=>{
                                return (
                                    <EstateItem key={index}
                                                index={index}
                                                prefix={this.state.prefix}
                                                content={item}
                                                selected={this.state.selected}
                                                selectMode={this.state.selectMode}
                                                iconType={this.state.iconType}
                                                occupancyType={this.state.occupancyType}
                                                editMode={this.state.editMode}
                                                options={this.state.options}
                                                showDetails={showDetails}
                                                onSelected={(value,label,index)=>{this.onSelectChange("estate",value,label,index)}}
                                                onChange={(value,label,index)=>{this.onContentChange("estate",value,label,index)}}
                                                />
                                )
                            }
                        )
                    }

                    {
                        (sickbay.length > 0)
                            ?
                            <div className="estate-divider">
                                <div className='estate-linethrough'/>
                                <div className="estate-text"><b>Sickbay</b></div>
                            </div>
                            :
                            null
                    }

                    <div>
                        {
                            sickbay.map(
                                (item,index,a)=>{
                                    return (
                                        <EstateItem key={index}
                                                    index={index}
                                                    prefix={this.state.prefix}
                                                    content={item}
                                                    selected={this.state.selected}
                                                    selectMode={this.state.selectMode}
                                                    iconType={this.state.iconType}
                                                    occupancyType={this.state.occupancyType}
                                                    editMode={this.state.editMode}
                                                    options={this.state.options}
                                                    onSelected={(value,label,index)=>{this.onSelectChange("sickbay",value,label,index)}}
                                                    onChange={(value,label,index)=>{this.onContentChange("sickbay",value,label,index)}}
                                                    showDetails={showDetails}
                                                    />
                                    )
                                }
                            )
                        }
                    </div>
                </div>

                {
                    (agreementPermission.view && (estateType == "room" || estateType == "bed"))
                        ?
                        <EstateAgreement content={agreements}/>
                        :
                        null
                }

                {
                    (combinedPermissions.accessory && (estateType == "room" || estateType == "sickbay"))
                        ?
                        <AccessoryOwner content={accessories}
                                        dormID={location.dormID}
                                        ownershipType="Room"
                                        onChange={this.onChangeAccessories}
                                        type={(estateType=="sickbay") ? "sickbay" : "room"}
                                        estate={{
                                            location:location,
                                            label: EstateUtilities.getNoLocationLabel(location,true,(estateType=="sickbay"))
                                        }}
                                        />
                        :
                        null
                }
            </AppLayout>
        )
    }
}

class EstateItem extends React.Component{

    state={
      selected:false
    };

    static getDerivedStateFromProps(props,state){
        state.selected = (props.selected.data.filter(
          (item)=>{
            return (item.id == props.content.location[props.content.type+"ID"])
          }).length > 0)
        return state;
    }

    getPieChart=(content,occupancy,vacancy,isEmpty)=>{
        const graph = (this.props.occupancyType == "booking") ? content.bookingNationality : content.physicalNationality;
        return {
          showLegend: false,
          backgroundColor: [content.color,"#f0f0f0"],
          data: [occupancy,vacancy],
          labels: (content.type == "sickbay" || this.props.occupancyType=="physical") ? ["Occupied","Vacant"] : ["Booked","Vacant"],
          graph:graph
        }
    }

    getLink=(content)=>{
        if(content.type == "sickbed" || content.type == "bed"){
            if(content.tenantID){
              return "/resident/" + content.tenantID;
            }
            else if(content.clientID){
              return "/client/"+content.clientID;
            }
            else {
              return "none";
            }
        }

        return "/estate-list/"+content.type+"/"+content.location[content.type+"ID"];
    }

    getOwner=(content)=>{
        /** Owner Priority
        * Check for transferPending
        * Check for blocked
        * Check for resident without occupancyType
        * Check for resident with physicalOccupancy if occupancyType == physical
        * Check for client
        * Vacant for bed or sickbed
        * PieChart
        */
        if(content.transferPending == true){
            return {
                name:"Transfer Pending",
                avatarLink:"/images/estate/blocked-bed.png",
                title:"Pending Transfer",
                style:{background:content.color},
            };
        }
        else if (content.blocked == true){
            return {
              name:(content.blockedReason == "Repurposed") ? content.repurposeTo : "Blocked",
              avatarLink:(content.blockedReason == "Repurposed") ? "/images/estate/repurpose-room.png" : "/images/estate/blocked-bed.png",
              title:"Blocked",
              fieldTwo:(content.blockedReason) ? content.blockedReason : "None",
              fieldThree:(content.blockedReason == "Repurposed") ? "Blocked" : null,
              style:{background:content.color},
            }
        }
        else if(this.props.occupancyType == "physical" && content.physicalOccupancy == 1 && content.tenantID){
            return {
              name:content.tenantName,
              avatarLink:content.tenantAvatarLink,
              title:"Occupied",

              labelTwo:(content.type == "sickbed") ? "Warded" : "Check-in",
              fieldTwo: (content.type == "sickbed") ? ((content.tenantEnwardDate) ? moment(content.tenantEnwardDate).format("DD MMM YYYY") : "None" ): ((content.checkInDate) ? moment(content.checkInDate).format("DD MMM YYYY") : "None"),
              labelThree:(content.type == "sickbed") ? "Release" : "Check-out",
              fieldThree:(content.type == "sickbed") ? ((content.tenantDewardDate) ? moment(content.tenantDewardDate).format("DD MMM YYYY") : "None" ): ((content.checkOutDate) ? moment(content.checkOutDate).format("DD MMM YYYY") : "None"),
            };
        }
        else if(this.props.occupancyType == "booking" && content.tenantID){
            return {
              name:content.tenantName,
              avatarLink:content.tenantAvatarLink,
              title:"Booked",

              labelTwo:(content.type == "sickbed") ? "Warded" : "Check-in",
              fieldTwo: (content.type == "sickbed") ? ((content.tenantEnwardDate) ? moment(content.tenantEnwardDate).format("DD MMM YYYY") : "None" ): ((content.checkInDate) ? moment(content.checkInDate).format("DD MMM YYYY") : "None"),
              labelThree:(content.type == "sickbed") ? "Release" : "Check-out",
              fieldThree:(content.type == "sickbed") ? ((content.tenantDewardDate) ? moment(content.tenantDewardDate).format("DD MMM YYYY") : "None" ): ((content.checkOutDate) ? moment(content.checkOutDate).format("DD MMM YYYY") : "None"),
            };
        }
        else if(this.props.occupancyType == "booking" && content.clientID){
            return {
              name:content.clientName,
              avatarLink:"/images/estate/booked-room.png",
              title:"Booked",
              fieldTwo:"Booked",
              style:{background:content.color},
            };
        }
        else if(content.type == "sickbed" || content.type == "bed"){
            return {
              name:"Vacant",
              avatarLink:"/images/estate/vacant-room.png",
              title:"Vacant",
            };
        }

      return {
          name:"pie"
      };
    }

    getLabel=(content)=>{
        if(typeof this.props.prefix == "undefined"){
            return content.label;
        }
        if(content.type == "sickbay"){
            return content.label;
        }
        let prefix = this.props.prefix;
        prefix=prefix.replace(/dormName/g,content.location.dormName);
        prefix=prefix.replace(/clusterName/g,content.location.clusterName);
        prefix=prefix.replace(/buildingName/g,content.location.buildingName);
        prefix=prefix.replace(/levelName/g,content.location.levelName);
        prefix=prefix.replace(/roomName/g,content.location.roomName);
        prefix=prefix.replace(/bedName/g,content.location.bedName);
        return prefix + content.label;
    }

    render=()=>{
        const content=this.props.content;
        const isEmpty = (content.capacity == 0);
        const capacity = (isEmpty) ? 1 : content.capacity;
        const occupancy = (this.props.occupancyType == "booking") ? content.bookingOccupancy : content.physicalOccupancy;
        const vacancy = (isEmpty) ? 1 : capacity-occupancy;
        const pieChart=this.getPieChart(content,occupancy,vacancy,isEmpty);
        const occupancyPercentage=(occupancy/content.capacity*100.00).toFixed(2);
        const to =this.getLink(content);
        const owner = this.getOwner(content);
        const label = this.getLabel(content);

        return(
            <div className={"estate-card-container" + ((this.props.iconType == "small" && !this.props.editMode) ? " small-mode" : "")}>
                {
                    (this.props.selectMode)
                        ?
                        <div style={{display:"inline-block",position:"relative",zIndex:"2"}}>
                            <Checkbox values={{value:this.state.selected}}
                                      label={content.location[content.type+"ID"]}
                                      index={this.props.index}
                                      onChange={this.props.onSelected}
                                      />
                        </div>
                        :
                        ""
                }

                {
                  (this.props.editMode)
                    ?
                    <EditEstate content={content}
                                label={label}
                                index={this.props.index}
                                options={this.props.options}
                                onChange={this.props.onChange}/>
                    :
                    (
                      (this.props.iconType == "small")
                        ?
                        <SmallEstate content={content}
                                     owner={owner}
                                     occupancy={occupancy}
                                     vacancy={vacancy}
                                     pieChart={pieChart}
                                     label={label}
                                     to={to}
                                     selectMode={this.props.selectMode}
                                     occupancyType={this.props.occupancyType}
                                     showDetails={this.props.showDetails}
                                     isEmpty={isEmpty}
                                     />
                        :
                        <LargeEstate content={content}
                                     owner={owner}
                                     occupancy={occupancy}
                                     vacancy={vacancy}
                                     pieChart={pieChart}
                                     label={label}
                                     to={to}
                                     selectMode={this.props.selectMode}
                                     occupancyPercentage={occupancyPercentage}
                                     occupancyType={this.props.occupancyType}
                                     showDetails={this.props.showDetails}
                                     isEmpty={isEmpty}
                                     />
                    )
                }
            </div>
        )
    }
}

class LargeEstate extends React.Component{
    render=()=>{
        const content = this.props.content;
        const occupancy = this.props.occupancy;
        const vacancy=this.props.vacancy;
        const loose = (typeof this.props.clientID == "undefined");
        const owner = this.props.owner;
        const to = this.props.to;
        const isEmpty = this.props.isEmpty;
        const pieChart=this.props.pieChart;

        return (
            <Link to={to} disabled={(this.props.selectMode || to == "none")}>
                <div className="estate-card">
                    {
                        (content.type=="sickbay")
                            ?
                            <img src="/images/sickbay/icon.png" title="Sickbay" style={{position:"absolute",top:"2px",right:"2px",width:"20px"}}/>
                            :
                            ""
                    }

                    {
                        (owner.name == "pie")
                            ?
                            <div className="estate-chart">
                                <PieChart content={pieChart}
                                          isEmpty={isEmpty}
                                          noBorder={pieChart.noBorder}
                                          toolTipOverride={(label,value)=>{return label}}
                                          redraw={true}
                                          />
                            </div>
                            :
                            <div className="estate-chart image">
                                <div className="estate-image-holder"
                                     style={owner.style}
                                     title={owner.title}>
                                    <img src={owner.avatarLink}
                                         className="estate-image"
                                         />
                                </div>
                            </div>
                    }

                    <div className="estate-details">
                        <div className="estate-details-name" style={{color:content.color}}><b>{this.props.label}</b></div>
                        {
                            (owner.name == "pie")
                              ?
                              <div className="estate-details-items">
                                  {
                                      (content.type == "sickbay")
                                          ?
                                          <div style={{color:content.color}}><b>{content.sickbayType}</b></div>
                                          :
                                          <div><div className={"ed-left " + content.type}>Fill Rate:</div>{(isEmpty) ? "0" : this.props.occupancyPercentage}%</div>
                                  }
                                  <div><div className={"ed-left " + content.type}>{(this.props.occupancyType == "physical") ? "Occupied" : "Booked"}:</div>{(occupancy)}</div>
                                  <div><div className={"ed-left " + content.type}>Vacant:</div>{(isEmpty) ? "0" : vacancy}</div>
                              </div>
                              :
                              <div className="estate-details-items">
                                  <div style={{color:content.colors}}>{(owner.label) ? <div className={"ed-left " + content.type}>{owner.label}:</div> : null}{owner.name}</div>
                                  <div>{(owner.labelTwo) ? <div className={"ed-left " + content.type}>{owner.labelTwo}:</div> : null}{(owner.fieldTwo) ? owner.fieldTwo : "-"}</div>
                                  <div>{(owner.labelThree) ? <div className={"ed-left " + content.type}>{owner.labelThree}:</div> : null}{(owner.fieldThree) ? owner.fieldThree : "-"}</div>
                              </div>
                        }
                    </div>

                    {
                        (this.props.showDetails && pieChart.graph)
                            ?
                            <div className="estate-nationality">
                                {
                                    (pieChart.graph.length > 0)
                                        ?
                                        <div className="estate-detail-container">
                                            <div className="estate-location-detail">
                                                {
                                                    (content.type=="sickbay")
                                                        ?
                                                        null
                                                        :
                                                        <b>Location: (In) <span className="text-emphasis">{content.locationIn} Pax</span> - (Out) <span className="text-negative">{content.locationOut} Pax</span></b>
                                                }
                                            </div>

                                            <div className="estate-nationality-table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th className="country">Nationality</th>
                                                            <th className="count">Resident</th>
                                                            <th className="capacity">Capacity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            pieChart.graph.map(
                                                                (item,index)=>{
                                                                    return(
                                                                        <tr key={index}>
                                                                            <td className="country">{item.label}</td>
                                                                            <td className="count">{item.count}</td>
                                                                            <td className="capacity">{(parseFloat(item.count)/parseFloat(content.capacity) * 100).toFixed(1)}%</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <div className="nationality-placeholder">
                                            <b>No Residents</b>
                                        </div>
                                }
                            </div>
                            :
                            null
                    }
                </div>
            </Link>
        )
    }
}

class SmallEstate extends React.Component{

    render=()=>{
        const content = this.props.content;
        const occupancy = this.props.occupancy;
        const vacancy=this.props.vacancy;
        const loose = (typeof this.props.clientID == "undefined");
        const owner = this.props.owner;
        const to = this.props.to;

        return(
            <Link to={to} className={"estate-link" + ((to == "none" || this.props.selectMode || this.props.editMode) ? " disabled" : "")}>
                <div className="estate-card small-mode">
                    {
                        (content.type=="sickbay")
                            ?
                            <img src="/images/sickbay/icon.png" title="Sickbay" style={{position:"absolute",top:"2px",right:"2px",width:"20px"}}/>
                            :
                            ""
                    }

                    <div className="small-estate-name" style={{color:content.color}}>
                        <b>{this.props.label}</b>
                    </div>

                    {
                        (owner.name == "pie")
                            ?
                            <div className="estate-chart">
                                <PieChart content={this.props.pieChart}
                                          isEmpty={this.props.isEmpty}
                                          noBorder={this.props.pieChart.noBorder}
                                          toolTipOverride={(label,value)=>{return label}}
                                          redraw={true}
                                          />
                            </div>
                            :
                            <div className="estate-chart image">
                                <div className="estate-image-holder"
                                     style={owner.style}
                                     title={owner.title}>
                                    <img src={owner.avatarLink}
                                         className="estate-image"
                                         />
                                </div>
                            </div>
                    }

                    <div className="estate-owner">
                        {
                            (owner.name == "pie")
                                ?
                                occupancy+" / "+content.capacity
                                :
                                owner.name
                        }
                    </div>

                </div>
            </Link>
          )
    }
}

class EditEstate extends React.Component{

    estateParams={
        dorm:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"box",label:"Code",field:"code",rules:{required:true}},
            {dataType:"box",label:"Block Name",field:"blockName",rules:{required:true}},
            {dataType:"box",label:"Street Name",field:"streetName",rules:{required:true}},
            {dataType:"box",label:"Postal Code",field:"postalCode",rules:{required:true},enabled:false},
        ],
        cluster:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"box",label:"Postal Code",field:"postalCode"},
        ],
        building:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"box",label:"Postal Code",field:"postalCode"},
        ],
        level:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
        ],
        room:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"select",label:"Recovery",field:"recovery",options:"yesNo"},
            {dataType:"select",label:"Blocked",field:"blocked",options:"yesNo"},
            {dataType:"select",label:"Room Status",field:"blockedReason",options:"blockedReason"},
            {dataType:"box",label:"Repurpose To",field:"repurposeTo"},
        ],
        bed:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"select",label:"Blocked",field:"blocked",options:"yesNo"},
            {dataType:"select",label:"Blocked Reason",field:"blockedReason",options:"blockedReason"},
        ],
        sickbay:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Type",field:"sickbayType",rules:{required:true,list:this.props.options.sickbayType.map((item)=>{return item.value}),listHeight:"120px"}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
        ],
        sickbed:[
            {dataType:"box",label:"Name",field:"label",rules:{required:true}},
            {dataType:"box",label:"Color",field:"color",rules:{required:true}},
            {dataType:"select",label:"Blocked",field:"blocked",options:"yesNo"},
            {dataType:"select",label:"Room Status",field:"blockedReason",options:"blockedReason"},
        ]
    }

    onChange=()=>{

    }

    render=()=>{
        const content=this.props.content;
        const id = {type:content.type,id:content.location[content.type+"ID"]};
        const url = "/api/update-estate-info";

        return(
            <div className="estate-edit-container">
                <div>
                    {
                        (content.type=="sickbay")
                            ?
                            <img src="/images/sickbay/icon.png" title="Sickbay" style={{position:"absolute",top:"2px",right:"2px",width:"20px"}}/>
                            :
                            ""
                    }

                    <h3 style={{color:content.color,margin:"5px auto"}}>
                        {this.props.label}
                    </h3>
                    {
                      this.estateParams[content.type].map(
                          (item,index)=>{
                              if(item.field == "repurposeTo" && content.blockedReason != "Repurposed"){
                                  return null;
                              }
                              return (
                                  <div key={index} className="estate-edit-item">
                                      <div className="estate-edit-label">{item.label}:</div>
                                      <div className="estate-edit-input">
                                            {
                                                (item.dataType=="box")
                                                  ?
                                                  <EditableBox values={{value:content[item.field],enabled:((typeof item.enabled != "undefined") ? item.enabled : true)}} label={item.label} field={item.field} index={this.props.index} id={id} url={url} onChange={this.props.onChange}/>
                                                  :
                                                  null
                                            }
                                            {
                                                (item.dataType=="select")
                                                  ?
                                                  <EditableSelect values={{value:content[item.field],options:this.props.options[item.options],enabled:((typeof item.enabled != "undefined") ? item.enabled : true)}} label={item.label} field={item.field} index={this.props.index} id={id} url={url} onChange={this.props.onChange}/>
                                                  :
                                                  null
                                            }
                                      </div>
                                  </div>
                              )
                          }
                      )
                    }
                </div>
            </div>
        )
    }
}

class EstateTag extends React.Component{
    render=()=>{
        const color=this.props.color;
        const content = this.props.content;
        return (
            <div className={"estate-tags" + (this.props.selectMode ? " disabled" : "")}>
                <div className="parent-map">
                    {
                        (content.ownerID)
                            ?
                            <Link to={"/estate-list/asset/all"}>
                                <div className="tag" style={{marginBottom:"5px"}}>
                                    <div className="tag-label clickable" style={{backgroundColor:color}}>
                                        {content.ownerName}
                                    </div>
                                </div>
                            </Link>
                            :
                            ""
                    }
                    {
                        (content.dormID)
                            ?
                            <span>
                                <span> &gt; </span>
                                <Link to={"/estate-list/dorm/"+content.dormID}>
                                    <div className="tag" style={{marginBottom:"5px"}}>
                                        <div className="tag-label clickable" style={{backgroundColor:color}}>
                                            {content.dormName}
                                        </div>
                                    </div>
                                </Link>
                            </span>
                            :
                            ""
                    }
                    {
                        (content.clusterID)
                            ?
                            <span>
                                <span> &gt; </span>
                                <Link to={"/estate-list/cluster/"+content.clusterID}>
                                    <div className="tag" style={{marginBottom:"5px"}}>
                                        <div className="tag-label clickable" style={{backgroundColor:color}}>
                                            Zone {content.clusterName}
                                        </div>
                                    </div>
                                </Link>
                            </span>
                            :
                            ""
                    }
                    {
                        (content.buildingID)
                            ?
                            <span>
                                <span> &gt; </span>
                                <Link to={"/estate-list/building/"+content.buildingID}>
                                    <div className="tag" style={{marginBottom:"5px"}}>
                                        <div className="tag-label clickable" style={{backgroundColor:color}}>
                                            Blk {content.buildingName}
                                        </div>
                                    </div>
                                </Link>
                            </span>
                            :
                            ""
                    }
                    {
                        (content.levelID)
                            ?
                            <span>
                                <span> &gt; </span>
                                <Link to={"/estate-list/level/"+content.levelID}>
                                    <div className="tag" style={{marginBottom:"5px"}}>
                                        <div className="tag-label clickable" style={{backgroundColor:color}}>
                                            Level {content.levelName}
                                        </div>
                                    </div>
                                </Link>
                            </span>
                            :
                            ""
                    }
                    {
                        (content.roomID)
                            ?
                            <span>
                                <span> &gt; </span>
                                <Link to={"/estate-list/room/"+content.roomID}>
                                    <div className="tag" style={{marginBottom:"5px"}}>
                                        <div className="tag-label clickable" style={{backgroundColor:color}}>
                                            Room {content.roomName}
                                        </div>
                                    </div>
                                </Link>
                            </span>
                            :
                            ""
                    }
                    {
                        (content.sickbayID)
                            ?
                            <span>
                              <span> &gt; </span>
                              <Link to={"/estate-list/sickbay/"+content.sickbayID}>
                                  <div className="tag" style={{marginBottom:"5px"}}>
                                      <div className="tag-label clickable" style={{backgroundColor:color}}>
                                          Sickbay {content.sickbayName}
                                      </div>
                                  </div>
                              </Link>
                            </span>
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
}
EstateTag.propTypes={
    content:PropTypes.object,
    color:PropTypes.string,
};

class EditEstateMode extends React.Component{

  state={
      active:false
  };

  show=(show)=>{
      this.setState({active:show});
  }

  render=()=>{
      if(!this.state.active){
          return <div/>
      }
      return (
          <div className="estate-edit-segment mobile-hide">
              <h2 className="text-emphasis" style={{margin:"0 auto"}}>Edit Mode Active</h2>
          </div>
      )
  }
}

class AddEstate extends React.Component{

    typeMap={
        dorm:{value:"dorm",label:"Dormitory"},
        cluster:{value:"cluster",label:"Zone"},
        building:{value:"building",label:"Block"},
        level:{value:"level",label:"Level"},
        room:{value:"room",label:"Room"},
        bed:{value:"bed",label:"Bed"},
        sickbed:{value:"sickbed",label:"Sickbed"}
    };

    state={
        error:"",
        loading:false,
        typeOptions:[],
        entryData:{
            count:1,
        },
        loadText:"",
        additionalParams:[],
        options:{},
    };

    static getDerivedStateFromProps(props,state){
        if(props.type != state.type){
            state.type = props.type;
            state.update = true;
        }
        return state;
    }

    componentDidMount=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["sickbayType"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            //Map type options
            this.setState({selectMode:false,error:""});
            const nextType = EstateUtilities.getNextType(this.state.type);
            let typeOptions = [this.typeMap[nextType]];

            if(nextType == "room"){
                typeOptions.push({value:"bed",label:"Bed"});
                this.setState({selectMode:true});
            }

            if(nextType == "cluster" || nextType == "building" || nextType == "level" || nextType == "room"){
                typeOptions.push({value:"sickbay",label:"Sickbay"});
            }

            let entryData=this.state.entryData;
            entryData.type = undefined;

            this.setState({
              entryData:entryData,
              typeOptions:typeOptions,
              update:false,
              nextType:nextType,
            });
        }
    }

    show=(show)=>{
        this.refs['add'].show(show);

        if(show){
            if(this.state.entryData.type == "bed" && this.state.nextType == "room"){
                this.props.onSelectMode(true,false);
            }
        }
    }

    onChange=(value,label,index)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;

        if(label == "type" && value == "bed" && this.state.nextType == "room"){
            this.props.onSelectMode(true,false);
        }
        else if (label == "type" && (value != "bed" || this.state.nextType != "room")){
            this.props.onSelectMode(false,true);
        }

        this.setState({entryData:entryData});
    }

    onSubmit=()=>{
        this.setState({error:""});
        const refs=["type","count","name","sickbayType","start","indexToLetters","prefix","suffix","length","color",];
        let data={};
        for(var i=0; i<refs.length; i++){
            if(this.refs[refs[i]]){
                const d = this.refs[refs[i]].validate();
                if(!d.valid){
                    this.setState({error:d.value});
                    return 0;
                }
                data[refs[i]] = d.value;
            }
        }

        if(this.state.entryData.type == "bed" && this.state.nextType == "room"){
            const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
            if(entries == 0){
                this.setState({error:"Select at least 1 room to add beds below"});
                return 0;
            }

            data.selected=this.props.selected;
        }

        const additionalParams = EstateUtilities.estateParams[data.type];
        for(var j=0; j<additionalParams.length; j++){
            const p = this.refs[additionalParams[j].field].validate();
            if(!p.valid){
                this.setState({error:p.value});
                return 0;
            }
            data[additionalParams[j].field] = p.value;
        }
        data.parent={type:this.props.type,id:this.props.id};
        this.refs['add'].onValidated(data);
    }

    onComplete=()=>{
        this.setState({
          entryData:{
              count:1,
          },
        });
        this.props.onSelectMode(false,true);
    }

    onCancel=()=>{
        this.setState({
            entryData:{
                count:1,
            },
        });
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        const entryData=this.state.entryData;
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        const sickbayTypeOptions = (this.state.options.sickbayType) ? this.state.options.sickbayType.map((item)=>{return item.value}) : [];
        return (
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onComplete}
                         onRefreshList={this.props.onRefreshList}
                         onToggleForm={this.props.onToggleForm}
                         >
                <div className="segment-error">{this.state.error}</div>
                <div style={{position:"relative"}}>
                    <h3>Complete the following to create an estate</h3>

                    <div className="segment-full-item">
                        <div className="segment-item-label">Estate Type:</div>
                        <div className="segment-item-input"><Select ref="type" label="Type" field="type" values={{value:entryData.type,options:this.state.typeOptions}} onChange={this.onChange}/></div>
                    </div>

                    <div className="segment-full-item">
                        <div className="segment-item-label">Number to Create:</div>
                        <div className="segment-item-input"><Box ref="count" label="Count" field="count" type="number" values={{value:entryData.count}} rules={{required:true,minValue:1}} onChange={this.onChange}/></div>
                    </div>

                    {
                      (entryData.count > 1)
                        ?
                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Start Index:</div>
                                <div className="segment-item-input"><Box ref="start" label="Start" field="start" type="number" values={{value:entryData.start}} rules={{required:true,minValue:1,defaultValue:1}} onChange={this.onChange}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Index To Letters <Info style={{width:"15px",height:"15px",marginBottom:"3px"}} title="Converts index from number to alphabets. Index '1-26' will change to letter 'A-Z', and subsequently 'AA-AZ', 'BA-BZ'..."/> :</div>
                                <div className="segment-item-input"><Select ref="indexToLetters" label="Index To Letters" field="indexToLetters" values={{value:entryData.indexToLetters,options:this.props.options.yesNo}} onChange={this.onChange}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Index Prefix <Info style={{width:"15px",height:"15px",marginBottom:"3px"}} title="Keywords such as Zone, Blk, Level, #Level-Room, Bed, are automatically added and should not be added here"/> :</div>
                                <div className="segment-item-input"><Box ref="prefix" label="Prefix" field="prefix" values={{value:entryData.prefix}} onChange={this.onChange}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Index Suffix:</div>
                                <div className="segment-item-input"><Box ref="suffix" label="Suffix" field="suffix" values={{value:entryData.suffix}} onChange={this.onChange}/></div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Index Length <Info style={{width:"15px",height:"15px",marginBottom:"3px"}} title="If Index Length is 2, and start index is 5, Estate Name will be 05. If index is already 2 digits, such as 10, no changes will be made"/> :</div>
                                <div className="segment-item-input"><Box ref="length" label="length" field="length" type="number" values={{value:entryData.length}} rules={{minValue:2,placeholder:"Leave blank for no padding"}} onChange={this.onChange}/></div>
                            </div>
                        </div>
                        :
                        <div className="segment-full-item">
                            <div className="segment-item-label">Estate Name:</div>
                            <div className="segment-item-input"><Box ref="name" label="Name" field="name" rules={{required:true}} onChange={this.onChange}/></div>
                        </div>
                    }

                    {
                        (entryData.type == "sickbay")
                            ?
                            <div className="segment-full-item" style={{zIndex:"3"}}>
                                <div className="segment-item-label">Sickbay Type:</div>
                                <div className="segment-item-input"><Box ref="sickbayType" label="Sickbay Type" field="sickbayType" rules={{required:true,list:sickbayTypeOptions,listHeight:"100px"}} onChange={this.onChange}/></div>
                            </div>
                            :
                            null
                    }

                    <div className="segment-full-item">
                        <div className="segment-item-label">Color <Info style={{width:"15px",height:"15px",marginBottom:"3px"}} title="If creating more than 1 estate and color is set, all estates will have the same color. If left empty, estates will receive a random color"/> :</div>
                        <div className="segment-item-input"><Box ref="color" label="Color" field="color" rules={{placeholder:"Random"}} onChange={this.onChange}/></div>
                    </div>

                    {
                        (typeof entryData.type != "undefined")
                          ?
                          EstateUtilities.estateParams[entryData.type].map(
                            (item,index)=>{
                                return (
                                  <div className="segment-full-item" key={index}>
                                      <div className="segment-item-label">{item.label}:</div>
                                      <div className="segment-item-input">
                                          {
                                            (item.dataType == "box")
                                              ?
                                              <Box ref={item.field} label={item.label} values={{value:entryData[item.field]}} rules={item.rules} onChange={this.onChange}/>
                                              :
                                              null
                                          }
                                          {
                                            (item.dataType == "select")
                                              ?
                                              <Select ref={item.field} label={item.label} values={{value:entryData[item.field],options:item.options}} rules={item.rules} onChange={this.onChange}/>
                                              :
                                              null
                                          }
                                      </div>
                                  </div>
                                )
                            }
                          )
                          :
                          null
                    }

                    {
                      (this.state.nextType=="room" && entryData.type == "bed")
                        ?
                        <div className="segment-selected-bg">
                            <div className="segment-selected"><u>{entries} {(entries==1) ? "room" : "rooms"} selected</u></div>
                            <div className="segment-note">Select rooms to attach beds below</div>
                        </div>
                        :
                        null
                    }

                    <div className="segment-buttons">
                        <span className="mobile-only"><Button type="medium" onClick={this.onCancel}>Cancel</Button></span>
                        <Button type="medium" onClick={this.onSubmit}>Create</Button>
                    </div>
                </div>
            </AddListItem>
        )
    }

}

class EstateAgreement extends React.Component{

    pageLength=10;

    state={
        page:1,
        maxPage:1,
        startIndex:0,
        endIndex:9,
    }

    onChangePage=(page)=>{
        let startIndex=(parseInt(page)-1)*this.pageLength;
        let endIndex=startIndex+this.pageLength-1;
        this.setState({page:page,startIndex:startIndex,endIndex:endIndex});
    }

    render=()=>{
        const content=this.props.content;
        const page=this.state.page;
        const maxPage = Math.ceil(content.length / parseFloat(this.pageLength));

        return(
            <Databox active={true} style={{position:"relative",textAlign:"center"}} title="Assigned Agreements">
                <div className='list-table-container' style={{position:"relative"}}>
                {
                    (content.length > 0)
                        ?
                        <div className="list-table" style={{width:"98%",maxHeight:"none"}}>
                            <table>
                                <thead>
                                    <tr>
                                        <th className="left">Agreement</th>
                                        <th>Estate</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Organization</th>
                                        <th className="right">Created By</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                      content.map((item,index)=>{
                                          if(index < this.state.startIndex || index > this.state.endIndex){
                                              return null;
                                          }
                                          return(
                                              <tr key={index}>
                                                  <td><Link to={"/agreement/"+item.agreementID}>{item.agreementReference}</Link></td>
                                                  <td>{item.location}</td>
                                                  <td>{moment(item.agreementStartDate).format("DD MMM YYYY")}</td>
                                                  <td>{moment(item.agreementEndDate).format("DD MMM YYYY")}</td>
                                                  <td>{item.clientName}</td>
                                                  <td>{item.createdByStaffName}</td>
                                              </tr>
                                          )
                                      })
                                  }
                                </tbody>
                            </table>

                            <div style={{margin:"30px auto 10px auto"}}>
                                <PageSelector page={page}
                                              maxPage={maxPage}
                                              onChange={this.onChangePage}
                                              />
                            </div>
                        </div>
                        :
                        <div className="list-placeholder">
                            <h3>No Agreements Assigned</h3>
                            <div><i>Refresh page to update this section</i></div>
                        </div>
                }
                </div>
            </Databox>
        )
    }
}

export class EstateUtilities {

    static hierarchy=[
        "dorm",
        "cluster",
        "building",
        "level",
        "room",
        "bed"
    ];

    static estateParams={
        dorm:[
            {dataType:"box",field:"blockName",label:"Block Name",rules:{required:true,placeholder:"Used if no blocks"}},
            {dataType:"box",field:"streetName",label:"Street Name",rules:{required:true}},
            {dataType:"box",field:"postalCode",label:"Postal Code",rules:{required:true,minLength:6,maxLength:6}},
            {dataType:"box",field:"code",label:"Code",rules:{required:true,placeholder:"Short form dormitory identifier",maxLength:10}},
        ],
        cluster:[
            {dataType:"box",field:"postalCode",label:"Postal Code",rules:{placeholder:"Leave blank if same as parent"}},
        ],
        building:[
            {dataType:"box",field:"postalCode",label:"Postal Code",rules:{placeholder:"Leave blank if same as parent"}},
        ],
        level:[],
        room:[],
        bed:[],
        sickbay:[],
        sickbed:[],
    };

    static getLabel(content,prefix){
        if(typeof prefix == "undefined"){
            return content.label;
        }
        if(content.type == "sickbay"){
            return content.label;
        }

        prefix=prefix.replace(/dormName/g,content.location.dormName);
        prefix=prefix.replace(/clusterName/g,content.location.clusterName);
        prefix=prefix.replace(/buildingName/g,content.location.buildingName);
        prefix=prefix.replace(/levelName/g,content.location.levelName);
        prefix=prefix.replace(/roomName/g,content.location.roomName);
        prefix=prefix.replace(/bedName/g,content.location.bedName);
        return prefix + content.label;
    }

    static getPrefixValue(content,prefix,noLocation){
      if(typeof prefix == "undefined"){
          return "";
      }

      prefix=prefix.replace(/dormName/g,(noLocation) ? content.dormName : content.location.dormName);
      prefix=prefix.replace(/clusterName/g,(noLocation) ? content.clusterName : content.location.clusterName);
      prefix=prefix.replace(/buildingName/g,(noLocation) ? content.buildingName : content.location.buildingName);
      prefix=prefix.replace(/levelName/g,(noLocation) ? content.levelName : content.location.levelName);
      prefix=prefix.replace(/roomName/g,(noLocation) ? content.roomName : content.location.roomName);
      prefix=prefix.replace(/bedName/g,(noLocation) ? content.bedName : content.location.bedName);
      return prefix;
    }

    static getLocationLabel(content,config){
        const l = content.location;

        return this.getPrefixValue(content,config.dormPrefix) + l.dormName +
               ((config.cluster) ? " > " + this.getPrefixValue(content,config.clusterPrefix) + l.clusterName : "") +
               ((config.building) ? " > " + this.getPrefixValue(content,config.buildingPrefix) + l.buildingName : "") +
               " > " + this.getPrefixValue(content,config.levelPrefix) + l.levelName;
    }

    static getNoLocationLabel(content,skipDorm,isSickbay){
        const l = content;
        const config = this.getDormConfig();

        if(isSickbay){
          return "Sickbay " + content.sickbayName;
        }

        return ((!skipDorm) ? this.getPrefixValue(content,config.dormPrefix,true) + l.dormName : "") +
               ((config.cluster) ? ((!skipDorm) ? " > " : "") + this.getPrefixValue(content,config.clusterPrefix,true) + l.clusterName : "") +
               ((config.building) ? ((!skipDorm || config.cluster) ? " > " : "") + this.getPrefixValue(content,config.buildingPrefix,true) + l.buildingName : "") +
               " > " + this.getPrefixValue(content,config.roomPrefix,true) + l.roomName + ((content.bedID) ? "/" + content.bedName : "");
    }

    static getDormConfig(){
      return store.get("dormConfig");
    }

    static getNextType(type){
        if(type == "sickbay"){
            return "sickbed";
        }
        if(type == "bed"){
            return "none";
        }
        const dormConfig = this.getDormConfig();
        const start = this.hierarchy.lastIndexOf(type) + 1;
        for(var i=start; i<this.hierarchy.length; i++){
            if(dormConfig[this.hierarchy[i]]){
                return this.hierarchy[i]
            }
        }

        return "none";
    }
}
