import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,
        AddListItem,DynamicListArea,DownloadTemplate,LoadGraphic,
        Dropzone,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Box,EditSizeLabel,Select,EditSizeLink,Time,
        TextButton,ContactNumber,Checkbox,MiniMinusButton,
        InputPhoto,Info,DayOfWeek,EmptyBox,BoolSlider,
        EditableDate,EditableArea,Date,Area} from '../components/Input';
import {Photo} from '../data/Mimetypes';
import {AddSearchTag,Tag} from '../components/OverviewList';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';
import "../styles/facility-list.scss";

export default class FacilityList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Facilities",
        permission:"facility",
        url:"/facility-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-facility-entry",
        deleteURL:"/api/delete-facility-entries",
        spreadsheetURL:"/api/generate-facility-spreadsheet",
        overviewURL:"/api/get-all-facility-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"facility-list",
        orderByField:"facilityName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    pageComponents={
        add:{component:AddFacility,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        settings:{component:FacilitySettings,props:{}},
    }

    componentDidMount=()=>{
        const permissions = u.getPermissions("facility");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {ref:"add",label:"Add Facility",enabled:(permissions.create==2)},
              {ref:"delete",label:"Delete Entries",enabled:(permissions.delete==2)},
              {ref:"spreadsheet",label:"Generate Spreadsheet",enabled:combined.spreadsheet},
              {ref:"bulkEdit",label:"Bulk Edit",image:"/images/page-menu/edit.png",enabled:bulkEditEnabled,padding:"3px"},
              {ref:"settings",label:"Configure Settings",ref:"settings",enabled:(permissions.view==2)},
              {ref:"bookings",label:"View Bookings",enabled:(permissions.view==2),onClick:this.onBookings},
            ],
        });
    }

    onBookings=()=>{
        window.location = "/booking-list";
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <FacilityItem key={index}
                                              content={content}
                                              index={index}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}
                                              onPopup={(content)=>{this.refs['layout'].onPopup(content)}}
                                              onCancelPopup={()=>{this.refs['layout'].onCancelPopup()}}
                                              permissions={this.state.permissions}
                                              />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }

}

class FacilityItem extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
        slotEntry:{
            allowed:[],
        },
        blackList:{},
        blockout:{},
    }

    getDow=(arr)=>{
        let label = "";
        const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        for(var i=0; i<arr.length; i++){
            if(arr[i] == 1){
                label += ((label.length > 0) ? " / " : "") + days[i];
            }
        }
        return label;
    }

    onViewImage=(index)=>{
        const image = this.props.content.photo[index];
        this.setState({
            step:"view-photo",
            selectedImage:image.url,
            selectedImageIndex:index,
            selectedImageName:image.filename,
        });
    }

    onUploadPhoto=(url)=>{
        this.setState({loading:true,loadText:"Processing...",error:""});
        u.post({
            url:"/api/add-facility-photo",
            data:{
              facilityID:this.props.content.facilityID,
              filename:url
            },
            success:()=>{
                let content=this.props.content;
                content.photo.push({filename:this.state.filename,url:url});
                this.props.onChange(content.photo,"photo",this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDeletePhoto=()=>{
        this.setState({loading:true});
        u.post({
            url:"/api/delete-facility-photo",
            data:{
                facilityID:this.props.content.facilityID,
                imageURL:this.state.selectedImage,
                imageName:this.state.selectedImageName,
            },
            success:()=>{
                let images = this.props.content.photo;
                images.splice(this.state.selectedImageIndex,1);
                this.props.onChange(images,"photo",this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onSlotRequest=(index)=>{
        this.setState({
          step:"slot",
          slotEntry:(index >= 0) ? this.props.content.bookingHours[index] : {timeStart:moment().format("HH:00:00"),timeEnd:moment().format("HH:00:00"),allowed:[]},
          editIndex:index
        });
    }

    onSlotChange=(value,label,index)=>{
        let slotEntry = this.state.slotEntry;
        slotEntry[label] = value;
        this.setState({slotEntry:slotEntry});
    }

    onAddTimeSlot=()=>{
        this.setState({error:""});
        let slotEntry = this.state.slotEntry;

        let keys=["dayOfWeek","timeStart","timeEnd"];
        for(var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        const editIndex = this.state.editIndex;
        let entryData = this.props.content;

        //Check if overlapping time slots
        const date=moment().format("YYYY-MM-DDT");
        const label = ["Sun","Mon","Tue","Wed","Thu","Sat","Sun"];
        for(var b=0; b<entryData.bookingHours.length; b++){
            if(b != this.state.editIndex){
                const existingSlot = entryData.bookingHours[b];
                for(var d=0; d<7; d++){
                    if(slotEntry.dayOfWeek[d] == 1 && existingSlot.dayOfWeek[d] == 1){
                        if(moment(date+slotEntry.timeStart).isSameOrBefore(date+existingSlot.timeStart) && moment(date+slotEntry.timeEnd).isSameOrAfter(date+existingSlot.timeStart)){
                            this.setState({error:"Time slot overlaps with existing " + label[d] + " - " + moment(date+existingSlot.timeStart).format("h:mma") + " " + moment(date+existingSlot.timeEnd).format("h:mma")});
                            return 0;
                        }
                    }
                }
            }
        }

        this.setState({loading:true,loadText:"Adding slot"});
        let newBooking = this.props.content.bookingHours.map((item)=>{return item});

        if(editIndex >= 0){
            newBooking[editIndex] = slotEntry;
        }
        else{
            newBooking.push(slotEntry);
        }

        u.post({
            url:"/api/update-facility-info",
            data:{
                old:this.props.content.bookingHours,
                new:newBooking,
                field:"bookingHours",
                id:this.props.content.facilityID
            },
            success:()=>{
                this.props.onChange(newBooking,"bookingHours",this.props.index);
                this.setState({step:"view",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onRemoveTimeSlot=(index)=>{
        const removeSlotFunction=()=>{
            let bookingHours = this.props.content.bookingHours.map((item)=>{return item});
            bookingHours.splice(index,1);
            this.setState({loading:true,loadText:"Removing slot..."});
            u.post({
                url:"/api/update-facility-info",
                data:{
                    old:this.props.content.bookingHours,
                    new:bookingHours,
                    field:"bookingHours",
                    id:this.props.content.facilityID
                },
                success:()=>{
                    this.props.onChange(bookingHours,"bookingHours",this.props.index);
                    this.setState({loading:false,step:"view"});
                },
                error:(error)=>{
                    this.setState({error:error,loading:false});
                }
            })
        }

        this.props.onPopup({
          confirm:removeSlotFunction,
          cancel:this.props.onCancelPopup,
          title:"Confirm Delete Slot?",
          description:"Note. All active bookings of that time slot will be cancelled",
        });
    }

    onChangeTags=(allowed)=>{
        let slotEntry = this.state.slotEntry;
        slotEntry.allowed = allowed;
        this.setState({slotEntry:slotEntry});
    }

    onAddToBlacklist=()=>{
        this.setState({error:""});
        const keys=["nric","createFor","expireOn","remark"];
        let data={};
        for(var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }
        this.setState({loading:true,loadText:"Adding to blacklist..."});
        u.post({
            url:"/api/add-blacklist-entry",
            data:{
                ...data,
                facilityID:this.props.content.facilityID,
            },
            success:(newBlacklist)=>{
                let blacklist=this.props.content.blacklist;
                blacklist.unshift(newBlacklist);
                this.props.onChange(blacklist,"blacklist",this.props.index);
                this.setState({loading:false,step:"view-blacklist",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false})
            }
        })
    }

    onRemoveBlacklistRequest=(index)=>{
        let content=this.props.content;
        this.setState({
            step:"remove-blacklist",
            blackList:content.blacklist[index],
            blackListIndex:index,
            error:""
        });
    }

    onRemoveFromBlacklist=()=>{
        this.setState({loading:true,loadText:"Removing from blacklist..."});
        u.post({
            url:"/api/remove-blacklist-entry",
            data:{
                facilityID:this.props.content.facilityID,
                entry:this.state.blackList,
            },
            success:()=>{
                let content=this.props.content;
                content.blacklist.splice(this.state.blackListIndex,1);
                this.props.onChange(content.blacklist,"blacklist",this.props.index);
                this.setState({loading:false,step:"view-blacklist"});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChangeBlacklist=(value,label,index)=>{
        let blacklist = this.props.content.blacklist;
        blacklist[index][label]=value;
        this.props.onChange(blacklist,"blacklist",this.props.index);
    }

    onAddToBlockout=()=>{
        this.setState({error:""});
        const keys=["reason","startDate","startTime","endDate","endTime"];
        let data={};
        for(var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }

        data["startDateTime"] = data["startDate"] + " " + data["startTime"];
        data["endDateTime"] = data["endDate"] + " " + data["endTime"];

        if(moment(data.startDateTime).isAfter(data.endDateTime)){
            this.setState({error:"Start On/Time must be before End On/Time"});
            return 0;
        }

        this.setState({loading:true,loadText:"Adding to blockout..."});
        u.post({
            url:"/api/add-blockout-entry",
            data:{
                ...data,
                facilityID:this.props.content.facilityID,
            },
            success:(newBlockout)=>{
                let blockout=this.props.content.blockout;
                blockout.push(newBlockout);
                blockout.sort((a,b)=>{
                    return moment(a.startDateTime).diff(b.startDateTime,"mins");
                })
                this.props.onChange(blockout,"blockout",this.props.index);
                this.setState({loading:false,step:"view-blockout",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false})
            }
        })
    }

    onRemoveBlockoutRequest=(index)=>{
        let content=this.props.content;
        this.setState({
            step:"remove-blockout",
            blockout:content.blockout[index],
            blockoutIndex:index,
            error:""
        });
    }

    onRemoveFromBlockout=()=>{
        this.setState({loading:true,loadText:"Removing from blockout..."});
        u.post({
            url:"/api/remove-blockout-entry",
            data:{
                facilityID:this.props.content.facilityID,
                entry:this.state.blockout,
            },
            success:()=>{
                let content=this.props.content;
                content.blockout.splice(this.state.blockoutIndex,1);
                this.props.onChange(content.blockout,"blockout",this.props.index);
                this.setState({loading:false,step:"view-blockout"});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChangeBlockout=(value,label,index)=>{
        let blockout = this.props.content.blockout;
        blockout[index][label]=value;
        this.props.onChange(blockout,"blockout",this.props.index);
    }

    render=()=>{
        const {content,permissions} = this.props;
        const {slotEntry,blockout} = this.state;

        return (
            <div style={{position:"relative",textAlign:"center"}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step=="view"}>
                    <DynamicListArea listLabel={"facility-list"}
                                     permission="facility"
                                     content={this.props.content}
                                     onChange={this.props.onChange}
                                     index={this.props.index}
                                     />

                    <div className="list-segment-table">

                          <div className="segment-table">
                              <b>Booking Slots</b>
                              {
                                  (content.bookingHours.length > 0)
                                  ?
                                  <table style={{margin:"15px auto"}}>
                                      <thead>
                                        <tr>
                                          <th className="segment-table-button left"/>
                                          <th>Day of Week</th>
                                          <th>Time</th>
                                          <th>Residents</th>
                                          <th className="segment-table-button right"/>
                                        </tr>
                                      </thead>
                                      <tbody>
                                          {
                                              content.bookingHours.map(
                                                  (item,index)=>{
                                                      return (
                                                        <tr className="item" key={index}>
                                                            <td className="segment-table-button">
                                                                <div style={{width:"15px",height:"15px"}}>
                                                                    <MiniMinusButton enabled={(permissions.update)} onClick={()=>{this.onRemoveTimeSlot(index)}}/>
                                                                </div>
                                                            </td>
                                                            <td>{this.getDow(item.dayOfWeek)}</td>
                                                            <td>{moment("2020-01-01T"+item.timeStart).format("h:mma")} - {moment("2020-01-01T"+item.timeEnd).format("h:mma")}</td>
                                                            <td>
                                                            {
                                                              (item.allowed.length > 0)
                                                                ?
                                                                item.allowed.map(
                                                                    (tag,t)=>{
                                                                        return(
                                                                          <Tag key={t}
                                                                               index={t + ""}
                                                                               clickable={true}
                                                                               label={tag}
                                                                               onRemove={()=>{}}
                                                                               values={{removable:false}}
                                                                               />
                                                                          )
                                                                    }
                                                                )
                                                                :
                                                                "All"
                                                            }
                                                            </td>
                                                            <td className="segment-table-button">
                                                                <img src="/images/input/edit.png" className={"segment-table-button-image" + ((permissions.update) ? "" : " disabled")} onClick={()=>{this.onSlotRequest(index)}}/>
                                                            </td>
                                                        </tr>
                                                      )
                                                  }
                                              )
                                          }
                                      </tbody>
                                  </table>
                                  :
                                  <div className="segment-placeholder">
                                      <b>No Booking Slots</b>
                                      <div>You may add and edit booking slots after creating this entry</div>
                                      {
                                          (permissions.update)
                                            ?
                                            <div style={{margin:"20px auto"}}>
                                                <TextButton onClick={()=>{this.onSlotRequest(-1)}}>Add Slot</TextButton>
                                            </div>
                                            :
                                            null
                                      }
                                  </div>
                            }
                          </div>
                          {
                            (content.bookingHours.length > 0 && (permissions.update))
                                ?
                                <div className="list-segment-buttons">
                                    <TextButton style={{fontSize:"12px"}} onClick={()=>{this.onSlotRequest(-1)}}>Add Slot</TextButton>
                                </div>
                                :
                                null
                          }
                    </div>

                    {
                        (content.photo)
                            ?
                            <div className="list-image-container">
                            {
                                content.photo.map(
                                    (item,index)=>{
                                        return(
                                            <div key={index} className="list-image-item">
                                                <div className="list-image-flex"><img src={item.url} onClick={()=>{this.onViewImage(index)}}/></div>
                                            </div>
                                        )
                                    }
                                )
                            }
                            </div>
                            :
                            null
                    }

                    {
                        (permissions.update && content.photo.length == 0)
                          ?
                          <div className="list-segment-buttons">
                              <TextButton onClick={()=>{this.setState({error:"",step:"upload-photo"})}}>Add Photo</TextButton>
                          </div>
                          :
                          null
                    }

                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view-blacklist",error:""})}}>Blacklist</Button>
                        <Button onClick={()=>{this.setState({step:"view-blockout",error:""})}}>Blockout</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "view-photo"} scroll={true}>
                    <div className="list-image-container">
                        <h2>Photo: {this.state.selectedImageName}</h2>
                        <img className="list-view-image" src={this.state.selectedImage} onClick={()=>{window.open(this.state.selectedImage,"_blank")}}/>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            {
                                (permissions.update)
                                  ?
                                  <Button type="medium" onClick={()=>{this.setState({step:"delete-photo"})}}>Delete</Button>
                                  :
                                  null
                            }
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "upload-photo"} scroll={true}>
                    <div className="list-upload-photo">
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "delete-photo"} scroll={true}>
                    <div style={{padding:"30px 0 20px 0",textAlign:'center'}}>
                      <h2 className="text-negative">Confirm Delete Photo: {this.state.selectedImageName}</h2>
                      <div>Note. This action cannot be reversed</div>
                    </div>

                    <div className="segment-buttons">
                          <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                          <Button type="medium" onClick={this.onDeletePhoto}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "slot"} scroll={true}>
                    <div style={{fontSize:"1.25em"}}>
                    <h3>Configure Booking Slot</h3>
                    <div className="segment-content">
                        <div className="segment-full-item">
                            <div className="segment-item-label">Day of Week</div>
                            <div className="segment-item-input">
                                <DayOfWeek ref="dayOfWeek" label="Day of Week" field="dayOfWeek" values={{value:slotEntry.dayOfWeek}} rules={{required:true}} onChange={this.onSlotChange}/>
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Time Start</div>
                            <div className="segment-item-input">
                                <Time ref="timeStart" label="Time Start" field="timeStart" values={{value:slotEntry.timeStart}} rules={{skipSeconds:true}} onChange={this.onSlotChange}/>
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Time End</div>
                            <div className="segment-item-input">
                                <Time ref="timeEnd" label="Time End" field="timeEnd" values={{value:slotEntry.timeEnd}} rules={{skipSeconds:true,minTime:slotEntry.timeStart}} onChange={this.onSlotChange}/>
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Residents <Info title="Use search tags to categorize the group of residents who are allowed to book this time slot. If left blank, all residents in the respective dormitory can book this time slot"/></div>
                            <div className="segment-item-input">
                                <EmptyBox onClick={()=>{this.setState({step:"add-tag",error:""})}} style={{padding:"0 5px",cursor:"pointer"}}>
                                    {
                                        (slotEntry.allowed.length > 0)
                                            ?
                                            slotEntry.allowed.map(
                                                (tag, index)=> {
                                                    return (
                                                        <Tag key={index}
                                                             index={index + ""}
                                                             clickable={true}
                                                             label={tag}
                                                             onRemove={()=>{}}
                                                             values={{removable:false}}
                                                            />
                                                    )
                                                }
                                            )
                                            :
                                            <div style={{padding:"5px 0",color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                    }
                                </EmptyBox>
                            </div>
                        </div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                            <Button type="medium" onClick={this.onAddTimeSlot}>Confirm</Button>
                        </div>
                    </div>
                    </div>
                </Step>

                <Step active={(this.state.step == "add-tag")} scroll={true}>
                    <div style={{padding:"10px 0 30px 0",fontSize:"1.25em"}}>
                        <h3>Add resident search tags below</h3>
                        <div style={{width:"800px", maxWidth:"96%",fontSize:"0.85em",margin:"20px auto",textAlign:"center"}}>You do not need to add in tags @status(!Check Out), @dorm() & @app(Registered) as these tags are automatically added</div>
                        <AddSearchTag listLabel="resident-list" tags={slotEntry.allowed} onChange={this.onChangeTags} />
                        <div className="segment-buttons">
                            <Button index="add" type="medium" onClick={()=>{this.setState({error:"",step:"slot"})}}>Done</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "view-blacklist"} scroll={true}>
                    <div className="list-segment-table">
                        <div className="segment-table">
                            <b>Blacklist</b>
                            {
                              (content.blacklist.length > 0)
                                ?
                                <table style={{margin:"15px auto"}}>
                                  <thead>
                                    <tr>
                                      <th className="segment-table-button left"/>
                                      <th>Resident</th>
                                      <th>NRIC/FIN</th>
                                      <th>Expire On</th>
                                      <th className="right">Remarks</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                      {
                                          content.blacklist.map(
                                              (item,index)=>{
                                                  return (
                                                    <tr className="item" key={index}>
                                                        <td className="segment-table-button">
                                                            <div style={{width:"15px",height:"15px"}}>
                                                                <MiniMinusButton enabled={(permissions.update)} onClick={()=>{this.onRemoveBlacklistRequest(index)}}/>
                                                            </div>
                                                        </td>
                                                        <td><Link to={"/resident/"+item.tenantID}>{item.tenantName}</Link></td>
                                                        <td>{item.tenantNRIC}</td>
                                                        <td>
                                                            <EditableDate values={{
                                                                            value:item.expireOn
                                                                          }}
                                                                          label="Expire On"
                                                                          field="expireOn"
                                                                          id={item}
                                                                          index={index}
                                                                          onChange={this.onChangeBlacklist}
                                                                          url="/api/update-blacklist-entry"
                                                                          />
                                                        </td>
                                                        <td>
                                                            <EditableArea values={{
                                                                            value:item.remark
                                                                          }}
                                                                          label="Remark"
                                                                          field="remark"
                                                                          id={item}
                                                                          index={index}
                                                                          onChange={this.onChangeBlacklist}
                                                                          url="/api/update-blacklist-entry"
                                                                          />
                                                        </td>
                                                    </tr>
                                                  )
                                              }
                                          )
                                      }
                                  </tbody>
                                </table>
                                :
                                <div className="segment-placeholder">
                                    <b>No Residents Blacklisted</b>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='list-segment-note'>
                        Blacklisted residents are not able to book the respective facility until the entry has expired
                    </div>
                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        {
                            (permissions.update)
                              ?
                              <Button onClick={()=>{this.setState({step:"add-blacklist",error:""})}}>Add Entry</Button>
                              :
                              null
                        }
                    </div>
                </Step>

                <Step active={this.state.step == "add-blacklist"} scroll={true} style={{padding:"20px 0"}}>
                    <h2>Add Resident to Blacklist</h2>
                    <div style={{position:"relative",fontSize:"14px"}}>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Resident FIN:</div>
                            <div className="segment-item-input">
                                <Box ref="nric"
                                     label="FIN"
                                     rules={{required:true}}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Blacklist On:</div>
                            <div className="segment-item-input">
                                <Select ref="createFor"
                                        label="Blacklist On"
                                        values={{
                                            options:[
                                                {value:"current",label:"Current Facility"},
                                                {value:"all",label:"All Facilities"},
                                            ]
                                        }}
                                        rules={{required:true}}
                                        />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Blacklist Until:</div>
                            <div className="segment-item-input">
                                <Date ref="expireOn"
                                      label="Blacklist Until"
                                      range={[0,10]}
                                      rules={{
                                        minDate:moment().format("YYYY-MM-DD"),
                                        defaultValue:moment().add(7,"days").format("YYYY-MM-DD"),
                                      }}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Remark:</div>
                            <div className="segment-item-input">
                                <Area ref="remark"
                                      label="Remark"
                                      rules={{placeholder:"Optional"}}
                                     />
                            </div>
                        </div>
                    </div>

                    <div className="list-segment-note">
                        Note. Any upcoming bookings made by resident will be cancelled immediately and resident will not be able to make bookings until the listing has expired
                    </div>

                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onAddToBlacklist}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "remove-blacklist"} scroll={true} style={{padding:"30px 0"}}>
                    <h2 className="text-negative">Remove {this.state.blackList.tenantName} ({this.state.blackList.tenantNRIC}) From Blacklist?</h2>
                    <div className="list-segment-note">
                        Note. Resident will be allowed to book this facility again immediately, if not removed, the entry will automatically expire on the respective expiry date
                    </div>
                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onRemoveFromBlacklist}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "view-blockout"} scroll={true}>
                    <div className="list-segment-table">
                        <div className="segment-table padded">
                            <b>Blockout</b>
                            {
                              (content.blockout.length > 0)
                                ?
                                <div>
                                    <table style={{margin:"15px auto"}}>
                                        <thead>
                                          <tr>
                                              <th className="remove left"/>
                                              <th>Reason</th>
                                              <th style={{width:"200px"}}>Start</th>
                                              <th className="right" style={{width:"200px"}}>End</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                content.blockout.map(
                                                    (item,index)=>{
                                                        return (
                                                          <tr className="item" key={index}>
                                                              <td className="remove" style={{paddingTop:"15px",paddingBottom:"15px"}}>
                                                                  <div style={{width:"15px",height:"15px"}}>
                                                                      <MiniMinusButton enabled={(permissions.update)} onClick={()=>{this.onRemoveBlockoutRequest(index)}}/>
                                                                  </div>
                                                              </td>
                                                              <td>{item.reason}</td>
                                                              <td style={{width:"200px"}}>{moment(item.startDateTime).format("DD MMM YYYY - hh:mma")}</td>
                                                              <td style={{width:"200px"}}>{moment(item.endDateTime).format("DD MMM YYYY - hh:mma")}</td>
                                                          </tr>
                                                        )
                                                    }
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="segment-placeholder">
                                    <b>No Upcoming Blockouts</b>
                                    <div className='list-segment-note'>Blockouts that have passed are automatically removed from this list</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className='list-segment-note'>
                        Sessions that overlap blocked out dates will not be bookable by residents
                    </div>

                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        {
                            (permissions.update)
                              ?
                              <Button onClick={()=>{this.setState({step:"add-blockout",error:""})}}>Add Entry</Button>
                              :
                              null
                        }
                    </div>
                </Step>

                <Step active={this.state.step == "add-blockout"} scroll={true} style={{padding:"20px 0"}}>
                    <h2>Add Blockout Entry</h2>
                    <div style={{position:"relative",fontSize:"14px"}}>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Reason:</div>
                            <div className="segment-item-input">
                                <Box ref="reason"
                                     label="Reason"
                                     rules={{required:true}}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Start On:</div>
                            <div className="segment-item-input">
                                <Date ref="startDate"
                                      label="Start On"
                                      range={[0,20]}
                                      rules={{
                                        minDate:moment().format("YYYY-MM-DD"),
                                        defaultValue:moment().add(1,"days").format("YYYY-MM-DD"),
                                      }}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Start Time:</div>
                            <div className="segment-item-input">
                                <Time ref="startTime"
                                      label="Start Time"
                                      rules={{defaultValue:"08:00:00"}}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">End On:</div>
                            <div className="segment-item-input">
                                <Date ref="endDate"
                                      label="End On"
                                      range={[0,20]}
                                      rules={{
                                        minDate:moment().format("YYYY-MM-DD"),
                                        defaultValue:moment().add(1,"days").format("YYYY-MM-DD")
                                      }}
                                     />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Start Time:</div>
                            <div className="segment-item-input">
                                <Time ref="endTime"
                                      label="End Time"
                                      rules={{defaultValue:"18:00:00"}}
                                     />
                            </div>
                        </div>
                    </div>

                    <div className="list-segment-note" style={{margin:"30px auto 20px auto"}}>
                        Note. Residents who have already made a booking will receive a cancellation notification immediately
                    </div>

                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onAddToBlockout}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "remove-blockout"} scroll={true} style={{padding:"30px 0"}}>
                    <h2 className="text-negative">Remove {blockout?.reason} From Blockout?</h2>
                    <div className="list-segment-note">
                        Note. Resident will be allowed to book the facility during this timeslot again immediately
                    </div>
                    <div className="list-segment-buttons">
                        <Button onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                        <Button onClick={this.onRemoveFromBlockout}>Confirm</Button>
                    </div>
                </Step>

            </div>
        )
    }
}

class AddFacility extends React.Component{

    state={
      error:"",
      step:"add",
      loading:false,
      loadText:"",
      entryData:{
          photo:[],
          bookingHours:[],
      },
      slotEntry:{
          timeStart:moment().format("HH:00:00"),
          timeEnd:moment().format("HH:00:00"),
          allowed:[],
      },
      dormOptions:[],
      options:{},
    };

    componentDidMount=()=>{
        this.getDormOptions();
        this.getOptions();
    }

    getDormOptions=()=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:"dorm",
            },
            success:(dormOptions)=>{
                this.setState({dormOptions:dormOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["facilityActivityType"]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onCreate=()=>{
        this.setState({error:""});

        const keys = [
            "dormID",
            "facilityName",
            "capacity",
            "activityType",
            "photo",
        ];
        for(var i=0; i<keys.length; i++){
            let current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.refs['add'].onValidated(this.state.entryData);
    }

    onChange=(value,label,index)=>{
        let entryData = this.state.entryData;
        entryData[label] = value;
        this.setState({entryData:entryData});
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.setState({entryData:{photo:[],bookingHours:[]}})
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    onRemovePhoto=(index)=>{
        let entryData=this.state.entryData;
        entryData.photo.splice(index,1);
        this.setState({entryData:entryData});
    }

    onUploadPhoto=(url)=>{
        let entryData = this.state.entryData;
        entryData.photo.push({filename:this.state.filename,url:url});
        this.setState({step:"add",loading:false,entryData:entryData,error:""});
    }

    getDow=(arr)=>{
        let label = "";
        const days = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        for(var i=0; i<arr.length; i++){
            if(arr[i] == 1){
                label += ((label.length > 0) ? " / " : "") + days[i];
            }
        }
        return label;
    }

    onSlotRequest=(index)=>{
        this.setState({
          step:"slot",
          slotEntry:(index >= 0) ? this.state.entryData.bookingHours[index] : {timeStart:moment().format("HH:00:00"),timeEnd:moment().format("HH:00:00"),allowed:[]},
          editIndex:index
        });
    }

    onSlotChange=(value,label,index)=>{
        let slotEntry = this.state.slotEntry;
        slotEntry[label] = value;
        this.setState({slotEntry:slotEntry});
    }

    onAddTimeSlot=()=>{
        this.setState({error:""});
        let slotEntry = this.state.slotEntry;

        let keys=["dayOfWeek","timeStart","timeEnd"];
        for(var i=0; i<keys.length; i++){
            const current=this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        const editIndex = this.state.editIndex;
        let entryData = this.state.entryData;

        //Check if overlapping time slots
        const date=moment().format("YYYY-MM-DDT");
        const label = ["Sun","Mon","Tue","Wed","Thu","Sat","Sun"];
        for(var b=0; b<entryData.bookingHours.length; b++){
            if(b != this.state.editIndex){
                const existingSlot = entryData.bookingHours[b];
                for(var d=0; d<7; d++){
                    if(slotEntry.dayOfWeek[d] == 1 && existingSlot.dayOfWeek[d] == 1){
                        if(moment(date+slotEntry.timeStart).isSameOrBefore(date+existingSlot.timeStart) && moment(date+slotEntry.timeEnd).isSameOrAfter(date+existingSlot.timeStart)){
                            this.setState({error:"Time slot overlaps with existing " + label[d] + " - " + moment(date+existingSlot.timeStart).format("h:mma") + " " + moment(date+existingSlot.timeEnd).format("h:mma")});
                            return 0;
                        }
                    }
                }
            }
        }

        if(editIndex >= 0){
            entryData.bookingHours[editIndex] = slotEntry;
        }
        else{
            entryData.bookingHours.push(slotEntry);
        }
        this.setState({
          step:"add",
          error:"",
          entryData:entryData,
        });
    }

    onRemoveTimeSlot=(index)=>{
        let entryData = this.state.entryData;
        entryData.bookingHours.splice(index,1);
        this.setState({entryData:entryData});
    }

    onChangeTags=(allowed)=>{
        let slotEntry = this.state.slotEntry;
        slotEntry.allowed = allowed;
        this.setState({slotEntry:slotEntry});
    }

    render=()=>{
        const entryData = this.state.entryData;
        const options = this.state.options;
        const slotEntry = this.state.slotEntry;
        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onCancel}
                         onToggleForm={this.props.onToggleForm}>

                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="segment-error">{this.state.error}</div>

                    <Step active={this.state.step=="add"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dormID" label="Dormitory" field="dormID" values={{value:entryData.dormID,options:this.state.dormOptions}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Facility Name</div>
                                <div className="segment-item-input">
                                    <Box ref="facilityName" label="Facility" field="facilityName" values={{value:entryData.facilityName}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Capacity <Info title="Total number of people that can book the same time slot"/></div>
                                <div className="segment-item-input">
                                    <Box ref="capacity" label="Capacity" type="number" field="capacity" values={{value:entryData.capacity}} rules={{required:true,minValue:0}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Activity Type <Info title="Type of activity taking place in this facility"/></div>
                                <div className="segment-item-input">
                                    <Select ref="activityType" label="Facility Type" field="activityType" values={{value:entryData.activityType,options:options.facilityActivityType}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Photo <Info title="Photo is diplayed in the resident's app, to help residents better identify the facility they are booking"/></div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="photo"
                                                label="Photo"
                                                rules={{required:true,max:1}}
                                                values={{value:entryData.photo}}
                                                onAdd={()=>{this.setState({error:"",step:"photo"})}}
                                                onRemove={this.onRemovePhoto}
                                                />
                                </div>
                            </div>

                            <div className="segment-buttons">
                                {
                                    (entryData.bookingHours.length > 0)
                                      ?
                                      <div className="segment-table">
                                          <b>Booking Slots</b>
                                          <table style={{margin:"15px auto"}}>
                                            <thead>
                                              <tr>
                                                <th className="segment-table-button left"/>
                                                <th>Day of Week</th>
                                                <th>Time</th>
                                                <th>Residents</th>
                                                <th className="segment-table-button right"/>
                                              </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    entryData.bookingHours.map(
                                                        (item,index)=>{
                                                            return (
                                                              <tr className="item" key={index}>
                                                                  <td className="segment-table-button">
                                                                      <div style={{width:"15px",height:"15px"}}>
                                                                          <MiniMinusButton onClick={()=>{this.onRemoveTimeSlot(index)}}/>
                                                                      </div>
                                                                  </td>
                                                                  <td>{this.getDow(item.dayOfWeek)}</td>
                                                                  <td>{moment("2020-01-01T"+item.timeStart).format("h:mma")} - {moment("2020-01-01T"+item.timeEnd).format("h:mma")}</td>
                                                                  <td>
                                                                  {
                                                                    (item.allowed.length > 0)
                                                                      ?
                                                                      item.allowed.map(
                                                                          (tag,t)=>{
                                                                              return(
                                                                                <Tag key={t}
                                                                                     index={t + ""}
                                                                                     clickable={true}
                                                                                     label={tag}
                                                                                     onRemove={()=>{}}
                                                                                     values={{removable:false}}
                                                                                     />
                                                                                )
                                                                          }
                                                                      )
                                                                      :
                                                                      "All"
                                                                  }
                                                                  </td>
                                                                  <td className="segment-table-button">
                                                                      <img src="/images/input/edit.png" className="segment-table-button-image" onClick={()=>{this.onSlotRequest(index)}}/>
                                                                  </td>
                                                              </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                          </table>
                                          <TextButton style={{fontSize:"0.85em"}} onClick={()=>{this.onSlotRequest(-1)}}>Add Booking Slot</TextButton>
                                      </div>
                                      :
                                      <div className="segment-placeholder">
                                          <b>No Booking Slots</b>
                                          <div>You may add and edit booking slots after creating this entry</div>
                                          <div style={{margin:"20px auto"}}>
                                              <TextButton onClick={()=>{this.onSlotRequest(-1)}}>Add Booking Slot</TextButton>
                                          </div>
                                      </div>
                                }
                            </div>

                            <div className="segment-buttons">
                                <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "photo"}>
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({error:"",step:"add"})}}>Back</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step == "slot"}>
                        <h3>Configure Booking Slot</h3>
                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Day of Week</div>
                                <div className="segment-item-input">
                                    <DayOfWeek ref="dayOfWeek" label="Day of Week" field="dayOfWeek" values={{value:slotEntry.dayOfWeek}} rules={{required:true}} onChange={this.onSlotChange}/>
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Time Start</div>
                                <div className="segment-item-input">
                                    <Time ref="timeStart" label="Time Start" field="timeStart" values={{value:slotEntry.timeStart}} rules={{skipSeconds:true}} onChange={this.onSlotChange}/>
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Time End</div>
                                <div className="segment-item-input">
                                    <Time ref="timeEnd" label="Time End" field="timeEnd" values={{value:slotEntry.timeEnd}} rules={{skipSeconds:true,minTime:slotEntry.timeStart}} onChange={this.onSlotChange}/>
                                </div>
                            </div>
                            <div className="segment-full-item">
                                <div className="segment-item-label">Residents <Info title="Use search tags to categorize the group of residents who are allowed to book this time slot. If left blank, all residents in the respective dormitory can book this time slot"/></div>
                                <div className="segment-item-input">
                                    <EmptyBox onClick={()=>{this.setState({step:"add-tag",error:""})}} style={{padding:"0 5px",cursor:"pointer"}}>
                                        {
                                            (slotEntry.allowed.length > 0)
                                                ?
                                                slotEntry.allowed.map(
                                                    (tag, index)=> {
                                                        return (
                                                            <Tag key={index}
                                                                 index={index + ""}
                                                                 clickable={true}
                                                                 label={tag}
                                                                 onRemove={()=>{}}
                                                                 values={{removable:false}}
                                                                />
                                                        )
                                                    }
                                                )
                                                :
                                                <div style={{padding:"5px 0",color:"lightgrey",cursor:"pointer"}}>Click To Add</div>
                                        }
                                    </EmptyBox>
                                </div>
                            </div>
                            <div className="segment-buttons">
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"add"})}}>Back</Button>
                                <Button type="medium" onClick={this.onAddTimeSlot}>Confirm</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={(this.state.step == "add-tag")}>
                        <div style={{padding:"10px 0 30px 0"}}>
                            <h3>Add resident search tags below</h3>
                            <div style={{width:"800px", maxWidth:"96%",fontSize:"0.85em",margin:"20px auto",textAlign:"center"}}>You do not need to add in tags @status(!Check Out), @dorm() & @app(Registered) as these tags are automatically added</div>
                            <AddSearchTag listLabel="resident-list" tags={slotEntry.allowed} onChange={this.onChangeTags} />
                            <div className="segment-buttons">
                                <Button index="add" type="medium" onClick={()=>{this.setState({error:"",step:"slot"})}}>Done</Button>
                            </div>
                        </div>
                    </Step>
                </div>

            </AddListItem>
        )
    }
}

class FacilitySettings extends React.Component{

    state={
        active:false,
        content:{},
        options:{},
        madeChanges:false,
        entryData:{},
        step:"view",
        loading:false,
        loadText:"",
    }

    show=(show)=>{
        this.setState({active:show});
    }

    componentDidMount=()=>{
        this.setState({canEdit:u.getPermissions("facility").update});
        this.getOptions();
        this.getContent();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:[
                  "limitFacilityActivityType",
                  "limitMaxBookingFacilityType",
                  "limitFacilityMaxBookingQuantifier",
                  "limitResidentGrouping"
                ],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
              this.setState({error:error});
            }
        });
    }

    getContent=()=>{
        u.post({
            url:"/api/get-facility-settings",
            data:{},
            success:(content)=>{
                this.setState({
                  entryData:Object.assign({},content),
                  content:content,
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onChange=(value,label,index,options,autoUpdate)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});

        if(!autoUpdate){
            this.setState({madeChanges:true});
        }
    }

    onSubmit=()=>{
        this.setState({error:""});
        let keys=[
            "maxBookingFacilityType",
            "maxBookingValue",
            "maxBookingQuantifier",
            "groupingActivityType",
            "residentGrouping",
            "maxAdvanceBookingDate",
            "minIntervalCalendarDays",
            "intervalActivityType",
        ];

        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.setState({loading:true,loadText:"Updating Settings..."});

        u.post({
            url:"/api/update-facility-settings",
            data:{
                old:this.props.content,
                new:this.state.entryData,
            },
            success:()=>{
                this.setState({loading:false,step:"complete"});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onCancel=()=>{
        if(this.props.onToggleForm){
            this.props.onToggleForm(this.props.reference,false);
        }
    }

    onComplete=()=>{
        this.setState({
            step:"view",
            error:"",
            madeChanges:false,
        });
    }

    render=()=>{
        const canEdit=this.state.canEdit;
        const madeChanges=this.state.madeChanges;
        const content=this.state.entryData;
        const options=this.state.options;

        return (
            <Segment active={this.state.active}>
                <div className="segment-title">Facility Rules</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>

                <Step active={this.state.step == "view"}>
                    <div className="segment-content" style={{position:"relative",textAlign:"center"}}>
                        <h3>Configure Facility Booking Rules</h3>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Max Booking</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitMaxBooking}
                                                index="limitMaxBooking"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Each resident may only book
                                    </div>

                                    <div className="row-item" style={{width:"250px",margin:"0 10px"}}>
                                        <Select ref="maxBookingFacilityType"
                                                values={{
                                                    value:content.maxBookingFacilityType,
                                                    options:options.limitMaxBookingFacilityType,
                                                    enabled:(content.limitMaxBooking && canEdit)
                                                }}
                                                label="Facility Type"
                                                field="maxBookingFacilityType"
                                                onChange={this.onChange}
                                                />
                                    </div>

                                    <div style={{position:"relative",margin:"10px auto"}}>
                                        <div className="row-item">
                                            up to a maximum of
                                        </div>

                                        <div className="row-item" style={{width:"50px",margin:"0 10px"}}>
                                            <Box ref="maxBookingValue"
                                                 values={{
                                                    value:content.maxBookingValue,
                                                    enabled:(content.limitMaxBooking && canEdit)
                                                 }}
                                                 label="Max Booking Value"
                                                 field="maxBookingValue"
                                                 onChange={this.onChange}
                                                 type="number"
                                                 rules={{
                                                     required:true,
                                                     minValue:0
                                                 }}
                                                 />
                                        </div>

                                        <div className="row-item">
                                            time slots per
                                        </div>

                                        <div className="row-item" style={{width:"80px",margin:"0 10px"}}>
                                        <Select ref="maxBookingQuantifier"
                                                values={{
                                                  value:content.maxBookingQuantifier,
                                                  options:options.limitFacilityMaxBookingQuantifier,
                                                  enabled:(content.limitMaxBooking && canEdit)
                                                }}
                                                label="Max Booking Quantifier"
                                                field="maxBookingQuantifier"
                                                rules={{
                                                      required:true,
                                                }}
                                                onChange={this.onChange}
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Resident Grouping</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitResidentGrouping}
                                                index="limitResidentGrouping"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Restrict booking of time slots for
                                    </div>

                                    <div className="row-item" style={{width:"250px",margin:"0 10px"}}>
                                        <Select ref="groupingActivityType"
                                                values={{
                                                    value:content.groupingActivityType,
                                                    options:options.limitFacilityActivityType,
                                                    enabled:(content.limitResidentGrouping && canEdit)
                                                }}
                                                label="Activity Type"
                                                field="groupingActivityType"
                                                onChange={this.onChange}
                                                />
                                    </div>

                                    <div style={{position:"relative",margin:"10px auto"}}>
                                        <div className="row-item">
                                            to residents from the same
                                        </div>

                                        <div className="row-item" style={{width:"250px",margin:"0 10px"}}>
                                            <Select ref="residentGrouping"
                                                    values={{
                                                        value:content.residentGrouping,
                                                        options:options.limitResidentGrouping,
                                                        enabled:(content.limitResidentGrouping && canEdit)
                                                    }}
                                                    label="Resident Grouping"
                                                    field="residentGrouping"
                                                    onChange={this.onChange}
                                                    />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Advance Booking</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitAdvanceBookingDate}
                                                index="limitAdvanceBookingDate"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Residents may only book slots up to
                                    </div>

                                    <div className="row-item" style={{width:"50px",margin:"10px"}}>
                                        <Box ref="maxAdvanceBookingDate"
                                             values={{
                                                  value:content.maxAdvanceBookingDate,
                                                  enabled:(content.limitAdvanceBookingDate && canEdit)
                                             }}
                                             type="number"
                                             label="Advance Booking Days"
                                             field="maxAdvanceBookingDate"
                                             rules={{required:true, minValue:1}}
                                             onChange={this.onChange}
                                             />
                                    </div>

                                    <div className="row-item">
                                        day(s) in advance
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        <div>
                            <div className="segment-full-item">
                                <div className="segment-item-label" style={{verticalAlign:"middle",fontSize:"1.1em",width:"64%",margin:"0 0 0 1%"}}>
                                    <b>Limit Booking Interval</b>
                                </div>
                                <div className="segment-item-input" style={{verticalAlign:"middle",textAlign:"right",width:"35%",margin:"0"}}>
                                    <BoolSlider value={content.limitBookingInterval}
                                                index="limitBookingInterval"
                                                onChange={this.onChange}
                                                />
                                </div>
                            </div>

                            <div style={{position:"relative",fontSize:"14px"}}>
                                <div className="segment-full-item">
                                    <div className="row-item">
                                        Residents must wait a minimum of
                                    </div>

                                    <div className="row-item" style={{width:"50px",margin:"0 10px"}}>
                                        <Box ref="minIntervalCalendarDays"
                                             values={{
                                                  value:content.minIntervalCalendarDays,
                                                  enabled:(content.limitBookingInterval && canEdit)
                                             }}
                                             type="number"
                                             label="Minimum Booking Interval"
                                             field="minIntervalCalendarDays"
                                             rules={{required:true, minValue:1}}
                                             onChange={this.onChange}
                                             />
                                    </div>

                                    <div className="row-item">
                                        day(s) between
                                    </div>

                                    <div className="row-item">
                                        booking of facilities which provide
                                    </div>

                                    <div className="row-item" style={{width:"200px",margin:"10px"}}>
                                        <Select ref="intervalActivityType"
                                                values={{
                                                    value:content.intervalActivityType,
                                                    enabled:(content.limitBookingInterval && canEdit),
                                                    options:options.limitFacilityActivityType
                                                 }}
                                                label="Booking Interval Activity Type"
                                                field="intervalActivityType"
                                                rules={{required:true}}
                                                onChange={this.onChange}
                                                />
                                    </div>
                                </div>
                            </div>

                            <div className='segment-full-item' style={{borderBottom:"1px solid #f4f4f4"}}/>
                        </div>

                        {
                            (canEdit)
                                ?
                                <div className="segment-buttons">
                                    <div className="segment-error">Changes will only take effect after you click Save Changes</div>
                                </div>
                                :
                                <div className="segment-buttons">
                                    <div className="segment-error">You do not have the necessary permissions to make changes</div>
                                </div>
                        }

                        <div className="segment-buttons">
                            <div className="segment-error">{this.state.error}</div>
                            <Button onClick={this.onCancel}>Cancel</Button>
                            <Button onClick={this.onSubmit} enabled={canEdit && madeChanges}>Save Changes</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "complete"} scroll={true}>
                    <div className="segment-content" style={{position:"relative",textAlign:"center"}}>
                        <SegmentComplete onComplete={this.onComplete}>
                            Successfully updated <span className="text-emphasis">facility settings</span>
                        </SegmentComplete>
                    </div>
                </Step>
            </Segment>
        )
    }
}
