// src/components/NotFoundPage.js
import React from 'react';
import PublicLayoutPage from '../components/PublicPageLayout';
import {Header,Footer,Databox} from '../components/Common';
import {Link} from 'react-router-dom';

export default class NotFoundPage extends React.Component {
    render() {
        return (
            <PublicLayoutPage url="/not-found" title="Page Not Found" containerStyle={{height:"100%"}} style={{height:"50%"}}>
                <div style={{textAlign:"center",height:"100%",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:'center'}}>
                    <img src="/images/input/cancel.png" style={{width:"64px",height:"64px"}}/>
                    <h2>Page does not exist</h2>
                    <div>Please <Link to="/login" title="Return to login page">click here</Link> to return</div>
                </div>
            </PublicLayoutPage>
        );
    }
}
