import React from 'react';
import PublicPageLayout from '../../components/PublicPageLayout';
import {io} from 'socket.io-client';
import {LoadGraphic} from '../../components/Common';
import {TextButton,Select} from '../../components/Input';
import moment from 'moment';
import u from '../../utilities/Utilities';
import store from 'store';
import "../../styles/dashboard-generic.scss";
import "../../styles/dashboards/psa-dashboards.scss";
import {Chart, Line, Bar} from 'react-chartjs-2';

export default class PSAOperationalStatistics extends React.Component{

    socketType="Operational Statistics";

    dormID = this.props.match.params.dormID;

    state={
        content:{
            charts:[],
        },
        loading:false,
        refresh:false,
        refreshRate:-1,
        showValues:false,
        options:{
            responsive: true,
            maintainAspectRatio:false,
            showLines:true,
            spanGaps:false,
            plugins:{
              legend:{
                display:false,
              },
              datalabels:{
                  display:false,
                  align:"end",
                  anchor:"end",
                  offset:1,
                  font:{
                      weight:"bold",
                  }
              },
              tooltip:{
                  callbacks:{
                      title:(datasets)=>{
                          let titles=[];
                          for(var i=0; i<datasets.length; i++){
                              const title = (datasets[i].dataset.tooltipTitle) ? datasets[i].dataset.tooltipTitle[datasets[i].dataIndex] : datasets[i].label;
                              titles.push(title);
                          }
                          return titles;
                      }
                  }
              }
            },
        }
    };

    componentDidMount=()=>{
        this.openedOn=moment().format("YYYY-MM-DDTHH:mm:ss");
        this.getContent();
    }

    componentWillUnmount=()=>{
        if(this.contentInterval){
            clearInterval(this.contentInterval);
        }
    }

    getContent=(refresh)=>{
        this.setState({
            error:"",
            loading:!(refresh),
            refresh:refresh,
        });
        u.post({
            url:"/api/get-dashboard-content",
            data:{
                dormID:this.dormID,
                type:this.socketType,
                testDelay:2000,
                openedOn:this.openedOn,
            },
            success:(content)=>{
                if(content.kill){
                    window.close();
                    return 0;
                }

                let modContent = this.modContent(content);
                this.setState({
                  content:modContent,
                  loading:false,
                  refresh:false,
                  lastRefreshed:moment().format("YYYY-MM-DDTHH:mm:ss"),
                });
            },
            error:(error)=>{
                this.setState({
                    error:error,
                    loading:false,
                    refresh:false
                });
            }
        });
    }

    modContent=(content)=>{
        content.charts = content.charts.map(
            (chart)=>{
                if(chart.type=="line"){
                    chart.datasets = chart.datasets.map((item)=>{
                        if(item.borderColor=="gradient"){
                            item.borderColor=this.drawGradient;
                        }
                        return item;
                    });
                }
                return chart;
            }
        )
        return content;
    }

    psaColors=[
      u.hexToRgb("#229dce"),
      u.hexToRgb("#37a7b6"),
      u.hexToRgb("#63c08a"),
      u.hexToRgb("#8ed75e"),
      u.hexToRgb("#a5e348"),
      u.hexToRgb("#cac9d0"),
    ];

    getGradient=(ctx, chartArea)=>{
        let width, height, gradient;
        const chartWidth = chartArea.right - chartArea.left;
        const chartHeight = chartArea.bottom - chartArea.top;
        if (!gradient || width !== chartWidth || height !== chartHeight) {
          // Create the gradient because this is either the first render
          // or the size of the chart has changed
          width = chartWidth;
          height = chartHeight;
          gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
          gradient.addColorStop(0, this.psaColors[0].hex);
          gradient.addColorStop(0.5, this.psaColors[3].hex);
          gradient.addColorStop(1, this.psaColors[5].hex);
      }
      return gradient;
    }

    drawGradient=(context)=>{
         const chart = context.chart;
         const {ctx, chartArea} = chart;
         if (!chartArea) {
           // This case happens on initial chart load
           return;
         }
         return this.getGradient(ctx, chartArea);
    }

    onToggleValues=()=>{
        let newValue=!this.state.showValues;
        let options=this.state.options;
        options.plugins.datalabels.display=newValue;
        this.setState({showValues:newValue,options:options});
    }

    onChangeRefresh=(valueMS)=>{
        //console.log("Changing Refresh",valueMS);
        //Check difference from refresh
        this.setState({refreshRate:valueMS});
        if(valueMS == "-1"){
            if(this.contentInterval){
                clearInterval(this.contentInterval);
            }
        }
        else{
          const msSinceRefresh = moment().diff(moment(this.state.lastRefreshed));
          //console.log("MS Since Refresh",msSinceRefresh);
          if(msSinceRefresh > valueMS){
              this.getContent(true);
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              this.contentInterval = setInterval(()=>{
                  this.getContent(true);
              },valueMS);
          }
          else{
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              setTimeout(()=>{
                  this.getContent(true);
                  if(this.contentInterval){
                      clearInterval(this.contentInterval);
                  }
                  this.contentInterval = setInterval(()=>{
                      this.getContent(true);
                  },valueMS);
              },(valueMS - msSinceRefresh));
          }
        }
    }

    render=()=>{
        const content=this.state.content;
        const options=this.state.options;

        return(
            <PublicPageLayout url="/psa-operational-status"
                              title={this.socketType}
                              style={{position:"relative",zIndex:102}}>
                <LoadGraphic active={this.state.loading} text="Preparing"/>

                <PSAHeader label={(content.label) ? content.label : this.socketType.toUpperCase()}
                           onChange={this.onChangeRefresh}
                           loading={this.state.loading}
                           lastRefreshed={this.state.lastRefreshed}
                           refresh={this.state.refresh}
                           refreshRate={this.state.refreshRate}
                           />

                <div className="psa-graphs-container" style={{minHeight:"400px"}}>
                    {
                        content.charts.map(
                            (item,index)=>{
                                if(item.type=="line"){
                                  return(
                                      <div key={index} className="psa-graph-container">
                                          <h2 className="psa-graph-title">{item.label}</h2>
                                          <div className="psa-graph">
                                              <Line options={{
                                                      ...options,
                                                      scales:{
                                                          x:{
                                                              ticks: {
                                                                  autoSkip: true,
                                                                  autoSkipPadding: 20,
                                                              },
                                                              grid:{
                                                                  color:(this.state.showValues) ? "white" : undefined,
                                                                  borderColor:(this.state.showValues) ? "white" : undefined
                                                              }
                                                          },
                                                          y:{
                                                              display:true,
                                                              beginAtZero:(typeof item.beginAtZero != "undefined") ? item.beginAtZero : true,
                                                              steps:5,
                                                              grace:"10%",
                                                              ticks:{
                                                                  callback:(value,index,ticks)=>{
                                                                      return (this.state.showValues) ? "" : value;
                                                                  }
                                                              },
                                                              grid:{
                                                                  color:(this.state.showValues) ? "white" : undefined,
                                                                  borderColor:(this.state.showValues) ? "white" : undefined
                                                              }
                                                          },
                                                      },
                                                      layout:{
                                                          padding:{
                                                              right:30,
                                                              top:10,
                                                          }
                                                      },
                                                    }}
                                                    data={item}
                                                    redraw={false}
                                                    />
                                          </div>
                                      </div>
                                  )
                                }
                                return (
                                    <div key={index} className="psa-graph-container">
                                        <h2 className="psa-graph-title">{item.label}</h2>
                                        <div className="psa-graph">
                                            <Bar options={{
                                                  ...options,
                                                  scales:{
                                                      x:{
                                                        grid:{
                                                          color:(this.state.showValues) ? "white" : undefined,
                                                          borderColor:(this.state.showValues) ? "white" : undefined
                                                        }
                                                      },
                                                      y:{
                                                          display:true,
                                                          beginAtZero:(typeof item.beginAtZero != "undefined") ? item.beginAtZero : true,
                                                          steps:5,
                                                          grace:"10%",
                                                          ticks:{
                                                              callback:(value,index,ticks)=>{
                                                                  return (this.state.showValues) ? "" : value;
                                                              }
                                                          },
                                                          grid:{
                                                              color:(this.state.showValues) ? "white" : undefined,
                                                              borderColor:(this.state.showValues) ? "white" : undefined
                                                          }
                                                      },
                                                  },
                                                  layout:{
                                                      padding:{
                                                          top:10,
                                                          right:30,
                                                      }
                                                  },
                                                 }}
                                                 data={item}
                                                 redraw={false}
                                                 />
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>

                {
                    (this.state.loading)
                        ?
                        null
                        :
                        <div style={{fontSize:"0.75em",height:"0",marginLeft:"25px",textAlign:"left"}}>
                            <TextButton onClick={this.onToggleValues}>
                                {(this.state.showValues) ? "HIDE" : "DISPLAY"} VALUES
                            </TextButton>
                        </div>

                }
            </PublicPageLayout>
        )
    }
}

export class Clock extends React.Component{

    state={
        reload:false
    }

    componentDidMount=()=>{
        this.interval = setInterval(()=>{
            this.setState({reload:!this.state.reload});
        },1000);
    }

    componentWillUnmount=()=>{
        if(this.interval){
            clearInterval(this.interval);
        }
    }

    render=()=>{
        return (
            <span>
                {moment().format("DD MMM YY h")}
                <span className="pdh-clock-seconds">:</span>
                {moment().format("mma")}
            </span>
        )
    }
}

export class PSAHeader extends React.Component{
    options=[
        {value:"-1",label:"Do Not Refresh"},
        /*{value:"5000",label:"Every 5 Sec"},
        {value:"15000",label:"Every 15 Sec"},*/
        {value:"60000",label:"Every 1 Min"},
        {value:"300000",label:"Every 5 Min"},
        {value:"900000",label:"Every 15 Min"},
        {value:"1800000",label:"Every 30 Min"},
        {value:"3600000",label:"Every 1 Hour"},
    ];

    render=()=>{
        return(
            <div className="psa-dashboard-header-container">
                <div className="psa-dashboard-header">
                    <div className="pdh-title"><h2>{this.props.label}</h2></div>
                    <div className='pdh-title right'>
                        <div className="pdh-refresh-container">
                            <div className="pdh-refresh-top">
                                <span style={{marginBottom:"1px"}}>Auto Refresh:</span>
                                <div className="pdh-input">
                                    <Select label="Refresh Rate"
                                            values={{
                                                value:this.props.refreshRate,
                                                options:this.options
                                            }}
                                            onChange={this.props.onChange}
                                            />
                                </div>
                            </div>
                        </div>
                        <h2 className="pdh-clock-container"><Clock/></h2>
                    </div>
                </div>

                {
                    (this.props.loading)
                        ?
                        null
                        :
                        <div className="pdh-last-refresh">
                            {
                                (this.props.refresh)
                                    ?
                                    <div>
                                        <div className="pdh-loader-container">
                                            <div className="pdh-loader"></div>
                                        </div>
                                        <span style={{verticalAlign:"middle"}}><i>Syncing Data...</i></span>
                                    </div>
                                    :
                                    <i>Last sync on {moment(this.props.lastRefreshed).format("DD MMM YY - h:mma")}</i>
                            }
                        </div>
                }

            </div>
        )
    }
}
