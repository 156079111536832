import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,
        AddListItem,DownloadTemplate,DynamicListArea,BulkEdit,
        LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {EditSizeLabel,EditableBox,EditableSelect,Box,
        Select,TextButton,Button,Input} from '../components/Input';
import {Access} from './Staff';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import PropTypes, { number } from 'prop-types';
import {Spreadsheet} from '../data/Mimetypes';
import moment from 'moment';
import store from 'store';

export default class StaffList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Staff",
        permission:"staff",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/staff-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-staff",
        templateURL:"/api/get-add-staff-template",
        bulkURL:"/api/add-bulk-staff",
        deleteURL:"/api/delete-staff-entries",
        spreadsheetURL:"/api/generate-staff-spreadsheet",
        overviewURL:"/api/get-all-staff",

        /**** List Setup ****/
        list:true,
        listLabel:"staff-list",
        orderByField:"staffName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddEntry,props:{}},
        delete:{component:DeleteListItem,props:{}},
        download:{component:DownloadTemplate,props:{skipParams:true}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        printCard:{component:BulkPrintCard,props:{}},
    };

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("staff");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
                {label:"Add", mobileLabel:"Add Staff",ref:"add",image:"/images/page-menu/add.png",title:"Add new staff",enabled:(permissions.create==2)},
                {label:"Template", mobileLabel:"Download Template",ref:"download",image:"/images/page-menu/template.png",title:"Download bulk add template",enabled:(permissions.create==2)},
                {label:"Delete", mobileLabel:"Delete Entries",ref:"delete",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
                {label:"Spreadsheet", mobileLabel:"Generate Spreadsheet",ref:"spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
                {label:"Bulk Edit",mobileLabel:"Bulk Edit",ref:"bulkEdit",image:"/images/page-menu/edit.png",title:"Edit staff details",enabled:bulkEditEnabled,padding:"3px"},
                {label:"Print Card",mobileLabel:"Print Card",ref:"printCard",image:"/images/page-menu/print-card.png",title:"Bulk print access card",enabled:(permissions.create == 2),padding:"2px"},
            ]
        })
    };

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    };

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <StaffItem key={index} index={index} content={content} onChange={onChange} onContentChange={onContentChange} registerReference={registerRef} permissions={this.state.permissions}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class StaffItem extends React.Component{

    state={
        error:"",
        step:"view",
        loading:false,
        loadText:"",
    };

    onUnlockPassback=()=>{
        this.setState({loading:true,loadText:"Unlocking passback..."});
        u.post({
            url:"/api/unlock-passback",
            data:{
                type:"staff",
                id:this.props.content.staffID,
                tenantID:this.props.content.tenantID,
            },
            success:()=>{
                this.setState({step:"confirm-remove",loading:false});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    };

    onPrintCard=()=>{
        store.set("staff-card",this.props.content);
        window.open("/staff-card?action=print","_blank","width=800,height=800,left=200,top=100");
    };

    render=()=>{
        const content = this.props.content;
        const edit = (this.props.permissions.update == 2);
        const url = "/api/update-staff-info";
        const index = this.props.index+"";
        const id = content.staffID;

        return (
            <div style={{textAlign:"center"}}>

                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <DynamicListArea listLabel={"staff-list"} permission="staff" content={this.props.content} onChange={this.props.onChange} index={this.props.index}/>

                <div className="list-segment-buttons">
                    <Link to={"/staff/"+content.staffID}>See More</Link>
                </div>
            </div>
        )
    }
}

class AddEntry extends React.Component{

    state={
        error:"",
        options:{},
        permissionGroupOptions:[],
        entryData:{
            turnstileAccess:{},
            dataAccess:{},
        },
    };

    show=(show)=>{
        this.refs[this.props.reference].show(show);
    }

    componentDidMount=()=>{
        this.getOptions();
        this.getPermissionGroups();
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:[
                "yesNo","staffCategory","gender"
              ]
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getPermissionGroups=()=>{
        u.post({
            url:"/api/get-permission-group-options",
            data:{},
            success:(options)=>{
                this.setState({permissionGroupOptions:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onComplete=()=>{
        this.setState({
            error:"",
            entryData:{
              turnstileAccess:{},
              dataAccess:{},
            }
        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label,index)=>{
        console.log(value,label);
        let entryData=this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onChangeAccess=(value,field)=>{
        this.setState({error:""});
        let entryData=this.state.entryData;
        entryData[field]=value;
        this.setState({entryData:entryData});
    }

    onValidate=(fields)=>{
        let keys = fields.map((item)=>{
          return item.field;
        });

        for(var i=0; i<keys.length; i++){
            if(keys[i] != "divider" && this.refs[keys[i]]){
                const current=this.refs[keys[i]].validate();
                console.log(current);
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
            }
        }


        this.refs[this.props.reference].onValidated(this.state.entryData);
    }

    render=()=>{
        const entryData=this.state.entryData;
        const options=this.state.options;
        const fields=[
            {dataType:"box",field:"staffName",label:"Staff Name",rules:{required:true}},
            {dataType:"select",field:"gender",label:"Gender",options:options.gender},
            {dataType:"contactNumber",field:"countryCode",countryCodeField:"countryCode",contactNumberField:"contactNumber",label:"Contact Number"},
            {dataType:"box",field:"email",label:"Email",rules:{required:true},type:"email"},
            {dataType:"select",field:"category",label:"Category",options:options.staffCategory},
            {dataType:"box",field:"designation",label:"Designation",info:"<b>Designation</b></br></br>The role, title or designation that is given to represent the job scope and or authority of the respective staff"},
            {dataType:"divider",field:"divider"},
            {dataType:"select",field:"isAdmin",label:"Is Admin",options:options.yesNo},
            {dataType:"select",field:"permissionGroupID",label:"Permission Group",options:this.state.permissionGroupOptions},
        ];

        return (
            <AddListItem ref={this.props.reference}
                         settings={this.props.settings}
                         reference={this.props.reference}
                         filetypes={Spreadsheet}
                         onToggleForm={this.props.onToggleForm}
                         onComplete={this.onComplete}
                         onCancel={this.onCancel}
                         stepScroll={true}
                         dragAndDropLabel="Upload completed template spreadsheet here"
                         >

                <div>
                    <h3>Fill in the following to create staff</h3>
                    {
                        fields.map(
                            (item,index)=>{
                                if(!entryData.isAdmin && item.field == "permissionGroupID"){
                                    return null;
                                }
                                return (
                                    <Input ref={item.field}
                                           key={index}
                                           fields={{
                                             ...item,
                                             onChange:this.onChange
                                           }}
                                           content={entryData}
                                           />
                                )
                            }
                        )
                    }

                    {
                        (entryData.isAdmin)
                            ?
                            <div style={{position:"relative",fontSize:"0.85em",marginTop:"30px"}}>
                                <Access content={entryData} onChange={this.onChangeAccess}/>
                            </div>
                            :
                            null
                    }
                </div>

                <div className="segment-buttons" style={{fontSize:"0.85em"}}>
                    <TextButton onClick={()=>{this.refs[this.props.reference].onBulkUpload()}}>Bulk Upload</TextButton>
                </div>

                <div className="segment-buttons">
                    <div className="segment-error">{this.state.error}</div>
                    <Button onClick={this.onCancel}>Cancel</Button>
                    <Button onClick={()=>{this.onValidate(fields)}}>Confirm</Button>
                </div>

           </AddListItem>
        )
    }
}

class BulkPrintCard extends React.Component{

    state={
        active:false,
        loading:false,
        step:0,
        cardOptions:[],
    };

    componentDidMount=()=>{
        this.getCardThemes();
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onBack=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    getCardThemes=()=>{
        u.post({
            url:"/api/get-card-themes",
            data:{},
            success:(cardOptions)=>{
                if(cardOptions.length == 0){
                    cardOptions.push({value:"default",label:"Default"});
                }
                this.setState({cardOptions:cardOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onSubmit=()=>{
        this.setState({error:""});
        const numEntries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        if(numEntries == 0){
            this.setState({error:"Please select at least 1 staff to continue"});
            return 0;
        }

        const cardTheme=this.refs['cardTheme'].validate();
        if(!cardTheme.valid){
            this.setState({error:cardTheme.value});
            return 0;
        }

        this.setState({loading:true,error:""});
        u.post({
            url:"/api/get-selected-staff",
            data:{
                selected:this.props.selected
            },
            success:(staffs)=>{
                const staff = staffs.map((item)=>{
                    item.cardTheme=cardTheme.value;
                    return item;
                })
                store.set("print-multi-card",staff);
                store.set("print-multi-card-category","staff");
                this.setState({loading:false});
                window.open("/print-multiple-card","_blank","width=800,height=800,left=200,top=100");
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
        return(
            <Segment active={this.state.active} scrollAnchor={this.props.scrollAnchor} align={"center"}>
                <div className="list-add">
                    <div className="segment-title">Bulk Print Card</div>
                    <div className="segment-error">{this.state.error}</div>
                    <LoadGraphic active={this.state.loading} text="Preparing print preview..."/>
                    <div className="segment-content">
                        <h3>Please select the staff to print & card template:</h3>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Card Template:</div>
                            <div className="segment-item-input">
                                <Select ref="cardTheme" values={{options:this.state.cardOptions}} rules={{required:true}}/>
                            </div>
                        </div>
                        <div className="segment-selected"><u>{entries} staff selected</u></div>
                        <div className="segment-buttons">
                            <Button type="medium" onClick={this.onBack}>Cancel</Button>
                            <Button type="medium" onClick={this.onSubmit}>Print</Button>
                        </div>
                    </div>
                </div>
            </Segment>
        )
    }
}
