import React from 'react';
import PublicPageLayout from '../../components/PublicPageLayout';
import {Clock} from './PSAOperationalStatistics';
import {DLTD,DLTH,MapNav} from './PSAPPRDormLayout';
import {io} from 'socket.io-client';
import {LoadGraphic} from '../../components/Common';
import {TextButton} from '../../components/Input';
import moment from 'moment';
import u from '../../utilities/Utilities';
import store from 'store';
import {Link} from 'react-router-dom';
import "../../styles/dashboards/psa-dashboards.scss";
import "../../styles/dashboards/psa-ppr-dorm-layout.scss";

export default class PSAPPRDormLayout extends React.Component{

    socketType="BR Dormitory Layout";

    tableRows=10;

    pollInterval=300000;

    dormID = this.props.match.params.dormID;

    state={
        content:[],
        instantiated:false,
        defaultView:true,
    };

    componentDidMount=()=>{
        this.getContent();

        this.updateInterval=setInterval(()=>{
            this.setState({update:!this.state.update});
        },5000);

        this.contentInterval=setInterval(()=>{
            this.getContent();
        },this.pollInterval);
    }

    componentWillUnmount=()=>{
        clearInterval(this.contentInterval);
        clearInterval(this.updateInterval);
    }

    getContent=()=>{
        this.setState({error:""});
        u.post({
            url:"/api/get-dashboard-content",
            data:{
                dormID:this.dormID,
                type:this.socketType,
            },
            success:(content)=>{
                this.setState({
                    content:content,
                    instantiated:true
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getSocketInfo=()=>{
        this.setState({loading:true,loadText:"Getting server info..."});
        u.post({
            url:"/api/get-socket-info",
            data:{},
            success:({socketIP,socketToken})=>{
                this.token=socketToken;
                this.socketIP=socketIP;
                this.setupSocket();
            },
            error:(error)=>{
                this.setState({loadText:error});
            }
        })
    }

    setupSocket=()=>{
        this.socket = io(this.socketIP,{
            reconnectionDelayMax: 5000,
            withCredentials: true,
            extraHeaders:{
                "csrf-token":store.get("csrfToken"),
                "socket-token":this.token,
            },
            transports:["websocket"],
        });

        this.socket.on('connect',()=>{
            this.setState({sessionID:this.socket.id,connected:true});
            //Initial Emit
            this.socket.emit("get-base-state",
            {
                socketID:this.socket.id,
                socketType:this.socketType,
            });
        });

        this.socket.on("disconnect",()=>{
            this.setState({connected:false});
        });

        this.socket.on("set-base-state",(content)=>{
            let modContent = this.modContent(content);
            this.setState({content:modContent});
        });
    }

    getScrollWidth=(content)=>{
        const blockMargin=10;
        const tableWidth = content.map((building)=>{
            const maxRooms=Math.max(...(building.levels.map((rooms)=>{return rooms.length})));
            return (62 + (72*maxRooms));
        });
        let width=0;
        for(var i=0; i<tableWidth.length; i++){
            width += blockMargin + tableWidth[i];
        }
        return width+"px";
    }

    onNav=(index)=>{
        const element = document.getElementById("block-"+index);
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    }

    onToggleView=()=>{
        this.setState({defaultView:!this.state.defaultView});
    }

    render=()=>{
        const content=this.state.content;
        const scrollWidth=this.getScrollWidth(content);

        return(
            <PublicPageLayout url="/psa-operational-status" title={this.socketType}
                              containerStyle={{paddingLeft:"10px",paddingRight:"10px"}}>
                <LoadGraphic active={!this.state.instantiated} text="Preparing"/>

                <div className="psa-dashboard-header-container" style={{height:"0"}}>
                    <div className="psa-dashboard-header">
                        <div className="pdh-title"><h2>{this.socketType.toUpperCase()}</h2></div>
                        <div className='pdh-title right'><h2><Clock/></h2></div>
                    </div>
                </div>

                <div style={{position:"relative",textAlign:"center"}}>
                    {
                        (this.state.defaultView)
                            ?
                            <div>
                                <div className="br-dorm-blueprint">
                                    <img src="/images/dashboard/psa/br-clean.png"/>
                                    {
                                        content.map(
                                            (item,index)=>{
                                                return (
                                                    <MapNav key={index}
                                                            content={item}
                                                            index={index}
                                                            onClick={this.onNav}
                                                            width="24%"
                                                            fontSize="11px"
                                                            />
                                                )
                                            }
                                        )
                                    }
                                </div>

                                <div className="br-content-container">
                                    <div ref="scroll" className="horizontal-scroll-container" style={{width:scrollWidth}}>
                                        {
                                        content.map(
                                            (building,b)=>{
                                                return (
                                                    <div key={b} id={"block-"+b} className="dl-building-container">
                                                        <div className="dl-building-name" style={{backgroundColor:building.buildingColor}}><b>{building.buildingName}</b></div>
                                                        <div className="dl-table-container">
                                                        {
                                                            building.levels.map(
                                                                (rooms,index,arr)=>{
                                                                    const hasSickbay = (rooms.filter((item)=>{return item.type == "sickbay"}).length > 0);
                                                                    return (
                                                                        <div key={index} className={"dl-table" + ((index == 0) ? " first" : "") + ((index == arr.length - 1) ? " last" : "")}>
                                                                            <DLTH hasSickbay={hasSickbay}
                                                                                  />
                                                                            {
                                                                                rooms.map(
                                                                                    (room,r)=>{
                                                                                        return(
                                                                                            <DLTD key={r}
                                                                                                  content={room}
                                                                                                  hasSickbay={hasSickbay}
                                                                                                  />
                                                                                        )
                                                                                    }
                                                                                )
                                                                            }
                                                                        </div>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="br-content-container stacked">
                                {
                                    content.map(
                                        (building,b)=>{
                                            return (
                                                <div key={b} className="dl-building-container stacked">
                                                    <div className="dl-building-name" style={{backgroundColor:building.buildingColor}}><b>{building.buildingName}</b></div>
                                                    <div className="dl-table-container">
                                                    {
                                                        building.levels.map(
                                                            (rooms,index,arr)=>{
                                                                const hasSickbay = (rooms.filter((item)=>{return item.type == "sickbay"}).length > 0);
                                                                return (
                                                                    <div key={index} className={"dl-table" + ((index == 0) ? " first" : "") + ((index == arr.length - 1) ? " last" : "")}>
                                                                        <DLTH hasSickbay={hasSickbay}
                                                                              stacked={true}
                                                                              />
                                                                        {
                                                                            rooms.map(
                                                                                (room,r)=>{
                                                                                    return(
                                                                                        <DLTD key={r}
                                                                                              content={room}
                                                                                              hasSickbay={hasSickbay}
                                                                                              stacked={true}
                                                                                              />
                                                                                    )
                                                                                }
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                            </div>
                    }
                    <div style={{textAlign:"center",margin:"20px auto"}}>
                        <TextButton onClick={this.onToggleView}>
                          {
                              (this.state.defaultView)
                                  ?
                                  "See Stacked View"
                                  :
                                  "See Blueprint View"
                          }
                        </TextButton>
                    </div>
                </div>
            </PublicPageLayout>
        )
    }
}
