import React from 'react';
import {Databox,LoadGraphic,Step} from '../components/Common';
import PublicPageLayout from '../components/PublicPageLayout';
import {Box,Button} from '../components/Input';
import sha from 'sha1';
import u from '../utilities/Utilities';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import '../styles/create-user.scss';

export default class ResetPassword extends React.Component{

    state={
        loading:true,
        loadText: "Attempting to reset password...",
        step:0,
        error:"",
    };

    componentDidMount=()=>{
        const query = qs.parse(this.props.location.search);
        const passToken = query.token;

        if(passToken) {
            u.post({
                url: "/noauth/commit-password",
                data: {
                    token: passToken
                },
                success:(callback)=>{
                    this.setState({loading:false});
                },
                error:(error, status)=>{
                    this.setState({loadText: error});
                }
            });
        }

        else{
            this.setState({loadText:"Error! Please access this page from the link sent to your email"});
        }
    }

    render=()=>{
        const enabled=(typeof this.state.passToken != "undefined");
        const entryData = this.state.entryData;

        return(
            <PublicPageLayout title="Reset Password" url="/reset-password">
                <div style={{position:"relative",textAlign:"center"}}>
                    <div className="create-container">
                        <div className="create-message-container">
                            <h2 className="create-message">Confirm Password Reset</h2>
                        </div>

                        <div className="page-error">{this.state.error}</div>

                        <div style={{position:"relative",padding:"20px 0"}}>
                            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                            <h3>
                                <img src="/images/input/confirm.png" style={{height:"60px",display:"inline-block",verticalAlign:"middle",margin:"0 10px"}}/>
                                <span style={{display:"inline-block",verticalAlign:"middle",margin:"20px 10px 5px 10px"}}>Password has been successfully reset</span>
                            </h3>
                            <div className="disclaimer-text" style={{margin:"0 auto 20px auto"}}>
                                  <Link to="/login" title="Login here">Go to Login</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </PublicPageLayout>
        )
    }
}
