import React from 'react';
import {Helmet,HelmetProvider} from 'react-helmet-async';
import PropTypes from 'prop-types';

export default class EmptyPageLayout extends React.Component{

    state={
        instantiated:false,
    };

    componentDidMount=()=>{
        this.setState({
            instantiated:true
        });
    }

    render(){
        if(!this.state.instantiated){
            return (<div/>)
        }

        return(
            <HelmetProvider>
                <div style={this.props.style}>
                    <Helmet>
                        <title>{this.props.title}</title>
                        <meta name="viewport" content="width=device-width,initial-scale=1"/>
                        <meta name="viewport" content="height=device-height,initial-scale=1"/>
                    </Helmet>
                    <div className={this.props.className}>
                        {this.props.children}
                    </div>

                    <div id="tool-tip" className="tool-tip">
                        <div id="tool-tip-text" className="tool-tip-text"/>
                        <div className="tool-tip-triangle"/>
                    </div>
                </div>
            </HelmetProvider>
        );
    }
};
EmptyPageLayout.propTypes={
    title:PropTypes.string,
    url:PropTypes.string.isRequired,
};
