import React from 'react';
import {Databox, LoadGraphic, Step} from '../../components/Common';
import {LandingLayout} from './PortalLogin';
import {Box, Button} from '../../components/Input';
import u from '../../utilities/Utilities';
import {Link} from 'react-router-dom';
import qs from 'query-string';
import '../../styles/client-portal/portal-create-user.scss';

export default class PortalResetPassword extends React.Component {

    state = {
        loading: true,
        loadText: "Attempting to update credentials...",
        step: 0,
        error: "",
    };

    componentDidMount = () => {
        const query = qs.parse(this.props.location.search);
        const passToken = query.token;

        if (passToken) {
            u.post({
                url: "/noauth/cp-commit-password",
                data: {
                    token: passToken,
                    testDelay: 2000,
                },
                success: (callback) => {
                    this.setState({loading: false});
                },
                error: (error, status) => {
                    this.setState({loadText: error});
                }
            });
        }

        else {
            this.setState({loadText: "Error! Please access this page from the link sent to your email"});
        }
    }

    render = () => {
        const enabled = (typeof this.state.passToken != "undefined");
        const entryData = this.state.entryData;

        return (
            <LandingLayout title="Reset Credentials | MWMD Dormitories" url="/reset-password" text="Confirm Reset">
                <div style={{position: "relative", textAlign: "center"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText} />

                    <div className="page-error">{this.state.error}</div>

                    <div style={{position: "relative", padding: "20px 0"}}>
                        <LoadGraphic active={this.state.loading} text={this.state.loadText} />
                        <h3>
                            <img src="/images/input/confirm.png" style={{height: "60px", display: "inline-block", verticalAlign: "middle", margin: "0 10px"}} />
                            <span style={{display: "inline-block", verticalAlign: "middle", margin: "20px 10px 5px 10px"}}>Credentials has been successfully reset</span>
                        </h3>
                        <div className="disclaimer-text" style={{margin: "0 auto 20px auto", fontSize: "14px"}}>
                            <Link to="/supplier" title="Login here">Go to Login</Link>
                        </div>
                    </div>
                </div>
            </LandingLayout>
        )
    }
}
