
const navButtons = [
    {name:"Dashboard", url:"/dashboard",permission:"none",image:"/images/nav-menu/dashboard.png"},
    {name:"Estate", url:"/estate-list/asset/all",permission:"estate",image:"/images/nav-menu/overview.png"},
    {name:"Resident", url:"/resident-list",permission:"resident",image:"/images/nav-menu/tenants.png"},
    {name:"Report Sick", url:"/report-sick-list",permission:"resident",image:"/images/nav-menu/first-aid-sign.png"},
    {name:"Staff", url:"/staff-list",permission:"staff",image:"/images/nav-menu/staff.png"},
    {name:"Permission", url:"/permission-list",permission:"permission",image:"/images/nav-menu/permissions.png"},
    {name:"Organization", url:"/client-list",permission:"client",image:"/images/nav-menu/clients.png"},
    {name:"Agreement", url:"/agreement-list",permission:"agreement",image:"/images/nav-menu/agreements.png"},
    {name:"Accessory", url:"/accessory-list",permission:"combined",field:"accessory",image:"/images/nav-menu/accessory.png"},
    {name:"Misconduct", url:"/misconduct-list",permission:"misconduct",image:"/images/nav-menu/misconduct.png"},
    {name:"Exit Req.", url:"/exit-request-list",permission:"exit",image:"/images/nav-menu/exit.png"},
    {name:"Facility", url:"/facility-list",permission:"facility",image:"/images/nav-menu/facility.png"},
    {name:"Feedback", url:"/feedback-list",permission:"feedback",image:"/images/nav-menu/feedback.png"},
    {name:"Transfer", url:"/transfer-list",permission:"transfer",image:"/images/nav-menu/transfer_v2.png"},
    {name:"Tasks", url:"/task-list",permission:"task",image:"/images/nav-menu/task.png"},
    {name:"Parcel", url:"/parcel-list",permission:"parcel",image:"/images/nav-menu/parcel.png"},
    {name:"Visitor", url:"/visitor-list",permission:"visitor",image:"/images/nav-menu/visitor.png"},
    {name:"Events", url:"/attendance-event-list",permission:"attendance",image:"/images/nav-menu/attendance-event.png"},
    {name:"Security", url:"/security-list",permission:"combined",field:"security",image:"/images/nav-menu/security.png"},
    {name:"Reports", url:"/reports",permission:"combined",field:"report",altPermission:"report",image:"/images/nav-menu/reports.png"},
    {name:"Notification", url:"/notification-list",permission:"notification",image:"/images/nav-menu/notification.png"},
    {name:"Survey", url:"/survey-list",permission:"survey",image:"/images/nav-menu/survey.png"},
    {name:"History", url:"/history-list",permission:"combined",field:"history",image:"/images/nav-menu/history.png"},
    {name:"Portal Hist.", url:"/portal-history-list",permission:"combined",field:"history",image:"/images/nav-menu/portal-history.png"},
];

export default navButtons;
