// src/components/AppRoutes.js
import React from 'react';
import {Route,Switch} from 'react-router-dom';
import u from "../utilities/Utilities";
import '../styles/app.scss';

export default class AppRoutes extends React.Component {

    componentDidMount=()=>{
        this.resize();
        window.addEventListener("resize",this.resize);
    }

    componentWillUnmount=()=>{
        window.removeEventListener("resize",this.resize);
    }

    resize=()=>{
        const pageRoot = document.getElementById("root");

        if(!u.isiOS()){
            pageRoot.style.height = window.innerHeight +"px";
        }
        else{
            pageRoot.style.height = screen.height +"px";
        }
    }

    render=()=>{
        return (
            <Switch>
                <Route exact={true} path="/" component={Landing}/>
                <Route path="/login" component={Landing}/>
                <Route path="/create-user" component={CreateUser}/>
                <Route path="/reset-password" component={ResetPassword}/>
                <Route path="/download-file/:filestamp/:filename" component={Download}/>
                <Route path="/dashboard" component={Dashboard}/>
                <Route path="/submit-vitals/:dormCode" component={SubmitVitals}/>

                <Route path="/absence-reason-list" component={AbsenceReasonList}/>
                <Route path="/accessory-list" component={AccessoryList}/>
                <Route path="/agreement-list" component={AgreementList}/>
                <Route path="/agreement/:agreementID" component={Agreement}/>
                <Route path="/announcement-list" component={AnnouncementList}/>
                <Route path="/attendance-event-list" component={AttendanceEventList}/>
                <Route path="/attendance-list" component={AttendanceList}/>
                <Route path="/booking-list" component={BookingList}/>
                <Route path="/client-list" component={ClientList}/>
                <Route path="/client/:clientID" component={Client}/>
                <Route path="/estate-list/:type/:id" component={Estate}/>
                <Route path="/exit-request-list" component={ExitRequestList}/>
                <Route path="/facility-list" component={FacilityList}/>
                <Route path="/form-list" component={FormList}/>
                <Route path="/feedback-list" component={FeedbackList}/>
                <Route path="/history-list" component={HistoryList}/>
                <Route path="/misconduct-list" component={MisconductList}/>
                <Route path="/misconduct-incident-report" component={MisconductIncidentReport}/>
                <Route path="/notification-list" component={NotificationList}/>
                <Route path="/offense-list" component={OffenseList}/>
                <Route path="/permission-list" component={PermissionGroupList}/>
                <Route path="/report-sick-list" component={ReportSickList}/>
                <Route path="/event-print-qr" component={PrintAttendanceEvent}/>
                <Route path="/print-card" component={PrintCard}/>
                <Route path="/print-dorm" component={PrintDormQR}/>
                <Route path="/print-multiple-card" component={PrintMultipleCard}/>
                <Route path="/price-list" component={PriceList}/>
                <Route path="/reports" component={Reports}/>
                <Route path="/resident-list" component={ResidentList}/>
                <Route path="/resident/:tenantID" component={Resident}/>
                <Route path="/room-manifest" component={RoomManifest}/>
                <Route path="/staff-list" component={StaffList}/>
                <Route path="/staff/:staffID" component={Staff}/>
                <Route path="/security-list" component={SecurityList}/>
                <Route path="/scheduler-list" component={SchedulerList}/>
                <Route path="/survey-list" component={SurveyList}/>
                <Route path="/survey-result/:surveyID" component={SurveyResult}/>
                <Route path="/task-list" component={TaskList}/>
                <Route path="/task-report" component={TaskReport}/>
                <Route path="/transfer-list" component={TransferList}/>
                <Route path="/usage-report" component={UsageReport}/>
                <Route path="/visitor-list" component={VisitorList}/>
                <Route path="/visitor-self-submission/:dormCode" component={VisitorSelfSubmission}/>
                <Route path="/visitor-card" component={VisitorCard}/>
                <Route path="/vitals-dashboard/:dormID" component={VitalsDashboard}/>
                <Route path="/portal-history-list" component={PortalHistoryList}/>
                <Route path="/parcel-list" component={ParcelList}/>

                <Route path="/psa-resident-status/:dormID" component={PSAResidentStatus}/>
                <Route path="/psa-operational-statistics/:dormID" component={PSAOperationalStatistics}/>
                <Route path="/psa-operational-status/:dormID" component={PSAOperationalStatus}/>
                <Route path="/psa-room-status/:dormID" component={PSARoomStatus}/>
                <Route path="/psa-ppr-dorm-layout/:dormID" component={PSAPPRDormLayout}/>
                <Route path="/psa-br-dorm-layout/:dormID" component={PSABRDormLayout}/>

                <Route path="/supplier" component={PortalLogin}/>
                <Route path="/portal/dashboard" component={PortalDashboard}/>
                <Route path="/portal/create-user" component={PortalCreateUser}/>
                <Route path="/portal/reset-password" component={PortalResetPassword}/>
                <Route path="/portal/history-list" component={PortalHistory}/>
                <Route path="/portal/resident-list" component={PortalResidentList}/>
                <Route path="/portal/resident/:tenantID" component={PortalResident}/>
                <Route path="/portal/absence-reason-list" component={PortalAbsenceReason}/>
                <Route path="/portal/transfer-list" component={PortalTransferList}/>

                <Route path="*" component={NotFound}/>
            </Switch>
        );
    }
}

import AbsenceReasonList from '../pages/AbsenceReasonList';
import AccessoryList from '../pages/AccessoryList';
import AgreementList from '../pages/AgreementList';
import Agreement from '../pages/Agreement';
import AnnouncementList from '../pages/AnnouncementList';
import AttendanceEventList from '../pages/AttendanceEventList';
import AttendanceList from '../pages/AttendanceList';
import BookingList from '../pages/BookingList';
import ClientList from '../pages/ClientList';
import Client from '../pages/Client';
import CreateUser from '../pages/CreateUser';
import Dashboard from '../pages/Dashboard';
import Download from '../pages/Download';
import Estate from '../pages/Estate';
import ExitRequestList from '../pages/ExitRequestList';
import FacilityList from '../pages/FacilityList';
import FormList from '../pages/FormList';
import FeedbackList from '../pages/FeedbackList';
import HistoryList from '../pages/HistoryList';
import Landing from '../pages/Landing';
import MisconductList from '../pages/MisconductList';
import MisconductIncidentReport from '../pages/MisconductIncidentReport';
import NotFound from '../pages/NotFound';
import NotificationList from '../pages/NotificationList';
import OffenseList from '../pages/OffenseList';
import PermissionGroupList from '../pages/PermissionGroupList';
import ReportSickList from '../pages/ReportSickList';
import PrintAttendanceEvent from '../pages/PrintAttendanceEvent';
import PrintCard from '../pages/PrintCard';
import PrintDormQR from '../pages/PrintDormQR';
import PrintMultipleCard from '../pages/PrintMultipleCard';
import PriceList from '../pages/PriceList';
import Reports from '../pages/Reports';
import ResidentList from '../pages/ResidentList';
import Resident from '../pages/Resident';
import ResetPassword from '../pages/ResetPassword';
import RoomManifest from '../pages/RoomManifest';
import SchedulerList from '../pages/SchedulerList';
import StaffList from '../pages/StaffList';
import Staff from '../pages/Staff';
import SecurityList from '../pages/SecurityList';
import SubmitVitals from '../pages/SubmitVitals';
import SurveyList from '../pages/SurveyList';
import SurveyResult from '../pages/SurveyResult';
import TaskList from "../pages/TaskList";
import TaskReport from '../pages/TaskReport';
import TransferList from '../pages/TransferList';
import UsageReport from '../pages/UsageReport';
import VisitorList from '../pages/VisitorList';
import VisitorCard from '../pages/VisitorCard';
import VisitorSelfSubmission from '../pages/VisitorSelfSubmission';
import VitalsDashboard from '../pages/VitalsDashboard';
import PortalHistoryList from '../pages/PortalHistoryList';
import ParcelList from '../pages/ParcelList';

import PSAResidentStatus from '../pages/dashboards/PSAResidentStatus';
import PSAOperationalStatistics from '../pages/dashboards/PSAOperationalStatistics';
import PSAOperationalStatus from '../pages/dashboards/PSAOperationalStatus';
import PSARoomStatus from '../pages/dashboards/PSARoomStatus';
import PSAPPRDormLayout from '../pages/dashboards/PSAPPRDormLayout';
import PSABRDormLayout from '../pages/dashboards/PSABRDormLayout';

import PortalLogin from '../pages/client-portal/PortalLogin';
import PortalDashboard from '../pages/client-portal/PortalDashboard';
import PortalCreateUser from '../pages/client-portal/PortalCreateUser';
import PortalHistory from '../pages/client-portal/PortalHistoryList';
import PortalResidentList from '../pages/client-portal/PortalResidentList';
import PortalTransferList from '../pages/client-portal/PortalTransferList';
import PortalAbsenceReason from '../pages/client-portal/PortalAbsenceReason';
import PortalResident from '../pages/client-portal/PortalResident';
import PortalResetPassword from '../pages/client-portal/PortalResetPassword';