import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,TextButton,ContactNumber, Checkbox} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';

export default class ClientList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Organizations",
        permission:"client",
        url:"/client-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-new-client",
        bulkURL:"/api/add-bulk-client",
        deleteURL:"/api/delete-client-entries",
        spreadsheetURL:"/api/generate-clients-spreadsheet",
        templateURL:"/api/get-add-client-template",
        overviewURL:"/api/get-all-clients",

        /**** List Setup ****/
        list:true,
        listLabel:"client-list",
        orderByField:"clientName",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddClient,props:{}},
        delete:{component:DeleteListItem,props:{}},
        download:{component:DownloadTemplate,props:{skipParams:true}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
        merge:{component:ClientMerge,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("client");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add",ref:"add",mobileLabel:"Add Entry",image:"/images/page-menu/add.png",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Template",ref:"download",mobileLabel:"Download Bulk Add Template",image:"/images/page-menu/template.png",title:"Download bulk add template",enabled:(permissions.create==2)},
              {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",mobileLabel:"Bulk Edit",image:"/images/page-menu/edit.png",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
              {label:"Merge Entries",ref:"merge",mobileLabel:"Merge Entries",image:"/images/page-menu/merge.png",title:"Merge multiple entries",enabled:(permissions.create),padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <ClientItem key={index}
                                            content={content}
                                            index={index}
                                            onChange={onChange}
                                            onContentChange={onContentChange}
                                            registerReference={registerRef}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class ClientItem extends React.Component{
    render=()=>{
        const content = this.props.content;
        return (
            <div>
                <DynamicListArea listLabel={"client-list"}
                                 permission="client"
                                 content={this.props.content}
                                 onChange={this.props.onChange}
                                 index={this.props.index}
                                 />

                <div className="list-segment-buttons">
                    <Link to={"/client/"+content.clientID}>See More</Link>
                </div>
            </div>
        )
    }
}
ClientItem.propTypes={
    registerReference:PropTypes.func.isRequired
};

class AddClient extends React.Component{

    state={
      error:""
    };

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "clientName",
            "contactName",
            "contactEmail",
            "contactNumber",
        ];

        let data={};
        for(var i=0; i<values.length; i++){
            const field = this.refs[values[i]].validate();
            if(!field.valid){
                this.setState({error:field.value});
                return 0;
            }
            else{
              if(typeof field.contactNumberField != "undefined"){
                  data[field.contactNumberField] = field.contactNumber;
                  data[field.countryCodeField] = field.countryCode;
              }
              else{
                  data[values[i]] = field.value;
              }
            }
        }

        this.refs['add'].onValidated(data);
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    render=()=>{
        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         onComplete={this.onCancel}
                         onToggleForm={this.props.onToggleForm}
                         dragAndDropLabel="Upload Completed Organization Template Here">

                <h3>Complete following fields to continue</h3>

                <div className="segment-error">{this.state.error}</div>

                <div className="segment-content">
                    <div className="segment-full-item">
                        <div className="segment-item-label">Organization</div>
                        <div className="segment-item-input">
                            <Box ref="clientName" label="Organization" rules={{required:true}}/>
                        </div>
                    </div>

                    <div className="segment-full-item">
                        <div className="segment-item-label">Contact Name</div>
                        <div className="segment-item-input">
                            <Box ref="contactName" label="Contact Name" rules={{required:true}}/>
                        </div>
                    </div>

                    <div className="segment-full-item">
                        <div className="segment-item-label">Email</div>
                        <div className="segment-item-input">
                            <Box ref="contactEmail" label="Email" type="email" rules={{required:true}}/>
                        </div>
                    </div>

                    <div className="segment-full-item">
                        <div className="segment-item-label">Phone</div>
                        <div className="segment-item-input">
                            <ContactNumber ref="contactNumber" contactNumberField="contactNumber" countryCodeField="contactCountryCode"/>
                        </div>
                    </div>

                    <div className="segment-buttons" style={{fontSize:"0.85em"}}>
                        <TextButton onClick={()=>{this.refs[this.props.reference].onBulkUpload()}}>Bulk Add Organizations</TextButton>
                    </div>

                    <div className="segment-buttons">
                        <span className="mobile-only"><Button onClick={this.onCancel}>Cancel</Button></span>
                        <Button type="medium" onClick={this.onCreate}>Create</Button>
                    </div>
                </div>
            </AddListItem>
        )
    }
}

class ClientMerge extends React.Component{

  state={
      active:false,
      loading:false,
      loadText:"Loading...",
      error:"",
      step:"select",
      clients:[],
      mainIndex:0,
  };

  show=(show)=>{
      this.setState({active:show});
  }

  onCancel=()=>{
      this.setState({step:"select",error:"",clients:[],mainIndex:0});
      this.props.onToggleForm(this.props.reference,false);
  }

  onSelected=()=>{
      this.setState({error:""});
      const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
      if(entries < 2){
          this.setState({error:"Please select at least 2 entries to be merged"});
          return 0;
      }
      else if(entries > 5){
          this.setState({error:"You may only merge up to 5 entries each time"});
          return 0;
      }
      this.setState({loading:true,loadText:"Checking..."});
      u.post({
          url:"/api/client-check-merge",
          data:{
              selected:this.props.selected
          },
          success:(clients)=>{
              this.setState({step:"choose-main",loading:false,clients:clients});
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
      })
  }

  onMerge=()=>{
      this.setState({loading:true,loadText:"Merging..."});
      u.post({
          url:"/api/client-merge-entries",
          data:{
              mainIndex:this.state.mainIndex,
              entries:this.state.clients,
          },
          success:()=>{
              this.setState({step:"complete",error:"",loading:false});
              setTimeout(()=>{
                if(this.props.settings.overview){
                    this.props.settings.overview.onRefreshList();
                }
              },500);
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
      });
  }

  render=()=>{
      const entries = (this.props.selected.all) ? (this.props.selected.entryCount - this.props.selected.data.length) : this.props.selected.data.length;
      return(
        <Segment active={this.state.active}>
            <div className="segment-title">Merge Organizations</div>
            <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
            <div className="segment-error">{this.state.error}</div>

            <Step active={this.state.step == "select"}>
                <div className="segment-content" style={{textAlign:"center"}}>
                    <h3>Select Organizations To Merge</h3>

                    <div className="segment-selected-bg">
                        <div className="segment-selected">{entries} {(entries==1) ? "entry" : "entries"} selected</div>
                        <div className="segment-note" style={{marginBottom:"20px"}}>Select the entries you wish to edit from the list below</div>
                    </div>

                    <div className="segment-buttons">
                        {
                          (u.isMobileDevice())
                            ?
                            <Button onClick={this.onClick}>Cancel</Button>
                            :
                            null
                        }
                        <Button onClick={this.onSelected}>Next</Button>
                    </div>
                </div>
            </Step>

            <Step active={this.state.step == "choose-main"}>
                <div className="segment-content" style={{textAlign:"center"}}>
                    <h3>Select Main Entry</h3>
                    <div className="segment-note">Single column data such as Name, Code, RCB, etc. will default to the values provided in the main entry</div>
                    <div className="segment-table">
                        <table>
                            <tbody>
                                <tr>
                                    <th className="segment-table-select"/>
                                    <th>Organization</th>
                                    <th>RCB</th>
                                    <th>Address</th>
                                    <th>Postal Code</th>
                                </tr>
                                {
                                    this.state.clients.map(
                                        (item,index)=>{
                                            return (
                                                <tr key={index} className="segment-table-row">
                                                    <td className="segement-table-select"><Checkbox values={{value:(this.state.mainIndex == index)}} onChange={()=>{this.setState({mainIndex:index,error:""})}}/></td>
                                                    <td>{item.clientName}</td>
                                                    <td>{item.rcb}</td>
                                                    <td>{item.address}</td>
                                                    <td>{item.postalCode}</td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="segment-note text-negative">Note. Action cannot be undone</div>
                    <div className="segment-buttons">
                        <Button onClick={()=>{this.setState({error:"",step:"select"})}}>Back</Button>
                        <Button onClick={this.onMerge}>Merge</Button>
                    </div>
                </div>
            </Step>

            <Step active={(this.state.step == "complete")}>
                <div className="segment-content" style={{textAlign:"center"}}>
                    <SegmentComplete onComplete={this.onCancel}>Successfully Merged {this.state.clients.length} Organization Entries</SegmentComplete>
                </div>
            </Step>
        </Segment>
      )
  }
}
