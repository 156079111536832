// src/components/Layout.js
import React from 'react';
import {Helmet,HelmetProvider} from 'react-helmet-async';
import {ComponentPopup,PopupGallery} from '../components/Popup';
import PropTypes from 'prop-types';
import store from 'store';
import {Header,Footer} from '../components/Common';
import navButtons from '../data/NavButtons';
import Popup from '../components/Popup';
import u from '../utilities/Utilities';
import moment from 'moment';
import queryString from 'query-string';

export default class AppLayout extends React.Component {

    state={
        isLoading:true,
        navButtons:[],
    };

    componentDidMount=(e)=>{
        //Check if tempUser is not in tempLocation, send to tempLocation
        const tempUser=store.get("tempUser");
        const tempLocation=store.get("tempLocation");
        const tempQuery = store.get("tempQuery");
        const url = this.props.url;
        if(tempUser){
            if(tempLocation.substr(0,url.length) != url){
                window.location = tempLocation + ((tempQuery) ? tempQuery : "");
                return 0;
            }
        }

        u.post({
            url:"/api/validate-token",
            data:{},
            success:(callback)=>{
                //Update cookies
                for(var key in callback){
                    store.set(key,callback[key]);
                }
                store.set("tokenTime",moment().format("YYYY-MM-DDTHH:mm:ss"));

                const hasPermission = this.checkPermission(this.props.settings.permission,this.props.settings.combinedField,this.props.settings.altPermission,this.props.settings.altField);
                if(hasPermission){
                    this.setState({isLoading:false});
                }
                else {
                    store.set("dashboard-action", "No Permission");
                    store.set("dashboard-action-label", this.props.title);
                    store.set("redirect", window.location);
                    if (typeof window != "undefined") {
                        window.location = "/dashboard";
                    }
                }
            },
            error:(error)=>{}
        });
        this.setState({isLoading:false});

        let nb=[];
        for(var i=0; i<navButtons.length; i++){
            if (this.checkPermission(navButtons[i].permission,navButtons[i].field,navButtons[i].altPermission,navButtons[i].altField)) {
                nb.push(navButtons[i]);
            }
        }
        this.setState({navButtons:nb});
    }

    checkPermission=(moduleID,combinedField,altModuleID,altField)=>{
        if(moduleID == "none"){
            return true;
        }
        const perms = u.getAllPermissions();
        let access = false;

        if(combinedField){
            if(perms[moduleID])
                access = (parseInt(perms[moduleID][combinedField]) == 2);
        }
        else{
            if(perms[moduleID])
                access = (parseInt(perms[moduleID].view) == 2)
        }

        if(!access){
            if(altField){
                if(perms[altModuleID])
                    access = (parseInt(perms[altModuleID][combinedField]) == 2);
            }
            else if(altModuleID){
                if(perms[altModuleID])
                    access = (parseInt(perms[altModuleID].view) == 2);
            }
        }

        return access;
    };

    onPopup=(content)=>{
        content.cancel=this.onCancelPopup;
        this.setState({popup:content});
    }

    onCancelPopup=()=>{
        this.setState({popup:undefined});
    }

    showPopup=(show)=>{
        this.refs['popup'].show(show);
    }

    setPopupComponent=(component)=>{
        this.refs['popup'].setComponent(component);
    }

    render=()=>{
        if(this.state.isLoading){
            return (
                <div className="app-load-container"></div>
            )
        }

        return (
            <HelmetProvider>
                <div className="page-container" style={this.props.style}>
                    <Helmet>
                        <title>{this.props.settings.title} | Smartdorm</title>
                        <meta name="viewport" content="width=device-width,initial-scale=1"/>
                        <meta name="viewport" content="height=device-height,initial-scale=1"/>
                    </Helmet>
                    <div id="docu-top" className="document-top"/>
                    <Header type="normal" hasNav={true} buttons={this.state.navButtons} current={this.props.settings.url} pageTitle={this.props.settings.title}/>
                    <div className="app-content">{this.props.children}</div>

                    {
                        (this.state.popup)
                            ?
                            <Popup content={this.state.popup}/>
                            :
                            null
                    }

                    <div className="no-print">
                        <Footer/>
                    </div>

                    <PopupGallery ref="gallery"/>

                    <ComponentPopup ref="popup"/>

                    <div id="tool-tip" className="tool-tip">
                        <div className="tool-tip-down-triangle"/>
                        <div id="tool-tip-text" className="tool-tip-text"/>
                        <div className="tool-tip-up-triangle"/>
                    </div>
                </div>
            </HelmetProvider>
        );
    }
}
AppLayout.propTypes={
    settings:PropTypes.object.isRequired,
};
