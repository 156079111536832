import React from 'react';
import store from 'store';
import AppLayout from '../components/AppLayout';
import u from '../utilities/Utilities';
import {Databox,DataboxGeneral,PageMenu,UploadPhoto,DownloadTemplate,
        Segment,Step,DynamicDetailArea,LoadGraphic,SegmentComplete,
        Remarks,FileDropbox} from '../components/Common';
import {EditableBox,EditableSelect,EditableDate,
        EditableContactNumber,ContactNumber,Box,Button,
        Select,MiniPlusButton,MiniMinusButton,TextButton,
        Checkbox,Menu,Info,Date} from '../components/Input';
import Notebook from '../components/Notebook';
import {ResidentDownloadTemplate} from "./ResidentList";
import {Assignments} from './Client';
import {EstateUtilities} from './Estate';
import {PriceForm} from './AgreementList';
import moment from 'moment';
import {Link} from 'react-router-dom';
import "../styles/agreement-list.scss";

export default class Agreement extends React.Component{

    id = this.props.match.params.agreementID;

    state={
        error:"",
        permissions:{},
        content:{
            assignedRooms:[],
            assignedBeds:[],
        },
        pageMenuButtons:[],
    };

    settings={
        /**** Page Setup ****/
        title:"Agreement",
        permission:"agreement",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/agreement",
        label:"agreement",
        hasNotebook:true,
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions(this.settings.permission);

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Update Start/End Date",ref:"changeDate",enabled:(permissions.update)},
              {label:"Download Document",ref:"downloadDocument",enabled:(permissions.view)},
              {label:"View Residents",ref:"viewResidents",enabled:(permissions.view),onClick:this.onViewResidents},
            ]
        });

        if(this.id){
            this.getContent(this.id);
        }
        else {
            this.setState({error:"Agreement Not Found"});
        }
    }

    getContent=(id)=>{
        u.post({
            url:"/api/get-agreement",
            data:{
                agreementID:id
            },
            success:(callback)=>{
                this.setState({content:callback});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        })
    }

    onContentChange=(value,field,index,error,option)=>{
        let content = this.state.content;
        content[field] = value;
        this.setState({content:content});
    }

    onViewResidents=()=>{
        store.set("resident-list","@agreement("+this.state.content.reference+")");
        window.open("/resident-list","_blank");
    }

    render=()=>{
        const content = this.state.content;
        const permissions=this.state.permissions;
        if(typeof content.agreementID == "undefined"){
            return <div/>
        }

        const pageComponents={
            changeDate:{component:ChangeDate,props:{content:content}},
            downloadDocument:{component:DownloadDocument,props:{content:content}},
        };

        return (
            <AppLayout settings={this.settings}>

                <div className="page-error">{this.state.error}</div>

                <PageMenu buttons={this.state.pageMenuButtons}
                          settings={this.settings}
                          components={pageComponents}/>

                <DynamicDetailArea settings={this.settings}
                                   content={content}
                                   onChange={this.onContentChange}
                                   />

                <Databox title="Remarks">
                    <Remarks content={content.remarks}
                             onChange={this.onContentChange}
                             addURL="/api/agreement-add-remark"
                             deleteURL="/api/agreement-remove-remark"
                             permissions={permissions.update}
                             id={content.agreementID}
                             />
                </Databox>

                <Databox title="Dropbox">
                    <FileDropbox content={content.dropbox}
                                 onChange={this.onContentChange}
                                 addURL="/api/agreement-add-dropbox-file"
                                 deleteURL="/api/agreement-remove-dropbox-file"
                                 permissions={permissions.update}
                                 id={content.agreementID}
                                 />
                </Databox>

                <Databox title="Price Form">
                    <PriceTable content={content}
                                onChange={this.onContentChange}
                                />
                </Databox>

                <Assignments content={content}
                             onChange={this.onContentChange}
                             type="room"
                             agreement={true}
                             />

                <Assignments content={content}
                             onChange={this.onContentChange}
                             type="bed"
                             agreement={true}
                             />

                <Notebook module={"agreement"} moduleID={this.id} notebookID={content.notebookID}/>
            </AppLayout>
        )
    }
}

class PriceTable extends React.Component{

    state={
        step:"view",
        editTable:[],
        entryData:{},
        loading:false,
        loadText:"",
    }

    onEditTable=()=>{
        this.getDefaultPriceForm();
    }

    getDefaultPriceForm=(success,error)=>{
        this.setState({loading:true,loadText:"Getting price template..."});
        u.post({
            url:"/api/get-price-template",
            data:{
                  dormID:this.props.content.dormID
            },
            success:({depositFactor,stampDutyFactor,priceTemplate})=>{
                let entryData = this.state.entryData;
                let priceForm = JSON.parse(JSON.stringify(this.props.content.priceForm));
                priceForm = priceForm.map((entry,index)=>{
                    //Map approved items to price template
                    for(var i=0; i<priceTemplate.length; i++){
                        entry.preApprovedContent=[];
                        entry.preApprovedOptions=[];

                        if(priceTemplate[i].priceID == entry.priceID){
                          entry.preApprovedContent = priceTemplate[i].items.filter((item)=>{return item.required == undefined || item.required==0});
                          entry.preApprovedOptions = entry.preApprovedContent.map((option)=>{return option.name});
                          entry.allowUnapprovedItem = priceTemplate[i].allowUnapprovedItem;
                          break;
                        }
                    }

                    return entry;
                })
                entryData.priceForm=priceForm;
                entryData.depositFactor=depositFactor;
                entryData.stampDutyFactor=stampDutyFactor;
                entryData.preApprovedSectionContent=priceForm.slice(0);
                entryData.preApprovedSectionOptions=priceForm.map((item)=>{return item.header});
                entryData.autoDeposit = false;
                entryData.deposit=this.props.content.deposit;
                entryData.autoStampDuty = false;
                entryData.stampDuty=this.props.content.stampDuty
                this.setState({
                    entryData:entryData,
                    step:"edit",
                    loading:false,
                    error:""
                });
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onPriceValidate=()=>{
        this.setState({loading:true,error:"",loadText:"Creating agreement..."});
        const entryData=this.state.entryData;
        const content=this.props.content;
        u.post({
          url:"/api/update-agreement-price-table",
          data:{
              agreementID:content.agreementID,
              oldPriceTable:content.priceForm,
              newPriceForm:entryData.priceForm,
              deposit:entryData.deposit,
              stampDuty:entryData.stampDuty,
          },
          success:()=>{
              this.props.onChange(entryData.priceForm,"priceForm");
              this.props.onChange(entryData.deposit,"deposit");
              this.props.onChange(entryData.stampDuty,"stampDuty");
              this.setState({step:"view",loading:false});
          },
          error:(error)=>{
              this.setState({loading:false,error:error});
          }
        });
    }

    onChangeEntryData=(value,field,index,option)=>{
        let entryData = this.state.entryData;
        entryData[field] = value;
        this.setState({entryData:entryData});
    }

    render=()=>{
        const content=this.props.content;
        const entryData=this.state.entryData;

        return(
            <div className='agreement-price-table'>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <Step active={this.state.step=="view"}>
                    {
                        (content.priceForm.length > 0)
                            ?
                            <div>
                                {
                                  content.priceForm.map(
                                      (item,index,arr)=>{
                                          return (
                                            <div className="price-table-container view" key={index} style={{zIndex:(arr.length - index)}}>
                                                <div>
                                                    <div className="section-header">{item.header}</div>
                                                </div>
                                                <div className="price-table">
                                                    <div>
                                                        <div className="price-item-extended price-header">Item</div>
                                                        <div className="price-quantity no-val price-header">Quantity</div>
                                                        <div className="price-value no-val price-header">Unit Price</div>
                                                        <div className="price-remark price-header">Remark</div>
                                                        <div className="price-table-right price-header">&nbsp;</div>
                                                    </div>
                                                    <div>
                                                        {
                                                            (item.items.length > 0)
                                                              ?
                                                              item.items.map(
                                                                  (line,lineIndex,itemArray)=>{
                                                                      return(
                                                                          <div key={lineIndex} className="price-line">
                                                                              <div className="price-item-extended">
                                                                                  <div className="price-line-view">{line.name}</div>
                                                                              </div>
                                                                              <div className="price-quantity no-val">
                                                                                  <div className="price-line-view">{line.quantity}</div>
                                                                              </div>
                                                                              <div className="price-value no-val">
                                                                                  <div className="price-line-view">{line.price}</div>
                                                                              </div>
                                                                              <div className="price-remark">
                                                                                  <div className="price-line-view">{(line.remark) ? line.remark : <span style={{color:"white"}}>None</span>}</div>
                                                                              </div>
                                                                              <div className="price-table-right">
                                                                                  <Info style={{width:"16px",height:"16px"}} title={"Monthly Recurring: "+((item.monthlyRecurring) ? "Yes" : "No")+"</br>Tax Type: " + line.taxType + "<br/>Deposit: " + ((line.deposit) ? "Yes" : "No") +"</br>Rental Line: "+ ((line.rental) ? "Yes" : "No")}/>
                                                                              </div>
                                                                          </div>
                                                                      )
                                                                  }
                                                              )
                                                              :
                                                              <div style={{padding:"50px 0",textAlign:'center',background:"#f4f4f4"}}>
                                                                  <b>No Items Added</b>
                                                              </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                          )
                                      }
                                  )
                                }

                                <div>
                                    <div className="price-deposit-view">
                                        <b>Deposit</b>
                                        <div className="price-line-view">${u.integerComma(content.deposit)}</div>
                                    </div>

                                    <div className="price-deposit-view">
                                        <b>Stamp Duty</b>
                                        <div className="price-line-view">${u.integerComma(content.stampDuty)}</div>
                                    </div>
                                </div>

                                <div style={{margin:"50px auto 0 auto",textAlign:"center",fontSize:"12px"}}>
                                    <TextButton onClick={this.onEditTable}>Edit Table</TextButton>
                                </div>
                            </div>
                            :
                            <div className="list-placeholder">
                                No Price Entries
                                <TextButton onClick={this.onEditTable}>Edit Price Table</TextButton>
                            </div>
                    }
                </Step>

                <Step active={this.state.step=="edit"}>
                    <PriceForm content={entryData.priceForm}
                               agreementID={this.props.agreementID}
                               estimatedOccupancy={content.estimatedOccupancy}
                               entryData={entryData}
                               onChange={this.onChangeEntryData}
                               preApprovedSectionContent={entryData.preApprovedSectionContent}
                               preApprovedSectionOptions={entryData.preApprovedSectionOptions}
                               onBack={()=>{this.setState({step:"view",error:""})}}
                               onValidated={this.onPriceValidate}
                               />
                </Step>
            </div>
        )
    }
}

class ChangeDate extends React.Component{
    state={
      active:false,
      step:"view",
      startDate:this.props.content.startDate,
      loading:false,
      loadText:"",
      unavailable:[],
    }

    show=(show)=>{
        this.setState({active:show});
    }

    onCancel=()=>{
        this.setState({step:"view",loading:false,error:""});
        this.props.onToggleForm(this.props.reference,false);
    }

    onChangeDate=()=>{
        this.setState({error:""});
        const keys=['startDate','endDate'];
        let data={};
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
            data[keys[i]]=current.value;
        }
        this.setState({loading:true,loadText:"Checking..."});
        u.post({
            url:"/api/agreement-change-dates",
            data:{
                agreementID:this.props.content.agreementID,
                startDate:data.startDate,
                endDate:data.endDate,
            },
            success:({valid,unavailable})=>{
                this.setState({
                  loading:false,
                  step:((valid) ? "success" : "unavailable"),
                  unavailable:(unavailable) ? unavailable : [],
                });
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    render=()=>{
        const content=this.props.content
        return(
            <Segment active={this.state.active}>
                <div className="segment-title">Change Agreement Date</div>
                <div className="segment-error">{this.state.error}</div>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="segment-content" style={{textAlign:"center"}}>
                    <Step active={this.state.step=="view"}>
                        <h3>Select the new dates to continue</h3>
                        <div style={{fontSize:"14px",margin:"20px auto",width:"800px",maxWidth:"98%"}}>If rooms/beds have already been assigned to this algorithm, you must ensure that they are not assigned to an overlapping organization or agreement to complete the date changes</div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">Start Date:</div>
                            <div className="segment-item-input">
                                <Date ref="startDate"
                                      label="Start Date"
                                      values={{value:this.state.startDate}}
                                      range={[2,10]}
                                      rules={{defaultValue:content.startDate}}
                                      onChange={(value)=>{this.setState({startDate:value})}}
                                      />
                            </div>
                        </div>
                        <div className="segment-full-item">
                            <div className="segment-item-label">End Date:</div>
                            <div className="segment-item-input">
                                <Date ref="endDate"
                                      label="End Date"
                                      range={[2,10]}
                                      rules={{defaultValue:content.endDate,minDate:this.state.startDate}}
                                      />
                            </div>
                        </div>
                        <div className="segment-buttons">
                            <Button onClick={this.onCancel}>Cancel</Button>
                            <Button onClick={this.onChangeDate}>Confirm</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step=="unavailable"}>
                        <h3 className="text-negative">Unable to change dates because rooms/beds have been reassigned</h3>
                        <div className="segment-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th className="left">Room/Bed</th>
                                        <th>Agreement</th>
                                        <th className="right">Period</th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {
                                      this.state.unavailable.map(
                                          (item,index)=>{
                                              return(
                                                  <tr key={index} className="item padding">
                                                      <td>{item.estateLabel}</td>
                                                      {
                                                          (item.agreementID)
                                                            ?
                                                            <td>({item.agreementType}) <Link to={"/agreement/"+item.agreementID}>{item.agreementReference}</Link></td>
                                                            :
                                                            <td>(Organization) <Link to={"/client/"+item.clientID}>{item.clientName}</Link></td>
                                                      }
                                                      {
                                                          (item.agreementID)
                                                              ?
                                                              <td>{moment(item.agreementStartDate).format("DD MMM YYYY")} to {moment(item.agreementEndDate).format("DD MMM YYYY")}</td>
                                                              :

                                                              <td>Indefinitely</td>
                                                      }
                                                  </tr>
                                              )
                                          }
                                      )
                                  }
                                </tbody>
                            </table>
                        </div>
                        <div className="segment-buttons">
                            <Button onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </Step>

                    <Step active={this.state.step=="success"}>
                        <SegmentComplete onComplete={this.onCancel}>Successfully updated start/end dates</SegmentComplete>
                    </Step>
                </div>
            </Segment>
        )
    }
}

class DownloadDocument extends React.Component{

    state={
        active:false,
        documentOptions:[],
        step:0,
        loading:false,
        loadText:"",
    };

    static getDerivedStateFromProps(props,state){
        if(props.content.type != state.type){
            state.type = props.content.type;
            state.documentOptions=DownloadDocument.getTypeOptions(props.content.type,props.content.locked);
        }
        return state;
    }

    onDownload=()=>{
        const type = this.refs['type'].validate();
        if(!type.valid){
            this.setState({error:type.value});
            return 0;
        }
        this.setState({loading:true,loadText:"Preparing document..."});
        const content = this.props.content;
        u.autoDownload({
            url:"/api/download-document",
            data:{
                agreementID:content.agreementID,
                documentType:type.value,
            },
            success:()=>{
                this.setState({loading:false,step:1});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }

        });
    };

    onCancel=()=>{
        this.props.onToggleForm(this.props.reference,false);
    };

    show=(show)=>{
        this.setState({active:show});
        if(!show){
            this.setState({loading:false,error:"",step:0});
        }
    }

    static getTypeOptions(type,locked){
        let options = [];

        if(type == "Quotation" || type == "Booking" || type == "Agreement" || type == "Renewal"){
            options.push({value:"Quotation",label:"Quotation"});
        }
        if(type == "Booking" || type == "Agreement" || type == "Renewal"){
            options.push({value:"Booking",label:"Booking"});
        }

        if(type == "Agreement" || type == "Renewal"){
            options.push({value:"Agreement",label:"Agreement"});
        }
        return options;
    };

    render=()=>{
        return(
            <Segment active={this.state.active} align="center" scrollAnchor={this.props.scrollAnchor}>
                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <div className="segment-title">Download Document</div>
                    <div className="segment-error">{this.state.error}</div>
                    <Step active={this.state.step==0}>
                        <div>
                            <div className="segment-content" style={{textAlign:"center"}}>
                                <h3>Select a document</h3>
                                <div className="segment-full-item">
                                    <Select ref="type" label="document" values={{options:this.state.documentOptions}}/>
                                </div>
                                <div className="segment-buttons">
                                    <Button type="medium" onClick={this.onCancel}>Cancel</Button>
                                    <Button type="medium" onClick={this.onDownload}>Download</Button>
                                </div>
                            </div>
                        </div>
                    </Step>
                    <Step active={this.state.step==1}>
                        <div style={{textAlign:"center", padding:"10px 0 0 0"}}>
                            <SegmentComplete onComplete={this.onCancel}>Download has successfully started</SegmentComplete>
                        </div>
                    </Step>
                </div>
            </Segment>
        )
    }
}
