import React from 'react';
import styles from '../styles/input.scss';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import moment from 'moment';
import u from '../utilities/Utilities';
import Slider from 'react-rangeslider';

/**
 * Creates a ready to use themed input element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} defaultValue
 *          default value that only updates on componentMount & clear
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} minValue
 *          minimum value of input type=number
 *
 *      @var {number} maxValue
 *          maximum value of input type=number
 *
 *      @var {bool} noSpaces
 *          accepts only text with no spaces
 *
 *      @var {bool} stripSpaces
 *          removes all spaces in the entry on submission
 *
 *      @var {bool} toUpperCase
 *          all text in the box will be set to upper case
 *
 *      @var {bool} capitalize
 *          text will capitalize on spaces or first character
 *
 *      @var {object} styles
 *          custom styles to be implemented to input box
 *
 *      @var {array} list
 *          adds autocomplete function
 *
 *      @var {bool} ignoreListFilter
 *
 *      @var {number} toFixed
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the box changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class Box extends React.Component{

    state={
        value:(u.notNull(this.props.values.value)) ? this.props.values.value : ((this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : ""),
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        hasError:false,
        showList:false,
        suggest:[],
        selected:-1,
    };

    listkey = u.getKey();

    componentDidMount=()=>{
        if(this.props.rules.list){
            const value = this.state.value + "";
            const suggest=this.props.rules.list.filter((item)=>{
                const listItem = item +"";
                return listItem.toLowerCase().indexOf(value.toLowerCase()) != -1;
            });
            this.setState({suggest:suggest});
        }
    };

    static getDerivedStateFromProps=(props,state)=>{

        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled = props.values.enabled;
        }
        if(u.notNull(props.values.value) && props.values.value != state.value){
            state.value = props.values.value;
        }

        //Derive autocomplete
        if(props.rules.list){
            const value = state.value+"";
            const suggest= (props.rules.ignoreListFilter) ? props.rules.list : props.rules.list.filter((item)=>{
                const listItem = item +"";
                return listItem.toLowerCase().indexOf(value.toLowerCase()) != -1;
            });
            state.suggest=suggest;
        }
        else{
            state.suggest=[];
        }

        return state;
    }

    componentWillUnmount=()=>{
        document.removeEventListener('keydown',this.keydown);
    }

    onChange=(e)=>{
        if(!this.state.enabled)
            return 0;

        const val = e.target.value;

        const newValue = (this.props.rules.toUpperCase) ? val.toUpperCase() : ((this.props.rules.capitalize) ? u.capitalize(val) : val);

        this.setState({value:newValue,selected:-1});

        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(newValue,label,this.props.index);
        }
    }

    clear=(text)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : "";
        const newValue = (text) ? text : defaultValue;
        this.setState({value:newValue,hasError:false});
        if(this.props.onChange) {
            this.props.onChange(newValue, this.props.label, this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    validate=()=>{
        this.setState({error:"",hasError:false});

        let value = (this.props.type == "number") ? this.state.value : this.state.value + "";
        const label= this.props.label;

        if(this.props.rules.required){
            if(value.length<=0){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-required");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }
        else{
            if(value.length == 0){
                return {valid:true,value:"",label:this.props.label,index:this.props.index};
            }
        }

        if(this.props.type=="email"){
            const emailRegex= /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if(!emailRegex.test(value)){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-email");
                this.setState({hasError:true});
                return {valid:false, value:error};
            }
        }

        if(this.props.rules.noSpecialCharacters){
            const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/i;
            const exists = value.search(specialCharacters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-special-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if (this.props.rules.dotOnly){
            const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]/i;
            const exists = value.search(specialCharacters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-special-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.lettersOnly){
            const nonLetters = /[^a-zA-Z ]/g;
            const exists = value.search(nonLetters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-special-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.mobileNumber){
            const nonLetters = /[^0-9.+ ()]/g;
            const exists = value.search(nonLetters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-mobile-number");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.noLetters){
            const letters = /[^0-9.]/g;
            const exists = value.search(letters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.isFloat){
            const letters = /[^\-0-9.]/g;
            let exists = value.search(letters);

            if(isNaN(parseFloat(value))){
                exists = 1;
            }

            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.username){
            const notAllowed = /[^a-zA-Z_.0-9]/g;
            const exists = value.search(notAllowed);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-special-characters");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.minLength){
            if(value.length < this.props.rules.minLength){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-min-length") + " " + this.props.rules.minLength + " characters";
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.maxLength){
            if(value.length > this.props.rules.maxLength){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-max-length") + " " + this.props.rules.maxLength + " characters";
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.maxValue){
            if(parseFloat(value) > parseFloat(this.props.rules.maxValue)){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-max-value") + " " + this.props.rules.maxValue;
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(typeof this.props.rules.minValue != "undefined"){
            if(parseFloat(value) < parseFloat(this.props.rules.minValue)){
                const error =u.tx("error-field") + ": " + label + " " + u.tx("error-min-value") + " " + this.props.rules.minValue;
                this.setState({hasError:true});
                return {valid:false, value: error};
            }
        }

        if(this.props.rules.noSpaces){
            const specialCharacters = / /i;
            const exists = value.search(specialCharacters);
            if(exists != -1){
                const error=u.tx("error-field") + ": " + label + " " + u.tx("error-no-space");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.stripSpaces){
            value = value.replace(/ /g,'');
        }

        let parsedValue = ((this.props.type=="number") ? parseInt(value) : value);

        if(typeof this.props.rules.toFixed != "undefined"){
            parsedValue = (parseFloat(parsedValue)).toFixed(this.props.rules.toFixed);
            this.setState({value:parsedValue});
            if(this.props.onChange){
                const label = (this.props.field) ? this.props.field : this.props.label;
                this.props.onChange(parsedValue,label,this.props.index);
            }
        }

        return {valid: true, value: parsedValue,label:this.props.label,index:this.props.index};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    onClick=()=>{
        //Calculate list here
        if(this.state.suggest.length > 0){
            this.setState({showList:true});
            //Hook events
            document.addEventListener('keydown',this.keydown);
        }
    }

    keydown=(e)=>{
        if(e.keyCode == 13){
            if(this.state.selected > -1){
                this.parseAutoComplete(e);
            }
        }
        else if(e.keyCode == 38){
            if(this.state.selected == -1 || this.state.selected == 0){
                this.setState({selected:(this.state.suggest.length - 1)});
            }
            else{
                this.setState({selected:this.state.selected-1});
            }
        }
        else if(e.keyCode == 40){
            if(this.state.selected == (this.state.suggest.length - 1)){
                this.setState({selected:0});
            }
            else{
                this.setState({selected:this.state.selected+1});
            }
        }
    }

    onBlur=(e)=>{
        this.setState({showList:false,selected:-1});
        document.removeEventListener('keydown',this.keydown);
        if(this.props.onBlur){
            this.props.onBlur();
        }
    }

    parseAutoComplete=(e)=>{
        if(e){
            e.preventDefault();
        }


        const newValue = this.state.suggest[this.state.selected];
        this.setState({value:newValue,showList:false});
        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(newValue,label,this.props.index,"click");
        }
        document.removeEventListener('keydown',this.keydown);
    }

    noFocus=(e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onFocus=(e)=>{
        if(this.state.suggest.length > 0){
            this.setState({showList:true});
            document.addEventListener('keydown',this.keydown);
        }

        if(this.props.onFocus){
            this.props.onFocus();
        }
    }

    onDragStart=(e)=>{
        if(this.state.enabled){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    focus=()=>{
        this.refs['input'].focus();
    }

    closeList=()=>{
        this.setState({showList:false});
    }

    onEnterKey=(e)=>{
        if(e){
            e.preventDefault();
        }
        if(this.props.onEnterKey){
            const field = (this.props.field) ? this.props.field : this.props.label;
            this.props.onEnterKey(field,this.props.index);
        }
    }

    blur=()=>{
        this.refs['input'].blur();
    }

    render=()=>{
        return (
            <div className="box" title={this.props.title} onBlur={this.onBlur} style={this.props.style} draggable={this.props.draggable} onDragStart={this.onDragStart}>
                <form onSubmit={this.onEnterKey}>
                    <input ref="input"
                           onClick={this.onClick}
                           className={((this.state.enabled) ? "" : "input-disabled") + ((this.state.hasError) ? " input-error" : "")}
                           placeholder={this.props.rules.placeholder}
                           type={this.props.type}
                           value={this.state.value}
                           onChange={this.onChange}
                           style={this.props.boxStyle}
                           step={(this.props.rules.step) ? this.props.rules.step : "any"}
                           autoFocus={this.props.autoFocus}
                           onFocus={this.onFocus}
                           onBlur={this.onBlur}
                           />
                </form>
                {
                    (this.state.showList && this.state.suggest.length > 0)
                        ?
                        <div className="box-list-container" style={{maxHeight:((this.props.rules.listHeight) ? this.props.rules.listHeight : undefined)}}>
                            {
                                this.state.suggest.map(
                                    (item,index)=>{
                                        return (
                                            <div onClick={this.parseAutoComplete} onMouseDown={this.noFocus} onMouseOver={()=>{this.setState({selected:index})}} className={"box-option" + ((this.state.selected==index) ? " hover" : "")} key={index}>{((item.value) ? item.value : item)}</div>
                                        )
                                    }
                                )
                            }
                        </div>
                        :
                        ""
                }
            </div>
        )
    }
}
Box.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func,
    autoFocus:PropTypes.bool,
    style:PropTypes.object,
    boxStyle:PropTypes.object,
};
Box.defaultProps={
    type:"text",
    values:{},
    rules:{},
    style:{}
};

export class ContactNumber extends React.Component{

    state={
        countryCode:"",
        contactNumber:"",
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        hasError:false,
    }

    static getDerivedStateFromProps=(props,state)=>{

        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if(u.notNull(props.values.contactNumber) && props.values.contactNumber != state.contactNumber){
            state.contactNumber = props.values.contactNumber;
        }

        if(u.notNull(props.values.countryCode) && props.values.countryCode != state.countryCode){
            state.countryCode = props.values.countryCode;
        }

        return state;
    }

    onChange=(value,label,index)=>{
        if(label == this.props.countryCodeField){
            this.setState({countryCode:value});
        }
        else if(label == this.props.contactNumberField){
            this.setState({contactNumber:value});
        }
        if(this.props.onChange){
            const propLabel = (label == this.props.countryCodeField) ? this.props.countryCodeField : this.props.contactNumberField;
            this.props.onChange(value,propLabel,this.props.index);
        }
    }

    validate=()=>{
        this.setState({hasError:false});

        //Validate 2 fields
        const countryCode = this.refs['countryCode'].validate();
        if(!countryCode.valid){
            return countryCode;
        }
        const contactNumber = this.refs['contactNumber'].validate();
        if(!contactNumber.valid){
            return contactNumber;
        }

        return {
          valid:true,
          value:countryCode.value + contactNumber.value,
          contactNumber:contactNumber.value,
          countryCode:countryCode.value,
          countryCodeField:this.props.countryCodeField,
          contactNumberField:this.props.contactNumberField,
          index:this.props.index
        };
    }

    clear=(value)=>{
        this.setState({countryCode:"",contactNumber:"",hasError:false});

        if(this.props.onChange) {
            this.props.onChange("", this.props.countryCodeField , this.props.index);
            this.props.onChange("", this.props.contactNumberField, this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    render=()=>{
        return (
            <div className="contact-number-container">
                <div className="country-code">
                    <Box ref="countryCode" label="Country Code" field={this.props.countryCodeField} values={{value:this.state.countryCode,enabled:this.state.enabled}} rules={{minLength:2,maxLength:3,placeholder:"65",noLetters:true}} onChange={this.onChange} onEnterKey={()=>{this.refs['contactNumber'].focus()}}/>
                </div>
                <div className="contact-number">
                    <Box ref="contactNumber" label="Phone Number" field={this.props.contactNumberField} values={{value:this.state.contactNumber,enabled:this.state.enabled}} rules={{...this.props.rules,mobileNumber:true}} onChange={this.onChange} onEnterKey={this.props.onEnterKey}/>
                </div>
            </div>
        )
    }
}
ContactNumber.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    countryCodeField:PropTypes.string.isRequired,
    contactNumberField:PropTypes.string.isRequired,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func,
    style:PropTypes.object,
    boxStyle:PropTypes.object,
};
ContactNumber.defaultProps={
    values:{},
    rules:{},
    style:{}
};

/**
 * Creates a ready to use themed editable input element with pre-loaded validation rules. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} minValue
 *          minimum value of input type=number
 *
 *      @var {number} maxValue
 *          maximum value of input type=number
 *
 *      @var {bool} stripSpaces
 *          removes all spaces in the entry on submission
 *
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 *      @var {function} validate
 *          additional validation function run before submission
 *
 *      @var {string} tagOn
 *          Adds text to the back of the label
 *
 * @param {function} onChange(value,field,index,onError)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableContactNumber extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.contactNumber != "undefined") ? (((this.props.values.countryCode) ? "("+this.props.values.countryCode+") " : "") + this.props.values.contactNumber) : "",
        countryCode:(typeof this.props.values.countryCode != "undefined") ? this.props.values.countryCode : "",
        contactNumber:(typeof this.props.values.contactNumber != "undefined") ? this.props.values.contactNumber : "",
        prevCountryCode:(typeof this.props.values.countryCode != "undefined") ? this.props.values.countryCode : "",
        prevContactNumber:(typeof this.props.values.contactNumber != "undefined") ? this.props.values.contactNumber : "",
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(props,state)=>{
        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled = props.values.enabled;
        }
        if(((typeof props.values.countryCode != "undefined") && props.values.countryCode != state.prevCountryCode) ||
          ((typeof props.values.contactNumber != "undefined") && props.values.contactNumber != state.prevContactNumber)){
            state.value = ((props.values.countryCode) ? "("+props.values.countryCode+") " : "") + props.values.contactNumber;
            state.prevContactNumber=props.values.contactNumber;
            state.prevCountryCode=props.values.countryCode;
            state.countryCode = props.values.countryCode;
            state.contactNumber = props.values.contactNumber;
        }
        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }

        let updateCountryCode=false;
        //Check countryCode
        if(this.addNull(result.countryCode) != this.addNull(this.props.values.countryCode)){
            updateCountryCode=true;
        }

        let updateContactNumber=false;
        if(this.addNull(result.contactNumber) != this.addNull(this.props.values.contactNumber)){
            updateContactNumber=true;
        }

        let promises = [];

        if(updateContactNumber){
            const contactNumberPromise = new Promise((resolve,reject)=>{
                u.post({
                  url:this.props.url,
                  data:{
                    oldValue:this.props.values.contactNumber,
                    newValue:result.contactNumber,
                    field:this.props.contactNumberField,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                  },
                  success:resolve,
                  error:reject
                });
            });
            promises.push(contactNumberPromise);
        }
        if(updateCountryCode){
            const countryCodePromise = new Promise((resolve,reject)=>{
                u.post({
                  url:this.props.url,
                  data:{
                    oldValue:this.props.values.countryCode,
                    newValue:result.countryCode,
                    field:this.props.countryCodeField,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                  },
                  success:resolve,
                  error:reject
                });
            });
            promises.push(countryCodePromise);
        }

        if(promises.length > 0){
            Promise.all(promises).then(()=>{
              if(this.props.onChange){
                  if(updateContactNumber){
                      this.props.onChange(result.contactNumber,this.props.contactNumberField,this.props.index,(error)=>{this.setState({error:error})});
                  }

                  if(updateCountryCode){
                      this.props.onChange(result.countryCode,this.props.countryCodeField,this.props.index,(error)=>{this.setState({error:error})});
                  }
              }
              this.setState({active:false,changed:true});
            }).catch((error)=>{
                this.setState({error:error});
            });
        }
        else{
            this.setState({active:false,changed:false});
        }

    }

    addNull=(item)=>{
        if(typeof item == "undefined"){
            return null;
        }
        if(typeof item == "string" && item.length == 0){
            return null;
        }
        return item;
    }

    updateValue=(value,label,index)=>{
        if(label == this.props.countryCodeField){
            this.setState({countryCode:value});
        }
        else if(label == this.props.contactNumberField){
            this.setState({contactNumber:value});
        }
    }

    setActive=(e)=>{
        this.setState({active: true});
    }

    render=()=>{
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>
                            {(this.state.value) ? this.state.value : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}
                        </div>
                        :
                        <div className="editable-input-content">
                            <ContactNumber ref="input"
                                           label={this.props.label}
                                           index={this.props.index}
                                           values={{contactNumber:this.state.contactNumber,countryCode:this.state.countryCode}}
                                           contactNumberField={this.props.contactNumberField}
                                           countryCodeField={this.props.countryCodeField}
                                           rules={this.props.rules}
                                           title={this.props.title}
                                           onChange={this.updateValue}
                                           onEnterKey={this.onSubmit}
                                           />
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>
                }
            </div>
        )
    }
}
EditableContactNumber.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    countryCodeField:PropTypes.string.isRequired,
    contactNumberField:PropTypes.string.isRequired,
    values:PropTypes.object.isRequired,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
};
EditableContactNumber.defaultProps={
    index:"0",
    values:{},
    rules:{
        nullText:"None"
    }
};

/**
 * Creates a ready to use themed empty box element
 */
export class EmptyBox extends React.Component{

    render=()=>{
        return (
            <div className={"empty-box" + ((this.props.enabled) ? " enabled" : "") + ((this.props.className) ? " " + this.props.className : "") + ((this.props.hasError) ? " input-error" : "")}
                 title={this.props.title}
                 style={this.props.style}
                 onClick={this.props.onClick}>
                {
                    this.props.children
                }
            </div>
        )
    }
}
EmptyBox.propTypes={
    title:PropTypes.string,
    style:PropTypes.object,
    onClick:PropTypes.func,
};

/**
 * Creates a ready to use themed editable input element with pre-loaded validation rules. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} minValue
 *          minimum value of input type=number
 *
 *      @var {number} maxValue
 *          maximum value of input type=number
 *
 *      @var {bool} stripSpaces
 *          removes all spaces in the entry on submission
 *
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 *      @var {function} validate
 *          additional validation function run before submission
 *
 *      @var {string} tagOn
 *          Adds text to the back of the label
 *
 * @param {function} onChange(value,field,index,onError)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableBox extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(props,state)=>{
        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled = props.values.enabled;
        }
        if((typeof props.values.value != "undefined") && props.values.value != state.value){
            state.value = props.values.value;
            state.newValue = props.values.value;
        }
        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        if(result.value != this.props.values.value){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(result.value);
                    if(!addVal.valid){
                        this.setState({error:addVal.value});
                        return 0;
                    }
                }
            }

            u.post({
                url:this.props.url,
                data:{
                    oldValue:this.props.values.value,
                    newValue:result.value,
                    field:this.props.field,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                },
                success:(e)=>{
                    if(this.props.onChange){
                        this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{this.setState({error:error})});
                    }
                    this.setState({active:false,changed:true});
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        }
        else{
            this.setState({active:false,changed:false});
        }
    }

    updateValue=(value,label,index)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        if(!this.state.enabled){
            return 0;
        }
        this.setState({active:true});
    }

    render=()=>{
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive} style={this.props.labelStyle}>
                            {(this.props.values.value) ? this.props.values.value : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}{(this.props.rules.tagOn ? " " + this.props.rules.tagOn : "")}
                        </div>
                        :
                        <div className="editable-input-content">
                            <Box ref="input" label={this.props.label} index={this.props.index} type={this.props.type} values={{value:this.state.newValue}}
                                 rules={this.props.rules} title={this.props.title} onChange={this.updateValue} onEnterKey={this.onSubmit}/>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>
                }
            </div>
        )
    }
}
EditableBox.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object.isRequired,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired
};
EditableBox.defaultProps={
    index:"0",
    type:"text",
    values:{},
    rules:{
        nullText:"None"
    }
};

/**
 * Creates a ready to use themed select element
 *
 * @param {string} index=0
 *      reference to this input element, will be returned onChange
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 *      @var {array} options=[]
 *          array of objects to be displayed in list in format {label,value}
 *
 * @param {object} rules
 *      Values are only run on component mount and clear & during validation
 *
 *      @var {string} defaultValue
 *          value of the select list at the beginning & on clear
 *
 *      @var {bool} required
 *          Will not accept value == undefined || none
 *
 *
 * @param {function} onChange(value,label,index,option)
 *      callback function whenever the checkbox changes value
 *
 * @param {function} onOptions(value,label,index,option)
 *      triggers on new options added,
 *      returns value of first option
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string, if string == null, clears to first option. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index,option} of this element
 */
export class Select extends React.Component{

    state={
        options:[],
        value:0,
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        selected_index:0,
        hasError:false,
    }

    componentDidMount=()=>{
        const optionOnlyValue = (this.props.values.options) ? ((this.props.values.options.length > 0) ? this.props.values.options[0].value : ""): "";
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : optionOnlyValue) : optionOnlyValue;
        let options = (typeof this.props.values.options != "undefined") ? this.props.values.options : [];

        const value = (u.notNull(this.props.values.value)) ? this.props.values.value : defaultValue;

        let selected_index=0;
        for(var i=0; i<options.length; i++){
            if(options[i].value == value){
                selected_index = i;
            }
        }
        this.setState({
            options:options,
            selected_index:selected_index,
            value:value,
        });
    }

    static getDerivedStateFromProps=(newProps,state)=>{

        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if((typeof newProps.values.options != "undefined") && newProps.values.options != state.options) {
            state.options = newProps.values.options;

            //Check if current value is undefined || does not exist
            if(state.options.length > 0){
                const value = (typeof newProps.values.value == "undefined") ? ((typeof state.value == "undefined") ? undefined : state.value) : newProps.values.value;

                let invalidValue = (typeof value == "undefined") || (u.getIndexFromValue(value,state.options) == -1);;

                if(invalidValue){
                    state.value = state.options[0].value;
                    state.selected_index = 0;
                    state.promptParentDefault = true;
                }
            }
        }

        if(u.notNull(newProps.values.value) && newProps.values.value != state.value && !state.promptParentDefault){
            const selected_index = u.getIndexFromValue(newProps.values.value,state.options);
            state.value = newProps.values.value;
            state.selected_index = selected_index;
        }

        return state;
    }

    componentDidUpdate=(prevProps,prevState)=>{
        if(this.state.promptParentDefault){
            this.setState({promptParentDefault:false});
            if(this.props.onChange){
                const label = (this.props.field) ? this.props.field : this.props.label;
                this.props.onChange(this.state.value,label,this.props.index,this.state.options[0],true);
            }
        }
    }

    onChange=(e)=>{
        if(!this.state.enabled)
            return 0;

        const selected_index = parseInt(e.currentTarget.selectedIndex);
        const value = e.currentTarget.value;

        this.setState({value:value,selected_index:selected_index,hasError:""});

        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(value,label,this.props.index,this.state.options[selected_index]);
        }
    }

    validate=()=>{
        this.setState({hasError:false});

        if(this.props.rules.required) {
            if (this.state.value == "None" || this.state.value == "none" || typeof this.state.value == "undefined" || (this.state.value+"") == "") {
                this.setState({hasError:true});
                return {valid:false,value:"Field: " + this.props.label + " is required"};
            }

            if(this.state.options.length == 0){
                this.setState({hasError:true});
                return {valid:false,value:"No options supplied to " + this.props.label};
            }
        }

        return {valid:true,value:this.state.value,label:this.props.label,index:this.props.index,option:this.state.options[this.state.selected_index]};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    clear=(value)=>{
        const optionOnlyValue = (this.state.options) ? ((this.state.options.length > 0) ? this.state.options[0].value : "") : "";
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : optionOnlyValue) : optionOnlyValue;
        const newValue = (value) ? value : defaultValue;
        const selected_index = u.getIndexFromValue(newValue,this.state.options);

        this.setState({value:newValue,selected_index:selected_index,hasError:false});
        if(this.props.onChange) {
            this.props.onChange(newValue, this.props.label, this.props.index, this.state.options[selected_index]);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    render=()=>{
        return(
            <div className="select" title={this.props.title} style={this.props.style}>
                <select className={((this.state.enabled) ? "" : "input-disabled") + ((this.state.hasError) ? " input-error" : "")} onChange={this.onChange} value={this.state.value}>
                    {this.state.options.map(
                        (option,index) => {
                            return <option key={index} value={option.value}>{option.label}</option>
                        }
                    )}
                </select>
            </div>
        )
    }
}
Select.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    values:PropTypes.object.isRequired,
    title:PropTypes.string,
    onChange:PropTypes.func,
};
Select.defaultProps={
    rules:{}
};

/**
 * Creates a ready to use themed select element. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input element, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 *      @var {array} options=[]
 *          array of objects to be displayed in list in format {label,value}
 *
 * @param {object} rules
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 *      @var {string} defaultValue
 *          one time defaultValue
 *
 *      @var {bool} mixValueLabel
 *          use label for options label but value for result label
 *
 *      @var {array} labelOptions
 *
 * @param {function} onChange(value,field,index,onError,option)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string, if string == null, clears to first option. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableSelect extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:"",
        label:(typeof this.props.values.value != "undefined" && this.props.values.options) ? u.getLabelFromValue(this.props.values.value,(this.props.rules.labelOptions) ? this.props.rules.labelOptions : this.props.values.options) : ""
    };

    static getDerivedStateFromProps=(newProps,state)=>{

        if(((typeof newProps.values.value != "undefined") && newProps.values.value != state.value) || ((typeof newProps.values.options != "undefined") && newProps.values.options != state.options)){
            const labelOptions = (newProps.rules.labelOptions) ? newProps.rules.labelOptions : newProps.values.options;
            const label = (newProps.rules.mixValueLabel) ? newProps.values.value : u.getLabelFromValue(newProps.values.value,labelOptions);
            state.value=newProps.values.value;
            state.newValue=newProps.values.value;
            state.options=newProps.values.options;
            state.label=label+"";
        }

        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled
        }

        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }

        if(this.state.newValue != this.props.values.value && this.state.newValue != "No Change"){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(this.state.newValue);
                    if(!addVal.valid){
                        return addVal;
                    }
                }
            }

            u.post({
                url:this.props.url,
                data:{
                    oldValue:this.state.value,
                    newValue:this.state.newValue,
                    field:this.props.field,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                },
                success:(e)=>{
                    if(this.props.onChange){
                        this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{this.setState({error:error});},result.option);
                    }

                    const labelOptions = (this.props.rules.labelOptions) ? this.props.rules.labelOptions : this.props.values.options;
                    const label = ((this.props.rules.mixValueLabel) ? result.value : u.getLabelFromValue(result.value,labelOptions));

                    this.setState({
                        active:false,
                        value:result.value,
                        changed:(!(this.props.rules.noColor)),
                        label:label
                    });
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        }
        else{
            this.setState({active:false,changed:false});
        }

    }

    updateValue=(value,label,index,option)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        if(!this.state.enabled){
            return 0;
        }
        this.setState({active:true});
    }

    render=()=>{
        return (
            <div className="editable-input" title={this.props.title}>
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>
                            {(this.state.label.length > 0) ? this.state.label : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}
                        </div>
                        :
                        <div className="editable-input-content">
                            <div className="editable-input-content-container">
                                <Select ref="input" label={this.props.label} index={this.props.index} values={{value:this.state.newValue,options:this.props.values.options}}
                                        title={this.props.title} onChange={this.updateValue}
                                        rules={this.props.rules}
                                />
                            </div>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>
                }
            </div>
        )
    }
}
EditableSelect.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object.isRequired,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired
};
EditableSelect.defaultProps={
    index:"0",
    type:"text",
    values:{},
    rules:{
        nullText:"None"
    }
};

/**
 * Creates a ready to use themed date element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string::sqlDate} value=moment().format("YYYY-MM-DD")
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {number} skipDateTo
 *          Always set date to designated number and hide date value
 *
 *      @var {string::sqlDate} minDate
 *          minimum date value to be inputted
 *
 *      @var {string::sqlDate} maxDate
 *          maximum date value to be inputted
 *
 *      @var {string} minDateError
 *          error message on minDate error
 *
 *      @var {string} maxDateError
 *          error message on maxDate error
 *
 *      @var {bool} addEmpty
 *          allows user to select null
 *
 *      @var {string} modifyDate
 *          end
 *
 *
 * @param {array} range
 *      [beforeYears,afterYears] to render into options
 *
 * @param {function} onChange(value,label,index,option)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string::sqlDate)
 *      clears the input and sets value to new string, if string == null, set to todays date. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class Date extends React.Component{

    state={
        dateArray:[],
        monthArray:[],
        yearArray:[],
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
    };

    componentDidMount=()=>{
        let monthArray=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
        let yearArray=[];
        let year = moment().subtract(this.props.range[0],"years").year();
        for(var i=0; i<this.props.range[0]+this.props.range[1]; i++){
            yearArray.push((year + i) + "");
        }

        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : moment().format("YYYY-MM-DD")) : moment().format("YYYY-MM-DD");
        let value = (u.notNull(this.props.values.value) && this.props.values.value != "0000-00-00") ? this.props.values.value : ((this.props.rules.skipDateTo)? moment().date(parseInt(this.props.rules.skipDateTo)).format("YYYY-MM-DD") : defaultValue);

        let dateArray=[];
        const daysInMonth = moment(value).daysInMonth();
        for(var d=1; d<=daysInMonth; d++){
            dateArray.push(
                ((d<10) ? "0" + d : "" + d)
            );
        }

        if(this.props.rules){
            if(this.props.rules.addEmpty){
                monthArray.unshift("NA");
                yearArray.unshift("NA");
                dateArray.unshift("NA");
            }
        }

        this.setState({
            value:(this.props.rules.addEmpty) ? undefined : value,
            dateArray:(this.props.rules.skipDateTo) ? [this.props.rules.skipDateTo] : dateArray,
            monthArray:monthArray,
            yearArray:yearArray,
            enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
            date:(this.props.rules.addEmpty) ? "NA" : moment(value).format("DD"),
            month:(this.props.rules.addEmpty) ? "NA" : moment(value).format("M"),
            year:(this.props.rules.addEmpty) ? "NA" : moment(value).format("YYYY"),
            hasError:false,
            defaultValue:this.props.rules.defaultValue,
        })
    }

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if(u.notNull(newProps.values.value) && newProps.values.value != state.value){
            const daysInMonth = moment(newProps.values.value).daysInMonth();
            state.dateArray = u.getDateArray(daysInMonth,newProps.rules.addEmpty);
            state.value=newProps.values.value;
            state.date=(newProps.rules.skipDateTo) ? newProps.rules.skipDateTo : moment(newProps.values.value).format("DD");
            state.month= moment(newProps.values.value).format("M");
            state.year=moment(newProps.values.value).format("YYYY");
        }

        if(u.notNull(newProps.values.value) && typeof newProps.rules.defaultValue != "undefined" && newProps.rules.defaultValue != state.defaultValue){
            const daysInMonth = moment(newProps.rules.defaultValue).daysInMonth();
            state.dateArray = u.getDateArray(daysInMonth,newProps.rules.addEmpty);
            state.value=newProps.rules.defaultValue;
            state.date=(newProps.rules.skipDateTo) ? newProps.rules.skipDateTo : moment(newProps.rules.defaultValue).format("DD");
            state.month=moment(newProps.rules.defaultValue).format("M");
            state.year=moment(newProps.rules.defaultValue).format("YYYY");
            state.defaultValue=newProps.rules.defaultValue;
        }

        return state;
    }

    onChange=(e)=>{
        if(!this.state.enabled)
            return 0;

        const id = e.currentTarget.id;
        const value = e.currentTarget.value;
        const label = (this.props.field) ? this.props.field : this.props.label;

        if(value == "NA"){
            this.setState({value:undefined,date:"NA",month:"NA",year:"NA"});
            if(this.props.onChange){
                this.props.onChange(undefined,label,this.props.index);
            }
            return 0;
        }

        const hasTime = Time.isDateTime(this.props.values.value);
        const checkFormat = (hasTime) ? "YYYY-MM-DDTHH:mm:ssZ" : "YYYY-MM-DD";

        if(id=="date"){
            if(this.state.date != value){
                const newValue= moment(this.state.value).set("date",value).format(checkFormat);
                this.setState({date:value, value:newValue});

                if(this.props.onChange){
                    this.props.onChange(newValue,label,this.props.index);
                }
            }
        }
        else if(id=="month"){
            if(this.state.month != value){
                const newValue= moment(this.state.value).set("month",parseInt(value)-1);
                //Check date & date array
                const daysInMonth = newValue.daysInMonth();
                if(parseInt(this.state.date) > daysInMonth){
                    this.setState({date:daysInMonth});
                }

                if(this.state.dateArray.length != daysInMonth + ((this.props.rules.addEmpty) ? 1 : 0)){
                    this.setState({dateArray:u.getDateArray(daysInMonth,this.props.rules.addEmpty)});
                }

                this.setState({month:value, value:newValue.format(checkFormat)});
                if(this.props.onChange){
                    this.props.onChange(newValue.format(checkFormat),label,this.props.index);
                }
            }
        }
        else if(id=="year"){
            if(this.state.year != value){
                const newValue= moment(this.state.value).set("year",value);
                //Check date & date array
                const daysInMonth = newValue.daysInMonth();
                if(parseInt(this.state.date) > daysInMonth){
                    this.setState({date:daysInMonth});
                }

                if(this.state.dateArray.length != daysInMonth){
                    let dateArray=[];
                    for(var k=1; k<=daysInMonth; k++){
                        dateArray.push(((k<10) ? "0" + k : "" + k));
                    }
                    this.setState({dateArray:dateArray});
                }

                this.setState({year:value, value:newValue.format(checkFormat)});
                if(this.props.onChange){
                    this.props.onChange(newValue.format(checkFormat),label,this.props.index);
                }
            }
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    clear=(value)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : moment().format("YYYY-MM-DD")) : moment().format("YYYY-MM-DD");
        const newValue = (value) ? value : ((this.props.rules.skipDateTo) ? moment().date(this.props.rules.skipDateTo).format("YYYY-MM-DD") : defaultValue);
        this.setState({value:newValue,date:moment(newValue).format("DD"),month:moment(newValue).format("M"),year:moment(newValue).format("YYYY"),hasError:false});
        if(this.props.onChange){
            this.props.onChange(newValue,this.props.label,this.props.index);
        }
    }

    validate=()=>{
        let value = this.state.value;
        this.setState({hasError:false});

        if(this.props.rules.skipDateTo){
            value = moment(value).date(parseInt(this.props.rules.skipDateTo)).format("YYYY-MM-DD");
        }

        if(this.props.rules.skipMonthTo){
            value = moment(value).date(parseInt(this.props.rules.skipMonthTo) - 1).format("YYYY-MM-DD");
        }

        if(this.props.rules.addEmpty){
            if(typeof this.state.value == "undefined"){
                return {valid:true};
            }
        }

        const validDateTime = (moment(value,"YYYY-MM-DD").isValid() || moment(value,"YYYY-MM-DDTHH:mm:ss").isValid());

        if(!validDateTime){
            return {valid:false,value:"Field: " + this.props.label + " has an invalid date"};
        }

        if(this.props.rules.minDate){
            let minDate = this.props.rules.minDate;
            if(this.props.rules.minDate.substr(0,5) == "today"){
                //Derive minDate
                const operator = (minDate.charAt(5) == "+") ? "add" : ((minDate.charAt(5) == "-") ? "subtract" : "undefined");
                const operationValue = parseInt(minDate.substr(6));
                if(operator == "undefined"){
                    minDate = moment().format("YYYY-MM-DD");
                }
                else if(isNaN(operationValue)){
                    this.setState({hasError: true});
                    return {valid:"false",value:"Field: " +this.props.label+" - 'today' should include + - days"};
                }
                else{
                    minDate = (operator == "add") ? moment().add(operationValue,"d").format("YYYY-MM-DD") : moment().subtract(operationValue,"d").format("YYYY-MM-DD");
                }
            }

            if(moment(value).isBefore(minDate)){
                this.setState({hasError:true});
                return {valid:false,value:"Field: " + ((this.props.rules.minDateErrorMessage) ? this.props.rules.minDateErrorMessage : (this.props.label + " cannot be before " + ((typeof this.props.rules.skipDateTo != "undefined") ? moment(minDate).format("MMM YYYY") : moment(minDate).format("DD MMM YYYY"))))};
            }
        }

        if(this.props.rules.maxDate) {
            let maxDate = this.props.rules.maxDate;
            if(this.props.rules.maxDate.substr(0,5) == "today"){
                const operator = (maxDate.charAt(5) == "+") ? "add" : ((maxDate.charAt(5) == "-") ? "subtract" : "undefined");
                const operationValue = parseInt(maxDate.substr(6));
                if(operator == "undefined"){
                    maxDate = moment().format("YYYY-MM-DD");
                }
                else if(isNaN(operationValue)){
                    this.setState({hasError: true});
                    return {valid: "false", value: "Field: " + this.props.label + " - 'today' should include + - days"};
                }
                else{
                    maxDate = (operator == "add") ? moment().add(operationValue, "d").format("YYYY-MM-DD") : moment().subtract(operationValue, "d").format("YYYY-MM-DD");
                }
            }
            if (moment(value).isAfter(maxDate)) {
                this.setState({hasError: true});
                return {
                    valid: false,
                    value: "Field: " + ((this.props.rules.maxDateErrorMessage) ? this.props.rules.maxDateErrorMessage : this.props.label + " cannot be after " + ((typeof this.props.rules.skipDateTo != "undefined") ? moment(maxDate).format("MMM YYYY") : moment(maxDate).format("DD MMM YYYY")))
                };
            }
        }

        if(this.props.rules.modifyDate){
            if(this.props.rules.modifyDate == "end"){
                value = moment(value).endOf("month").format("YYYY-MM-DD");
            }
        }

        return {valid:true,value:value,label:this.props.label,index:this.props.index};
    }

    render=()=>{
        return(
            <div className="date" title={this.props.title}>
                <select className={"date-select" + ((this.props.rules.skipDateTo) ? " skipDate" : "")+ ((this.props.rules.skipMonthTo) ? " skipMonth" : "") + ((this.state.enabled && !this.props.rules.lockDate) ? "" : " input-disabled") + ((this.state.hasError) ? " input-error" : "")} id="date" value={this.state.date} onChange={this.onChange}>
                    {
                        (this.state.dateArray.map(
                            (date,index)=>{
                                return <option key={index} value={date}>{date}</option>
                            }
                        ))
                    }
                </select>
                <select className={"month-select" + ((this.props.rules.skipDateTo) ? " skipDate" : "") + ((this.props.rules.skipMonthTo) ? " skipMonth" : "") + ((this.state.enabled && !this.props.rules.lockMonth) ? "" : " input-disabled") + ((this.state.hasError) ? " input-error" : "")} id="month" value={this.state.month} onChange={this.onChange}>
                    {
                        (this.state.monthArray.map(
                            (month,index)=>{
                                const value = (this.props.rules.addEmpty) ? ((month == "NA") ? "NA" : index) : (index+1);
                                return <option key={index} value={(value == "NA") ? "NA" : value}>{month}</option>
                            }
                        ))
                    }
                </select>
                <select className={"year-select" + ((this.props.rules.skipDateTo) ? " skipDate" : "")+ ((this.props.rules.skipMonthTo) ? " skipMonth" : "") + ((this.state.enabled && !this.props.rules.lockYear) ? "" : " input-disabled") + ((this.state.hasError) ? " input-error" : "")} id="year" value={this.state.year} onChange={this.onChange}>
                    {
                        (this.state.yearArray.map(
                            (year,index)=>{
                                return <option key={index} value={year}>{year}</option>
                            }
                        ))
                    }
                </select>
            </div>
        )
    }
}
Date.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    title:PropTypes.string,
    values:PropTypes.object,
    onChange:PropTypes.func,
    rules:PropTypes.object,
    range:PropTypes.array
};
Date.defaultProps={
    index:"0",
    values:{},
    rules:{},
    range:[3,3]
};

/**
 * Creates a ready to use themed date element with pre-loaded validation rules. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string::sqlDate} value=moment().format("YYYY-MM-DD")
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string::sqlDate} minDate
 *          minimum date value to be inputted
 *
 *      @var {string::sqlDate} maxDate
 *          maximum date value to be inputted
 *
 *      @var {string} minDateError
 *          error message on minDate error
 *
 *      @var {string} maxDateError
 *          error message on maxDate error
 *
 *      @var {bool} addEmpty
 *          Allows user to set field to null
 *
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 *      @var {object} confirm
 *          Creates a popup for user confirmation
 *           @var {string} title
 *
 *           @var {string} description
 *
 *           @var {function} triggerPopup
 *
 *
 * @param {array} range
 *      [beforeYears,afterYears] to render into options
 *
 * @param {function} onChange(value,field,index,onError)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string::sqlDate)
 *      clears the input and sets value to new string, if string == null, set to todays date. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableDate extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : undefined,
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value : undefined,
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.value != "undefined") && newProps.values.value != state.value){
            state.value=newProps.values.value;
            state.newValue=newProps.values.value;
        }
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }
        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        if(result.value != this.props.values.value){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(result.value);
                    if(!addVal.valid){
                        return addVal;
                    }
                }

                if(this.props.rules.confirm){
                    let popupContent=this.props.rules.confirm;
                    popupContent.confirm=this.onCommit;
                    popupContent.addCancel=this.onCancel;
                    this.props.rules.confirm.triggerPopup(popupContent);
                }
                else{
                    this.onCommit();
                }
            }

            else{
                this.onCommit();
            }
        }
        else{
            this.setState({active:false,changed:false});
        }

    }

    onCancel=()=>{
        this.setState({active:false});
    }

    onCommit=()=>{
        const result = this.refs['input'].validate();
        u.post({
            url:this.props.url,
            data:{
                oldValue:this.props.values.value,
                newValue:result.value,
                field:this.props.field,
                id:this.props.id,
                additionalData:(this.props.rules) ? this.props.rules.additionalData : null
            },
            success:(e)=>{
                if(this.props.onChange){
                    this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{
                        this.setState({error:error});
                    });
                }
                this.setState({active:false,changed:true});
            },
            error:(error,status)=>{
                this.setState({error:error});
            }
        });
    }

    updateValue=(value,label,index)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        if(!this.state.enabled){
            return 0;
        }
        this.setState({active:true});
    }

    render=()=>{
        const expired = (this.props.rules.flagExpired) ? ((this.props.values.value) ? (moment().isAfter(this.props.values.value)) : false) : false;
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") +((expired) ? " text-negative" :"") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>{(this.props.values.value) ? moment(this.props.values.value).format((this.props.rules.skipDateTo) ? "MMM YYYY" :"DD MMM YYYY") : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}</div>
                        :
                        <div className="editable-input-content">
                            <div className="editable-input-content-container">
                                <Date ref="input" label={this.props.label} index={this.props.index} values={{value:this.state.newValue}}
                                      title={this.props.title} onChange={this.updateValue} rules={this.props.rules} range={this.props.range}/>
                            </div>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>

                }
            </div>
        )
    }
}
EditableDate.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object.isRequired,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired,
    range:PropTypes.array
};
EditableDate.defaultProps={
    index:"0",
    values:{},
    rules:{},
    range:[3,3]
};

/**
 * Creates a ready to use themed time element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string::sqlTime} value=moment().format("HH:mm:ss")
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *
 *      @var {bool} skipSeconds
 *           Always show seconds as "00"
 *
 *      @var {number} skipMinute
 *          Always skip to skipMinute intervals, I.E. 5, 10, 15
 *
 *      @var {bool} minTime
 *
 *      @var {bool} maxTime
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string::sqlTime)
 *      clears the input and sets value to new string, if string == null, set to now. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class Time extends React.Component{

    state={
        tArray:[],
        hArray:[],
    };

    componentDidMount=()=>{
        let hArray=[];
        let tArray=[];
        for(var i=0; i<60; i++){
            if(i<24){
                hArray.push((i<10) ? "0"+i : i + "");
            }

            if(this.props.rules.skipMinute){
                if(i%parseInt(this.props.rules.skipMinute)==0){
                    tArray.push((i<10) ? "0"+i : i + "");
                }
            }
            else{
                tArray.push((i<10) ? "0"+i : i + "");
            }
        }

        const defaultValue = (this.props.rules) ? ((this.props.rules.defaultValue) ? moment().format("YYYY-MM-DD") +"T"+ this.props.rules.defaultValue : moment().format("YYYY-MM-DDTHH:mm:ss")) : moment().format("YYYY-MM-DDTHH:mm:ss");
        let value = (this.props.values.value) ? ((Time.isDateTime(this.props.values.value)) ? this.props.values.value : moment().format("YYYY-MM-DD") + "T" + this.props.values.value) : defaultValue;

        let minute = moment(value).format("mm");
        if(this.props.rules.skipMinute){
            const difference = parseInt(minute) % parseInt(this.props.rules.skipMinute);
            if( difference != 0){
                minute = (parseInt(minute) - difference);
                minute = (minute > 10) ? minute + "" : "0" + minute;
            }
            value = moment(value).minute(minute).format("YYYY-MM-DDTHH:mm:ss");
        }

        if(this.props.rules.addEmpty){
            hArray.unshift("NA");
            tArray.unshift("NA");
        }

        this.setState(
            {
                value:value,
                enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
                hour:moment(value).format("HH"),
                minute:moment(value).format("mm"),
                seconds:moment(value).format("ss"),
                defaultValue:this.props.rules.defaultValue,
                hArray:hArray,
                tArray:tArray
            }
        );
    }

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if(u.notNull(newProps.values.value) && newProps.values.value != state.value){
            let value = Time.isDateTime(newProps.values.value) ? newProps.values.value : moment().format("YYYY-MM-DD") + "T"+ newProps.values.value;
            let minute = moment(value).format("mm");
            if(newProps.rules.skipMinute){
                const difference = parseInt(minute) % parseInt(newProps.rules.skipMinute);
                if( difference != 0){
                    minute = (parseInt(minute) - difference) + "";
                    minute = (minute > 10) ? minute + "" : "0" + minute;
                    value = moment(value).minute(minute).format("YYYY-MM-DDTHH:mm:ss");
                }
            }

            state.value=value;
            state.hour=moment(value).format("HH");
            state.minute=moment(value).format("mm");
            state.seconds=moment(value).format("ss");
        }

        if(u.notNull(newProps.values.value) && typeof newProps.rules.defaultValue != "undefined" && newProps.rules.defaultValue != state.defaultValue){
            let value = moment().format("YYYY-MM-DD") + "T"+ newProps.rules.defaultValue;
            let minute = moment(value).format("mm");
            if(newProps.rules.skipMinute){
                const difference = parseInt(minute) % parseInt(newProps.rules.skipMinute);
                if( difference != 0){
                    minute = (parseInt(minute) - difference) + "";
                    minute = (minute > 10) ? minute + "" : "0" + minute;
                    value = moment(value).minute(minute).format("YYYY-MM-DDTHH:mm:ss");
                }
            }

            state.value=value;
            state.hour=moment(value).format("HH");
            state.minute=moment(value).format("mm");
            state.seconds=moment(value).format("ss");
            state.defaultValue=newProps.rules.defaultValue;
        }

        return state;
    }

    static isDateTime(value){
        if(typeof value != "string"){
           return false;
        }
        if(value.indexOf("T") != -1){
           return true;
        }
        return false;
    }

    onChange=(e)=>{
        if(!this.state.enabled)
            return 0;

        const id = e.currentTarget.id;
        const value = e.currentTarget.value;
        const label = (this.props.field) ? this.props.field : this.props.label;

        if(value == "NA"){
            this.setState({value:undefined,hour:"NA",minute:"NA",seconds:"NA"});
            if(this.props.onChange){
                this.props.onChange(undefined,label,this.props.index);
            }
            return 0;
        }

        let newValue = moment(this.state.value).set(id,value);
        const formattedNewValue = (this.props.rules.keepTimeZone) ?  "YYYY-MM-DDTHH:mm:ssZ" : "YYYY-MM-DDTHH:mm:ss";
        this.setState({value:newValue.format(formattedNewValue),hour:newValue.format("HH"),minute:newValue.format("mm"),seconds: newValue.format("ss")});

        if(this.props.onChange){
            const submitFormat = Time.isDateTime(this.props.values.value) ? "YYYY-MM-DDTHH:mm:ssZ" : "HH:mm:ss";
            this.props.onChange(newValue.format(submitFormat),label,this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    clear=(value)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : moment().format("YYYY-MM-DDTHH:mm:ss")) : moment().format("YYYY-MM-DDTHH:mm:ss");
        const newValue = (value) ? value : defaultValue;
        this.setState({value:newValue,hour:moment(newValue).format("HH"),minute:moment(newValue).format("mm"),seconds: moment(newValue).format("ss"),hasError:false});

        if(this.props.onChange){
            this.props.onChange(newValue,this.props.label,this.props.index);
        }
    }

    validate=()=>{
        let value = this.state.value;
        if(this.props.rules.skipSeconds){
            value = moment(this.state.value).seconds(0).format("YYYY-MM-DDTHH:mm:ss");
        }
        if(!moment(value).isValid()){
            return {valid:false,value:"Field: " + this.props.label + " is not a valid time"};
        }

        if(this.props.rules.addEmpty){
            if(typeof this.state.value == "undefined"){
                return {valid:true};
            }
        }

        if(this.props.rules.minTime){
            const currentTime = moment(this.state.value).format("HHmmss");
            const minTime = moment("2020-01-01T"+this.props.rules.minTime).format("HHmmss");
            if(parseInt(currentTime) < parseInt(minTime)){
                return {valid:false,value:"Field: " + this.props.label +" cannot be before " + moment("2020-01-01T"+this.props.rules.minTime).format("h:mma")};
            }
        }

        if(this.props.rules.maxTime){
            const currentTime = moment(this.state.value).format("HHmmss");
            const minTime = moment("2020-01-01T"+this.props.rules.maxTime).format("HHmmss");
            if(parseInt(currentTime) > parseInt(minTime)){
                return {valid:false,value:"Field: " + this.props.label +" cannot be after " + moment("2020-01-01T"+this.props.rules.maxTime).format("h:mma")};
            }
        }

        const submitFormat = Time.isDateTime(this.props.values.value) ? "YYYY-MM-DDTHH:mm:ssZ" : "HH:mm:ss";
        return {valid:true,value:moment(value).format(submitFormat),label:this.props.label,index:this.props.index};
    }

    render=()=>{
        return(
            <div className="time" title={this.props.title}>
                <select className={"hour-select" + ((this.props.rules.skipSeconds) ? " skipSeconds" : "") + ((this.state.enabled) ? "" : " disabled")}
                        id="hour"
                        value={this.state.hour}
                        onChange={this.onChange}>
                    {
                        (this.state.hArray.map(
                            (hour,index)=>{
                                return <option key={index} value={hour}>{hour}</option>
                            }
                        ))
                    }
                </select>
                <div className={"time-divider" + ((this.props.rules.skipSeconds) ? " long" : "")}>:</div>
                <select className={"minute-select" + ((this.props.rules.skipSeconds) ? " skipSeconds" : "") + ((this.state.enabled) ? "" : " disabled")}
                        id="minute"
                        value={this.state.minute}
                        onChange={this.onChange}>
                    {
                        (this.state.tArray.map(
                            (minute,index)=>{
                                return <option key={index} value={minute}>{minute}</option>
                            }
                        ))
                    }
                </select>
                <div className={"time-divider" + ((this.props.rules.skipSeconds) ? " skipSeconds" : "")}>:</div>
                <select className={"seconds-select" + ((this.props.rules.skipSeconds) ? " skipSeconds" : "") + ((this.state.enabled) ? "" : " disabled")}
                        id="second"
                        value={this.state.seconds}
                        onChange={this.onChange}>
                    {
                        (this.state.tArray.map(
                            (seconds,index)=>{
                                return <option key={index} value={seconds}>{seconds}</option>
                            }
                        ))
                    }
                </select>
            </div>
        )
    }
}
Time.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    title:PropTypes.string,
    values:PropTypes.object,
    onChange:PropTypes.func,
    rules:PropTypes.object,
};
Time.defaultProps={
    index:"0",
    values:{},
    rules:{
      skipSeconds:true
    }
};

/**
 * Creates a ready to use themed date element with pre-loaded validation rules. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string::sqlDate} value=moment().format("YYYY-MM-DD")
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string::sqlDate} minTime
 *          minimum date value to be inputted
 *
 *      @var {string::sqlDate} maxTime
 *          maximum date value to be inputted
 *
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 *
 * @param {function} onChange(value,field,index,onError)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string::sqlDate)
 *      clears the input and sets value to new string, if string == null, set to todays date. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableTime extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : moment().format("HH:mm:ss"),
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value : moment().format("HH:mm:ss"),
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.value != "undefined") && newProps.values.value != state.value){
            state.value=newProps.values.value;
            state.newValue=newProps.values.value;
        }
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        if(result.value != this.props.values.value){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(result.value);
                    if(!addVal.valid){
                        return addVal;
                    }
                }
            }

            u.post({
                url:this.props.url,
                data:{
                    oldValue:this.props.values.value,
                    newValue:result.value,
                    field:this.props.field,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                },
                success:(e)=>{
                    if(this.props.onChange){
                        this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{
                            this.setState({error:error});
                        });
                    }
                    this.setState({active:false,changed:true});
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        }
        else{
            this.setState({active:false,changed:false});
        }
    }

    updateValue=(value,label,index)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        if(!this.state.enabled){
            return 0;
        }
        this.setState({active:true});
    }

    render=()=>{
        const displayFormat = (this.props.rules.displayFormat) ? this.props.rules.displayFormat : "h:mma";
        const value=(Time.isDateTime(this.props.values.value)) ? moment(this.props.values.value).format(displayFormat) : moment("2000-01-01T" + this.props.values.value).format(displayFormat);
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>
                            {(this.props.values.value) ? value  : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}
                        </div>
                        :
                        <div className="editable-input-content">
                            <div className="editable-input-content-container">
                                <Time ref="input" label={this.props.label} index={this.props.index} values={{value:this.state.newValue}}
                                      title={this.props.title} onChange={this.updateValue} rules={this.props.rules}/>
                            </div>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>

                }
            </div>
        )
    }
}
EditableTime.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object.isRequired,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired,
};
EditableTime.defaultProps={
    index:"0",
    values:{},
    rules:{}
};

/**
 * Creates a ready to use themed textarea element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} rows
 *          number of rows to display on this area
 *
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class Area extends React.Component{

    state={
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : ((this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : ""),
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        rows:(typeof this.props.rules.rows != "undefined") ? this.props.rules.rows : 4,
        hasError:false,
    }

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if(u.notNull(newProps.values.value) && newProps.values.value != state.value){
            state.value=newProps.values.value;
        }

        return state;
    }

    onChange=(e)=>{
        if(!this.state.enabled)
            return 0;

        const newValue = e.target.value;
        this.setState({value:newValue});
        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(newValue,label,this.props.index);
        }
    }

    clear=(text)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : "";
        const newValue = (text) ? text : defaultValue;
        this.setState({value:newValue,hasError:false});

        if(this.props.onChange){
            this.props.onChange(newValue,this.props.label,this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    validate=()=>{
        this.setState({error:"",hasError:false});
        let value = this.state.value;
        const label= this.props.label.replace(/[^a-zA-Z0-9\- ]/g, "");

        if(this.props.rules.required){
            if(!(value) || value.length<=0){
                const error = "Please enter your " + label.toLowerCase();
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }
        else{
            if(!(this.props.rules.minLength)) {
                if(value.length == 0){
                    return {valid:true,data:""};
                }
            }
        }

        if(this.props.rules.noSpecialCharacters){
            const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/i;
            const exists = value.search(specialCharacters);
            if(exists != -1){
                const error="Please remove special characters from " + label.toLowerCase();
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if (this.props.rules.dotOnly){
            const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]/i;
            const exists = value.search(specialCharacters);
            if(exists != -1){
                const error="Please remove special characters from " + label.toLowerCase();
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.noLetters){
            const letters = /[^0-9.]/g;
            const exists = value.search(letters);
            if(exists != -1){
                const error=label + " cannot contain letters or special characters";
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }

        if(this.props.rules.minLength){
            if(value.length < this.props.rules.minLength){
                const error = label + " cannot be shorter than " + this.props.rules.minLength + " characters";
                this.setState({hasError:true});
                return {valid:false,value: error};
            }
        }

        if(this.props.rules.maxLength){
            if(value.length > this.props.rules.maxLength){
                const error = label + " cannot be longer than " + this.props.rules.maxLength + " characters";
                this.setState({hasError:true});
                return {valid:false,value: error};
            }
        }

        return {valid: true, value:value,label:this.props.label,index:this.props.index};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    render=()=>{
        return (
            <div className="area" title={this.props.title}>
                <textarea className={((this.state.enabled) ? "" : " input-disabled")+ ((this.state.hasError) ? " input-error" : "")}
                          rows={this.state.rows}
                          placeholder={this.props.rules.placeholder}
                          value={this.state.value}
                          onChange={this.onChange}
                          />
            </div>
        )
    }
}
Area.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func
};
Area.defaultProps={
    index:"0",
    values:{},
    rules:{}
};

/**
 * Creates a ready to use themed editable textarea with pre-loaded validation rules. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {object} additionalData
 *          additional data to add to post function
 *
 * @param {function} onChange(value,field,index,onError)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableArea extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value : "",
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.value != "undefined") && newProps.values.value != state.value){
            state.value=newProps.values.value;
            state.newValue=newProps.values.value;
        }
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        if(result.value != this.props.values.value){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(result.value);
                    if(!addVal.valid){
                        return addVal;
                    }
                }
            }

            u.post({
                url:this.props.url,
                data:{
                    oldValue:this.props.values.value,
                    newValue:result.value,
                    field:this.props.field,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                },
                success:(e)=>{
                    if(this.props.onChange){
                        this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{this.setState({error:error});});
                    }
                    this.setState({active:false,changed:true});
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        }
        else{
            this.setState({active:false,changed:false});
        }
    }

    updateValue=(value,label,index)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        if(!this.state.enabled){
            return 0;
        }
        this.setState({active: true});
    }

    render=()=>{
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div style={this.props.labelStyle} className={"editable-input-label area" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>{(this.props.values.value) ? this.props.values.value : ((this.props.rules.nullText) ? this.props.rules.nullText : "None")}</div>
                        :
                        <div className="editable-input-content">
                            <div className="editable-input-content-container">
                                <Area ref="input" label={this.props.label} index={this.props.index} type={this.props.type} values={{value:this.state.newValue}}
                                      rules={this.props.rules} title={this.props.title} onChange={this.updateValue}/>
                            </div>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>
                }
            </div>
        )
    }
}
EditableArea.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object.isRequired,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired
};
EditableArea.defaultProps={
    index:"0",
    type:"text",
    values:{},
    rules:{
        nullText:"None"
    }
};

/**
 * Creates a themed checkbox with the following props, has fixed width & height
 *
 * @param {number} index=0
 *      reference to this checkbox, will be returned onChange
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {bool} value=false
 *          value of the checkbox
 *
 *      @var {bool} enabled=true
 *          enable or disable this checkbox
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {bool} showNegative=false
 *          show a cross instead of empty checkbox
 *
 *      @var {bool} alertOnly=false
 *          does not update value on click, only triggers onChange command, value should be updated from parent component
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enabled or disable this checkbox
 *
 * @function {} clear(bool)
 *      clears the checkbox to value, only usable if checkbox manages its own state
 *
 * @function {object} validate()
 *      returns {valid,value}
 *
 */
export class Checkbox extends React.Component{

    state={
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : ((this.props.rules.defaultValue) ? this.props.rules.defaultValue :false),
        enabled:(typeof this.props.values.enabled !="undefined") ? this.props.values.enabled : true,
        showNegative:(typeof this.props.rules.showNegative != "undefined") ? this.props.rules.showNegative : false
    };

    componentDidMount=()=>{
        if(!this.props.values.value){
            this.setState({value:(this.props.rules.defaultValue)});
        }
    }

    static getDerivedStateFromProps=(newProps,state)=>{
        if((typeof newProps.values.enabled != "undefined") && newProps.values.enabled != state.enabled){
            state.enabled=newProps.values.enabled;
        }

        if(u.notNull(newProps.values.value) && newProps.values.value != state.value){
            state.value=newProps.values.value;
        }

        return state;
    }

    toggleCheckbox=(e)=>{
        e.stopPropagation();
        if(this.state.enabled) {
            const newState = (typeof this.state.value == "string") ? ((this.state.value == "1") ? false : true) : !this.state.value;
            if(this.props.rules.alertOnly){}
            else {
                if(typeof this.props.values.value == "undefined"){
                    this.setState({value:newState});
                }
            }

            if (this.props.onChange) {
                const label = (this.props.field) ? this.props.field : this.props.label;
                this.props.onChange(newState, label, this.props.index);
            }
        }
    }

    enable=(enable)=>{
        this.setState({enabled:enable});
    }

    validate=()=>{
        return {valid:true,value:this.state.value};
    }

    clear=(value)=>{
        this.setState({value:(value)});
    }

    render=()=>{
        return(
            <div onClick={this.toggleCheckbox} className={"checkbox "+((this.state.enabled) ? "" : "input-disabled")} style={this.props.style} title={this.props.title}>
                {
                    (this.state.value == true || this.state.value == "1" || this.state.value == 1)
                        ?
                        <img src="/images/input/tick.png"/>
                        :
                        (
                            (this.state.showNegative)
                                ?
                                <img src="/images/input/cross.png"/>
                                :
                                ""
                        )
                }
            </div>
        );
    }
}
Checkbox.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    values:PropTypes.object,
    onChange:PropTypes.func,
    rules:PropTypes.object,
    title:PropTypes.string,
    label:PropTypes.string,
};
Checkbox.defaultProps={
    values:{},
    rules:{}
};

/**
 * Creates a themed text button for that has an onClick
 *
 * @param {func} onClick
 *      on click event callback
 */
export class TextButton extends React.Component{

    onClick=()=>{
        this.props.onClick(this.props.index);
    }

    render=()=>{
        return(
            <div className={"text-button" + ((this.props.enabled === false) ? " disabled" : "")} style={this.props.style} onClick={this.onClick}>{this.props.children}</div>
        )
    }
}
TextButton.propTypes={
    onClick:PropTypes.func.isRequired,
    style:PropTypes.object,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
};
TextButton.defaultProps={
    style:{}
};

/**
 * Renders a standard button skinned to the application
 *
 * @param {func} onClick
 *      parent on click function
 *
 * @param {string} index
 *      returns the index onClick
 *
 * @param {string} title
 *      tooltip text
 *
 * @param {string} type
 *      tight, medium, large
 *
 */
export class Button extends React.Component{

    onClick=(e)=>{
        if(e){
            e.preventDefault();
        }
        e.stopPropagation();
        this.props.onClick(this.props.index);
    }

    render=()=>{
        return (
          <button id={this.props.id}
                  className={"button " + this.props.type + ((typeof this.props.enabled != "undefined") ? ((this.props.enabled) ? "" : " disabled") : "") + ((this.props.className) ? " " + this.props.className : "")}
                  onClick={this.onClick}
                  title={this.props.title}
                  style={(this.props.style) ? this.props.style : {margin:this.props.margin}}>
                      {this.props.children}
          </button>
        )
    }
}
Button.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    onClick:PropTypes.func.isRequired,
    title:PropTypes.string,
    margin:PropTypes.string,
    type:PropTypes.string,
    id:PropTypes.string,
    style:PropTypes.object,
};
Button.defaultProps={
    margin:"10px",
    type:"medium"
};

/**
 * Creates a themed mini + button, takes the size of the parent div
 *
 * @function onClick(this.props.index)
 */
export class MiniPlusButton extends React.Component{

    onClick=(e)=>{
        if(e){
            e.preventDefault();
        }
        if(this.props.enabled) {
            this.props.onClick(this.props.index);
        }
    }

    render=()=>{
        return (
            <div className={"mini-button" + ((this.props.enabled) ? "" : " disabled")}
                 style={this.props.style}
                 onClick={this.onClick}
                 title={this.props.title}>
                <img src="/images/input/plus.png"/>
            </div>
        )
    }
}
MiniPlusButton.propTypes={
    onClick:PropTypes.func.isRequired,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    title:PropTypes.string,
    enabled:PropTypes.oneOfType([PropTypes.bool,PropTypes.number])
};
MiniPlusButton.defaultProps={
    enabled:true
};

/**
 * Creates a themed mini + button, takes the size of the parent div
 */
export class MiniMinusButton extends React.Component{

    onClick=(e)=>{
        if(e){
            e.preventDefault();
        }
        if(this.props.enabled) {
            this.props.onClick(this.props.index);
        }
    }

    render=()=>{
        return (
            <div className={"mini-button"+ ((this.props.enabled) ? "" : " disabled")} onClick={this.onClick} title={this.props.title}>
                <img src="/images/input/minus.png"/>
            </div>
        )
    }
}
MiniMinusButton.propTypes={
    onClick:PropTypes.func.isRequired,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    title:PropTypes.string,
    enabled:PropTypes.oneOfType([PropTypes.bool,PropTypes.number])
};
MiniMinusButton.defaultProps={
    enabled:true
};

/**
 * Creates a themed label to be sized the same with editable inputs
 *
 * @param {string} nullText
 *      Text to display if incoming children is undefined
 */
export class EditSizeLabel extends React.Component{
    render=()=>{
        return(
                <div className="editable-input" style={this.props.style}>
                    <div className="editable-link-label" style={this.props.labelStyle}>
                      {
                        (this.props.children)
                            ?
                            <span>
                              <span>{((this.props.prefix) ? this.props.prefix : "")}</span>
                              <span>{this.props.children}</span>
                              <span>{((this.props.suffix) ? this.props.suffix : "")}</span>
                            </span>
                            :
                            (
                              (this.props.nullText)
                                  ?
                                  this.props.nullText
                                  :
                                  "None"
                            )
                      }
                    </div>
                </div>
            )
    }
}
EditSizeLabel.propTypes={
    nullText:PropTypes.string,
    style:PropTypes.object,
    labelStyle:PropTypes.object
};

/**
 * Creates a themed label to be sized the same with editable inputs
 *
 * @param {string} nullText
 *      Text to display if incoming children is undefined
 */
export class EditSizeLink extends React.Component{
    render=()=>{
        return(
            <div className="editable-input" style={this.props.style}>
                <div className="editable-link-label" style={this.props.labelStyle}>{(this.props.children) ? this.props.children : ((this.props.nullText) ? this.props.nullText : "None")}</div>
            </div>
        )
    }
}
EditSizeLabel.propTypes={
    nullText:PropTypes.string,
    style:PropTypes.object,
    labelStyle:PropTypes.object
};

/**
 * Creates a themed input slider
 *
 * @param {number} value
 *
 * @param {number} min
 *
 * @param {number} max
 *
 * @param {func} onChange
 */
export class ProgressSlider extends React.Component{

    render=()=>{
        return(
            <div className="slider">
                <Slider value={this.props.value} step={this.props.step} onChange={this.props.onChange} min={this.props.min} max={this.props.max} orientation={this.props.orientation}/>
            </div>
        )
    }
}
ProgressSlider.propTypes={
    value:PropTypes.number.isRequired,
    min:PropTypes.number.isRequired,
    max:PropTypes.number.isRequired,
    onChange:PropTypes.func.isRequired,
    step:PropTypes.number,
    orientation:PropTypes.string,
};
ProgressSlider.defaultProps={
    step:1,
    orientation:"horizontal"
};

/**
 * Places an input box that is permanently focused and waiting for keyboard input
 *
 * @param onSubmit(value)
 *
 */
export class ScanInput extends React.Component{

    intervalEvent = null;

    componentDidMount=(e)=>{
        this.setInterval();
    }

    componentWillUnmount=()=>{
        clearTimeout(this.intervalEvent);
    }

    setInterval=()=>{
        clearTimeout(this.intervalEvent);
        this.intervalEvent=setTimeout(()=>{
            this.refs['input'].focus();
            this.setInterval();
        },250);
    }

    onSubmit=(e)=>{
        if(e){
            e.preventDefault();
        }

        this.props.onSubmit(this.refs['input'].value);
        this.refs['input'].value = "";
    }

    render=()=>{
        return (
            <div>
                <form onSubmit={this.onSubmit} style={{opacity:0,position:"absolute"}}>
                    <input ref="input" type="text"/>
                </form>
            </div>
        )
    }
}
ScanInput.propTypes={
    onSubmit:PropTypes.func.isRequired,
};

/**
 * Creates a themed on off button
 *
 * @param {bool} value
 *
 * @param {func} onChange
 *
 * @param {string} activeLabel
 *
 * @param {string} inactiveLabel
 */
export class BoolSlider extends React.Component{

    onClick=(e)=>{
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        if(this.props.enabled){
            if(this.props.onChange){
                this.props.onChange(!this.props.value,this.props.index);
            }
        }
        else{
            this.props.onNoPermission();
        }
    }

    render=()=>{
        return(
            <div className={"bool-slider"+ ((this.props.value) ? " checked" : "") + ((this.props.enabled) ? "" : " disabled")} onClick={this.onClick}>
                <div className="bs-text">{(this.props.value) ? this.props.activeLabel : this.props.inactiveLabel}</div>
                <div className="bs-align"/>
                <div className="bs-button"/>
            </div>
        );
    }
}
BoolSlider.propTypes={
    value:PropTypes.bool,
    onChange:PropTypes.func.isRequired,
    activeLabel:PropTypes.string,
    inactiveLabel:PropTypes.string,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    enabled:PropTypes.bool,
    onNoPermission:PropTypes.func,
};
BoolSlider.defaultProps={
    activeLabel:"On",
    inactiveLabel:"Off",
    enabled:true,
};

/**
 * Creates a themed on off button
 *
 * @param {bool} value
 *
 * @param {func} onChange
 *
 * @param {string} activeLabel
 *
 * @param {string} inactiveLabel
 */
export class LongBoolSlider extends React.Component{

    onClick=()=>{
        if(this.props.enabled){
            if(this.props.onChange){
                this.props.onChange(!this.props.value,this.props.index);
            }
        }
    }

    render=()=>{
        return(
            <div className="long-slider-container">
                {
                  (this.props.label)
                    ?
                    <div className="long-slider-text">{this.props.label}</div>
                    :
                    ""
                }
                <div className={"long-bool-slider"+ ((this.props.value) ? " checked" : "") + ((this.props.enabled) ? "" : " disabled")} onClick={this.onClick}>
                    <div className="bs-text">{(this.props.value) ? this.props.activeLabel : this.props.inactiveLabel}</div>
                    <div className="bs-align"/>
                    <div className="bs-button"/>
                </div>
            </div>
        );
    }
}
BoolSlider.propTypes={
    value:PropTypes.bool.isRequired,
    onChange:PropTypes.func.isRequired,
    activeLabel:PropTypes.string,
    inactiveLabel:PropTypes.string,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    enabled:PropTypes.bool,
    onNoPermission:PropTypes.func,
};
BoolSlider.defaultProps={
    activeLabel:"On",
    inactiveLabel:"Off",
    enabled:true,
};

export class Color extends React.Component{
    onClick=(e)=>{
        if(e){
            e.preventDefault();
        }
        if(this.props.onChange) {
            this.props.onChange(true, this.props.label, this.props.index);
        }
    }

    validate=()=>{
        return {valid:true,value:this.props.color};
    }

    render=()=>{
        const clickable = (typeof this.props.onChange != "undefined");
        return (
            <div className="color" style={{cursor:((clickable) ? "pointer" : "default")}} onClick={this.onClick}>
                <div className="color-content" style={{color:this.props.color,backgroundColor:this.props.color}}/>
            </div>
        )
    }
}
Color.propTypes={
    label:PropTypes.string,
    field:PropTypes.string,
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    color:PropTypes.string,
    onChange:PropTypes.func
};

export class Info extends React.Component{

    onMouseEnter=(e)=>{
        this.info = e.target;
        const infoPosition = this.offset(this.info);
        this.tooltip = document.getElementById("tool-tip")
        this.tooltip.style.display="block";

        this.tooltiptext = document.getElementById("tool-tip-text");
        this.tooltiptext.innerHTML = this.props.title;

        const iconWidth = this.info.offsetWidth;
        const iconHeight = this.info.offsetHeight;

        const width = this.tooltip.offsetWidth;
        const height = this.tooltip.offsetHeight;

        const canGoUp = (infoPosition.top - height >= 0);
        const goUp = (infoPosition.top - height) + "px";
        const goDown = (infoPosition.top + iconHeight) + "px";

        this.tooltip.style.top = (canGoUp) ? goUp : goDown;
        if(!canGoUp){
            this.tooltip.classList.add("down");
        }
        this.tooltip.style.left = (infoPosition.left + (iconWidth / 2) - (width / 2)) + "px";
    }

    onMouseLeave=()=>{
       this.tooltiptext.innerHTML = "";
       this.tooltip.style.display="none";
       this.tooltip.classList.remove("down");
    }

    offset=(el)=>{
        var rect = el.getBoundingClientRect(),
        scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
        scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
    }

    render=()=>{
        return (
            <div className={"info" + (this.props.fill ? " fill" : "") } style={this.props.style} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                {
                    (this.props.fill)
                        ?
                        null
                        :
                        <img src="/images/input/info.png" className="info-img"/>
                }
            </div>
        )
    }
}
Info.propTypes={
    title:PropTypes.string.isRequired,
    style:PropTypes.object,
    fill:PropTypes.bool,
};

export class Menu extends React.Component{

    state={
        open:false
    };

    onClick=(item)=>{
        this.setState({open:false});
        item.onClick();
    }

    render=()=>{
        const options=this.props.options.filter((item)=>{return item.enabled});

        if(options.length == 0){
            return null;
        }

        return(
            <div className="menu-container" style={this.props.style}>
                <div className={'menu-select-button' + ((this.state.open) ? " open" : "")} onClick={()=>{this.setState({open:!this.state.open})}}>
                    {
                      (this.state.open)
                        ?
                        <div className="menu-triangle"/>
                        :
                        ""
                    }
                    <img src={(this.state.open) ? "/images/page-menu/cancel.png" : "/images/page-menu/row-menu.png"}/>
                </div>
                {
                  (this.state.open)
                    ?
                    <div className="menu-select-container">
                        {
                          options.map(
                              (item,index)=>{
                                  return(
                                      <div key={index} className={"menu-select-option" + ((index==0) ? " first" : "")} onClick={()=>{this.onClick(item)}}>{item.label}</div>
                                  )
                              }
                          )
                        }
                    </div>
                    :
                    null
                }
            </div>
        )
    }
}
Menu.propTypes={
    options:PropTypes.array.isRequired
};

/**
 * Creates a ready to use themed input element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} defaultValue
 *          default value that only updates on componentMount & clear
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} minValue
 *          minimum value of input type=number
 *
 *      @var {number} maxValue
 *          maximum value of input type=number
 *
 *      @var {bool} noSpaces
 *          accepts only text with no spaces
 *
 *      @var {bool} stripSpaces
 *          removes all spaces in the entry on submission
 *
 *      @var {bool} toUpperCase
 *          all text in the box will be set to upper case
 *
 *      @var {bool} capitalize
 *          text will capitalize on spaces or first character
 *
 *      @var {object} styles
 *          custom styles to be implemented to input box
 *
 *      @var {array} list
 *          adds autocomplete function
 *
 *      @var {bool} ignoreListFilter
 *
 *      @var {number} toFixed
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the box changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class DayOfWeek extends React.Component{

    state={
        value:(u.notNull(this.props.values.value)) ? this.props.values.value : ((this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : [0,0,0,0,0,0,0]) : [0,0,0,0,0,0,0]),
        hasError:false,
        label:["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
    };

    listkey = u.getKey();

    static getDerivedStateFromProps=(props,state)=>{
        if(u.notNull(props.values.value) && props.values.value != state.value){
            state.value = props.values.value;
        }
        return state;
    }

    onChange=(value,label,index)=>{
        let newValue = this.state.value;
        newValue[index] = (value) ? 1 : 0;
        this.setState({value:newValue});

        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(newValue,label,this.props.index);
        }
    }

    clear=(text)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : "";
        const newValue = (text) ? text : defaultValue;
        this.setState({value:newValue,hasError:false});
        if(this.props.onChange) {
            this.props.onChange(newValue, this.props.label, this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    validate=()=>{
        this.setState({error:"",hasError:false});

        let value = this.state.value;
        const label= this.props.label;

        if(this.props.rules.required){
            const hasActive = value.filter((item)=>{return (item==1)});
            if(!hasActive.length > 0){
                this.setState({hasError:true});
                return {valid:false,value:"Field: " + label + " requires at least 1 day of week"};
            }
        }

        return {valid:true,value:value,label:this.props.label,index:this.props.index};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    focus=()=>{
        this.refs['input'].focus();
    }

    onEnterKey=(e)=>{
        if(e){
            e.preventDefault();
        }
        if(this.props.onEnterKey){
            const field = (this.props.field) ? this.props.field : this.props.label;
            this.props.onEnterKey(field,this.props.index);
        }
    }

    render=()=>{
        const enabled = (this.props.values.enabled != "undefined") ? this.props.values.enabled : true;
        const content = this.state.value;
        const label = this.state.label;

        return (
            <div className={"dow" + ((this.props.noBorder) ? " no-border" : "") + ((this.state.hasError) ? " dow-error" : "")} title={this.props.title} onBlur={this.onBlur} style={this.props.style}>
                {
                    content.map(
                        (value,index)=>{
                            return (
                                <div key={index} className="dow-item">
                                    <div className="dow-text">{label[index]}</div>
                                    <div className="dow-checkbox"><Checkbox values={{value:value}} label={label[index]} index={index} onChange={this.onChange}/></div>
                                </div>
                            )
                        }
                    )
                }
            </div>
        )
    }
}
DayOfWeek.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func,
    autoFocus:PropTypes.bool,
    style:PropTypes.object,
    boxStyle:PropTypes.object,
};
DayOfWeek.defaultProps={
    values:{},
    rules:{},
    style:{}
};

/**
 * Creates a ready to use themed select element. Auto updates database
 *
 * @param {string} index=0
 *      reference to this input element, will be returned onChange
 *
 * @param {string} url
 *      API call when this input is updated
 *
 * @param {string} field
 *      Field name (column) in database
 *
 * @param {string} id
 *      Unique reference to identify correct row in database
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 *      @var {array} options=[]
 *          array of objects to be displayed in list in format {label,value}
 *
 * @param {object} rules
 *
 *      @var {bool} required
 *
 * @param {function} onChange(value,field,index,onError,option)
 *      callback function whenever the checkbox changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string, if string == null, clears to first option. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {value,label,index} of this element
 */
export class EditableDOW extends React.Component{

    state={
        active:false,
        value:(typeof this.props.values.value != "undefined") ? this.props.values.value : [0,0,0,0,0,0,0],
        newValue:(typeof this.props.values.value != "undefined") ? this.props.values.value.slice(0) : [0,0,0,0,0,0,0],
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        error:""
    };

    static getDerivedStateFromProps=(props,state)=>{
        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled = props.values.enabled;
        }
        if((typeof props.values.value != "undefined") && props.values.value != state.value){
            state.value = props.values.value;
            state.newValue = props.values.value.slice(0);
        }
        return state;
    }

    onSubmit=()=>{
        this.setState({error:""});
        const result = this.refs['input'].validate();
        if(!result.valid){
            this.setState({error:result.value});
            return 0;
        }
        let hasChanged=false;
        for(var i=0; i<result.value.length; i++){
            if(result.value[i] != this.props.values.value[i]){
               hasChanged=true;
            }
        }
        if(hasChanged){
            if(this.props.rules){
                if(this.props.rules.validate){
                    const addVal = this.props.rules.validate(result.value);
                    if(!addVal.valid){
                        this.setState({error:addVal.value});
                        return 0;
                    }
                }
            }

            u.post({
                url:this.props.url,
                data:{
                    oldValue:this.props.values.value,
                    newValue:result.value,
                    field:this.props.field,
                    id:this.props.id,
                    additionalData:(this.props.rules) ? this.props.rules.additionalData : null
                },
                success:(e)=>{
                    if(this.props.onChange){
                        this.props.onChange(result.value,this.props.field,this.props.index,(error)=>{this.setState({error:error})});
                    }
                    this.setState({active:false,changed:true});
                },
                error:(error,status)=>{
                    this.setState({error:error});
                }
            });
        }
        else{
            this.setState({active:false,changed:false});
        }
    }

    updateValue=(value,label,index)=>{
        this.setState({newValue:value});
    }

    setActive=(e)=>{
        this.setState({active: true});
    }

    getLabel=(value)=>{
        if(typeof value == "undefined" || value == null || value.length != 7 || value.filter((item)=>{return item == 1}).length == 0){
            return (this.props.rules.nullText) ? this.props.rules.nullText : "None";
        }
        const labelText=["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        let dow = "";
        for(var i=0; i<value.length; i++){
            if(value[i]){
                dow += ((dow.length > 0) ? " / " : "") + labelText[i];
            }
        }
        return dow;
    }

    render=()=>{
        return (
            <div className="editable-input">
                {
                    (!this.state.active)
                        ?
                        <div className={"editable-input-label" + ((this.state.enabled) ? "" : " editable-disabled") + ((this.state.changed) ? " editable-changed" : "")} onClick={this.setActive}>
                            {this.getLabel(this.props.values.value)}
                        </div>
                        :
                        <div className="editable-input-content">
                            <DayOfWeek ref="input"
                                       label={this.props.label}
                                       index={this.props.index}
                                       values={{value:this.state.newValue}}
                                       rules={this.props.rules}
                                       title={this.props.title}
                                       onChange={this.updateValue}/>
                            <img src="/images/input/confirm.png" onClick={this.onSubmit} title="Confirm"/>
                            <div className="editable-input-error">{this.state.error}</div>
                        </div>
                }
            </div>
        )
    }
}
EditableDOW.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    values:PropTypes.object.isRequired,
    title:PropTypes.string,
    onChange:PropTypes.func.isRequired,
    url:PropTypes.string.isRequired,
    id:PropTypes.oneOfType([PropTypes.string,PropTypes.object]).isRequired,
    field:PropTypes.string.isRequired
};
EditableDOW.defaultProps={
    index:"0",
    values:{},
    rules:{
        nullText:"None"
    }
};

/**
 * Creates a ready to use themed input element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {bool} required
 *          1 photo at least
 *
 *      @var {number} min
 *
 *      @var {number} max
 *          maximum number of photos allowed
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the box changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class InputPhoto extends React.Component{

    state={
        value:(u.notNull(this.props.values.value)) ? this.props.values.value : ((this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : []) : []),
        hasError:false,
    };

    static getDerivedStateFromProps=(props,state)=>{
        if(u.notNull(props.values.value) && props.values.value != state.value){
            state.value = props.values.value;
        }
        return state;
    }

    onChange=(value,label,index)=>{
        let newValue = this.state.value;
        newValue[index] = (value) ? 1 : 0;
        this.setState({value:newValue});

        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(newValue,label,this.props.index);
        }
    }

    clear=(text)=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : "") : "";
        const newValue = (text) ? text : defaultValue;
        this.setState({value:newValue,hasError:false});
        if(this.props.onChange) {
            this.props.onChange(newValue, this.props.label, this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    validate=()=>{
        this.setState({error:"",hasError:false});

        let value = this.state.value;
        const label= this.props.label;

        if(this.props.rules.required){
            if(!value.length > 0){
                this.setState({hasError:true});
                return {valid:false,value:"Field: " + label + " requires at least 1 image"};
            }
        }
        if(this.props.rules.min){
            if(!value.length >= this.props.rules.min){
                this.setState({hasError:true});
                return {valid:false,value:"Field: " + label + " requires at least " + this.props.ruls.min + " images"};
            }
        }

        return {valid:true,value:value,label:this.props.label,index:this.props.index};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    focus=()=>{
        this.refs['input'].focus();
    }

    onRemove=(index)=>{
        this.props.onRemove(index);
    }

    onAdd=()=>{
        this.props.onAdd();
    }

    render=()=>{
        const enabled = (this.props.values.enabled != "undefined") ? this.props.values.enabled : true;
        const content = this.state.value;
        const boxStyle = {padding:"5px"};

        return (
            (content.length > 0)
                ?
                <div className="list">
                    {
                        content.map(
                            (item,index)=>{
                                return (
                                  <div key={index} style={{marginTop:((index>0) ? "5px" : "0")}}>
                                      <div className="segment-input-remove" style={{marginLeft:"0",marginRight:"5px"}}>
                                          <MiniMinusButton onClick={()=>{this.onRemove(index)}}/>
                                      </div>
                                      <div className="segment-input-removable">
                                          <EmptyBox style={{position:"relative",width:"100%",padding:"0",overflow:"hidden"}}>
                                              {
                                                  (this.props.type=="attachment")
                                                      ?
                                                      null
                                                      :
                                                      <div className="iphoto-image">
                                                          <div className="iphoto-flex"><img src={item.url}/></div>
                                                      </div>
                                              }
                                              <div className="iphoto-label" style={{padding:"5px"}}>{item.filename}</div>
                                          </EmptyBox>
                                      </div>
                                  </div>
                                )
                            }
                        )
                    }
                    <div className="input-subtext">
                        {
                            (typeof this.props.rules.max != "undefined")
                            ?
                            (
                              (content.length >= this.props.rules.max)
                                ?
                                <i>Max {this.props.rules.max} {(this.props.type=="attachment") ? "documents" : "images"}</i>
                                :
                                <TextButton style={{margin:"0"}} onClick={this.onAdd}>Add</TextButton>
                            )
                            :
                            <TextButton style={{margin:"0"}} onClick={this.onAdd}>Add</TextButton>
                        }
                    </div>
                </div>
                :
                <EmptyBox enabled={true} style={{...boxStyle,...this.props.style,...{textAlign:"left"}}} onClick={this.onAdd}>
                    {(this.props.rules.nullText) ? this.props.rules.nullText : "Click to add photo"}
                    <img src={(this.props.type=="attachment") ? "/images/input/document.png" : "/images/input/photo-camera.png"} className="input-photo-camera"/>
                </EmptyBox>
        )
    }
}
InputPhoto.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func,
    style:PropTypes.object,
    boxStyle:PropTypes.object,
    onRemove:PropTypes.func,
    onAdd:PropTypes.func,
    type:PropTypes.string,//attachment or empty
};
InputPhoto.defaultProps={
    values:{},
    rules:{},
    style:{}
};

/**
 * Creates a ready to use themed input element with pre-loaded validation rules
 *
 * @param {string} index=0
 *      reference to this input box, will be returned onChange
 *
 * @param {string} type=text
 *      type of input accepted in this input box, text | number | email
 *
 * @param {object} values
 *      Values are refreshed in run-time, every change made to values will supersede changes made by component
 *
 *      @var {string} value=""
 *          value of the input, parse in null to allow input to manage its own value
 *
 *      @var {bool} enabled=true
 *          enable or disable this input
 *
 * @param {object} rules
 *      Rules are not updated in run-time, only checks once
 *
 *      @var {string} defaultValue
 *          default value that only updates on componentMount & clear
 *
 *      @var {string} placeholder
 *          default null value placeholder
 *
 *      @var {bool} required
 *          entry must have at least 1 character
 *
 *      @var {bool} noSpecialCharacters
 *          no special characters allowed
 *
 *      @var {bool} dotOnly
 *          no specialCharacters except for .
 *
 *      @var {bool} lettersOnly
 *          no specialCharacters & numerals
 *
 *      @var {bool} noLetters
 *          accepts only numbers and .
 *
 *      @var {bool} username
 *          accepts only valid username formats
 *
 *      @var {number} minLength
 *          minimum length of input type!=number
 *
 *      @var {number} maxLength
 *          maximum length of input type!=number
 *
 *      @var {number} minValue
 *          minimum value of input type=number
 *
 *      @var {number} maxValue
 *          maximum value of input type=number
 *
 *      @var {bool} noSpaces
 *          accepts only text with no spaces
 *
 *      @var {bool} stripSpaces
 *          removes all spaces in the entry on submission
 *
 *      @var {bool} toUpperCase
 *          all text in the box will be set to upper case
 *
 *      @var {bool} capitalize
 *          text will capitalize on spaces or first character
 *
 *      @var {object} styles
 *          custom styles to be implemented to input box
 *
 *      @var {array} list
 *          adds autocomplete function
 *
 *      @var {bool} ignoreListFilter
 *
 *      @var {number} toFixed
 *
 * @param {function} onChange(value,label,index)
 *      callback function whenever the box changes value
 *
 * @param {string} title
 *      tooltip text
 *
 * @function {} enable(bool)
 *      enable or disable this input
 *
 * @function {} clear(string)
 *      clears the input and sets value to new string. only works if input manages its own value
 *
 * @function {object} validate()
 *      returns {valid,value,label,index} of this element
 */
export class List extends React.Component{

    state={
        value:(u.notNull(this.props.values.value)) ? this.props.values.value : ((this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : []) : []),
        enabled:(typeof this.props.values.enabled != "undefined") ? this.props.values.enabled : true,
        hasError:false,
        showList:false,
        suggest:[],
        selected:-1,
    };

    static getDerivedStateFromProps=(props,state)=>{

        if((typeof props.values.enabled != "undefined") && props.values.enabled != state.enabled){
            state.enabled = props.values.enabled;
        }
        if(u.notNull(props.values.value) && props.values.value != state.value){
            state.value = props.values.value;
        }

        return state;
    }

    onChange=(newValue,label,index)=>{
        let value = this.state.value;
        value[index]=newValue;
        this.setState({value:value});

        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(value,label,this.props.index);
        }
    }

    clear=()=>{
        const defaultValue = (this.props.rules) ? ((typeof this.props.rules.defaultValue != "undefined") ? this.props.rules.defaultValue : [""]) : [""];
        const newValue = defaultValue;
        this.setState({value:newValue,hasError:false});
        if(this.props.onChange) {
            this.props.onChange(newValue, this.props.label, this.props.index);
        }
    }

    enable=(enabled)=>{
        this.setState({enabled:enabled});
    }

    validate=()=>{
        this.setState({error:"",hasError:false});

        let value =  this.state.value;
        const label= this.props.label;

        if(this.props.rules.required){
            if(value.length<=0){
                const error = u.tx("error-field") + ": " + label + " " + u.tx("error-required");
                this.setState({hasError:true});
                return {valid:false,value:error};
            }
        }
        else{
            if(value.length == 0){
                return {valid:true,value:value,label:this.props.label,index:this.props.index};
            }
        }

        if(this.props.isBox){
            for(var i=0; i<value.length; i++){
                const ref = "input-" + i;
                let current = this.refs[ref].validate();
                if(!current.valid){
                    return current;
                }
            }
        }
        return {valid: true, value:value};
    }

    setError=()=>{
        this.setState({hasError:true});
    }

    onBlur=(e)=>{
        this.setState({showList:false,selected:-1});
        document.removeEventListener('keydown',this.keydown);
    }

    noFocus=(e)=>{
        if(e){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    onFocus=(e)=>{
        if(this.state.suggest.length > 0){
            this.setState({showList:true});
            document.addEventListener('keydown',this.keydown);
        }
    }

    focus=()=>{
        this.refs['input'].focus();
    }

    onRemove=(index)=>{
        if(this.props.onRemove){
            this.props.onRemove(index);
            return 0;
        }
        let value = this.state.value;
        value.splice(index,1);
        this.setState({value:value});
        if(this.props.onChange){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onChange(value,label,this.props.index);
        }
    }

    onAddItem=()=>{
        if(this.props.isBox){
            let value = this.state.value;
            value.push("");
            this.setState({value:value});

            if(this.props.onChange){
                const label = (this.props.field) ? this.props.field : this.props.label;
                this.props.onChange(value,label,this.props.index);
            }
        }
        else{
            this.props.onAdd();
        }
    }

    onEditItem=(index)=>{
        if(this.props.onEdit){
            this.props.onEdit(index);
        }
    }

    onDragStart=(index,item)=>{
        this.setState({dragging:index});
        this.drag=item;
    }

    onDragEnd=(index,item)=>{
        this.setState({dragging:-1,dragover:-1});
    }

    onDragOver=(event,index,item)=>{
        event.stopPropagation();
        event.preventDefault();
        this.setState({dragover:index});
    }

    onDrop=(index,item)=>{
        let newValue=u.arrayMove(u.deepCloneArray(this.state.value),this.state.dragging,index);
        newValue = newValue.map((item,index)=>{
            item.order=index;
            return item;
        });
        this.setState({value:newValue});

        if(this.props.onReorder){
            const label = (this.props.field) ? this.props.field : this.props.label;
            this.props.onReorder(newValue,label,this.props.index);
        }
    }

    render=()=>{
        return (
            <div className="list" title={this.props.title}>
                {
                    (this.state.value.length == 0)
                        ?
                        <EmptyBox style={{padding:"5px"}}
                                  hasError={this.state.hasError}
                                  enabled={true}
                                  onClick={this.onAddItem}>
                                  {(this.props.nullText) ? this.props.nullText : "None"}
                        </EmptyBox>
                        :
                        <div>
                            {
                              this.state.value.map(
                                  (item,index)=>{
                                      const dragging=this.state.dragging;
                                      const dragover=this.state.dragover;
                                      const canDragover = (dragging != dragover && dragging+1 != dragover)
                                      return(
                                          <div className={"form-list-item" + (dragging==index ? " dragging" : "") + ((dragover==index && canDragover) ? " drag-over" : "")}
                                               key={index}
                                               draggable={this.props.order}
                                               onDragStart={()=>{this.onDragStart(index,item)}}
                                               onDragEnd={()=>{this.onDragEnd(index,item)}}
                                               onDragOver={(e)=>{this.onDragOver(e,index,item)}}
                                               onDrop={()=>{this.onDrop(index,item)}}>
                                              <div className="segment-input-remove" style={{marginLeft:"0",marginRight:"5px"}}>
                                                  <MiniMinusButton onClick={()=>{this.onRemove(index)}}/>
                                              </div>
                                              <div className="segment-input-removable">
                                                  {
                                                      (this.props.isBox)
                                                          ?
                                                          <Box ref={"input-"+index}
                                                               label={this.props.label + " Item " + (index+1)}
                                                               field="input"
                                                               style={{marginTop:(index > 0) ? "5px" : "0"}}
                                                               values={{value:item}}
                                                               onChange={this.onChange}
                                                               rules={{required:true}}
                                                               index={index}
                                                          />
                                                          :
                                                          <EmptyBox style={{
                                                                        padding:"5px",
                                                                        marginTop:(index > 0) ? "5px" : "0"
                                                                      }}
                                                                      hasError={this.state.hasError}
                                                                      onClick={()=>{this.props.onEdit(index)}}>
                                                                      {(item.label) ? item.label : item.value}
                                                          </EmptyBox>
                                                  }
                                              </div>
                                          </div>
                                      )
                                  }
                            )
                            }
                            <div className="input-subtext">
                                {
                                    (this.props.rules.maxLength && this.state.value.length == this.props.rules.maxLength)
                                        ?
                                        <span className="subtext-max">{this.state.value.length} / {this.props.rules.maxLength}</span>
                                        :
                                        <TextButton style={{margin:"0"}} onClick={this.onAddItem}>Add Item{(this.props.rules.maxLength) ? " ("+this.state.value.length+" / "+this.props.rules.maxLength+")" : ""}</TextButton>
                                }
                            </div>
                        </div>
                }
            </div>
        )
    }
}
List.propTypes={
    index:PropTypes.oneOfType([PropTypes.string,PropTypes.object,PropTypes.number]),
    label:PropTypes.string,
    field:PropTypes.string,
    type:PropTypes.string,
    values:PropTypes.object,
    rules:PropTypes.object,
    title:PropTypes.string,
    onChange:PropTypes.func,
    autoFocus:PropTypes.bool,
    style:PropTypes.object,
    boxStyle:PropTypes.object,
    onAdd:PropTypes.func,
    onEdit:PropTypes.func,
    order:PropTypes.bool,//Allows user to drag and drop to re-order items in list
};
List.defaultProps={
    type:"text",
    values:{},
    rules:{},
    style:{}
};

/**
* Themed Segment Input
*
* @param {object} content
*
*/
export class Input extends React.Component{

    validate=()=>{
        return this.refs['input'].validate();
    }

    onFocus=()=>{
        if(this.props.onFocus){
            this.props.onFocus(this.props.fields,this.props.content);
        }
    }

    render=()=>{
        const fields = this.props.fields;
        const content = this.props.content;
        if(fields.dataType == "divider"){
            return (
                <div className="segment-full-item" style={{margin:"15px auto",backgroundColor:"lightgrey",width:"100%",height:"1px"}}/>
            )
        }
        return(
            <div className="segment-full-item" style={this.props.containerStyle}>
                {
                    (this.props.hideLabel)
                      ?
                      null
                      :
                      <div className="segment-item-label">
                          {fields.label}
                          {
                              (fields.info)
                                  ?
                                  <Info title={fields.info}/>
                                  :
                                  null
                          }
                      </div>
                }
                <div className="segment-item-input" style={this.props.inputStyle}>
                    {
                        (fields.dataType == "box")
                            ?
                            <Box ref="input"
                                 label={fields.label}
                                 field={fields.field}
                                 style={fields.style}
                                 boxStyle={fields.boxStyle}
                                 values={{
                                    value:content[fields.field],
                                    enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                  }}
                                 type={fields.type}
                                 index={fields.index}
                                 rules={fields.rules}
                                 onChange={fields.onChange}
                                 onFocus={this.onFocus}
                                 />
                            :
                            null
                    }
                    {
                        (fields.dataType == "select")
                            ?
                            <Select ref="input"
                                    label={fields.label}
                                    field={fields.field}
                                    style={fields.style}
                                    boxStyle={fields.boxStyle}
                                    values={{
                                        value:content[fields.field],
                                        options:fields.options,
                                        enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                    }}
                                    index={fields.index}
                                    rules={fields.rules}
                                    onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "list")
                            ?
                            <List ref="input"
                                  label={fields.label}
                                  field={fields.field}
                                  style={fields.style}
                                  boxStyle={fields.boxStyle}
                                  values={{
                                      value:content[fields.field],
                                      enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                  }}
                                  index={fields.index}
                                  rules={fields.rules}
                                  onChange={fields.onChange}
                                  onAdd={fields.onAdd}
                                  onEdit={fields.onEdit}
                                  onRemove={fields.onRemove}
                                  isBox={fields.isBox}
                                  order={this.props.order || fields.order}
                                  onReorder={this.props.onReorder}
                                  />
                            :
                            null
                    }
                    {
                        (fields.dataType == "date")
                            ?
                            <Date ref="input"
                                  label={fields.label}
                                  field={fields.field}
                                  style={fields.style}
                                  boxStyle={fields.boxStyle}
                                  values={{
                                      value:content[fields.field],
                                      enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                   }}
                                  index={fields.index}
                                  rules={fields.rules}
                                  range={fields.range}
                                  onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "time")
                            ?
                            <Time ref="input"
                                  label={fields.label}
                                  field={fields.field}
                                  style={fields.style}
                                  boxStyle={fields.boxStyle}
                                  values={{
                                      value:content[fields.field],
                                      enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                  }}
                                  index={fields.index}
                                  rules={{...{skipSeconds:true},...fields.rules}}
                                  onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "area")
                            ?
                            <Area ref="input"
                                  label={fields.label}
                                  field={fields.field}
                                  style={fields.style}
                                  boxStyle={fields.boxStyle}
                                  values={{
                                      value:content[fields.field],
                                      enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                  }}
                                  index={fields.index}
                                  rules={fields.rules}
                                  onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "contactNumber")
                            ?
                            <ContactNumber ref="input"
                                           label={fields.label}
                                           countryCodeField={fields.countryCodeField}
                                           contactNumberField={fields.contactNumberField}
                                           style={fields.style}
                                           boxStyle={fields.boxStyle}
                                           values={{
                                                countryCode:content[fields.countryCodeField],
                                                contactNumber:content[fields.contactNumberField],
                                                enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                            }}
                                           index={fields.index}
                                           rules={fields.rules}
                                           onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "dow")
                            ?
                            <DayOfWeek ref="input"
                                       label={fields.label}
                                       field={fields.field}
                                       style={fields.style}
                                       boxStyle={fields.boxStyle}
                                       values={{
                                            value:content[fields.field],
                                            enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                        }}
                                       index={fields.index}
                                       rules={fields.rules}
                                       onChange={fields.onChange}/>
                            :
                            null
                    }
                    {
                        (fields.dataType == "photo")
                            ?
                            <InputPhoto ref="input"
                                        label={fields.label}
                                        field={fields.field}
                                        style={fields.style}
                                        boxStyle={fields.boxStyle}
                                        values={{
                                            value:content[fields.field],
                                            enabled:(typeof fields.enabled != "undefined") ? fields.enabled : true
                                         }}
                                        index={fields.index}
                                        rules={fields.rules}
                                        onChange={fields.onChange}/>
                            :
                            null
                    }
                </div>
            </div>
        )
    }
}
Input.propTypes={
    fields:PropTypes.object.isRequired,
    content:PropTypes.object
};
Input.defaultProps={
    content:{}
};

export class PageSelector extends React.Component{

    state={
        editing:false,
    }

    static getDerivedStateFromProps(props,state){
        if(!state.editing){
            state.page=props.page;
        }
        return state;
    }

    onChange=(value)=>{
        if(this.props.disabled){
           return 0;
        }

        const maxPage=this.props.maxPage;
        value = parseInt(value);
        if(isNaN(value)){
            this.refs['input'].blur();
            return 0;
        }
        if(value > maxPage){
            value = maxPage;
        }
        else if(value <= 0){
            value = 1;
        }
        this.props.onChange(value);
    }

    getJumpArray=()=>{
        const maxPage = this.props.maxPage;
        if(maxPage >= 5 && maxPage < 20){
          return [
              1,
              Math.ceil(maxPage * 0.25),
              Math.ceil(maxPage * 0.5),
              Math.ceil(maxPage * 0.75),
              maxPage,
          ]
        }
        else if(maxPage >= 20){
            return [
                1,
                Math.ceil(maxPage * 0.1),
                Math.ceil(maxPage * 0.2),
                Math.ceil(maxPage * 0.3),
                Math.ceil(maxPage * 0.4),
                Math.ceil(maxPage * 0.5),
                Math.ceil(maxPage * 0.6),
                Math.ceil(maxPage * 0.7),
                Math.ceil(maxPage * 0.8),
                Math.ceil(maxPage * 0.9),
                maxPage,
            ]
        }
        return [];
    }

    onSubmit=()=>{
       this.onChange(this.state.page);
       this.refs['input'].blur();
    }

    onBlur=()=>{
        this.setState({editing:false});
    }

    render=()=>{
        if(this.props.maxPage == 1){
            return null;
        }
        const jumpArray = this.getJumpArray();
        return(
            <div className={'page-selector-container' + (this.props.className ? " " + this.props.className : "")}>
                <div className="paging-top">
                    <img src="/images/input/list-arrow.png" className="paging-arrow" onClick={()=>{this.onChange(this.props.page - 1)}}/>
                    <div className="paging-content">
                        <span>Page</span>
                        <div className="page-content-input">
                            <Box ref="input"
                                 onChange={(value)=>{this.setState({page:value})}}
                                 label="page"
                                 type="number"
                                 values={{value:this.state.page}}
                                 onFocus={()=>{this.setState({editing:true})}}
                                 onBlur={this.onBlur}
                                 onEnterKey={this.onSubmit}
                                 />
                        </div>
                        /  
                        <div style={{display:"inline-block",verticalAlign:"bottom",minWidth:"30px",margin:"0 5px"}}>{this.props.maxPage}</div>
                    </div>
                    <img src="/images/input/list-arrow.png" className="paging-arrow right" onClick={()=>{this.onChange(this.props.page + 1)}}/>
                </div>
                {
                    (jumpArray.length > 0)
                        ?
                        <div className="paging-skip-to">
                            <div>
                              {
                                  jumpArray.map(
                                      (item,index)=>{
                                          return (
                                              <span key={index} className="jump-option" onClick={()=>{this.onChange(item)}}>{item}</span>
                                          )
                                      }
                                  )
                              }
                            </div>
                        </div>
                        :
                        null
                }
            </div>
        )
    }
}
PageSelector.propTypes={
    maxPage:PropTypes.number.isRequired,
    page:PropTypes.number.isRequired,
    onChange:PropTypes.func.isRequired,
    disabled:PropTypes.bool,
};
PageSelector.defaultProps={

};

export class Table extends React.Component{

    state={
        reload:false,
    }

    componentDidMount=()=>{
        this.interval=setInterval(()=>{
            this.setState({reload:!this.state.reload});
        },1000);
    }

    componentWillUnmount=()=>{
        clearInterval(this.interval);
    }

    render=()=>{
        const fields=this.props.fields;
        const content=this.props.content;
        if(content.length==0 && this.props.minRow==0){
            return (
                <div className="input-table-placeholder">
                      {this.props.emptyText}
                </div>
            )
        }

        const padLength = (content.length < this.props.minRow) ? this.props.minRow - content.length : 0;
        const minRowTable = Array(padLength).fill("");
        let overflow=false;
        if(this.tbody){
            overflow = (this.tbody.scrollHeight > this.tbody.clientHeight);
        }

        return(
            <div className="input-table-container" style={this.props.containerStyle}>
                <table className="input-table" style={this.props.tableStyle}>
                    <thead ref={(element)=>{this.thead=element}}
                           className={"input-table-head"+ ((this.props.onRemove) ? " removable" : "")  +((overflow) ? " overflow" : "")}
                           style={this.props.headStyle}
                        >
                        <tr>
                        {
                            fields.map(
                                (field,index,arr)=>{
                                    const left= (index==0);
                                    const right= (index == arr.length - 1);
                                    return (
                                        <th key={index}
                                            style={field.style}
                                            className="input-header">
                                            {field.label}
                                        </th>
                                    )
                                }
                            )
                        }
                        </tr>
                    </thead>
                    <tbody ref={(element)=>{this.tbody=element}} className="input-table-body" style={{...this.props.bodyStyle,maxHeight:this.props.maxHeight}}>
                      {
                          content.map(
                              (item,index)=>{
                                  return (
                                      <tr key={index} className={"item " + (index%2==0 ? "even" : "odd")}>
                                          {
                                              (this.props.onRemove)
                                                  ?
                                                  <td className="remove">
                                                      <div style={{position:"relative",width:"15px",height:'15px'}}>
                                                          <MiniMinusButton onClick={()=>{this.props.onRemove(index)}}/>
                                                      </div>
                                                  </td>
                                                  :
                                                  null
                                          }
                                          {
                                              fields.map(
                                                  (field,f,farr)=>{
                                                      let to = field.to;
                                                      if(field.to){
                                                          while(to.indexOf("%(") != -1){
                                                              const start = to.indexOf("%(");
                                                              const end = to.indexOf(")%");
                                                              const contentField = to.substring(start + 2, end);
                                                              to = to.replace("%(" + contentField + ")%" , item[contentField]);
                                                          }
                                                      }
                                                      return(
                                                        <td key={f} className={field.className} style={field.style}>
                                                            {
                                                                (field.to)
                                                                  ?
                                                                  <Link to={to}>
                                                                      {item[field.field]}
                                                                  </Link>
                                                                  :
                                                                  item[field.field]
                                                            }
                                                        </td>
                                                      )
                                                  }
                                              )
                                          }
                                      </tr>
                                  )
                              }
                          )
                      }
                      {
                          (padLength > 0)
                              ?
                              minRowTable.map(
                                  (row,index)=>{
                                      return (
                                          <tr className={"empty-row " + ((content.length + index)%2==0 ? "even" : "odd")}>
                                            {
                                                fields.map(
                                                    (field,index)=>{
                                                        return (
                                                            <td key={content.length + index} className={"empty-td " + ((field.className) ? field.className : "")} style={field.style}>
                                                                Empty
                                                            </td>
                                                        )
                                                    }
                                                )
                                            }
                                          </tr>
                                      )
                                  }
                              )
                              :
                              null
                      }
                    </tbody>
                </table>
            </div>
        )
    }
}
Table.propTypes={
    fields:PropTypes.array.isRequired,
    content:PropTypes.array,
    minRow:PropTypes.number,
    maxHeight:PropTypes.number,
    containerStyle:PropTypes.object,
    tableStyle:PropTypes.object,
    headStyle:PropTypes.object,
    bodyStyle:PropTypes.object,
    onRemove:PropTypes.func,
};
Table.defaultProps={
    content:[],
    minRow:0,
    emptyText:<b>No Data Available</b>,
};

/**
* Themed input 6 digit otp component
* Built for google authenticator or other TOTP oauthv1 solutions
*/
export class InputOTP extends React.Component {

    state = {
        pin: ["", "", "", "", "", ""],
    }

    clear=()=>{
        for(var i=0; i<6; i++){
            if(this.refs['pin-'+i]){
                this.refs['pin-'+i].clear();
            }
        }
        if(this.refs['pin-0']){
            this.refs['pin-0'].focus();
        }
    }

    onChangePin = (value, field, index) => {
        let pin = this.state.pin;
        pin[index] = (value.length > 1) ? value.substr(value.length - 1) : value;
        this.setState({ pin: pin });
        //Last field keyed
        if (value == "") {
            /*if(index > 0){
                this.refs['pin-'+(index-1)].focus();
            }*/
        }
        else {
            if (index == 5) {
                //Check completeness
                setTimeout(() => {
                    const pinInput = this.validate();
                    if (pinInput.valid) {
                        this.onComplete(pin);
                    }
                }, 250);
            }
            else {
                this.refs['pin-' + (index + 1)].focus();
            }
        }

    }

    validate = () => {
        let pin = "";
        for (var i = 0; i < 6; i++) {
            let current = this.refs["pin-" + i].validate();
            if (!current.valid) {
                return current;
            }
            pin += current.value;
        }
        return { valid: true, value: pin };
    }

    onComplete = (pinArray) => {
        const pin = pinArray.reduce((a, b) => { a + "" + b }, "");
        if (this.props.onComplete) {
            this.props.onComplete(pin);
        }
    }

    onBackspace = (value, field, index) => {
        if (index > 0 && value == "") {
            this.refs["pin-" + (index - 1)].focus();
        }
    }

    render = () => {
        const pin = this.state.pin;
        const enabled = (this.props.enabled != undefined) ? this.props.enabled : true;

        return (
            <div className="input-otp" style={this.props.style}>
                {
                    pin.map(
                        (value, index) => {
                            return (
                                <Box ref={"pin-" + index}
                                    key={index}
                                    label={"Pin " + (index + 1)}
                                    values={{
                                        value: value,
                                        enabled: enabled
                                    }}
                                    index={index}
                                    onChange={this.onChangePin}
                                    rules={{ required: true }}
                                    onBackspace={this.onBackspace}
                                    type="number"
                                    autoFocus={index==0}
                                />
                            )
                        }
                    )
                }
            </div>
        )
    }
}
InputOTP.propTypes = {
    enabled: PropTypes.bool,
}
