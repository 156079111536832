import React from 'react';
import PublicPageLayout from '../../components/PublicPageLayout';
import {PSAHeader} from './PSAOperationalStatistics';
import {io} from 'socket.io-client';
import moment from 'moment';
import {EditableBox} from '../../components/Input';
import {LoadGraphic} from '../../components/Common';
import u from '../../utilities/Utilities';
import store from 'store';
import {Link} from 'react-router-dom';
import "../../styles/dashboard-generic.scss";
import "../../styles/dashboards/psa-dashboards.scss";

export default class PSAResidentStatus extends React.Component{

    socketType="Resident Status";

    tableRows = 8;

    dormID = this.props.match.params.dormID;

    state={
        content:{
            totalOccupants:0,
            occupantInDorm:0,
            occupantOutDorm:0,
            reportingSick:0,
            homeLeave:0,
            hospitalized:0,
            nonShift:0,
            stayout:0,
            tables:[],
            refreshRate:-1,
            loading:false,
            refresh:false,
        },
        selected:{},
        loading:false,
        refresh:false,
        refreshRate:-1,
    };

    componentDidMount=()=>{
        this.openedOn=moment().format("YYYY-MM-DDTHH:mm:ss");

        this.getContent();
    }

    componentWillUnmount=()=>{
        if(this.demoInterval){
            clearInterval(this.demoInterval);
        }

        if(this.contentInterval){
            clearInterval(this.contentInterval);
        }
    }

    getContent=(refresh)=>{
        this.setState({
            error:"",
            loading:!(refresh),
            refresh:refresh,
        });
        u.post({
            url:"/api/get-dashboard-content",
            data:{
                dormID:this.dormID,
                type:this.socketType,
                testDelay:2000,
                openedOn:this.openedOn,
            },
            success:(content)=>{
                if(content.kill){
                    window.close();
                    return 0;
                }

                this.setState({
                  content:content,
                  loading:false,
                  refresh:false,
                  lastRefreshed:moment().format("YYYY-MM-DDTHH:mm:ss"),
                });
            },
            error:(error)=>{
                this.setState({
                    error:error,
                    loading:false,
                    refresh:false
                });
            }
        });
    }

    /*
    getSocketInfo=()=>{
        this.setState({loading:true,loadText:"Getting server info..."});
        u.post({
            url:"/api/get-socket-info",
            data:{
                dormID:this.dormID,
            },
            success:({socketIP,socketToken})=>{
                this.token=socketToken;
                this.socketIP=socketIP;
                this.setupSocket();
            },
            error:(error)=>{
                this.setState({loadText:error});
            }
        })
    }

    setupSocket=()=>{
        const ipConfig = require("../../../ipconfig.json");
        this.socket = io(this.socketIP,{
            reconnectionDelayMax: 5000,
            withCredentials: true,
            extraHeaders:{
                "csrf-token":store.get("csrfToken"),
                "socket-token":this.token,
                dormID:this.dormID,
            },
            transports:["websocket"],
        });

        this.socket.on('connect',()=>{
            this.setState({sessionID:this.socket.id,connected:true});
            //Initial Emit
            this.socket.emit("get-base-state",
            {
                // send tokens here too because `extraHeaders` don't work in the browser
                csrfToken:store.get("csrfToken"),
                socketToken:this.token,

                dormID:this.dormID,
                socketID:this.socket.id,
                socketType:this.socketType,
            });
        });

        this.socket.on("disconnect",()=>{
            this.setState({connected:false});
        });

        this.socket.on("set-base-state",(content)=>{
            this.setState({content:content,instantiated:true});
        });

        this.socket.on("update-state",this.onUpdateState);
    }

    onUpdateState=(value)=>{
        let content=this.state.content;
        if(value.tableContent){
            content.tables[value.tableIndex].content=value.tableContent;
        }
        for(var key in value){
            if(key != "tableValue" && key != "tableIndex"){
                content[key] = value[key]
            }
        }
        this.setState({content:content});
    }
    */

    psaColors=[
      u.hexToRgb("#229dce"),
      u.hexToRgb("#37a7b6"),
      u.hexToRgb("#63c08a"),
      u.hexToRgb("#8ed75e"),
      u.hexToRgb("#a5e348"),
      u.hexToRgb("#cac9d0"),
    ];

    onChangeDashboard=(value,label,index)=>{
        let content=this.state.content;
        content.tables[index].labelEditable.value=value;
        this.setState({content:content});
    }

    onSelectHeader=(tableIndex,headerIndex)=>{
        let content=this.state.content;
        content.tables = content.tables.map(
            (item,tIndex)=>{
                item.headers = item.headers.map(
                  (header,hIndex)=>{
                      if(!(tIndex == tableIndex && hIndex == headerIndex)){
                        header.selected=false;
                        header.twice=false;
                      }
                      return header;
                  }
                )
                return item;
            }
        );
        let header = content.tables[tableIndex].headers[headerIndex];
        let searchDescending=true;
        if(header.selected){
            header.twice=!header.twice;
            searchDescending=!header.twice;
        }
        else{
            header.selected=true;
            searchDescending=true;
        }

        //Perform sort
        let sortContent = content.tables[tableIndex].content;
        sortContent = sortContent.sort(
            (a,b)=>{
                if(searchDescending){
                    return (a[header.field] > b[header.field]) ? -1 : 1;
                }
                return (a[header.field] < b[header.field]) ? -1 : 1;
            }
        );

        this.setState({content:content});
    }

    onChangeRefresh=(valueMS)=>{
        //console.log("Changing Refresh",valueMS);
        //Check difference from refresh
        this.setState({refreshRate:valueMS});
        if(valueMS == "-1"){
            if(this.contentInterval){
                clearInterval(this.contentInterval);
            }
        }
        else{
          const msSinceRefresh = moment().diff(moment(this.state.lastRefreshed));
          //console.log("MS Since Refresh",msSinceRefresh);
          if(msSinceRefresh > valueMS){
              this.getContent(true);
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              this.contentInterval = setInterval(()=>{
                  this.getContent(true);
              },valueMS);
          }
          else{
              if(this.contentInterval){
                  clearInterval(this.contentInterval);
              }
              setTimeout(()=>{
                  this.getContent(true);
                  if(this.contentInterval){
                      clearInterval(this.contentInterval);
                  }
                  this.contentInterval = setInterval(()=>{
                      this.getContent(true);
                  },valueMS);
              },(valueMS - msSinceRefresh));
          }
        }
    }

    render=()=>{
        const content=this.state.content;
        const selected=this.state.selected;

        return(
            <PublicPageLayout url="/psa-resident-status"
                              title={this.socketType}
                              style={{position:"relative",zIndex:102}}
                              >

                <LoadGraphic active={this.state.loading} text="Preparing"/>

                <PSAHeader label={(content.label) ? content.label : this.socketType.toUpperCase()}
                           onChange={this.onChangeRefresh}
                           loading={this.state.loading}
                           lastRefreshed={this.state.lastRefreshed}
                           refresh={this.state.refresh}
                           refreshRate={this.state.refreshRate}
                           />

                <div style={{position:"relative",width:"100%",paddingTop:"20px"}}/>

                <div className="psa-top-box">
                    <div className="psa-long-box green" style={{marginLeft:"0"}}>
                        <div className="long-left">{content.totalOccupants}</div>
                        <div className="long-right">RESIDENTS</div>
                    </div>
                    <div className="psa-long-box lightgreen">
                        <div className="long-left">{content.occupantInDorm}</div>
                        <div className="long-right">IN DORM</div>
                    </div>
                    <div className="psa-long-box red">
                        <div className="long-left">{content.occupantOutDorm}</div>
                        <div className="long-right">OUT OF DORM</div>
                    </div>
                    <div className="psa-short-box green">
                        <div className="short-center">{content.reportingSick}</div>
                        <div className="short-bottom">REPORT SICK</div>
                    </div>
                    <div className="psa-short-box lightgreen" style={{marginRight:"0"}}>
                        <div className="short-center">{content.nonShift}</div>
                        <div className="short-bottom">NON-SHIFT</div>
                    </div>
                    <div className="psa-short-box red">
                        <div className="short-center">{content.homeLeave}</div>
                        <div className="short-bottom">HOME LEAVE</div>
                    </div>
                    <div className="psa-short-box red">
                        <div className="short-center">{content.hospitalized}</div>
                        <div className="short-bottom">HOSPITALIZED</div>
                    </div>
                    <div className="psa-short-box red">
                        <div className="short-center">{content.stayout}</div>
                        <div className="short-bottom">STAY OUT</div>
                    </div>

                </div>

                <div className="psa-tables-container" style={{minHeight:"400px"}}>
                    {
                        content.tables.map(
                            (item,index)=>{
                                let padLength = (this.tableRows - item.content.length);
                                if(padLength < 0){
                                    padLength = 0;
                                }
                                const tablePadding=Array(padLength).fill("");

                                return (
                                    <div key={index} className={"psa-table-container " + (index%2==0 ? "tleft" : "tright")}>
                                        <h3 className="psa-table-title" style={{color:this.psaColors[index].hex}}>
                                            {item.label}
                                            {
                                              (item.labelEditable)
                                                  ?
                                                  <span style={{marginLeft:"5px"}}>
                                                    (<div className="dashboard-editable">
                                                        <EditableBox label={item.labelEditable.label}
                                                                     field={item.labelEditable.field}
                                                                     values={{value:item.labelEditable.value}}
                                                                     labelStyle={{padding:"0",border:"none"}}
                                                                     type={item.labelEditable.type}
                                                                     index={index}
                                                                     id={"USE TOKEN"}
                                                                     url="/api/update-dashboard-setting"
                                                                     onChange={this.onChangeDashboard}
                                                                     rules={item.labelEditable.rules}
                                                                     />
                                                    </div>)
                                                  </span>
                                                  :
                                                  null
                                            }
                                        </h3>
                                        <div className="psa-table">
                                            <table>
                                                <thead className={(item.content.length > this.tableRows ? "oversize" : "")} style={{backgroundColor:this.psaColors[index].hex}}>
                                                    <tr>
                                                        {
                                                            item.headers.map(
                                                                (header,h,arr)=>{
                                                                    return (
                                                                        <th key={h}
                                                                            style={{backgroundColor:this.psaColors[index].hex}}
                                                                            onClick={()=>{this.onSelectHeader(index,h)}}
                                                                            className={header.label}
                                                                            >
                                                                        {header.label}
                                                                        {
                                                                            (header.selected)
                                                                                ?
                                                                                <img src="/images/app/nav-triangle.png" className={"dashboard-selected" + ((header.twice) ? " twice" : "")}/>
                                                                                :
                                                                                null
                                                                        }
                                                                        </th>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody style={{maxHeight:(40*this.tableRows)+"px"}}>
                                                    {
                                                        item.content.map(
                                                            (row,ri)=>{
                                                                return (
                                                                    <Link key={ri} to={u.linkReplace(item.link,row)}>
                                                                        <tr className={"item " + (ri%2 == 0 ? "even" : "odd")}>
                                                                          {
                                                                            item.headers.map(
                                                                                (header,j)=>{
                                                                                    return (
                                                                                        <td key={(ri*item.headers.length) + j} className={header.label}>
                                                                                            {
                                                                                                (header.type)
                                                                                                    ?
                                                                                                    null
                                                                                                    :
                                                                                                    row[header.field]

                                                                                            }
                                                                                            {
                                                                                              (header.type == "dateTime")
                                                                                                  ?
                                                                                                  moment(row[header.field]).format("DD MMM YY (HH:mm)")
                                                                                                  :
                                                                                                  null

                                                                                            }
                                                                                            {
                                                                                                (header.type == "date")
                                                                                                  ?
                                                                                                  moment(row[header.field]).format("DD MMM YY")
                                                                                                  :
                                                                                                  null
                                                                                            }
                                                                                        </td>
                                                                                    )
                                                                                }
                                                                            )
                                                                          }
                                                                        </tr>
                                                                    </Link>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    {
                                                        tablePadding.map(
                                                            (emptyRow,ei)=>{
                                                                return(
                                                                    <tr key={ei} className={"item " + ((item.content.length + ei)%2 == 0 ? "even" : "odd")}>
                                                                      {
                                                                        item.headers.map(
                                                                            (header,j)=>{
                                                                                return (
                                                                                    <td key={(ei*item.headers.length) + j}>
                                                                                            <div className="pad">NONE</div>
                                                                                    </td>
                                                                                )
                                                                            }
                                                                        )
                                                                    }
                                                                    </tr>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                            }
                        )
                    }
                </div>
            </PublicPageLayout>
        )
    }
}
