// src/components/Layout.js
import React from 'react';
import {Helmet,HelmetProvider} from 'react-helmet-async';
import Popup,{ComponentPopup,PopupGallery} from './Popup';
import PropTypes from 'prop-types';
import store from 'store';
import {CPHeader,Footer,CPGenerateSpreadsheet,LoadGraphic,Step} from './Common';
import {Button,PageSelector} from './Input';
import navButtons from '../data/PortalNavButtons';
import u from '../utilities/Utilities';
import moment from 'moment';
import queryString from 'query-string';

export default class PortalLayout extends React.Component {

    state={
        isLoading:true,
        navButtons:[],
    };

    componentDidMount=(e)=>{
        //Check if tempUser is not in tempLocation, send to tempLocation
        const tempUser=store.get("tempUser");
        const tempLocation=store.get("tempLocation");
        const tempQuery = store.get("tempQuery");
        const url = this.props.url;
        if(tempUser){
            if(tempLocation.substr(0,url.length) != url){
                window.location = tempLocation + ((tempQuery) ? tempQuery : "");
                return 0;
            }
        }

        u.post({
            url:"/client/cp-validate-token",
            data:{},
            success:(callback)=>{
                //Update cookies
                for(var key in callback){
                    store.set(key,callback[key]);
                }
                store.set("tokenTime",moment().format("YYYY-MM-DDTHH:mm:ss"));

                const hasPermission = true;//this.checkPermission(this.props.settings.permission,this.props.settings.combinedField,this.props.settings.altPermission,this.props.settings.altField);
                if(hasPermission){
                    this.setState({isLoading:false});
                }
                else {
                    store.set("dashboard-action", "No Permission");
                    store.set("dashboard-action-label", this.props.title);
                    store.set("redirect", window.location);
                    if (typeof window != "undefined") {
                        window.location = "/portal/dashboard";
                    }
                }
            },
            error:(error)=>{}
        });
        this.setState({isLoading:false});

        let nb=[];
        for(var i=0; i<navButtons.length; i++){
            if (this.checkPermission(navButtons[i].permission,navButtons[i].field,navButtons[i].altPermission,navButtons[i].altField)) {
                nb.push(navButtons[i]);
            }
        }
        this.setState({navButtons:nb});
    }

    checkPermission=(moduleID,combinedField,altModuleID,altField)=>{
        if(moduleID == "none"){
            return true;
        }
        const perms = u.getAllPermissions();
        let access = false;

        if(combinedField){
            if(perms[moduleID])
                access = (parseInt(perms[moduleID][combinedField]) == 2);
        }
        else{
            if(perms[moduleID])
                access = (parseInt(perms[moduleID].view) == 2);
        }

        if(!access){
            if(altField){
                if(perms[altModuleID])
                    access = (parseInt(perms[altModuleID][combinedField]) == 2);
            }
            else if(altModuleID){
                if(perms[altModuleID])
                    access = (parseInt(perms[altModuleID].view) == 2);
            }
        }

        return access;
    };

    onPopup=(content)=>{
        content.cancel=this.onCancelPopup;
        this.setState({popup:content});
    }

    onCancelPopup=()=>{
        this.setState({popup:undefined});
    }

    showPopup=(show)=>{
        this.refs['popup'].show(show);
    }

    setPopupComponent=(component)=>{
        this.refs['popup'].setComponent(component);
    }

    render=()=>{
        if(this.state.isLoading){
            return (
                <div className="app-load-container"></div>
            )
        }

        return (
            <HelmetProvider>
                <div className="page-container" style={this.props.style}>
                    <Helmet>
                        <title>{this.props.settings.title} | MWMD Portal</title>
                        <meta name="viewport" content="width=device-width,initial-scale=1"/>
                        <meta name="viewport" content="height=device-height,initial-scale=1"/>
                    </Helmet>
                    <div id="docu-top" className="document-top"/>
                    <CPHeader type="normal" 
                              hasNav={true} 
                              buttons={this.state.navButtons} 
                              current={this.props.settings.url} 
                              pageTitle={this.props.settings.title}
                              />
                    <div className="app-content">{this.props.children}</div>

                    {
                        (this.state.popup)
                            ?
                            <Popup content={this.state.popup}/>
                            :
                            null
                    }

                    <div className="no-print">
                        <Footer/>
                    </div>

                    <PopupGallery ref="gallery"/>

                    <ComponentPopup ref="popup"/>

                    <div id="tool-tip" className="tool-tip">
                        <div className="tool-tip-down-triangle"/>
                        <div id="tool-tip-text" className="tool-tip-text"/>
                        <div className="tool-tip-up-triangle"/>
                    </div>
                </div>
            </HelmetProvider>
        );
    }
}
PortalLayout.propTypes={
    settings:PropTypes.object.isRequired,
};

export class CPMiniList extends React.Component{

    state={
        content:[],
        pageNumber:1,
        pageCount:1,
        orderByField:(this.props.orderByField) ? this.props.orderByField : this.props.settings.orderByField,
        orderByType:(this.props.orderByType) ? this.props.orderByType : this.props.settings.orderByType,
        loading:false,
        loadText:"",
        step:"view",
        selected:{
            all:true,
            data:[],
            tags:[],
        },
        dimensions:{width:0,height:0},
    };

    componentDidMount=(e)=>{
        const listLabel = this.props.settings.listLabel;
        const settings = u.getListSettings(listLabel);
        this.setState({
            tags:this.props.tags,
            columnSettings:settings.columnSettings.filter((item)=>{return item.selected}).map((item,index,arr)=>{
                item.miniListWidth = this.calculateWidth(item.width,arr.length,listLabel) + "px";
                return item;
            }),
        });

        this.getContent(this.props.tags,this.state.orderByField,this.state.orderByType);
    }

    getContent=(tags,orderByField,orderByType)=>{
        this.setState({loading:true});
        u.post({
            url:this.props.settings.overviewURL,
            data:{
                tags:this.props.tags,
                rows:0,
                pageNumber:1,
                orderByField:orderByField,
                orderByType:orderByType,
            },
            success:(e)=>{
                let pageContent = e.pageContent;

                if(this.props.settings.timeMode){
                    let renderedDates=[];
                    for(var t=0; t<pageContent.length; t++){
                        const contentDate = moment(pageContent[t][this.props.settings.timeModeDateField]).format("YYYY-MM-DD");
                        if(renderedDates.indexOf(contentDate) == -1){
                            pageContent[t].showDate = true;
                            renderedDates.push(contentDate);
                        }
                    }
                }

                const numPages = Math.ceil(e.entryCount / this.props.pageLength);
                const currentPage = (this.state.pageNumber > numPages) ? numPages : this.state.pageNumber;
                const startIndex = (currentPage - 1) * this.props.pageLength;
                const endIndex = startIndex + this.props.pageLength - 1;

                this.setState({
                    content:pageContent,
                    entryCount:e.entryCount,
                    pageNumber:currentPage,
                    pageCount:numPages,
                    startIndex:startIndex,
                    endIndex:endIndex,
                    loading:false
                });
            },
            error:(e)=>{
                this.setState({error:e,loading:false});
            }
        });
    }

    onChangePage=(newPage)=>{
        const numPages = this.state.pageCount;
        if(newPage < 1){
            return 0;
        }
        else if(newPage > numPages){
            return 0;
        }
        else{
            const startIndex = (newPage - 1) * this.props.pageLength;
            const endIndex = startIndex + this.props.pageLength - 1;
            this.setState({
                pageNumber:newPage,
                startIndex:startIndex,
                endIndex:endIndex,
            });
        }
    }

    onSkipToPage=(value)=>{
        const numPages = parseInt(this.state.pageCount);
        if(value > numPages){
            value = numPages;
        }
        else if (value < 1){
            value = 1;
        }
        const newPage = parseInt(value);
        const startIndex = (newPage - 1) * this.props.pageLength;
        const endIndex = startIndex + this.props.pageLength - 1;
        this.setState({
            pageNumber:newPage,
            startIndex:startIndex,
            endIndex:endIndex,
        });
    }

    onSetContext=(listID)=>{
        if(this.props.settings.detailsLink){
            return 0;
        }
        store.set(this.props.settings.listLabel,"@id("+listID+")");
    }

    refresh=()=>{
        this.getContent(this.props.tags,this.state.orderByField,this.state.orderByType);
    }

    calculateWidth=(percentage,visibleArrayLength,listLabel)=>{
        const element = document.getElementById("mini-list-" + listLabel);
        const width = element.offsetWidth;
        return (width * (parseInt(percentage)/100.0));
    }

    render=()=>{
        const columns = this.state.columnSettings;
        const selected = {all:true,data:[],tags:this.props.tags,entryCount:this.state.entryCount};

        return (
            <div className="mini-list" id={"mini-list-" + this.props.settings.listLabel}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="overview-list-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                {
                    (this.state.content.length > 0)
                        ?
                        <div>
                            <div className="mini-list-table">
                                <table>
                                    <thead>
                                        <tr>
                                        {
                                            columns.map(
                                                (column,c,arr)=>{
                                                    const style = {width:column.miniListWidth, whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis", textAlign:((column.align) ? column.align : "left")};
                                                    return (
                                                        <th key={c} style={style} className={(c == 0) ? "top-left" : null}>{column.label}</th>
                                                    )
                                                }
                                            )
                                        }
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.content.map((item,index)=>{
                                                if(index > this.state.endIndex || index < this.state.startIndex){
                                                    return null;
                                                }
                                                return (
                                                    <tr key={index} className="item">
                                                        {
                                                            columns.map(
                                                                (column,c)=>{
                                                                    const content = item[column.field];
                                                                    const nullText=(column.nullText) ? column.nullText : "None";
                                                                    const style = {width:column.miniListWidth, whiteSpace:"nowrap",overflow:"hidden",textOverflow:"ellipsis", textAlign:((column.align) ? column.align : "left")};

                                                                    const isAddition = (typeof column.field == "string") ? (column.field.indexOf("+") != -1) : false;
                                                                    if(isAddition){
                                                                        const addedFields = (isAddition) ? column.field.split("+") : [];
                                                                        let addedValue = 0;
                                                                        for(var i=0; i<addedFields.length; i++){
                                                                            addedValue += parseInt(item[addedFields[i]]);
                                                                        }
                                                                        return (
                                                                            <td key={c} style={style}>{addedValue}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "dateTime"){
                                                                        return (
                                                                            <td key={c} style={style}>{ ((content) ? moment(content).format("DD MMM YY - hh:mma") : nullText)}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "date"){
                                                                        return (
                                                                            <td key={c} style={style}>{((content) ? moment(content).format("DD MMM YY") : nullText)}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "time"){
                                                                        const moddedContent = (content.indexOf("T") != -1) ? content : "2020-01-01T" + content;
                                                                        return (
                                                                            <td key={c} style={style}>{((content) ? moment(moddedContent).format("hh:mma") : nullText)}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "boolToInOut"){
                                                                        return (
                                                                            <td key={c} style={style}>{(content == "1" || content) ? "In" : "Out"}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "boolToYesNo"){
                                                                        return (
                                                                            <td key={c} style={style}>{(content == "1" || content) ? "Yes" : "No"}</td>
                                                                        )
                                                                    }

                                                                    if(column.type == "object"){
                                                                        return (
                                                                            <td key={c} style={style}>
                                                                            {
                                                                                    (item[column.field][column.typeReference])
                                                                                        ?
                                                                                        item[column.field][column.typeReference]
                                                                                        :
                                                                                        nullText
                                                                            }
                                                                            </td>
                                                                        )
                                                                    }

                                                                    if(column.type == "array"){
                                                                        return (
                                                                            <td key={c} style={style}>{
                                                                                (item[column.field])
                                                                                    ?
                                                                                    item[column.field].map(
                                                                                        (arrItem,index)=>{
                                                                                            return (
                                                                                                <span key={index}>{((index==0) ? "" :" | ") + arrItem}</span>
                                                                                            )
                                                                                        }
                                                                                    )
                                                                                    :
                                                                                    nullText
                                                                            }
                                                                            </td>
                                                                        )
                                                                    }

                                                                    if(column.type == "object-array"){
                                                                        return (
                                                                            <td key={c} style={style}>{
                                                                                (item[column.field])
                                                                                    ?
                                                                                    item[column.field].map(
                                                                                        (item,index)=>{
                                                                                            return (
                                                                                                <span key={index}>{((index==0) ? "" :" | ") + item[column.typeReference]}</span>
                                                                                            )
                                                                                        }
                                                                                    )
                                                                                    :
                                                                                    nullText
                                                                        }
                                                                            </td>
                                                                        )
                                                                    }

                                                                    return (
                                                                        <td key={c} style={style}>
                                                                            {
                                                                                (item[column.field] && (item[column.field]+"").trim().length > 0)
                                                                                    ?
                                                                                    item[column.field]
                                                                                    :
                                                                                    nullText
                                                                            }
                                                                        </td>
                                                                    )
                                                                }
                                                            )
                                                        }
                                                    </tr>
                                                )
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>

                                {
                                    (this.state.pageCount > 1)
                                        ?
                                        <PageSelector className="mini-list"
                                                    page={this.state.pageNumber}
                                                    maxPage={this.state.pageCount}
                                                    onChange={this.onChangePage}
                                                    />
                                        :
                                        ""
                                }

                            <div style={{margin:"20px auto 0 auto",textAlign:"center"}}>
                                <Button type="medium" onClick={()=>{this.setState({step:"download"})}}>Download</Button>
                            </div>
                        </div>
                        :
                        <div className="mini-list-placeholder">
                            <b>No Entries</b><br/>
                            Refresh page to reload list
                        </div>
                }
                </Step>

                <Step active={this.state.step == "download"}>
                    <CPGenerateSpreadsheet active={true}
                                           reference="download"
                                           selected={selected}
                                           settings={this.props.settings}
                                           onToggleForm={()=>{this.setState({step:"view",error:""})}}
                                           miniList={true}
                                           segmentStyle={{border:"none",padding:"0"}}
                                           ignoreScroll={true}
                    />
                </Step>
            </div>
        )
    }
}
CPMiniList.propTypes={
    settings:PropTypes.object.isRequired,
    tags:PropTypes.array.isRequired,
    pageLength:PropTypes.number.isRequired,
    detailsLink:PropTypes.string,
};
