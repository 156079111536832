import React from 'react';
import AppLayout from '../components/AppLayout';
import {Databox,LoadGraphic,Step} from '../components/Common';
import {Button,Date,Select,Checkbox,EditableTime,Box,Area} from '../components/Input';
import {Link} from 'react-router-dom';
import {PieChart} from './Reports';
import {Line} from 'react-chartjs-2';
import "../styles/vitals-dashboard.scss"
import moment from 'moment';
import u from '../utilities/Utilities';

export default class VitalsDashboard extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Vitals Dashboard",
        permission:"resident",  //Accepts combined permission module as well
        //combinedField:"",     //Used when permission exists in a combined field
        url:"/vitals-dashboard",
        //label:"resident",
    };

    state={
        loadText:"Preparing...",
        loading:true,
        readingOptions:[],
        sectorOptions:[{value:"all",label:"All Sectors"}],
        sector:"all",
        activeList:[],
        criticalList:[],
        complianceList:{
            list:[]
        }
    };

    poll=null;
    pollInterval=30;    //Seconds

    componentDidMount=()=>{
        this.dormID = this.props.match.params.dormID;
        this.getReadingConfig(this.dormID);
    }

    componentWillUnmount=()=>{
        if(this.poll){
            clearInterval(this.poll);
        }
    }

    getReadingConfig=(dormID)=>{
        this.setState({loading:true,loadText:"Acquiring dormitory configuration..."});
        u.post({
            url:"/api/get-vitals-reading-config",
            data:{
                dormID:dormID
            },
            success:({readingOptions,sectorOptions,current})=>{
                const date = moment().format("YYYY-MM-DD");
                sectorOptions.unshift({value:"all",label:"All Sectors"});
                this.setState({date:date,readingOptions:readingOptions,vitalsType:current,sectorOptions:sectorOptions});
                this.getVitalData();
            },
            error:(error)=>{
                this.setState({error:error,loadText:error});
            }
        });
    }

    getVitalData=(date,vitalsType,sector)=>{
        if(typeof date == "undefined"){
            date = this.state.date;
        }
        if(typeof vitalsType == "undefined"){
            vitalsType = this.state.vitalsType
        }
        if(typeof sector == "undefined"){
            sector = this.state.sector;
        }

        u.post({
            url:"/api/get-vitals-dashboard",
            data:{
                dormID:this.dormID,
                date:date,
                vitalsType:vitalsType,
                sector:sector,
            },
            success:({vitalsConfig,criticalList,complianceList})=>{
                console.log(complianceList);
                this.setState({
                    vitalsConfig:vitalsConfig,
                    criticalList:criticalList,
                    complianceList:complianceList,
                    loading:false,
                });
            },
            error:(error)=>{
                this.setState({error:error,loadText:error,loading:false});
            }
        });
    }

    onPollData=()=>{
        if(this.poll){
            clearInterval(this.poll);
        }
        this.getVitalData();
    }

    onChange=(value,label,index,option)=>{
        if(this.poll){
            clearInterval(this.poll);
        }
        this.setState({loading:true,loadText:"Preparing..."});
        if(label == "date"){
            this.setState({date:value});
            this.getVitalData(value);
        }
        else if (label == "vitalsType"){
            this.setState({vitalsType:value});
            this.getVitalData(undefined,value);
        }
        else if(label == "sector"){
            this.setState({sector:value});
            this.getVitalData(undefined,undefined,value);
        }
    }

    onChangeCriticalList=(value,label)=>{
        this.setState({criticalList:value});
    }

    onChangeCompliance=(value)=>{
        let complianceList = this.state.complianceList;
        complianceList.list = value;
        this.setState({complianceList:complianceList});
    }

    render=()=>{
        return (
            <AppLayout settings={this.settings}>
                <div style={{position:"relative",textAlign:"center"}}>
                    <h2>Vitals Dashboard</h2>
                    <div className="db-container">
                        <div className="db-inner" style={{padding:"0 10px 0 0"}}>
                            <Date values={{value:this.state.date}} label="Date" field="date" range={[1,1]} onChange={this.onChange}/>
                        </div>

                        <div className="db-inner" style={{padding:"0 5px 0 5px"}}>
                            <Select values={{value:this.state.sector,options:this.state.sectorOptions}} label="Sector" field="sector" onChange={this.onChange}/>
                        </div>

                        <div className="db-inner" style={{padding:"0 0 0 10px"}}>
                            <Select values={{value:this.state.vitalsType,options:this.state.readingOptions}} label="Reading" field="vitalsType" onChange={this.onChange}/>
                        </div>
                    </div>

                    <div>
                        <ComplianceGraph content={this.state.complianceList} date={this.state.date} vitalsType={this.state.vitalsType} loading={this.state.loading} loadText={this.state.loadText}/>
                    </div>

                    <div style={{margin:"50px auto"}}>
                        <ComplianceList dormID={this.dormID} content={this.state.complianceList.list} onChange={this.onChangeCompliance} date={this.state.date} vitalsType={this.state.vitalsType} loading={this.state.loading} loadText={this.state.loadText}/>
                    </div>

                    <div style={{margin:"50px auto"}}>
                        <CriticalList dormID={this.dormID} content={this.state.criticalList} vitalsConfig={this.state.vitalsConfig} date={this.state.date} vitalsType={this.state.vitalsType} onChange={this.onChangeCriticalList} loading={this.state.loading} loadText={this.state.loadText}/>
                    </div>
                </div>
            </AppLayout>
        )
    }
}

class CriticalList extends React.Component{

    state={
        entryData:{},
        error:"",
        loading:false,
        loadText:"",
        selectAll:false,
        step:"view",
        activeList:[],
        app:"All",
        nationality:"All",
        zone:"All",
        nationalityOptions:[],
        zoneOptions:[],
        currentPage:1,
        maxPage:10,
        linesPerPage:10,
    }

    contentLabel="criticalList";
    notifyAPI="/api/update-vitals-notified";
    appOptions=[{value:"All",label:"App & No App"},{value:"Yes",label:"App Registered"},{value:"No",label:"App Not Registered"}];

    static getDerivedStateFromProps=(props,state)=>{
        let nationality={};
        let zones={};
        const activeList = props.content.map(
            (item,index)=>{
                nationality[item.nationality] = true;
                zones[item.zone] = true;
                item.index = index;
                return item;
            }
        ).filter((item)=>{
            return ((state.app == "All") || (state.app == item.app)) && ((state.nationality == "All") || (state.nationality == item.nationality))
                    && ((state.zone == "All") || (state.zone == item.zone));
        });

        let nationalityOptions=[];
        for(var key in nationality){
            nationalityOptions.push({value:key,label:key});
        }
        nationalityOptions.sort((a,b)=>{
            return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        }).unshift({value:"All",label:"All Nationality"});

        let zoneOptions=[];
        for(var key in zones){
            zoneOptions.push({value:key,label:key});
        }
        zoneOptions.sort((a,b)=>{
            return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        }).unshift({value:"All",label:"All Zones"});

        state.activeList=activeList;
        state.nationalityOptions=nationalityOptions;
        state.zoneOptions=zoneOptions;

        state.maxPage= Math.ceil(activeList.length / state.linesPerPage);
        state.indexMin = (state.currentPage - 1) * state.linesPerPage;
        state.indexMax = state.currentPage * state.linesPerPage;

        return state;
    }

    onSelectAll=(value)=>{
        let content=this.props.content.map((item)=>{
            item.selected = value;
            return item
        });
        this.setState({selectAll:value});
        this.props.onChange(content,this.contentLabel);
    }

    onSelect=(value,label,index)=>{
        let content=this.props.content;
        content[index].selected=value;
        this.props.onChange(content,this.contentLabel);
    }

    onChangeNotified=(value,label,index)=>{
        let content=this.props.content;
        content[index][label]=value;
        this.props.onChange(content,this.contentLabel);
    }

    onChangeEntry=(value,label,index)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onFire=()=>{
        this.setState({error:""});
        const targets = this.state.activeList;
        if(targets.length == 0){
            this.setState({error:"Please select at least 1 target from the previous list"});
            return 0;
        }

        const keys=["subject","message"];
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.setState({loading:true,loadText:"Sending Notifications...."});
        const entryData = this.state.entryData;

        u.post({
            url:"/api/fire-vitals-notification",
            data:{
                dormID:this.props.dormID,
                targets:targets,
                subject:entryData.subject,
                message:entryData.message,
            },
            success:()=>{
                let content=this.props.content;
                for(var i=0; i<this.state.activeList.length; i++){
                    const item = this.state.activeList[i];
                    content[item.index].lastNotified=moment().format("HH:mm:ss");
                }
                this.props.onChange(content,this.contentLabel);
                this.setState({step:"ack",error:"",loading:false,selectAll:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDownload=()=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.post({
            url:"/api/get-vitals-report",
            data:{
                dormID:this.props.dormID,
                date:this.props.date,
                type:this.props.vitalsType,
                app:this.state.app,
                zone:this.state.zone,
                nationality:this.state.nationality,
            },
            success:({filename,filestamp})=>{
                u.download(filename,filestamp,()=>{
                    this.setState({loading:false,entryData:{}});
                },(error)=>{
                    this.setState({loading:false,error:error});
                })
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    getProblem=(item)=>{
        const config = this.props.vitalsConfig;
        let first = true;
        let problem="";

        const badTemp = (item.temperature) ? (parseFloat(item.temperature) >= parseFloat(config.temperatureMax) || parseFloat(item.temperature) <= parseFloat(config.temperatureMin)) : false;
        const badSBP = (item.systolicBP) ? (parseInt(item.systolicBP) <= parseInt(config.sysMin) || parseInt(item.systolicBP) >= parseInt(config.sysMax)) : false;
        const badDBP = (item.diastolicBP) ? (parseInt(item.diastolicBP) <= parseInt(config.diaMin) || parseInt(item.diastolicBP) >= parseInt(config.diaMax)) : false;
        const badOXY = (item.oxygenSaturation) ? (parseInt(item.oxygenSaturation) <= parseInt(config.oxygenMin) || parseInt(item.oxygenSaturation) >= parseInt(config.oxygenMax)) : false;
        const badPulse = (item.pulse) ? (parseInt(item.pulse) <= parseInt(config.pulseMin) || parseInt(item.pulse) >= parseInt(config.pulseMax)) : false;

        if(config.temperature && badTemp){
            problem = problem + (!first ? ", " : "") + " Temp: " + item.temperature;
            first=false;
        }
        if(config.bloodPressure && (badSBP || badDBP)){
            problem = problem + (!first ? ", " : "") + "Blood Pressure: "+item.systolicBP + "/" + item.diastolicBP;
            first=false;
        }
        if(config.oxygenSaturation && badOXY){
            problem = problem + (!first ? ", " : "") + "SpO2: "+item.oxygenSaturation;
            first=false;
        }
        if(config.pulse && badPulse){
            problem = problem + (!first ? ", " : "") + "Pulse: "+item.pulse;
            first=false;
        }
        if(config.breathingDifficulty && item.breathingDifficulty){
            problem = problem + (!first ? ", " : "") + "Breathing Difficulty";
            first=false;
        }
        if(config.chestPain && item.chestPain){
            problem = problem + (!first ? ", " : "") + "Chest Pains";
            first=false;
        }
        if(config.requiresAttention && item.requiresAttention){
            problem = problem + (!first ? ", " : "") + "Requires Attention";
            first=false;
        }
        return problem;
    }

    onChangeSearch=(value,label)=>{
        if(label == "nationality"){
            this.setState({nationality:value});
        }
        if(label == "app"){
            this.setState({app:value});
        }
        if(label == "zone"){
            this.setState({zone:value});
        }
    }

    onChangePage=(dir)=>{
        if(dir == "left"){
            if(this.state.currentPage == 1){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage-1});
            }
        }
        else if(dir == "right"){
            if(this.state.currentPage == this.state.maxPage){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage+1});
            }
        }
    }

    render(){
        const content = this.state.activeList;
        const entryData = this.state.entryData;
        return(
            <Databox active={true} title="Critical List" margin={"0"}>
                <div className="active-list">
                <LoadGraphic active={this.props.loading || this.state.loading} text={(this.props.loading) ? this.props.loadText : this.state.loadText}/>
                    <Step active={this.state.step=="view"}>
                        <div>
                            <div className="db-container">
                                <div className="db-inner" style={{padding:"0 10px 0 0"}}>
                                    <Select values={{value:this.state.zone,options:this.state.zoneOptions}} label="zone" onChange={this.onChangeSearch}/>
                                </div>

                                <div className="db-inner" style={{padding:"0 5px 0 5px"}}>
                                    <Select values={{value:this.state.nationality,options:this.state.nationalityOptions}} label="nationality" onChange={this.onChangeSearch}/>
                                </div>

                                <div className="db-inner" style={{padding:"0 0 0 10px"}}>
                                    <Select values={{value:this.state.app,options:this.appOptions}} label="app" onChange={this.onChangeSearch}/>
                                </div>
                            </div>
                            {
                                (content.length > 0)
                                    ?
                                    <div>
                                        <div style={{margin:"20px auto"}}>
                                            <table style={{position:"relative",textAlign:"center",width:"100%"}}>
                                                <tbody>
                                                    <tr className="active-row">
                                                        <th style={{textAlign:"left"}}>Time</th>
                                                        <th style={{padding:"0 10px",textAlign:"left"}}>Resident</th>
                                                        <th style={{padding:"0 10px",textAlign:"left"}}>Problem</th>
                                                        <th>Contact Number</th>
                                                        <th>Nationality</th>
                                                        <th>Zone</th>
                                                        <th>App</th>
                                                        <th style={{width:"177px"}}>Notified</th>
                                                    </tr>
                                                    {
                                                        content.map(
                                                            (item,index)=>{
                                                                if(index<this.state.indexMin || index >= this.state.indexMax){
                                                                    return null;
                                                                }
                                                                const problem = this.getProblem(item);
                                                                const id={tenantID:item.tenantID,dormID:this.props.dormID,entry:item};
                                                                const url=this.notifyAPI;
                                                                return(
                                                                    <tr key={index} className="active-row">
                                                                        <td style={{textAlign:"left"}}>{moment(item.datetime).format("h:mma")}</td>
                                                                        <td style={{padding:"0 10px",textAlign:"left"}}><Link to={"/tenant/"+this.props.dormID+"/"+item.tenantID}>{item.tenantName}</Link></td>
                                                                        <td style={{padding:"0 10px",textAlign:"left"}}>{problem}</td>
                                                                        <td>{item.contactNumber}</td>
                                                                        <td>{item.nationality}</td>
                                                                        <td>{item.zone}</td>
                                                                        <td>{item.app}</td>
                                                                        <td style={{width:"177px"}}>
                                                                            <EditableTime values={{value:item.lastNotified}} field="lastNotified" label="Notified"
                                                                                        rules={{skipSeconds:true,nullText:"None"}}
                                                                                        index={index+""} url={url} id={id} onChange={this.onChangeNotified}/>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                        {
                                            (this.state.maxPage > 1)
                                            ?
                                            <div className="db-page-container">
                                                <img className="db-arrow" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("left")}}/>
                                                <div className="db-page">Page {this.state.currentPage} / {this.state.maxPage}</div>
                                                <img className="db-arrow right" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("right")}}/>
                                            </div>
                                            :
                                            ""
                                        }

                                        <div className="db-buttons">
                                            <Button type="medium" onClick={this.onDownload}>Download Spreadsheet</Button>
                                            <Button type="medium" onClick={()=>{this.setState({step:"notify"})}}>Fire Notification</Button>
                                        </div>
                                    </div>
                                    :
                                    <div className="active-list-placeholder" style={{padding:"20px 0"}}>
                                        <i>
                                            No Critical Vitals Identified
                                        </i>
                                    </div>
                            }
                        </div>
                    </Step>

                    <Step active={this.state.step=="notify"}>
                        <div className="db-common">
                            <h3>Notifying <span className="text-emphasis">{content.length}</span> targets</h3>
                            <div>
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Subject:</div>
                                    <div className="segment-item-input">
                                        <Box ref="subject" values={{value:entryData.subject}} label="Subject" field="subject" rules={{required:true}} onChange={this.onChangeEntry}/>
                                    </div>
                                </div>
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Message:</div>
                                    <div className="segment-item-input">
                                        <Area ref="message" values={{value:entryData.message}} label="Message" field="message" rules={{required:true,rows:5}} onChange={(this.onChangeEntry)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="db-buttons">
                                <div className="page-error">{this.state.error}</div>
                                <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                                <Button type="medium" onClick={this.onFire}>Fire</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step=="ack"}>
                        <div className="db-common">
                            <h3 className="text-emphasis">Notification Successfully Sent</h3>
                            <div>Note. Only residents with a registered app will receive notification & have notified updated</div>
                            <div className="db-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Continue</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Databox>
        )
    }
}

class ComplianceList extends React.Component{

    state={
        entryData:{},
        error:"",
        loading:false,
        loadText:"",
        selectAll:false,
        step:"view",
        activeList:[],
        app:"All",
        nationality:"All",
        zone:"All",
        nationalityOptions:[],
        zoneOptions:[],
        currentPage:1,
        maxPage:1,
        linesPerPage:10,
    }

    contentLabel="complianceList";
    notifyAPI="/api/update-vitals-notified";
    appOptions=[{value:"All",label:"App & No App"},{value:"Yes",label:"App Registered"},{value:"No",label:"App Not Registered"}];

    static getDerivedStateFromProps=(props,state)=>{
        let nationality={};
        let zones={};
        const activeList = props.content.map(
            (item,index)=>{
                nationality[item.nationality] = true;
                zones[item.zone] = true;
                item.index = index;
                return item;
            }
        ).filter((item)=>{
            return ((state.app == "All") || (state.app == item.app)) && ((state.nationality == "All") || (state.nationality == item.nationality))
                    && ((state.zone == "All") || (state.zone == item.zone));
        });

        let nationalityOptions=[];
        for(var key in nationality){
            nationalityOptions.push({value:key,label:key});
        }
        nationalityOptions.sort((a,b)=>{
            return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        }).unshift({value:"All",label:"All Nationality"});

        let zoneOptions=[];
        for(var key in zones){
            zoneOptions.push({value:key,label:key});
        }
        zoneOptions.sort((a,b)=>{
            return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);
        }).unshift({value:"All",label:"All Zones"});

        state.activeList=activeList;
        state.nationalityOptions=nationalityOptions;
        state.zoneOptions=zoneOptions;

        state.maxPage= Math.ceil(activeList.length / state.linesPerPage);
        state.indexMin = (state.currentPage - 1) * state.linesPerPage;
        state.indexMax = state.currentPage * state.linesPerPage;

        return state;
    }

    /*onSelectAll=(value)=>{
        let content=this.props.content.map((item)=>{
            item.selected = value;
            return item
        });
        this.setState({selectAll:value});
        this.props.onChange(content,this.contentLabel);
    }

    onSelect=(value,label,index)=>{
        let content=this.props.content;
        content[index].selected=value;
        this.props.onChange(content,this.contentLabel);
    }*/

    onChangeNotified=(value,label,index)=>{
        let content=this.props.content;
        content[index][label]=value;
        this.props.onChange(content,this.contentLabel);
    }

    onChangeEntry=(value,label,index)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;
        this.setState({entryData:entryData});
    }

    onFire=()=>{
        this.setState({error:""});
        const targets = this.state.activeList;
        if(targets.length == 0){
            this.setState({error:"Please select at least 1 target from the previous list"});
            return 0;
        }

        const keys=["subject","message"];
        for(var i=0; i<keys.length; i++){
            const current = this.refs[keys[i]].validate();
            if(!current.valid){
                this.setState({error:current.value});
                return 0;
            }
        }

        this.setState({loading:true,loadText:"Sending Notifications...."});
        const entryData = this.state.entryData;

        u.post({
            url:"/api/fire-vitals-notification",
            data:{
                dormID:this.props.dormID,
                targets:targets,
                subject:entryData.subject,
                message:entryData.message,
            },
            success:()=>{
                let content=this.props.content;
                for(var i=0; i<this.state.activeList.length; i++){
                    const item = this.state.activeList[i];
                    content[item.index].lastNotified=moment().format("HH:mm:ss");
                }
                this.props.onChange(content,this.contentLabel);
                this.setState({step:"ack",error:"",loading:false,selectAll:false});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onDownload=()=>{
        this.setState({loading:true,loadText:"Downloading..."});
        u.post({
            url:"/api/download-vitals-no-submit",
            data:{
                dormID:this.props.dormID,
                date:this.props.date,
                vitalsType:this.props.vitalsType,
                app:this.state.app,
                nationality:this.state.nationality,
                zone:this.state.zone
            },
            success:({filename,filestamp})=>{
                u.download(filename,filestamp,()=>{
                    this.setState({loading:false,entryData:{}});
                },(error)=>{
                    this.setState({loading:false,error:error});
                });
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        });
    }

    onChangeSearch=(value,label)=>{
        if(label == "nationality"){
            this.setState({nationality:value});
        }
        if(label == "app"){
            this.setState({app:value});
        }
        if(label == "zone"){
            this.setState({zone:value});
        }
    }

    onChangePage=(dir)=>{
        if(dir == "left"){
            if(this.state.currentPage == 1){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage-1});
            }
        }
        else if(dir == "right"){
            if(this.state.currentPage == this.state.maxPage){
                return 0;
            }
            else{
                this.setState({currentPage:this.state.currentPage+1});
            }
        }
    }

    render(){
        const content = this.state.activeList;
        const entryData = this.state.entryData;

        return(
            <Databox active={true} title="No Submission List" margin={"0"}>
                <div className="active-list">
                    <LoadGraphic active={this.props.loading || this.state.loading} text={(this.props.loading) ? this.props.loadText : this.state.loadText}/>
                    <Step active={this.state.step=="view"}>
                        <div>
                            <div className="db-container">
                                <div className="db-inner" style={{padding:"0 10px 0 0"}}>
                                    <Select values={{value:this.state.zone,options:this.state.zoneOptions}} label="zone" onChange={this.onChangeSearch}/>
                                </div>

                                <div className="db-inner" style={{padding:"0 5px 0 5px"}}>
                                    <Select values={{value:this.state.nationality,options:this.state.nationalityOptions}} label="nationality" onChange={this.onChangeSearch}/>
                                </div>

                                <div className="db-inner" style={{padding:"0 0 0 10px"}}>
                                    <Select values={{value:this.state.app,options:this.appOptions}} label="app" onChange={this.onChangeSearch}/>
                                </div>
                            </div>
                        {
                            (content.length > 0)
                                ?
                                <div>
                                    <div style={{margin:"20px auto"}}>
                                        <table style={{position:"relative",textAlign:"center",width:"100%"}}>
                                            <tbody>
                                                <tr className="active-row">
                                                    <th style={{padding:"0 10px",textAlign:"left"}}>Resident</th>
                                                    <th>Contact Number</th>
                                                    <th>Check-In</th>
                                                    <th>Check-Out</th>
                                                    <th>Nationality</th>
                                                    <th>Zone</th>
                                                    <th>App</th>
                                                    <th style={{width:"177px"}}>Notified</th>
                                                </tr>
                                                {
                                                    content.map(
                                                        (item,index)=>{
                                                            if(index<this.state.indexMin || index >= this.state.indexMax){
                                                                return null;
                                                            }
                                                            const id={tenantID:item.tenantID,dormID:this.props.dormID,entry:item};
                                                            const url=this.notifyAPI;
                                                            const enrollToday = moment().format("DD-MM-YYYY") == moment(item.enrollmentDate).format("DD-MM-YYYY");
                                                            const enrollYest = moment().subtract(1,"days").format("DD-MM-YYYY") == moment(item.enrollmentDate).format("DD-MM-YYYY");
                                                            const outToday = moment().format("DD-MM-YYYY") == moment(item.advanceCheckoutDate).format("DD-MM-YYYY");
                                                            const outYest = moment().subtract(1,"days").format("DD-MM-YYYY") == moment(item.advanceCheckoutDate).format("DD-MM-YYYY");
                                                            return(
                                                                <tr key={index} className="active-row">
                                                                    <td style={{padding:"0 10px",textAlign:"left"}}><Link to={"/tenant/"+this.props.dormID+"/"+item.tenantID}>{item.tenantName}</Link></td>
                                                                    <td>{item.contactNumber}</td>
                                                                    <td>{(item.enrollmentDate) ? ((enrollToday) ? "Today" : ((enrollYest) ? "Yesterady" : moment(item.enrollmentDate).format("DD MMM YYYY"))) : "None"}</td>
                                                                    <td>{(item.advanceCheckoutDate) ? ((outToday) ? "Today" : ((outYest) ? "Yesterady" : moment(item.advanceCheckoutDate).format("DD MMM YYYY"))) : "None"}</td>
                                                                    <td>{item.nationality}</td>
                                                                    <td>{item.zone}</td>
                                                                    <td>{item.app}</td>
                                                                    <td style={{width:"177px"}}>
                                                                        <EditableTime values={{value:item.lastNotified}} field="lastNotified" label="Notified"
                                                                                    rules={{skipSeconds:true,nullText:"None"}}
                                                                                    index={item.index+""} url={url} id={id} onChange={this.onChangeNotified}/>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    {
                                        (this.state.maxPage > 1)
                                        ?
                                        <div className="db-page-container">
                                            <img className="db-arrow" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("left")}}/>
                                            <div className="db-page">Page {this.state.currentPage} / {this.state.maxPage}</div>
                                            <img className="db-arrow right" src="/images/input/arrow-left.png" onClick={()=>{this.onChangePage("right")}}/>
                                        </div>
                                        :
                                        ""
                                    }

                                    <div className="db-buttons">
                                        <Button type="medium" onClick={this.onDownload}>Download Spreadsheet</Button>
                                        <Button type="medium" onClick={()=>{this.setState({step:"notify"})}}>Fire Notification</Button>
                                    </div>
                                </div>
                                :
                                <div className="active-list-placeholder" style={{padding:"20px 0"}}>
                                    <i>
                                        All Residents Have Submitted
                                    </i>
                                </div>

                        }
                        </div>
                    </Step>

                    <Step active={this.state.step=="notify"}>
                        <div className="db-common">
                            <h3>Notifying <span className="text-emphasis">{content.length}</span> targets</h3>
                            <div>
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Subject:</div>
                                    <div className="segment-item-input">
                                        <Box ref="subject" values={{value:entryData.subject}} label="Subject" field="subject" rules={{required:true}} onChange={this.onChangeEntry}/>
                                    </div>
                                </div>
                                <div className="segment-full-item">
                                    <div className="segment-item-label">Message:</div>
                                    <div className="segment-item-input">
                                        <Area ref="message" values={{value:entryData.message}} label="Message" field="message" rules={{required:true,rows:5}} onChange={(this.onChangeEntry)}/>
                                    </div>
                                </div>
                            </div>
                            <div className="db-buttons">
                                <div className="page-error">{this.state.error}</div>
                                <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                                <Button type="medium" onClick={this.onFire}>Fire</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step=="ack"}>
                        <div className="db-common">
                            <h3 className="text-emphasis">Notification Successfully Sent</h3>
                            <div>Note. Only residents with a registered app will receive notification & have notified updated</div>
                            <div className="db-buttons">
                                <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Continue</Button>
                            </div>
                        </div>
                    </Step>
                </div>
            </Databox>
        )
    }
}

const defaultColor="#07abf2";
const nosubmitColor="#fd3c77";
const dualColors=["#3A98D6","#3b3b3c"];//["#3b3b3c","#fd3c77"];
const multiColors=[
    "#7EB7DD","#3A98D6","#2C74A3","#173D57","#314857",  //Blues
    "#3D5E48","#215E35","#6FAB83","#50E381","#90DFAA",  //Greens
    "#FDC3B6","#FD8469","#C96A55","#7D4234","#7D605A",  //Oranges
];
class ComplianceGraph extends React.Component{
    state={
        content:this.props.content,
        graphData:{
            datasets:[],
            labels:[],
        },
        graphOptions:{
            showLines:true,
            spanGaps:false,
            maintainAspectRatio:false,
            plugins:{
              legend:{
                  display:true,
                  labels:{
                      boxWidth: 40,
                      padding: 20,
                  }
              },
              datalabels:{
                  display:false,
              }
            },

            scales:{
                x:{
                    autoSkip: true,
                    autoSkipPadding: 20,
                },
                y:{
                    ticks: {
                        callback: function(value, index, values) {
                            if(value >= 1000000){
                                let v = value / 1000000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }
                                return v + 'M';
                            }
                            else if(value >= 10000){
                                let v = value / 1000.00;
                                if(v % 1 != 0){
                                    v = Math.round(v * 100)/100.00
                                }
                                else{
                                    v = Math.round(v);
                                }

                                return v + "k";
                            }
                            else{
                                return value;
                            }
                        }
                    }
                }
            },
            tooltips:{
                callbacks:{
                    label:(toolTipItem,data)=>{
                        var label = data.datasets[toolTipItem.datasetIndex].label;
                        var percentage=data.datasets[toolTipItem.datasetIndex].percentage[toolTipItem.index];
                        if(label){
                            label +=": ";
                        }
                        label += toolTipItem.value + " (" +percentage + "%)";
                        return label;
                    }
                }
            }
        },
        nationality:{
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
        },
        app:{
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
        },
        notified:{
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
        },
        zone:{
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
        },
    };

    static getDerivedStateFromProps=(props,state)=>{
        if(props.content != state.content){
            state.update=true;
            state.content=props.content;
        }
        return state;
    }

    componentDidUpdate=()=>{
        if(this.state.update){
            this.constructGraphs(this.state.content);
            this.setState({update:false});
        }
    }

    constructGraphs=(content)=>{
        const pieData = this.calculatePieCharts(content.list);
        const graph = this.calculateGraph(content);

        const emptyPie = {
            showLegend: false,
            backgroundColor: ["#eaeaea"],
            data: [1],
            labels: ["No Data"],
            tooltips: false,
            pie:true,
        };

        this.setState({
            graphData:{
                datasets:[graph.submit,graph.nosubmit],
                labels:content.labels
            },
            nationality:(!pieData.ntnEmpty) ? {
                showLegend: false,
                backgroundColor: multiColors,
                data: pieData.ntnData,
                labels: pieData.ntnLabels,
                tooltips: true,
            } : emptyPie,
            app:(!pieData.appEmpty) ?{
                showLegend: false,
                backgroundColor: dualColors,
                data: pieData.app,
                labels: ["Yes","No"],
                tooltips: true,
            } : emptyPie,
            notified:(!pieData.notifiedEmpty) ? {
                showLegend: false,
                backgroundColor: dualColors,
                data: pieData.notified,
                labels: ["Yes","No"],
                tooltips: true,
            } : emptyPie,
            zone:(!pieData.zoneEmpty) ? {
                showLegend: false,
                backgroundColor: multiColors,
                data: pieData.zoneData,
                labels: pieData.zoneLabels,
                tooltips: true,
            } : emptyPie,
        });
    }

    calculateGraph=(content)=>{

        let pSubmit = [];
        let pNosubmit = [];
        for(var i=0; i<content.submit.length; i++){
            const submitPercentage= Math.round((content.submit[i] / (content.submit[i] + content.nosubmit[i]))*100);
            const nosubmitPercentage = 100 - submitPercentage;
            pSubmit.push(submitPercentage);
            pNosubmit.push(nosubmitPercentage);
        }

        const submitColor=u.hexToRgb(defaultColor);
        let submit={
            borderWidth:1,
            lineType:"solid",
            fill:false,
            borderColor:submitColor.hex,
            backgroundColor:"rgba("+submitColor.r+","+submitColor.g+","+submitColor.b+",0.5)",
            pointBackgroundColor:submitColor.hex,
            showPointValue:true,
            pointBorderColor:submitColor.hex,
            pointBorderWidth:1,
            pointHoverRadius:4,
            pointHoverBorderWidth:2,
            lineTension:0,
            label:"Completed",
            data:content.submit,
            percentage:pSubmit,
        };

        const noSubmitColor=u.hexToRgb(nosubmitColor);
        let noSubmit={
            borderWidth:1,
            lineType:"solid",
            fill:false,
            borderColor:noSubmitColor.hex,
            backgroundColor:"rgba("+noSubmitColor.r+","+noSubmitColor.g+","+noSubmitColor.b+",0.5)",
            pointBackgroundColor:noSubmitColor.hex,
            showPointValue:true,
            pointBorderColor:noSubmitColor.hex,
            pointBorderWidth:1,
            pointHoverRadius:4,
            pointHoverBorderWidth:2,
            lineTension:0,
            label:"No Submission",
            data:content.nosubmit,
            percentage:pNosubmit,
        };
        return {submit:submit,nosubmit:noSubmit};
    }

    calculatePieCharts=(list)=>{
        let nationality = {};
        let app=[0,0];
        let appEmpty = true;
        let notified=[0,0];
        let notifiedEmpty=true;
        let ntnEmpty=true;
        let zones={};
        let zoneEmpty=true;
        for(var i=0; i<list.length; i++){
            const item = list[i];
            if(item.app == "Yes"){
                app[0]= app[0]+1;
                appEmpty=false;
            }
            else
            {
                app[1]=app[1]+1;
                appEmpty=false;
            }
            if(typeof item.lastNotified != "undefined"){
                notified[0]=notified[0]+1;
                notifiedEmpty=false;
            }
            else{
                notified[1]=notified[1]+1;
                notifiedEmpty=false;
            }
            if(nationality[item.nationality]){
                nationality[item.nationality]=nationality[item.nationality]+1;
                ntnEmpty=false;
            }
            else{
                nationality[item.nationality] = 1;
                ntnEmpty=false;
            }
            if(zones[item.zone]){
                zones[item.zone]=zones[item.zone]+1;
                zoneEmpty=false;
            }
            else{
                zones[item.zone] = 1;
                zoneEmpty=false;
            }
        }

        let ntnSort=[];
        for(var key in nationality){
            ntnSort.push({value:nationality[key],label:key});
        }
        ntnSort.sort((a,b)=>{
            return b.value-a.value;
        });

        let zoneSort=[];
        for(var key in zones){
            zoneSort.push({value:zones[key],label:key});
        }
        zoneSort.sort((a,b)=>{
            return b.value-a.value;
        });

        const ntnLabels = ntnSort.map(item=>{return item.label});
        const ntnData = ntnSort.map(item=>{return item.value});
        const zoneLabels = zoneSort.map(item=>{return item.label});
        const zoneData = zoneSort.map(item=>{return item.value});

        const pieData = {
            app:app,
            appEmpty:appEmpty,
            notified:notified,
            notifiedEmpty:notifiedEmpty,
            ntnLabels:ntnLabels,
            ntnData:ntnData,
            ntnEmpty:ntnEmpty,
            zoneLabels:zoneLabels,
            zoneData:zoneData,
            zoneEmpty:zoneEmpty
        };
        return pieData;
    }

    render=()=>{
        console.log(this.state.nationality);
        return(
            <Databox active={true} title="Compliance" margin={"0"}>
                <div style={{textAlign:"center"}}>
                    <LoadGraphic active={this.props.loading} text={this.props.loadText}/>

                    <div className="compliance-graph-container">
                        <Line data={this.state.graphData}
                              options={this.state.graphOptions}
                              />
                    </div>

                    <div className="compliance-pie-container">
                        <h2>No Submission Breakdown</h2>
                        <div className="compliance-pie">
                            <h3>Nationatilty</h3>
                            <div className="compliance-pie-image">
                                <PieChart content={this.state.nationality}
                                          isEmpty={Math.max(this.state.nationality.data) == 0}
                                          />
                            </div>
                        </div>
                        <div className="compliance-pie">
                            <h3>Zone</h3>
                            <div className="compliance-pie-image">
                                <PieChart content={this.state.zone}/>
                            </div>
                        </div>
                        <div className="compliance-pie">
                            <h3>App Installed</h3>
                            <div className="compliance-pie-image">
                                <PieChart content={this.state.app}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Databox>
        )
    }
}
