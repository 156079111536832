import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,
        DynamicListArea,Dropzone,DownloadTemplate,LoadGraphic,Step,
        Segment,SegmentComplete,ResolutionStack} from '../components/Common';
import {Button,Select,Area,Box,EditSizeLabel,Date,Time,
        EmptyBox,EditSizeLink,TextButton,ContactNumber,
        Checkbox,InputPhoto,Info,MiniPlusButton} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Photo} from "../data/Mimetypes";
import {Link} from 'react-router-dom';
import store from 'store';
import "../styles/task-report.scss";

export default class TaskList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Tasks",
        permission:"task",
        url:"/task-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-task-entry",
        deleteURL:"/api/delete-task-entries",
        spreadsheetURL:"/api/generate-task-spreadsheet",
        overviewURL:"/api/get-all-task-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"task-list",
        orderByField:"deadline",
        orderByType:"ascending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        add:{component:AddTask,props:{}},
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
        options:{},
    };

    componentDidMount=()=>{
        this.getOptions();
        const permissions = u.getPermissions("task");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Add Entry",ref:"add",title:"Add new entry",enabled:(permissions.create==2)},
              {label:"Delete Entries",ref:"delete",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Generate Spreadsheet",ref:"spreadsheet",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
              {label:"Setup Scheduler",ref:"scheduler",title:"Configured scheduler",enabled:(permissions.create==2),onClick:this.onScheduler},
              {label:"Setup Forms",ref:"form",title:"Configured scheduler",enabled:(permissions.create==2),onClick:this.onForm},
            ],
        });
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
                keys:["taskGrade"]
            },
            success:(options)=>{
              this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    onScheduler=()=>{
        window.location = "/scheduler-list";
    }

    onForm=()=>{
        window.location = "/form-list";
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              location={this.props.location}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <TaskItem key={index}
                                          content={content}
                                          index={index}
                                          onChange={onChange}
                                          onContentChange={onContentChange}
                                          registerReference={registerRef}
                                          options={this.state.options}
                                          permissions={this.state.permissions}
                                          />
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class TaskItem extends React.Component{

    state={
        step:"view",
        loading:false,
        loadText:"",
        showDeliverable:false,
    }

    onViewImage=(index,photoArray)=>{
        const image = this.props.content[photoArray][index];
        this.setState({
            step:"view-photo",
            photoArray:photoArray,
            selectedImage:image.url,
            selectedImageIndex:index,
            selectedImageName:image.filename,
        });
    }

    onUploadPhoto=(url)=>{
        this.setState({loading:true,loadText:"Processing...",error:""});
        u.post({
            url:"/api/add-task-photo",
            data:{
              taskID:this.props.content.taskID,
              field:this.state.photoArray,
              filename:url
            },
            success:()=>{
                const field = this.state.photoArray;
                let images=this.props.content[field];
                images.push({filename:this.state.filename,url:url});
                this.props.onChange(images,field,this.props.index);
                this.setState({step:"view",error:"",loading:false});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        })
    }

    onDeletePhoto=()=>{
        this.setState({loading:true});
        u.post({
            url:"/api/delete-task-photo",
            data:{
                taskID:this.props.content.taskID,
                field:this.state.photoArray,
                imageURL:this.state.selectedImage,
                imageName:this.state.selectedImageName,
            },
            success:()=>{
                const field = this.state.photoArray;
                let images = this.props.content[field];
                images.splice(this.state.selectedImageIndex,1);
                this.props.onChange(images,field,this.props.index);
                this.setState({loading:false,error:"",step:"view"});
            },
            error:(error)=>{
                this.setState({loading:false,error:error});
            }
        });
    }

    onStatusChange=()=>{
        this.setState({error:""});
        const keys=['grade','remarks'];
        let data={};
        for(var i=0; i<keys.length; i++){
            if(this.refs[keys[i]]){
                const current=this.refs[keys[i]].validate();
                if(!current.valid){
                    this.setState({error:current.value});
                    return 0;
                }
                data[keys[i]] = current.value;
            }
        }
        this.setState({loading:true,loadText:"Updating..."});
        data.status=(this.props.content.status == "Completed") ? "Verified" : "Completed";
        data.taskID=this.props.content.taskID;
        u.post({
            url:"/api/task-update-status",
            data:data,
            success:()=>{
                this.props.onChange(data.status,"status",this.props.index);
                if(typeof data.grade !="undefined"){
                  this.props.onChange(data.grade,"grade",this.props.index);
                }
                if(typeof data.remarks !="undefined"){
                  this.props.onChange(data.remarks,"remarks",this.props.index);
                }
                this.setState({loading:false,step:"view",error:""});
            },
            error:(error)=>{
                this.setState({error:error,loading:false});
            }
        })
    }

    onReject=()=>{
      this.setState({error:""});
      const keys=['rejectReason'];
      let data={};
      for(var i=0; i<keys.length; i++){
          if(this.refs[keys[i]]){
              const current=this.refs[keys[i]].validate();
              if(!current.valid){
                  this.setState({error:current.value});
                  return 0;
              }
              data[keys[i]] = current.value;
          }
      }
      this.setState({loading:true,loadText:"Updating..."});

      u.post({
          url:"/api/task-reject",
          data:{
              taskID:this.props.content.taskID,
              rejectReason:data.rejectReason,
          },
          success:()=>{
              this.props.onChange("Rejected","status",this.props.index);
              this.props.onChange(data.rejectReason,"rejectReason",this.props.index);
              this.setState({loading:false,step:"view",error:""});
          },
          error:(error)=>{
              this.setState({error:error,loading:false});
          }
      })
    }

    onChangeIntercept=(value,label,index)=>{
        /*if(label=="status"){
            if(value != "Pending"){
                this.props.onChange(u.getUserID(),value.toLowerCase()+"ByID",index);
                this.props.onChange(u.getFullName(),value.toLowerCase()+"ByName",index);
            }
        }*/
        this.props.onChange(value,label,index);
    }

    onViewReport=()=>{
        store.set("task-report",this.props.content);
        window.open("/task-report","_blank");
    }

    setDef=(value,type)=>{
        if(!value){
            return "None";
        }
        else if(type == "datetime"){
            return moment(value).format("DD MMM YYYY - h:mma");
        }
        else if(type == "date"){
            return moment(value).format("DD MMM YYYY");
        }
        else if(type == "time"){
            return moment("2000-01-01T" + value).format("h:mma");
        }
        else if(type == "dow"){
            const labels = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
            let dow = "";
            for(var i=0; i<value.length; i++){
                if(value[i]){
                    dow = dow + ((dow.length > 0) ? " / " : "") + labels[i];
                }
            }
            return dow;
        }

        return value;
    };

    render=()=>{
        const content = this.props.content;
        const permissions = this.props.permissions;
        return (
            <div style={{position:"relative",textAlign:'center'}}>
                <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                <div className="list-segment-error">{this.state.error}</div>

                <Step active={this.state.step == "view"}>
                    <DynamicListArea listLabel={"task-list"}
                                     permission="task"
                                     content={this.props.content}
                                     onChange={this.onChangeIntercept}
                                     index={this.props.index}
                                     />

                    <div className="list-segment extended" style={{textAlign:'center'}}>
                        <h3 style={{textAlign:"center"}}>
                            <span style={{marginLeft:(permissions.update) ? "25px" : null}}>Supporting Photos</span>
                            {
                                (permissions.update)
                                  ?
                                  <div style={{marginLeft:"10px",display:"inline-block",verticalAlign:"middle",width:"15px",height:"15px",marginBottom:"3px"}}>
                                      <MiniPlusButton onClick={()=>{this.setState({step:"upload-photo",photoArray:"supportingPhoto",error:""})}}/>
                                  </div>
                                  :
                                  null
                            }
                        </h3>
                        {
                              (content.supportingPhoto.length > 0)
                                  ?
                                <div className="list-image-container">
                                {
                                    content.supportingPhoto.map(
                                        (item,index)=>{
                                            return(
                                                <div key={index} className="list-image-item">
                                                    <div className="list-image-flex"><img src={item.url} onClick={()=>{this.onViewImage(index,"supportingPhoto")}}/></div>
                                                </div>
                                            )
                                        }
                                    )
                                }
                                </div>
                                :
                                <div className="list-placeholder">
                                    <b>No Supporting Photos</b>
                                </div>
                        }
                    </div>

                    {
                        (content.form.length == 0)
                            ?
                            <div className="list-segment extended" style={{textAlign:'center'}}>
                                <h3 style={{textAlign:"center"}}>
                                    <span style={{marginLeft:(permissions.update) ? "25px" : null}}>Completed Photos</span>
                                    {
                                        (permissions.update)
                                          ?
                                          <div style={{marginLeft:"10px",display:"inline-block",verticalAlign:"middle",width:"15px",height:"15px",marginBottom:"3px"}}>
                                              <MiniPlusButton onClick={()=>{this.setState({step:"upload-photo",photoArray:"completedPhoto",error:""})}}/>
                                          </div>
                                          :
                                          null
                                    }
                                </h3>
                                {
                                    (content.completedPhoto.length > 0)
                                        ?
                                        <div className="list-image-container">
                                        {
                                            content.completedPhoto.map(
                                                (item,index)=>{
                                                    return(
                                                        <div key={index} className="list-image-item">
                                                            <div className="list-image-flex">
                                                                <img src={item.url} onClick={()=>{this.onViewImage(index,"completedPhoto")}}/>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            )
                                        }
                                        </div>
                                        :
                                        <div className="list-placeholder">
                                            <b>No Completion Photos</b>
                                        </div>
                                  }
                            </div>
                            :
                            null
                    }

                    {
                        (this.state.showDeliverable)
                            ?
                            <div>
                                <div className="list-segment extended" style={{textAlign:'center'}}>
                                    <h3 style={{textAlign:"center"}}>Deliverable</h3>
                                    <div className="report-table">
                                        <table>
                                            <tbody>
                                                {
                                                    content.form.map(
                                                        (item,index)=>{
                                                            return (
                                                              <tr key={index}>
                                                                  <td className="report-item"><b>{item.label}</b></td>
                                                                  {
                                                                      (item.dataType == "photo")
                                                                          ?
                                                                          (
                                                                            (item.value)
                                                                                ?
                                                                                (item.value.url)
                                                                                    ?
                                                                                    <td className="report-item">
                                                                                        <img src={item.value.url} className="report-image"/>
                                                                                        <div className="report-image-name">{item.value.filename}</div>
                                                                                    </td>
                                                                                    :
                                                                                    <td className="report-item"/>
                                                                                :
                                                                                <td className="report-item"/>
                                                                          )
                                                                          :
                                                                          <td className="report-item">
                                                                            {this.setDef(item.value,item.dataType)}
                                                                          </td>
                                                                  }
                                                              </tr>
                                                            )
                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                    }

                    <div className="list-segment-buttons">
                        <TextButton onClick={this.onViewReport}>View Report</TextButton>
                        {
                            (content.form.length > 0)
                              ?
                              <TextButton onClick={()=>{this.setState({showDeliverable:!this.state.showDeliverable})}}>{(this.state.showDeliverable) ? "Hide" : "Show"} Deliverable</TextButton>
                              :
                              null
                        }
                    </div>

                    <div className="list-segment-buttons">
                        {
                            (permissions.update && content.status == "Completed")
                                ?
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"reject"})}}>Reject</Button>
                                :
                                null
                        }
                        {
                            (permissions.update && (content.status != "Verified"))
                                ?
                                <Button type="medium" onClick={()=>{this.setState({error:"",step:"status-change"})}}>{(content.status == "Pending") ? "Complete" : "Verify"}</Button>
                                :
                                null
                        }
                    </div>

                    <div className="list-segment-buttons">
                        <ResolutionStack content={content.participants}/>
                    </div>
                </Step>

                <Step active={this.state.step == "view-photo"}>
                    <div className="list-image-container">
                        <h2>Photo: {this.state.selectedImageName}</h2>
                        <img className="list-view-image" src={this.state.selectedImage} onClick={()=>{window.open(this.state.selectedImage,"_blank")}}/>
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view",error:""})}}>Back</Button>
                            {
                                (permissions.update)
                                ?
                                <Button type="medium" onClick={()=>{this.setState({step:"delete-photo"})}}>Delete</Button>
                                :
                                null
                            }

                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "upload-photo"}>
                    <div className="list-upload-photo">
                        <Dropzone label="iphoto-dropzone"
                                  onAdded={(filename)=>{this.setState({loading:true,filename:filename,loadText:"Uploading " + filename})}}
                                  onUploaded={this.onUploadPhoto}
                                  onError={(error)=>{this.setState({loading:false,error:error})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..."+value+"%"})}}
                                  accessibleForPreview={true}
                                  filetypes={Photo}
                                  noUpload={false}
                                  />
                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"view"})}}>Back</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "delete-photo"}>
                    <div style={{padding:"30px 0 20px 0",textAlign:'center'}}>
                      <h2 className="text-negative">Confirm Delete Photo: {this.state.selectedImageName}</h2>
                      <div>Note. This action cannot be reversed</div>
                    </div>

                    <div className="segment-buttons">
                          <Button type="medium" onClick={()=>{this.setState({error:"",step:"view"})}}>Back</Button>
                          <Button type="medium" onClick={this.onDeletePhoto}>Confirm</Button>
                    </div>
                </Step>

                <Step active={this.state.step == "status-change"}>
                    <div style={{padding:"20px 0"}}>
                        <h2>Declare Task {(content.status == "Completed" ) ? "Verified" : "Completed"}?</h2>
                        {
                            ((content.taskType == "Inspection" && content.status == "Pending") || (content.taskType != "Inspection" && content.status == "Completed"))
                                ?
                                <div>
                                    <div className="segment-full-item list">
                                        <div className="segment-item-label">Grade:</div>
                                        <div className="segment-item-input">
                                            <Select ref="grade" label="Grade" values={{options:this.props.options.taskGrade}}/>
                                        </div>
                                    </div>
                                    <div className="segment-full-item list">
                                        <div className="segment-item-label">Remarks:</div>
                                        <div className="segment-item-input">
                                            <Area ref="remarks" label="Remarks"/>
                                        </div>
                                    </div>
                                </div>
                                :
                                null
                        }
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:'view',error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onStatusChange}>Confirm</Button>
                        </div>
                    </div>
                </Step>

                <Step active={this.state.step == "reject"}>
                    <div style={{padding:"20px 0"}}>
                        <h2 className="text-negative">Reject Task Completion?</h2>

                        <div className="segment-full-item list" style={{fontSize:"12px"}}>
                            <div className="segment-item-input">
                                <Area ref="rejectReason" label="Reason" rules={{required:true,placeholder:"Reason for rejection..."}}/>
                            </div>
                        </div>

                        <div className="list-segment-buttons">
                            Note. Task & reject reason will reappear on respective assignee's pending list
                        </div>

                        <div className="list-segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:'view',error:""})}}>Back</Button>
                            <Button type="medium" onClick={this.onReject}>Confirm</Button>
                        </div>
                    </div>
                </Step>
            </div>
        )
    }
}

export class AddTask extends React.Component{

    state={
      error:"",
      options:[],
      estateOptions:{
        dorm:[],
        cluster:[],
        building:[],
        level:[],
        room:[]
      },
      staffOptions:[],
      entryData:{
          targetUser:"staff",
          supportingPhoto:[],
          deadlineDate:moment().add(1,"days").format("YYYY-MM-DD"),
          deadlineTime:moment().format("HH:00:00"),
      },
      loading:false,
      loadText:'',
      step:"add",
    };

    componentDidMount=()=>{
        this.getOptions();
        this.getEstateOptions("dorm");
        this.getStaffOptions();
        this.getFormOptions();

        if(this.props.feedback){
            const feedback = Object.assign({},this.props.feedback);
            let entryData=this.state.entryData;
            entryData.feedbackID = feedback.feedbackID;
            entryData.supportingPhoto = feedback.supportingPhoto;
            entryData.dormID=feedback.dormID;
            entryData.taskLocation = "User Input";
            entryData.locationDetailsText = feedback.reportedLocation;
            entryData.taskDescription = feedback.remarks;
            entryData.taskType = "Maintenance";
            entryData.lockDorm =  true;
        }
    }

    onCreate=()=>{
        this.setState({error:""});

        const values = [
            "dormID",
            "taskType",
            "formID",
            "deadlineDate",
            "deadlineTime",
            "targetUser",
            "assignedPrimaryID",
            "assignedSecondaryID",
            "tenantFIN",
            "taskLocation",
            "locationDetailsText",
            "clusterID",
            "buildingID",
            "levelID",
            "roomID",
            "taskDescription",
        ];

        for(var i=0; i<values.length; i++){
            if(this.refs[values[i]]){
                const field = this.refs[values[i]].validate();
                if(!field.valid){
                    this.setState({error:field.value});
                    return 0;
                }
            }
        }

        let data = Object.assign({},this.state.entryData);
        if(data.taskLocation != "User Input"){
            data.locationDetails={
              clusterID:data.clusterID,
              buildingID:data.buildingID,
              levelID:data.levelID,
              roomID:data.roomID,
          };
        }
        data.deadline = data.deadlineDate + "T" + data.deadlineTime;

        delete data.clusterID;
        delete data.buildingID;
        delete data.levelID;
        delete data.roomID;
        delete data.deadlineDate;
        delete data.deadlineTime;

        this.refs['add'].onValidated(data);
    }

    getOptions=()=>{
        u.post({
            url:"/api/get-options",
            data:{
              keys:["taskType","taskLocation","taskTargetUser"],
            },
            success:(options)=>{
                this.setState({options:options});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getEstateOptions=(type,id)=>{
        u.post({
            url:"/api/get-estate-options",
            data:{
                type:type,
                id:id,
            },
            success:(estateOption)=>{
                let estateOptions = this.state.estateOptions;
                estateOptions[type]=estateOption;
                this.setState({estateOptions:estateOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getStaffOptions=()=>{
        u.post({
            url:"/api/get-staff-options",
            data:{
                permission:{
                    task:{view:true}
                }
            },
            success:(staffOptions)=>{
                staffOptions.unshift({value:"None",label:"None"});
                this.setState({
                  staffOptions:staffOptions
                });
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    getFormOptions=()=>{
        u.post({
            url:"/api/get-form-options",
            data:{},
            success:(formOptions)=>{
                this.setState({formOptions:formOptions});
            },
            error:(error)=>{
                this.setState({error:error});
            }
        });
    }

    show=(show)=>{
        this.refs['add'].show(show);
        if(!show){
            this.setState({error:""});
        }
    }

    onCancel=()=>{
        this.setState({entryData:{
            targetUser:"staff",
            supportingPhoto:[],
            deadlineDate:moment().add(1,"days").format("YYYY-MM-DD"),
            deadlineTime:moment().format("HH:00:00"),
        },error:""});
        this.props.settings.onToggleForm(this.props.reference,false);
    }

    onChange=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        if(label == "targetUser" && value == "residentRoom"){
            if(entryData.taskLocation != "room"){
                this.onChangeLocation("room","taskLocation");
            }
        }
        this.setState({entryData:entryData});
    }

    onChangeEstate=(value,label)=>{
        let entryData = this.state.entryData;
        entryData[label]=value;

        if(label == "dormID"){
            this.getEstateOptions("cluster",value);
            entryData.clusterID="";
            entryData.buildingID="";
            entryData.levelID="";
            entryData.roomID="";
        }
        else if(label == "clusterID"){
            this.getEstateOptions("building",value);
            entryData.buildingID="";
            entryData.levelID="";
            entryData.roomID="";
        }
        else if(label == "buildingID"){
            this.getEstateOptions("level",value);
            entryData.levelID="";
            entryData.roomID="";
        }
        else if(label == "levelID"){
            this.getEstateOptions("room",value);
            entryData.roomID="";
        }
        this.setState({entryData:entryData});
    }

    onChangeLocation=(value,label)=>{
        let entryData=this.state.entryData;
        entryData[label]=value;
        let estateOptions = this.state.estateOptions;
        estateOptions.cluster=[];
        estateOptions.building=[];
        estateOptions.level=[];
        estateOptions.room=[];
        entryData.clusterID="";
        entryData.buildingID="";
        entryData.levelID="";
        entryData.roomID="";

        this.setState({entryData:entryData,estateOptions:estateOptions});

        if(value != "User Input"){
            this.getEstateOptions("cluster",entryData.dormID);
        }
    }

    onPhotoUploaded=(url)=>{
        let entryData = this.state.entryData;
        entryData.supportingPhoto.push({filename:this.state.filename,url:url});
        this.setState({entryData:entryData,step:"add",error:"",loading:false});
    }

    onPhotoRemoved=(index)=>{
        let entryData=this.state.entryData;
        entryData.supportingPhoto.splice(index,1);
        this.setState({entryData:entryData});
    }

    render=()=>{
        const entryData=this.state.entryData;
        const options=this.state.options;
        const location=this.state.estateOptions;
        return(
            <AddListItem ref={this.props.reference}
                         reference={this.props.reference}
                         settings={this.props.settings}
                         success={this.props.success}
                         inList={this.props.inList}
                         onComplete={(this.props.onComplete) ? this.props.onComplete : this.onCancel}
                         onToggleForm={this.props.onToggleForm}>

                <div style={{position:"relative"}}>
                    <LoadGraphic active={this.state.loading} text={this.state.loadText}/>
                    <Step active={this.state.step == "add"}>
                        <h3>Complete following fields to continue</h3>

                        <div className="segment-error">{this.state.error}</div>

                        <div className="segment-content">
                            <div className="segment-full-item">
                                <div className="segment-item-label">Dormitory</div>
                                <div className="segment-item-input">
                                    <Select ref="dormID" field="dormID" label="Dormitory" values={{value:entryData.dormID,options:location.dorm,enabled:!(entryData.lockDorm)}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Task Type</div>
                                <div className="segment-item-input">
                                    <Select ref="taskType" field="taskType" label="Task Type" values={{value:entryData.taskType,options:options.taskType}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Deliverable <Info title="By default, assigned staff will only need to take completion photo. You may create a custom Form in the Form list for users to perform before completing the task"/></div>
                                <div className="segment-item-input">
                                    <Select ref="formID" field="formID" label="Deliverable" values={{value:entryData.formID,options:this.state.formOptions}} rules={{required:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Deadline <Info title="When is this task due? On completion, the task punctuality will be determined based on the given deadline"/></div>
                                <div className="segment-item-input">
                                    <Date ref="deadlineDate" field="deadlineDate" label="Deadline Date" values={{value:entryData.deadlineDate}} rules={{minDate:moment().format("YYYY-MM-DD")}} range={[1,2]} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label"/>
                                <div className="segment-item-input">
                                    <Time ref="deadlineTime" field="deadlineTime" label="Deadline Time" values={{value:entryData.deadlineTime}} rules={{skipSeconds:true}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Target User <Info title="<b>Staff</b><br/>Assign task to selected staff<br/><br/><b>Resident (FIN)</b><br/>Assign to resident of the inputed FIN<br/><br/><b>Resident (Room)</b><br/>Target all residents registered to the room in 'Location'"/></div>
                                <div className="segment-item-input">
                                    <Select ref="targetUser"
                                            field="targetUser"
                                            label="Target User"
                                            values={{
                                                value:entryData.targetUser,
                                                options:options.taskTargetUser
                                            }}
                                            rules={{required:true}}
                                            onChange={this.onChange}
                                            />
                                </div>
                            </div>

                            {
                                (entryData.targetUser == "staff")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Primary <Info title="This is the person that the task will be assigned to"/></div>
                                        <div className="segment-item-input">
                                            <Select ref="assignedPrimaryID"
                                                    field="assignedPrimaryID"
                                                    label="Assigned Primary"
                                                    values={{
                                                        value:entryData.assignedPrimaryID,
                                                        options:this.state.staffOptions
                                                    }}
                                                    rules={{required:true}}
                                                    onChange={this.onChange}
                                                    />
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                (entryData.targetUser == "staff")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Secondary <Info title="Recommended to assign to the person that is verifying the task completion. If verification is not required, secondary can also be the 2nd person in charge of completing the task"/></div>
                                        <div className="segment-item-input">
                                            <Select ref="assignedSecondaryID" field="assignedSecondaryID" label="Assigned Secondary" values={{value:entryData.assignedSecondaryID,options:this.state.staffOptions}} rules={{required:true}} onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            {
                                (entryData.targetUser == "residentFIN")
                                    ?
                                    <div className="segment-full-item">
                                        <div className="segment-item-label">Resident FIN <Info title="FIN number of the resident that this task is assigned to"/></div>
                                        <div className="segment-item-input">
                                            <Box ref="tenantFIN" field="tenantFIN" label="Resident FIN" values={{value:entryData.tenantFIN}} rules={{required:true}} onChange={this.onChange}/>
                                        </div>
                                    </div>
                                    :
                                    null
                            }

                            <div className="segment-full-item">
                                <div className="segment-item-label">Location <Info title="Where is the task location? Choose specific estates to tag the task for future seaching"/></div>
                                <div className="segment-item-input">
                                    <Select ref="taskLocation"
                                            field="taskLocation"
                                            label="Task Location"
                                            values={{
                                              value:entryData.taskLocation,
                                              options:options.taskLocation,
                                              enabled:!(entryData.targetUser == "residentRoom")
                                            }}
                                            rules={{required:true}}
                                            onChange={this.onChangeLocation}
                                            />
                                    {
                                        (entryData.taskLocation == "User Input")
                                            ?
                                            <div>
                                                <Box ref="locationDetailsText" style={{margin:"5px 0"}} field="locationDetailsText" label="Task Location" values={{value:entryData.locationDetailsText}} rules={{required:true}} onChange={this.onChange}/>
                                            </div>
                                            :
                                            <div>
                                                <Select ref="clusterID" style={{margin:"5px 0"}} label="Zone" field="clusterID" values={{value:entryData.clusterID,options:location.cluster}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                {
                                                  (entryData.taskLocation == "building" || entryData.taskLocation == "level" || entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="buildingID" style={{margin:"5px 0"}} label="Building" field="buildingID" values={{value:entryData.buildingID,options:location.building}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.taskLocation == "level" || entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="levelID" style={{margin:"5px 0"}} label="Level" field="levelID" values={{value:entryData.levelID,options:location.level}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                                {
                                                  (entryData.taskLocation == "room")
                                                    ?
                                                    <Select ref="roomID" style={{margin:"5px 0"}} label="Room" field="roomID" values={{value:entryData.roomID,options:location.room}} rules={{required:true}} onChange={this.onChangeEstate}/>
                                                    :
                                                    null
                                                }
                                            </div>
                                    }
                                </div>
                            </div>



                            <div className="segment-full-item">
                                <div className="segment-item-label">Task Description <Info title="What is the task? Put detailed information of the task that needs to be completed"/></div>
                                <div className="segment-item-input">
                                    <Area ref="taskDescription" field="taskDescription" label="Task Description" values={{value:entryData.taskDescription}} rules={{required:true,rows:5}} onChange={this.onChange}/>
                                </div>
                            </div>

                            <div className="segment-full-item">
                                <div className="segment-item-label">Supporting Photo <Info title="Will the task or location be clearer with an image? Upload up to 5 images to help the user understand the task"/></div>
                                <div className="segment-item-input">
                                    <InputPhoto ref="supportingPhoto" field="supportingPhoto" label="Supporting Photo" values={{value:entryData.supportingPhoto}} rules={{max:5}} onChange={this.onChange}
                                                onAdd={()=>{this.setState({step:"photo",error:""})}}
                                                onRemove={this.onPhotoRemoved}
                                    />
                                </div>
                            </div>

                            <div className="segment-buttons">
                                <Button onClick={this.onCancel}>Cancel</Button>
                                <Button type="medium" onClick={this.onCreate}>Create</Button>
                            </div>
                        </div>
                    </Step>

                    <Step active={this.state.step == "photo"}>
                        <div className="segment-error">{this.state.error}</div>
                        <Dropzone label="add-task-dropzone"
                                  accessibleForPreview={true}
                                  onAdded={(filename)=>{this.setState({filename:filename,loading:true,loadText:"Uploading "+filename+"..."})}}
                                  onUploaded={this.onPhotoUploaded}
                                  onError={(error)=>{this.setState({error:error,loading:false})}}
                                  onProgress={(value)=>{this.setState({loadText:"Uploading..." + value + "%"})}}
                                  filetypes={Photo}
                        />
                        <div className="segment-buttons">
                            <Button type="medium" onClick={()=>{this.setState({step:"add"})}}>Back</Button>
                        </div>
                    </Step>
                </div>
            </AddListItem>
        )
    }
}
