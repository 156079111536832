import React from 'react';
import AppLayout from '../components/AppLayout';
import OverviewList from '../components/OverviewList';
import {PageMenu,DeleteListItem,GenerateSpreadsheet,BulkEdit,AddListItem,DynamicListArea,DownloadTemplate,LoadGraphic,Step,Segment,SegmentComplete} from '../components/Common';
import {Button,Box,EditSizeLabel,EditSizeLink,TextButton,ContactNumber, Checkbox} from '../components/Input';
import u from '../utilities/Utilities';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Link} from 'react-router-dom';
import store from 'store';

export default class BookingList extends React.Component{

    settings={
        /**** Page Setup ****/
        title:"Bookings",
        permission:"facility",
        url:"/booking-list",

        /**** Action Setup ****/
        addType:0,
        addURL:"/api/add-booking-entry",
        deleteURL:"/api/delete-booking-entries",
        spreadsheetURL:"/api/generate-booking-spreadsheet",
        overviewURL:"/api/get-all-booking-entries",

        /**** List Setup ****/
        list:true,
        listLabel:"booking-list",
        orderByField:"bookedOn",
        orderByType:"descending",
        numRows:20,
        checkboxPermissions:{},
        overview:undefined,
        onPopup:(content)=>{this.refs['layout'].onPopup(content)}
    };

    pageComponents={
        delete:{component:DeleteListItem,props:{}},
        spreadsheet:{component:GenerateSpreadsheet,props:{}},
        bulkEdit:{component:BulkEdit,props:{}},
    }

    state={
        selected:{
            tags:[],
            all:false,
            data:[],
            entryCount:0
        },
        pageMenuButtons:[],
        addError:"",
        permissions:{},
    };

    componentDidMount=()=>{
        const permissions = u.getPermissions("facility");
        const bulkEditOptions = u.getBulkEdit(this.settings.listLabel);
        const bulkEditEnabled = (permissions.update == 2 && bulkEditOptions.length > 0);
        const combined = u.getPermissions("combined");

        this.setState({
            permissions:permissions,
            pageMenuButtons:[
              {label:"Delete",ref:"delete",mobileLabel:"Delete Entries",image:"/images/page-menu/delete.png",title:"Delete selected entries",enabled:(permissions.delete==2)},
              {label:"Spreadsheet",ref:"spreadsheet",mobileLabel:"Generate Spreadsheet",image:"/images/page-menu/spreadsheet.png",title:"Generate spreadsheet from selection",enabled:combined.spreadsheet},
              {label:"Bulk Edit",ref:"bulkEdit",mobileLabel:"Bulk Edit",image:"/images/page-menu/edit.png",title:"Bulk edit details",enabled:bulkEditEnabled,padding:"3px"},
            ],
        });
    }

    onSelectChange=(selected)=>{
        this.setState({selected:selected});
    }

    render=()=>{
        return (
            <AppLayout ref="layout" settings={this.settings}>

                <div className="page-error-container">
                    <div className="page-error">{this.state.error}</div>
                </div>

                <PageMenu settings={this.settings}
                          buttons={this.state.pageMenuButtons}
                          components={this.pageComponents}
                          selected={this.state.selected}/>

                <OverviewList ref={(ref)=>{this.settings.overview=ref}}
                              settings={this.settings}
                              onSelectChange={this.onSelectChange}
                              registerReference={this.registerReference}
                              onMutatedChange={this.onMutatedChange}
                              location={this.props.location}>
                    {
                        (content,index,onChange,onContentChange,registerRef)=>{
                            return (
                                <BookingItem key={index}
                                              content={content}
                                              index={index}
                                              onChange={onChange}
                                              onContentChange={onContentChange}
                                              registerReference={registerRef}/>
                            )
                        }
                    }
                </OverviewList>

            </AppLayout>
        )
    }
}

class BookingItem extends React.Component{
    render=()=>{
        const content = this.props.content;
        return (
            <div>
                <DynamicListArea listLabel={"booking-list"}
                                 permission="facility"
                                 content={this.props.content}
                                 onChange={this.props.onChange}
                                 index={this.props.index}
                                 />

            </div>
        )
    }
}
