import React from 'react';
import Barcode from 'jsbarcode';
import u from '../utilities/Utilities';
import store from 'store';
import styles from '../styles/print-card.scss';
import QRCode from 'qrcode.react';
import Measure from 'react-measure';
import moment from 'moment';
import EmptyPageLayout from '../components/EmptyPageLayout';

export default class PrintCardPage extends React.Component{

    state={
      content:{},
      category:""
    };

    componentDidMount = () =>{
        let content = store.get("print-card");
        if(typeof content == "undefined"){
            content =  {
                category: ""
            };
        }
        this.setState({
            content:content,
            category:content.category
        })

        setTimeout(function(){
            window.print();
        },500);
    };

    render=()=>{
        return(
          <EmptyPageLayout title="Print Card" url="/print-card">
              <div className="print-card-container">
                  {
                      (this.state.category == "tenant")
                          ?
                          <TenantCardTemplate content={this.state.content}/>
                          :
                          null
                  }
                  {
                      (this.state.category == "staff")
                          ?
                          <StaffCardTemplate content={this.state.content} cardTheme={this.state.content.cardTheme}/>
                          :
                          null

                  }
                  {
                      (this.state.category == "visitor")
                          ?
                          <VisitorCardTemplate content={this.state.content}/>
                          :
                          null

                  }
              </div>
          </EmptyPageLayout>
        );
    }
}

export class MultipleCard extends React.Component{

    state={
        content:[]
    };

    componentDidMount=()=>{
        let content = store.get("print-multi-card");
        let category = store.get("print-multi-card-category");
        if(typeof content == "undefined"){
            content = [];
        }
        this.setState({content:content,category:category});
        setTimeout(()=>{window.print()},500);
    };

    render=()=>{
        return (
          <EmptyPageLayout title="Print Card" url="/print-card">
              <div className="print-multi-card">
                  {
                      this.state.content.map(
                          (item,index)=>{
                              return (
                                  <div className="multi-card" key={index}>
                                      {
                                          (this.state.category == "tenant")
                                              ?
                                              <TenantCardTemplate content={item}/>
                                              :
                                              null
                                      }
                                      {
                                          (this.state.category == "staff")
                                              ?
                                              <StaffCardTemplate content={item} cardTheme={item.cardTheme}/>
                                              :
                                              null
                                      }
                                      {
                                          (this.state.category == "visitor")
                                              ?
                                              <VisitorCardTemplate content={item} cardTheme={item.cardTheme}/>
                                              :
                                              null
                                      }
                                  </div>
                              )
                          }
                      )
                  }
              </div>
          </EmptyPageLayout>
        )
    }
}

export class TenantCardTemplate extends React.Component{

    state={
        imgSRC:null
    };

    componentDidMount=()=>{
        this.setState({imgSRC:u.getAvatarLink(this.props.content.avatarLink)});
    };

    print=()=>{
        if(this.props.onStart){
            this.props.onStart();
        }
        const input = document.getElementById("card-template");
        const htmlToImage = require("html-to-image");
        htmlToImage.toCanvas(input).then(
            (canvas)=>{
                const imgData = canvas.toDataURL('image/jpeg',1);
                const jsPDF = require("jspdf");
                const pdf = new jsPDF({
                    orientation:"portrait",
                    unit:"in",
                    format:"credit-card",
                    putOnlyUsedFonts:true,
                    compress:false,
                });
                pdf.addImage(imgData,'JPEG',0,0,2.16,3.39,null,"NONE");
                pdf.autoPrint();
                pdf.save(this.props.content.tenantName);
                if(this.props.onEnd){
                    this.props.onEnd();
                }
            }
        );
    };

    render=()=>{
        const content=this.props.content;
        const cardTheme = (this.props.overrideTheme) ? this.props.overrideTheme : ((content.overrideTheme) ? content.overrideTheme : content.cardTheme);
        return (
            <div className="card-template" id="card-template">
                {
                    (typeof cardTheme == "undefined" || cardTheme == "default")
                        ?
                        <DefaultTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-ppt")
                        ?
                        <S11PPTTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-cl2")
                        ?
                        <S11CL2TenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-hsjv")
                        ?
                        <S11HSJVTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "utoc")
                        ?
                        <UTOCTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "centurion-juniper")
                        ?
                        <CenturionJuniperTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "tee-up")
                        ?
                        <TeeUpTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "cec")
                        ?
                        <CECTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "cec2")
                        ?
                        <CEC2TenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "nus")
                        ?
                        <NUSTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "mwoc-tengah")
                        ?
                        <MWOCTengahTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "mwoc-eunos")
                        ?
                        <MWOCEunosTenantCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "prime-lodge")
                        ?
                        <PrimeLodgeCardTemplate content={content} imgSrc={this.state.imgSRC}/>
                        :
                        null
                }
                {
                    (cardTheme == "f1-reg")
                        ?
                        <F1PITTenantCardTemplate content={content} imgSrc={this.state.imgSRC}/>
                        :
                        null
                }
                {
                    (cardTheme == "f1-wristband")
                        ?
                        <F1PITWristbandTemplate content={content} imgSrc={this.state.imgSRC}/>
                        :
                        null
                }
                {
                    (cardTheme == "f1-invoice")
                        ?
                        <F1PITInvoiceTemplate content={content}/>
                        :
                        null
                }
                {
                    (cardTheme == "mwoc-default")
                        ?
                        <MWOCTenantCardTemplate content={content}/>
                        :
                        null
                }
            </div>
        );
    }
}

export class StaffCardTemplate extends React.Component{

    state={
        imgSRC:null
    };

    componentDidMount = () =>{
        this.setState({imgSRC:u.getAvatarLink(this.props.content.avatarLink)});

        /**
         * Converts avatar link to local canvas image
         */
        /*var img = new Image(),
            canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d"),
            src = u.getAvatarLink(this.props.content.avatarLink);

        img.onload = ()=>{
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            this.setState({imgSRC:canvas.toDataURL('image/png',1)});
        };

        img.crossOrigin="anonymous";
        img.src=src;*/
    };

    print=()=>{
        if(this.props.onStart){
            this.props.onStart();
        }
        const input = document.getElementById("card-template");
        const htmlToImage = require("html-to-image");
        htmlToImage.toCanvas(input).then(
            (canvas)=>{
                const imgData = canvas.toDataURL('image/jpeg',1);
                const jsPDF = require("jspdf");
                const pdf = new jsPDF({
                    orientation:"portrait",
                    unit:"in",
                    format:"credit-card",
                    putOnlyUsedFonts:true,
                    compress:false,
                });
                pdf.addImage(imgData,'JPEG',0,0,2.16,3.39,null,"NONE");
                pdf.autoPrint();
                pdf.save(this.props.content.staffName);

                if(this.props.onEnd){
                    this.props.onEnd();
                }
            }
        );
    };

    render=()=>{
        return (
            <div className="card-template" id="card-template">
                {
                    (typeof this.props.cardTheme == "undefined" || this.props.cardTheme == "default")
                        ?
                        <DefaultStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-ppt")
                        ?
                        <S11PPTStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-ppt-contractor")
                        ?
                        <S11PPTContractorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-cl2")
                        ?
                        <S11CL2StaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-cl2-contractor")
                        ?
                        <S11CL2ContractorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-hsjv")
                        ?
                        <S11HSJVStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "s11-hsjv-contractor")
                        ?
                        <S11HSJVContractorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "utoc")
                        ?
                        <UTOCStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "centurion-juniper")
                        ?
                        <CenturionJuniperStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "tee-up")
                        ?
                        <TeeUpStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "tt2")
                        ?
                        <TT2StaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "nsrcc")
                        ?
                        <NSRCCStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "mwoc-tengah")
                        ?
                        <MWOCTengahStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "mwoc-eunos")
                        ?
                        <MWOCEunosStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (this.props.cardTheme == "prime-lodge")
                        ?
                        <PrimeLodgeStaffCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
            </div>
        );
    }
}

export class VisitorCardTemplate extends React.Component{

    state={
        imgSRC:null
    };

    componentDidMount=()=>{
        this.setState({imgSRC:u.getAvatarLink(this.props.content.avatarLink)});
    };

    print=()=>{
        if(this.props.onStart){
            this.props.onStart();
        }
        const input = document.getElementById("card-template");
        const htmlToImage = require("html-to-image");
        htmlToImage.toCanvas(input).then(
            (canvas)=>{
                const imgData = canvas.toDataURL('image/jpeg',1);
                const jsPDF = require("jspdf");
                const pdf = new jsPDF({
                    orientation:"portrait",
                    unit:"in",
                    format:"credit-card",
                    putOnlyUsedFonts:true,
                    compress:false,
                });
                pdf.addImage(imgData,'JPEG',0,0,2.16,3.39,null,"NONE");
                pdf.autoPrint();
                pdf.save(this.props.content.staffName);

                if(this.props.onEnd){
                    this.props.onEnd();
                }
            }
        );
    };

    render=()=>{
        const content=this.props.content;
        const cardTheme = content.cardTheme;
        return (
            <div className="card-template" id="card-template">
                {
                    (typeof cardTheme == "undefined" || cardTheme == "default")
                        ?
                        <DefaultVisitorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-ppt")
                        ?
                        <S11PPTVisitorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-cl2")
                        ?
                        <S11CL2VisitorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
                {
                    (cardTheme == "s11-hsjv")
                        ?
                        <S11HSJVVisitorCardTemplate content={this.props.content} imgSrc={this.state.imgSRC}/>
                        :
                        ""
                }
            </div>
        );
    }
}

/************************* Tenant Card Templates ****************************/
class DefaultTenantCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      return(
          <div className="tenant-card-centurion">
              <img className="sd-logo" src={"/images/app/app-logo.png"}/>
              <div className='sd-content-area'>

                <img src={this.props.imgSrc}  className="sd-avatar-image"/>

                <div className="sd-text-content align-container">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="item name">{this.props.content.tenantName}</div>
                        <div className="item estate">{this.props.content.nric}</div>
                        <div className="item estate">{this.props.content.dormName}</div>
                    </div>
                </div>

                <div>
                  <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                      {
                          ({measureRef})=>
                              (
                                  <div ref={measureRef} className="sd-qrcode">
                                      <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                  </div>
                              )
                      }
                  </Measure>
                </div>

              </div>
              <img src="/images/app/chimeric.png"  className="sd-footer"/>
          </div>
      );
  }

}

class S11PPTTenantCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11">
                <div className="flex-card">
                    <div className="flex-top" style={{backgroundColor:this.props.content.clusterColor}}>
                        RESIDENT
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>
                        <div className="tc-horizontal-guide"></div>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.tenantName}</div>
                            <div className="tc-permit">{this.props.content.nric}</div>
                            <div className="tc-company">{this.props.content.registeredCompanyName}</div>
                        </div>

                        <div className="tc-vertical-content flex">
                            <div>{this.props.content.buildingName}</div>
                            <div>{this.props.content.levelRoomName}</div>
                        </div>

                        <img src={"/images/cards/s11-card-logo.png"} className="s11-ppt-card-logo"/>
                    </div>
                </div>
            </div>
        );
    }
}

class S11CL2TenantCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11">
                <div className="flex-card">
                    <div className="flex-top" style={{backgroundColor:this.props.content.clusterColor}}>
                        RESIDENT
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>
                        <div className="tc-horizontal-guide"></div>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.tenantName}</div>
                            <div className="tc-permit">{this.props.content.nric}</div>
                            <div className="tc-company">{this.props.content.registeredCompanyName}</div>
                        </div>

                        <div className="tc-vertical-content flex">
                            <div>{this.props.content.buildingName}</div>
                            <div>{this.props.content.levelRoomName}</div>
                        </div>

                        <img src={"/images/cards/s11-cl2-card-logo.jpg"} className="s11-ppt-card-logo"/>
                    </div>
                </div>
            </div>
        );
    }
}

class S11HSJVTenantCardTemplate extends React.Component{
  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11">
                <div className="flex-card">
                    <div className="flex-top" style={{backgroundColor:this.props.content.clusterColor}}>
                        RESIDENT
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>
                        <div className="tc-horizontal-guide"></div>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.tenantName}</div>
                            <div className="tc-permit">{this.props.content.nric}</div>
                            <div className="tc-company">{this.props.content.registeredCompanyName}</div>
                        </div>

                        <div className="tc-vertical-content flex">
                            <div>{this.props.content.buildingName}</div>
                            <div>{this.props.content.levelRoomName}</div>
                        </div>

                        <img src={"/images/cards/s11-hsjv-card-logo.png"} className="s11-ppt-card-logo"/>
                    </div>
                </div>
            </div>
        );
    }
}

class UTOCTenantCardTemplate extends React.Component{
    render(){
        return(
            <div className="tenant-card-s11">
                <div className="tc-top-background cluster-color" style={{backgroundColor:this.props.content.clusterColor}}>
                    <div className="color-text-left">RESIDENT</div>
                    <div className="color-text-right">RESIDENT</div>

                    <div className="tc-top-image-div">
                        <img src={this.props.imgSrc}/>
                    </div>
                </div>
                <div className="tc-mid-content utoc" style={{textAlign:"center"}}>
                    <div className="tc-horizontal-guide"></div>

                    <div className="tc-horizontal-content utoc">
                        <div className="tc-name utoc">{this.props.content.tenantName}</div>
                        <div className="tc-permit utoc">{this.props.content.nric}</div>
                        <div className="tc-permit utoc">{this.props.content.workPermit}</div>
                        <div className="tc-company utoc">{this.props.content.registeredCompanyName}</div>
                    </div>

                    <div className="utoc-logo-position">
                        <div className="utoc-logo-container">
                            <img src={"/images/cards/utoc-card-logo.jpg"} style={{display:"inline-block",verticalAlign:"middle",marginRight:"10px",height:"37px"}}/>
                            <div style={{display:"inline-block",verticalAlign:"middle",letterSpacing:0,lineHeight:"12px"}}>
                                <div className="utoc-name">UTOC Unity Dormitory</div>
                                352 Jalan Boon Lay,S619531<br/>
                                6264 2116
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class CenturionJuniperTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render(){
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-centurion">
                <img className="centurion-logo" src={"/images/cards/centurion-juniper.jpg"}/>

                <div className="centurion-image-holder">
                    <div className="centurion-image">
                        <img src={this.props.imgSrc}  className="centurion-avatar"/>
                    </div>
                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="centurion-qr-code">
                                        <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>
                </div>

                <div className="centurion-text-content align-content">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="cent-item name">{this.props.content.tenantName}</div>
                        <div className="cent-item estate">{this.props.content.buildingName} / {this.props.content.roomName}</div>
                        <div className="cent-item nric">{this.props.content.nric}</div>
                        <div className="cent-item permit">{this.props.content.workPermit}</div>
                        <div className="cent-item company">{this.props.content.registeredCompanyName}</div>
                    </div>
                </div>
                <div className="centurion-address-divider"/>
                <div className="centurion-address-container">
                    Westlite Dormitory<br/>
                    23 Mandai Estate, SG 729937
                </div>
            </div>
        );
    }
}

class TeeUpTenantCardTemplate extends React.Component{

    render=()=>{
        const content=this.props.content;
        return (
            <div className="tenant-card-tee-up">
                <img src={"/images/cards/tee-up-card-logo-small.png"} className="tee-up-card-logo"/>

                <div className="tee-up-top-background">
                    <div className="tee-up-text-logo">PPT LODGE 1A</div>
                    <div className="tee-up-bg"/>
                    <div className="tee-up-text-room">{(content.levelRoomName) ? content.levelRoomName.toUpperCase() : "None"}</div>
                    <img className="tee-up-image" src={this.props.imgSrc}/>
                </div>

                <div className="tee-up-mid-content" style={{textAlign:"center"}}>
                    <div style={{fontSize:"0.8em",height:"42px",overflow:"hidden"}}>
                        <div className="tee-up-valign-left"/>
                        <div className="tee-up-valign-right"><b>{content.tenantName}</b></div>
                    </div>
                    <div style={{margin:"1px auto",fontSize:"0.78em"}}><b>{content.workPermit}</b></div>
                    <div style={{margin:"1px auto",fontSize:"0.78em",overflow:"hidden"}}>
                        {content.registeredCompanyName}
                    </div>
                </div>

                <div className="tee-up-rfid">
                    <div style={{fontSize:"0.78em",overflow:"hidden"}}>
                        <b>{content.rfid}</b>
                    </div>
                </div>
            </div>
        )
    }
}

class CECTenantCardTemplate extends React.Component{

    render(){
        const content=this.props.content;
        return (
            <div className="cec-card-container">
                <div className="cec-welcome">WELCOME TO CHANGI EXHIBITION CENTER!</div>
                <div className="cec-detail">
                    <div className="cec-detail-label">NAME:</div>
                    <div className="cec-detail-input">{content.tenantName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">BED:</div>
                    <div className="cec-detail-input">{content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">FIN:</div>
                    <div className="cec-detail-input">{content.nric.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">DOB:</div>
                    <div className="cec-detail-input">{moment(content.dateOfBirth).format("DD MMM YYYY").toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">NATIONALITY:</div>
                    <div className="cec-detail-input">{(content.nationality) ? content.nationality.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">CAT:</div>
                    <div className="cec-detail-input">{(content.covidStatus) ? content.covidStatus.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">CHECK-IN DATE:</div>
                    <div className="cec-detail-input">{(content.enrollmentDate) ?(moment(content.enrollmentDate).format("DD MMM YYYY")).toUpperCase() : "NONE"}</div>
                </div>

                <div className="cec-image">
                    <img src="/images/cards/cec-wifi.png"/>
                </div>
            </div>
        )
    }
}

class CEC2TenantCardTemplate extends React.Component{

    render(){
        const content=this.props.content;
        return (
            <div className="cec-card-container">
                <div className="cec-welcome">WELCOME TO CHANGI EXHIBITION CENTER 2!</div>
                <div className="cec-detail">
                    <div className="cec-detail-label">NAME:</div>
                    <div className="cec-detail-input">{content.tenantName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">BED:</div>
                    <div className="cec-detail-input">{content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">FIN:</div>
                    <div className="cec-detail-input">{content.nric.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">DOB:</div>
                    <div className="cec-detail-input">{moment(content.dateOfBirth).format("DD MMM YYYY").toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">NATIONALITY:</div>
                    <div className="cec-detail-input">{(content.nationality) ? content.nationality.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">CAT:</div>
                    <div className="cec-detail-input">{(content.covidStatus) ? content.covidStatus.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">CHECK-IN DATE:</div>
                    <div className="cec-detail-input">{(content.enrollmentDate) ?(moment(content.enrollmentDate).format("DD MMM YYYY")).toUpperCase() : "NONE"}</div>
                </div>

                <div className="cec-image">
                    <img src="/images/cards/cec-2.png"/>
                </div>
            </div>
        )
    }
}

class CEC2DormTenantCardTemplate extends React.Component{

    render(){
        const content=this.props.content;
        return (
            <div className="cec-card-container">
                <div className="cec-welcome">WELCOME TO CHANGI EXHIBITION CENTER!</div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">NAME:</div>
                    <div className="cec-detail-input">{content.tenantName.toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">BED:</div>
                    <div className="cec-detail-input">{content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">FIN:</div>
                    <div className="cec-detail-input">{content.nric.toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">DOB:</div>
                    <div className="cec-detail-input">{moment(content.dateOfBirth).format("DD MMM YYYY").toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">CONTACT NUMBER:</div>
                    <div className="cec-detail-input">{(content.contactNumber) ? content.contactNumber.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">NATIONALITY:</div>
                    <div className="cec-detail-input">{(content.nationality) ? content.nationality.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">FOOD PREFERENCE:</div>
                    <div className="cec-detail-input">{(content.foodPreference) ? content.foodPreference.toUpperCase() : "NONE"}</div>
                </div>

                <div className="cec-image">
                    <img src="/images/cards/cec-2-dorm.png"/>
                </div>
            </div>
        )
    }
}

class F1PITTenantCardTemplate extends React.Component{

    render=()=>{
        const content=this.props.content;
        const bedLabel = content.levelName.toUpperCase() + "-" + content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase();
        return (
            <div className="cec-card-container">
                <div className="cec-welcome">WELCOME TO<br/>COVID-19 TREATMENT FACILITY<br/>@ F1 PIT BLDG!</div>
                <div className="cec-detail" style={{marginTop:"50px"}}>
                    <div className="cec-detail-label">NAME:</div>
                    <div className="cec-detail-input">{content.tenantName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">DOB:</div>
                    <div className="cec-detail-input">{moment(content.dateOfBirth).format("DD MMM YYYY").toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">BED:</div>
                    <div className="cec-detail-input">{bedLabel}</div>
                </div>
            </div>
        )
    }
}

class F1PITWristbandTemplate extends React.Component{

    componentDidMount=()=>{
      const content=this.props.content;
      const finLabel = content.nric;
      const bedLabel = content.levelName.toUpperCase() + "-" + content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase();
      Barcode("#fin-barcode",finLabel,{
          format:"CODE128",
          width:1,
          height:30,
          displayValue:false,
      });
      Barcode("#bed-barcode",bedLabel,{
          format:"CODE128",
          width:1,
          height:30,
          displayValue:false,
      });
    }

    render=()=>{
        const content=this.props.content;
        const finLabel = content.nric;
        const bedLabel = content.levelName.toUpperCase() + "-" + content.roomName.toUpperCase() + "-" + content.bedName.toUpperCase();
        return(
            <div className="f1-pit-container">
                <div className="f1-pit-rotate">
                      <div className="f1-barcode start">
                          <div className="barcode-title">{finLabel}</div>
                          <div className="f1-barcode-content">
                              <canvas id="fin-barcode" className="f1-barcode-canvas"/>
                          </div>
                      </div>

                      <div className="f1-details">
                          <div className="f1-text">{content.tenantName.toUpperCase()}</div>
                          <div className="f1-text">{content.gender.toUpperCase()}</div>
                          <div className="f1-text">{moment(content.checkInDate).format("DD-MM-YYYY")}</div>
                      </div>

                      <div className="f1-barcode">
                          <div className="barcode-title right">{bedLabel}</div>
                          <div className="f1-barcode-content">
                              <canvas id="bed-barcode" className="f1-barcode-canvas"/>
                          </div>
                      </div>
                </div>
            </div>
        )
    }
}

class F1PITInvoiceTemplate extends React.Component{

    componentDidMount=()=>{
        const content=this.props.content;
    }

    render=()=>{
        const content=this.props.content;
        const duration=moment(content.checkOutDate).diff(content.checkInDate,"days");
        const total = (parseFloat(content.invoiceAmount)) ? parseFloat(content.invoiceAmount) : 0;
        const gst = (total/107) * 7;
        const subtotal = total - gst;

        return(
            <div className="cec-card-container f1-pit-invoice-container">
                <div className="f1-invoice-header">
                    <div className="f1-invoice-header-left">
                        <h2 style={{marginBottom:"0"}}>Tax Invoice</h2>
                        <div>GST Reg No. M2-0058747-2</div>
                    </div>
                    <div className="f1-invoice-header-right">
                        <img className="f1-invoice-logo" src="/images/cards/mo-logo-trim.png"/>
                    </div>
                </div>

                <div className="f1-invoice-details">
                    <div className="f1-invoice-header-left">
                        <div className="invoice-item"><span className="f1-bold">Customer:</span> {content.tenantName}</div>
                        <div className="invoice-additional">
                            <b>
                                MOH-appointed operator of the COVID Treatment Facility<br/>
                                @ F1 Pit Building
                            </b>
                        </div>
                    </div>

                    <div className="f1-invoice-header-right">
                        <div className="invoice-item">
                            <div className="invoice-title">Invoice No.</div>
                            <div className="invoice-value">CTF-{moment(content.checkOutDate).format("DDMMYY")}-{content.buildingName}-{content.bedName}</div>
                        </div>
                        <div className="invoice-item">
                            <div className="invoice-title">Invoice Date</div>
                            <div className="invoice-value">{moment(content.checkOutDate).format("DD-MMM-YY")}</div>
                        </div>
                        <div className="invoice-item">
                            <div className="invoice-title">Arrival Date</div>
                            <div className="invoice-value">{moment(content.checkInDate).format("DD-MMM-YY")}</div>
                        </div>
                        <div className="invoice-item">
                            <div className="invoice-title">Departure Date</div>
                            <div className="invoice-value">{moment(content.checkOutDate).format("DD-MMM-YY")}</div>
                        </div>
                        <div className="invoice-item">
                            <div className="invoice-title">Bed No.</div>
                            <div className="invoice-value">{content.buildingName}-{content.bedName}</div>
                        </div>
                        <div className="invoice-item">
                            <div className="invoice-title">Cashier</div>
                            <div className="invoice-value">{u.getFullName()}</div>
                        </div>
                    </div>
                </div>

                <div className="f1-invoice-table">
                    <table>
                        <thead>
                            <tr>
                                <th className='it-date'>Date</th>
                                <th className="it-desc">Description</th>
                                <th className="it-amount">Amount (SGD)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="it-date">{moment(content.checkInDate).format("DD-MMM-YY")}</td>
                                <td className="it-desc">
                                    Total Charges:<br/>
                                    F1 CTF Facility Stay - {moment(content.checkInDate).format("Do MMM YYYY")} to {moment(content.checkOutDate).format("Do MMM YYYY")} ({duration} Day{(duration != 1) ? "s" : null})<br/>
                                    {(content.invoiceRemarks) ? content.invoiceRemarks : ""}
                                </td>
                                <td className="it-amount">{subtotal.toFixed(2)}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="f1-invoice-table-footer">
                          GST will be absorbed for all Singapore Citizens, Permanent Residents and Long Term Visit Pass-Plus Holders (SC/PR/LTVP+)
                    </div>
                </div>

                <div className="f1-invoice-total">
                    <div className="it-total-item">
                        <div className="it-total-title">Total before GST:</div>
                        <div className="it-total-value">{subtotal.toFixed(2)}</div>
                    </div>
                    <div className="it-total-item">
                        <div className="it-total-title">GST (7%):</div>
                        <div className="it-total-value">{gst.toFixed(2)}</div>
                    </div>
                    <div className="it-total-item">
                        <div className="it-total-title">Total (Tax Inclusive):</div>
                        <div className="it-total-value">{total.toFixed(2)}</div>
                    </div>
                    <br/>
                    <div className="it-total-item">
                        <div className="it-total-title">Payment Status:</div>
                        <div className="it-total-value">{content.paymentStatus}</div>
                    </div>
                </div>

                <div className="f1-invoice-ack-container">
                    <h3 className="ack-title">Customer Acknowledgement</h3>
                    <div className="ack-desc">I agree that my liability for this account is not waived and I agree to be held personally liable in the event that the indicated person, company or association fails to pay all or part of these charges.</div>
                    <div className="ack-sign">
                        <div className="ack-sign-item" style={{marginRight:"100px"}}>
                            <div>Date</div>
                            <div className="ack-sign-line"/>
                        </div>
                        <div className="ack-sign-item">
                            <div>Signature</div>
                            <div className="ack-sign-line"/>
                        </div>
                    </div>
                </div>

                <div className="f1-invoice-footer">
                    <div className="f1-invoice-header-left small-font">
                        CTF @ F1 Pit Building 1 Republic Boulevard, Singapore 038975<br/>
                        Telephone: +65 12345678
                    </div>
                    <div className="f1-invoice-header-right small-font">
                        Marina Bay Hotel Private Limited<br/>
                        UEN: 198105199K
                    </div>
                </div>

                <div className="display-cover">
                    <h1>F1 Pit Invoice</h1>
                    <img src="/images/cards/mo-logo-trim.png" style={{width:"100px"}}/>
                </div>

            </div>
        )
    }
}

class NUSTenantCardTemplate extends React.Component{

    render(){
        const content=this.props.content;
        const block = content.buildingName.substr(content.buildingName.indexOf(" ")+1);
        const level = content.levelName.substr(content.levelName.indexOf(" ") + 1);
        return (
            <div className="cec-card-container nus">
                <div className="cec-welcome">WELCOME TO PRINCE GEORGE'S PARK RESIDENCES AT NUS!</div>
                <div className="cec-detail">
                    <div className="cec-detail-label">NAME:</div>
                    <div className="cec-detail-input">{content.tenantName.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">FIN:</div>
                    <div className="cec-detail-input">{content.nric.toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">DOB:</div>
                    <div className="cec-detail-input">{moment(content.dateOfBirth).format("DD MMM YYYY").toUpperCase()}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">ROOM:</div>
                    <div className="cec-detail-input">BLK {block} LVL {level} RM {content.bedName.toUpperCase()}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">NATIONALITY:</div>
                    <div className="cec-detail-input">{(content.nationality) ? content.nationality.toUpperCase() : "NONE"}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">CONTACT:</div>
                    <div className="cec-detail-input">{(content.contactNumber) ? content.contactNumber : "NONE"}</div>
                </div>
                <div className="cec-detail">
                    <div className="cec-detail-label">DIETARY:</div>
                    <div className="cec-detail-input">{(content.foodPreference) ? content.foodPreference : "NONE"}</div>
                </div>
                <div className="cec-detail long">
                    <div className="cec-detail-label">CHECK-IN DATE:</div>
                    <div className="cec-detail-input">{(content.enrollmentDate) ?(moment(content.enrollmentDate).format("DD MMM YYYY")).toUpperCase() : "NONE"}</div>
                </div>

                <div className="cec-image">
                    <img src="/images/cards/nus-card.png"/>
                </div>
            </div>
        )
    }
}

class STCTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    getFoodPreference=(foodPreference)=>{
        if(typeof foodPreference == "undefined"){
            return "NP"
        }
        let fparr = (foodPreference.indexOf("-") != -1) ? foodPreference.split("-") : foodPreference.split(" ");

        let value = "";
        for(var i=0; i<fparr.length; i++){
            value += fparr[i].substr(0,1);
        }
        return value;
    }

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="top-logo" src="/images/cards/turf-club-logo.png"/>
                    <img className="bottom-logo" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:content.clusterColor}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                        <div className="stc-food-pref">{this.getFoodPreference(content.foodPreference)}</div>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area">
                    <div className="item">{content.tenantName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.levelName}/{content.roomName}/{content.bedName}</div>
                    <div className="item">{content.registeredCompanyName}</div>
                </div>
            </div>
        )
    }
}

class TPTTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    getFoodPreference=(foodPreference)=>{
        if(typeof foodPreference == "undefined"){
            return "NP"
        }
        let fparr = (foodPreference.indexOf("-") != -1) ? foodPreference.split("-") : foodPreference.split(" ");

        let value = "";
        for(var i=0; i<fparr.length; i++){
            value += fparr[i].substr(0,1);
        }
        return value;
    }

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="top-logo" src="/images/cards/psa-logo.png"/>
                    <img className="bottom-logo" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:content.clusterColor}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                        <div className="stc-food-pref">{this.getFoodPreference(content.foodPreference)}</div>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area tpt">
                    <div className="item">{content.tenantName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.roomName}/{content.bedName}</div>
                    <div className="item">{content.registeredCompanyName}</div>
                </div>
            </div>
        )
    }
}

class TT2TenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    getFoodPreference=(foodPreference)=>{
        if(typeof foodPreference == "undefined"){
            return "NP"
        }
        let fparr = (foodPreference.indexOf("-") != -1) ? foodPreference.split("-") : foodPreference.split(" ");

        let value = "";
        for(var i=0; i<fparr.length; i++){
            value += fparr[i].substr(0,1);
        }
        return value;
    }

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="bottom-logo tt2" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:content.clusterColor}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                        <div className="stc-food-pref">{this.getFoodPreference(content.foodPreference)}</div>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area tpt">
                    <div className="item">{content.tenantName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.roomName}/{content.bedName}</div>
                    <div className="item">{content.registeredCompanyName}</div>
                </div>
            </div>
        )
    }
}

class NSRCCTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="nsrcc-container">
                <div className="nsrcc-cluster-color" style={{backgroundColor:content.clusterColor}}/>

                <img className="nsrcc-logo" src="/images/cards/nsrcc-logo.jpeg"/>

                <div className="nsrcc-image-holder">
                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="nsrcc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="nsrcc-content-area">
                    <div className="item">{content.tenantName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.buildingName}-{content.roomName}-{content.bedName}</div>
                    <div className="item">{content.registeredCompanyName}</div>
                </div>
            </div>
        )
    }
}

class MWOCTengahTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="mwoc-top-logo">
                    <img src="/images/cards/mwoc-tengah.jpg"/>
                </div>

                <div className="stc-image-holder" style={{margin:"0"}}>

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area mwoc">
                    <div className="item">
                      <b>{content.tenantName}</b>
                    </div>
                    <div className="item">
                      <b>XXXX{content.nric.substr(4)}</b>
                    </div>
                    <div className="item">
                      <b>{moment(content.dateOfArrival).format("DD MMM YYYY")}</b>
                    </div>
                    <div className="item">
                      <b>{content.buildingName}/{content.levelName}-{content.roomName}/{content.bedName}</b>
                    </div>
                    <div className="item">
                      <b>{content.clientName}</b>
                    </div>
                </div>

                <div className="mwoc-logo-container">
                    <img src="/images/cards/dasl-logo.png"/>
                </div>
            </div>
        )
    }
}

class MWOCEunosTenantCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="mwoc-top-logo">
                    <img src="/images/cards/mwoc-eunos.jpg"/>
                </div>

                <div className="stc-image-holder" style={{margin:"0"}}>

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area mwoc">
                    <div className="item">
                      <b>{content.tenantName}</b>
                    </div>
                    <div className="item">
                      <b>XXXX{content.nric.substr(4)}</b>
                    </div>
                    <div className="item">
                      <b>{moment(content.dateOfArrival).format("DD MMM YYYY")}</b>
                    </div>
                    <div className="item">
                      <b>{content.buildingName}/{content.levelName}-{content.roomName}/{content.bedName}</b>
                    </div>
                    <div className="item">
                      <b>{content.clientName}</b>
                    </div>
                </div>

                <div className="mwoc-logo-container">
                    <img src="/images/cards/dasl-logo.png"/>
                </div>
            </div>
        )
    }
}

class MWOCTenantCardTemplate extends React.Component{

    state={

    };

    render=()=>{
        const content = this.props.content;

        return(
            <div className="mwoc-container">
                <div className="mwoc-content">
                    <div className="mwoc-name">{content.tenantName}</div>
                    <div className="mwoc-nric">{content.nric}</div>
                    <div className="mwoc-room">{content.buildingName}-{content.levelName}-{content.roomName}/{content.bedName}</div>
                </div>
            </div>
        )
    }
}

class PrimeLodgeCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      const content=this.props.content;
      return(
          <div className="pl-card-container">
              <div className="pl-underlay">{content.buildingName}</div>

              <img className="pl-logo" src={"/images/cards/prime-lodge.png"}/>

              <div className="pl-image-container">
                <img src={this.props.imgSrc} className="pl-avatar-image"/>
                <div className="pl-block-color" style={{backgroundColor:content.buildingColor}}/>
              </div>

              <div className="pl-flex-container">
                  <div className="pl-name"><b>{content.tenantName}</b></div>

                  <div>
                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="sd-qrcode">
                                        <QRCode value={this.props.content.rfid}
                                                level="M"
                                                fgColor="#000"
                                                bgColor="transparent"
                                                size={width}
                                                />
                                    </div>
                                )
                        }
                    </Measure>
                  </div>

                  <div className="pl-text">
                      <div className="pl-left"><b>FIN-{content.nric}</b></div>
                      <div className="pl-right"><b>RN-{content.roomName}</b></div>
                  </div>

              </div>
          </div>
      );
  }

}

/************************ Staff Card Templates *****************************/
class DefaultStaffCardTemplate extends React.Component{
  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      return(
          <div className="tenant-card-centurion">
              <img className="sd-logo" src={"/images/app/app-logo.png"}/>
              <div className='sd-content-area'>

                <img src={this.props.imgSrc}  className="sd-avatar-image"/>

                <div className="sd-text-content align-container">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="item name">{this.props.content.staffName}</div>
                        <div className="item estate">{this.props.content.nric}</div>
                        <div className="item estate">{this.props.content.designation}</div>
                    </div>
                </div>

                <div>
                  <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                      {
                          ({measureRef})=>
                              (
                                  <div ref={measureRef} className="sd-qrcode">
                                      <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                  </div>
                              )
                      }
                  </Measure>
                </div>

              </div>
              <img src="/images/app/chimeric.png"  className="sd-footer"/>
          </div>
      );
  }
}

class S11PPTStaffCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11 staff">
                <div className="flex-card">
                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.staffName}</div>
                            <div className="tc-company">{this.props.content.ownerName}</div>
                        </div>

                        <img src={"/images/cards/s11-card-logo.png"} className="s11-ppt-card-logo staff"/>
                    </div>

                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>
                </div>
            </div>
        );
    }
}

class S11PPTContractorCardTemplate extends React.Component{

    render(){
        return(
            <div className="tenant-card-s11 staff">
                <div className="tc-top-background contractor">
                    <div className="tc-top-image-div">
                        <img src={this.props.imgSrc}/>
                    </div>
                </div>
                <div className="tc-mid-content" style={{textAlign:"center"}}>
                    <div className="tc-horizontal-guide"></div>

                    <div className="tc-horizontal-content">
                        <div className="tc-name s11">{this.props.content.staffName}</div>
                        <div className="tc-company">{this.props.content.department}</div>
                    </div>

                    <img src={"/images/cards/s11-card-logo.png"} className="s11-ppt-card-logo"/>
                </div>
                <div className="tc-bottom-content contractor">
                    <div className="tc-bottom-guide"></div>
                    <div className="tc-bottom-text">CONTRACTOR</div>
                </div>
            </div>
        );
    }
}

class S11CL2StaffCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11 staff">
                <div className="flex-card">
                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.staffName}</div>
                            <div className="tc-company">{this.props.content.ownerName}</div>
                        </div>

                        <img src={"/images/cards/s11-cl2-card-logo.jpg"} className="s11-ppt-card-logo staff"/>
                    </div>

                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>
                </div>
            </div>
        );
    }
}

class S11CL2ContractorCardTemplate extends React.Component{

    render(){
        return(
            <div className="tenant-card-s11 staff">
                <div className="tc-top-background contractor">
                    <div className="tc-top-image-div">
                        <img src={this.props.imgSrc}/>
                    </div>
                </div>
                <div className="tc-mid-content" style={{textAlign:"center"}}>
                    <div className="tc-horizontal-guide"></div>

                    <div className="tc-horizontal-content">
                        <div className="tc-name s11">{this.props.content.staffName}</div>
                        <div className="tc-company">{this.props.content.department}</div>
                    </div>

                    <img src={"/images/cards/s11-cl2-card-logo.jpg"} className="s11-cl2-card-logo"/>
                </div>
                <div className="tc-bottom-content contractor">
                    <div className="tc-bottom-guide"></div>
                    <div className="tc-bottom-text">CONTRACTOR</div>
                </div>
            </div>
        );
    }
}

class S11HSJVStaffCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-s11 staff">
                <div className="flex-card">
                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <div className="centurion-image">
                            <img src={this.props.imgSrc}  className="centurion-avatar"/>
                        </div>
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>

                        <div className="tc-horizontal-content">
                            <div className="tc-name s11">{this.props.content.staffName}</div>
                            <div className="tc-company">{this.props.content.ownerName}</div>
                        </div>

                        <img src={"/images/cards/s11-hsjv-card-logo.png"} className="s11-ppt-card-logo staff"/>
                    </div>

                    <div className="tc-bottom-content">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">MANAGEMENT</div>
                    </div>
                </div>
            </div>
        );
    }
}

class S11HSJVContractorCardTemplate extends React.Component{

    render(){
        return(
            <div className="tenant-card-s11 staff">
                <div className="tc-top-background contractor">
                    <div className="tc-top-image-div">
                        <img src={this.props.imgSrc}/>
                    </div>
                </div>
                <div className="tc-mid-content" style={{textAlign:"center"}}>
                    <div className="tc-horizontal-guide"></div>

                    <div className="tc-horizontal-content">
                        <div className="tc-name s11">{this.props.content.staffName}</div>
                        <div className="tc-company">{this.props.content.ownerName}</div>
                    </div>

                    <img src={"/images/cards/s11-hsjv-card-logo.png"} className="staff s11-hsjv-card-logo"/>
                </div>
                <div className="tc-bottom-content contractor">
                    <div className="tc-bottom-guide"></div>
                    <div className="tc-bottom-text">CONTRACTOR</div>
                </div>
            </div>
        );
    }
}

class UTOCStaffCardTemplate extends React.Component{

    render(){
        return(
            <div className="tenant-card-s11">
                <div className="tc-top-background utoc-staff">
                    <div className="tc-top-image-div">
                        <div className="utoc-background"/>
                        <img src={this.props.imgSrc}/>
                    </div>
                    <div className="color-text-left utoc-staff">STAFF</div>
                    <div className="color-text-right utoc-staff">STAFF</div>
                </div>
                <div className="tc-mid-content utoc" style={{textAlign:"center"}}>
                    <div className="tc-horizontal-guide"></div>

                    <div className="tc-horizontal-content utoc">
                        <div className="tc-name utoc">{this.props.content.staffName}</div>
                        <div className="tc-permit utoc">{this.props.content.nric}</div>
                        <div className="tc-company utoc">{this.props.content.designation}</div>
                    </div>

                    <div className="utoc-logo-position">
                        <div className="utoc-logo-container">
                            <img src={"/images/cards/utoc-card-logo.jpg"} style={{display:"inline-block",verticalAlign:"middle",marginRight:"10px",height:"37px"}}/>
                            <div style={{display:"inline-block",verticalAlign:"middle",letterSpacing:0,lineHeight:"12px"}}>
                                <div className="utoc-name">UTOC Unity Dormitory</div>
                                352 Jalan Boon Lay,S619531<br/>
                                6264 2116
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

class CenturionJuniperStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render(){
        const { width, height } = this.state.dimensions;
        return(
            <div className="tenant-card-centurion">
                <img className="centurion-logo" src={"/images/cards/centurion-juniper.jpg"}/>

                <div className="centurion-image-holder staff">
                    <div className="centurion-image">
                        <img src={this.props.imgSrc}  className="centurion-avatar"/>
                    </div>
                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="centurion-qr-code">
                                        <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>
                </div>

                <div className="centurion-text-content align-container">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="cent-item staffName">{this.props.content.staffName}</div>
                        <div className="cent-item staff">{this.props.content.designation}</div>
                    </div>
                </div>

                <div className="centurion-address-divider"/>
                <div className="centurion-address-container">
                    Westlite Dormitory<br/>
                    23 Mandai Estate, SG 729937
                </div>
            </div>
        );
    }
}

class TeeUpStaffCardTemplate extends React.Component{

    render=()=>{
        const content=this.props.content;
        return (
            <div className="tenant-card-tee-up">

                <img src={"/images/cards/tee-up-card-logo-small.png"} className="tee-up-card-logo"/>

                <div className="tee-up-top-background">
                    <div className="tee-up-text-logo staff">PPT LODGE 1A</div>
                    <div className="tee-up-bg"/>
                    <div className="tee-up-text-room staff">STAFF</div>
                    <img className="tee-up-image staff" src={this.props.imgSrc}/>
                </div>

                <div className="tee-up-mid-content staff" style={{textAlign:"center"}}>
                    <div className="tee-up-valign-left"/>
                    <div className="tee-up-valign-right">
                        <div style={{fontSize:"0.8em"}}><b>{content.staffName}</b></div>
                        <div style={{fontSize:"0.78em",margin:"5px auto"}}>{content.designation}</div>
                    </div>
                </div>

                <div className="tee-up-rfid">{content.rfid}</div>

            </div>
        )
    }
}

class STCStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{

        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="top-logo" src="/images/cards/turf-club-logo.png"/>
                    <img className="bottom-logo" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:"#000"}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area">
                    <div className="item">{content.staffName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.designation}</div>
                </div>
            </div>
        )
    }
}

class TPTStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{

        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="top-logo" src="/images/cards/psa-logo.png"/>
                    <img className="bottom-logo" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:"#000"}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area tpt">
                    <div className="item">{content.staffName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.designation}</div>
                </div>
            </div>
        )
    }
}

class NSRCCStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="nsrcc-container">
                <div className="nsrcc-cluster-color" style={{backgroundColor:"#000"}}/>

                <img className="nsrcc-logo" src="/images/cards/nsrcc-logo.jpeg"/>

                <div className="nsrcc-image-holder">
                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="nsrcc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>
                </div>

                <div className="nsrcc-content-area">
                    <div className="item">{content.staffName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.designation}</div>
                </div>
            </div>
        )
    }
}

class TT2StaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{

        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="stc-top-area">
                    <img className="bottom-logo tt2" src="/images/cards/dasl-logo.png"/>
                    <div className="stc-triangle" style={{background:"#000"}}>
                        <img src="/images/cards/dasl-triangle-cover.png"/>
                    </div>
                </div>

                <div className="stc-image-holder">

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area tpt">
                    <div className="item">{content.staffName}</div>
                    <div className="item">{content.nric}</div>
                    <div className="item">{content.designation}</div>
                </div>
            </div>
        )
    }
}

class CEC2DormStaffCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      return(
          <div className="tenant-card-centurion border">
              <img className="sd-logo" src={"/images/app/app-logo.png"}/>
              <div className='sd-content-area'>
                <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                    {
                        ({measureRef})=>
                            (
                                <div ref={measureRef} style={{width:"90px",height:"90px"}}>
                                    <QRCode value={this.props.content.rfid} level="M" fgColor="#000" size={width}/>
                                </div>
                            )
                    }
                </Measure>

                <div className="sd-text-content align-container">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="item name">{this.props.content.staffName}</div>
                        <div className="item estate">Room Pass</div>
                    </div>
                </div>

              </div>
              <img src="/images/app/chimeric.png"  className="sd-footer"/>
          </div>
      );
  }
}

class MWOCTengahStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="mwoc-top-logo">
                    <img src="/images/cards/mwoc-tengah.jpg"/>
                </div>

                <div className="stc-image-holder" style={{margin:"0"}}>

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area mwoc">
                    <div className="item">
                      <b>{content.staffName}</b>
                    </div>
                    <div className="item">
                      <b>Staff Pass</b>
                    </div>
                </div>

                <div className="mwoc-logo-container">
                    <img src="/images/cards/dasl-logo.png"/>
                </div>
            </div>
        )
    }
}

class MWOCEunosStaffCardTemplate extends React.Component{

    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const content = this.props.content;
        const { width, height } = this.state.dimensions;

        return(
            <div className="stc-container">
                <div className="mwoc-top-logo">
                    <img src="/images/cards/mwoc-eunos.jpg"/>
                </div>

                <div className="stc-image-holder" style={{margin:"0"}}>

                    <div className="stc-image">
                        <img src={content.avatarLink}/>
                    </div>

                    <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                        {
                            ({measureRef})=>
                                (
                                    <div ref={measureRef} className="stc-qr-code">
                                        <QRCode value={content.rfid} level="M" fgColor="#000" size={width}/>
                                    </div>
                                )
                        }
                    </Measure>

                </div>

                <div className="stc-content-area mwoc">
                    <div className="item">
                      <b>{content.staffName}</b>
                    </div>
                    <div className="item">
                      <b>Staff Pass</b>
                    </div>
                </div>

                <div className="mwoc-logo-container">
                    <img src="/images/cards/dasl-logo.png"/>
                </div>
            </div>
        )
    }
}

class PrimeLodgeStaffCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      const content=this.props.content;
      return(
          <div className="pl-card-container-staff">

              <div className="pl-staff-top-underlay">
                  <div className="pl-tri-red"/>
                  <div className="pl-tri-blue"/>
              </div>

              <div className="pl-staff-bottom-underlay">
                  <div className="pl-bot-rect-red"/>
                  <div className="pl-bot-tri-red"/>
                  <div className="pl-bot-rect-blue"/>
                  <div className="pl-bot-tri-blue"/>
              </div>

              <div className="pl-staff-overlay">
                  <b>MANAGEMENT</b>
              </div>

              <img className="pl-logo" src={"/images/cards/prime-lodge.png"}/>

              <div className="pl-image-container">
                <img src={this.props.imgSrc} className="pl-staff-image"/>
              </div>

              <div className="pl-flex-container">
                  <div className="pl-name">
                    <b>{content.staffName}</b>
                    <br/>
                    <b>{content.nric}</b>
                  </div>
              </div>
          </div>
      );
  }

}

/************************ Visitor Card Templates *****************************/

class DefaultVisitorCardTemplate extends React.Component{

  state={
      dimensions:{
          width:-1,
          height:-1,
      }
  };

  render=()=>{
      const { width, height } = this.state.dimensions;
      const content=this.props.content;
      return(
          <div className="tenant-card-centurion">
              <img className="sd-logo" src={"/images/app/app-logo.png"}/>
              <div className='sd-content-area'>
                <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                    {
                        ({measureRef})=>
                            (
                                <div ref={measureRef} style={{width:"90px",height:"90px"}}>
                                    <QRCode value={this.props.content.passValue} level="M" fgColor="#000" size={width}/>
                                </div>
                            )
                    }
                </Measure>

                <div className="sd-text-content align-container">
                    <div className="align-bar"/>
                    <div className="align-content">
                        <div className="item name">{this.props.content.name.toUpperCase()}</div>
                        <div className="item estate" style={{color:"grey",margin:"10px auto"}}>{(content.clusterName == "All") ? "All Zones" : "Zone " + content.clusterName}</div>
                        <div className="item estate" style={{color:"grey"}}>
                            {
                                (content.permanent)
                                    ?
                                    "Preset Pass"
                                    :
                                    "Expire " + moment(content.validEnd).format("DD MMM - h:mma")
                            }
                        </div>
                    </div>
                </div>

              </div>
              <img src="/images/app/chimeric.png"  className="sd-footer"/>
          </div>
      );
  }
}

class S11PPTVisitorCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        const content=this.props.content;
        return(
            <div className="tenant-card-s11 visitor" style={{textAlign:"center"}}>
                <div className="flex-card">
                    <div className="tc-bottom-content visitor">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">VISITOR</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.passValue} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>

                        <div className="tc-horizontal-content" style={{marginTop:"5px"}}>
                            <div className="tc-name s11">{content.name.toUpperCase()}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>{(content.clusterName=="All") ? "All Zones" : "Zone " + content.clusterName}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>
                                {
                                    (content.permanent)
                                        ?
                                        "Preset Pass"
                                        :
                                        "Expire " + moment(content.validEnd).format("DD MMM - h:mma")
                                }
                            </div>
                        </div>

                        <img src={"/images/cards/s11-card-logo.png"} className="s11-ppt-card-logo"/>
                    </div>
                </div>
            </div>
        );
    }
}

class S11CL2VisitorCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        const content=this.props.content;
        return(
            <div className="tenant-card-s11 visitor" style={{textAlign:"center"}}>
                <div className="flex-card">
                    <div className="tc-bottom-content visitor">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">VISITOR</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.passValue} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>
                        <div className="tc-horizontal-content" style={{marginTop:"5px"}}>
                            <div className="tc-name s11">{content.name.toUpperCase()}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>{(content.clusterName=="All") ? "All Zones" : "Zone " + content.clusterName}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>
                                {
                                    (content.permanent)
                                        ?
                                        "Preset Pass"
                                        :
                                        "Expire " + moment(content.validEnd).format("DD MMM - h:mma")
                                }
                            </div>
                        </div>

                        <img src={"/images/cards/s11-cl2-card-logo.jpg"} className="s11-ppt-card-logo" style={{width:"50%",left:"25%"}}/>
                    </div>
                </div>
            </div>
        );
    }
}

class S11HSJVVisitorCardTemplate extends React.Component{
    state={
        dimensions:{
            width:-1,
            height:-1,
        }
    };

    render=()=>{
        const { width, height } = this.state.dimensions;
        const content=this.props.content;
        return(
            <div className="tenant-card-s11 visitor" style={{textAlign:"center"}}>
                <div className="flex-card">
                    <div className="tc-bottom-content visitor">
                        <div className="tc-bottom-guide"></div>
                        <div className="tc-bottom-text">VISITOR</div>
                    </div>

                    <div className="centurion-image-holder s11-flex">
                        <Measure bounds onResize={contentRect => {this.setState({ dimensions: contentRect.bounds })}}>
                            {
                                ({measureRef})=>
                                    (
                                        <div ref={measureRef} className="centurion-qr-code">
                                            <QRCode value={this.props.content.passValue} level="M" fgColor="#000" size={width}/>
                                        </div>
                                    )
                            }
                        </Measure>
                    </div>

                    <div className="flex-content" style={{textAlign:"center"}}>
                        <div className="tc-horizontal-content" style={{marginTop:"5px"}}>
                            <div className="tc-name s11">{content.name.toUpperCase()}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>{(content.clusterName=="All") ? "All Zones" : "Zone " + content.clusterName}</div>
                            <div className="tc-company" style={{marginTop:"10px",color:"grey"}}>
                                {
                                    (content.permanent)
                                        ?
                                        "Preset Pass"
                                        :
                                        "Expire " + moment(content.validEnd).format("DD MMM - h:mma")
                                }
                            </div>
                        </div>
                        <img src={"/images/cards/s11-hsjv-card-logo.png"} className="s11-ppt-card-logo"/>
                    </div>
                </div>
            </div>
        );
    }
}
